import React from "react";
import { PATTERN_TYPE } from "../../../shared/constants/pattern-types";
import { PERCENTAGE } from "../../../constants/commonConstants";
import LiftTrendCard from "./LiftTrendCard";
import LiftSpikeCard from "./LiftSpikeCard";

const LiftPercentContainer = ({ data }) => {
  const isDerived = data.is_derived;
  const kpiFormat = data.kpi_format;

  const derivedNonPercentage = isDerived && kpiFormat !== PERCENTAGE;
  const normalizedView = !isDerived && kpiFormat !== PERCENTAGE;

  const isTrend = data.pattern_type === PATTERN_TYPE.TREND_CHANGE;

  return isTrend ? (
    <LiftTrendCard
      data={data}
      derivedNonPercentage={derivedNonPercentage}
      normalizedView={normalizedView}
    />
  ) : (
    <LiftSpikeCard
      data={data}
      derivedNonPercentage={derivedNonPercentage}
      normalizedView={normalizedView}
    />
  );
};

export default LiftPercentContainer;
