import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { typography } from "../../../shared/theme-constants";
import { Loading } from "../styled-components";
import "./RootCauseHighChart.css";
import { PATTERN_CONTRIBUTION, RCA_BAR_COLOR_CONFIG } from "./rootcauseConstants";

const useStyles = makeStyles({
  icon: {
    color: "rgba(56, 178, 172, 1)", // Color for up arrow
    fontSize: 24,
  },
  iconNegative: {
    color: "rgba(217, 90, 90, 1)", // Color for down arrow
    fontSize: 24,
  },
});
const X_RANGE_BUFFER = 2;

const RootCauseHighChart = ({
  chartData = [],
  handleDrillDown,
  isPrimaryDriverChart = false,
  filterType = PATTERN_CONTRIBUTION,
  patternData = {},
}) => {
  const classes = useStyles();
  const [positiveContributorsChartData, setPositiveContributorsChartData] = useState([]);
  const [negativeContributorsChartData, setNegativeContributorsChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setPositiveContributorsChartData([]);
    setNegativeContributorsChartData([]);

    let positiveContributors = [];
    let negativeContributors = [];

    // When insight is spike down and accent is negative
    // We want negative percentages in positive contributors and positive percentages in negative contributors. So that's why we have put below condition.
    if (patternData?.pattern?.pattern_direction === "down" && patternData?.pattern?.accent === 'negative') {
      positiveContributors = chartData.filter((hotspot) => hotspot[filterType] < 0);
      negativeContributors = chartData.filter((hotspot) => hotspot[filterType] > 0);
    } else {
      positiveContributors = chartData.filter((hotspot) => hotspot[filterType] > 0);
      negativeContributors = chartData.filter((hotspot) => hotspot[filterType] < 0);
    }

    // Set the Chart Data for negative and positive values separately
    setPositiveContributorsChartData(positiveContributors);
    setNegativeContributorsChartData(negativeContributors);

    setIsLoading(false);
  }, [chartData]);

  const handleBarClick = (event) => {
    const clickedData = event.point.options;
    handleDrillDown(event, clickedData);
  };

  const baseChartOptions = {
    accessibility: {
      enabled: false,
    },
    title: {
      text: "",
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        pointPadding: 0.2,
        pointWidth: 6,
        borderColor: "rgba(233, 233, 233, 1)",
        cursor: "pointer",
        events: {
          // Drilldown button click.
          click: function (event) {
            handleBarClick(event);
          },
        },
      },
      // series: {
      //   stacking: "normal",
      // },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
      crosshairs: true
    },
  };

  // this Function decides whether the X Axis Labled will be placed on the RIght of the bars or the Left of the bars
  const getXSequence = (areAllValuesPositive) => {
    let oppositeXAxisDirection = false;

    if (isPrimaryDriverChart) {
      // if (isPositive){
      // No matter what the values are
      // for primary drivers we need to keep the labels on the left
      oppositeXAxisDirection = false;
    } else {
      if (areAllValuesPositive) {
        oppositeXAxisDirection = false;
      } else {
        oppositeXAxisDirection = true;
      }
    }
    return oppositeXAxisDirection;
  };

  // This Function decides whether the Y axis numbers will start from left to right or right to left
  const getYSequence = (areAllValuesPositive) => {
    let reverseYAxisDirection = false;
    if (isPrimaryDriverChart) {
      // Here We know that we need to show the chart from left to right
      // But the negative axis should also start from left to right
      // hence the below change
      if (patternData?.pattern?.pattern_direction === "down" && patternData?.pattern?.accent === 'negative') {
        // In this condition we have negative values in positive contributors.
        // Hence, we are doing reverse of axis.
        if (areAllValuesPositive) {
          reverseYAxisDirection = true;
        } else {
          reverseYAxisDirection = false;
        }
      } else {
        if (areAllValuesPositive) {
          reverseYAxisDirection = false;
        } else {
          reverseYAxisDirection = true;
        }
      }
    } else {
      // In the below condition we have negative values in positive contributors and vice versa.
      // Hence, we are doing reverse of axis.
      if (patternData?.pattern?.pattern_direction === "down" && patternData?.pattern?.accent === 'negative') {
        reverseYAxisDirection = true;
        } else {
          // In this case we don't need to reverse the sequence as
          // negattive values will be on left of the axis and positive values will be on right
          reverseYAxisDirection = false;
        }
    }
    return reverseYAxisDirection;
  };

  const getBarColors = (areAllValuesPositive) => {
    let color;
    if (isPrimaryDriverChart) {
      color = RCA_BAR_COLOR_CONFIG[filterType];
    } else {
      if (patternData?.pattern?.accent === "positive") {
        if (areAllValuesPositive) {
          color = "rgba(154, 214, 169, 1)";
        } else {
          color = "rgba(217, 90, 90, 1)";
        }
      } else {
        if (areAllValuesPositive) {
          color = "rgba(217, 90, 90, 1)";
        } else {
          color = "rgba(154, 214, 169, 1)";
        }
      }
    }
    return color;
  };

  const getArrowColors = (areAllValuesPositive, section) => {
    let obj = {};
      if (patternData?.pattern?.accent === "positive") {
        if (areAllValuesPositive) {
          obj.color = "rgba(56, 178, 172, 1)";
        } else {
          obj.color = "rgba(217, 90, 90, 1)";
        }
      } else {
        if (areAllValuesPositive) {
          obj.color = "rgba(217, 90, 90, 1)";
        } else {
          obj.color = "rgba(56, 178, 172, 1)";
        }
      }
    return obj;
  };

  // This Function is to show the threshold Line on the Primary drivers chart
  const getThresholdLine = (data = [], areAllValuesPositive) => {
    let plotLine = [];
    let thresholdValue = 0;
    // As Of now We could figure out that We can apply threshold lines only yo primary drivers and
    // create value for threshld line
    // check if the Xrange max is < than the spike up value.
    // If yes then threshold value is calculated using an arbitrary constant
    // Else keep it as is
    const xMax = Math.max(...data.map((item) => Math.abs(item[filterType])));
    if (xMax * X_RANGE_BUFFER * 100 < Math.abs(patternData?.pattern?.pattern_count)) {
      // If all values are negative then our xaxis will be opposite so keeping the value of threshold as positive will show it on left side of bar.
      // That is why taking the negative threshold.
      if (patternData?.pattern?.pattern_direction === "down" && patternData?.pattern?.accent === 'negative') {
        // When spike is down and accent is negative then positive contributors will hold negative percentage values.
        // Hence below condition.
        thresholdValue = areAllValuesPositive
        ? -(xMax * X_RANGE_BUFFER * 80)
        : xMax * X_RANGE_BUFFER * 80;
      } else {
        thresholdValue = areAllValuesPositive
        ? xMax * X_RANGE_BUFFER * 80
        : -(xMax * X_RANGE_BUFFER * 80);
      }
    } else if (patternData?.pattern?.pattern_direction === "down" && patternData?.pattern?.accent === 'negative') {
      // When spike is down and accent is negative then positive contributors will hold negative percentage values.
      // Hence below condition.
      thresholdValue = areAllValuesPositive ? patternData?.pattern?.pattern_count : Math.abs(patternData?.pattern?.pattern_count);
    } else {
      thresholdValue = areAllValuesPositive
        ? Math.abs(patternData?.pattern?.pattern_count)
        : patternData?.pattern?.pattern_count;
    }
    // This below line of code is to identify if our threshold label is overflowing ?
    // We check our threshold value with bit lesser value than xMax range to identify if it's closer to end of axis. If yes then we align our label on right side.
    const align = Math.abs(thresholdValue) > xMax * X_RANGE_BUFFER * 70 ? "right" : "left";
    const xOffset = align === "right" ? -10 : 10; // Adjust offset to prevent overflow

    if (filterType === PATTERN_CONTRIBUTION && isPrimaryDriverChart) {
      plotLine.push({
        color: patternData?.pattern?.accent === "negative" ? "#E88A8A" : "#43A45C",
        width: 2,
        value: thresholdValue,
        label: {
          text: `<b>${patternData?.pattern?.formatted_lift_percentage}</b> ${
            patternData?.pattern?.pattern_direction === "down" ? "Spike Down" : "Spike Up"
          }`, // Label text on threshold Line
          align: align,
          x: xOffset,
          rotation: 0,
          style: {
            color: patternData?.pattern?.accent === "negative" ? "#E88A8A" : "#43A45C",
            fontSize: "16px",
          },
        },
      });
    }
    return plotLine;
  };

  const generateChartOptions = (
    chartData = [],
    areAllValuesPositive = false,
    xRange = [0, 100]
  ) => ({
    ...baseChartOptions,
    chart: {
      type: "bar",
      height: chartData?.length * 70,
      backgroundColor: "transparent",
    },
    xAxis: {
      categories: chartData.map(
        (item) =>
          `<span class="truncate" title="${isPrimaryDriverChart ? `${item.dim_name}:` : ""} ${item.dim_val}">${
            isPrimaryDriverChart ? `<b>${item.dim_name}: </b>` : ""
          }${`${item.dim_val}`}</span>`
      ),
      title: {
        text: null,
      },
      crossing: 0,
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: "transparent",
      tickColor: "transparent",
      labels: {
        style: {
          fontSize: "16px",
        },
        useHTML: true,
      },
      gridLineWidth: 0,
      opposite: getXSequence(areAllValuesPositive),
      reversed: true,
    },
    yAxis: {
      min: xRange[0],
      max: xRange[1],
      title: {
        text: "",
      },
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
      lineWidth: 0,
      minorGridLineWidth: 0,
      reversed: getYSequence(areAllValuesPositive),
      plotLines: [...getThresholdLine(chartData, areAllValuesPositive)],
    },
    series: [
      {
        name: "Progress",
        data: chartData.map((item) => ({
          ...item,
          y: parseFloat((item[filterType] * 100).toFixed(1)),
        })),
        color: getBarColors(areAllValuesPositive),
        showInLegend: false,
        dataLabels: {
          enabled: true,
          y: !areAllValuesPositive && !isPrimaryDriverChart ? -20 : -20,
          useHTML: true,
          style: {
            fontSize: "16px",
            textOutline: "none",
          },
          formatter: function () {
            let barHeight = 0;
            if (this?.point && this.point.shapeArgs && this.point.shapeArgs.height) {
              barHeight = this.point.shapeArgs.height;
            }
            let xOffset =
              !areAllValuesPositive && !isPrimaryDriverChart ? barHeight + 3 : -(barHeight + 3);
            return `<div style="background:unset; font-size: 16px; border:none; cursor: pointer;font-weight: bold; transform: translateX(${xOffset}px);">
            <span style="color: ${getBarColors(areAllValuesPositive)};">${
              this.point[`formatted_${filterType}`]
            }</span>&nbsp;&nbsp;<img style="width:6px;vertical-align: baseline;" src="/images/drill_down_arrow.png" /></div>`;
          },
        },
      },
    ],
  });

  const calculateXRange = (data, areAllValuesPositive = true) => {
    let xRange = [0, 100];
    const xMax = Math.max(...data.map((item) => Math.abs(item[filterType])));

    // we need to get the maximum abs value of the bar from both +ve and -ve contributors array
    // and set the range as per the max
    // This will make sure the proportion of length of the bars is maintained
    xRange = [-xMax * X_RANGE_BUFFER * 100, xMax * X_RANGE_BUFFER * 100];
    return xRange;
  };

  if (isLoading) {
    return <Loading />;
  }

  // Only for primary drivers chart
  if (isPrimaryDriverChart) {
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={generateChartOptions(
          chartData,
          negativeContributorsChartData?.length > 0 ? false : true,
          calculateXRange(chartData, negativeContributorsChartData?.length > 0 ? false : true)
        )}
      />
    );
  }

  // Only render negative direction chart
  if (positiveContributorsChartData?.length === 0) {
    return (
      <Stack direction="column" mt={-9} gap={4}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(16),
              fontWeight: typography.font_weight.semiBold,
              display: "flex",
              alignItems: "center",
            })}
          >
            <ArrowDownwardIcon sx={{...getArrowColors(false)}} /> Dimensions away from the Spike
          </Typography>
          <Tooltip
            title="Dimensions that have negatively impacted the metric, showing their contribution percentage."
            placement="bottom"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  color: "var(--black)",
                  backgroundColor: "#E6E6E6",
                  fontSize: "0.8em",
                },
              },
            }}
          >
            <InfoOutlinedIcon sx={{ ml: 1.5 }} fontSize="small" color="var(--comet)" />
          </Tooltip>
        </Stack>
        <Box style={{ width: "100%", alignSelf: "flex-start" }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={generateChartOptions(
              negativeContributorsChartData,
              false,
              calculateXRange(chartData, false)
            )}
          />
        </Box>
      </Stack>
    );
  }

  // Only render Positive direction charts
  if (negativeContributorsChartData.length === 0) {
    return (
      <Stack direction="column" mt={-9}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(16),
              fontWeight: typography.font_weight.semiBold,
              display: "flex",
              alignItems: "center",
            })}
          >
            <ArrowUpwardIcon sx={{...getArrowColors(true)}} /> Dimensions towards the Spike
          </Typography>
          <Tooltip
            title="Dimensions that have positively impacted the metric, showing their contribution percentage."
            placement="bottom"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  color: "var(--black)",
                  backgroundColor: "#E6E6E6",
                  fontSize: "0.8em",
                },
              },
            }}
          >
            <InfoOutlinedIcon sx={{ ml: 1.5 }} fontSize="small" color="var(--comet)" />
          </Tooltip>
        </Stack>
        <Box style={{ width: "100%", alignSelf: "flex-end" }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={generateChartOptions(
              positiveContributorsChartData,
              true,
              calculateXRange(chartData, true)
            )}
          />
        </Box>
      </Stack>
    );
  }

  // Render Both charts
  return (
    <>
      <Stack direction="column" mt={-9}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(16),
              fontWeight: typography.font_weight.semiBold,
              display: "flex",
              alignItems: "center",
            })}
          >
            <ArrowUpwardIcon sx={{...getArrowColors(true)}} /> Dimensions towards the Spike
          </Typography>
          <Tooltip
            title="Dimensions that have positively impacted the metric, showing their contribution percentage."
            placement="bottom"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  color: "var(--black)",
                  backgroundColor: "#E6E6E6",
                  fontSize: "0.8em",
                },
              },
            }}
          >
            <InfoOutlinedIcon sx={{ ml: 1.5 }} fontSize="small" color="var(--comet)" />
          </Tooltip>
        </Stack>
        <Box style={{ width: "100%", alignSelf: "flex-end" }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={generateChartOptions(
              positiveContributorsChartData,
              true,
              calculateXRange(chartData, true)
            )}
          />
        </Box>
      </Stack>
      <Stack direction="column">
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(16),
              fontWeight: typography.font_weight.semiBold,
              display: "flex",
              alignItems: "center",
            })}
          >
            <ArrowDownwardIcon sx={{...getArrowColors(false)}} /> Dimensions away from the Spike
          </Typography>
          <Tooltip
            title="Dimensions that have negatively impacted the metric, showing their contribution percentage."
            placement="bottom"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  color: "var(--black)",
                  backgroundColor: "#E6E6E6",
                  fontSize: "0.8em",
                },
              },
            }}
          >
            <InfoOutlinedIcon sx={{ ml: 1.5 }} fontSize="small" color="var(--comet)" />
          </Tooltip>
        </Stack>
        <Box style={{ width: "100%", alignSelf: "flex-start" }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={generateChartOptions(
              negativeContributorsChartData,
              false,
              calculateXRange(chartData, false)
            )}
          />
        </Box>
      </Stack>
    </>
  );
};

export default RootCauseHighChart;
