import React, { useEffect, useState, useRef, useCallback } from "react";
import { Box } from "@mui/material";
// CSS imports
import "./SummaryCard.css";
import "./styleguide.css";

// Local imports
import {
  getInsightSummary,
  getInsightSummaryForTimeSeries,
  getInsightSummaryHistogram,
} from "../../../actions/insightSummaryActions";
import TimePickerIcon from "../../common/TimePickerIcon";
import TimePickerModal from "../../common/TimePickerModal";
import {
  SummaryCardLoader,
  SummaryHeaderLoader,
} from "../../common/SummaryCardLoader";
import { Histogram } from "../../common/Histogram";
import {
  summaryHistogramPlot,
  summaryHistogramLayout,
  summaryHistogramConfig,
} from "../../../shared/plotly-config";

// date function imports
import { formatRelative } from "date-fns";
import { enUS } from "date-fns/esm/locale";

// Redux imports
import { useDispatch, useSelector } from "react-redux";
// Styled SVG imports
import Icon from "../../common/Icon";
import useBoolean from "../../../hooks/useBoolean";
import { TIME_FILTER_UPDATE } from "../../../constants/filterCriteriaConstants";
import { HistogramLoader } from "../../common/HistogramLoader";
import { merge } from "../../../utils/objects";
import {
  getBarGap,
  getStartAndEndTimestamps,
  getXAxisOverrideConfig,
  getXAxisTicksCount,
  translateToHistogramChartAxes,
} from "../../../utils/histogramHelpers";
import { SummaryMetricLink } from "../../common/styled-components/metric/Metric.styled";
import { useSelectedTenant } from "../../../hooks/useSelectedTenant";

// custom string for last processed time format
const formatRelativeLocale = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "P",
};

const locale = {
  ...enUS,
  formatRelative: (token) => formatRelativeLocale[token],
};

function SummaryCard() {
  const dispatch = useDispatch();
  const timeFilter = useSelector((state) => state.feedScreenTimeFilter);
  const tenantGlobalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);
  const [loadingTopMetric, setLoadingTopMetric] = useState(false);
  const [loadingPattern, setLoadingPattern] = useState(false);
  const [loadingTimeSeriesData, setLoadingTimeSeriesData] = useState(false);
  const [isHistogramLoading, setHistogramLoading] = useState();
  const [histogramLayout, setHistogramLayout] = useState();
  const [topMetricData, setTopMetricData] = useState(undefined);
  const [insightHistoryData, setInsightHistoryData] = useState(undefined);
  const [timeSeriesAnalysed, setTimeSeriesAnalysed] = useState(undefined);
  const [lastPipelineRunDate, setLastPipelineRunDate] = useState(null);
  const [patternData, setPatternData] = useState(undefined);
  const [granularity, setGranularity] = useState();
  const [
    isTimePickerOpen,
    { setTrue: openTimePicker, setFalse: closeTimePicker },
  ] = useBoolean();
  const isMountedRef = useRef(null);
  const selectedTenant = useSelectedTenant();

  const fetchInsightSummaryHistogram = useCallback(
    (startTime, endTime) => {
      setHistogramLoading(true);
      dispatch(
        getInsightSummaryHistogram({
          tenant_id: selectedTenant,
          start_time: startTime,
          end_time: endTime,
          dim_name: tenantGlobalFilter?.dimension_name,
          dim_val: tenantGlobalFilter?.dimension_value,
        })
      ).then((response) => {
        if (response && isMountedRef.current) {
          setGranularity(response.histogram_granularity);
          setInsightHistoryData(
            merge(
              summaryHistogramPlot,
              translateToHistogramChartAxes(
                response.histogram_data,
                response.histogram_granularity,
                startTime,
                endTime
              )
            )
          );

          setHistogramLayout(
            merge(summaryHistogramLayout, {
              bargap: getBarGap(
                getXAxisTicksCount(
                  startTime,
                  endTime,
                  response.histogram_granularity,
                  response.histogram_data.length
                )
              ),
              xaxis: getXAxisOverrideConfig(startTime, endTime, response),
            })
          );

          setHistogramLoading(false);
        }
      });
    },
    [selectedTenant, dispatch, tenantGlobalFilter]
  );

  // fetch the time series data from summary api
  const fetchTimeSeriesAnalyzed = useCallback(
    (startingTime, endingTime) => {
      setLoadingTimeSeriesData(true);
      dispatch(
        getInsightSummaryForTimeSeries({
          tenant_id: selectedTenant,
          start_time: startingTime,
          end_time: endingTime,
          dim_name: tenantGlobalFilter?.dimension_name,
          dim_val: tenantGlobalFilter?.dimension_value,
        })
      )
        .then((result) => {
          if (result && isMountedRef.current) {
            setTimeSeriesAnalysed(result);
            try {
              // use formating function from date-fns which will represent the time based on custom time format declared above
              setLastPipelineRunDate(
                formatRelative(
                  new Date(result?.last_pipeline_run_ts),
                  new Date(),
                  {
                    locale,
                  }
                )
              );

              setLoadingTimeSeriesData(false);
            } catch (error) {
              // log error if any
            }
          }
        })
        .catch((error) => {
          // log error if any
        });
    },
    [dispatch, selectedTenant, tenantGlobalFilter]
  );

  const fetchTopMetric = useCallback(
    (startingTime, endingTime) => {
      setLoadingTopMetric(true);
      dispatch(
        getInsightSummary(
          "top_metric",
          selectedTenant,
          startingTime,
          endingTime,
          tenantGlobalFilter?.dimension_name,
          tenantGlobalFilter?.dimension_value,
        )
      ).then((result) => {
        if (result && isMountedRef.current) {
          setTopMetricData(result);
          setLoadingTopMetric(false);
        } else {
          // logic for no records scenario
        }
      });
    },
    [dispatch, selectedTenant, tenantGlobalFilter]
  );

  const fetchPatternData = useCallback(
    (startingTime, endingTime) => {
      setLoadingPattern(true);
      dispatch(
        getInsightSummary("patterns", selectedTenant, startingTime, endingTime, tenantGlobalFilter?.dimension_name, tenantGlobalFilter?.dimension_value)
      ).then((result) => {
        if (result && isMountedRef.current) {
          setPatternData(result);
          setLoadingPattern(false);
        } else {
          // logic for no records scenario
        }
      });
    },
    [dispatch, selectedTenant, tenantGlobalFilter]
  );

  function handleTimeSelected(selectedStartEndTimestamp) {
    dispatch({
      type: TIME_FILTER_UPDATE,
      payload: selectedStartEndTimestamp,
    });
  }

  function handleHistogramBarClick(barDate) {
    const { start, end } = getStartAndEndTimestamps(
      new Date(barDate),
      granularity
    );

    dispatch({
      type: TIME_FILTER_UPDATE,
      payload: {
        start_time: start.toISOString(),
        end_time: end.toISOString(),
        type: `histogram/${granularity}`,
      },
    });
  }

  // initial fetch, with initial date&time range
  // TODO set proper initial values
  useEffect(() => {
    isMountedRef.current = true;

    fetchTopMetric(timeFilter.start_time, timeFilter.end_time);
    fetchPatternData(timeFilter.start_time, timeFilter.end_time);
    fetchTimeSeriesAnalyzed(timeFilter.start_time, timeFilter.end_time);
    fetchInsightSummaryHistogram(timeFilter.start_time, timeFilter.end_time);
    // API calls for other two cards
    // fetchTopDimensionData();
    // fetchSeverity();

    return () => {
      isMountedRef.current = false;
    };
  }, [
    timeFilter.start_time,
    timeFilter.end_time,
    fetchTopMetric,
    fetchPatternData,
    fetchTimeSeriesAnalyzed,
    fetchInsightSummaryHistogram,
  ]);

  return (
    <div style={{ zIndex: 1, position: "relative" }}>
      {isTimePickerOpen && (
        <TimePickerModal
          onClose={closeTimePicker}
          onSelect={handleTimeSelected}
        />
      )}
      <TimePickerIcon onClick={openTimePicker} />
      <div style={{ position: "relative" }}>
        <div className="summary-card screen">
          {loadingTimeSeriesData ? (
            <SummaryHeaderLoader />
          ) : (
            <div className="frame-853 poppins-normal-white-13px-2">
              <p className="we-processed valign-text-middle">
                <span>
                  <span className="poppins-normal-blue-bell-13px">
                    We processed{" "}
                  </span>
                  <span className="poppins-medium-white-13px">
                    {timeSeriesAnalysed?.time_series_analysed.toLocaleString()}{" "}
                  </span>
                  <span className="poppins-normal-blue-bell-13px">
                    time-series
                  </span>
                </span>
                <span className="poppins-normal-blue-bell-13px">
                  {" "}
                  for you to generate this summary
                </span>
              </p>
              <div className="last-processed valign-text-middle">
                <span className="poppins-normal-blue-bell-13px">
                  Last Processed:{" "}
                </span>
                <span className="poppins-normal-white-13px">
                  {lastPipelineRunDate}
                </span>
              </div>
            </div>
          )}
          <div className="frame-849">
            <div className="frame-847">
              <div className="frame-657">
                {loadingTopMetric ? (
                  <SummaryCardLoader />
                ) : (
                  <>
                    <div className="frame-714">
                      <div className="icons-1">
                        <Icon name="TopMetric" size="44px" />
                      </div>

                      <div className="frame-655">
                        <div className="top-metric valign-text-middle poppins-bold-white-19px">
                          Top Metric
                        </div>
                        <div className="surname valign-text-middle poppins-normal-white-13px">
                          {topMetricData?.attributes &&
                          topMetricData.attributes.hasOwnProperty("kpi_id") ? (
                            <SummaryMetricLink
                              to={`/metrics/${topMetricData.attributes.kpi_id}`}
                            >
                              {topMetricData.formatted_value}
                            </SummaryMetricLink>
                          ) : (
                            <span>{topMetricData?.formatted_value}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <h1 className="number-1 valign-text-middle poppins-bold-white-36px">
                      {/* {topMetricData?.formatted_count} */}
                    </h1>
                  </>
                )}
              </div>

              <div className="frame-658">
                {loadingPattern ? (
                  <SummaryCardLoader />
                ) : (
                  <>
                    <div className="frame-714-2">
                      <Icon name="Patterns" size="44px" />
                      <div className="frame-655-2">
                        <div className="pattern valign-text-middle poppins-bold-white-19px">
                          Spikes
                        </div>
                        {/* <div className="spike-up valign-text-middle poppins-normal-white-13px">
                          {patternData?.formatted_value}
                        </div> */}
                      </div>
                    </div>
                    <div className="number-3 valign-text-middle poppins-bold-white-36px">
                      {patternData?.count}
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* We will need the Below Code once we Integrate Top Dimenesions and AutoResolution. Hence keeping it for now */}

            {/*  <div className="frame-848">
              <div className="frame-814">
                <div className="frame-714-1">
                  <div
                    className="icons"
                    style={{
                      backgroundImage: `url(${summaryCardData.icons1})`,
                    }}
                  ></div>
                  <div className="frame-655-1">
                    <div className="top-dimension valign-text-middle poppins-bold-white-19px">
                      {summaryCardData.topDimension}
                    </div>
                    <div className="i-phone valign-text-middle poppins-normal-white-13px">
                      {summaryCardData.iphone}
                    </div>
                  </div>
                </div>
                <div className="number-2 valign-text-middle poppins-bold-white-36px">
                  {summaryCardData.number2}
                </div>
              </div>
              <div className="frame-814">
                <div className="frame-714-3">
                  <div
                    className="icons"
                    style={{
                      backgroundImage: `url(${summaryCardData.icons3})`,
                    }}
                  ></div>
                  <div className="frame-655-3">
                    <div className="severity valign-text-middle poppins-bold-white-19px">
                      {summaryCardData.severity}
                    </div>
                  </div>
                </div>
                <div className="frame-723">
                  <div className="frame-720">
                    <div className="number valign-text-middle poppins-bold-white-26px">
                      {summaryCardData.number4}
                    </div>
                    <div className="low valign-text-middle poppins-semi-bold-white-13px">
                      {summaryCardData.low}
                    </div>
                  </div>
                  <div className="frame-72">
                    <div className="number valign-text-middle poppins-bold-white-26px">
                      {summaryCardData.number5}
                    </div>
                    <div className="med valign-text-middle poppins-semi-bold-white-13px">
                      {summaryCardData.med}
                    </div>
                  </div>
                  <div className="frame-72">
                    <div className="number valign-text-middle poppins-bold-white-26px">
                      {summaryCardData.number6}
                    </div>
                    <div className="high valign-text-middle poppins-semi-bold-white-13px">
                      {summaryCardData.high}
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}
          </div>

          <Box sx={{ padding: "20px 20px 10px", height: 120 }}>
            {isHistogramLoading ? (
              <HistogramLoader />
            ) : (
              <Histogram
                onBarClick={handleHistogramBarClick}
                data={[insightHistoryData]}
                layout={histogramLayout}
                config={summaryHistogramConfig}
              />
            )}
          </Box>
        </div>
      </div>
    </div>
  );
}

export default SummaryCard;
