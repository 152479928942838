export const currencyMap = [
    {"code": "USD", "name": "United States Dollar", "symbol": "$"},
    {"code": "EUR", "name": "Euro", "symbol": "€"},
    {"code": "GBP", "name": "British Pound Sterling", "symbol": "£"},
    {"code": "CAD", "name": "Canadian Dollar", "symbol": "$"},
    {"code": "AUD", "name": "Australian Dollar", "symbol": "$"},
    {"code": "JPY", "name": "Japanese Yen", "symbol": "¥"},
    {"code": "CNY", "name": "Chinese Yuan", "symbol": "¥"},
    {"code": "INR", "name": "Indian Rupee", "symbol": "₹"},
    {"code": "BRL", "name": "Brazilian Real", "symbol": "R$"},
    {"code": "RUB", "name": "Russian Ruble", "symbol": "₽"},
    {"code": "ZAR", "name": "South African Rand", "symbol": "R"},
    {"code": "CHF", "name": "Swiss Franc", "symbol": "CHF"},
    {"code": "SGD", "name": "Singapore Dollar", "symbol": "S$"},
    {"code": "HKD", "name": "Hong Kong Dollar", "symbol": "HK$"},
    {"code": "SEK", "name": "Swedish Krona", "symbol": "kr"},
    {"code": "MXN", "name": "Mexican Peso", "symbol": "$"},
    {"code": "NZD", "name": "New Zealand Dollar", "symbol": "$"},
    {"code": "NOK", "name": "Norwegian Krone", "symbol": "kr"},
    {"code": "TRY", "name": "Turkish Lira", "symbol": "₺"},
    {"code": "KRW", "name": "South Korean Won", "symbol": "₩"},
    {"code": "ARS", "name": "Argentine Peso", "symbol": "$"},
    {"code": "CLP", "name": "Chilean Peso", "symbol": "$"},
    {"code": "COP", "name": "Colombian Peso", "symbol": "$"},
    {"code": "CZK", "name": "Czech Koruna", "symbol": "Kč"},
    {"code": "DKK", "name": "Danish Krone", "symbol": "kr"},
    {"code": "EGP", "name": "Egyptian Pound", "symbol": "£"},
    {"code": "HUF", "name": "Hungarian Forint", "symbol": "Ft"},
    {"code": "IDR", "name": "Indonesian Rupiah", "symbol": "Rp"},
    {"code": "ILS", "name": "Israeli New Shekel", "symbol": "₪"},
    {"code": "KES", "name": "Kenyan Shilling", "symbol": "KSh"},
    {"code": "MAD", "name": "Moroccan Dirham", "symbol": "د.م."},
    {"code": "MYR", "name": "Malaysian Ringgit", "symbol": "RM"},
    {"code": "NGN", "name": "Nigerian Naira", "symbol": "₦"},
    {"code": "PHP", "name": "Philippine Peso", "symbol": "₱"},
    {"code": "PLN", "name": "Polish Zloty", "symbol": "zł"},
    {"code": "QAR", "name": "Qatari Rial", "symbol": "ر.ق"},
    {"code": "RON", "name": "Romanian Leu", "symbol": "lei"},
    {"code": "SAR", "name": "Saudi Riyal", "symbol": "ر.س"},
    {"code": "THB", "name": "Thai Baht", "symbol": "฿"},
    {"code": "TWD", "name": "New Taiwan Dollar", "symbol": "NT$"},
    {"code": "UAH", "name": "Ukrainian Hryvnia", "symbol": "₴"},
    {"code": "VND", "name": "Vietnamese Dong", "symbol": "₫"},
    {"code": "AED", "name": "United Arab Emirates Dirham", "symbol": "د.إ"},
    {"code": "BDT", "name": "Bangladeshi Taka", "symbol": "৳"},
    {"code": "BHD", "name": "Bahraini Dinar", "symbol": ".د.ب"},
    {"code": "BND", "name": "Brunei Dollar", "symbol": "$"},
    {"code": "BGN", "name": "Bulgarian Lev", "symbol": "лв"},
    {"code": "HRK", "name": "Croatian Kuna", "symbol": "kn"},
    {"code": "DZD", "name": "Algerian Dinar", "symbol": "د.ج"},
    {"code": "ISK", "name": "Icelandic Krona", "symbol": "kr"},
    {"code": "JOD", "name": "Jordanian Dinar", "symbol": "د.ا"},
    {"code": "KWD", "name": "Kuwaiti Dinar", "symbol": "د.ك"},
    {"code": "LKR", "name": "Sri Lankan Rupee", "symbol": "Rs"},
    {"code": "LYD", "name": "Libyan Dinar", "symbol": "ل.د"},
    {"code": "OMR", "name": "Omani Rial", "symbol": "ر.ع."},
    {"code": "PEN", "name": "Peruvian Sol", "symbol": "S/."},
    {"code": "PKR", "name": "Pakistani Rupee", "symbol": "₨"},
    {"code": "PYG", "name": "Paraguayan Guarani", "symbol": "₲"},
    {"code": "TND", "name": "Tunisian Dinar", "symbol": "د.ت"},
    {"code": "TTD", "name": "Trinidad and Tobago Dollar", "symbol": "TT$"},
    {"code": "UGX", "name": "Ugandan Shilling", "symbol": "USh"},
    {"code": "UYU", "name": "Uruguayan Peso", "symbol": "$U"},
    {"code": "UZS", "name": "Uzbekistani Som", "symbol": "лв"},
    {"code": "VEF", "name": "Venezuelan Bolívar", "symbol": "Bs"},
    {"code": "XOF", "name": "West African CFA Franc", "symbol": "CFA"},
    {"code": "ZMW", "name": "Zambian Kwacha", "symbol": "ZK"}
];