/**
 * Group the array elements based on the provided criteria (key)
 * @param {*} arr
 * @param {*} criteria
 * @returns grouped array with criteria as the key
 */
export const groupBy = (arr, criteria) => {
  if (arr?.length) {
    return arr.reduce((obj, item) => {
      const key = item[criteria];
      // If the key doesn't exist yet, create it
      if (!obj.hasOwnProperty(key)) obj[key] = [];
      // Push the value to the object
      obj[key].push(item);
      // Return the object to the next item in the loop
      return obj;
    }, {});
  }
};

export function range(start, stop, step = 1) {
  return Array.from(
    { length: (stop - start) / step + 1 },
    (value, i) => start + i * step
  );
}
