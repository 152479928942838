import axios from "axios";

const url = process.env.REACT_APP_BASE_URL.concat("pattern_series/overlays");

export const getOverlays = (payload) => async () => {
  try {
    const { data } = await axios.get(`${url}`, {
      params: new URLSearchParams(payload),
    });
    return data;
  } catch (error) {
    throw error;
  }
};
