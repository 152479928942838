import { IconButton, Stack, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { colors, typography } from "../../../shared/theme-constants";
import Icon from "../../common/Icon";
import { LightTooltip } from "../../common/mui-wrapper-components/LightTooltip";
import MetricTableData from "./MetricTableData";
import styled from "@emotion/styled";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "var(--comet)",
  fontSize: "1.25rem",
  fontWeight: typography.font_weight.regular,
}));

const MetricTable = ({ kpiGroup, insightID }) => {
  const [metricTableData, setmetricTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let filteredInsights = [];
    for (let i = 0; i < kpiGroup.length; i++) {
      let kpiKey = kpiGroup[i].kpi_id;
      for (let j = 0; j < kpiGroup[i].timeframes.length; j++) {
        let key = `${kpiKey}_${kpiGroup[i].timeframes[j].dim_name}_${kpiGroup[i].timeframes[j].dim_val}`;
        const existingItem = filteredInsights.find((item) => item.key === key);
        if (
          !existingItem ||
          kpiGroup[i].timeframes[j].timestamp > existingItem.item.timestamp
        ) {
          const newItem = { key, item: kpiGroup[i].timeframes[j] };
          if (existingItem) {
            filteredInsights[filteredInsights.indexOf(existingItem)] = newItem;
          } else {
            filteredInsights.push(newItem);
          }
        }
      }
    };
    setFilteredData([...filteredInsights]);
    kpiGroup.map((group, index) => {
      setmetricTableData((maticData) => [
        ...maticData,
        {
          matricName: group.kpi_display_name,
          matricTimeFrame: group.timeframes[0],
        },
      ]);
    });
  }, []);

  return (
    <div>
      <>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
            width: "90%",
            marginInline: "auto",
            marginBottom: "1rem",
            maxHeight: "25.5rem",
          }}
        >
          <Table
            sx={{
              minWidth: "650",
              border: "none",
              borderCollapse: "separate",
              borderSpacing: "0px 2px",
            }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead sx={{ border: "none", boxShadow: "none" }}>
              <TableRow>
                <TableCell align="left" width="30%" sx={{ pl: 0, border: "none" }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <StyledTypography>Metric</StyledTypography>
                    <LightTooltip
                      fontSize="0.625rem"
                      title={
                        <>
                          <span>
                            Metrics are grouped by accent, duration & category/funnel and are sorted
                            by importance & impact.
                          </span>
                          <br></br>
                          <em style={{ color: colors.gray[450] }}>Click Metrics for Detail.</em>
                        </>
                      }
                      placement="right"
                      width="10rem"
                    >
                      <IconButton>
                        <Icon name="tooltip_info" size="0.6875rem" />
                      </IconButton>
                    </LightTooltip>
                  </Stack>
                </TableCell>
                <TableCell align="left" width="30%" sx={{ pl: 0, border: "none" }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <StyledTypography>Dimensions</StyledTypography>
                    <LightTooltip
                      fontSize="0.625rem"
                      title={
                        <>
                          <span>It indicate the primary dimensional</span>
                          <span>value that contributed towards</span>
                          <span>metric movements.</span>
                        </>
                      }
                      placement="right"
                    >
                      <IconButton>
                        <Icon name="tooltip_info" size="0.6875rem" />
                      </IconButton>
                    </LightTooltip>
                  </Stack>
                </TableCell>
                <TableCell align="center" width="30%" sx={{ pl: 0, border: "none" }}>
                  <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                    <StyledTypography>Sparkline</StyledTypography>
                    <LightTooltip
                      fontSize="0.625rem"
                      title={
                        <>
                          <span>It indicates percent difference</span>
                          <span>between Out Of The Blue</span>
                          <span>forecasts and actual value.</span>
                        </>
                      }
                      placement="right"
                    >
                      <IconButton>
                        <Icon name="tooltip_info" size="0.6875rem" />
                      </IconButton>
                    </LightTooltip>
                  </Stack>
                </TableCell>
                <TableCell align="center" width="10%" sx={{ border: "none" }}>
                  <StyledTypography>{null}</StyledTypography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ border: "none" }}>
              {metricTableData &&
                filteredData.map((group, index) => (
                  <MetricTableData
                    key={index}
                    insightId={group.item.insight_id}
                    filteredData={filteredData}
                    group={group}
                    insightID={insightID}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </div>
  );
};

export default MetricTable;
