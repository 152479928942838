import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Container, Grid, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  add,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  format,
  formatISO,
  isThisMonth,
  isThisQuarter,
  isThisWeek,
  isWithinInterval,
  parseISO,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  sub
} from "date-fns";
import pluralize from "pluralize";
import React, { useEffect, useReducer, useState } from "react";
import ContentLoader from "react-content-loader";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getInsightSummary,
  getInsightSummaryForTimeSeries,
} from "../actions/insightSummaryActions";
import InsightHistogram from "../components/collaborate/InsightHistogram";
import EventDollarImpactChip from "../components/common/EventDollarImpactChip";
import Icon from "../components/common/Icon";
import ListLoader from "../components/common/ListLoader";
import LiveIndicator from "../components/common/LiveIndicator";
import MetricImpact from "../components/common/MetricImpact";
import ToggleButtonWithSideButtonsGroup, {
  SideButton,
} from "../components/common/ToggleButtonWithSideButtonsGroup";
import StyledTabs from "../components/common/mui-wrapper-components/StyledTabs";
import BusinessHealthTable from "../components/digest/BusinessHealthTable";
import DigestCard from "../components/digest/DigestCard";
import EventSummaryCard from "../components/digest/EventSummaryCard";
import MetricTimeSeriesChart from "../components/digest/MetricTimeSeriesChart";
import MetricsPanelCard from "../components/digest/MetricsPanel";
import StoriesTable from "../components/digest/StoriesTable";
import { SET_COLLABORATE_TIMESTAMP } from "../constants/filterCriteriaConstants";
import { useAxios } from "../hooks/useAxios";
import { useSelectedTenant } from "../hooks/useSelectedTenant";
import { colors, typography } from "../shared/theme-constants";
import { localToUTC } from "../utils/dateUtils";
import { isEmpty } from "../utils/is";
import { getNumberWithOrdinal } from "../utils/numberUtils";
import { titleCase } from "../utils/stringUtils";
import EventSummary from "../components/Events/components/EventSummary";
import { SAAS_EVENT_SUMMARY_FEATURE_TOGGLE } from "../constants/featureFlagConstants";
import { isEnabled } from "../utils/featureFlags";
import moment from "moment-timezone";

const CardState = ({ status, loadingElement, errorElement, children }) => {
  if (status === "error") {
    return errorElement;
  }
  if (status === "loading") {
    return loadingElement;
  }
  if (status === "success") {
    return children;
  }

  return null;
};

const MetricValueSkeleton = () => (
  <ContentLoader
    speed={2}
    width={120}
    height={64}
    viewBox="0 0 120 64"
    foregroundOpacity={0.4}
    backgroundOpacity={0.8}
    backgroundColor={colors.gray[100]}
  >
    <rect x="0" y="12" rx="5" ry="5" width="120" height="40" />
  </ContentLoader>
);

const SeeMoreLink = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: typography.font_weight.bold,
  textDecoration: "underline",
  fontSize: typography.font_size.xl,
}));

const SummaryHeader = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5, 9),
  backgroundColor: colors.blue[925],
  borderRadius: "10px",
}));

const StatsTitle = styled("div")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(20),
  color: "#C8D5F4",
  maxWidth: "12rem",
  lineHeight: "126.5%",
  fontWeight: typography.font_weight.mediumx,
  fontStyle: "normal",
}));

const StatsValue = styled("span")(({ theme }) => ({
  color: "#fff",
  fontSize: theme.typography.pxToRem(45),
  fontWeight: typography.font_weight.bold,
}));

const DigestHighlight = styled("div")(({ theme }) => ({
  color: "#fff",
  fontSize: theme.typography.pxToRem(42),
  fontWeight: typography.font_weight.bold,
  fontStyle: "normal",
  lineHeight: theme.typography.pxToRem(48),
}));

const DigestDuration = styled("span")(({ theme }) => ({
  color: "#C8D5F4",
  fontSize: theme.typography.pxToRem(22),
  fontWeight: typography.font_weight.bold,
  fontStyle: "normal",
  lineHeight: "normal",
  marginTop: theme.spacing(10),
}));

const MetricValuesContainer = styled(Stack)(({ theme }) => ({
  "& > div": {
    borderLeft: "var(--border-width) solid #29409F",
    borderRight: "var(--border-width) solid #29409F",
  },

  "div + div": {
    borderLeft: 0,
  },

  [theme.breakpoints.up("sl")]: {
    "--border-width": "6px",
  },
  [theme.breakpoints.up("xll")]: {
    "--border-width": "8px",
  },
}));

const CardMessage = styled("span")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(22),
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: theme.typography.pxToRem(48.6),
  color: colors.title
}));

const ErrorCardContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  marginTop: "18px",
}));

const MetricError = ({ errorMessage }) => (
  <ErrorCardContainer>
    <Icon justifyContent="center" name="NorthStarMetric" />
    <CardMessage sx={
      {
        marginTop: "16px"
      }
    }>
      {errorMessage ? errorMessage : "Unable to get data for North Star Metric"}
    </CardMessage>
  </ErrorCardContainer>
);

const EventSummaryError = ({ errorMessage }) => {
  <ErrorCardContainer>
    <CardMessage
      sx={{
        marginTop: "16px",
      }}
    >
      {errorMessage ? errorMessage : "Unable to get data for Events Summary"}
    </CardMessage>
  </ErrorCardContainer>;
};

const StyledLinkInfo = styled("div")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: typography.font_weight.semiBold,
  lineHeight: 1.5,
  color: colors.popper_font_color,
  marginTop: theme.spacing(3),
}));

const StyledLinkContainer = styled(Paper)({
  boxShadow: "0px 3px 24px rgba(0, 0, 0, 0.15)",
  textAlign: "center",
  borderRadius: 10,
  width: 60,
  marginLeft: "auto",

  "&:hover": {
    boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.15)",
  },
});

const StyledAnchor = styled("a")({
  display: "inline-flex",
  padding: "10px 6px",
  textDecoration: "none",
  color: colors.gray[400],
});

const PIPELINE_OPTIONS = [
  { value: "week", label: "Weekly", frequency: "w", checkDateFunction: (date) => isThisWeek(date, { weekStartsOn: 0 }) },
  { value: "month", label: "Monthly", frequency: "m", checkDateFunction: (date) => isThisMonth(date) },
  { value: "quarter", label: "Quarterly", frequency: "q", checkDateFunction: (date) => isThisQuarter(date) },
];

const PIPELINE_OPTIONS_WITH_QUARTER_DISABLED = [
  { value: "week", label: "Weekly" },
  { value: "month", label: "Monthly" },
  { value: "quarter", label: "Quarterly", disabled: true },
];

const DISPLAY_TYPE_OPTIONS = [
  { value: "time", label: "Time" },
  { value: "impact", label: "Impact" },
];

const DISPLAY_TYPE_OPTIONS_WITH_IMPACT_DISABLED = [
  { value: "time", label: "Time" },
  { value: "impact", label: "Impact", disabled: true },
];

const PERIOD_TO_DURATION = {
  week: { weeks: 1 },
  month: { months: 1 },
  quarter: { months: 3 },
};

const PERIOD_TO_OVERLAY_MAPPING = {
  week: {
    overlay: "WoW",
    frequency: "d"
  },
  month: {
    overlay: "MoM",
    frequency: "d"
  },
  quarter: {
    overlay: "QoQ",
    frequency: "w"
  }
};

const DEFAULT_TIMEZONE = "Etc/UTC";

const METRIC_TABS = [
  { value: "top_metric", label: "Top Metrics", preference_name: "TOP_METRICS_PREFERENCE", scope: "tenant" },
  { value: "my_metrics", label: "My Metrics", preference_name: "MY_METRICS_PREFERENCE", scope: "user" },
];

const MAX_EVENTS_TO_SHOW_ON_SUMMARY = 3;

function formatDate(startDate, endDate, frequency) {
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();
  const startMonth = format(startDate, "LLL");

  if (frequency === "week") {
    const endMonth = format(endDate, "LLL");
    const endMonthDisplayValue = startMonth !== endMonth ? endMonth : "";
    const startYearDisplayValue = startYear !== endYear ? startYear : "";
    const endYearDisplayValue = startYear !== endYear ? endYear : "";

    return `Week of ${startYearDisplayValue} ${startMonth} ${getNumberWithOrdinal(startDate.getDate())} to ${endYearDisplayValue} ${endMonthDisplayValue} ${getNumberWithOrdinal(endDate.getDate())}`;
  }

  const quarter = Math.floor(endDate.getMonth() / 3) + 1;

  if (frequency === "month") {
    return `${startMonth} ${endYear}`;
  }

  if (frequency === "quarter") {
    return `${getNumberWithOrdinal(quarter)} Quarter of ${startYear}`;
  }
}

const DigestScreen = () => {
  const navigate = useNavigate();
  const NUMBER_OF_STORIES = 5;
  const dispatch = useDispatch();
  const selectedTenant = useSelectedTenant();
  const [selectedPipelineFrequency, setSelectedPipelineFrequency] =
    useState("week");
  const defaultStartDate = useSelector((state) => state.digestScreenTimeFilter.start_time);
  const tenantConfigs = useSelector((state) => state.tenantConfigs.configs);
  const tenantGlobalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);
  const [startDate, setStartDate] = useState(startOfWeek(sub(localToUTC(defaultStartDate[selectedPipelineFrequency]), { week: 1 }), { weekStartsOn: 0 }));
  const [previousPeriodStartDate, setPreviousPeriodStartDate] = useState(startOfWeek(sub(localToUTC(defaultStartDate[selectedPipelineFrequency]), { weeks: 1 }), { weekStartsOn: 0 }));
  const [endDate, setEndDate] = useState(endOfWeek(sub(localToUTC(defaultStartDate[selectedPipelineFrequency]), { week: 1 }), { weekStartsOn: 0 }));
  const [dataPoints, setDataPoints] = useState();
  const [generatedInsightsCount, setGeneratedInsightsCount] = useState();
  const [metricTab, setMetricTab] = useState(METRIC_TABS[0].value);
  const [sectionSortBy, setSectionSortBy] = useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    {
      live: "time",
      developed: "time",
    }
  );
  let isEventSummaryEnabled = isEnabled(SAAS_EVENT_SUMMARY_FEATURE_TOGGLE);

  const [metricsOverviewTab, setMetricsOverviewTab] = useReducer((prevState, newState) => ({ ...prevState, ...newState }),
    {
      name: "TOP_METRICS_PREFERENCE",
      scope: "tenant",
    });

  const baseGroupsParams = {
    tenant_id: selectedTenant,
    start_time: formatISO(startDate, { representation: "date" }),
    end_time: formatISO(endDate, { representation: "date" }),
    count: NUMBER_OF_STORIES,
  };

  const { data: metricsOverviewData, status: metricsOverviewStatus } = useAxios(
    "preferences",
    {
      params: {
        tenant_id: selectedTenant,
        name: metricsOverviewTab.name,
        scope: metricsOverviewTab.scope
      },
    }
  );
  const { data: liveGroupsData, status: liveGroupsStatus } = useAxios("digest/stories", {
    params: {
      ...baseGroupsParams,
      status: "active",
      sort: sectionSortBy.live,
      dim_name: tenantGlobalFilter?.dimension_name,
      dim_val: tenantGlobalFilter?.dimension_value,
    },
  });
  const { data: developedGroupsData, status: developedGroupsStatus } = useAxios("digest/stories", {
    params: {
      ...baseGroupsParams,
      status: "inferred",
      sort: sectionSortBy.developed,
      dim_name: tenantGlobalFilter?.dimension_name,
      dim_val: tenantGlobalFilter?.dimension_value,
    },
  });

  const { data: northStartMetricData, status: northStartMetricStatus } = useAxios(
    "metrics_hub/metrics/north_star",
    {
      params: {
        tenant_id: selectedTenant
      },
    }
  );

  // Handels the tab value which is selected on Digest page for metrics
  const handleTabClick = (event, newValue) => {
    setMetricTab(newValue);
  };

  function setStartAndEndDates(frequency, date) {
    // Check if type of date is string, if yes, parse it to Date object
    if (typeof date === "string") {
      date = localToUTC(parseISO(date));
    } else if (typeof date === "object" && !(date instanceof Date)) {
      // Check if date is key-value object and not a date object, if yes, use the object to set the date based on frequency
      date = localToUTC(parseISO(date[frequency]));
    }
    if (frequency === "week") {
      const startDate = startOfWeek(date, { weekStartsOn: 0 });
      setStartDate(startOfWeek(date, { weekStartsOn: 0 }));
      setEndDate(endOfWeek(date, { weekStartsOn: 0 }));
      setPreviousPeriodStartDate(startOfWeek(sub(startDate, { weeks: 1 }), { weekStartsOn: 0 }));
    }
    if (frequency === "month") {
      const startDate = startOfMonth(date);
      setStartDate(startOfMonth(date));
      setEndDate(endOfMonth(date));
      setPreviousPeriodStartDate(startOfMonth(sub(startDate, { months: 1 })));
    }
    if (frequency === "quarter") {
      const startDate = startOfQuarter(date);
      setStartDate(startOfQuarter(date));
      setEndDate(endOfQuarter(date));
      setPreviousPeriodStartDate(startOfQuarter(sub(startDate, { months: 3 })));
    }
  }

  function getImpactEndDate() {
    const selectedPipeline = PIPELINE_OPTIONS.find(item => item.value === selectedPipelineFrequency);
    if (selectedPipeline && selectedPipeline.checkDateFunction(startDate)) {
      return moment.tz(DEFAULT_TIMEZONE).format('YYYY-MM-DD');
    }
    return formatISO(endDate, { representation: "date" });
  }

  function handleChange(_event, selectedOption) {
    setSelectedPipelineFrequency(selectedOption);
    setStartAndEndDates(selectedOption, defaultStartDate);

    // reset header values
    setDataPoints(null);
    setGeneratedInsightsCount(null);
  }

  function handlePrevClick(selectedOption) {
    const newEndDate = sub(endDate, PERIOD_TO_DURATION[selectedOption]);
    setStartAndEndDates(selectedOption, newEndDate);
  }

  function handleNextClick(selectedOption) {
    const newEndDate = add(endDate, PERIOD_TO_DURATION[selectedOption]);
    setStartAndEndDates(selectedOption, newEndDate);
  }

  function getRelativeTimePeriodString(startDate, endDate, frequency) {
    if (frequency === "week") {
      if (isWithinInterval(localToUTC(new Date()), { start: startDate, end: endDate })) {
        return "This ";
      } else if (
        isWithinInterval(sub(localToUTC(new Date()), { weeks: 1 }), { start: startDate, end: endDate })
      ) {
        return "Last ";
      }
      return "Previous ";
    } else if (frequency === "month") {
      if (isWithinInterval(localToUTC(new Date()), { start: startDate, end: endDate })) {
        return "This ";
      } else if (
        isWithinInterval(sub(localToUTC(new Date()), { months: 1 }), { start: startDate, end: endDate })
      ) {
        return "Last ";
      }
      return "Previous ";
    } else if (frequency === "quarter") {
      if (isWithinInterval(localToUTC(new Date()), { start: startDate, end: endDate })) {
        return "This ";
      } else if (
        isWithinInterval(sub(localToUTC(new Date()), { months: 3 }), { start: startDate, end: endDate })
      ) {
        return "Last ";
      }
      return "Previous ";
    }
  }

  useEffect(() => {
    let isMounted = true;
    dispatch({
      type: SET_COLLABORATE_TIMESTAMP,
      payload: {
        start_time: formatISO(startDate, { representation: "date" }),
        end_time: formatISO(endDate, { representation: "date" }),
      },
    });
    if (isMounted) {
      dispatch(
        getInsightSummaryForTimeSeries({
          tenant_id: selectedTenant,
          start_time: formatISO(startDate, { representation: "date" }),
          end_time: formatISO(endDate, { representation: "date" }),
          dim_name: tenantGlobalFilter?.dimension_name,
          dim_val: tenantGlobalFilter?.dimension_value,
        })
      ).then((response) => {
        if (response) {
          setDataPoints(response.time_series_analysed ?? "N/A");
        }
      });

      dispatch(
        getInsightSummary(
          "patterns",
          selectedTenant,
          formatISO(startDate, { representation: "date" }),
          formatISO(endDate, { representation: "date" }),
          tenantGlobalFilter?.dimension_name,
          tenantGlobalFilter?.dimension_value,
        )
      ).then((response) => {
        if (response) {
          setGeneratedInsightsCount(response.count ?? "N/A");
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [dispatch, endDate, selectedTenant, startDate, tenantGlobalFilter]);

  const noLiveStories =
    liveGroupsStatus === "success" && isEmpty(liveGroupsData);
  const noDevelopedStories =
    developedGroupsStatus === "success" && isEmpty(developedGroupsData);

  const pipelineOptions =
    sectionSortBy.live === "impact" || sectionSortBy.developed === "impact"
      ? PIPELINE_OPTIONS_WITH_QUARTER_DISABLED
      : PIPELINE_OPTIONS;
  const displayTypeOptions =
    selectedPipelineFrequency === "quarter"
      ? DISPLAY_TYPE_OPTIONS_WITH_IMPACT_DISABLED
      : DISPLAY_TYPE_OPTIONS;

  return (
    <Box bgcolor="#E5ECFD" minHeight="100vh">
      <Container maxWidth="xll" sx={{ py: 10, px: { sm: 4, sl: 6, xll: 8 } }}>
        <Grid container rowGap={8}>
          <SummaryHeader container gap={4}>
            <Grid item sm={3} gap={5}>
              <Stack>
                <DigestHighlight>
                  {getRelativeTimePeriodString(startDate, endDate, selectedPipelineFrequency)}
                  <span className="capitalize">
                    {selectedPipelineFrequency}
                  </span>
                  <br />
                  in Review
                </DigestHighlight>
                <DigestDuration>
                  {formatDate(startDate, endDate, selectedPipelineFrequency)}
                </DigestDuration>
              </Stack>
            </Grid>

            {tenantConfigs?.is_forecast_enabled ? (
              <Grid item alignSelf="center">
                <MetricValuesContainer direction="row">
                  <Stack
                    sx={(theme) => ({
                      [theme.breakpoints.up("lt")]: { px: 4 },
                      [theme.breakpoints.up("xll")]: { px: 12 },
                    })}
                  >
                    <StatsTitle>Insights Generated:</StatsTitle>
                    {generatedInsightsCount === undefined ? (
                      <MetricValueSkeleton />
                    ) : (
                      <StatsValue>{generatedInsightsCount}</StatsValue>
                    )}
                  </Stack>

                  <Stack
                    sx={(theme) => ({
                      [theme.breakpoints.up("sl")]: { pl: 4, pr: 2 },
                      [theme.breakpoints.up("xll")]: { pl: 12, pr: 10 },
                    })}
                  >
                    <StatsTitle>Datapoints Analyzed:</StatsTitle>
                    {dataPoints === undefined ? (
                      <MetricValueSkeleton />
                    ) : (
                      <StatsValue>{dataPoints}</StatsValue>
                    )}
                  </Stack>
                </MetricValuesContainer>
              </Grid>
            ) : <></>}

            <Grid item ml="auto" alignSelf="center">
              <ToggleButtonWithSideButtonsGroup
                value={selectedPipelineFrequency}
                options={pipelineOptions}
                onChange={handleChange}
                prevButton={<SideButton onClick={handlePrevClick} />}
                nextButton={
                  <SideButton
                    // Disable the SideButton if the last_digest_ts for the selected frequency is exactly between the start and end date, both included
                    disabled={endDate.valueOf() >= localToUTC(new Date()).valueOf() && startDate.valueOf() <= localToUTC(new Date()).valueOf()}
                    onClick={handleNextClick}
                  />
                }
              />
            </Grid>
          </SummaryHeader>

          <Grid container columnSpacing={8}>
            <Grid item sm={12} tm={6}
              sx={{
                minHeight: "200px"
              }}
            >
              <DigestCard
                title={
                  <Stack direction="row" sx={{ alignItems: "center" }}>
                    North Star Metric
                    <Tooltip
                      title={`Calculated from our analysis and insights on your ${northStartMetricData?.kpi_display_name ? northStartMetricData?.kpi_display_name : "North Star Metric"}`}
                      placement="right-start"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "var(--new-car)", color: "var(--white)"
                          }
                        }
                      }}
                    >
                      <InfoOutlinedIcon sx={{ ml: 1.5 }} fontSize="medium" color="var(--resolution-blue)" />
                    </Tooltip>
                  </Stack>
                }
                sx={{ minHeight: "100%" }}
              >
                <CardState
                  status={northStartMetricStatus}
                  errorElement={
                    <MetricError />
                  }
                  sx={{ minHeight: "100%" }}
                  loadingElement={
                    <ListLoader
                      count={1}
                      height={200}
                      paddingY={10}
                    />
                  }
                >
                  {northStartMetricData?.kpi_id &&
                    <Box sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      position: "absolute",
                      right: 0,
                      top: 0,
                      marginTop: 5,
                      marginRight: 5
                    }}>
                      {/* <StyledLinkInfo>
                        View Metric Details
                      </StyledLinkInfo>
                      <StyledLinkContainer
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: 6,
                        }}
                      >
                        <StyledAnchor
                          href={`/metrics/${northStartMetricData?.kpi_id}`}
                          target="_blank"
                        >
                          <ArrowForwardIcon />
                          <div className="sr-only"></div>
                        </StyledAnchor>
                      </StyledLinkContainer> */}
                    </Box>
                  }
                  {northStartMetricData?.kpi_display_name && (
                    <Stack>
                      <Typography sx={{ fontSize: 30, color: "var(--comet)" }}>{northStartMetricData?.kpi_display_name}</Typography>
                      <Stack sx={{ display: "flex", flexDirection: "column", height: 270, width: "100%", justifyContent: "center" }} gap={4}>
                        <MetricImpact
                          kpi_id={northStartMetricData?.kpi_id}
                          pipeline_schedule={PIPELINE_OPTIONS.find(element => element.value == selectedPipelineFrequency).frequency}
                          timestamp_start={formatISO(startDate, { representation: "date" })}
                          timestamp_end={getImpactEndDate()}
                        >
                        </MetricImpact>
                        <MetricTimeSeriesChart
                          startDate={formatISO(startDate, { representation: "date" })}
                          previousPeriodstartDate={formatISO(previousPeriodStartDate, { representation: "date" })}
                          endDate={formatISO(endDate, { representation: "date" })}
                          patternData={{
                            pattern: {
                              kpi_format: northStartMetricData?.kpi_format,
                              tenant_id: selectedTenant,
                              kpi_name: northStartMetricData?.kpi_name,
                              kpi_id: northStartMetricData?.kpi_id,
                              kpi_display_name: northStartMetricData?.kpi_display_name,
                              accent: northStartMetricData?.accent,
                              pipeline_schedule: PERIOD_TO_OVERLAY_MAPPING[selectedPipelineFrequency]['frequency']
                            }
                          }}
                          overlayType={PERIOD_TO_OVERLAY_MAPPING[selectedPipelineFrequency]['overlay']}
                          metricError={MetricError}
                          isForecastEnabled={tenantConfigs?.is_forecast_enabled}
                          tenantGlobalFilter={tenantGlobalFilter}
                          showPreviousPeriodComparison
                          selectedPipelineFrequency={selectedPipelineFrequency}
                        />
                      </Stack>
                    </Stack>
                  )}
                </CardState>
              </DigestCard>
            </Grid>

            <Grid item sm={12} tm={6} sx={{ minHeight: "250px" }}>
              {tenantConfigs?.is_forecast_enabled ? (
                <DigestCard sx={{ minHeight: "100%" }}>
                  <BusinessHealthTable
                    tenant_id={selectedTenant}
                    pipelineSchedule={selectedPipelineFrequency[0]}
                    startDate={startDate}
                    endDate={endDate}
                    sx={{ minHeight: "80px" }}
                  />
                </DigestCard>
              ) : (
                <DigestCard title="Business Health" sx={{ minHeight: "100%" }}>
                  <CardState
                    status="success"
                    sx={{ minHeight: "100%" }}
                    loadingElement={<ListLoader count={2} height={80} paddingY={10} />}
                  >
                    <Box
                      sx={(theme) => ({
                        minHeight: 300,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      })}
                    >
                      <Stack direction="column" gap={4} alignItems="center">
                        <img width="100%" src={`/images/feature_disabled.png`} />
                        <Typography>
                          This analysis activates after forecasting is enabled.
                        </Typography>
                      </Stack>
                    </Box>
                  </CardState>
                </DigestCard>
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sm={12}>
              <DigestCard title="Metrics Overview">
                <Stack gap={4}>
                  <StyledTabs value={metricTab} options={METRIC_TABS} onChange={(_event, selectedValue) => {
                    setMetricTab(selectedValue);
                    setMetricsOverviewTab({
                      name: METRIC_TABS.find((element) => element.value === selectedValue).preference_name,
                      scope: METRIC_TABS.find((element) => element.value === selectedValue).scope
                    });
                  }}
                  />
                  <CardState
                    status={metricsOverviewStatus}
                    loadingElement={<ListLoader height={160} paddingY={10} />}
                  >
                    <Grid container spacing={6} sx={{ justifyContent: "space-between" }}>
                      {
                        metricsOverviewData?.preference_value?.kpis ? (
                          metricsOverviewData?.preference_value?.kpis?.map((metric) => (
                            <Grid item sm={3} key={metric}>
                              <MetricsPanelCard
                                metricId={metric}
                                startDate={formatISO(startDate, { representation: "date" })}
                                endDate={formatISO(endDate, { representation: "date" })}
                                impactEndDate={getImpactEndDate()}
                                pipeline_schedule={PERIOD_TO_OVERLAY_MAPPING[selectedPipelineFrequency]["frequency"]}
                                impact_pipeline_schedule={PIPELINE_OPTIONS.find(element => element.value == selectedPipelineFrequency).frequency}
                                isForecastEnabled={tenantConfigs?.is_forecast_enabled}
                                tenantGlobalFilter={tenantGlobalFilter}
                                previousPeriodstartDate={formatISO(previousPeriodStartDate, { representation: "date" })}
                                showPreviousPeriodComparison
                                selectedPipelineFrequency={selectedPipelineFrequency}
                              />
                            </Grid>
                          ))
                        ) : (
                          <Stack direction="row" height={100} width="100%" sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center"
                          }}>
                            <Typography>
                              No {METRIC_TABS.find((tab) => tab.value == metricTab)?.label} configured. Please configure it from Preferences screen
                            </Typography>
                          </Stack>
                        )
                      }
                    </Grid>
                  </CardState>
                </Stack>
              </DigestCard>
            </Grid>
          </Grid>

          {isEventSummaryEnabled ? (
            <Grid item sm={12}>
              <DigestCard  title="Events Summary" sx={{ display: "flex", flexDirection: "column",  gap: 3}}>
                <EventSummary
                  startTime={startDate}
                  endTime={endDate}
                  pipelineSchedule={PIPELINE_OPTIONS.find(pipeline => pipeline.value === selectedPipelineFrequency).frequency}
                  pageType="digest"
                />
            </DigestCard>
           </Grid>
          ) : (
            <></>
          )}

          {tenantConfigs.is_forecast_enabled ?
            <>
              {!(noLiveStories && noDevelopedStories) &&
                <Grid container>
                  <Grid item sm={12}>
                    <DigestCard title="Insight History">
                      <InsightHistogram
                        tenantId={selectedTenant}
                        sx={{ margin: "10px 0" }}
                        // this is hotfix for disabling histogram bar clicks
                        onBarClick={() => { }}
                      />
                    </DigestCard>
                  </Grid>
                </Grid>
              }

              {!(noLiveStories) &&
                <Grid container>
                  <Grid item sm={12}>
                    <DigestCard
                      title={
                        <Stack direction="row" alignItems="center" gap={8}>
                          <span>
                            {noLiveStories ? `` : `Top ${liveGroupsData && liveGroupsData.length != 0 ? liveGroupsData.length : ''} Live ${liveGroupsData && liveGroupsData.length == 1 ? 'Story' : 'Stories'} to Focus on`}
                          </span>
                          {noLiveStories ? (
                            <CardMessage>
                              No Live Stories Available For
                              {selectedPipelineFrequency === "month"
                                ? " The Month of "
                                : " The "}
                              {formatDate(
                                startDate,
                                endDate,
                                selectedPipelineFrequency
                              )}
                            </CardMessage>
                          ) : liveGroupsStatus !== "error" ? (
                            <>
                              {/* Commented as part of DEV-4592
                            <ToggleButtonGroup
                              value={sectionSortBy.live}
                              options={displayTypeOptions}
                              onChange={(_event, selectedValue) =>
                                setSectionSortBy({ live: selectedValue })
                              }
                            /> */}
                              <LiveIndicator
                                sx={{ marginLeft: "auto", fontSize: "1.125rem" }}
                              />
                            </>
                          ) : null}
                        </Stack>
                      }
                      subtitle={
                        liveGroupsStatus !== "success" || noLiveStories
                          ? null
                          : "Based on the data provided, we perceive these following to be the most impactful to business"
                      }
                    >
                      <Container
                        maxWidth={false}
                        disableGutters
                        /* Reason for assigning this margin conditionally is that we want margin
                        * in all states (error, loading, when data present).
                        * We don't need it when data is fetched but not present, hence conditional sx
                        */
                        sx={noLiveStories ? {} : { mt: 4 }}
                      >
                        <CardState
                          status={liveGroupsStatus}
                          errorElement={
                            <CardMessage>
                              Encountered an error while fetching stories
                            </CardMessage>
                          }
                          loadingElement={
                            <ListLoader
                              count={3}
                              height={30}
                              paddingY={3}
                            />
                          }
                        >
                          <StoriesTable rows={liveGroupsData} />
                        </CardState>
                      </Container>

                      {/* <Box className="text-right" mt={6}>
                  <SeeMoreLink to="/feed?status=active">See more</SeeMoreLink>
                </Box> */}
                    </DigestCard>
                  </Grid>
                </Grid>
              }

              {!(noDevelopedStories) &&
                <Grid container>
                  <Grid item sm={12}>
                    <DigestCard
                      title={
                        <Stack direction="row" alignItems="center" gap={8}>
                          <span>
                            {noDevelopedStories ? `` : `Top ${developedGroupsData && developedGroupsData.length != 0 ? developedGroupsData.length : ''} ${developedGroupsData && developedGroupsData.length == 1 ? 'Story' : 'Stories'} to Focus on`}
                          </span>
                          {noDevelopedStories ? (
                            <CardMessage>
                              No Developed Stories Available For
                              {selectedPipelineFrequency === "month"
                                ? " The Month of "
                                : " The "}
                              {formatDate(
                                startDate,
                                endDate,
                                selectedPipelineFrequency
                              )}
                            </CardMessage>
                          )
                            // Commented as part of DEV-4592
                            // : developedGroupsStatus !== "error" ? (
                            //   <ToggleButtonGroup
                            //     value={sectionSortBy.developed}
                            //     options={displayTypeOptions}
                            //     onChange={(_event, selectedValue) =>
                            //       setSectionSortBy({ developed: selectedValue })
                            //     }
                            //   />
                            // )
                            : null}
                        </Stack>
                      }
                      subtitle={
                        developedGroupsStatus !== "success" || noDevelopedStories
                          ? null
                          : "Based on the data provided, we perceive these following to be the most impactful to business"
                      }
                    >
                      <Container
                        maxWidth={false}
                        disableGutters
                        sx={noDevelopedStories ? {} : { mt: 4 }}
                      >
                        <CardState
                          status={developedGroupsStatus}
                          errorElement={
                            <CardMessage>
                              Encountered an error while fetching stories
                            </CardMessage>
                          }
                          loadingElement={
                            <ListLoader
                              count={3}
                              height={30}
                              paddingY={3}
                            />
                          }
                        >
                          <StoriesTable rows={developedGroupsData} />
                        </CardState>
                      </Container>

                      {/* <Box className="text-right" mt={6}>
                  <SeeMoreLink to="/feed?status=inferred">
                    See more
                  </SeeMoreLink>
                </Box> */}
                    </DigestCard>
                  </Grid>
                </Grid>
              }
            </> :
            <></>}
        </Grid>
      </Container>
    </Box>
  );
};

export default DigestScreen;
