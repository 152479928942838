export const trendPredictionConfig = {
  mode: "lines",
  line: {
    width: 2,
  },
};

// Dashdot trendPredictionConfig
export const trendPredictionDashdotConfig = {
  mode: "lines",
  line: {
    width: 2,
    dash: 'dashdot',
  },
};
