import {
  TEAM_INSIGHT_ACTION_CANCEL,
  TEAM_INSIGHT_ACTION_FAILED,
  TEAM_INSIGHT_ACTION_INITIATED,
  TEAM_INSIGHT_ACTION_REQUESTED,
  TEAM_INSIGHT_ACTION_SUCCESS,
} from "../constants/teamConstants";

export const teamReducer = (
  state = { showTeamControl: false, selectedInsights: [] },
  action
) => {
  switch (action.type) {
    case TEAM_INSIGHT_ACTION_INITIATED:
      return {
        showTeamControl: true,
        loading: false,
        selectedInsights: action.selectedInsights,
      };

    case TEAM_INSIGHT_ACTION_CANCEL:
      return {
        showTeamControl: false,
        loading: false,
        selectedInsights: action.selectedInsights,
      };
    case TEAM_INSIGHT_ACTION_REQUESTED:
      return {
        showTeamControl: true,
        loading: true,
        selectedInsights: action.selectedInsights,
      };
    case TEAM_INSIGHT_ACTION_SUCCESS:
      return {
        showTeamControl: false,
        loading: false,
        selectedInsights: [],
      };
    case TEAM_INSIGHT_ACTION_FAILED:
      return {
        showTeamControl: false,
        loading: false,
        selectedInsights: [],
        error: action.payload
      };
    default:
      return state;
  }
};
