
export const COLUMN_META_DATA_LOADING = 'column_meta_data_loading';
export const COLUMN_META_DATA_SUCESS = 'column_meta_data_sucess';
export const COLUMN_META_DATA_ERROR = 'column_meta_data_error';
export const METRICS_CREATE_INITIATE = 'metrics_create_initiate';
export const METRICS_CREATE_SUCCESS = 'metrics_create_sucess';
export const METRICS_CREATE_FAILED = 'metrics_create_failed';
export const UPLOAD_STATUS_PCT = 'upload_status_pct';

export const columnMetaDataReducer = (state = {}, action) => {
    switch (action.type) {
        case COLUMN_META_DATA_LOADING:
            return {
                loading: true,
                column_meta_data: []
            };
        case COLUMN_META_DATA_SUCESS:
            return {
                loading: false,
                column_meta_data: action.payload
            };
        case COLUMN_META_DATA_ERROR:
            return {
                loading: false,
                error: action.payload,
                column_meta_data: []
            };
        default:
            return state;
    }
};

export const createMetricsReducer = (state = { loading: false, metrics: [] }, action) => {
    switch (action.type) {
        case METRICS_CREATE_INITIATE:
            return {
                loading: true,
                metrics: []
            };
        case METRICS_CREATE_SUCCESS:
            return {
                loading: false,
                metrics: action.payload
            };
        case METRICS_CREATE_FAILED:
            return {
                loading: false,
                error: action.payload,
                metrics: []
            };
        default:
            return state;
    }
};

export const uploadStatusReducer = (state = { pct: 0 }, action) => {
    switch (action.type) {
        case UPLOAD_STATUS_PCT:
            return {
                pct: action.payload
            };
        default:
            return state;
    }
};
