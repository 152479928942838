import React from "react";
import { useDispatch } from "react-redux";

import { StyledButton } from "../mui-wrapper-components/Button";

import { GTAG_EVENTS, sendDataToGTM } from "../../../utils/gtmHelper";
import { EVENT_HISTORY, SHOW_MODAL } from "../../../constants/modalConstants";

export const ViewHistory = ({ groupId, gTagArgs, isGroup = false }) => {
  const dispatch = useDispatch();

  const showEventHistory = () => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        modalType: EVENT_HISTORY,
        modalProps: { title: "Activity Timeline", groupId },
      },
    });
    sendDataToGTM(
      isGroup
        ? GTAG_EVENTS.GROUP_EVENT_HISTORY_VIEWED
        : GTAG_EVENTS.EVENT_HISTORY_VIEWED,
      gTagArgs
    );
  };

  return <StyledButton onClick={showEventHistory}>History</StyledButton>;
};
