export const theme = {
  colors: {
    header: "#ebfbff",
    footer: "#003333",
  },
  web: "1540px",
  ipadPro: "1199px", // This dimension is not valid for iPad Pro, need to remove it later
  iPadPro: "1024px",
  mobile: "768px", // This should be renamed to tablet
  tablet: "480px", // This should be renamed to mobile
  medium: "580px",
  large: "680px",
  mediumMobile: "375px",
  smallMobile: "320px",
};

export const bp = {
  min: (width) => `@media (min-width: ${width})`,
  max: (width) => `@media (max-width: ${width})`,
};
