import styled from "styled-components";

import {
  PoppinsBoldComet186px,
  PoppinsBoldQuickSilver15px,
  PoppinsBoldMariner15px,
  ValignTextMiddle,
} from "../../../collaborate/styledMixins";

export const TimeImpactContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 28px;
  align-self: stretch;
`;

export const AsOfDate = styled.p`
  ${ValignTextMiddle}
  ${PoppinsBoldQuickSilver15px}
  width: fit-content;
  height: 26px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 19.9px;
`;

export const TimeImpactDetails = styled.div`
  display: flex;
  align-items: baseline;
  gap: 16px;
`;

export const DisplayTime = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldComet186px}
  min-width: 149px;
  letter-spacing: 0;
  line-height: 12.8px;
  white-space: nowrap;
`;

export const Percent = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldMariner15px}
  min-width: 41px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 19.9px;
  white-space: nowrap;
`;
