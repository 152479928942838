import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";

import { getImpactDataForGroup } from "../../../actions/impactActions";
import { LOGIN } from "../../../shared/constants/routes";
import { HTTP_STATUS } from "../../../shared/enums/http-status";
import {
  calculateTimeInMinutes,
  datePartsWithTZ,
} from "../../../utils/dateUtils";
import { labels } from "../../../shared/intl/labels";
import {
  TimeImpactContainer,
  AsOfDate,
  TimeImpactDetails,
  DisplayTime,
  Percent,
} from "../../common/styled-components/collaborate";
import { useNavigate } from "react-router-dom";

function TimeImpact({ groupId, insightTs }) {
  const isMountedRef = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();

  const { year, monthName, day, hours24, minutes } = datePartsWithTZ(insightTs);
  let datetime = "";
  if (+hours24 > 0) {
    datetime = `${hours24}:${minutes} on `;
  }
  datetime = datetime.concat(`${monthName} ${day}, ${year}`);

  useEffect(() => {
    isMountedRef.current = true;
    dispatch(getImpactDataForGroup(groupId))
      .then((result) => {
        if (isMountedRef.current) {
          setTableData(result);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error?.status === HTTP_STATUS.UNAUTHORIZED) {
          navigate(`/${LOGIN}`);
        }
      });

    return () => (isMountedRef.current = false);
    // navigate excluded from deps array because it changes on every route change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, groupId]);

  return (
    <TimeImpactContainer>
      <AsOfDate>
        {labels.COLLABORATE.AS_OF.replace("__time", datetime)}
      </AsOfDate>
      {loading ? (
        <>
          <DisplayTime>{labels.COLLABORATE.TIME_TO_DETECT}</DisplayTime>
          <Percent>
            <CircularProgress size="0.8rem" />
          </Percent>
          <DisplayTime>{labels.COLLABORATE.TIME_TO_RESOLVE}</DisplayTime>
          <Percent>
            <CircularProgress size="0.8rem" />
          </Percent>
        </>
      ) : (
        tableData
          .filter((data) => data.type === "time")
          .map((timeImpact) =>
            timeImpact.data.map((time) => (
              <React.Fragment key={time.name}>
                <TimeImpactDetails>
                  <DisplayTime>{time.name}</DisplayTime>
                  <Percent>{calculateTimeInMinutes(time.value)}</Percent>
                </TimeImpactDetails>
              </React.Fragment>
            ))
          )
      )}
    </TimeImpactContainer>
  );
}

export default TimeImpact;
