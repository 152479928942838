import React, { useState, useContext } from "react";
import "./chart-table.css";
import { PERCENTAGE } from "../../../constants/commonConstants";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { InsightContext } from "../../../context/InsightContext";
import { MuiPopper } from "../mui-wrapper-components/Popper/Popper";
import LiftPercentContainer from "../lift-percentage/LiftPercentContainer";
import { AccentPercent } from "../styled-components/TableChart.styled";

function ChartTable() {
  const [linkRef, setLinkRef] = useState();

  const { tableChartData, patternData } = useContext(InsightContext);
  const onlyPercents = patternData.pattern.kpi_format === PERCENTAGE;

  const handleClick = (event) => {
    setLinkRef(linkRef ? null : event.currentTarget);
  };

  const handleAwayClick = () => {
    setLinkRef(null);
  };

  return (
    <div className="table-container">
      {tableChartData &&
        tableChartData.map((record, index) => {
          return (
            <div key={index} className="rows">
              <div className="table-data-labels">
                {record.heading && (
                  <div className="td-heading">{record.heading}</div>
                )}
                {record.subText && (
                  <div className="td-subtext extra-margin">
                    {record.subText}
                  </div>
                )}
                {record.accent && (
                  <ClickAwayListener onClickAway={handleAwayClick}>
                    {/* ClickAwayListener only expect a block element as children, so had to wrap the Tooltip around the div */}
                    <div>
                      <Tooltip title="Click to see how it is calculated">
                        <AccentPercent
                          color={record.accent.color}
                          onClick={handleClick}
                        >
                          {record.accent.value}
                        </AccentPercent>
                      </Tooltip>
                      <MuiPopper ref={linkRef}>
                        <LiftPercentContainer data={patternData?.pattern} />
                      </MuiPopper>
                    </div>
                  </ClickAwayListener>
                )}
              </div>
              <div className="table-data-values">
                {record.tableData && (
                  <>
                    <div
                      className="record-value"
                      style={onlyPercents ? { paddingBottom: "10px" } : {}}
                    >
                      {record.tableData.value}
                    </div>
                    {record.tableData.subText && (
                      <div className="td-subtext">
                        {record.tableData.subText}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default ChartTable;
