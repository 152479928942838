import React, { Children, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { StyledTooltipChart } from "../styled-components/Tooltip.styled";
import { NavigateSlideButton } from "../styled-components/Carousel.styled";
import { ChevronLeftIcon, ChevronRightIcon } from "../Icons";
import { GTAG_EVENTS, sendDataToGTM } from "../../../utils/gtmHelper";
import { GTagGroupContext } from "../../../context/GtagContext";

export const GroupChartCarousel = (props) => {
  const dispatch = useDispatch();
  const gtagGroupContext = useContext(GTagGroupContext);
  // Slide index is zero based, and children count returns exact number of children, so we must subtract 1 from it
  const totalChildren = Children.count(props.children) - 1;
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    dispatch({
      type: "COLLAPSE_TABLE",
      payload: {
        carouselIndex: currentSlideIndex,
        shouldCollapseTable: true,
        insightId: gtagGroupContext.group_id,
      },
    });
  }, [dispatch, currentSlideIndex, gtagGroupContext]);

  const handleChange = (selectedIndex) => {
    if (currentSlideIndex !== selectedIndex) {
      setCurrentSlideIndex(selectedIndex);
    }
    if (selectedIndex === 1) {
      sendDataToGTM(GTAG_EVENTS.GROUP_TABLE_VIEWED, {
        ...gtagGroupContext,
      });
    } else if (selectedIndex === 2) {
      sendDataToGTM(GTAG_EVENTS.GROUP_IMPACT_TABLE_VIEWED, {
        ...gtagGroupContext,
      });
    }
  };

  const next = () => {
    if (currentSlideIndex < totalChildren) {
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  };

  const previous = () => {
    if (currentSlideIndex > 0) {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  return (
    <>
      {currentSlideIndex > 0 && (
        <StyledTooltipChart title="Back to previous view" placement="right-end">
          <NavigateSlideButton
            onClick={previous}
            style={{ left: 0, "--translateX": "-50%", background: 'rgba(36, 90, 230, 1)', color: 'var(--white)' }}
          >
            <ChevronLeftIcon fontSize="large" />
          </NavigateSlideButton>
        </StyledTooltipChart>
      )}
      {totalChildren !== currentSlideIndex && (
        <StyledTooltipChart title="View next card" placement="right-end">
          <NavigateSlideButton
            onClick={next}
            style={{ right: 0, "--translateX": "50%", background: 'rgba(36, 90, 230, 1)', color: 'var(--white)' }}
          >
            <ChevronRightIcon fontSize="large" />
          </NavigateSlideButton>
        </StyledTooltipChart>
      )}
      <Carousel
        showThumbs={false}
        showIndicators={false}
        selectedItem={currentSlideIndex}
        showArrows={false}
        stopOnHover={true}
        useKeyboardArrows={true}
        onChange={handleChange}
        statusFormatter={() => ""}
      >
        {props.children}
      </Carousel>
    </>
  );
};
