import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import { Box } from "@mui/material";
import { getL3DataByResourceType } from "../actions/groupInsightActions";
import { listMetricDetails } from "../actions/metricActions";
import CollaborateComponent from "../components/collaborate/CollaborateComponent";
import CompareMetrics from "../components/collaborate/CompareMetrics";
import DimensionsBreakup from "../components/collaborate/CompareMetrics/DimensionsBreakup";
import FilterStack from "../components/collaborate/Filter/FilterStack";
import FilteredInsightsGrid from "../components/collaborate/FilteredInsightsComponent/FilteredInsightsGrid";
import InsightHistogram from "../components/collaborate/InsightHistogram";
import TimeImpact from "../components/collaborate/TimeImpact";
import InsightTitleSummaryView from "../components/common/InsightTitleSummaryView";
import TimePickerModal from "../components/common/TimePickerModal";
import ApiScreenError from "../components/common/error-components/ApiScreenError";
import { Loading } from "../components/common/styled-components";
import {
  L3Components,
  L3ComponentsContainer,
  L3ContainerMain,
} from "../components/common/styled-components/collaborate";
import {
  ChartTitleTypography,
  ContentTitle,
} from "../components/common/styled-components/collaborate/Collaborate.styled";
import Feedback from "../components/insights/insight/feedback/Feedback";
import { RESOURCE_TYPE_GROUP } from "../constants/commonConstants";
import { SET_COLLABORATE_TIMESTAMP } from "../constants/filterCriteriaConstants";
import { METRIC_COMPARE, PIPELINE_SCHEDULE } from "../constants/multiSelectFilterConstants";
import useBoolean from "../hooks/useBoolean";

const GroupCollaborateScreen = () => {
  const { id } = useParams();
  const globalFilter = useSelector((state)=> state.tenantGlobalFilter?.appliedFilter);
  const filterSelector = useSelector((state) => state.collaborateFilters);
  const [loading, setLoading]= useState(true);

  const dispatch = useDispatch();

  const [groupData, setGroupData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [
    isTimeFilterOpen,
    { setTrue: openTimeFilterModal, setFalse: closeTimeFilterModal },
  ] = useBoolean();

  const  { pipelineSchedule, chartType } = useSelector((state)=> state.collaborateFilters);

  const { metric } = useSelector((state) => state.metricDetails);

  function handleTimeSelected(selectedStartEndTimestamp) {
    dispatch({
      type: SET_COLLABORATE_TIMESTAMP,
      payload: selectedStartEndTimestamp,
    });
  }

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    let queryParams = {};
    // Set filter query params
    queryParams.dim_name = globalFilter?.dimension_name;
    queryParams.dim_val= globalFilter?.dimension_value;

    dispatch(getL3DataByResourceType(RESOURCE_TYPE_GROUP, id, queryParams))
      .then((response) => {
        if (isMounted) {
          // Make metric :id call to get the information about the dimensions attached to the metric
          dispatch(listMetricDetails(response?.top_kpi_id)).then((data) => {
            if (data) {
              // In this case, we don't to fetch the last active pipeline schedule as the data is already present in group regarding its pipeline schedule
              // Hence set this value to the store instead
              dispatch({
                type: PIPELINE_SCHEDULE,
                payload: response.pipeline_schedule,
              });
            }
            setGroupData(() => {
              dispatch({
                type: SET_COLLABORATE_TIMESTAMP,
                payload: {
                  start_time: response.chart_ts_start,
                  end_time: response.chart_ts_end,
                },
              });
              return response;
            });
            setLoading(false);
          });
        }
      })
      .catch((error) => {
        setError(error);
      });

    window.scrollTo(0, 0);

    return () => (isMounted = false);
  }, [dispatch, id, globalFilter]);

  if (error) {
    return <ApiScreenError error={error} resourceType="group" />;
  }
  if (loading) {
    return (
      <L3ContainerMain id={id}>
        <L3ComponentsContainer>
          <Loading />
        </L3ComponentsContainer>
      </L3ContainerMain>
    );
  }

  return (
    <div className="container-center-horizontal">
      {isTimeFilterOpen && (
        <TimePickerModal
          onClose={closeTimeFilterModal}
          onSelect={handleTimeSelected}
        />
      )}

      <L3ContainerMain id={id}>
        <L3ComponentsContainer>
          {groupData && (
            <L3Components>
              <CollaborateComponent
                tenantId={groupData.tenant_id}
                link={`groups/${groupData.group_id}/collaborate?dim_name=${globalFilter?.dimension_name}&dim_val=${globalFilter?.dimension_value}`}
                resourceType={RESOURCE_TYPE_GROUP}
                resourceId={id}
                data={groupData}
              />
              {/** replace below with <Box/> */}
              <InsightTitleSummaryView
                accent={groupData.group_accent}
                title={groupData.group_title}
                summary={groupData.group_summary}
                groupId={id}
                showLegend={false}
                allowEdit={false}
                insightType= {groupData?.dim_display_name ? `${groupData.dim_display_name}: ${groupData.dim_val}` : 'Overall Metric'}
                funnels= {groupData?.funnels}
              />
              <FilterStack
                onTimeFilterClick={openTimeFilterModal}
                tenantId={groupData.tenant_id}
                kpiId={groupData.top_kpi_id}
                pipelineSchedule={pipelineSchedule}
                chartType={chartType}
                metric={metric}
                globalFilter={globalFilter}
              />
              <Box
                sx={{
                  border: "1.39px solid #C7D1EB",
                  padding: "5px 40px",
                  borderRadius: 1,
                  width: "100%",
                }}
              >
                {chartType === METRIC_COMPARE ? (
                  <CompareMetrics
                    tenantId={groupData.tenant_id}
                    kpiId={groupData.top_kpi_id}
                    pipelineSchedule={pipelineSchedule}
                  />
                ) : (
                  <DimensionsBreakup
                    metric={metric}
                    chartType={chartType}
                    kpiId={groupData.top_kpi_id}
                    pipelineSchedule={pipelineSchedule}
                  />
                )}
              </Box>
              <ChartTitleTypography>{groupData.chart_title}</ChartTitleTypography>
              <TimeImpact groupId={id} insightTs={groupData.group_ts_end} />

              <ContentTitle>Insight History</ContentTitle>
              <InsightHistogram
                tenantId={groupData.tenant_id}
                pipelineSchedule={pipelineSchedule}
                dimensionNames= {filterSelector?.selectedDimensionNames}
                dimensionValues={filterSelector?.selectedDimensionValues}
              />

              <FilteredInsightsGrid
                tenantId={groupData.tenant_id}
                pipelineSchedule={pipelineSchedule}
              />
              <Feedback resourceId={id} resourceType={RESOURCE_TYPE_GROUP} />
            </L3Components>
          ) }
        </L3ComponentsContainer>
      </L3ContainerMain>
    </div>
  );
};

export default GroupCollaborateScreen;
