import styled from "styled-components";
import { bp, theme } from "./theme";

export const ToggleContainer = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  right: 5%;

  ${bp.min(theme.iPadPro)} {
    right: 0;
  }
`;
