import styled from "styled-components";

export const InsightsHighlight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    align-items: flex-start;
    margin: 0;
    width: unset;
  }
`;
