import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import Chip, { chipClasses } from "@mui/material/Chip";
import { TIME_FILTER_REMOVE } from "../../constants/filterCriteriaConstants";
import { colors, typography } from "../../shared/theme-constants";
import {
  formatDate,
  formatDateTime,
  formatDateTimeUtc,
  formatDateUtc,
} from "../../utils/dateUtils";

const StyledChip = styled(Chip)({
  fontFamily: "Poppins",
  color: colors.comet,
  borderColor: colors.cadet_gray,
  borderRadius: "10px",

  [`& .${chipClasses.deleteIcon}`]: {
    fontSize: "1.125rem",
  },
});

const TimeWithSeparator = ({ startTime, endTime }) => (
  <>
    {startTime}{" "}
    <Typography
      component="span"
      variant="inherit"
      fontWeight={typography.font_weight.regular}
      color={colors.comet}
    >
      —
    </Typography>{" "}
    {endTime}
  </>
);

function formatSelectedDateTime(timeFilter) {
  if (timeFilter.type === "custom" || timeFilter.type === "preset/last_hour") {
    return (
      <TimeWithSeparator
        startTime={formatDateTime(timeFilter.start_time)}
        endTime={formatDateTime(timeFilter.end_time)}
      />
    );
  }

  if (timeFilter.type?.startsWith("preset/")) {
    return (
      <TimeWithSeparator
        startTime={formatDate(timeFilter.start_time)}
        endTime={formatDate(timeFilter.end_time)}
      />
    );
  }

  if (timeFilter.type === "histogram/hour") {
    return formatDateTimeUtc(timeFilter.start_time);
  }

  if (timeFilter.type === "histogram/week") {
    return (
      <TimeWithSeparator
        startTime={formatDateUtc(timeFilter.start_time)}
        endTime={formatDateUtc(timeFilter.end_time)}
      />
    );
  }

  return formatDateUtc(timeFilter.start_time);
}

const TimeFilter = ({ timeFilter }) => {
  const dispatch = useDispatch();

  function handleDelete() {
    dispatch({ type: TIME_FILTER_REMOVE });
  }

  const labelMarkup = (
    <>
      <Typography
        component="span"
        variant="inherit"
        fontWeight={typography.font_weight.medium}
      >
        Selected time
      </Typography>{" "}
      is{" "}
      <Typography
        component="span"
        variant="inherit"
        fontWeight={typography.font_weight.semiBold}
        sx={{ color: "#202534" }}
      >
        {formatSelectedDateTime(timeFilter)}
      </Typography>
    </>
  );

  return (
    <StyledChip
      variant="outlined"
      label={labelMarkup}
      onDelete={handleDelete}
    />
  );
};

export default TimeFilter;
