import styled from "styled-components";

export const StyledInput = styled.input.attrs((props) => ({
  type: props.type,
  placeholder: props.placeholder,
}))`
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 10px 30px;

  position: static;
  width: 400px;
  height: 60px;
  left: 0px;
  top: 0px;

  border: 2px solid #98a0a4;
  box-sizing: border-box;
  border-radius: 12px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 20px;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    min-width: 230px;
    width: auto;
  }
`;
