import { styled } from "@mui/material/styles";

export const CardContent = styled("div")`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  height: 400px;
  margin-top: 1rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding-left: 0;
    margin-top: 7%;
  }
`;
