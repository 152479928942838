import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import ListItemButtonDialog from "../common/mui-wrapper-components/ListItemButtonDialog";
import { Box, Button, Stack } from "@mui/material";
import styled from "@emotion/styled";
import { typography } from "../../shared/theme-constants";
import StyledCheckBox from "../common/mui-wrapper-components/CheckBox/StyledCheckBox";
import MultiSelect from "../common/mui-wrapper-components/Select/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import StyledSelect from "../common/mui-wrapper-components/Select/StyledSelect";
import { ToggleGroup } from "../common/mui-wrapper-components/StyledToggleGroup";
import { useSelectedTenant } from "../../hooks/useSelectedTenant";
import { updatePipelinesWithPreferenceValues } from "../../actions/onboardingActions";
import NotificationPreferences from "../preferences/NotificationPreferences";
import ApiKey from "../preferences/apiKeyTab/ApiKey";

const Title = styled("div")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(35),
  fontWeight: typography.font_weight.mediumx,
  lineHeight: theme.typography.pxToRem(53),
  letterSpacing: "0em",
  textAlign: "center",
  color: "#5C6178",
  margin: "3% 0 3% 0",
}));
const ActionButton = styled(Button)(({ theme, textcolor, bgcolor }) => ({
  width: "100px",
  height: "41px",
  padding: theme.spacing(0, 7),
  borderRadius: "9px",
  border: "1.5px",
  background: bgcolor,
  color: textcolor ? textcolor : "white",
  ":hover": {
    cursor: "pointer",
  },
  "&:disabled": {
    background: textcolor ? null : "linear-gradient(0deg, #3063E7, #3063E7),linear-gradient(0deg, #C4C4C4, #C4C4C4)",
    opacity: 0.4,
    color: textcolor ? textcolor : "var(--zircon)",
  },
  alignSelf: "center",
}));

const StyledQuestions = styled("div")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  fontWeight: typography.font_weight.mediumx,
  lineHeight: theme.typography.pxToRem(45),
  letterSpacing: "0em",
  textAlign: "left",
  color: "#5C6178",
  margin: "1% 0 1% 0",
}));

const StyledNotes = styled("div")(({ theme }) => ({
  maxWidth: theme.typography.pxToRem(450),
  fontSize: theme.typography.pxToRem(9),
  letterSpacing: "0em",
  textAlign: "left",
  color: "#5C6178",
  margin: "1% 0 1% 0",
}));

const dialogOptions = [
  { value: "analysis", label: "Analysis" },
  { value: "notification", label: "Notification" },
  { value: "api_keys", label: "API Keys" },
];

const frequencyCheckboxOptions = [
  { value: "hourly", label: "Hourly", description: "Every Hour", isDefault: false },
  { value: "daily", label: "Daily", description: "Every 24 Hours", isDefault: true },
  { value: "weekly", label: "Weekly", description: "Every 7 Days", isDefault: true },
  { value: "monthly", label: "Monthly", description: "Every 4 Weeks", isDefault: true },
];
const reStateToggleOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];
const restateFrequencyOptions = [{ value: "daily", label: "Daily", description: "", isDefault: true }];

const timeDurationOptions = [
  { value: "year", label: "1 Year" },
  { value: "month", label: "1 Month" },
  { value: "halfYear", label: "6 Months" },
  { value: "quarter", label: "3 Months" },
];
const eventOptions = [
  { value: "all_events", label: "All Events", description: "Show me everything", isDefault: false },
  { value: "events_with_hotspots", label: "Events with Hotspots", description: "Only show things that has Root Causes", isDefault: false },
  { value: "only_anomaly", label: "Only Anomaly Events", description: "Only show things that need my attention", isDefault: true },
];

const eventAlertOptions = [
  { value: "high", label: "Critical", isDefault: false },
  { value: "medium", label: "Needs Attention", isDefault: true },
  { value: "low", label: "Tell me everything", isDefault: false },
];
const whenToAlertOptions = [
  { value: "as_event_happens", label: "As Event Happens", description: "Send me alerts in real time", isDefault: false },
  { value: "daily_summaries", label: "Daily Summaries", description: "Send me all alerts Summarised", isDefault: false },
  { value: "both", label: "Both", description: "", isDefault: true },
];

const emailOptions = [
  { value: "simple_email", label: "Email", description: "", isDefault: true },
  { value: "slack", label: "Slack", description: "", isDefault: false },
  { value: "microsoft_teams", label: "Teams", description: "", isDefault: false },
];
const SetPreferences = () => {
  const columnsMetaData = useSelector((state) => state.onBoardingColumnDetails?.column_meta_data?.columns);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedTenant = useSelectedTenant();
  const [loading, setLoading] = useState(true);
  const [reStateValue, setReStateValue] = useState("no");
  const [seletedColumn, setSelectedColumn] = useState(null);
  const [activeStep, setActiveStep] = useOutletContext();
  const [section, setSection] = useState("analysis");

  const handleNext = () => {
    let preferencePayload = Object.assign(
      {},
      {
        preference_value: {
          date_column: seletedColumn.field_name,
        },
        tenant_id: selectedTenant,
      }
    );
    dispatch(updatePipelinesWithPreferenceValues(preferencePayload)).then((response) => {
      navigate("/onboarding/finish");
    });
  };
  const handleTimeSeriesColumnSelect = (_event, value) => {
    setSelectedColumn(value);
  };
  const handleRestateValueChange = (_event, selectedRestateValue) => {
    setReStateValue(selectedRestateValue);
  };
  const getSection = (section) => {
    return sections[section];
  };

  const getAnalysisOptions = () => (
    <Box component="div">
      <Stack direction="column" sx={{ pl: 15 }}>
        <Box>
          <StyledQuestions>Which Field should we use to create Time Series?</StyledQuestions>
          <Stack>
            <MultiSelect
              componentsProps={{
                paper: {
                  sx: {
                    width: "fit-content",
                  },
                },
              }}
              value={seletedColumn || null}
              disableCloseOnSelect={false}
              sx={{ color: "#5C6178" }}
              multiple={false}
              onChange={handleTimeSeriesColumnSelect}
              optionsCheckbox={false}
              placeholder="Metric Name"
              options={columnsMetaData || []}
              getOptionLabel={(option) => option?.field_name || ""}
              isOptionEqualToValue={(option, value) => option.field_name === value.field_name}
              groupBy={(option) => option.source_table_name}
            />
            <StyledNotes>
              <span style={{ fontWeight: typography.font_weight.semiBold }}>NOTE:</span> Date/time stored in this field would be converted to UTC{" "}
            </StyledNotes>
          </Stack>
        </Box>
        <Box>
          <StyledQuestions>What duration should we analyze?</StyledQuestions>
          <Stack>
            <StyledSelect disabled={true} defaultValue={timeDurationOptions[0]} options={timeDurationOptions}></StyledSelect>
            <StyledNotes>
              <span style={{ fontWeight: typography.font_weight.semiBold }}>NOTE:</span> Choose the reporting period you want for the initial sync. This time span is based on the
              current date. The more months of data you sync, the longer the initial sync will take.
            </StyledNotes>
          </Stack>
        </Box>
        <Box>
          <StyledQuestions>How frequently should we analyze your data?</StyledQuestions>
          <StyledCheckBox disabled={true} options={frequencyCheckboxOptions}></StyledCheckBox>
        </Box>
        <Box>
          <StyledQuestions>Do you re-state your data?</StyledQuestions>
          <Stack>
            <Stack direction="row" gap={6} height={37}>
              <ToggleGroup disabled={true} onChange={handleRestateValueChange} value={reStateValue} options={reStateToggleOptions}></ToggleGroup>
              {/* <StyledCheckBox disabled={true} options={restateFrequencyOptions}></StyledCheckBox> */}
            </Stack>
            <StyledNotes>
              <span style={{ fontWeight: typography.font_weight.semiBold }}>NOTE:</span> If you update your data after particular period, this will make sure we do the same too.
            </StyledNotes>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );

  const getNotificationOptions = () => (
    <Box component="div">
      <Stack direction="column" spacing={2} sx={{ pl: 15 }}>
        <Box>
          <StyledQuestions>What events would you like to see on feed?</StyledQuestions>
          <StyledCheckBox disabled={true} options={eventOptions}></StyledCheckBox>
        </Box>
        <Box>
          <StyledQuestions>What events would you like to be alerted about?</StyledQuestions>
          <StyledCheckBox disabled={true} options={eventAlertOptions}></StyledCheckBox>
        </Box>
        <Box>
          <StyledQuestions>How should we alert you?</StyledQuestions>
          <StyledCheckBox disabled={true} options={whenToAlertOptions}></StyledCheckBox>
        </Box>
        <Box>
          <StyledQuestions>Where shall we send the alerts to?</StyledQuestions>
          <StyledCheckBox disabled={true} options={emailOptions}></StyledCheckBox>
        </Box>
      </Stack>
    </Box>
  );

  const sections = {
    analysis: getAnalysisOptions(),
    notification: <NotificationPreferences />,
    api_keys: <ApiKey />,
  };

  const handleSectionClicked = (section) => {
    setSection(section);
  };

  useEffect(() => setActiveStep(3));
  return (
    <Stack sx={{ pl: 20, pr: 20 }}>
      <Box sx={{ maxWidth: "1035px", width: "100%", alignSelf: "center" }}>
        <Title>Set Preferences</Title>
        <ListItemButtonDialog sx={{ marginBottom: "2%" }} options={dialogOptions} onClick={handleSectionClicked}>
          <Stack direction="row" sx={{ overflowY: "auto", height: "100%" }}>
            {getSection(section)}
          </Stack>
        </ListItemButtonDialog>
        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          <ActionButton disabled textcolor="var(--mariner)" bgcolor="var(--zircon)" onClick={() => navigate(-1)}>
            Back
          </ActionButton>
          <ActionButton bgcolor="linear-gradient(0deg, #245AE6, #245AE6)" onClick={handleNext} disabled={seletedColumn ? false : true}>
            Next
          </ActionButton>
        </Stack>
      </Box>
    </Stack>
  );
};

export default SetPreferences;
