import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  inputAdornmentClasses,
  InputBase,
  Link as MuiLink,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { GridSearchIcon as SearchIcon } from "@mui/x-data-grid";
import Icon from "../common/Icon";
import { useAxios } from "../../hooks/useAxios";
import { isEmpty } from "../../utils/is";
import { omit } from "../../utils/objects";
import { colors } from "../../shared/theme-constants";
import FrequencyFilter from "./FrequencyFilter";

function getOptionLabel(option) {
  if (typeof option === "boolean") {
    return option ? "Yes" : "No";
  }

  return `${option}`;
}

const Container = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),

  "& .MuiAutocomplete-listbox": {
    minHeight: 80,
    maxHeight: 162,
    padding: 0,
  },

  "& .MuiAutocomplete-noOptions": {
    padding: theme.spacing(3, 0),
  },
}));

const LinkButton = styled(
  React.forwardRef(function LinkButton(props, ref) {
    return (
      <MuiLink
        component="button"
        variant="body2"
        underline="hover"
        ref={ref}
        {...props}
      />
    );
  })
)({
  "&:disabled": {
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "default",
    textDecoration: "none",
  },
});

const StyledInput = styled(InputBase)(({ theme }) => ({
  width: "100%",
  paddingBottom: 4,

  "& input": {
    borderRadius: theme.shape.borderRadius,
    padding: 8,
    // icon size small is 20px + 8px input padding
    paddingRight: 28,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    border: `1px solid ${colors.gray[300]}`,
    fontSize: theme.typography.pxToRem(14),

    "&:focus": {
      boxShadow: `0px 0px 0px 3px rgba(3, 102, 214, 0.3)`,
      borderColor: theme.palette.primary.main,
    },
  },

  [`& .${inputAdornmentClasses.root}`]: {
    position: "absolute",
    right: 8,
  },
}));

const PassthroughWrapper = ({
  anchorEl,
  className,
  disablePortal,
  open,
  ...props
}) => <div {...props} />;

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  return <List ref={ref} dense {...props} />;
});

const InputComponent = ({ InputProps, inputProps, onChange }) => {
  // if menu item label matches pressed key, it will be focused
  // this should prevent list items stealing focus from input
  // when label matches character that user entered to input
  function handleKeyDown(event) {
    event.stopPropagation();
  }

  return (
    <StyledInput
      autoFocus
      ref={InputProps.ref}
      inputProps={{
        ...inputProps,
        onKeyDown: handleKeyDown,
        onChange: (event) => onChange(event.target.value),
      }}
      endAdornment={
        <InputAdornment position="end">
          <SearchIcon fontSize="small" />
        </InputAdornment>
      }
      placeholder="Filter options"
    />
  );
};

const FilterOptionsList = ({ data, selectedOptions, setSelectedOptions }) => {
  const [searchInputValue, setSearchInputValue] = useState("");

  return (
    <Container>
      <Autocomplete
        open
        multiple
        clearOnBlur={false}
        value={selectedOptions}
        inputValue={searchInputValue}
        onChange={(event, newValue, reason) => {
          if (
            event.type === "keydown" &&
            event.key === "Backspace" &&
            reason === "removeOption"
          ) {
            return;
          }

          setSelectedOptions(newValue);
        }}
        renderTags={() => null}
        noOptionsText="No options"
        PopperComponent={PassthroughWrapper}
        PaperComponent={PassthroughWrapper}
        ListboxComponent={ListboxComponent}
        renderOption={({ className, ...props }, option, { selected }) => {
          const optionLabel = getOptionLabel(option);

          return (
            <ListItem
              key={optionLabel}
              disablePadding
              disableGutters
              {...props}
            >
              <ListItemButton>
                <ListItemIcon>
                  {selected ? <Icon name="check" size="20px" /> : null}
                </ListItemIcon>

                <ListItemText primary={optionLabel} />
              </ListItemButton>
            </ListItem>
          );
        }}
        options={data?.values}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <InputComponent {...params} onChange={setSearchInputValue} />
        )}
      />
    </Container>
  );
};

const MenuFooterActions = ({ hideMenu, handleFilter }) => (
  <ListItem>
    <Stack direction="row" gap={3} ml="auto">
      <Button color="primary" variant="outlined" onClick={hideMenu}>
        Cancel
      </Button>
      <Button color="primary" variant="contained" onClick={handleFilter}>
        Filter
      </Button>
    </Stack>
  </ListItem>
);

const MetricsColumnMenuFilterSection = ({
  columnId,
  selectedTenant,
  selectedFilters,
  setSelectedFilters,
  currentColumn,
  hideMenu,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedFrequency, setSelectedFrequency] = useState();

  const { data, status } = useAxios("metrics_hub/metrics/filters", {
    params: new URLSearchParams({
      tenant_id: selectedTenant,
      name: columnId,
    }),
  });

  function handleFrequencyChange(value) {
    setSelectedFrequency(value);
  }

  function handleFilter(event) {
    const isNoneOrAllOptionsSelected =
      selectedOptions.length === 0 ||
      selectedOptions.length === data?.values.length;

    // If custom filter is enabled, then include it in the selected filter to call the API
    const selectedPipelineFreq = currentColumn.showCustomFilter
      ? {
        frequency: selectedFrequency ? selectedFrequency : "Hourly",
      }
      : {};
    if (isNoneOrAllOptionsSelected) {
      // when all or none options are selected, exclude that key from selectedFilters
      // But, if custom filter is present, and since it is a radio button as of now, hence it will always have a selected value
      // so we need to pass it
      setSelectedFilters(
        (prevState) => (omit(prevState, columnId), { ...selectedPipelineFreq })
      );
    } else {
      setSelectedFilters((prevState) => ({
        ...prevState,
        ...selectedPipelineFreq,
        [columnId]: selectedOptions,
      }));
    }

    hideMenu(event);
  }

  useEffect(
    function preselectOptionsWhenDataIsFetched() {
      if (isEmpty(data?.values)) return;
      setSelectedFrequency(selectedFilters.frequency);
      setSelectedOptions(
        isEmpty(selectedFilters[columnId])
          ? data.values
          : selectedFilters[columnId]
      );
    },
    [columnId, data?.values, selectedFilters]
  );

  return (
    <>
      {currentColumn.showCustomFilter && (
        <FrequencyFilter
          preSelectOption={selectedFilters?.frequency}
          onFrequencyChange={handleFrequencyChange}
        />
      )}
      <Divider />

      <ListItem sx={{ display: "block" }}>
        <ListItemText>Filter by values</ListItemText>

        <Stack gap={2}>
          <div>
            <LinkButton
              disabled={!data}
              onClick={() => setSelectedOptions(data?.values)}
            >
              Select All
            </LinkButton>
            <Box component="span" sx={{ px: 2 }}>
              ⸱
            </Box>
            <LinkButton disabled={!data} onClick={() => setSelectedOptions([])}>
              Clear
            </LinkButton>
          </div>

          {status === "loading" ? (
            <Box textAlign="center" py={1.5}>
              <CircularProgress size={24} />
            </Box>
          ) : status === "error" ? (
            <Typography color="error.main" variant="body2">
              Error occured while fetching column values.
            </Typography>
          ) : (
            <FilterOptionsList
              data={data}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
            />
          )}
        </Stack>
      </ListItem>

      <Divider />

      <MenuFooterActions hideMenu={hideMenu} handleFilter={handleFilter} />
    </>
  );
};

export default MetricsColumnMenuFilterSection;
