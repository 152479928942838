import { isNullOrEmpty } from "./is";

/**
 * This function transforms a sentence or a word to its titlecase equivalent
 * @param { word/sentence } string
 * @returns {titleCase word/sentence} string
 */
export const titleCase = (string) => {
  return (
    string &&
    string
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(" ")
  );
};

export const areEqualAndNotEmpty = (str1, str2) => {
  if (isNullOrEmpty(str1) || isNullOrEmpty(str2)) return false;

  return str1 === str2;
};

/**
 * Transform a number to its equivalent `K for thousand`, `M for million`, `B for billion`,
 * `T for trillion` representation
 * @param {} num
 * @param {} digits
 * @returns 1K for 1000, 1M for 100000 and 999 for 999 etc.
 */
export const nFormatter = (num, digits) => {
  const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
  ];

  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (Math.abs(num) >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits) + si[i].symbol;
};

export function buildUrl(
  endpoint,
  queryParams = {},
  base = process.env.REACT_APP_BASE_URL
) {
  const builtURL = new URL(base + endpoint);

  for (let queryParamKey in queryParams) {
    builtURL.searchParams.set(
      queryParamKey,
      encodeURIComponent(queryParams[queryParamKey])
    );
  }

  return builtURL.href;
}

export const escapeString = (string) => {
  if (!string) return string;
  // escape the double and single quotes of the string inputs
  return string.replace(/\\'/g, "'").replace(/\\"/g, '"');
};