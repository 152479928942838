// React Imports
import * as React from "react";

// Local Imports
import Icon from "./Icon";
import { TimeIconContainer } from "./styled-components/DateTimeContainer.styled";
import { StyledTooltip } from "./styled-components/Tooltip.styled";

export default function TimePickerIcon({ onClick, position, right }) {
  return (
    <StyledTooltip key="TimeFilter" placement="left-end" title="Time Filter">
      <TimeIconContainer position={position} right={right} onClick={onClick}>
        <Icon name="TimePicker" size="30px" showcursor="true" />
      </TimeIconContainer>
    </StyledTooltip>
  );
}
