import styled from "@emotion/styled";
import { Box, List, ListItemButton, ListItemText, listItemTextClasses, listItemButtonClasses } from "@mui/material";
import React, { useState } from "react";
import { typography } from "../../../shared/theme-constants";

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "53vh",
  background: "var(--white)",
  boxShadow: "0px 4px 22px rgba(17, 39, 133, 0.25)",
  borderRadius: theme.typography.pxToRem(10),
}));

const ListButton = styled(ListItemButton)(({ theme, selected }) => ({
  height: theme.typography.pxToRem(47),
  background: "var(--white)",
  border: "1px #ECF0F9 solid",
  color: "var(--comet)",
  ...(selected && {
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      borderRight: "9px solid  #E5ECFD",
      borderBottom: "9px solid  #E5ECFD",
      borderLeft: "15px solid transparent",
      borderTop: "15px solid transparent",
      right: -11,
      width: 0,
      height: 0,
      transform: "rotate(-45deg)",
      zIndex: 0,
    },
  }),
  [`&.${listItemButtonClasses.selected}`]: {
    "&.Mui-selected": {
      border: "4px solid var(--mairner)",
      color: theme.palette.primary.main,
    },
  },
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  [`& .${listItemTextClasses.primary}`]: { fontWeight: typography.font_weight.semiBold },
}));

const ListItemButtonDialog = ({ options, onClick, children, ...delegatedProps }) => {
  // Set default first element selected
  const [selectedValue, setSelectedValue] = useState(options[0]?.value);

  // pass the onclick  handler function to the parent
  const handleClick = (value) => {
    setSelectedValue(value);
    if (typeof onClick === "function") {
      onClick(value);
    }
  };

  return (
    <StyledBox component="div" {...delegatedProps}>
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%", height: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: "row", maxWidth: "30%", width: "100%" }}>
          <List disablePadding component="nav" sx={{ width: "100%", padding: "20% 0", borderRight: "2px solid #E5ECFD" }}>
            {options.map(({ index, label, value, ...rest }) => (
              <ListButton onClick={() => handleClick(value)} key={`${value}${index}`} selected={selectedValue === value}>
                <StyledListItemText primary={label}></StyledListItemText>
              </ListButton>
            ))}
          </List>
        </Box>
        <Box sx={{ overflowX: "auto" }}>{children}</Box>
      </Box>
    </StyledBox>
  );
};

export default ListItemButtonDialog;
