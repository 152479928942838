import { Avatar, Box, CircularProgress, Container, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import pluralize from "pluralize";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { joinTenant } from "../../actions/userOnboardingActions";
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser";
import { useAxios } from "../../hooks/useAxios";
import { typography } from "../../shared/theme-constants";
import { StyledActionButton } from "../common/mui-wrapper-components/Button";

const TenantSelectionScreen = () => {
  const [joiningTenantLoader, setJoiningTenantLoader] = useState(false);
  const user = useAuthenticatedUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: tenantDetails, status: tenantDetailsStatus } = useAxios(
    `tenants/${user?.tenant_uuid}`
  );

  const handleJoinTeam = () => {
    setJoiningTenantLoader(true);
    const postData = {
      user_email: user?.email,
    };
    dispatch(joinTenant(postData, user?.tenant_uuid)).then((response) => {
      setJoiningTenantLoader(false);
      if (response) {
        navigate(`/onboard/user_persona`);
      }
    });
  };

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "var(--grey-50)",
      }}
    >
      {/* User info box */}
      <Box
        sx={{
          position: "absolute",
          top: "16px",
          right: "16px",
          backgroundColor: "var(--white)",
          padding: "8px",
          borderRadius: "4px",
          backgroundColor: "transparent",
        }}
      >
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(12),
            fontWeight: typography.font_weight.regular,
            color: "var(--grey-600)",
          })}
        >
          Logged in as:
        </Typography>
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(13),
            fontWeight: typography.font_weight.mediumx,
            color: "var(--grey-800)",
          })}
        >
          {user?.email || "Not logged in"}
        </Typography>
      </Box>
      <Box
        elevation={3}
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "auto",
          width: "100vw",
          maxWidth: theme.typography.pxToRem(460),
          boxShadow: "none",
          backgroundColor: "transparent",
          [theme.breakpoints.up("tm")]: {
            width: `${theme.typography.pxToRem(460)} !important`, // Increase height on smaller screens
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
          }}
          gap={6}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
              width: "100%",
            }}
            gap={6}
          >
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(22),
                fontWeight: typography.font_weight.mediumx,
                color: "var(--grey-900)",
                textAlign: "center",
              })}
            >
              We found your team on
              <br />
              Out Of The Blue!
            </Typography>
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(12),
                fontWeight: typography.font_weight.mediumx,
                color: "var(--grey-700)",
                textAlign: "center",
              })}
            >
              Join your team's Workspace and gain instant access to all your
              <br />
              revenue and marketing metrics.
            </Typography>
          </Box>
          <Paper
            elevation={3}
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "auto",
              width: "100vw",
              maxWidth: theme.typography.pxToRem(460),
              boxShadow: "0 5px 20px 0 rgba(0, 0, 0, 0.15)",
              backgroundColor: "var(--white)",
              padding: "24px",
              borderRadius: "8px",
              gap: "24px",
              [theme.breakpoints.up("tm")]: {
                width: `${theme.typography.pxToRem(460)} !important`, // Increase height on smaller screens
              },
            })}
          >
            <Stack
              direction="row"
              gap={1}
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #E0E0E0",
                borderRadius: "8px",
                padding: "12px",
              }}
            >
              <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={2}>
                {tenantDetails?.logo_path ? (
                  <Avatar
                    alt="Org Logo"
                    src={`${process.env.REACT_APP_CDN_BASE_URL}${tenantDetails?.logo_path}`}
                  />
                ) : (
                  <Avatar>{tenantDetails?.display_name.charAt(0).toUpperCase()}</Avatar>
                )}
                <Stack direction="column" gap={1} sx={{ alignItems: "flex-start" }}>
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(13),
                      fontWeight: typography.font_weight.semiBold,
                      color: "var(--grey-900)",
                    })}
                  >
                    {tenantDetails?.display_name || ""}
                  </Typography>
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(12),
                      fontWeight: typography.font_weight.regular,
                      color: "var(--grey-700)",
                    })}
                  >
                    {`${tenantDetails?.member_count} ${pluralize(
                      "Member",
                      tenantDetails?.member_count
                    )}`}
                  </Typography>
                </Stack>
              </Box>
              <StyledActionButton
                sx={{
                  backgroundColor: "var(--brand-blue)",
                  height: "2rem",
                  width: "3.25rem",
                  minWidth: "3.25rem",
                  fontSize: "13px",
                }}
                onClick={handleJoinTeam}
              >
                {joiningTenantLoader ? (
                <CircularProgress size={13} color="inherit" />
              ) : (
                "Join"
              )}
              </StyledActionButton>
            </Stack>
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};
export default TenantSelectionScreen;
