import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors, typography } from "../../../shared/theme-constants";

export const LiftCardContainer = styled("div")`
  padding: 10px 0;
`;

export const RowStack = styled(Stack)`
  justify-content: space-between;
  align-items: center;
`;

export const KpiRowStack = styled(RowStack)`
  justify-content: flex-end;
  margin-top: 10px;
`;

export const RowSeparator = styled(Stack)`
  border: 0.5px solid ${colors.popper_font_color};
  margin: 0 10px;
`;

export const FooterStack = styled(RowStack)`
  background-color: ${colors.popper_footer_bgcolor};
  border-radius: 0px 0px 10px 10px;
  margin-top: 30px;
`;

export const TitleTypography = styled(Typography)`
  font-weight: ${typography.font_weight.regular};
  font-size: ${typography.font_size.lg}px;
  color: ${colors.popper_font_color};
  line-height: 2;
  padding: 0 10px;
`;

export const SubTitleTypography = styled(TitleTypography)`
  font-size: ${typography.font_size.xs}px;
  text-decoration: underline;
  line-height: 0.8;
`;

export const RowTypography = styled(Typography)`
  font-family: "Inter";
  font-weight: ${typography.font_weight.medium};
  font-size: ${typography.font_size.base}px;
  color: ${colors.popper_font_color};
  padding: 2px 10px;
`;

export const KpiRowTypography = styled(RowTypography)`
  font-size: ${typography.font_size.xs}px;
`;

export const RowTypographyBold = styled(RowTypography)`
  font-weight: ${typography.font_weight.bold};
`;

export const FooterTypography = styled(RowTypographyBold)`
  color: ${colors.white};
  padding: 10px;
`;
