import { useEffect, useState } from "react";
import { differenceInMinutes, isAfter, isValid } from "date-fns";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import { Button, useMediaQuery } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Icon from "./Icon";
import {
  CalendarContainer,
  CalendarTitle,
  DateTimeTextField,
  TitleField,
} from "./styled-components/Calendar.Modal.styled";
import { TimePickerModalStyles } from "./styled-components/DateTimeContainer.styled";
import PresetTimePickerGroup from "./PresetTimePickerGroup";
import { theme } from "./styled-components/theme";
import useBoolean from "../../hooks/useBoolean";
import moment from "moment-timezone";

function areDatesInvalidOrInBadState(startDateTime, endDateTime) {
  return (
    !isValid(startDateTime) ||
    !isValid(endDateTime) ||
    isAfter(startDateTime, endDateTime) ||
    differenceInMinutes(endDateTime, startDateTime) < 5
  );
}

const TimePickerModal = ({ onClose, onSelect }) => {
  const timePickerBreakpoints = useMediaQuery(`(max-width:${theme.mobile})`);
  const timeFilter = useSelector((state) => state.feedScreenTimeFilter);
  const isTimeCustomOrPreset =
    timeFilter.type === "custom" || timeFilter.type?.startsWith("preset/");

  const [selectedStartDateTime, setSelectedStartDateTime] = useState(
    isTimeCustomOrPreset ? new Date(timeFilter.start_time) : null
  );
  const [selectedEndDateTime, setSelectedEndDateTime] = useState(
    isTimeCustomOrPreset ? new Date(timeFilter.end_time) : null
  );
  const [
    isSubmitEnabled,
    { setTrue: enableSubmitButton, setFalse: disableSubmitButton },
  ] = useBoolean(false);

  /**
   * When submit button is clicked, pick up the start and date and time
   * close the modal
   */
  const handleSubmit = () => {
    onSelect({
      start_time: moment(selectedStartDateTime).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      end_time: moment(selectedEndDateTime).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      type: "custom",
    });
    onClose();
  };

  useEffect(
    function syncSubmitButtonDisabledStateWithSelectedDates() {
      if (
        areDatesInvalidOrInBadState(selectedStartDateTime, selectedEndDateTime)
      ) {
        disableSubmitButton();
      } else {
        enableSubmitButton();
      }
    },
    [
      selectedStartDateTime,
      selectedEndDateTime,
      disableSubmitButton,
      enableSubmitButton,
    ]
  );

  return (
    <Modal
      BackdropProps={{ style: { background: "rgba(17, 39, 133, 0.82)" } }}
      onClose={onClose}
      open
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{ ...TimePickerModalStyles, alignItems: "center", outline: "none" }}
      >
        <CalendarContainer>
          <CalendarTitle
            style={{
              display: "flex",
              flexDirection: "row",
              width: "fit-content",
              alignItems: "center",
            }}
          >
            <Icon name="TimePicker" size="2em" />
            <TitleField>{"Pick date & time range"}</TitleField>
          </CalendarTitle>

          <Stack spacing={4} px={4} mx="auto">
            <Stack
              direction={timePickerBreakpoints ? "column" : "row"}
              spacing={4}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  disableFuture={true}
                  label="Select Start Date and Time"
                  renderInput={(props) => (
                    <DateTimeTextField
                      {...props}
                      inputProps={{
                        ...props.inputProps,
                        placeholder: "mm/dd/yyyy hh:mm am/pm",
                      }}
                    />
                  )}
                  value={selectedStartDateTime}
                  onChange={setSelectedStartDateTime}
                />
                <DateTimePicker
                  disableFuture={true}
                  minDateTime={
                    selectedStartDateTime
                      ? new Date(selectedStartDateTime).setMinutes(
                          selectedStartDateTime.getMinutes() + 5
                        )
                      : undefined
                  }
                  disabled={!selectedStartDateTime}
                  label="Select End Date and Time"
                  renderInput={(props) => (
                    <DateTimeTextField
                      {...props}
                      inputProps={{
                        ...props.inputProps,
                        placeholder: "mm/dd/yyyy hh:mm am/pm",
                      }}
                    />
                  )}
                  value={selectedEndDateTime}
                  onChange={setSelectedEndDateTime}
                />
              </LocalizationProvider>
            </Stack>

            <PresetTimePickerGroup onClose={onClose} onSelect={onSelect} />

            <Stack
              direction="row"
              sx={{
                alignSelf: timePickerBreakpoints ? "center" : "flex-end",
              }}
            >
              <Button
                disabled={!isSubmitEnabled}
                onClick={handleSubmit}
                size="large"
                variant="contained"
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </CalendarContainer>
      </Box>
    </Modal>
  );
};

export default TimePickerModal;
