import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import MuiToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";

const StyledToggleButtonGroup = styled(MuiToggleButtonGroup)(
  ({ theme, size }) => ({
    border: "1px solid",
    borderColor: theme.palette.grey[300],
    borderRadius: "50em",

    [`& .${toggleButtonGroupClasses.grouped}`]: {
      border: 0,
      borderRadius: "50em",
      minWidth: 65,
      color: theme.palette.secondary.main,
      ...(size === "small" && {
        paddingLeft: 14,
        paddingRight: 14,
      }),

      "&:not(:first-of-type)": {
        borderLeft: 0,
      },

      "&:not(:first-of-type), &:not(:last-of-type)": {
        borderTopLeftRadius: "50em",
        borderTopRightRadius: "50em",
        borderBottomLeftRadius: "50em",
        borderBottomRightRadius: "50em",
        marginLeft: 0,
      },

      [`&.${toggleButtonGroupClasses.disabled}`]: {
        border: 0,
      },

      "&.Mui-selected": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        transform: "scale(1.03)",

        "&:hover": {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  })
);

const ToggleButtonGroup = ({ value, onChange, options, ...delegatedProps }) => {
  const [selectedValue, setSelectedValue] = useState(value);

  function handleChange(event, selectedValue) {
    if (selectedValue !== null) {
      setSelectedValue(selectedValue);

      if (typeof onChange === "function") {
        onChange(event, selectedValue);
      }
    }
  }

  return (
    <StyledToggleButtonGroup
      exclusive
      onChange={handleChange}
      value={selectedValue}
      {...delegatedProps}
    >
      {options.map(({ label, value, ...rest }) => (
        <MuiToggleButton key={label} value={value} {...rest}>
          {label}
        </MuiToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

export default ToggleButtonGroup;
