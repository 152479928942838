import { Box, Stack, Tooltip, Typography, useScrollTrigger } from "@mui/material";
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EventGroupsFeed from "../components/Events/EventGroupsFeed";
import EventFeedTimeFilter from "../components/Events/components/EventFeedTimeFilter";
import EventSummary from "../components/Events/components/EventSummary";
import FeedContainer from "../components/FeedContainer";
import { PIPELINE_SCHEDULE_TRANSLATION, severityColorMapping } from "../constants/commonConstants";
import { EVENTS_FEED_FILTER_RESET, GROUP_EVENT_LIST_RESET } from "../constants/eventConstants";
import { SAAS_EVENT_SUMMARY_FEATURE_TOGGLE } from "../constants/featureFlagConstants";
import { typography } from "../shared/theme-constants";
import { getFormattedTimePeriod } from "../utils/dateUtils";
import { isEnabled } from "../utils/featureFlags";
import SelectedFiltersBox from "../components/Events/components/SelectedFilters";
import { isEmpty } from "../utils/is";

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: process.env.REACT_APP_DEV_REV_APP_ID ? 22 : 16, right: process.env.REACT_APP_DEV_REV_APP_ID ? 80: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

const EventGroupScreen = () => {
  const dispatch = useDispatch();
  let isEventSummaryEnabled = isEnabled(SAAS_EVENT_SUMMARY_FEATURE_TOGGLE);
  const eventsTimeFilter = useSelector((state) => state.eventsFeedFilters?.timestamps);
  const eventsSummaryFilter = useSelector((state) => state.eventsFeedFilters?.summaryFilters);

  useEffect(() => {
    return () => {
      dispatch({
        type: GROUP_EVENT_LIST_RESET,
        payload: [],
      });
      // Reset the filters applied on events feed
      dispatch({ type: EVENTS_FEED_FILTER_RESET });
    };
  }, []);

  return (
    <FeedContainer>
      {isEventSummaryEnabled ? (
        <Stack direction="column" gap={5}>
          <Stack
            sx={(theme) => ({
              flexDirection: {
                xs: "column",
                st: "row",
              },
              minHeight: 50,
              [theme.breakpoints.down("st")]: {
                alignItems: "center",
                gap: 4,
                minHeight: 30,
              },
              justifyContent: "space-between",
              alignItems: "center",
            })}
            id="back-to-top-anchor"
          >
            <Stack direction="column">
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(24),
                  color: "var(--comet)",
                  fontWeight: typography.font_weight.semiBold,
                })}
              >
                Event Summary
              </Typography>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(16),
                  color: "var(--comet)",
                  fontWeight: typography.font_weight.light,
                })}
              >
                {getFormattedTimePeriod(
                  eventsTimeFilter?.startDate,
                  eventsTimeFilter?.endDate,
                  PIPELINE_SCHEDULE_TRANSLATION[eventsTimeFilter?.pipelineSchedule]
                )}
              </Typography>
            </Stack>
            <EventFeedTimeFilter />
          </Stack>
          <EventSummary
            startTime={eventsTimeFilter?.startDate}
            endTime={eventsTimeFilter?.endDate}
            pipelineSchedule={eventsTimeFilter?.pipelineSchedule}
          />
          {!isEmpty(eventsSummaryFilter) ? (
            <SelectedFiltersBox />
          ) : (
            <></>
          )}
        </Stack>
      ) : (
        <></>
      )}

      <EventGroupsFeed
        startTime={eventsTimeFilter?.startDate}
        endTime={eventsTimeFilter?.endDate}
      />
      <ScrollTop>
      <Tooltip title="Scroll back to top" placement="top-end">
        <Fab
          size="small"
          aria-label="scroll back to top"
          sx={{
            backgroundColor: "var(--mariner)",
            "&:hover": {
              backgroundColor: "var(--mariner)",
            },
          }}
        >
          <KeyboardArrowUpIcon sx={{ color: "var(--white)" }} />
        </Fab>
        </Tooltip>
      </ScrollTop>
    </FeedContainer>
  );
};

export default EventGroupScreen;
