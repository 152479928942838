import { isEmpty, isObject } from "./is";

export function merge(target, source) {
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, { [key]: source[key] });
        } else {
          output[key] = merge(target[key], source[key]);
        }
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

/**
 * Creates an object composed of the picked object properties.
 *
 * @param {object} object The source object
 * @param {array} keys The property paths to pick
 * @returns {object} Returns the new object with properties specified in keys array
 */
export function pick(object, keys) {
  const picked = {};

  for (let key of Object.keys(object)) {
    if (keys.includes(key)) {
      picked[key] = object[key];
    }
  }

  return picked;
}

/**
 *
 * @param {Object} obj - The source object to omit specified keys from
 * @param {(string|Array<string>)} keysToOmit - string or array of keys to exclude
 * @returns {Object} A new object with the specified keys omitted
 */
export function omit(obj, keysToOmit) {
  if (isEmpty(obj) || !keysToOmit.length) return obj;

  if (typeof keysToOmit === "string") {
    keysToOmit = [keysToOmit];
  }

  return Object.keys(obj).reduce((acc, key) => {
    if (!keysToOmit.includes(key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

/**
 * Checks if an object has an own property with the given key
 *
 * @param {Object} object - The object to check
 * @param {string} key - The key to check
 * @returns {boolean} True if the object has an own property with the given key, false otherwise
 */
function hasOwnProperty(object, key) {
  return Object.prototype.hasOwnProperty.call(object, key);
}

/**
 * Gets the value at the given path in an object
 *
 * @param {Object} object - The object to query
 * @param {string|string[]} path - The path of the property to get, in dot-notation or array of keys
 * @returns {*} The value at the given path, or undefined if the path doesn't exist in the object
 */
export function get(object, path) {
  if (object == null) return undefined;

  const pathArray = Array.isArray(path) ? path : path.split(".");
  const length = pathArray.length;

  let value = Object.assign({}, object);
  for (let i = 0; i < length; i++) {
    const key = pathArray[i];
    if (!isObject(value) || !hasOwnProperty(value, key)) {
      return undefined;
    }
    value = value[key];
  }

  return value;
}
