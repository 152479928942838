import Icon from "../../components/common/Icon";

function parseStringToBoolean(value) {
  return value === "true";
}

function transformToObject(value, label) {
  if (value === "") {
    return { id: null, name: null };
  }

  return { id: value, name: label };
}

/**
 * `accessor` is dot-notation path used when value of the field is nested within object
 * `formatValue` is used for formating input value
 * `renderValue` is used for specifying rendering value displayed on read-only view
 * `transformValue` is used to transform values to required type before sending to server
 * `options` is an array of {value, label} objects or primitive values. If empty, it'll be populated with DB values.
 */

const baseAttributes = ( currencySymbol ) => {

  return [
    {
      title: "Display Name",
      name: "kpi_display_name",
    },
    {
      title: "Funnel/Tree",
      name: "funnel",
      accessor: "funnel.id",
      formatValue: (_value, data) => data.funnel.name,
      transformValue: (value, selectedOption) =>
        transformToObject(value, selectedOption?.name),
      options: [],
    },
    {
      title: "Category",
      name: "category",
      accessor: "category.id",
      formatValue: (_value, data) => data.category.name,
      transformValue: (value, selectedOption) =>
        transformToObject(value, selectedOption?.name),
      options: [],
    },
    {
      title: "Tier",
      name: "tier",
      options: [1, 2, 3],
      transformValue: (value) => Number(value),
    },
    {
      title: "Description",
      name: "description",
    },
    {
      title: "Impact to Topline",
      name: "impact_to_topline",
      suffix: "%",
      formatValue: (value) => {
        if (!value) return value;
        const valueInPercents = value * 100;

        return isNaN(valueInPercents) ? value : valueInPercents.toFixed(1);
      },
      transformValue: (value) => {
        const numericValue = Number(value);

        return isNaN(numericValue) ? numericValue : numericValue / 100;
      },
      inputProps: {
        type: "number",
        inputMode: "numeric",
        pattern: "[0-9]*[.,]?[0-9]+",
        step: "0.01",
        min: 0,
        max: 100,
      },
    },
    {
      title: "Active",
      name: "is_active",
      options: [
        {
          value: "true",
          label: "Yes",
        },
        {
          value: "false",
          label: "No",
        },
      ],
      transformValue: parseStringToBoolean,
      disabled: true,
    },
    {
      title: "Metric's Value (GMV/unit)",
      name: "dollar_impact",
      prefix: currencySymbol,
      formatValue: (value) => {
        if (!value) return value;
        const numericValue = Number(value);

        return isNaN(numericValue) ? value : numericValue.toFixed(1);
      },
      inputProps: {
        type: "number",
      },
    },
    {
      title: "Optimization Objective",
      name: "accent",
      options: [
        {
          value: "positive",
          label: "Up",
        },
        {
          value: "negative",
          label: "Down",
        },
      ],
      renderValue: ({ value, formattedValue }) => {
        const iconName = value === "positive" ? "arrow-upward" : "arrow-downward";

        return (
          <>
            <Icon name={iconName} size="20px" /> {formattedValue}
          </>
        );
      },
    },
  ];
};

const metricSourceAttributes = [
  {
    title: "Type",
    name: "is_derived",
    options: [
      {
        value: "true",
        label: "Derived",
      },
      {
        value: "false",
        label: "Observed",
      },
    ],
    transformValue: parseStringToBoolean,
  },
  {
    title: "Aggregation",
    name: "aggregation_operation",
    disabled: true,
  },
  {
    title: "Extraction Expression",
    name: "extraction_funnel_expression",
    formatValue: (value) => {
      // Replace escaped single and double quotes with regular ones, if not null
      if (!value) return value;
      return value.replace(/\\'/g, "'").replace(/\\"/g, '"');
    }
  },
  {
    name: "extraction_funnel_aggregation_operation",
    title: "Extraction Aggregation"
  }
];

const dataScienceAttributes = [
  {
    title: "Suppress Alerts Threshold",
    name: "suppress_threshold",
  },
  {
    title: "Zero as Outlier",
    name: "is_outlier",
  },
  {
    title: "Auto-zoom",
    name: "auto_zoom",
  },
];

export const attributesBySections = (currencySymbol = "$") => {
  return [
    {
      title: "Base",
      items: baseAttributes(currencySymbol),
    },
    {
      title: "Metric Source",
      items: metricSourceAttributes,
    },
    // { title: "Data Science", items: dataScienceAttributes },
  ];
};
