export const INSIGHT_OPEN_REQUEST = "INSIGHT_OPEN_REQUEST";
export const INSIGHT_OPEN_SUCCESS = "INSIGHT_OPEN_SUCCESS";
export const INSIGHT_OPEN_FAIL = "INSIGHT_OPEN_FAIL";

export const INSIGHT_IMPORTANT_REQUEST = "INSIGHT_IMPORTANT_REQUEST";
export const INSIGHT_IMPORTANT_SUCCESS = "INSIGHT_IMPORTANT_SUCCESS";
export const INSIGHT_IMPORTANT_FAIL = "INSIGHT_IMPORTANT_FAIL";

export const INSIGHT_SNOOZE_REQUEST = "INSIGHT_SNOOZE_REQUEST";
export const INSIGHT_SNOOZE_SUCCESS = "INSIGHT_SNOOZE_SUCCESS";
export const INSIGHT_SNOOZE_FAIL = "INSIGHT_SNOOZE_FAIL";
export const INSIGHT_SNOOZE_COMPLETE = "INSIGHT_SNOOZE_COMPLETE";

export const INSIGHT_FLAG_REQUEST = "INSIGHT_FLAG_REQUEST";
export const INSIGHT_FLAG_SUCCESS = "INSIGHT_FLAG_SUCCESS";
export const INSIGHT_FLAG_FAIL = "INSIGHT_FLAG_FAIL";

export const INSIGHT_ARCHIVE_REQUEST = "INSIGHT_ARCHIVE_REQUEST";
export const INSIGHT_ARCHIVE_SUCCESS = "INSIGHT_ARCHIVE_SUCCESS";
export const INSIGHT_ARCHIVE_FAIL = "INSIGHT_ARCHIVE_FAIL";

export const INSIGHT_RESOLVE_REQUEST = "INSIGHT_RESOLVE_REQUEST";
export const INSIGHT_RESOLVE_SUCCESS = "INSIGHT_RESOLVE_SUCCESS";
export const INSIGHT_RESOLVE_FAIL = "INSIGHT_RESOLVE_FAIL";

export const INSIGHT_LIST_REQUEST = "INSIGHT_LIST_REQUEST";
export const INSIGHT_LIST_SUCCESS = "INSIGHT_LIST_SUCCESS";
export const INSIGHT_LIST_FAIL = "INSIGHT_LIST_FAIL";

export const INSIGHT_DETAILS_REQUEST = "INSIGHT_DETAILS_REQUEST";
export const INSIGHT_DETAILS_SUCCESS = "INSIGHT_DETAILS_SUCCESS";
export const INSIGHT_DETAILS_FAIL = "INSIGHT_DETAILS_FAIL";

export const INSIGHT_COMMENTS_REQUEST = "INSIGHT_COMMENTS_REQUEST";
export const INSIGHT_COMMENTS_SUCCESS = "INSIGHT_COMMENTS_SUCCESS";
export const INSIGHT_COMMENTS_FAIL = "INSIGHT_COMMENTS_FAIL";
