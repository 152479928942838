export const SET_SNACKBAR = "notification/snackbar/set";
export const UNSET_SNACKBAR = "notification/snackbar/unset";

const initialState = {
    snackbarOpen: false,
    snackbarType: "info",
    snackbarMessage: "",
    position: { vertical: "top", horizontal: "center" },
};

// right now we will fixate the position of sncakbar to bottom center
// We can tweak the code to dynamically update the position later
export const notificationSnackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SNACKBAR:
            const { snackbarOpen, snackbarMessage, snackbarType } = action;
            return { ...state, snackbarOpen, snackbarMessage, snackbarType };
        case UNSET_SNACKBAR:
            return { ...state, snackbarOpen: false };
        default:
            return state;
    }
};
