import React, { useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import useForm from "../../hooks/useForm";
import {
  ButtonLabel,
  StyledTitle,
  SummaryText,
} from "./styled-components/insight-summay/Title.styled";
import { areEqualAndNotEmpty } from "../../utils/stringUtils";
import { isNullOrEmpty } from "../../utils/is";
import { editTitleSummary } from "../../actions/insightTitleSummaryAction";
import { bp, theme } from "./styled-components/theme";
import { HTTP_STATUS } from "../../shared/enums/http-status";
import { GTAG_EVENTS, sendDataToGTM } from "../../utils/gtmHelper";
import { GTagArgsContext } from "../../context/GtagContext";
import { ERROR } from "../../constants/notificationConstants";
import { typography } from "../../shared/theme-constants";
import { stripMarkdown } from "../../services/markdown";

const TitleTextArea = styled("textarea")({
  marginLeft: 0,
  minWidth: 275,
  [bp.min(theme.tablet)]: {
    minWidth: 350,
  },
  [bp.min(theme.mobile)]: {
    minWidth: 430,
  },
  [bp.min(theme.iPadPro)]: {
    minWidth: 550,
  },
});

const SummaryTextArea = styled("textarea")({
  minWidth: 200,
  [bp.min(theme.tablet)]: {
    minWidth: 350,
  },
  [bp.min(theme.mobile)]: {
    minWidth: 430,
  },
  [bp.min(theme.iPadPro)]: {
    minWidth: 470,
  },
});

function getRowsNumber(summary) {
  if (!summary) return 3;
  if (summary.length < 67) return 1;
  if (summary.length < 131) return 2;

  return 3;
}

export const InsightTitleSummaryEdit = ({
  title,
  summary,
  allowEdit,
  isModal,
  groupId,
  insightId,
  onEditCancel,
  setLocalTitleSummary,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const gTagArgsContext = useContext(GTagArgsContext);
  const [loading, setLoading] = useState(false);
  const [titleServerValue, setTitleServerValue] = useState(title);
  const [summaryServerValue, setSummaryServerValue] = useState(summary);
  const initialInputValues = useMemo(
    () => ({
      title: stripMarkdown(titleServerValue),
      summary: summaryServerValue,
    }),
    [summaryServerValue, titleServerValue]
  );
  // get info if modal with individual insight is up
  const modal = useSelector((state) => state.modal);

  function validate(inputs) {
    const errors = {};
    if (!inputs.title) {
      errors.title = "Title cannot be empty";
    }

    if (!inputs.summary) {
      errors.summary = "Summary cannot be empty";
    }

    if (
      areEqualAndNotEmpty(inputs.title, titleServerValue) &&
      areEqualAndNotEmpty(inputs.summary, summaryServerValue)
    ) {
      errors.title = "Please update either title or summary";
    }

    if (!isNullOrEmpty(errors.title) || !isNullOrEmpty(errors.summary)) {
      dispatch({ type: ERROR, payload: errors.title || errors.summary });
    }

    return errors;
  }

  // Call edit API on click of save
  const updateTitleOrSummary = useCallback(
    (inputs, { resetForm, setInputs }) => {
      setLoading(true);
      const payload = {
        old_data: {
          title: titleServerValue,
          summary: summaryServerValue,
        },
        new_data: {
          title: inputs.title,
          summary: inputs.summary,
        },
      };

      // conditionally triggers two different API points
      // when individual insight is in modal then calls different API point
      // groupId is used for unexpanded and Level 1 title/summary edits
      // insightId is used for title/summary edits in modal
      dispatch(
        editTitleSummary(
          insightId ? insightId : groupId,
          payload,
          insightId ? true : false
        )
      )
        .then((data) => {
          onEditCancel();
          setLoading(false);

          if (!areEqualAndNotEmpty(titleServerValue, inputs.title)) {
            // Title is updated
            sendDataToGTM(GTAG_EVENTS.TITLE_EDITED, gTagArgsContext);
          }
          if (!areEqualAndNotEmpty(summaryServerValue, inputs.summary)) {
            // Summary is updated
            sendDataToGTM(GTAG_EVENTS.SUMMARY_EDITED, gTagArgsContext);
          }

          setTitleServerValue(inputs.title);
          setSummaryServerValue(inputs.summary);
          setLocalTitleSummary(inputs);
        })
        .catch((err) => {
          if (err?.response?.status === HTTP_STATUS.UNAUTHORIZED) {
            navigate("/login");
          }
        })
        .finally(() => {
          setLoading(false);
          onEditCancel();
          // reset the title and summary, in case of API error
          resetForm();
          setInputs(initialInputValues);
        });
    },
    [
      dispatch,
      gTagArgsContext,
      groupId,
      initialInputValues,
      isModal,
      modal.modalProps.insightId,
      navigate,
      onEditCancel,
      setLocalTitleSummary,
      summaryServerValue,
      titleServerValue,
    ]
  );

  const focusInputField = useCallback((node) => {
    if (node) {
      node.focus();
    }
  }, []);

  function handleFormCancel() {
    onEditCancel();
    resetForm();
  }

  const { inputs, handleInputChange, handleSubmit, resetForm, setInputs } =
    useForm(initialInputValues, validate, updateTitleOrSummary);

  return (
    <form
      data-snippyly-comment-disabled
      onSubmit={handleSubmit}
      style={{ width: "calc(100% - 150px)" }}
    >
      <StyledTitle $allowEdit={allowEdit}>
        <TitleTextArea
          ref={focusInputField}
          rows={1}
          name="title"
          value={inputs.title}
          onChange={handleInputChange}
          className="inline-text_input inline-text_input--active"
        />
      </StyledTitle>

      <SummaryText $allowEdit={allowEdit} $isEditing>
        <Typography
          sx={{ font: "inherit", fontWeight: typography.font_weight.bold }}
          component="span"
        >
          Summary:{" "}
        </Typography>

        <SummaryTextArea
          rows={getRowsNumber(inputs.summary)}
          name="summary"
          value={inputs.summary}
          onChange={handleInputChange}
          className="inline-text_input inline-text_input--active"
        />
      </SummaryText>

      {allowEdit && (
        <Stack
          flexDirection="row"
          sx={{
            position: "absolute",
            right: isModal ? 35 : 20,
            top: 20,
          }}
        >
          <Box sx={{ mt: "-4px", mr: "-5px" }}>
            <Button size="small" variant="text" onClick={handleFormCancel}>
              <ButtonLabel>Cancel</ButtonLabel>
            </Button>

            <Button
              size="small"
              variant="text"
              disabled={loading}
              type="submit"
            >
              <ButtonLabel>
                Save {loading && <CircularProgress size="0.8rem" />}
              </ButtonLabel>
            </Button>
          </Box>
        </Stack>
      )}
    </form>
  );
};
