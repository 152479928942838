export const errorTitle = {
  BAD_REQUEST: "Bad Request",
  UNAUTHORIZED: "Session Expired",
  FORBIDDEN: "Not Allowed",
  NOT_FOUND: "Not Found",
  SERVER_ERROR: "Internal Server Error",
};

export const errorMessage = {
  BAD_REQUEST:
    "There is a missing parameter in the request, please check if the request is being made correctly.",
  UNAUTHORIZED:
    "It looks like you were idle for a long time or your session has expired. For your safety you will need to login again.",
  FORBIDDEN:
    "It looks like you don't have access to this resource. Please check with your administrator.",
  NOT_FOUND: "It looks like there is no data for this __resource__.",
  PAGE_NOT_FOUND:
    "Sorry, we couldn't find the page you are looking for, maybe it doesn't exist.",
  SERVER_ERROR:
    "It looks like our servers are not reachable at this moment. Please try again after sometime.",
  CARD_ERROR:
    "Something went wrong on our side, we are trying to fix the problem. Please try again after sometime.",
  UNEXPECTED_ERROR:
    "An unexpected error occurred. Our team has been notified and is working to resolve the issue. Please try again later.",
};
