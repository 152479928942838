import React from "react";
import styled from "styled-components";

import { colors } from "../../../shared/theme-constants";
import Icon from "../Icon";
import { StyledTooltipHeader } from "../styled-components/Tooltip.styled";

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: -5px;
`;

export const RadioItem = styled.div`
  display: flex;
  flex-direction: row;

  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
`;

export const RadioButton = ({
  name,
  label,
  value,
  title,
  isChecked,
  handleChange,
}) => {
  const formedTitle = "You've marked this '__'";
  return (
    <StyledTooltipHeader
      title={isChecked ? formedTitle.replace("__", title) : title}
      placement="bottom"
    >
      <RadioItem>
        <input
          type="radio"
          name={name}
          id={value}
          value={value}
          checked={isChecked}
          onChange={() => {}}
        />
        <Icon
          name={label}
          title={title}
          size="1.5625rem"
          showcursor="true"
          strokecolor={isChecked ? colors.emoji_selected : colors.emoji_default}
          color={isChecked ? colors.emoji_selected_bg : colors.white}
          onClick={() => handleChange(value)}
        />
      </RadioItem>
    </StyledTooltipHeader>
  );
};
