import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useOutletContext, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Loading } from "../common/styled-components";
import { Card } from "@mui/material";
import Icon from "../common/Icon";
import { typography } from "../../shared/theme-constants";
import styled from "@emotion/styled";
import axios from "axios";
import { useSelectedTenant } from "../../hooks/useSelectedTenant";
import { HTTP_STATUS } from "../../shared/enums/http-status";

const DATA_CONNECTION_STATUS_MSGS = [
  "Fetching data from the data source",
  "Syncing the data",
  "Processing the data",
  "Preparing the data schema",
  "Almost there",
];

const SyncTitleCard = styled("span")(({ theme }) => ({
  paddingTop: theme.typography.pxToRem(30),
  fontSize: theme.typography.pxToRem(35),
  color: "white",
  fontWeight: typography.font_weight.mediumx,
}));
const SyncCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "15% 0 15% 0",
  maxWidth: theme.typography.pxToRem(920),
  maxHeight: theme.typography.pxToRem(769),
  height: "100%",
  width: "100%",
  justifyContent: "space-around",
  background: "#112785",
  minHeight: "500px",
  boxShadow: "0px 4px 22px 0px #11278540",
}));
const RotateLoader = styled("div")(({ theme }) => ({
  WebkitAnimation: "rotation 2s infinite linear",
  "@keyframes rotation": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(359deg)",
    },
  },
}));

const SyncingData = () => {
  const [activeStep, setActiveStep] = useOutletContext();
  const selectedTenant = useSelectedTenant();
  const [searchParams, setSeachParams] = useSearchParams();
  const [connectionMessage, setConnectionMessage] = useState(DATA_CONNECTION_STATUS_MSGS[0]);

  // this function will trigger the Extraction Job
  const triggerExtractionByExtractionId = async (connector_id) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}connectors/${connector_id}/sync`, {});
  };

  // Once above function is complete we will trigger the sync status
  const checkExtractionSyncStatus = () => {
    const queryParams = {
      tenant_id: selectedTenant,
      status: "READY,SUCCESSFUL",
      connector_id: searchParams.get("id"),
    };

    const params = new URLSearchParams(queryParams);

    return axios.get(`${process.env.REACT_APP_BASE_URL}extractions`, { params });
  };

  // Define function that will replicate metrics and dimensions defined in transformation schema template
  const replicateTransformedMetricsAndDimensions = async (connector_id, connector_name) => {
    return axios.post(
      `${process.env.REACT_APP_BASE_URL}metrics_hub/onboard/replicate`,
      {
        from_tenant_id: `${connector_name}_template`,
        to_tenant_id: selectedTenant,
        datasource_id: connector_id,
      }
    );
  };

  // Set timeout to rotate through the messages
  useEffect(() => {
    const interval = setInterval(() => {
      setConnectionMessage((prevMessage) => {
        const index = DATA_CONNECTION_STATUS_MSGS.indexOf(prevMessage);
        if (index === DATA_CONNECTION_STATUS_MSGS.length - 1) {
          return DATA_CONNECTION_STATUS_MSGS[0];
        } else {
          return DATA_CONNECTION_STATUS_MSGS[index + 1];
        }
      });
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const { isLoading: triggerExtractionInProcess, data: triggerExtractionResponse } = useQuery({
    queryKey: ["datasources", searchParams.get("id")],
    queryFn: () => triggerExtractionByExtractionId(searchParams.get("id")),
    refetchOnWindowFocus: "always",
    refetchInterval: 5000,
    retry: (failureCount, error) => {
      if (error.response && error.response.data && error.response.data.schema_info) {
        return true;
      }
      return false;
    },
    enabled:
      searchParams.get("ongoing_extraction") === "false" && searchParams.get("forced") === "true" && !!!searchParams.get("transform"),
  });
  const { isLoading: connectorSyncStatusProgress, data: connectorSyncStatusResponse } = useQuery(
    "connector-sync-status-check",
    checkExtractionSyncStatus,
    {
      refetchInterval: 5000,
      enabled:
        !!triggerExtractionResponse ||
        ((searchParams.get("ongoing_extraction") === "false" &&
          searchParams.get("forced") === "false") ||
        searchParams.get("ongoing_extraction") === "true") && !!!searchParams.get("transform"),
    }
  );
  const { isLoading: replicateTransformedMetricsAndDimensionsInProcess, data: replicateTransformedMetricsAndDimensionsResponse} = useQuery({
    queryKey: ["datasources", searchParams.get("id")],
    queryFn: () => replicateTransformedMetricsAndDimensions(searchParams.get("id"), searchParams.get("transform")),
    retry: (failureCount, error) => {
      if (error.response && error.response.data) {
        return true;
      }
      return false;
    },
    enabled: !!searchParams.get("transform"),
  });

  useEffect(() => setActiveStep(2));

  if (connectorSyncStatusResponse && connectorSyncStatusResponse?.data.length !== 0 && !!!searchParams.get("transform")) {
    return (
      <Navigate
        to="/onboarding/configure-metrics"
        state={{ extraction_job_id: connectorSyncStatusResponse?.data[0]?.extraction_job_id }}
        replace
      />
    );
  }

  if (replicateTransformedMetricsAndDimensionsResponse && replicateTransformedMetricsAndDimensionsResponse?.status === HTTP_STATUS.OK) {
    return (
      <Navigate
        to="/onboarding/finish"
        replace
      />
    );
  }

  return (
    <div
      style={{ display: "flex", justifyContent: "center", padding: "0 12% 0 12%", marginTop: "5%" }}
    >
      {/* {triggerExtractionInProcess && (
        <SyncCard>
          <Icon name="sync-loader"></Icon>
          <SyncTitleCard>Data sync in progress</SyncTitleCard>
          <Icon name="sync-loader"></Icon>
        </SyncCard>
      )} */}
      {(triggerExtractionInProcess ||
        connectorSyncStatusProgress ||
        connectorSyncStatusResponse?.data.length === 0) && (
        <SyncCard>
          <RotateLoader>
            <Icon name="sync-status-pending" size="150px"></Icon>
          </RotateLoader>
          <SyncTitleCard>{connectionMessage}</SyncTitleCard>
        </SyncCard>
      )}
    </div>
  );
};

export default SyncingData;
