import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { colors, typography } from "../../shared/theme-constants";

const StyledCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 8),
  borderRadius: 12,
  backgroundColor: colors.white,
  position: "relative",
}));

const StyledTitle = styled("div")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(32),
  fontWeight: typography.font_weight.black,
  lineHeight: "normal",
  fontsize: "normal",
  color: colors.blue["925"],
}));

const StyledSubtitle = styled("div")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(22),
  fontWeight: typography.font_weight.regular,
  lineHeight: 1.5,
  color: theme.palette.secondary.main,
  marginTop: theme.spacing(3)
}));

const DigestCard = ({ title, subtitle, children, sx, ...restProps }) => {
  return (
    <StyledCard sx={sx} {...restProps}>
      <StyledTitle>
        {title}
      </StyledTitle>
      {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
      {children}
    </StyledCard>
  );
};

export default DigestCard;
