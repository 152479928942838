import { useEffect, useState } from "react";
import axios from "axios";
import { HTTP_STATUS } from "../shared/enums/http-status";
import { useNavigate } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.config.headers.mock) {
      return Promise.resolve({
        data: error.config.headers.data,
        status: 200,
        statusText: "OK",
        headers: {},
        config: error.config,
      });
    }

    return Promise.reject(error);
  }
);

/**
 *
 * @param {string} endpoint
 * @param {Object} options
 * @returns object containing response, error, status, and boolean values derived from status
 */
export const useAxios = (endpoint, config = {}) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [status, setStatus] = useState("loading");

  useEffect(() => {
    let didCancel = false;

    setStatus("loading");

    axios
      .get(BASE_URL.concat(endpoint), {
        params : new URLSearchParams(config?.params)
      })
      .then((response) => {
        if (!didCancel) {
          setData(response.data);
          setStatus("success");
        }
      })
      .catch((error) => {
        if (!didCancel) {
          setError(error);
          setStatus("error");

          if (error.response?.status === HTTP_STATUS.UNAUTHORIZED) {
            navigate("/login");
          }
        }
      });

    return () => {
      didCancel = true;
    };
    // navigate excluded from deps array because it changes on every route change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, JSON.stringify(config)]);

  return {
    data,
    error,
    status,
    isLoading: status === "loading",
    isSuccess: status === "success",
    isError: status === "error",
  };
};
