import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PREFERRED_COUNTRIES_LIST = ["us", "in"];
const DEFAULT_COUNTRY = "us";
const PhoneNumberInput = ({ number, onChange, ...delegatedProps }) => {
  const [phoneNumber, setPhoneNumber] = useState(number);

  const handlePhoneNumberChange = (value, data, event, formattedValue) => {
    setPhoneNumber(value);
    if (typeof onChange === "function") {
      onChange(value, data, event, formattedValue);
    }
  };

  return (
    <PhoneInput
      specialLabel={""}
      country={DEFAULT_COUNTRY}
      value={phoneNumber}
      preferredCountries={PREFERRED_COUNTRIES_LIST}
      enableSearch
      inputProps={{
        name: "phone",
        required: true,
        autoFocus: true,
      }}
      onChange={handlePhoneNumberChange}
      {...delegatedProps}
    ></PhoneInput>
  );
};

export default PhoneNumberInput;
