import { Container, Stack, styled } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { colors, typography } from "../../../shared/theme-constants";
import { StyledButton } from "../mui-wrapper-components/Button";

const ErrorTitle = styled("span")`
  color: ${colors.blue[900]};
  font-size: 4rem;
  font-weight: ${typography.font_weight.semiBold};
  text-align: center;
`;
const ErrorMessage = styled("div")`
  color: ${colors.blue[800]};
  font-size: 1.5rem;
  font-weight: ${typography.font_weight.semiBold};
  text-align: center;
  max-width: 48rem;
  margin: 0 auto;
`;
const SuggestedAction = styled("div")`
  color: ${colors.blue[700]};
  font-size: 1.2rem;
  font-weight: ${typography.font_weight.semiBold};
  text-align: center;
`;

const ScreenError = ({
  title = "500",
  message,
  showButtons = true,
  children,
}) => {
  const navigate = useNavigate();
  const handleGoBackClick = () => {
    navigate(-1);
  };
  const handleGoHomeClick = () => {
    navigate("/");
  };

  return (
    <Container maxWidth="xl">
      <Stack gap={2} justifyContent="center" pt="100px">
        <ErrorTitle>{title}</ErrorTitle>
        <ErrorMessage>{message}</ErrorMessage>

        {children}

        {showButtons && (
          <Stack pt="20px">
            <SuggestedAction>You might want to try to </SuggestedAction>
            <Stack gap={2} direction="row" p="20px" justifyContent="center">
              <StyledButton onClick={handleGoBackClick}>Go Back</StyledButton>
              <SuggestedAction>or</SuggestedAction>
              <StyledButton onClick={handleGoHomeClick}>
                Start Over
              </StyledButton>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

export default ScreenError;
