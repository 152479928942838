import ContentLoader from "react-content-loader";
import { colors } from "../../shared/theme-constants";
import { range } from "../../utils/arrayHelper";

const ListLoader = ({ count, height = 32, paddingY = 8, width = 500 }) => {
  return (
    <ContentLoader
      speed={2}
      width={width}
      viewBox={`0 0 ${width} ${(paddingY + height + paddingY) * count}`}
      foregroundOpacity={0.1}
      backgroundColor={colors.gray[100]}
      style={{ width: "100%" }}
    >
      {range(0, count - 1).map((i) => {
        return (
          <rect
            key={i}
            x="0"
            y={(paddingY + height + paddingY) * i + paddingY}
            rx="5"
            ry="5"
            width="100%"
            height={height}
          />
        );
      })}
    </ContentLoader>
  );
};

export default ListLoader;
