import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Button, CircularProgress } from "@mui/material";
import { isAfter, isBefore, isEqual, subDays, subHours } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

import { PATTERN_TYPE } from "../../shared/constants/pattern-types";
import { ConvertJsonToCsv, exportAsZip } from "../../utils/convertJsonToCsv";

import {
  no_anomaly_headers,
  point_anomaly_headers,
  trend_change_headers,
  overlayHeaders,
  overlay_placeholders,
  rcaHeaders,
} from "../../shared/csv-templates";

import Icon from "./Icon";
import {
  DoD,
  MoM,
  OVERLAYS_GRANULARITY_MAP,
  WoW,
  YoY,
} from "../../constants/chartOverlayConstants";
import { pipelineSchedule, shouldShowOverlayOption } from "./ChartOverlays";
import { datePartsWithTZ } from "../../utils/dateUtils";
import { labels } from "../../shared/intl/labels";
import { groupBy } from "../../utils/arrayHelper";
import { GTAG_EVENTS, sendDataToGTM } from "../../utils/gtmHelper";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const overlayOptions = [
  { value: DoD, label: DoD },
  { value: WoW, label: WoW },
  { value: MoM, label: MoM },
  { value: YoY, label: YoY },
];

/**
 * This function is used for Trend CSV data preparation, specifically for below three columns
 * -> Point part of Old trend Line (Yes/No)
 * -> Point part of New trend Line (Yes/No)
 * -> Point is Changepoint for Trend (Yes/No)
 * It determines whether the given data point is within the pre-trend, post-trend or trend-change line
 */
const pointIsOn = (datapoint_ts, patternData) => {
  // #region trend dates for comparisons, read them from insight object
  const trendPrediction = patternData.trend_line.trend_prediction;
  const oldTrendDateRange = trendPrediction.pre_trendline.coords;
  const newTrendDateRange = trendPrediction.post_trendline.coords;
  const trendChangeTS = patternData.trend_line.trend_change.lastcp_ts;
  // Get the Date object for the provided timestamp (ISO)
  const dp_ts_date = new Date(datapoint_ts);
  // #endregion

  if (
    isAfter(dp_ts_date, new Date(oldTrendDateRange[0][0])) &&
    isBefore(dp_ts_date, new Date(oldTrendDateRange[1][0]))
  ) {
    // if given timestamp is after the old trend start date, and before the old trend end date,
    // then the given point lies within the old trend line
    return "OLD_TREND";
  } else if (
    isAfter(dp_ts_date, new Date(newTrendDateRange[0][0])) &&
    isBefore(dp_ts_date, new Date(newTrendDateRange[1][0]))
  ) {
    // if given timestamp is after the new trend start date, and before the new trend end date,
    // then the given point lies within the new trend line
    return "NEW_TREND";
  } else if (isEqual(dp_ts_date, new Date(trendChangeTS))) {
    // if given timestamp is equal to the trend change timestamp, then it lies on the trend change line
    return "TREND_CHANGE";
  }
};

// Calcualte the overlay start date based on the pipeline schedule
const getOverlayStartDate = (schedule, endDate) => {
  switch (schedule) {
    case pipelineSchedule.hourly:
      return convertDate(
        subHours(
          endDate,
          OVERLAYS_GRANULARITY_MAP[pipelineSchedule.hourly].timeInterval
        )
      );
    case pipelineSchedule.daily:
      return convertDate(
        subDays(
          endDate,
          OVERLAYS_GRANULARITY_MAP[pipelineSchedule.daily].timeInterval
        )
      );
    case pipelineSchedule.weekly:
      return convertDate(
        subDays(
          endDate,
          OVERLAYS_GRANULARITY_MAP[pipelineSchedule.weekly].timeInterval
        )
      );
  }
};

// Converts a given date to UTC
const convertDate = (date) => {
  return zonedTimeToUtc(date, "UTC").toISOString();
};

// Prepare the API paramaters for pattern_series call
const prepareParams = (pattern, startDate, endDate, rest = undefined) => {
  const paramsDetail = [
    ["tenant_id", pattern.tenant_id],
    ["kpi", pattern.kpi_name],
    ["pipeline_schedule", pattern.pipeline_schedule],
  ];
  if (rest) {
    paramsDetail.push([rest.key, rest.value]);
  }
  if (pattern.chart_ts_start) {
    paramsDetail.push(["timestamp_start", startDate || pattern.chart_ts_start]);
  }
  if (pattern.chart_ts_end) {
    paramsDetail.push(["timestamp_end", endDate || pattern.chart_ts_end]);
  }
  if (pattern.dim_name) {
    paramsDetail.push(["dim_name", pattern.dim_name]);
  }
  if (pattern.dim_val) {
    paramsDetail.push(["dim_val", pattern.dim_val]);
  }
  if (pattern.pipeline_guid) {
    paramsDetail.push(["pipeline_guid", pattern.pipeline_guid]);
  }
  return new URLSearchParams(paramsDetail);
};

/**
 * This component downloads the provided JSON as CSV into zip file
 */
export const DownloadCSV = ({ insightData, buttonStyle, groupId }) => {
  // Signifies when to start the downloading and zipping of the generated CSV files
  const [triggerDownload, setTriggerDownload] = useState(false);
  // Signifies whether the download request is in progress or not
  const [downloadRequested, setIsDownloadRequested] = useState(false);
  const [timeseriesData, setTimeSeriesData] = useState(undefined);
  const [rootcauseData, setRootcauseData] = useState(undefined);
  const [overlayData, setOverlayData] = useState(undefined);
  // Holds the array of blob objects, generated via JSON to CSV convertor
  const [csvBlobs, setCsvBlobs] = useState([]);

  const isMounted = useRef(true);

  // #endregion

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Gets triggered whenever downlaod is requested
  useEffect(() => {
    if (triggerDownload) {
      // Check if there is some data to download
      if (csvBlobs.length) {
        // Group the CSV blobs by KPI name
        const groupedCSVBlobs = groupBy(csvBlobs, "kpiName");
        // Export all KPI specific data as zip
        Object.keys(groupedCSVBlobs).map((key) => {
          exportAsZip(groupedCSVBlobs[key], key);
        });

        // #region Flush state objects after successful download
        setTriggerDownload(false);
        setCsvBlobs([]);
        setTimeSeriesData(undefined);
        if (overlayData?.length) {
          setOverlayData(undefined);
        }
        if (rootcauseData?.length) {
          setRootcauseData(undefined);
        }
        // #endregion
      }
    }
    if (isMounted.current) setIsDownloadRequested(false);
  }, [csvBlobs, overlayData?.length, rootcauseData?.length, triggerDownload]);

  // Gets triggered whenever `timeseriesData` is populated, either Spikes/Trends/No Spikes, and
  // generate the CSV file for pattern_series, with headers and data using the template files
  useEffect(() => {
    if (timeseriesData) {
      const { insight, data } = timeseriesData;
      if (insight.pattern_type === PATTERN_TYPE.SPIKES) {
        const point_anomaly_data = [];
        data.forEach((datapoint) => {
          const current = {
            timestamp: datapoint.timestamp,
            current_y: datapoint.result.y,
            yhat_upper: datapoint.result.yhat_upper,
            yhat_lower: datapoint.result.yhat_upper,
            anomaly: datapoint.result.anomaly === "True" ? "Yes" : "No",
          };
          point_anomaly_data.push(current);
        });

        // Convert timeseries data
        const spikeBlob = ConvertJsonToCsv(
          point_anomaly_headers,
          point_anomaly_data
        );
        setCsvBlobs((prevState) => [
          ...prevState,
          {
            filename: "spikes_chart_data",
            blob: spikeBlob,
            kpiName: insight.kpi_display_name,
          },
        ]);
      } else if (insight.pattern_type === PATTERN_TYPE.TREND_CHANGE) {
        const trend_change_data = [];

        data.forEach((datapoint) => {
          const current = {
            timestamp: datapoint.timestamp,
            current_y: datapoint.result.y,
            yhat_upper: datapoint.result.yhat_upper,
            yhat_lower: datapoint.result.yhat_lower,
            is_point_on_old_trend_line:
              pointIsOn(datapoint.timestamp, insight.pattern_data) ===
              "OLD_TREND"
                ? "Yes"
                : "No",
            is_point_on_new_trend_line:
              pointIsOn(datapoint.timestamp, insight.pattern_data) ===
              "NEW_TREND"
                ? "Yes"
                : "No",
            is_point_trend_change_point:
              pointIsOn(datapoint.timestamp, insight.pattern_data) ===
              "TREND_CHANGE"
                ? "Yes"
                : "No",
            expected_value:
              pointIsOn(datapoint.timestamp, insight.pattern_data) ===
              "OLD_TREND"
                ? insight.expected_value
                : "",
            current_value:
              pointIsOn(datapoint.timestamp, insight.pattern_data) ===
              "NEW_TREND"
                ? insight.current_value
                : "",
          };
          trend_change_data.push(current);
        });

        const trendBlob = ConvertJsonToCsv(
          trend_change_headers,
          trend_change_data
        );

        setCsvBlobs([
          {
            filename: "trend_chart_data",
            blob: trendBlob,
            kpiName: insight.kpi_display_name,
          },
        ]);
        setTriggerDownload(true);
      } else if (insight.pattern_type === PATTERN_TYPE.NO_SPIKES) {
        const no_anomaly_data = [];
        data.forEach((datapoint) => {
          const current = {
            timestamp: datapoint.timestamp,
            current_y: datapoint.result.y,
            yhat_upper: datapoint.result.yhat_upper,
            yhat_lower: datapoint.result.yhat_upper,
            anomaly: datapoint.result.anomaly,
          };
          no_anomaly_data.push(current);
        });

        const noSpikeBlob = ConvertJsonToCsv(
          no_anomaly_headers,
          no_anomaly_data
        );

        setCsvBlobs([
          {
            filename: "no_anomaly_chart_data",
            blob: noSpikeBlob,
            kpiName: insight.kpi_display_name,
          },
        ]);
        setTriggerDownload(true);
      }
    }
  }, [timeseriesData]);

  // Gets triggered whenever `overlayData` is populated, and
  // generate the CSV file for overlays, with headers and data using the template files
  useEffect(() => {
    if (overlayData) {
      const { insight, data } = overlayData;
      const kpiName = insight.kpi_display_name;
      const overlayDataBlobs = [];
      data.forEach((overlay) => {
        const overlayHeader = overlayHeaders.map((header) => {
          if (header.key === "current_y") {
            return {
              ...header,
              name: header.name.replace("__Metric_Name__", kpiName),
            };
          } else if (header.key === "period1_y") {
            return {
              ...header,
              name: header.name.replace(
                "__period1_placeholder__",
                overlay_placeholders[0][overlay.type].period1
              ),
            };
          } else if (header.key === "period2_y") {
            return {
              ...header,
              name: header.name.replace(
                "__period2_placeholder__",
                overlay_placeholders[0][overlay.type].period2
              ),
            };
          } else return header;
        });
        const overlaysData = [],
          p1Data = [],
          p2Data = [];
        if (overlay.data?.data) {
          overlay.data.data?.main.forEach((main) => {
            const current = {
              timestamp: main.timestamp,
              current_y: main.result.y,
              yhat_upper: main.result.yhat_upper,
              yhat_lower: main.result.yhat_upper,
            };
            overlaysData.push(current);
          });
          overlay.data.data?.period1.forEach((p1) => {
            p1Data.push({ period1_y: p1.result.y });
          });
          overlay.data.data?.period2.forEach((p2) => {
            p2Data.push({ period2_y: p2.result.y });
          });
        }
        const mergedOverlaysData = overlaysData.map(
          ({ timestamp, current_y, yhat_upper, yhat_lower }, i) => ({
            timestamp,
            current_y,
            yhat_upper,
            yhat_lower,
            ...p1Data[i],
            ...p2Data[i],
          })
        );
        // Convert overlay data
        const overlayBlob = ConvertJsonToCsv(overlayHeader, mergedOverlaysData);
        overlayDataBlobs.push({
          filename: `${overlay.type}_overlay_chart_data`,
          blob: overlayBlob,
          kpiName: kpiName,
        });
      });
      setCsvBlobs((prevState) => [...prevState, ...overlayDataBlobs]);
      // If there is no rootcause associated, then trigger the download for spikes and overlays data
      if (!insight.has_rootcause) {
        setTriggerDownload(true);
      }
    }
  }, [overlayData]);

  // Gets triggered whenver `rootcauseData` is populated, and
  // generate the CSV file for rootcause, with headers and data using the template files
  useEffect(() => {
    if (rootcauseData) {
      const { insight, data } = rootcauseData;
      const rcaData = [];
      data.forEach((rootcause) => {
        rootcause.rootcause_details.forEach((rca) => {
          const current = {
            dim_name: rootcause.dim_name,
            dim_val: rca.dim_val,
            kpi_delta: rca.kpi_delta,
            kpi_value_movement: rca.kpi_value_movement,
          };
          rcaData.push(current);
        });
      });
      const rcaBlob = ConvertJsonToCsv(rcaHeaders, rcaData);
      setCsvBlobs((prevState) => [
        ...prevState,
        {
          filename: "rootcause_chart_data",
          blob: rcaBlob,
          kpiName: insight.kpi_display_name,
        },
      ]);
      setTriggerDownload(true);
    }
  }, [rootcauseData]);

  /**
   * This function makes all the API calls, for pattern_series, overlays, rootcause with required paramaters
   * It calculates which overlay options are applicable for the current insight, and accordingly calls the APIs
   */
  const downloadInsight = async () => {
    if (downloadRequested) return;
    try {
      setIsDownloadRequested(true);

      insightData.length &&
        insightData.forEach((insight) => {
          downloadSingleInsightData(insight);
        });
      if (insightData.length > 1) {
        sendDataToGTM(GTAG_EVENTS.DOWNLOAD_GROUP_CSV_CLICKED, {
          insight_ids: insightData.map((insight) => insight.insight_id),
          group_id: groupId,
        });
      } else {
        sendDataToGTM(GTAG_EVENTS.DOWNLOAD_CSV_CLICKED, {
          insight_id: insightData[0].insight_id,
        });
      }
    } catch (error) {}
  };

  const downloadSingleInsightData = async (insight) => {
    const startTime = insight.chart_ts_start,
      endTime = insight.chart_ts_end;
    const { year, month, day, hours24, minutes } = datePartsWithTZ(endTime);
    const endDate = new Date(year, month, day, hours24, minutes);
    const params = prepareParams(insight, startTime, endTime);
    axios
      .get(BASE_URL.concat("pattern_series"), { params })
      .then((timeseries) => {
        if (timeseries?.data) {
          setTimeSeriesData({ insight: insight, data: timeseries.data });
        }
      });

    if (insight.pattern_type === PATTERN_TYPE.SPIKES) {
      // Call overlay APIs, only if it's spikes
      const overlayURL = BASE_URL.concat("pattern_series/overlays");
      // Wait for the response of all API calls with the applicable overlay types
      const overlays = await Promise.all(
        overlayOptions
          .filter((overlayOption) =>
            // filter out overlay options that are applicable
            shouldShowOverlayOption(
              overlayOption.value,
              labels.PIPELINE_SCHEDULE[insight.pipeline_schedule]
            )
          )
          .map(async (overlay) => {
            // #region Start making calls with the applicable overly types

            // Calcualte the start date
            const startDate = getOverlayStartDate(
              labels.PIPELINE_SCHEDULE[insight.pipeline_schedule],
              endDate
            );
            // API param `type`
            const overlayType = {
              key: "type",
              value: overlay.value,
            };
            // Prepare API params
            const params = prepareParams(
              insight,
              startDate,
              endTime,
              overlayType
            );
            // return the overlay data with its type
            return {
              type: overlay.value,
              data: await axios.get(overlayURL, { params }),
            };
            // #endregion
          })
      );
      setOverlayData({ insight: insight, data: overlays });

      // Prepare rootcause data, if rootcause is available
      if (insight.has_rootcause) {
        axios
          .get(BASE_URL.concat(`insights/${insight.insight_id}/rootcause`))
          .then((response) => {
            const rootcauses = response.data.rootcause_data;
            setRootcauseData({ insight: insight, data: rootcauses });
          });
      }
    }
  };

  return (
    <Button
      sx={buttonStyle}
      disabled={downloadRequested}
      variant="contained"
      onClick={downloadInsight}
    >
      {!downloadRequested && (
        <Icon
          name="download"
          size="22px"
          strokecolor="white"
          margin="5px 0 0 0"
        />
      )}
      {downloadRequested && <CircularProgress size="0.8rem" />}
    </Button>
  );
};
