export const PATTERN_CONTRIBUTION = "pattern_contribution";
export const KPI_VALUE_MOVEMENT = "kpi_value_movement_decimal";
export const KPI_POPULATION_CHANGE_PCT = "kpi_population_change_pct";

export const RCA_FILTERING_PTIONS = [
  { name: "impact", label: "Impact", value: "By Impact (%)", filterKey: PATTERN_CONTRIBUTION },
  { name: "lift", label: "Lift", value: "By Lift (%)", filterKey: KPI_VALUE_MOVEMENT },
  { name: "volume", label: "Volume", value: "By Volume (%)", filterKey: KPI_POPULATION_CHANGE_PCT },
];

export const ASCENDING_SORT_ORDER = "ASC";
export const DESCENDING_SORT_ORDER = "DESC";

export const RCA_BAR_COLOR_CONFIG = {
  pattern_contribution: "rgba(255, 164, 71, 1)",
  kpi_value_movement_decimal: "rgba(244, 162, 246, 1)",
  kpi_population_change_pct: "rgba(197, 153, 255, 1)",
};
