import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// MUI Imports
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Box, CircularProgress } from "@mui/material";
// uncomment below import when we need to support image and PDF download
// import Typography from "@mui/material/Typography";
// import CircularProgress from "@mui/material/CircularProgress";

// Local Imports
// import Icon from "./Icon";
import { DownloadCSV } from "./DownloadCSV";
import {
  DownloadModalContainer,
  DownloadModalOptionContainer,
  downloadModalStyle,
  buttonProps,
  listProps,
  DownloadModalOptionText,
  DownloadModalOptionButton,
} from "./styled-components/DownloadModal.styled";
import { getInsightById } from "../../actions/groupInsightActions";

export const DownloadInsight = ({ insightIds }) => {
  const dispatch = useDispatch();
  const [insightData, setInsightData] = useState([]);

  useEffect(() => {
    dispatch(getInsightById(insightIds[0])).then((response) => {
      setInsightData([response]);
    });
  }, [dispatch, insightIds]);

  const downloadOptions = [
    {
      type: "csv",
      icon: "download",
      label: "Download Data",
      action: () => {},
    },
    {
      type: "pdf",
      icon: "download",
      label: "Download PDF",
      action: () => {},
    },
    {
      type: "image",
      icon: "download",
      label: "Download Image",
      action: () => {},
    },
  ];

  return (
    <Box sx={downloadModalStyle}>
      <DownloadModalContainer>
        <List sx={listProps}>
          {downloadOptions.map((downloadOption, index) => (
            <DownloadModalOptionContainer key={index}>
              {downloadOption.type === "csv" && ( // remove this condition when we need to support other download options
                <DownloadModalOptionText>
                  <ListItem>{downloadOption.label}</ListItem>
                </DownloadModalOptionText>
              )}
              {downloadOption.type === "csv" && (
                <DownloadModalOptionButton>
                  {insightData.length > 0 ? (
                    <DownloadCSV
                      insightData={insightData}
                      buttonStyle={buttonProps}
                    />
                  ) : (
                    <div style={{ margin: "10px 0 0 30px" }}>
                      <CircularProgress size="1.5rem" />
                    </div>
                  )}

                  {/* // Uncomment it when we need to support image and PDF download
                  // : (
                  //   <Button
                  //     sx={buttonProps}
                  //     variant="contained"
                  //     onClick={() => downloadOption.action()}
                  //   >
                  //     <Icon
                  //       name={downloadOption.icon}
                  //       size="22px"
                  //       strokecolor="white"
                  //       margin="5px 0 0 0"
                  //     />
                  //   </Button>
                  // ) */}
                </DownloadModalOptionButton>
              )}
            </DownloadModalOptionContainer>
          ))}
        </List>
      </DownloadModalContainer>
    </Box>
  );
};
