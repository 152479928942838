import axios from "axios";
const insightSummaryURL = process.env.REACT_APP_BASE_URL.concat(
  "insights/summary/info"
);
const insightSummaryHistogram = `${process.env.REACT_APP_BASE_URL}insights/summary/histogram`;
const insightSummaryForTimeSeriesUrl =
  process.env.REACT_APP_BASE_URL.concat("insights/summary");

export const getInsightSummary =
  (title, tenantId, start_time, end_time, dim_name = null, dim_val = null) => async () => {
    const params = {
      title: title,
      tenant_id: tenantId,
      start_time: start_time,
      end_time: end_time,
      dim_name: dim_name,
      dim_val: dim_val,
    };
    try {
      const { data } = await axios.get(insightSummaryURL, {
        params: new URLSearchParams(params),
      });
      return data;
    } catch (error) {
      throw error;
    }
  };

export const getInsightSummaryForTimeSeries = (params) => async () => {
  try {
    const { data } = await axios.get(insightSummaryForTimeSeriesUrl, {
      params: new URLSearchParams(params),
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getInsightSummaryHistogram = (paramsObjOrArray) => async () => {
  const params = new URLSearchParams(paramsObjOrArray);

  try {
    const { data } = await axios.get(insightSummaryHistogram, {
      params,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
