import { Box, Button, Collapse, Stack, Typography, buttonClasses } from "@mui/material";
import React, { createContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import styled from "@emotion/styled";
import { getGroupInsightsCharts } from "../../../actions/groupInsightActions";
import { RESOURCE_TYPE_GROUP } from "../../../constants/commonConstants";
import { PATTERN_TYPE } from "../../../constants/multiSelectFilterConstants";
import { GTagGroupContext } from "../../../context/GtagContext";
import { useStateWithCallback } from "../../../hooks";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { labels } from "../../../shared/intl/labels";
import { colors, typography } from "../../../shared/theme-constants";
import { GTAG_EVENTS, sendDataToGTM } from "../../../utils/gtmHelper";
import Card from "../../common/Card";
import Icon from "../../common/Icon";
import { RenderGroupCharts } from "../../common/RenderGroupCharts";
import { CopyLink } from "../../common/share-and-collaborate/CopyLink";
import { Download } from "../../common/share-and-collaborate/Download";
import { ViewHistory } from "../../common/share-and-collaborate/ViewHistory";
import { Loading } from "../../common/styled-components";
import InsightCardHeader from "../insight/InsightCardHeader";
import Feedback from "../insight/feedback/Feedback";
import MetricTable from "./MetricTable";
import { buildUrl } from "../../../utils/stringUtils";
export const GroupContext = createContext();

const StyledAnalyseButton = styled(Button)(({ theme }) => ({
  minWidth: theme.typography.pxToRem(100),
  color: colors.white,
  backgroundColor: colors.mariner,
  "&:hover": {
    backgroundColor: colors.mariner,
    color: colors.white,
  },
  [`& .${buttonClasses.startIcon}`]: { margin: 0 },
}));

function GroupedInsight({
  groupedInsight,
  handleClickActionIcon,
  fromLink,
  // added these variables for future reference to type of the resource and its id
  resourceId,
  resourceType,
}) {
  const [insightID, setinsightID] = useState();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalFilter = useSelector((state)=> state.tenantGlobalFilter?.appliedFilter);
  const user = useAuthenticatedUser();
  const [tagManagerArgs, setTagManagerArgs] = useStateWithCallback(null);

  const [isOpen, setIsOpen] = useState(fromLink);
  const [kpiGroup, setKpiGroup] = useState();

  const scrollRef = useRef(null);

  useEffect(() => {
    // Scroll back to the top of the page when insightID changes
    if (scrollRef.current && isOpen) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [insightID, isOpen]);

  function handleOpenClose() {
    setIsOpen((prevState) => !prevState);
  }
  const openL3View = () => {
    navigate(`/groups/${groupedInsight.group_id}/collaborate?`, {
      state: { backgroundLocation: location, isOverlay: true },
    });

    sendDataToGTM(GTAG_EVENTS.LEVEL3_VIEWED, tagManagerArgs);
  };

  useEffect(() => {
    // fetch on expand only if kpiGroup is not fetched yet
    if (isOpen && !kpiGroup) {
      dispatch(getGroupInsightsCharts(groupedInsight.group_id, {
        dim_name: globalFilter?.dimension_name,
        dim_val: globalFilter?.dimension_value,
      })).then((result) => {
        if (result) {
          setKpiGroup(result.kpis);
          // with this, the chart rendered on first expand of the card
          // will always be first insight of the top metric from groups/:id/metrics call
          setinsightID(groupedInsight?.insights[0]);
        } else {
          // TODO: handle no data found error
        }
      });
    }
  }, [dispatch, groupedInsight.group_id, groupedInsight.insights, isOpen, kpiGroup, globalFilter]);

  useEffect(() => {
    if (isOpen) {
      sendDataToGTM(GTAG_EVENTS.GROUP_CARD_EXPANDED, tagManagerArgs);
    }
  }, [isOpen, tagManagerArgs]);

  useEffect(() => {
    setTagManagerArgs(
      {
        ...groupedInsight,
        // tenant_id must always be the logged-in user's tenant_id
        tenant_id: user?.tenant_id,
        // this is added for outoftheblue users, it will hold the customer name selected from the dropdown
        customer_id: groupedInsight.tenant_id,
        pipeline_schedule: labels.PIPELINE_SCHEDULE[groupedInsight.pipeline_schedule],
      },
      (args) => {
        sendDataToGTM(GTAG_EVENTS.GROUP_CARD_DISPLAYED, args);
      }
    );
  }, [groupedInsight, setTagManagerArgs, user?.tenant_id]);

  return (
    <GTagGroupContext.Provider value={tagManagerArgs}>
      <GroupContext.Provider value={setinsightID}>
        <Box id={groupedInsight.group_id} sx={{ position: "relative" }} ref={scrollRef}>
          <Card
            groupId={groupedInsight.group_id}
            insightList={groupedInsight.insights}
            insightTime={groupedInsight.group_ts_end}
            handleClickActionIcon={handleClickActionIcon}
            pipelineSchedule={labels.PIPELINE_SCHEDULE[groupedInsight.pipeline_schedule]}
            isGroup={true}
            fromLink={fromLink}
            isLive={groupedInsight.group_status === "ACTIVE"}
            groupAccent={groupedInsight.group_accent}
            groupSeverity={groupedInsight.group_severity}
          >
            <InsightCardHeader
              accent={groupedInsight.group_accent}
              groupSeverity={groupedInsight.group_severity}
              title={groupedInsight.group_title}
              summary={groupedInsight.group_summary}
              groupId={groupedInsight.group_id}
              isOpen={isOpen}
              miniChartName={
                groupedInsight.group_pattern_type == PATTERN_TYPE.TREND_CHANGE
                  ? `trend-${groupedInsight.group_direction}-${groupedInsight.group_accent}`
                  : `spike-${groupedInsight.group_direction}-${groupedInsight.group_accent}`
              }
              onClick={handleOpenClose}
              insightType= {groupedInsight?.dim_display_name ? `${groupedInsight.dim_display_name}: ${groupedInsight.dim_val}` : 'Overall Metric'}
              funnels={groupedInsight?.funnels}
            />
            <Collapse in={isOpen} mountOnEnter unmountOnExit sx={{ mt: 7.5 }}>
              <Stack>
                {kpiGroup && insightID ? (
                  <>
                    <Box minHeight={420}>
                      <RenderGroupCharts insightId={insightID} accent={groupedInsight.group_accent} groupId={groupedInsight.group_id} groupStartTime={groupedInsight.group_ts_start} groupEndTime={groupedInsight.group_ts_end} />
                    </Box>
                    <Typography
                      sx={{
                        color: "var(--comet)",
                        fontSize: "1.5rem",
                        fontWeight:typography.font_weight.semiBold,
                        width: "90%",
                        marginInline: "auto",
                        marginTop: "3.63rem",
                        marginBottom: "1.31rem",
                      }}
                    >
                      Metrics that Moved Together
                    </Typography>
                    <MetricTable kpiGroup={kpiGroup} setinsightID={setinsightID} insightID={insightID} />
                  </>
                ) : (
                  <Box sx={{ width: "100%", minHeight: 570, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Loading />
                  </Box>
                )}
                <Stack justifyContent="space-between" sx={{ marginTop: "20px", gap: 4, width: "90%", marginInline: "auto" }} flexDirection={{ tm: "row" }}>
                  <Feedback resourceId={groupedInsight.group_id} resourceType={RESOURCE_TYPE_GROUP} isGroup={true} />

                  <Stack
                    alignItems={{ tm: "end" }}
                    sx={{
                      marginBottom: "0.4rem",
                    }}
                    gap={2}
                    flexDirection={{ tm: "row" }}
                  >
                    {groupedInsight.group_id && (
                      <StyledAnalyseButton
                        startIcon={<Icon name="search_feedback_icon" size="1.2rem"></Icon>}
                        sx={{
                          color: colors.white,
                          backgroundColor: colors.mariner,
                        }}
                        onClick={openL3View}
                      >
                        Analyse
                      </StyledAnalyseButton>
                    )}
                    <ViewHistory groupId={groupedInsight.group_id} gTagArgs={tagManagerArgs} isGroup={true} />

                    <CopyLink link={`groups/${groupedInsight.group_id}?dim_name=${globalFilter?.dimension_name}&dim_val=${globalFilter?.dimension_value}`} resourceType={RESOURCE_TYPE_GROUP} resourceId={groupedInsight.group_id} />

                    <Download resourceType={RESOURCE_TYPE_GROUP} resourceId={groupedInsight.group_id} insightIds={groupedInsight.insights} />
                  </Stack>
                </Stack>
              </Stack>
            </Collapse>
          </Card>
        </Box>
      </GroupContext.Provider>
    </GTagGroupContext.Provider>
  );
}

export default GroupedInsight;
