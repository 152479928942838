import { Box } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import {
  calculateOverlayDuration,
  getChartStartDuration,
} from "../../../src/shared/highcharts-config/timeseries-overlay-config";
import { PERCENTAGE } from "../../constants/commonConstants";
import { SAAS_RCA_FEATURE_TOGGLE } from "../../constants/featureFlagConstants";
import { InsightContext } from "../../context/InsightContext";
import { useHotspots, useInsight } from "../../hooks";
import { HTTP_STATUS } from "../../shared/enums/http-status";
import { errorMessage } from "../../shared/intl/error-messages";
import { isEnabled } from "../../utils/featureFlags";
import { isEmpty } from "../../utils/is";
import DrillDownHotspotChart from "./DrillDownHotspotChart";
import HotspotChart from "./HotspotChart";
import TimeseriesChart from "./TimeseriesChart";
import { GroupChartCarousel } from "./hoc/GroupChartCarousel";
import RootcauseChart from "./rootcause/RootcauseChart";
import { Loading } from "./styled-components";
import { Message } from "./ui-components/Message";

export const RenderGroupCharts = ({ accent, insightId, groupId, groupStartTime, groupEndTime }) => {
  const tenantGlobalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);
  const tenantConfigs = useSelector((state) => state?.tenantConfigs?.configs);

  let isRCAFeatureEnabled = isEnabled(SAAS_RCA_FEATURE_TOGGLE);

  const { pattern, isLoading, isSuccess, isError, error } = useInsight(insightId, {
    params: {
      dim_name: tenantGlobalFilter?.dimension_name,
      dim_val: tenantGlobalFilter?.dimension_value,
    },
  });

  const getPatternData = (patternData = {}) => {
    let updatedPatternData = _.cloneDeep(patternData);
    if (
      new Date(groupStartTime) <= new Date(patternData.chart_ts_start)
    ) {
      const chartStartDate = getChartStartDuration(
        pattern.schedule,
        groupStartTime
      );
      updatedPatternData.chart_ts_start = chartStartDate;
    }
    if (new Date(groupEndTime) < new Date(patternData.chart_ts_end)) {
      updatedPatternData.chart_ts_end = groupEndTime;
    }
    return updatedPatternData;
  };

  const insightContext = {
    insightSummary: {
      insightId: insightId,
      schedule: pattern.schedule,
      accent: pattern.accent,
      kpiMetric: pattern.kpiMetric,
      title: pattern.pattern?.insight_title,
      dimensionValue: pattern.dimensionValue,
      patternDirection: pattern.patternDirection,
      insightSeverity: pattern.pattern?.insight_severity,
      insightDate: pattern.pattern?.insight_id_ts,
      summary: pattern.pattern?.insight_summary,
    },
    patternData: {
      pattern: getPatternData(pattern.pattern),
      hasTrend: pattern.hasTrend,
      trendChartData: pattern.trendChartData,
      summary: pattern.patternDescription,
    },
    tableChartData: pattern.chartTable,
  };

  if (isLoading)
    return (
      <Box sx={{ width: "100%", minHeight: 570, display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Loading />
      </Box>
    );

  const plotBands = [];
  let overlayEndDateEpoch;

  if (groupStartTime && groupEndTime) {
    const chartEndDate = insightContext?.patternData?.pattern?.chart_ts_end;
    const overlayDuration = calculateOverlayDuration(pattern.schedule);
    const groupStartDateEpoch = new Date(groupStartTime).getTime();
    const groupEndDateEpoch = new Date(groupEndTime).getTime();
    const chartEndDateEpoch = new Date(chartEndDate).getTime();
    const earlierEndDateEpoch = Math.min(chartEndDateEpoch, groupEndDateEpoch);
    const overlayStartDateEpoch = groupStartDateEpoch - overlayDuration;
    overlayEndDateEpoch = earlierEndDateEpoch + overlayDuration;
    plotBands.push({
      color: pattern.accent === 'negative' ? 'rgba(229, 95, 95, 0.25)' : 'rgba(67, 164, 92, 0.25)',
      from: overlayStartDateEpoch,
      to: overlayEndDateEpoch,
    });
  }

  return (
    <InsightContext.Provider value={insightContext}>
      <GroupChartCarousel>
        <div style={{ overflowX: "hidden" }}>
          <TimeseriesChart plotBands={plotBands} overlayEndDateEpoch={overlayEndDateEpoch} isForecastEnabled={tenantConfigs?.is_forecast_enabled} />
        </div>
        {/* Disabling Older RCA highcharts */}
        {/* {rcaHighChartsMarkup} */}
        {isRCAFeatureEnabled && <RootcauseChart insightId={insightId} />}
        {/* Commented below the impact table as part of DEV-4390 */}
        {/* <div style={{ overflowX: "hidden" }}>
          <ImpactTable accent={accent} groupId={groupId} />
        </div> */}
      </GroupChartCarousel>
    </InsightContext.Provider>
  );
};
