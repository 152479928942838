import { UPDATE_PREFERENCE, UPDATE_PREFERENCE_SUCCESS, UPDATE_PREFERENCE_FAIL, UPDATE_PROFILE_DETAILS, UPDATE_PROFILE_DETAILS_SUCCESS, UPDATE_PROFILE_DETAILS_FAIL, GENERATE_API_REQUEST, GENERATE_API_REQUEST_SUCCESS, GENERATE_API_REQUEST_FAIL, GENERATE_API_REQUEST_RESET } from "../constants/preferenceConstants";

export const updateUserPreferenceReducer = (state = { updatedUserPreference: {}, loading: false, error: {} }, action) => {
  switch (action.type) {
    case UPDATE_PREFERENCE:
      return { loading: true };
    case UPDATE_PREFERENCE_SUCCESS:
      return { loading: false, success: true, updatedUserPreference: action.payload };
    case UPDATE_PREFERENCE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateProfileDetailsReducer = (state = { updatedProfileDetails: {}, loading: false, error: {} }, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_DETAILS:
      return { loading: true };
    case UPDATE_PROFILE_DETAILS_SUCCESS:
      return { loading: false, success: true, updatedProfileDetails: action.payload };
    case UPDATE_PROFILE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const generateAPIKeyReducer = (state = { apiKeyDetails: {}, loading: false, error: {} }, action) => {
  switch (action.type) {
    case GENERATE_API_REQUEST:
      return { loading: true };
    case GENERATE_API_REQUEST_SUCCESS:
      return { loading: false, success: true, apiKeyDetails: action.payload };
    case GENERATE_API_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    case GENERATE_API_REQUEST_RESET:
      return { loading: false, apiKeyDetails: {}, error: {}, };
    default:
      return state;
  }
};