import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { colors } from "../../../shared/theme-constants";
import { formatDatePerFrequency } from "../../../utils/dateUtils";
import "./DimensionsBreakupChart.css";
import { nFormatter } from "../../../utils/stringUtils";
import moment from "moment-timezone";

const DEFAULT_TIMEZONE = "Etc/UTC";

const DimensionsBreakupChart = ({ metric, data, chartType, pipelineSchedule }) => {
  const [dimensionsChartData, setDimensionsChartData] = useState([]);
  const [xAxisCategories, setXAxisCategories] = useState([]);

  // Function to add the The values at same indexes in Others array
  function addIndexes(arrays) {
    const length = arrays[0].length;
    const result = new Array(length).fill(0);

    arrays.forEach((array) => {
      array.forEach((value, index) => {
        result[index] += value;
      });
    });

    return result;
  }

  useEffect(() => {
    if (data?.length) {
      let dimensionBreakupdata = [...data];
      let uniqueDimensions = [];
      setXAxisCategories(dimensionBreakupdata.map((data) => data.timestamp));

      // unique dimensions
      dimensionBreakupdata.forEach((data) => {
        if (data.results.length > 0) {
          data.results.forEach((result) => {
            if (!uniqueDimensions.includes(result.dim_val)) {
              uniqueDimensions.push(result.dim_val);
            }
          });
        }
      });

      // iterate on result set to find if that dim is present on that timestamp or not. if not populate null for that dim for that timestamp
      let dimensionSeriesArray = [];
      uniqueDimensions.forEach((dimension) => {
        let dataPoints = [];
        dimensionBreakupdata.forEach((data) => {
          let matchedEntry = data.results.find((item) => item.dim_val === dimension);
          matchedEntry ? dataPoints.push(matchedEntry.y) : dataPoints.push(null);
        });
        dimensionSeriesArray.push({
          name: dimension,
          data: dataPoints,
        });
      });

      // sort the array based on additon of the values
      dimensionSeriesArray.sort((a, b) => {
        let summationA = a?.data.reduce((acc, curr) => acc + curr, 0);
        let summationB = b?.data.reduce((acc, curr) => acc + curr, 0);
        return summationB - summationA;
      });

      let resultArray = [];
      // check if Length is greater than 6
      if (dimensionSeriesArray.length > 6) {
        let slicedArray = dimensionSeriesArray.slice(5);
        let mappedArray = slicedArray.map((element) => element.data);
        let OthersArray = addIndexes(mappedArray);
        // First  6 Elements in start array
        let StartArray = dimensionSeriesArray.slice(0, 5);

        // Spread the first 5 elements in the result set
        resultArray = [...StartArray];
        // Push the Others array

        resultArray.push({
          name: "Others",
          data: OthersArray,
        });
      } else {
        resultArray = [...dimensionSeriesArray];
      }
      // Add Colours to each of them
      resultArray.forEach((series, index) => (series.color = colors.dimensions_breakup[index]));

      setDimensionsChartData(resultArray);
    }
  }, []);

  const generateChartOptions = () => {
    let options = {
      chart: {
        type: "area",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      accessibility: {
        enabled: false,
      },
      xAxis: {
        categories: xAxisCategories,
        tickColor: colors.gray[350],
        tickLength: 14,
        tickWidth: 0.5,
        labels: {
          style: {
            color: colors.gray[575],
            fontSize: "0.9em",
          },
          formatter: function () {
            return formatDatePerFrequency(this.value, pipelineSchedule, DEFAULT_TIMEZONE);
          },
        },
      },
      yAxis: {
        labels: {
          style: {
            color: colors.gray[575],
            tickColor: colors.gray[350],
            fontSize: "0.9em",
          },
          formatter: function () {
            if (chartType === "stacked_area") {
              if(metric.kpi_format === 'percentage'){
                return `${(this.value * 100).toFixed(1)}%`;
              }else{
                return nFormatter(this.value, 1);
              }
            } else {
              return `${this.value}%`;
            }
          },
        },
        title: {
          enabled: false,
        },
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        formatter: function () {
          const points = this.points;
          let tooltip = `<b>${moment(this.x).tz("Etc/Utc").format("DD MMM, YYYY")}</b><br/>`; // Format date
          points.forEach((point) => {
            tooltip += `<span style="color:${point.color}">\u25CF</span> 
            ${point.series.name}: <b>${
              chartType === "stacked_area"
                ? metric.kpi_format === 'percentage' ? `${(point.y * 100).toFixed(1)}%` : nFormatter(point.y, 1)
                : nFormatter(point.percentage, 1) + "%"
            }</b><br/>`;
          });
          return tooltip;
        },
      },
      plotOptions: {
        area: {
          fillOpacity: 1,
          stacking: chartType === "stacked_area" ? "normal" : "percent",
          marker: {
            enabled: false,
            symbol: "circle"
          },
        },
      },
      series: dimensionsChartData,
    };
    return options;
  };

  return <HighchartsReact highcharts={Highcharts} options={generateChartOptions()} />;
};

export default DimensionsBreakupChart;
