import Stack from "@mui/material/Stack";
import React, { useContext } from "react";

import styled from "@emotion/styled";
import { Button, buttonClasses } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RESOURCE_TYPE_INSIGHT } from "../../../constants/commonConstants";
import { HIDE_MODAL } from "../../../constants/modalConstants";
import { GTagArgsContext } from "../../../context/GtagContext";
import { InsightContext } from "../../../context/InsightContext";
import { COLLABORATE } from "../../../shared/constants/routes";
import { colors } from "../../../shared/theme-constants";
import { GTAG_EVENTS, sendDataToGTM } from "../../../utils/gtmHelper";
import Icon from "../Icon";
import { CopyLink } from "./CopyLink";
import { Download } from "./Download";
import { ViewHistory } from "./ViewHistory";

const StyledAnalyseButton = styled(Button)(({ theme }) => ({
  minWidth: theme.typography.pxToRem(100),
  color: colors.white,
  backgroundColor: colors.mariner,
  "&:hover": {
    backgroundColor: colors.mariner,
    color: colors.white,
  },
  [`& .${buttonClasses.startIcon}`]: { margin: 0 },
}));

export const ShareAndCollaborate = ({
  resourceType,
  // get rid of isModal in refactor task
  isModal,
  groupId,
  insightId,
  hasRootCause,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalFilter = useSelector((state)=> state.tenantGlobalFilter?.appliedFilter);
  const gTagArgs = useContext(GTagArgsContext);
  const { patternData } = useContext(InsightContext);

  const link =
    resourceType === RESOURCE_TYPE_INSIGHT
      ? `insights/${insightId}?dim_name=${globalFilter?.dimension_name}&dim_val=${globalFilter?.dimension_value}`
      : `groups/${groupId}?dim_name=${globalFilter?.dimension_name}&dim_val=${globalFilter?.dimension_value}`;

  const openL3View = () => {
    dispatch({
      type: HIDE_MODAL,
    });

    navigate(`/insights/${insightId}/${COLLABORATE}`, {
      state: {
        isModal,
        isOverlay: true,
        backgroundLocation: location,
      },
    });

    sendDataToGTM(GTAG_EVENTS.LEVEL3_VIEWED, gTagArgs);
  };

  return (
    <Stack flexDirection={{ tm: "row" }} gap={2} alignItems={{ tm: "center" }}>
      <StyledAnalyseButton
        startIcon={<Icon name="search_feedback_icon" size="1.2rem"></Icon>}
        onClick={openL3View}
      >
        Analyse
      </StyledAnalyseButton>

      <ViewHistory groupId={groupId} gTagArgs={gTagArgs} />

      <CopyLink
        resourceType={RESOURCE_TYPE_INSIGHT}
        resourceId={insightId}
        link={link}
      />

      <Download
        resourceType={RESOURCE_TYPE_INSIGHT}
        resourceId={insightId}
        insightIds={[insightId]}
        hasRootCause={hasRootCause}
        insightData={patternData}
      />
    </Stack>
  );
};
