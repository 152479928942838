export const trend_change_headers = [
  {
    key: "timestamp",
    name: "Timestamp",
  },
  {
    key: "current_y",
    name: "Actual Value",
  },
  {
    key: "yhat_upper",
    name: "Expected Maximum Value",
  },
  {
    key: "yhat_lower",
    name: "Expected Minimum Value",
  },
  {
    key: "is_point_on_old_trend_line",
    name: "Point Part of Old Trend Line (Yes/No)",
  },
  {
    key: "is_point_on_new_trend_line",
    name: "Point Part of New Trend Line (Yes/No)",
  },
  {
    key: "is_point_trend_change_point",
    name: "Point is Changepoint for Trend (Yes/No)",
  },
  {
    key: "expected_value",
    name: "Rate for Old Trend Line",
  },
  {
    key: "current_value",
    name: "Rate for New Trend Line",
  },
];
