import {
  TIME_FILTER_UPDATE,
  TIME_FILTER_REMOVE,
  TIME_FILTER_INITIATE,
} from "../constants/filterCriteriaConstants";
import { omit } from "../utils/objects";

let initialState = {
  start_time: null,
  end_time: null,
  type: "initial",
  history: [],
};

export const feedScreenTimeFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case TIME_FILTER_INITIATE:
      // store the last_insight_ts in a variable which can be referenced later
      initialState.start_time = action.payload.start_time;
      initialState.end_time = action.payload.end_time;
      return {
        start_time: action.payload.start_time,
        end_time: action.payload.end_time,
        type: action.payload.type,
        history : []
      };

    case TIME_FILTER_UPDATE:
      return {
        start_time: action.payload.start_time,
        end_time: action.payload.end_time,
        type: action.payload.type,
        history: [
          ...state.history,
          omit(state, "history")
        ],
      };

    case TIME_FILTER_REMOVE: {
      if (state.type.startsWith("histogram/")) {
        const { start_time, end_time, type } =
          state.history[state.history.length - 1];

        return {
          start_time,
          end_time,
          type,
          history: state.history.slice(0, -1),
        };
      } else {
        return initialState ;
      }
    }

    default:
      return state;
  }
};
