// React redux imports
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

// MUI imports
import Box from "@mui/material/Box";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useMediaQuery } from "@mui/material";

// Local Imports
import * as UserInsightState from "../../constants/userConstants";
import {
  CalendarContainer,
  OneClickSnoozeButton,
  CalendarTitle,
  calendarBoxStyle,
  stackButtonStyles,
  SaveButton,
  stackDatePickerStyles,
  SnoozeButtonText,
  DateTimeTextField,
  TitleField,
} from "./styled-components/Calendar.Modal.styled";
import Icon from "./Icon";
import { updateGroupSnooze } from "../../actions/groupInsightActions";

const CalendarModal = ({
  groupId,
  insightList,
  close,
  showSuccessNotification,
  selectedInsightState,
}) => {
  const dispatch = useDispatch();
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [showWeekendbutton, setShowWeekendButton] = useState(true);
  const [showTodayButton, setShowTodayButton] = useState(true);
  const [openCalendar, setOpenCalendar] = useState(true);

  const matchesMobile = useMediaQuery("(max-width: 480px)");
  const matchesTablet = useMediaQuery("(max-width: 768px)");

  const handleClose = () => {
    setOpenCalendar(false);
    close();
  };

  /**
   * This useEffect is to to check if the the tiime is Past 6pm of the day. If so then don't show the 6p/m snooze button
   * If the time is past this week's saturday 8 a.m then hide the "this Weekend 8 a.m button"
   */
  useEffect(() => {
    var dayOfWeek = new Date().getDay();
    var isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
    setShowWeekendButton(!isWeekend);
    var hourOfDay = new Date().getHours();
    setShowTodayButton(hourOfDay < 18);
  }, []);

  /**
   * When user clicks on this button, It will snooze the Insight for user until current day's 6 pm local time zone
   * When success response is received from the backend, it will call 2 functions
   * 1. handleclose() to close the snooze Modal
   * 2. showSuccessNotification which will show the success Notification on the top of the screen
   */
  const onTodaySnoozeClicked = () => {
    let today = new Date();
    today.setHours(18, 0, 0, 0);
    onSubmitButtonClicked(today);
  };

  /**
   * When user clicks on this button, It will snooze the Insight for user until tomorrow 8 a.m
   * When success response is received from the backend, it will call 2 functions
   * 1. handleclose() to close the snooze Modal
   * 2. showSuccessNotification which will show the success Notification on the top of the screen
   */
  const onTomorrowSnoozeClicked = () => {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(8, 0, 0, 0);
    onSubmitButtonClicked(tomorrow);
  };

  /**
   * When user clicks on this button, It will snooze the Insight for user until current week's saturday 8 a..
   * When success response is received from the backend, it will call 2 functions
   * 1. handleclose() to close the snooze Modal
   * 2. showSuccessNotification which will show the success Notification on the top of the screen
   */
  const onThisWeekendSnoozeClicked = () => {
    const today = new Date();
    const first = today.getDate() - today.getDay() + 1;
    const sixth = first + 5;
    const saturday = new Date(today.setDate(sixth));
    saturday.setHours(8, 0, 0, 0);
    onSubmitButtonClicked(saturday);
  };

  /**
   * When user clicks on this button, It will snooze the Insight for user until nex week's Monday morning 8 a.m
   *
   */
  const onNextWeekSnoozeClicked = () => {
    let currentDate = new Date();
    let nextWeek = new Date();
    nextWeek.setDate(
      currentDate.getDate() + ((1 + 7 - currentDate.getDay()) % 7 || 7)
    );
    nextWeek.setHours(8, 0, 0, 0);
    onSubmitButtonClicked(nextWeek);
  };

  /**
   * When submit button is clicked , it will snooze accordin to the
   */
  const onSubmitButtonClicked = (snoozeTimeStamp) => {
    dispatch(
      updateGroupSnooze(
        insightList,
        groupId,
        {
          old_state: selectedInsightState,
          current_state: UserInsightState.SNOOZED,
        },
        snoozeTimeStamp.toISOString()
      )
    ).then((result) => {
      handleClose();
      showSuccessNotification(result);
    });
  };

  /**
   * This function returns the maximum date upto which the user can snooze the Insight
   * Currently it has been set to 30 days from present time
   */
  const getMaxSnoozeDate = () => {
    const maxSnoozeDate = new Date();
    maxSnoozeDate.setDate(maxSnoozeDate.getDate() + 30);
    return maxSnoozeDate;
  };

  /**
   *  This will set the default time 10 mins from now, User can set the snooze time atleast 10 mins from now.
   */
  const getMinSnoozeDate = () => {
    const minSnoozeOffset = new Date();
    minSnoozeOffset.setMinutes(minSnoozeOffset.getMinutes() + 10);
    return minSnoozeOffset;
  };

  return (
    <>
      <Modal
        BackdropProps={{ style: { background: "rgba(17, 39, 133, 0.82)" } }}
        open={openCalendar}
        onClose={() => handleClose()}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box sx={calendarBoxStyle}>
          <CalendarContainer>
            <CalendarTitle>
              <Icon
                name={UserInsightState.SNOOZED}
                size="2em"
                margin="5px 0 0 10px"
              ></Icon>
              <TitleField>Snooze Until...</TitleField>
            </CalendarTitle>

            <Stack sx={stackButtonStyles}>
              <OneClickSnoozeButton
                disabled={!showTodayButton}
                onClick={onTodaySnoozeClicked}
              >
                <SnoozeButtonText
                  gap={
                    matchesMobile ? "100px" : matchesTablet ? "100px" : "200px"
                  }
                >
                  <div>Later Today</div>
                  <div style={{ fontWeight: "400", alignSelf: "center" }}>
                    6pm
                  </div>
                </SnoozeButtonText>
              </OneClickSnoozeButton>
              <OneClickSnoozeButton onClick={onTomorrowSnoozeClicked}>
                <SnoozeButtonText
                  gap={
                    matchesMobile ? "100px" : matchesTablet ? "100px" : "200px"
                  }
                >
                  <div>Tommorrow</div>
                  <div style={{ fontWeight: "400" }}>8am</div>
                </SnoozeButtonText>
              </OneClickSnoozeButton>
            </Stack>

            <Stack sx={stackButtonStyles}>
              <OneClickSnoozeButton
                disabled={!showWeekendbutton}
                onClick={onThisWeekendSnoozeClicked}
              >
                <SnoozeButtonText
                  gap={
                    matchesMobile ? "60px" : matchesTablet ? "60px" : "150px"
                  }
                >
                  <div>This Weekend</div>
                  <div style={{ fontWeight: "400" }}>Sat, 8am</div>
                </SnoozeButtonText>
              </OneClickSnoozeButton>

              <OneClickSnoozeButton onClick={onNextWeekSnoozeClicked}>
                {" "}
                <SnoozeButtonText
                  gap={
                    matchesMobile ? "75px" : matchesTablet ? "75px" : "170px"
                  }
                >
                  <div>Next Week</div>
                  <div style={{ fontWeight: "400" }}>Mon, 8am</div>
                </SnoozeButtonText>
              </OneClickSnoozeButton>
            </Stack>

            <Stack sx={stackDatePickerStyles}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  minDateTime={getMinSnoozeDate()}
                  maxDateTime={getMaxSnoozeDate()}
                  label="Select Date and Time"
                  renderInput={(props) => (
                    <DateTimeTextField
                      {...props}
                      inputProps={{
                        ...props.inputProps,
                        placeholder: "mm:dd:yyyy hh:mm am/pm",
                      }}
                    />
                  )}
                  value={selectedDateTime ? selectedDateTime : null}
                  onChange={(newValue) => {
                    setSelectedDateTime(
                      newValue > new Date() ? newValue : null
                    );
                  }}
                />
              </LocalizationProvider>
              <SaveButton
                disabled={!selectedDateTime}
                onClick={() => onSubmitButtonClicked(selectedDateTime)}
              >
                Submit
              </SaveButton>
            </Stack>
          </CalendarContainer>
        </Box>
      </Modal>
    </>
  );
};

export default CalendarModal;
