import React from "react";
import { HTTP_STATUS } from "../../../shared/enums/http-status";
import { errorMessage, errorTitle } from "../../../shared/intl/error-messages";
import ScreenError from "./ScreenError";

function translateStatusToErrorMessage(status, resourceType) {
  switch (status) {
    case HTTP_STATUS.BAD_REQUEST:
      return {
        title: errorTitle.BAD_REQUEST,
        message: errorMessage.BAD_REQUEST,
      };
    case HTTP_STATUS.UNAUTHORIZED:
      return {
        title: errorTitle.UNAUTHORIZED,
        message: errorMessage.UNAUTHORIZED,
      };
    case HTTP_STATUS.FORBIDDEN:
      return {
        title: errorTitle.FORBIDDEN,
        message: errorMessage.FORBIDDEN,
      };
    case HTTP_STATUS.NOT_FOUND:
      return {
        title: errorTitle.NOT_FOUND,
        message: errorMessage.NOT_FOUND.replace("__resource__", resourceType),
      };
    case HTTP_STATUS.SERVER_ERROR:
    case HTTP_STATUS.BAD_GATEWAY:
    case HTTP_STATUS.SERVICE_UNAVAILABLE:
    case HTTP_STATUS.GATEWAY_TIMEOUT:
      return {
        title: errorTitle.SERVER_ERROR,
        message: errorMessage.SERVER_ERROR,
      };
    default:
      return {
        title: errorTitle.SERVER_ERROR,
        message: errorMessage.SERVER_ERROR,
      };
  }
}

const ApiScreenError = ({ error, resourceType }) => {
  const status = error?.response?.status;
  const { title, message } = translateStatusToErrorMessage(
    status,
    resourceType
  );

  return <ScreenError title={title} message={message} />;
};

export default ApiScreenError;
