import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

import { colors, typography } from "../../shared/theme-constants";

const keyframesPulseRed = `
  @-webkit-keyframes pulse-red {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 0.5em rgba(255, 82, 82, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }
  @keyframes pulse-red {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 0.5em rgba(255, 82, 82, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }
`;

const LiveIndicatorDot = styled("div")`
  border-radius: 50%;
  margin: 10px;
  margin-right: 0.35em;
  height: 0.5em;
  width: 0.5em;
  transform: scale(1);
  background: ${colors.crisis_red};
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 3s infinite;
  ${keyframesPulseRed}
`;

const LiveTextStyle = styled("div")({
  color: colors.crisis_red,
  fontWeight: typography.font_weight.semiBold,
});

const LiveIndicator = (props) => {
  return (
    <Stack direction="row" alignItems="center" {...props}>
      <LiveIndicatorDot />
      <LiveTextStyle>Live</LiveTextStyle>
    </Stack>
  );
};
export default LiveIndicator;
