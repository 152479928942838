/**
 * @type {import("plotly.js").Config}
 */
export const config = {
  scrollZoom: true,
  useResizeHandler: true,
  responsive: true,
  // Plotly's in-built controls settings
  displaylogo: false,
  displayModeBar: true,
  // Below buttons will be removed from the control's list
  modeBarButtonsToRemove: ["toImage", "select2d", "lasso2d"],
};
/**
 * @type {import("plotly.js").Config}
 */
export const errorChartConfig = {
  useResizeHandler: true,
  displayModeBar: false,
};

export const styles = {
  width: "100%",
  height: "100%",
};
