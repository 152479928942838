Array.prototype.last = function () {
  return this[this.length - 1];
};

export function processAnomalySeriesData(timeseries, y, anomaly, actual_value_text, spike_up_down) {
  const anomalyIdx = anomaly
    .map((val, idx) => {
      if (val === "True") {
        return idx;
      } else {
        return -1;
      }
    })
    .filter((item) => item >= 0);

  const dataActual = y.filter((val, idx) => !anomalyIdx.includes(idx));
  const plotActual = timeseries.filter((val, idx) => !anomalyIdx.includes(idx));
  const textActual = actual_value_text.filter((val, idx) => !anomalyIdx.includes(idx));
  const dataAnomaly = y.filter((val, idx) => anomalyIdx.includes(idx));
  const plotAnomaly = timeseries.filter((val, idx) => anomalyIdx.includes(idx));
  const textAnomaly = actual_value_text.filter((val, idx) => anomalyIdx.includes(idx));
  const anomalyLabels = spike_up_down.filter((val, idx) => anomalyIdx.includes(idx));

  const isCurrentAnomaly = anomaly.last() === "True";
  if (isCurrentAnomaly) {
    // Don't plot the last anomalous point as we are plotting it with above anomaly marker
    dataAnomaly.splice(-1);
    plotAnomaly.splice(-1);
    textAnomaly.splice(-1);
    anomalyLabels.splice(-1);
  }

  return {
    dataActual,
    plotActual,
    textActual,
    plotAnomaly,
    dataAnomaly,
    textAnomaly,
    anomalyLabels,
    isCurrentAnomaly
  };
}
