import {
  EVENT_HISTORY_REQUEST,
  EVENT_HISTORY_SUCCESS,
  EVENT_HISTORY_FAIL,
} from "../constants/eventHistoryConstants";

export const eventHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_HISTORY_REQUEST:
      return { loading: true, data: action.payload };
    case EVENT_HISTORY_SUCCESS:
      return { loading: false, data: action.payload };
    case EVENT_HISTORY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
