import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import { colors } from "../../../../shared/theme-constants";

export const ChipContainer = styled(Stack)({
  position: "absolute",
  top: -30,
  left: 30,
  backgroundColor: "white",
  boxShadow: "0px -14px 14px -12px rgb(17 39 133 / 16%)",
  borderTopRightRadius: 10,
  borderTopLeftRadius: 10,
});

export const TimeChip = styled("div")`
  color: ${colors.title};
  font-size: 12px;
  padding: 7px 10px;
  align-self: center;
`;
