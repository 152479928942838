import styled from "styled-components";

import { colors } from "../../../shared/theme-constants";

export const Button = styled.button.attrs((props) => ({
  type: props.type,
  text: props.text,
  disabled: props.disabled,
  onClick: props.onClick,
}))`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  color: white;
  position: static;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  /* Primary */
  background: ${colors.blue[600]};
  border-color: ${colors.blue[600]};

  border-radius: 4px;
  border: 0;
  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:focus {
    outline: 0;
    color: white;
    border-color: ${colors.blue[700]};
    background-color: ${colors.blue[700]};
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.6;
    filter: saturate(60%);
  }

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
`;
