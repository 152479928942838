import styled from "styled-components";

export const TenantSelectionContainer = styled.div`
  justify-content: center;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.ipadPro}) {
    width: calc(100% - 778px);
  }
`;

export const TenantContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  align-items: center;
`;

export const LetsGoButton = styled.button`
  height: 53px;
  width: 409px;
  margin-top: 36px;
  border-radius: 10px 10px 10px 10px;
  border: none;
  background-color: #68d1bf;
  font-style: normal;
  font-weight: 700;
  font-size: 23.87px;
  color: #112785;
  line-height: 37px;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    background-color: #40c4ae;
  }

  &:disabled {
    background: rgba(239, 239, 239, 0.3);
    cursor: default;
  }

  @media (max-width: ${({ theme }) => theme.tablet}) {
    height: 60px;
    width: 174px;
  }
  @media (max-width: ${({ theme }) => theme.ipadPro}) {
    height: 57.4635124206543px;
    width: 348.7672119140625px;
    border-radius: 10px 10px 10px 10px;
    font-size: 20px;
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 35.75331115722656px;
    width: 217px;
    border-radius: 10px 10px 10px 10px;
    font-size: 12.87px;
  }
`;

export const customStyles = {
  control: (base) => ({
    ...base,
    height: 53,
    minHeight: 35,
    fontSize: 20,
    width: 409,

    "@media (max-width: 1199px)": {
      ...base,
      height: 57.4635124206543,
      width: 348.7672119140625,
    },

    "@media (max-width: 768px)": {
      ...base,
      height: 35.75331115722656,
      width: 217,
      fontSize: 14,
    },

    "@media (max-width: 560px)": {
      ...base,
      height: 35.75331115722656,
      width: 217,
    },
  }),
  option: (base) => ({
    ...base,
    fontSize: 23,
  }),
};
