import React from "react";
import { styled } from "@mui/material/styles";
import { GTAG_EVENTS, sendDataToGTM } from "../utils/gtmHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { buildUrl } from "../utils/stringUtils";
import { Button, Container, Paper, useMediaQuery } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TransitionAlerts from "../components/common/ui-components/TransitionAlerts";
import { typography } from "../shared/theme-constants";

const getInTouchUrl = "https://outoftheblue.ai/contact-us/";
const errorMessages = {
  invalid_domain: "Please use your work email",
};

function getDocsRedirectPathname(location) {
  const searchParams = new URLSearchParams(location.search);

  if (searchParams.get("redirect") === "docs") {
    const docsReturnUrl = searchParams.get("location");
    const docsHref = buildUrl("docs", { location: docsReturnUrl });

    // remove origin from given href so we only have pathname and search params
    // as those are only values we need to sent to server as instruction for redirect
    return docsHref.replace(new URL(docsHref).origin, "");
  }

  return undefined;
}

const LoginButton = styled(Button)(({ theme }) => ({
  height: "41px",
  background: "#FFFFFF",
  "&:hover": {
    background: "#FFFFFF",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
  },
  ":hover": {
    cursor: "pointer",
  },
  alignSelf: "center",
  marginTop: "5px",
  marginBottom: "5px",
  width: "100%",
  color: "#424242",
  border: "1px solid #E0E0E0",
  borderRadius: "10px",
  fontSize: "0.813rem",
}));

const LoginScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const returnUrl = location.state?.from?.pathname || getDocsRedirectPathname(location) || "/";

  // Get the query params from the URL
  const searchParams = new URLSearchParams(location.search);

  // Get the query param for the error message
  const errorMessage = searchParams.get("error");

  // Send the event to Google Tag Manager, if there is an error message
  if (errorMessage) {
    sendDataToGTM(GTAG_EVENTS.USER_LOGIN_ERROR, {
      error: errorMessage,
    });
  }

  const handleGoogleLogin = () => {
    // Redirect to the Google OAuth login endpoint on your Express server.

    const googleLoginUrl = buildUrl("auth/google", { redirectTo: returnUrl });

    window.open(googleLoginUrl, "_self");
  };

  const handleMicrosoftLogin = () => {
    // Redirect to the Microsoft OAuth login endpoint on your Express server.

    const microsoftLoginUrl = buildUrl("auth/microsoft", { redirectTo: returnUrl });

    window.open(microsoftLoginUrl, "_self");
  };

  const handleOktaLogin = () => {
    // Redirect to the Okta OAuth login endpoint on your Express server.

    const oktaLoginUrl = buildUrl("auth/okta", { redirectTo: returnUrl });

    window.open(oktaLoginUrl, "_self");
  };

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#FAFAFA",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "auto",
          width: useMediaQuery("(min-width:600px)") ? "400px" : "100vw",
          maxWidth: "400px",
          boxShadow: "none",
          backgroundColor: "transparent",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
              width: "100%",
            }}
          >
            <img
              src="/images/logo-with-tagline.png"
              alt="Out of the Blue"
              width="300px"
              height="auto"
            />
          </div>
          <div
            style={{
              height: "41px",
              width: "80%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            {errorMessage && (
              <TransitionAlerts
                alert_message={`Error: ${errorMessages[errorMessage]}`}
                alert_type="error"
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "40px",
              marginTop: "10px",
              width: "80%",
            }}
          >
            <LoginButton
              onClick={handleGoogleLogin}
              startIcon={
                <img src="/images/google-logo.png" alt="Google" width="20px" height="20px" />
              }
            >
              Sign up with Google
            </LoginButton>
            <LoginButton
              onClick={handleMicrosoftLogin}
              startIcon={
                <img src="/images/microsoft-logo.png" alt="Google" width="20px" height="20px" />
              }
            >
              Sign up with Microsoft
            </LoginButton>
            <LoginButton
              onClick={handleOktaLogin}
              sx={{
                backgroundColor: "#3063E7",
                color: "#FAFAFA",
                "&:hover": {
                  backgroundColor: "#3063E7",
                },
                border: "1px solid #3063E7",
              }}
            >
              Sign up with SSO
            </LoginButton>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img src="/images/powered-by-okta.png" alt="Powered by Okta" height="12px" />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "40px",
              width: "90%",
            }}
          >
            <span
              style={{
                fontSize: "13px",
                fontWeight: "normal",
                color: "#616161",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              By signing up, you agree to our&nbsp;
              <span
                style={{
                  color: "var(--grey-900)",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Terms of Service&nbsp;
              </span>&nbsp;
              and&nbsp;
              <span
                style={{
                  color: "var(--grey-900)",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Privacy Policy
              </span>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
              width: "90%",
            }}
          >
            <span
              style={{
                fontSize: "13px",
                fontWeight: typography.font_weight.light,
                color: "#616161",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Already have an account ?&nbsp;
              <span
                style={{
                  color: "var(--grey-900)",
                  cursor: "pointer",
                  fontSize: "13px",
                  fontWeight: typography.font_weight.light,
                }} // Different color and cursor for the sign-up link
                onClick={() => navigate("/login")} // Redirect to the signup page
              >
                Log in {"\u2192"}
              </span>
            </span>
          </div>
        </div>
      </Paper>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          height: "50px",
          display: "flex",
          flexDirection: "row",
          marginRight: useMediaQuery("(min-width:768px)") ? "40px" : "0px",
          justifyContent: useMediaQuery("(min-width:768px)") ? "flex-end" : "center",
        }}
      >
        <span
          style={{
            fontSize: "12px",
            fontWeight: "normal",
            color: "#6B6D77",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <a
            href="https://outoftheblue.ai/company/"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "inherit",
              textDecoration: "none",
            }}
          >
            ©2022 Out Of The Blue™
          </a>
        </span>
      </div>
    </Container>
  );
};
export default LoginScreen;
