import styled from "@emotion/styled";
import { Alert, Snackbar, alertClasses } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { UNSET_SNACKBAR } from "../../../reducers/notificationSnackbarReducer";

const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: "var(--white)",
  [`&.${alertClasses.outlinedInfo}`]: { border: "1px solid var(--new-car)" },
  [`&.${alertClasses.outlinedError}`]: { border: "1px solid var(--rose-pearl)" },
  [`& .${alertClasses.icon}`]: { color: "var(--new-car)" },
}));

const NotificationSnackbar = () => {
  const dispatch = useDispatch();
  const snackbarOpen = useSelector((state) => state.notificationSnackbar.snackbarOpen);
  const snackbarType = useSelector((state) => state.notificationSnackbar.snackbarType);
  const snackbarMessage = useSelector((state) => state.notificationSnackbar.snackbarMessage);
  const { vertical, horizontal } = useSelector((state) => state.notificationSnackbar.position);

  // forward close event to the caller
  const handleClose = (event, reason) => {
    if (reason == "clickaway") return;
    dispatch({ type: UNSET_SNACKBAR });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={snackbarOpen}
      onClose={handleClose}
      autoHideDuration={2000}
      style={{ zIndex: 999999 }}
    >
      <StyledAlert
        elevation={6}
        variant="outlined"
        color={snackbarType}
        severity={snackbarType}
      >
        {snackbarMessage}
      </StyledAlert>
    </Snackbar>
  );
};

export default NotificationSnackbar;
