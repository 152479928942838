import { Alert } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getFeedback,
  postFeedback,
} from "../../../../actions/groupInsightActions";
import { RESOURCE_TYPE_GROUP } from "../../../../constants/commonConstants";
import { ERROR, SUCCESS } from "../../../../constants/notificationConstants";
import {
  GTagArgsContext,
  GTagGroupContext,
} from "../../../../context/GtagContext";
import { useStateWithCallback } from "../../../../hooks";
import { HTTP_STATUS } from "../../../../shared/enums/http-status";
import { GTAG_EVENTS, sendDataToGTM } from "../../../../utils/gtmHelper";
import Icon from "../../../common/Icon";
import { MuiSelect } from "../../../common/mui-wrapper-components/Select/Select";
import { RadioButton, RadioGroup } from "../../../common/styled-components";
import "./feedback.css";

export default function Feedback({ resourceId, resourceType, isModal }) {
  const [open, setOpen] = useState(false);
  const [helpful, setHelpful] = useStateWithCallback(false);
  const [notHelpful, setNotHelpful] = useStateWithCallback(false);
  const [comment, setComment] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const gtagArgsContext = useContext(GTagArgsContext);
  const gtagGroupContext = useContext(GTagGroupContext);

  const isGroup = resourceType === RESOURCE_TYPE_GROUP;

  const feedbackOptions = [
    { value: "Upstream data issue", label: "Upstream data issue" },
    { value: "Incorrect forecast", label: "Incorrect forecast" },
    { value: "Not relevant", label: "Not relevant" },
    { value: "Other", label: "Other" },
  ];

  useEffect(() => {
    let isMounted = true;
    // Get the previously selected feedback provided by the logged-in user
    dispatch(getFeedback(resourceType, resourceId))
      .then((response) => {
          if(isMounted){
            if (response.feedback_id) {
              if (response.feedback_ishelpful === true) {
                setHelpful(true);
                setNotHelpful(false);
              } else if (response.feedback_ishelpful === false) {
                setHelpful(false);
                setNotHelpful(true);
              } else if (response.feedback_ishelpful === null) {
                setHelpful(false);
                setNotHelpful(false);
              }
              if (response.feedback_comment) {
                setComment(response.feedback_comment);
              }
            } else {
              setHelpful(false);
              setNotHelpful(false);
            }
        }
      })
      .catch((error) => {
        if (error?.status === HTTP_STATUS.UNAUTHORIZED) {
          navigate("/login");
        }
        if (error?.status === HTTP_STATUS.NOT_FOUND) {
          setHelpful(false);
          setNotHelpful(false);
        }
      });

      return () => isMounted=false;
    // navigate excluded from deps array because it changes on every route change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, resourceId, resourceType, setHelpful, setNotHelpful]);

  const handleFeedbackSelection = (event) => {
    const feedbackComment = event.target.value;
    setComment(feedbackComment);
    dispatch(postFeedback(resourceType, resourceId, null, feedbackComment))
      .then((result) => {
        if (result.success) {
          setOpen(true);
        }
      })
      .catch((error) => {
        if (!error.success) {
          dispatch({
            type: ERROR,
            payload: `There was an Error While posting feedback for this ${resourceType}`,
          });
        }
      });
    const gTagData = isGroup ? gtagGroupContext : gtagArgsContext;
    const gtagArg = { selected_option: feedbackComment };
    sendDataToGTM(GTAG_EVENTS.FEEDBACK_DROPDOWN_SELECTED, {
      ...gTagData,
      ...gtagArg,
    });
  };
  /**
   * This function accepts value of the smiley/frownie clicked
   * It will toggle the current value and generate appropirate payload
   * Also it will decide whether to call group feedback  or insight feedback based on resourceType param
   * resource Id is driven by resource type
   */
  const handleSubmit = (value) => {
    if (value === "isHelpful") {
      dispatch(
        postFeedback(resourceType, resourceId, !helpful ? true : null)
      ).then((result) => {
        if (result.success) {
          setHelpful(!helpful);
          setNotHelpful(false);
          dispatch({ type: SUCCESS, payload: result.message });
        } else {
          dispatch({
            type: ERROR,
            payload: `There was an Error While posting feedback for this ${resourceType}`,
          });
        }
      });
    } else {
      dispatch(
        postFeedback(resourceType, resourceId, !notHelpful ? false : null)
      ).then((result) => {
        if (result.success) {
          setNotHelpful(!notHelpful);
          setHelpful(false);
          dispatch({ type: SUCCESS, payload: result.message });
        } else {
          dispatch({
            type: ERROR,
            payload: `There was an Error While posting feedback for this ${resourceType}`,
          });
        }
      });
    }
    const gtagArgs = {
      emoji_selected:
        value === "isHelpful"
          ? "helpful"
          : value === "isNotHelpful"
          ? "not helpful"
          : "",
    };
    const gTagData = isGroup ? gtagGroupContext : gtagArgsContext;
    sendDataToGTM(GTAG_EVENTS.EMOJI_CLICKED, {
      ...gTagData,
      ...gtagArgs,
    });
  };

  useEffect(() =>{
    const alertMessageTimeOut = setTimeout(() => {
      setOpen(false);
    }, 3000);

    return ()=> clearTimeout(alertMessageTimeOut);
  }, [open]);

  return (
    <div>
      <div>
        {open && (
          <Alert
            variant="filled"
            severity="success"
            sx={{
              backgroundColor: "var(--light-lime-green)",
              marginBottom: "1rem",
              color: "var(--lime-green)",
              alignItems: "center",
              border: "1px solid var(--lime-green)",
            }}
            icon={
              <Icon
                name='feedback-success'
                size = '1rem'
              />
            }
            >
            Thanks for the feedback!
          </Alert>
        )}
      </div>
      <div data-snippyly-comment-disabled className="container">
        <div className="feedback-container">
          <div className="feedback-chat">
            <div className="feedback-section">
              <span className="feedback-question">Was this helpful?</span>
              <div style={{ width: "100%" }}>
                <RadioGroup>
                  <RadioButton
                    name="isHelpful"
                    value="isHelpful"
                    label="thumb_up"
                    title="Helpful"
                    isChecked={helpful}
                    handleChange={handleSubmit}
                  />
                  <RadioButton
                    name="isNotHelpful"
                    value="isNotHelpful"
                    label="thumb_down"
                    title="Not Helpful"
                    isChecked={notHelpful}
                    handleChange={handleSubmit}
                  />
                </RadioGroup>
              </div>
            </div>

            {!isModal && (
              <MuiSelect
                options={feedbackOptions}
                selectedValue={comment}
                handleChange={handleFeedbackSelection}
                placeholder="Feedback"
                size="small"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
