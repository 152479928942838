import { styled } from "@mui/material/styles";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputBaseClasses } from "@mui/material/InputBase";
import { colors } from "../../../../shared/theme-constants";

export const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  maxWidth: 190,
  "& .MuiInputBase-root": {
    width: "100%",
    color: "var(--purple-fog)",
  },
  "& .MuiInputBase-input": {
    "&::placeholder": {
      opacity: 1,
    },
  },
});

export const StyledPopper = styled(Popper)({
  paddingTop: 4,
  [`& .${autocompleteClasses.listbox} .${autocompleteClasses.option}`]: {
    padding: "6px 12px",
    fontSize: "0.875rem",
  },
});

export const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  padding: '10px 10px',
  color:  "var(--mariner)",
}));

export const GroupItems = styled('ul')(({ theme }) =>({
  paddingLeft: theme.typography.pxToRem(10),
}));

export const StyledInput = styled(TextField)(({ theme }) => ({
  [`& .${outlinedInputClasses.root}`]: {
    fontSize: "0.875rem",

    [`&:not(.${outlinedInputClasses.focused}):hover .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: colors.comet,
    },
    // on focused
    [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
    {
      boxShadow: "0px 0px 0px 3px rgba(3, 102, 214, 0.3)",
    },
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderRadius: 4,
    border: `1px solid ${colors.cadet_gray}`,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    boxShadow: "0px 1px 2px 0px rgba(31, 41, 55, 0.08)",
  },
  [`& .${inputBaseClasses.input}`]: {
    height: "100%"
  }
}));
