import React from "react";
import { Outlet } from "react-router-dom";
import NotificationSnackbar from "./common/mui-wrapper-components/NotificationSnackbar";

const OnboardingLayout = ({}) => {
  return (
    <React.Fragment>
      <NotificationSnackbar />
      <Outlet/>
    </React.Fragment>
  );
};

export default OnboardingLayout;
