import CloseIcon from "@mui/icons-material/Close";
import { Alert, Divider, Stack, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelectedTenant } from "../../../hooks/useSelectedTenant";
import { SET_SNACKBAR } from "../../../reducers/notificationSnackbarReducer";
import { typography } from "../../../shared/theme-constants";
import { StyledActionButton } from "../mui-wrapper-components/Button";
import { Loading } from "../styled-components";

const DeleteGlobalFilterDialog = ({
  isOpen,
  closeDeleteGlobalFilterDialog,
  filterToDelete,
}) => {
  const [deletingGlobalFilter, setDeletingGlobalFilter] = useState(false);
  const url = process.env.REACT_APP_BASE_URL;
  const selectedTenant = useSelectedTenant();
  const dispatch = useDispatch();

  const handleDeleteGlobalFilter = async () => {
    setDeletingGlobalFilter(true);
    try {
      const response = await axios.delete(
        `${url}feed/filters/${filterToDelete?.id}`,
        {
          params: {
            tenant_id: selectedTenant,
          },
        }
      );
      if (response?.data?.success) {
        dispatch({
          type: SET_SNACKBAR,
          snackbarOpen: true,
          snackbarMessage: "Global filter deleted successfully",
          snackbarType: "success",
        });
        closeDeleteGlobalFilterDialog();
      }
    } catch (error) {
      dispatch({
        type: SET_SNACKBAR,
        snackbarOpen: true,
        snackbarMessage: "Something went wrong.Please try again",
        snackbarType: "error",
      });
    }
    setDeletingGlobalFilter(false);
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth={false}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "525px",
          },
        },
      }}
    >
      <DialogTitle
        sx={(theme) => ({
          textAlign: "center",
          fontSize: theme.typography.pxToRem(16),
        })}
      >
        Delete Global Filter?
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeDeleteGlobalFilterDialog}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: "rgba(84, 92, 126, 1)",
        })}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <DialogContent sx={(theme) => ({ padding: theme.spacing(6) })}>
        {deletingGlobalFilter ? (
          <Stack
            direction="column"
            gap={6}
            sx={{ alignItems: "center", height: "100%" }}
          >
            <Loading margin="24px" />
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(12),
                fontWeight: typography.font_weight.mediumx,
              })}
            >
              Deleting filter: {filterToDelete?.display_name}
            </Typography>
          </Stack>
        ) : (
          <Stack
            direction="column"
            gap={6}
            sx={{ alignItems: "center", height: "100%" }}
          >
            <Stack
              direction="column"
              gap={1}
              sx={{ alignItems: "center", height: "100%" }}
            >
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: typography.font_weight.mediumx,
                })}
              >
                This will permanently clear your segmented analytics
              </Typography>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: typography.font_weight.mediumx,
                })}
              >
                for your dimension value of choice.
              </Typography>
            </Stack>
            <Stack
              direction="column"
              gap={2}
              sx={{ alignItems: "center", height: "100%" }}
            >
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(14),
                  fontWeight: typography.font_weight.semiBold,
                })}
              >
                Filter name: {filterToDelete?.display_name}
              </Typography>
              <Alert
                icon={false}
                severity="success"
                style={{ width: "100%" }}
                sx={(theme) => ({
                  backgroundColor: "rgba(245, 245, 245, 1)",
                  color: "rgba(102, 102, 102, 1)",
                  fontSize: theme.typography.pxToRem(12),
                  mt: 1,
                })}
              >
                <Stack direction="row" gap={2}>
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(12),
                      fontWeight: typography.font_weight.light,
                      color: "rgba(0, 0, 0, 1)",
                    })}
                  >
                    Dimension:
                  </Typography>
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(12),
                      fontWeight: typography.font_weight.mediumx,
                      color: "rgba(0, 0, 0, 1)",
                    })}
                  >
                    {filterToDelete?.dimension_name}
                  </Typography>
                </Stack>
                <Stack direction="row" gap={2}>
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(12),
                      fontWeight: typography.font_weight.light,
                      color: "rgba(0, 0, 0, 1)",
                    })}
                  >
                    Dimension Value:
                  </Typography>
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(12),
                      fontWeight: typography.font_weight.mediumx,
                      color: "rgba(0, 0, 0, 1)",
                    })}
                  >
                    {filterToDelete?.dimension_value}
                  </Typography>
                </Stack>
              </Alert>
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          justifyContent: "center",
          padding: theme.spacing(6),
        })}
      >
        <StyledActionButton
          disabled={deletingGlobalFilter}
          onClick={handleDeleteGlobalFilter}
        >
          Confirm
        </StyledActionButton>
        <StyledActionButton
          style={{
            background: "var(--white)",
            color: "var(--comet)",
            borderColor: "var(--comet)",
          }}
          onClick={closeDeleteGlobalFilterDialog}
          disabled={deletingGlobalFilter}
        >
          Cancel
        </StyledActionButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteGlobalFilterDialog;
