import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { StyledTitle } from "./styled-components";
import {
  ColoredBoldMetric,
  SummaryText,
} from "./styled-components/insight-summay/Title.styled";
import { colors, typography } from "../../shared/theme-constants";
import { deserializeToJSX, parseMarkdown } from "../../services/markdown";
import LabelChip from "./LabelChip";

function createSerializer(accent) {
  return {
    link: ({ url, value }) => <Link to={`/metrics/${url}`}>{value}</Link>,
    bold: ({ value }) => (
      <ColoredBoldMetric accent={accent}>{value}</ColoredBoldMetric>
    ),
  };
}

const InsightTitleSummaryView = ({
  accent,
  title,
  summary: summaryWithDrivenBy,
  insightType,
  allowEdit = false,
  funnels = [],
}) => {
  const [summary, drivenBy] = summaryWithDrivenBy.split("\n");
  const contentMarkup = deserializeToJSX(
    parseMarkdown(summary.concat("\n")),
    createSerializer(accent)
  );

  return (
    <Stack spacing = {4}>
      <StyledTitle $allowEdit={allowEdit}>
        <span className="inline-text_copy inline-text_copy--active">
          {deserializeToJSX(parseMarkdown(title), createSerializer(accent))}
        </span>
      </StyledTitle>

      <Box sx={(theme) => ({ display: 'flex', alignItems: 'center', paddingBottom: theme.spacing(0) })} style={{ marginTop: '6px' }}>
        <img width="13px" height="12px" src={`/icons/route.png`} alt="Route icon" />
        <Typography sx={(theme) => ({ fontSize: theme.typography.pxToRem(13), color: '#1E46EF', marginLeft: theme.spacing(1.5) })} className="insight-type">
          {insightType}
        </Typography>
      </Box>

      {Array.isArray(funnels) && funnels?.length > 0 && (
      <Stack direction="row">
        <LabelChip
          tags={funnels.slice(0, 3)}
        />
      </Stack>
      )}

      <SummaryText $allowEdit={allowEdit}>
        <Typography
          variant="inherit"
          fontWeight={typography.font_weight.bold}
          component="span"
        >
          Summary:{" "}
        </Typography>

        <Box component="span" sx={{ "span + span": { marginLeft: 2 } }}>
          {contentMarkup}
        </Box>
        <Typography
          pt="10px"
          variant="caption"
          color={colors.gray[600]}
          fontWeight={typography.font_weight.semiBold}
          component="div"
        >
          {drivenBy}
        </Typography>
      </SummaryText>
    </Stack>
  );
};

export default InsightTitleSummaryView;
