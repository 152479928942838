import { merge } from "../../utils/objects";
import { colors } from "../theme-constants";
/**
 * @type {import("plotly.js").Data}
 */
export const timeseriesPlot = {
  type: "scatter",
  marker: {
    color: colors.gray[900],
    size: 3,
  },
  mode: "markers",
};
/**
 * @type {import("plotly.js").Data}
 */
export const forecastPlot = {
  mode: "lines",
  line: {
    color: colors.blue[300],
    width: 0,
  },
};
/**
 * @type {import("plotly.js").Layout}
 */
export const scatterLayout = {
  ticks: "outside",
  showlegend: true,
  margin: {
    t: 60,
  },
  title: {
    font: {
      family: "Poppins",
      size: 18,
      color: colors.blue[925],
    },
    xref: "paper",
    x: 0,
  },
  // For responsive chart
  autosize: true,
  hovermode: "x unified",
  hoverlabel: {
    bgcolor: "#fff",
    bordercolor: colors.gray[200],
    font: {
      family: "Inter",
      color: "#000",
      size: "12px",
    },
    namelength: -1,
  },
  legend: {
    x: 1.1,
    y: 1,
    font: {
      color: colors.gray[500],
      size: 12,
    },
    traceorder: "reversed",
    itemsizing: "constant",
    orientation: "h",
    yanchor: "bottom",
    xanchor: "right",
    valign: "top",
  },
  xaxis: {
    showline: false,
    showgrid: false,
    title: {
      text: "",
      font: {
        color: colors.gray[300],
      },
    },
    tickfont: {
      color: colors.gray[500],
    },
  },
  yaxis: {
    showline: false,
    zeroline: true,
    zerolinecolor: colors.gray[100],
    title: {
      text: "",
      font: {
        color: colors.gray[500],
      },
    },
    tickfont: {
      color: colors.gray[500],
    },
  },
};
/**
 * @type {import("plotly.js").Layout}
 */
export const errorLayout = merge(scatterLayout, {
  yaxis: {
    tickfont: {
      color: "white",
    },
  },
  xaxis: {
    type: "date",
    tickformat: "%d %b",
  },
});

// New configs as per Highcharts implementation
export const spikesTimeSeriesLayout = (accent) => {
  return {
    name: "Spikes",
    linkedTo: "spikes",
    type: "spline",
    color: accent == "positive" ? "var(--lime-green)" : "var(--spike-red)",
    cursor: "pointer",
    zIndex: 3,
    marker: {
      lineColor: accent == "positive" ? "var(--lime-green)" : "var(--spike-red)",
      fillColor: accent == "positive" ? "var(--lime-green)" : "var(--spike-red)",
      enabled: true,
      symbol: "circle",
    },
    states: {
      hover: {
        enabled: true,
        lineColor: accent == "positive" ? "var(--lime-green)" : "var(--spike-red)",
      },
    },
  };
};

// New configs as per Highcharts implementation
export const spikesPointLayout = (accent, direction) => {
  return {
    name: direction == "up" ? "Spike Up" : "Spike Down",
    linkedTo: `spike-${direction}`,
    cursor: "pointer",
    zIndex: 6,
    lineWidth:0,
    marker: {
      lineWidth: 3,
      fillColor: accent == "positive" ? "var(--lime-green)" : "var(--spike-red)",
      lineColor: accent == "positive" ? "var(--lime-green)" : "var(--spike-red)",
      enabled: true,
      symbol: "circle",
    },
    states: {
      hover: {
        enabled: false
      },
    },
  };
};

export const timeSeriesAreaRangelayout = {
  name: "Expected Range",
  type: "areasplinerange",
  lineWidth: 0,
  color: "var(--pale-blue)",
  opacity: 0.6,
  marker: {
    enabled: false,
    states:{
      hover:{
        enabled: false
      }
    },
  },
  fillOpacity: 1,
  zIndex: 0,
};

export const actualTimeSeriesLayout = {
  name: "Actual",
  linkedTo: "actual",
  color: colors.gray[375],
  lineWidth: 1.25,
  type: "spline",
  zIndex: 5,
  marker: {
    fillColor: colors.gray[375],
    lineWidth: 1,
    lineColor: colors.gray[550],
    symbol: "circle",
    radius: 3,
    states:{
      hover:{
        enabled: false
      }
    },
  },
};

export const previousTimeSeriesLayout = {
  name: "Previous",
  linkedTo: "previous",
  id: "previous_period_series",
  color: colors.blue[300],
  lineWidth: 1.25,
  type: "spline",
  dashStyle: "LongDash", // Make the trace dotted
  zIndex: 1,
  marker: {
    enabled: false,  // Disables the markers
    states: {
      hover: {
        enabled: false, // Ensures markers don’t appear on hover
      },
    },
  },
};

export const expectedTimeSeriesRangeLayout = {
  name: "Expected Value",
  linkedTo: "expected",
  type: "spline",
  zIndex: 4,
  dashStyle: "Dash",
  color: colors.gray[550],
  marker: {
    fillColor: "var(--new-car)",
    symbol: "circle",
  },
  visible: false,
  showInLegend: false,
};