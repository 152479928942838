// React imports
import { Box } from "@mui/material";
import React from "react";
// Local Imports
import { RESOURCE_TYPE_GROUP } from "../../constants/commonConstants";
import { useAxios } from "../../hooks/useAxios";
import { HTTP_STATUS } from "../../shared/enums/http-status";
import { errorMessage } from "../../shared/intl/error-messages";
import InsightSummaryLoader from "../common/InsightSummaryLoader";
import ApiScreenError from "../common/error-components/ApiScreenError";
import { StyledCard } from "../common/styled-components";
import { Message } from "../common/ui-components/Message";
import GroupedInsight from "./groupedInsights/GroupedInsight";
import Insight from "./insight/Insight";
import { useSelector } from "react-redux";

function Group({ groupId, handleClickActionIcon, fromLink }) {
  const tenantGlobalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);

  const { data: groupData, isLoading, isError, error } = useAxios(`insights/groups/${groupId}`, {
    params: {
      dim_name: tenantGlobalFilter?.dimension_name,
      dim_val: tenantGlobalFilter?.dimension_value,
    },
  });
  if (isLoading) {
    return <InsightSummaryLoader />;
  }

  if (isError) {
    if (error.response?.status === HTTP_STATUS.NOT_FOUND) {
      return <ApiScreenError error={error} resourceType="Group" />;
    } else {
      return (
        <Box pb="50px">
          <StyledCard>
            <Message>{errorMessage.CARD_ERROR}</Message>
          </StyledCard>
        </Box>
      );
    }
  }

  if (groupData?.insights?.length > 1) {
    return (
      <GroupedInsight
        groupedInsight={groupData}
        handleClickActionIcon={handleClickActionIcon}
        fromLink={fromLink}
        resourceType={RESOURCE_TYPE_GROUP}
        resourceId={groupData.group_id}
      />
    );
  }

  return <Insight insight={groupData} handleClickActionIcon={handleClickActionIcon} fromLink={fromLink} resourceType={RESOURCE_TYPE_GROUP} resourceId={groupData.group_id} />;
}

export default Group;
