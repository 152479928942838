export const GROUP_INSIGHT_OPEN_REQUEST = "GROUP_INSIGHT_OPEN_REQUEST";
export const GROUP_INSIGHT_OPEN_SUCCESS = "GROUP_INSIGHT_OPEN_SUCCESS";
export const GROUP_INSIGHT_OPEN_FAIL = "GROUP_INSIGHT_OPEN_FAIL";

export const GROUP_INSIGHT_IMPORTANT_REQUEST = "GROUP_INSIGHT_IMPORTANT_REQUEST";
export const GROUP_INSIGHT_IMPORTANT_SUCCESS = "GROUP_INSIGHT_IMPORTANT_SUCCESS";
export const GROUP_INSIGHT_IMPORTANT_FAIL = "GROUP_INSIGHT_IMPORTANT_FAIL";

export const GROUP_INSIGHT_SNOOZE_REQUEST = "GROUP_INSIGHT_SNOOZE_REQUEST";
export const GROUP_INSIGHT_SNOOZE_SUCCESS = "GROUP_INSIGHT_SNOOZE_SUCCESS";
export const GROUP_INSIGHT_SNOOZE_FAIL = "GROUP_INSIGHT_SNOOZE_FAIL";
export const GROUP_INSIGHT_SNOOZE_COMPLETE = "GROUP_INSIGHT_SNOOZE_COMPLETE";

export const GROUP_INSIGHT_FLAG_REQUEST = "GROUP_INSIGHT_FLAG_REQUEST";
export const GROUP_INSIGHT_FLAG_SUCCESS = "GROUP_INSIGHT_FLAG_SUCCESS";
export const GROUP_INSIGHT_FLAG_FAIL = "GROUP_INSIGHT_FLAG_FAIL";

export const GROUP_INSIGHT_ARCHIVE_REQUEST = "GROUP_INSIGHT_ARCHIVE_REQUEST";
export const GROUP_INSIGHT_ARCHIVE_SUCCESS = "GROUP_INSIGHT_ARCHIVE_SUCCESS";
export const GROUP_INSIGHT_ARCHIVE_FAIL = "GROUP_INSIGHT_ARCHIVE_FAIL";

export const GROUP_INSIGHT_RESOLVE_REQUEST = "GROUP_INSIGHT_RESOLVE_REQUEST";
export const GROUP_INSIGHT_RESOLVE_SUCCESS = "GROUP_INSIGHT_RESOLVE_SUCCESS";
export const GROUP_INSIGHT_RESOLVE_FAIL = "GROUP_INSIGHT_RESOLVE_FAIL";

export const GROUP_INSIGHT_LIST_REQUEST = "GROUP_INSIGHT_LIST_REQUEST";
export const GROUP_INSIGHT_LIST_SUCCESS = "GROUP_INSIGHT_LIST_SUCCESS";
export const GROUP_INSIGHT_LIST_FAIL = "GROUP_INSIGHT_LIST_FAIL";
