import axios from "axios";

import {
  METRIC_CREATE_FAIL,
  METRIC_CREATE_REQUEST,
  METRIC_CREATE_SUCCESS,
  METRIC_DELETE_FAIL,
  METRIC_DELETE_REQUEST,
  METRIC_DELETE_SUCCESS,
  METRIC_DETAILS_FAIL,
  METRIC_DETAILS_REQUEST,
  METRIC_DETAILS_SUCCESS,
  METRIC_LIST_FAIL,
  METRIC_LIST_REQUEST,
  METRIC_LIST_SUCCESS,
  METRIC_UPDATE_FAIL,
  METRIC_UPDATE_REQUEST,
  METRIC_UPDATE_SUCCESS,
} from "../constants/metricConstants";
import { PIPELINE_SCHEDULE } from "../constants/multiSelectFilterConstants";

const url = process.env.REACT_APP_BASE_URL;

export const listMetrics = (queryParam) => async (dispatch) => {
  try {
    dispatch({ type: METRIC_LIST_REQUEST });

    const params = new URLSearchParams(queryParam);
    const { data } = await axios.get(`${url}metrics_hub/metrics`, { params });

    dispatch({
      type: METRIC_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: METRIC_LIST_FAIL,
      payload: error.response,
    });
  }
};

export const listMetricDetails = (metricId) => async (dispatch) => {
  try {
    dispatch({ type: METRIC_DETAILS_REQUEST });

    const { data } = await axios.get(`${url}metrics_hub/metrics/${metricId}`);
    // dispatch the pipeline details which was last active
    dispatch({
      type : PIPELINE_SCHEDULE,
      payload : data.last_active_pipeline_schedule
    });

    dispatch({
      type: METRIC_DETAILS_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: METRIC_DETAILS_FAIL,
      payload: error.response,
    });
  }
};

export const getRelatedMetrics = (metricId, queryParam) => async (dispatch) => {
  try {
    dispatch({ type: METRIC_DETAILS_REQUEST });

    const params = new URLSearchParams(queryParam);
    const { data } = await axios.get(
      `${url}metrics_hub/metrics/${metricId}/related-metrics`,
      { params }
    );

    dispatch({
      type: METRIC_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: METRIC_DETAILS_FAIL,
      payload: error.response,
    });
  }
};

export const deleteMetric = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: METRIC_DELETE_REQUEST,
    });

    await axios.delete(`${url}metrics_hub/metrics/${id}`);

    dispatch({
      type: METRIC_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: METRIC_DELETE_FAIL,
      payload: error.response,
    });
  }
};

export const createMetric = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: METRIC_CREATE_REQUEST,
    });

    const { data } = await axios.post(`${url}metrics_hub/metrics`, {});

    dispatch({
      type: METRIC_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: METRIC_CREATE_FAIL,
      payload: error.response,
    });
  }
};

export const updateMetric = (metricId, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: METRIC_UPDATE_REQUEST });

    const { data } = await axios.put(
      `${url}metrics_hub/metrics/${metricId}`,
      updatedData
    );

    dispatch({ type: METRIC_UPDATE_SUCCESS });
    dispatch({ type: METRIC_DETAILS_SUCCESS, payload: data });

    return data;
  } catch (error) {
    dispatch({
      type: METRIC_UPDATE_FAIL,
      payload: error.response,
    });

    return error.response;
  }
};
