import { useCallback, useEffect, useRef, useState } from "react";

/**
 * This custom hook allow a callback to execute as soon as the state updates happen.
 * This ensures that the callback will be having the new state
 * @param {*} initialValue initial value of state
 * @returns `[state, setStateWithCallback]` similar to `useState`, but the `setStateWithCallback` function
 * can accept a callback
 *
 * `Usage:`
 *
 * `const [state, setState] = useStateWithCallback(initialStateValue);`
 *
 * `setState(
 *    // new state value or a function call
 *    ,
 *    (latestStateValue) => {
 *      // do something with the updated state as soon as it gets updated
 *    }
 *  )
 * );`
 *
 */
export function useStateWithCallback(initialValue) {
  const [state, setState] = useState(initialValue);
  const cbRef = useRef(null);

  const setStateWithCallback = useCallback((newState, callback) => {
    cbRef.current = callback;
    setState(newState);
  }, []);

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state);
    }
    cbRef.current = null;
  }, [state]);

  return [state, setStateWithCallback];
}
