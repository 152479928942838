import { merge } from "../../utils/objects";
import { colors, typography } from "../theme-constants";
import { config } from "./common-config";

/**
 * @type {import("plotly.js").Layout}
 */
export const hotspotLayout = {
  showlegend: false,
  coloraxis: {
    showscale: false,
    colorbar: {
      title: "",
      tickformat: "%",
      outlinewidth: 0,
      nticks: 5,
    },
  },
  margin: {
    pad: 5,
    l: 80,
    r: 10,
    t: 20,
    b: 50,
  },
  barmode: "relative",
  xaxis: {
    tickformat: ".0%",
    title: { text: "" },
    ticks: "outside",
    tickfont: {
      color: colors.gray[500],
    },
    showticklabels: true,
    linewidth: 1,
    linecolor: colors.gray[200],
    mirror: false,
    zeroline: true,
    autorange: false,
    zerolinecolor: colors.gray[500],
    ticklen: 4,
    tickcolor: colors.gray[200],
    showline: true,
    showgrid: true,
    gridcolor: colors.gray[100],
  },
  yaxis: {
    type: "category",
    categoryorder: "total ascending",
    ticklen: 0,
    linewidth: 1,
    linecolor: colors.gray[500],
    showline: false,
    showgrid: false,
    title: { text: "" },
    mirror: true,
    showticklabels: false,
  },
};
/**
 * @type {import("plotly.js").Data}
 */
export const hotspotPlot = {
  type: "bar",
  mode: "markers",
  orientation: "h",
  textposition: "outside",
  textfont: {
    size: typography.font_size["sm"],
    color: colors.gray[500],
  },
  outsidetextfont: {
    color: colors.gray[500],
  },
  insidetextfont: {
    color: colors.white,
  },
  marker: {
    color: [0, 1, 2, 3, 4],
  },
};
/**
 * @type {import("plotly.js").Config}
 */
export const hotspotConfig = merge(config, {
  useResizeHandler: true,
  responsive: true,
  scrollZoom: false,
  displayModeBar: false,
});
