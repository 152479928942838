import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import MuiDialog from "@mui/material/Dialog";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import { colors, typography } from "../../../../shared/theme-constants";

export const MetricDetailTab = styled(Tab)({
  textTransform: "none",
  fontSize: typography.font_size.xl,
  fontWeight: typography.font_weight.regular,
  minWidth: "180px",
  "&.Mui-selected": {
    color: colors.popper_footer_bgcolor,
    fontWeight: typography.font_weight.bold,
  },
});

export const MetricInfoTypography = styled("span")`
  font-weight: ${typography.font_weight.bolder};
  text-align: center;
  color: ${colors.title};
  font-size: ${typography.font_size.xl}px;
  padding-left: 15px;
  line-height: 1.25;
`;

export const MetricDefinitionTypography = styled(MetricInfoTypography)`
  font-weight: ${typography.font_weight.bold};
  padding-left: 0;
`;

export const MetricDefinitionValue = styled(MetricDefinitionTypography)`
  font-weight: ${typography.font_weight.regular};
`;

export const MetricDetailsTypography = styled("span")`
  font-weight: ${typography.font_weight.medium};
  text-align: center;
  color: ${colors.title};
  font-size: ${typography.font_size["2xl"]}px;
  line-height: 1.25;
`;

export const MetricValue = styled("span")`
  padding: 0 30px 0 5px;
`;

export const Title = styled("div")`
  font-weight: ${typography.font_weight.medium};
  font-size: 2.3rem;
  color: ${colors.title};
`;

export const MetricAttributeHeader = styled(MetricInfoTypography)`
  font-weight: ${typography.font_weight.bold};
  padding: 5px 10px;
`;

export const MetricAttributeValue = styled("div")`
  padding: 10px;
  white-space: pre-wrap;
  display: inline-flex;
  align-items: center;
  font-weight: ${typography.font_weight.regular};
  font-size: 1.2rem;
  line-height: 1.5;
  color: ${colors.title};
  border: 1px solid ${colors.title};
  border-radius: 10px;
  min-width: 300px;
  width: 100%;
  min-height: 50px;
  max-height: 80px;
  overflow: auto;
  word-break: break-word;
`;

export const MetricDefinition = styled("span")`
  padding: 10px 0 40px 0;
  white-space: pre-wrap;
  display: inline-flex;
  font-weight: ${typography.font_weight.regular};
  font-size: 1.2rem;
  line-height: 1.5;
  color: ${colors.title};
`;

export const MessageContainer = styled(Box)({
  padding: "4px 16px",
  backgroundColor: "#FFE4E6",
  borderRadius: 4,
  border: "1px solid #E11D48",
});

export const GroupTitle = styled(Typography)({
  fontSize: typography.font_size["2xl"],
  fontWeight: typography.font_weight.bold,
});

export const Label = styled(InputLabel)({
  fontSize: typography.font_size.lg,
  fontWeight: typography.font_weight.bold,
  lineHeight: 1.5,
  color: colors.comet,
});

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 26, 138, 0.7)",
  },
  "& .MuiDialogTitle-root": {
    padding: `${theme.spacing(6)} ${theme.spacing(8)}`,

    "& h2": {
      margin: 0,
      color: colors.comet,
      fontWeight: typography.font_weight.medium,
      fontSize: theme.typography.pxToRem(32),
      lineHeight: 1.2,
    },
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(10),
  },
  "& .MuiDialogTitle-root+.MuiDialogContent-root": {
    paddingTop: theme.spacing(10),
  },
}));
