import React from "react";
import { RESOURCE_TYPE_GROUP } from "../../constants/commonConstants";
import ChartCarousel from "./hoc/ChartCarousel";
import { ImpactTable } from "./ImpactTable";
import TimeseriesChart from "./TimeseriesChart";

const TimeSeriesTrendChart = ({ accent, resourceId, resourceType, patternType }) => {
  return (
    <ChartCarousel resourceType={resourceType}>
      <div>
        <TimeseriesChart patternType={patternType} />
      </div>
      {/* Commented below the impact table as part of DEV-4390 */}
      {/* {resourceType === RESOURCE_TYPE_GROUP && (
        <div>
          <ImpactTable accent={accent} groupId={resourceId} />
        </div>
      )} */}
    </ChartCarousel>
  );
};

export default TimeSeriesTrendChart;
