import styled from "styled-components";
import { theme } from "./theme";

export const DownloadModalContainer = styled.div`
  overflow: auto;
  margin-top: 15px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    overflow: hidden;
  }
`;

export const DownloadModalOptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const DownloadModalOptionText = styled.div`
  padding: 10px;
  width: 90%;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    padding: 5px;
    width: 70%;
  }
`;

export const DownloadModalOptionButton = styled.div`
  padding: 10px;
  width: 10%;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    padding: 5px;
    width: 10%;
  }
`;

export const downloadModalStyle = {
  width: "500px",
  // height: "230px", put this back when to support multiple download
  top: "15%",
  background: "#FFFFFF",
  borderRadius: "16px",

  [`@media(max-width:${theme.tablet})`]: {
    width: "350px",
  },
};

export const buttonProps = {
  background: "#1E46EF",
  width: "100px",
  height: "40px",
  borderRadius: "8px",
  "&:hover": {
    background: "rgb(130, 165, 239)",
  },
  "&:disabled": {
    background: "#9f9f9f",
  },
  [`@media(max-width:${theme.tablet})`]: {
    width: "36px",
    height: "36px",
  },
};

export const listProps = {
  width: "100%",
  maxWidth: 400,
  padding: 0,
  margin: "15px 10px",
  bgcolor: "background.paper",
};
