import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';

export default function TransitionAlerts({ alert_message, alert_type }) {
  const [open, setOpen] = React.useState(true);

  return (
    <Fade in={open}>
      <Alert
        variant="outlined"
        severity={alert_type}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size= "small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{
          width: "100%",
          height: "41px",
          fontSize: "12px",
          fontWeight: "medium",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#FAE6E6",
          color: "#D95A5A",
          paddingLeft: "30px",
        }}
      >
        {alert_message}
      </Alert>
    </Fade>
  );
}