import React from "react";
import Stack from "@mui/material/Stack";
import { colors } from "../../../shared/theme-constants";

export const Message = ({ children, ...props }) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      color={colors.comet}
      pt="20px"
      pb="40px"
      fontSize="1.125rem"
      {...props}
    >
      {children}
    </Stack>
  );
};
