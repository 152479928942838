export const UPDATE_PREFERENCE = "ACCOUNT_SETTINGS/UPDATE_PREFERENCE";
export const UPDATE_PREFERENCE_SUCCESS = "ACCOUNT_SETTINGS/UPDATE_PREFERENCE_SUCCESS";
export const UPDATE_PREFERENCE_FAIL = "ACCOUNT_SETTINGS/UPDATE_PREFERENCE_FAIL";

export const UPDATE_PROFILE_DETAILS = "ACCOUNT_SETTINGS/PREFERENCES/UPDATE_PROFILE_DETAILS";
export const UPDATE_PROFILE_DETAILS_SUCCESS = "ACCOUNT_SETTINGS/PREFERENCES/UPDATE_PROFILE_DETAILS_SUCCESS";
export const UPDATE_PROFILE_DETAILS_FAIL = "ACCOUNT_SETTINGS/PREFERENCES/UPDATE_PROFILE_DETAILS_FAIL";

export const GET_PROFILE_DETAIL_REQUEST = "ACCOUNT_SETTINGS/PREFERENCES/GET_PROFILE_DETAILS_REQUEST";
export const GET_PROFILE_DETAILS_SUCCESS = "ACCOUNT_SETTINGS/PREFERENCES/GET_PROFILE_DETAILS_SUCCESS";
export const GET_PROFILE_DETAILS_FAIL = "ACCOUNT_SETTINGS/PREFERENCES/GET_PROFILE_DETAILS_FAIL";

export const GENERATE_API_REQUEST = "ACCOUNT_SETTINGS/PREFERENCES/GENERATE_API_REQUEST";
export const GENERATE_API_REQUEST_SUCCESS = "ACCOUNT_SETTINGS/PREFERENCES/GENERATE_API_REQUEST_SUCCESS";
export const GENERATE_API_REQUEST_FAIL = "ACCOUNT_SETTINGS/PREFERENCES/GENERATE_API_REQUEST_FAIL";
export const GENERATE_API_REQUEST_RESET = "ACCOUNT_SETTINGS/PREFERENCES/GENERATE_API_REQUEST_RESET";
