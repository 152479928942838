import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Histogram } from "../../common/Histogram";
import { getInsightSummaryHistogram } from "../../../actions/insightSummaryActions";
import {
  collaborateSummaryHistogramLayout,
  collaborateSummaryHistogramPlot,
  summaryHistogramConfig,
} from "../../../shared/plotly-config";
import { merge } from "../../../utils/objects";
import { generateDimensionListQueryParam, getQueryParamsFromFilterObject } from "../../../utils/processFilterParams";
import { HistogramLoader } from "../../common/HistogramLoader";
import {
  defaultTimestamps,
  getBarGap,
  getStartAndEndTimestamps,
  getXAxisOverrideConfig,
  getXAxisTicksCount,
  translateToHistogramChartAxes,
} from "../../../utils/histogramHelpers";
import { colors } from "../../../shared/theme-constants";
import { SET_COLLABORATE_TIMESTAMP } from "../../../constants/filterCriteriaConstants";

const Label = styled("span")`
  color: ${colors.comet};
  font-size: 37px;
  line-height: 1.1;
`;

const useHistogram = (tenantId, pipelineSchedule, dimensionNames, dimensionValues) => {
  const dispatch = useDispatch();
  const filterSelector = useSelector((state) => state.collaborateFilters);
  const globalFilter = useSelector((state)=> state.tenantGlobalFilter?.appliedFilter);
  const [granularity, setGranularity] = useState();
  const [isLoading, setLoading] = useState(true);
  const [insightHistogramData, setInsightHistogramData] = useState();
  const [insightHistogramLayout, setInsightHistogramLayout] = useState();

  useEffect(() => {
    let isMounted = true;
    let dimensionsArray = generateDimensionListQueryParam(dimensionNames, dimensionValues);
      let encodedQueryParams = getQueryParamsFromFilterObject(
        filterSelector,
        tenantId,
        pipelineSchedule,
        null,
        globalFilter?.dimension_name,
        globalFilter?.dimension_value,
        dimensionsArray
      );

      setLoading(true);

      dispatch(getInsightSummaryHistogram(encodedQueryParams)).then(
        (response) => {
          if (response && isMounted) {
            const { startTime, endTime } =
              filterSelector.timeStamps || defaultTimestamps;

            setGranularity(response.histogram_granularity);
            setInsightHistogramData(
              merge(
                collaborateSummaryHistogramPlot,
                translateToHistogramChartAxes(
                  response.histogram_data,
                  response.histogram_granularity,
                  startTime,
                  endTime
                )
              )
            );
            setInsightHistogramLayout(
              merge(collaborateSummaryHistogramLayout, {
                bargap: getBarGap(
                  getXAxisTicksCount(
                    startTime,
                    endTime,
                    response.histogram_granularity,
                    response.histogram_data.length
                  )
                ),
                xaxis: getXAxisOverrideConfig(startTime, endTime, response),
              })
            );

            setLoading(false);
          }
        }
      );
    return () => (isMounted = false);
  }, [dispatch, filterSelector, tenantId, pipelineSchedule, globalFilter]);

  return {
    isLoading,
    data: insightHistogramData,
    layout: insightHistogramLayout,
    granularity,
  };
};

const InsightHistogram = ({ tenantId, pipelineSchedule, onBarClick, sx, dimensionNames = [], dimensionValues= [] }) => {
  const dispatch = useDispatch();
  const { isLoading, data, layout, granularity } = useHistogram(
    tenantId,
    pipelineSchedule,
    dimensionNames,
    dimensionValues
  );

  function handleHistogramBarClick(barDate) {
    const { start, end } = getStartAndEndTimestamps(
      new Date(barDate),
      granularity
    );

    dispatch({
      type: SET_COLLABORATE_TIMESTAMP,
      payload: {
        start_time: start,
        end_time: end,
      },
    });
  }

  return (
    <Stack
      gap={10}
      direction="row"
      minHeight={70}
      height="100%"
      width="100%"
      margin="10px 0 60px"
      sx={sx}
    >
      {isLoading ? (
        <HistogramLoader isTransparent={false} />
      ) : (
        <Box flex={1}>
          <Histogram
            onBarClick={onBarClick || handleHistogramBarClick}
            data={[data]}
            layout={layout}
            config={summaryHistogramConfig}
          />
        </Box>
      )}
    </Stack>
  );
};

export default InsightHistogram;
