import { Tooltip, styled, tooltipClasses } from "@mui/material";

export const LightTooltip = styled(({ className, height, width, fontSize, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow/>
  ))(({ theme, height = "none", width = "none", fontSize = 8 }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#E5ECFD',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: fontSize,
      maxWidth : width,
      maxHeight : height,
      textAlign : 'left',
      display : 'flex',
      flexDirection : 'column',
    },
    [`& .MuiTooltip-arrow`]: {
        color: '#E5ECFD',
    }
  }));