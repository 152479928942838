import styled from "styled-components";

export const Percentage = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 26px;
  display: flex;
  align-items: center;
  padding-left: 5%;
  color: ${({ color }) => color};
`;
