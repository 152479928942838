import styled from "@emotion/styled";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  Popper,
  Stack,
  TextField,
  autocompleteClasses,
  outlinedInputClasses,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTenantInfo } from "../../../actions/tenantInfoActions";
import { SAAS_CREATE_GLOBAL_FILTER_FEATURE_TOGGLE } from "../../../constants/featureFlagConstants";
import { TENANT_UPDATE_GLOBAL_FILTER } from "../../../constants/tenantConstants";
import { useLocalStorageState } from "../../../hooks";
import useBoolean from "../../../hooks/useBoolean";
import { useSelectedTenant } from "../../../hooks/useSelectedTenant";
import { HTTP_STATUS } from "../../../shared/enums/http-status";
import { typography } from "../../../shared/theme-constants";
import { isEnabled } from "../../../utils/featureFlags";
import { isEmpty } from "../../../utils/is";
import ListLoader from "../ListLoader";
import DeleteGlobalFilterDialog from "../globalFilters/DeleteFilterDialog";

const StyledAutocomplete = styled(Autocomplete)(({ theme, isopen, isdefault }) => ({
  width: "100%",
  minWidth: 100,
  [`& .${autocompleteClasses.popupIndicator}`]: {
    color:
      isopen === "true" ? "var(--black)" : isdefault === "true" ? "var(--white)" : "var(--black)",
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderRadius: isopen === "true" ? "24px 24px 0 0" : 24,
    borderColor: "var(--white)",
  },
  "& .MuiInputBase-root": {
    width: "100%",
    color: "var(--purple-fog)",
    borderRadius: isopen === "true" ? "24px 24px 0 0" : 24,
    background:
      isopen === "true"
        ? "var(--white)"
        : isdefault === "true"
        ? "var(--resolution-blue)"
        : "var(--white)",
  },
  "& .MuiInputBase-input": {
    color:
      isopen === "true"
        ? "var(--resolution-blue)"
        : isdefault === "true"
        ? "var(--white)"
        : "var(--resolution-blue)",
    "&::placeholder": {
      opacity: 1,
    },
  },
}));

const StyledPopper = styled(Popper)({
  paddingTop: 0,
  "& .MuiPaper-root": {
    borderTop: "1px solid black",
  },
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
  },
  [`& .${autocompleteClasses.listbox} .${autocompleteClasses.option}`]: {
    height: 58,
    borderBottom: "1px solid #CCCCCC",
    fontSize: "0.875rem",
  },

  [`& .${autocompleteClasses.listbox} .${autocompleteClasses.option}.${autocompleteClasses.focused}`]:
    {
      background: "#D8DEEE",
      color: "var(--resolution-blue)",
      fontWeight: typography.font_weight.bold,
    },
});

export const StyledInput = styled(TextField)(({ theme }) => ({
  [`& .${outlinedInputClasses.root}`]: {
    fontSize: "0.875rem",
    fontWeight: typography.font_weight.semiBold,
    [`&:not(.${outlinedInputClasses.focused}):hover .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "var(--white)",
    },
    // on focused
    [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "var(--white)",
    },
  },
}));

const NavBarViewDropDown = ({ openCreateFilterDrawer, ...delegatedProps }) => {
  const [isDropDownOpen, { setTrue: openDropDown, setFalse: closeDropDown }] = useBoolean(false);
  const [
    isDeleteGlobalFilterDialogOpen,
    { setTrue: openDeleteGlobalFilterDialog, setFalse: closeDeleteGlobalFilterDialog },
  ] = useBoolean(false);
  const [globalFilterToDelete, setGlobalFilterToDelete] = useState(Object.assign({}, {}));
  const [, updateAppliedGlobalFilters] = useLocalStorageState("currentAppliedGlobalFilters");
  const [dropDownValue, setDropDownValue] = useState();
  const selectedTenant = useSelectedTenant();
  const anchorRef = useRef(null);
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const tenantGlobalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isEmpty(tenantGlobalFilter)) {
      setDropDownValue(tenantGlobalFilter);
    } else {
      setDropDownValue(null);
    }
  }, [tenantGlobalFilter]);

  // open the side bar which lets user create a filter
  const handleCreateGlobalFilter = (event) => {
    // Need to avoid default emit action when button is clicked
    event.stopPropagation();
    openCreateFilterDrawer();
  };

  // When User clicks on Open Autocomplete arrow button, this funtion is triggered
  // it fetches the options from the background and then sets the list in the drop down
  const fetchFeedFilters = async () => {
    const params = new URLSearchParams({ tenant_id: selectedTenant });
    openDropDown();
    setLoading(true);
    try {
      const filtersResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}feed/filters`, {
        params,
      });
      setDropDownOptions(filtersResponse?.data?.data);
      setLoading(false);
    } catch (error) {
      if (error.response?.status === HTTP_STATUS.UNAUTHORIZED) {
        return navigate("/login");
      }
    }
  };

  // We need to update the local store  of browser with this filter
  function handleOnChange(event, newValue, reason) {
    if (event.type === "keydown" && event.key === "Backspace" && reason === "removeOption") {
      return;
    }
    // On drop Down Change
    // We need to fire Tenant info APi which will let us know
    // the start end time stamps and if forecast is enabled or not for that dim_name/dim_val combination
    let queryParams = {};
    queryParams.dimension_name = newValue.dimension_name;
    queryParams.dimension_value = newValue.dimension_value;
    dispatch(getTenantInfo(selectedTenant, queryParams)).then(() => {
      // Update the local storage with the applied filters
      dispatch({ type: TENANT_UPDATE_GLOBAL_FILTER, payload: newValue });
    }).catch((error)=>{
      // Check if the error is 401
      if (error.response.status === HTTP_STATUS.UNAUTHORIZED) {
        setLoading(false);
        // Redirect to login page
        navigate("/login");
      }
    });

    updateAppliedGlobalFilters(newValue);
  }

  const handleFilterDelete = (event, option) => {
    // Need to avoid default emit action when button is clicked
    event.stopPropagation();
    openDeleteGlobalFilterDialog();
    setGlobalFilterToDelete(option);
    closeDropDown();
  };

  return (
    <>
      <StyledAutocomplete
        size="medium"
        ref={anchorRef}
        options={dropDownOptions}
        value={dropDownValue || {}}
        loading={loading}
        loadingText={<ListLoader count={4} height={20} paddingY={10} width="100%" />}
        disableClearable
        disablePortal
        PopperComponent={({ children, anchorEl, props }) => (
          <StyledPopper
            open={isDropDownOpen}
            anchorEl={anchorEl}
            {...props}
            sx={{ zIndex: 20000 }}
            style={{
              width: anchorRef.current ? anchorRef.current.clientWidth : null,
            }}
          >
            <Paper sx={{ zIndex: 20000, borderRadius: "0px 0px 24px 24px" }}>
              {children}
              <Stack padding={4} minHeight={70}>
                <Button
                  color="primary"
                  variant="outlined"
                  onMouseDown={handleCreateGlobalFilter}
                  fullWidth
                  disabled={!isEnabled(SAAS_CREATE_GLOBAL_FILTER_FEATURE_TOGGLE)}
                  sx={{ justifyContent: "center", borderRadius: 24 }}
                >
                  Create New Global Filter
                </Button>
              </Stack>
            </Paper>
          </StyledPopper>
        )}
        onChange={handleOnChange}
        getOptionLabel={(option) => option?.display_name || ""}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <StyledInput
            {...params}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            placeholder="Select Global Filter"
          />
        )}
        onOpen={fetchFeedFilters}
        onClose={closeDropDown}
        renderOption={(props, option) => {
          return (
            <Grid container {...props} justifyContent="space-between" spacing={2} p={4} mt={0}>
              <Grid item sm={10}>
                {option?.display_name}
              </Grid>
              <Grid item sm={2}>
                <Button
                  onClick={(event) => handleFilterDelete(event, option)}
                  disabled={option?.id === dropDownValue?.id || option?.is_default}
                  sx={{
                    ":hover": {
                      background: "none",
                    },
                    ":disabled": {
                      opacity: 0.3,
                    },
                  }}
                >
                  <DeleteOutlineOutlinedIcon
                    sx={{
                      color: "var(--comet)",

                      ":hover": {
                        cursor: "pointer",
                        color: "var(--red-3)",
                        transform: "scale3d(1.2, 1.2, 1.2)",
                      },
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
          );
        }}
        isopen={isDropDownOpen?.toString()}
        isdefault={dropDownValue?.is_default?.toString()}
        {...delegatedProps}
      />
      <DeleteGlobalFilterDialog
        isOpen={isDeleteGlobalFilterDialogOpen}
        closeDeleteGlobalFilterDialog={closeDeleteGlobalFilterDialog}
        filterToDelete={globalFilterToDelete}
      />
    </>
  );
};

export default NavBarViewDropDown;
