import styled from "@emotion/styled";
import { Accordion, AccordionSummary, Box, Divider, Drawer, Stack, Typography, Link} from "@mui/material";
import Switch from "@mui/material/Switch";
import React, { useEffect, useState } from "react";
import { typography } from "../../shared/theme-constants";
import { StyledActionButton } from "../common/mui-wrapper-components/Button";
import { Loading } from "../common/styled-components";
import { updateStorePreferenceDetails } from "../../actions/preferenceActions";
import { useDispatch } from "react-redux";

const StyledAccordion = styled(Accordion)({
  boxShadow: "none",
  border: 0,
  margin: "10px 0",
  "&:before": {
    content: "none",
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  minHeight: 40,
  height: 48,
});

const StoreProviderDrawer = ({
  isStoreProviderDrawerOpen = false,
  onStoreProviderDrawerClose,
  storePreferenceData,
  onClose,
  selectedStoreDetails,
}) => {
  const [eventProvidersData, setEventProvidersData] = useState([]);
  const [updatingProviderDetails, setUpdatingProviderDetails] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (storePreferenceData) {
      // On the basis of which type of drawer is open we set the events provider data in state.
      const providers = storePreferenceData?.preferences?.events?.providers;
      setEventProvidersData(providers);
    }
  }, [storePreferenceData]);

  // Function to check and uncheck the specific provider
  const isProviderChecked = (event, providerDetails) => {
    const updatedProvidersData = eventProvidersData.map(provider => {
      if (provider.id === providerDetails.id) {
        const obj = {
          ...provider,
          is_active: event.target.checked
        };
        return obj;
      }
      return provider;
    });
    setEventProvidersData(updatedProvidersData);
  };

  // Function to discard changes. If user makes some changes and click on discard then we revert all the changes.
  const handleCancelChanges = () => {
    const providers = storePreferenceData?.preferences?.events?.providers;
    setEventProvidersData(providers);
    onClose();
  };

  // When user clicks on save button then we are sending the updated details to backend with all the preferences data.
  const handleSaveChanges = () => {
    setUpdatingProviderDetails(true);
    const payload = { ...storePreferenceData };
    payload.preferences.events.providers = eventProvidersData;
    dispatch(updateStorePreferenceDetails(storePreferenceData.org_uuid, payload, selectedStoreDetails?.display_name)).then((response) => {
        setUpdatingProviderDetails(false);
        onClose();
    });
  };

  return (
    <Drawer
      anchor="right"
      open={isStoreProviderDrawerOpen}
      onClose={onStoreProviderDrawerClose}
      sx={{
        zIndex: 100000,
      }}
      PaperProps={{
        sx: { justifyContent: "center" },
      }}
    >
      {eventProvidersData?.length === 0 ? (
        <Loading />
      ) : (
        <>
          <Stack sx={{ width: 550, padding: 4, height: "90vh" }} gap={4}>
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(24),
                fontWeight: typography.font_weight.mediumx,
              })}
            >
              Event Providers
            </Typography>
            <Stack gap={4} sx={{ height: "90%" }}>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(14),
                })}
              >
                {`Select services that are currently used in your store. This selection will be used to send alerts when these services report any errors, outages or service disruptions`}
              </Typography>
              <Stack direction="column" gap={0} sx={{ overflowY: "scroll" }}>
                {eventProvidersData?.map((provider) => (
                  <React.Fragment key={provider.id}>
                    <StyledAccordion expanded={true}>
                      <StyledAccordionSummary expandIcon={<></>}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                          sx={{ width: "100%" }}
                        >
                          <Stack direction="row" alignItems="center" gap={3}>
                            <img src={`${provider.logo_url}`} width={20} height={20} />
                            <Typography
                              sx={(theme) => ({
                                fontSize: theme.typography.pxToRem(16),
                              })}
                            >
                              {provider.display_name}
                            </Typography>
                          </Stack>
                          <Switch
                            checked={provider.is_active}
                            onChange={(event) => isProviderChecked(event, provider)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </Stack>
                      </StyledAccordionSummary>
                    </StyledAccordion>
                    <Divider />
                  </React.Fragment>
                ))}
              </Stack>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            gap={3}
            sx={{
              height: "10vh",
              alignItems: "center",
              padding: 4,
            }}
          >
            <StyledActionButton
              onClick={handleSaveChanges}
              disabled={updatingProviderDetails}
              loading={updatingProviderDetails}
              sx={{
                width: 100,
              }}
            >
              Save
            </StyledActionButton>
            <StyledActionButton
              style={{
                background: "var(--white)",
                color: "var(--comet)",
                borderColor: "var(--comet)",
                width: 100,
              }}
              disabled={updatingProviderDetails}
              onClick={handleCancelChanges}
            >
              Cancel
            </StyledActionButton>
            <Box sx={{ marginLeft: "auto" }}>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  textAlign: "right",
                  color: "var(--purple-fog)",
                  fontWeight: typography.font_weight.mediumx,
                })}>
                Missing a provider?
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.mediumx,
                    color: "var(--comet)",
                    cursor: "pointer",
                  })}>
                    <Link
                      href="mailto:connect@outoftheblue.ai?subject=New%20Provider%20Support%20Request&body=Hi%2C%0A%0ARequesting%20you%20to%20please%20enable%20support%20for%20capturing%20errors%20and%20outages%20for%20below%20provider%3A%0A%0A-%20Provider%20Name%3A%0A-%20Provider%20Home%20Page%20URL%3A%0A-%20Provider%20Status%20Page%20URL%3A%0A%0AThanks!"
                      underline="none"
                      sx={{ textDecoration: "none", color: "var(--black)" }}
                    >
                      Contact us
                    </Link>
                  </Typography>
              </Typography>
            </Box>
          </Stack>
        </>
      )}
    </Drawer>
  );
};

export default StoreProviderDrawer;
