import React from "react";
import Checkbox from "@mui/material/Checkbox";
import {
  GroupHeader,
  GroupItems,
  StyledAutocomplete,
  StyledInput,
  StyledPopper,
} from "../../styled-components/collaborate/MultiSelect.styled";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Tooltip } from "@mui/material";
const MultiSelect = ({
  value,
  placeholder,
  onChange,
  options,
  getOptionLabel,
  getOptionDisabled,
  isOptionEqualToValue,
  optionsCheckbox = true,
  clearIcon,
  enableEllipsis = false,
  ...delegatedProps
}) => {
  function handleOnChange(event, newValue, reason) {
    if (
      event.type === "keydown" &&
      event.key === "Backspace" &&
      reason === "removeOption"
    ) {
      return;
    }

    onChange(event, newValue, reason);
  }

  return (
    <StyledAutocomplete
      multiple
      disableCloseOnSelect
      disablePortal
      size="small"
      clearIcon={clearIcon}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      isOptionEqualToValue={isOptionEqualToValue}
      value={value}
      onChange={handleOnChange}
      PopperComponent={StyledPopper}
      popupIcon = {<ExpandMoreIcon/>}
      renderTags={() => null}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      renderInput={(params) => (
        <StyledInput
          {...params}
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          placeholder={placeholder}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          { optionsCheckbox && <Checkbox sx={{ p: 0, mr: "6px" }} checked={selected} />}
          {enableEllipsis ? (
            <Tooltip title={getOptionLabel(option)} placement="top">
              <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {getOptionLabel(option)}
              </span>
            </Tooltip>
          ) : (
            getOptionLabel(option)
          )}
        </li>
      )}
      {...delegatedProps}

    />
  );
};

export default MultiSelect;
