import { colors } from "../theme-constants";

// Constants for chart types
export const TIMESERIES_METRIC_CHART = 'timeseries_metric_chart';
export const TIMESERIES_INSIGHT_CHART = 'timeseries_insight_chart';

// Thes will represent the base chart options which are common across all the time series charts
export const timeSeriesChartBaseConfig = {
    credits: {
        enabled: false, // This disables the Highcharts watermark
    },
    accessibility: {
        enabled: false,
    },
    series: []
};

// Metric Time seriese cahrt actual data config
export const metricActualTimeSeriesLayout = {
    name: "Actual",
    linkedTo: "actual",
    color: colors.gray[550],
    type: "spline",
    zIndex: 5,
    marker: {
        enabled:false,
      states:{
        hover:{
          enabled: false
        }
      },
    },
  };