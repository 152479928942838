import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import MuiDialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { colors } from "../../shared/theme-constants";
import { updateMetric } from "../../actions/metricActions";
import { isEmpty, isFunction,  } from "../../utils/is";
import { get, pick } from "../../utils/objects";
import { SERVER_ERROR_MESSAGE } from "../../constants/commonConstants";
import {
  Dialog,
  GroupTitle,
  Label,
  MessageContainer,
} from "../common/styled-components/metric/MetricDetails.styled";
import { attributesBySections } from "../../shared/metric-attributes/constants";
import Icon from "../common/Icon";

const fieldsToBeProcessed = attributesBySections()
  .map((section) => section.items)
  .flat()
  .filter((item) => item.transformValue);

const modalHeaderFooterStyles = {
  m: 0,
  p: 4,
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.04)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

function getInputAdornment(input) {
  const props = {};

  if (input.prefix) {
    props.startAdornment = (
      <InputAdornment position="start">{input.prefix}</InputAdornment>
    );
  }

  if (input.suffix) {
    props.endAdornment = (
      <InputAdornment position="end">{input.suffix}</InputAdornment>
    );
  }

  return props;
}

/**
 * Mutates and returns received object with specified types
 * Mutation should not cause any side effects since it mutates local object that's created for that purpose

 * @param fields {object} - Object with form fields that are touched
 * @param options {object} - Object with arrays (keyed by input name) of {id, name} objects
 * @returns {object} Mutated fields argument
 *  */
function castFormValuesToExpectedTypes(fields, options) {
  if (Object.keys(fields).length === 0) {
    return fields;
  }
  fieldsToBeProcessed.forEach((typedField) => {
    const value = fields[typedField.name];

    if (value != null) {
      fields[typedField.name] = typedField.transformValue(
        value,
        !isEmpty(options[typedField.name])
          ? options[typedField.name].find((option) => option.id === value)
          : {}
      );
    }
  });

  return fields;
}

const DialogTitle = ({ children, onClose, ...restProps }) => {
  return (
    <MuiDialogTitle sx={modalHeaderFooterStyles} component="div" {...restProps}>
      <h2>{children}</h2>

      <Icon
        aria-label="close"
        onClick={onClose}
        name="close"
        color="#9CA3AF"
        size="20px"
        showcursor="true"
      />
    </MuiDialogTitle>
  );
};

function populateOptions(attributesBySections, options) {
  return attributesBySections.map((section) => {
    for (let attribute of section.items) {
      if (options.hasOwnProperty(attribute.name)) {
        const transformedOptions = options[attribute.name].map((option) => ({
          value: option.id,
          label: option.name,
        }));

        attribute.options = transformedOptions;
      }
    }

    return section;
  });
}

const MetricAttributesFormModal = ({ onClose, metricId, data, options }) => {
  const dispatch = useDispatch();
  const tenantConfigs = useSelector((state) => state?.tenantConfigs?.configs);
  const { loading, error } = useSelector((state) => state.metricUpdate);
  const [touchedFields, setTouchedFields] = useState({});

  function handleBlur(event) {
    setTouchedFields((prevState) => ({
      ...prevState,
      [event.target.name]: true,
    }));
  }

  function handleSubmit(event) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const formObject = Object.fromEntries(formData.entries());

    // extract only form fields that were touched
    // to bypass server validation for fields that were initially empty
    const updatedFields = pick(formObject, Object.keys(touchedFields));
    const updatedFieldsWithExpectedTypes = castFormValuesToExpectedTypes(
      updatedFields,
      options
    );

    dispatch(updateMetric(metricId, updatedFieldsWithExpectedTypes)).then(
      (response) => {
        if (response?.success) {
          onClose();

          toast.success("Metric attributes are updated successfully.", {
            duration: 4000,
          });
        }
      }
    );
  }

  function getDefaultValue(input, data) {
    // this custom logic enables both read and edit views to utilize `formatValue`
    // without introducing new properties to config object
    // `accessor`'s purpose is to lead to input's ID, if it is nested in some object
    // `formatValue` is used both read-only view and input to format or normalize value
    if (!input.accessor && isFunction(input.formatValue)) {
      return input.formatValue(data[input.name], data, input);
    }

    return get(data, input.accessor || input.name) ?? "";
  }

  const attributesBySectionsWithOptions = populateOptions(
    attributesBySections(tenantConfigs?.currency_symbol),
    options
  );

  return (
    <Dialog aria-labelledby="metric-details" maxWidth="md" open>
      <DialogTitle id="metric-details" onClose={onClose}>
        Metric Details
      </DialogTitle>

      <DialogContent bgcolor="#F9FAFB">
        <Grid container spacing={2}>
          <Stack
            component="form"
            gap={10}
            onSubmit={handleSubmit}
            id="attributes-form"
          >
            {attributesBySectionsWithOptions.map((section) => (
              <Grid item sm={12} key={section.title}>
                <GroupTitle color="primary.main" component="h3">
                  {section.title}
                </GroupTitle>

                <Grid
                  mt={4}
                  container
                  rowSpacing={4}
                  columnSpacing={10}
                  maxWidth="md"
                >
                  {section.items.map((input) => (
                    <Grid
                      item
                      sm={12}
                      lt={6}
                      key={input.name}
                      component="fieldset"
                      disabled={loading}
                      sx={{ border: 0 }}
                    >
                      <Label shrink htmlFor={input.name}>
                        {input.title}
                      </Label>

                      <TextField
                        id={input.name}
                        // this might be fail point i.e. in select element, `None` is preselected
                        // instead of a actual value. Reason is that `select` element is loaded before
                        // `options` are populated in `select` element, and because this is uncontrolled
                        // form component, `defaultValue` is set once on component load and does not
                        // picks up dynamically populated values (but still reacts to user changes/events).
                        // In that case, either use `value` (and then this becomes controlled component),
                        // or defer component mounting until `options` array have all values
                        defaultValue={getDefaultValue(input, data)}
                        fullWidth
                        name={input.name}
                        select={!isEmpty(input.options)}
                        SelectProps={{ native: true }}
                        size="small"
                        variant="outlined"
                        InputProps={getInputAdornment(input)}
                        inputProps={input.inputProps}
                        disabled={input.disabled}
                        onBlur={handleBlur}
                      >
                        {!isEmpty(input.options) ? (
                          <>
                            <option value="" disabled>
                              Select {input.title}
                            </option>

                            <option value="">None</option>

                            {input.options.map((option) => (
                              <option
                                value={option.value || option}
                                key={option.label || option}
                              >
                                {option.label || option}
                              </option>
                            ))}
                          </>
                        ) : null}
                      </TextField>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Stack>
        </Grid>
      </DialogContent>

      <DialogActions sx={modalHeaderFooterStyles}>
        {error && (
          <MessageContainer>
            <Typography component="span" color="error">
              {SERVER_ERROR_MESSAGE}
            </Typography>
          </MessageContainer>
        )}

        <Stack ml="auto" flexShrink={0} direction="row" gap={4}>
          <Button color="primary" variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <Button
            autoFocus
            form="attributes-form"
            type="submit"
            variant="contained"
            disabled={loading}
          >
            Save{" "}
            {loading && (
              <CircularProgress
                sx={{ color: colors.white, ml: 2 }}
                size="1em"
              />
            )}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default MetricAttributesFormModal;
