import React, { useState } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { GTAG_EVENTS, sendDataToGTM } from "../utils/gtmHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { buildUrl } from "../utils/stringUtils";
import { Button, Container, Divider, Paper, TextField, Typography, useMediaQuery } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TransitionAlerts from "../components/common/ui-components/TransitionAlerts";
import { typography } from "../shared/theme-constants";
import { CheckBox } from "@mui/icons-material";

const getInTouchUrl = "https://outoftheblue.ai/contact-us/";
const errorMessages = {
  invalid_domain: "Please use your work email",
};

function getDocsRedirectPathname(location) {
  const searchParams = new URLSearchParams(location.search);

  if (searchParams.get("redirect") === "docs") {
    const docsReturnUrl = searchParams.get("location");
    const docsHref = buildUrl("docs", { location: docsReturnUrl });

    // remove origin from given href so we only have pathname and search params
    // as those are only values we need to sent to server as instruction for redirect
    return docsHref.replace(new URL(docsHref).origin, "");
  }

  return undefined;
}

const LoginButton = styled(Button)(({ theme }) => ({
  height: "47px",
  background: "#FFFFFF",
  "&:hover": {
    background: "#FFFFFF",
  },
  ":hover": {
    cursor: "pointer",
  },
  alignSelf: "center",
  marginTop: "5px",
  marginBottom: "5px",
  width: "100%",
  color: "#424242",
  border: "1px solid #E0E0E0",
  borderRadius: "10px",
  fontSize: theme.typography.pxToRem(12),
  ":disabled": {
    background: "rgba(0, 0, 0, 0.12)",
    color: "rgba(0, 0, 0, 0.26)",
    borderColor: "transparent",
    cursor: "default",
  },
}));

const EmailTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    "&:hover fieldset": {
      borderColor: "#E0E0E0",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3063E7",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "12px",
  },
  "& .MuiInputLabel-root": {
    color: "var(--grey-800)",
    fontSize: theme.typography.pxToRem(12),
  },
  "& .MuiInputLabel-outlined": {
    transform: "translate(14px, 14px) scale(1)",
  },
  "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0.75)",
  },
  "& .MuiFormHelperText-root": {
    fontSize: theme.typography.pxToRem(10),
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: 0,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E0E0E0",
  },
  "& .MuiOutlinedInput-multiline": {
    padding: "0",
  },
  "& .MuiOutlinedInput-multiline.MuiOutlinedInput-input": {
    padding: "0",
  },
}));

const LoginScreen = () => {
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // New state to track loading status
  const location = useLocation();
  const navigate = useNavigate();
  const returnUrl = location.state?.from?.pathname || getDocsRedirectPathname(location) || "/";

  // Get the query params from the URL
  const searchParams = new URLSearchParams(location.search);

  // Get the query param for the error message
  const errorMessage = searchParams.get("error");

  // Get the shopify_data from the query params
  let shopify_data = searchParams.get("shopify_data");

  // Send the event to Google Tag Manager, if there is an error message
  if (errorMessage) {
    sendDataToGTM(GTAG_EVENTS.USER_LOGIN_ERROR, {
      error: errorMessage,
    });
  }

  const handleGoogleLogin = () => {
    // Redirect to the Google OAuth login endpoint on your Express server.

    // Check if the shopify_data is present in the query params
    if (shopify_data) {
      // Replace spaces with '+' in the shopify_data
      shopify_data = shopify_data.replace(/ /g, "+");
      let googleLoginUrl = buildUrl("auth/google", { redirectTo: returnUrl });
      // Add the shopify_data to the query params
      googleLoginUrl = googleLoginUrl + `&shopify_data=${shopify_data}`;
      window.open(googleLoginUrl, "_self");
    } else {
      const googleLoginUrl = buildUrl("auth/google", { redirectTo: returnUrl });
      window.open(googleLoginUrl, "_self");
    }
  };

  const handleMicrosoftLogin = () => {
    // Redirect to the Microsoft OAuth login endpoint on your Express server.

    // Check if the shopify_data is present in the query params
    if (shopify_data) {
      // Replace spaces with '+' in the shopify_data
      shopify_data = shopify_data.replace(/ /g, "+");
      let microsoftLoginUrl = buildUrl("auth/microsoft", { redirectTo: returnUrl });
      // Add the shopify_data to the query params
      microsoftLoginUrl = microsoftLoginUrl + `&shopify_data=${shopify_data}`;
      window.open(microsoftLoginUrl, "_self");
    } else {
      const microsoftLoginUrl = buildUrl("auth/microsoft", { redirectTo: returnUrl });
      window.open(microsoftLoginUrl, "_self");
    }
  };

  const handleOktaLogin = () => {
    // Redirect to the Okta OAuth login endpoint on your Express server.

    // Check if the shopify_data is present in the query params
    if (shopify_data) {
      // Replace spaces with '+' in the shopify_data
      shopify_data = shopify_data.replace(/ /g, "+");
      let oktaLoginUrl = buildUrl("auth/okta", { redirectTo: returnUrl });
      // Add the shopify_data to the query params
      oktaLoginUrl = oktaLoginUrl + `&shopify_data=${shopify_data}`;
      window.open(oktaLoginUrl, "_self");
    } else {
      const oktaLoginUrl = buildUrl("auth/okta", { redirectTo: returnUrl });
      window.open(oktaLoginUrl, "_self");
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleMagicLogin = async () => {
    const isValidEmail = validateEmail(document.getElementById("email").value);
    if (!isValidEmail) {
      setInvalidEmail(true);
      return;
    } else {
      setIsLoading(true);
      let magicLoginUrl;
      let requestBody;
    // Redirect to the Magic OAuth login endpoint on your Express server.

    // Check if the shopify_data is present in the query params
    if (shopify_data) {
      // Replace spaces with '+' in the shopify_data
      shopify_data = shopify_data.replace(/ /g, "+");
      magicLoginUrl = buildUrl("auth/magic-login", { redirectTo: returnUrl });
      requestBody = {
        destination: document.getElementById("email").value,
        shopify_data: shopify_data,
      };
    } else {
      magicLoginUrl = buildUrl("auth/magic-login", { redirectTo: returnUrl });
      requestBody = {
        destination: document.getElementById("email").value,
      };
    }
    try {
      const response = await axios.post(magicLoginUrl, requestBody);
      const { success, message } = response.data;

      if (success) {
        setAlertMessage('Please check your email for the login link');
        setAlertType('success');
        document.getElementById("email").value = '';
      } else {
        setAlertMessage(message);
        setAlertType('error');
      }
    } catch (error) {
      setAlertMessage('An error occurred while sending the request');
      setAlertType("error");
    } finally {
      setIsLoading(false); // Re-enable the button after the request is done (success or error)
    }
  }
  };

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "auto",
          width: useMediaQuery("(min-width:600px)") ? "500px" : "100vw",
          maxWidth: "500px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "30px",
            marginBottom: "20px",
            width: "100%",
          }}
        >
          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "50px",
            width: "100%",
          }}>
            <img
              src="/images/logo.png"
              alt="Out Of The Blue"
              width="80px"
              height="auto"
            />
          </div>
          <Typography variant="h5" sx={{
            fontWeight: typography.font_weight.mediumx,
            color: "var(--grey-900)",
          }}>Sign up to Out Of The Blue</Typography>
          <Typography variant="body2" sx={{
            fontWeight: typography.font_weight.light,
            color: "var(--grey-500)",
            marginTop: "10px",
            textAlign: "center",
          }}>
            Start your free trial today!
          </Typography>
          {alertMessage && <TransitionAlerts alert_message={alertMessage} alert_type={alertType} style={ alertType === 'success' ? {
            backgroundColor: "rgb(237, 247, 237)",
            color: "#1E4620",
            width: "auto",
            marginTop: "10px"
          }: { width: "auto", marginTop: "10px" }} handleCloseAlert={() => {
            setAlertMessage('');
            setAlertType('');
          }} />}
          {errorMessage && <div
            style={{
              height: "41px",
              width: "80%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <TransitionAlerts
              alert_message={`Error: ${errorMessages[errorMessage]}`}
              alert_type="error"
            />
          </div>}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
              marginTop: "10px",
              width: "80%",
            }}
          >
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
              width: "100%",
            }}>
              <EmailTextField
                id="email"
                label="Email"
                variant="outlined"
                size="small"
                sx={{
                  width: "100%",
                }}
                onChange={(e) => {
                  setInvalidEmail(false);
                }}
              />
              {invalidEmail ? (
                <Typography
                  sx={(theme) => ({
                    color: "var(--red-3)",
                    fontSize: theme.typography.pxToRem(12),
                    marginLeft: "0.75rem",
                    width: "100%",
                  })}
                >
                  Please enter valid email.
                </Typography>
              ) : null}
              <LoginButton
                onClick={handleMagicLogin}
                disabled={isLoading} // Disable the button when loading
                sx={{
                  backgroundColor: "#3063E7",
                  color: "#FAFAFA",
                  "&:hover": {
                    backgroundColor: "#3063E7"
                  },
                  border: "1px solid #3063E7",
                  marginTop: "20px",
              }}>Continue with Email</LoginButton>
            </div>
            <Divider sx={{
              width: "80%",
            }}>
              <Typography variant="caption" sx={{
                color: "var(--grey-500)",
                fontWeight: typography.font_weight.light,
              }}>or</Typography>
            </Divider>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: "30px",
              marginBottom: "15px",
            }}>
              <LoginButton
                onClick={handleGoogleLogin}
                startIcon={
                  <img src="/images/google-logo.png" alt="Google" width="20px" height="20px" />
                }
              >
                Continue with Google
              </LoginButton>
              <LoginButton
                onClick={handleMicrosoftLogin}
                startIcon={
                  <img src="/images/microsoft-logo.png" alt="Google" width="20px" height="20px" />
                }
              >
                Continue with Microsoft
              </LoginButton>
            </div>
            <span
              style={{
                fontSize: "13px",
                fontWeight: "normal",
                color: "#616161",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              By signing up, you agree to our&nbsp;
              <span
                style={{
                  color: "var(--grey-900)",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Terms of Service&nbsp;
              </span>&nbsp;
              and&nbsp;
              <span
                style={{
                  color: "var(--grey-900)",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Privacy Policy
              </span>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
              width: "90%",
            }}
          >
            <span
              style={{
                fontSize: "13px",
                fontWeight: typography.font_weight.light,
                color: "#616161",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Already have an account?&nbsp;
              <span
                style={{
                  color: "#3063E7",
                  cursor: "pointer",
                  fontSize: "13px",
                  fontWeight: typography.font_weight.semiBold,
                }} // Different color and cursor for the sign-up link
                onClick={() => navigate("/login")} // Redirect to the signup page
              >
                Log in {"\u2192"}
              </span>
            </span>
          </div>
        </div>
      </Paper>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          height: "50px",
          display: "flex",
          flexDirection: "row",
          marginRight: useMediaQuery("(min-width:768px)") ? "40px" : "0px",
          justifyContent: useMediaQuery("(min-width:768px)") ? "flex-end" : "center",
        }}
      >
        <span
          style={{
            fontSize: "12px",
            fontWeight: "normal",
            color: "#6B6D77",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <a
            href="https://outoftheblue.ai/company/"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "inherit",
              textDecoration: "none",
            }}
          >
            ©2022 Out Of The Blue™
          </a>
        </span>
      </div>
    </Container>
  );
};
export default LoginScreen;
