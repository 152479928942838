import {
  addMonths,
  isSameDay,
  startOfDay,
  subMonths,
  subWeeks,
  endOfDay,
  endOfWeek,
  endOfMonth,
  endOfHour,
} from "date-fns";
import { TIME_GRANULARITY_TO_DTICK, TIME_GRANULARITY_TO_TICK_FORMAT } from "../constants/commonConstants";
import { getDifferenceInDays, localToUTC } from "./dateUtils";

export const defaultTimestamps = {
  // These default start and end timestamps are added for fallback, when they are not set through redux
  // Set start date to 2 weeks back from now
  startTime: subWeeks(Date.now(), 2).valueOf(),
  endTime: Date.now().valueOf(),
};

function addStartAndEndDatesToArrayWhenNotPresent(
  data,
  startTimestamp,
  endTimestamp
) {
  if (!(startTimestamp && endTimestamp)) return data;
  const { length, 0: xFirstItem, [length - 1]: xLastItem } = data.x;

  if (!isSameDay(new Date(xFirstItem), new Date(startTimestamp))) {
    data.x.unshift(startOfDay(new Date(startTimestamp)));
    data.y.unshift(0);
    data.text.unshift(0);
  }

  if (!isSameDay(new Date(xLastItem), new Date(endTimestamp))) {
    data.x.push(startOfDay(new Date(endTimestamp)));
    data.y.push(0);
    data.text.push(0);
  }

  return data;
}

export function translateToHistogramChartAxes(
  input,
  granularity,
  startTimestamp,
  endTimestamp
) {
  const histogramData = input.reduce(
    (accumulator, currentValue) => {
      if (currentValue.positive.count > 0) {
        accumulator.y.push(Math.min(currentValue.positive.count, 10));
        accumulator.x.push(currentValue.timestamp);
        accumulator.text.push(currentValue.positive.count);
      }
      if (currentValue.negative.count > 0) {
        accumulator.y.push(Math.min(currentValue.negative.count, 10) * -1);
        accumulator.x.push(currentValue.timestamp);
        accumulator.text.push(currentValue.negative.count * -1);
      }

      return accumulator;
    },
    { x: [], y: [], text: [] }
  );

  if (granularity === "day") {
    return addStartAndEndDatesToArrayWhenNotPresent(
      histogramData,
      startTimestamp,
      endTimestamp
    );
  }

  return histogramData;
}

/**
 * The closer number is to 1, the smaller width of bars is.
 * The fewer the number of bars there are, the closer value to 1 we'll need
 * to appear that all bars have the consistent width in all cases (1 - ~30 bars).
 * This function takes count of bars and returns value in range 0.838 to ~0.988.
 *
 * @param {number} itemsCount
 * @returns {number}
 *  */
export function getBarGap(itemsCount) {
  // when there are 25+ bars, this value matches the desired bar width the closest
  const MIN_GAP_VALUE = 0.81;
  const MAX_GAP_VALUE = 0.988;
  const MAX_COUNT_OF_BARS = 30;

  if (itemsCount === 0) return 0;
  if (itemsCount > MAX_COUNT_OF_BARS) return MIN_GAP_VALUE;

  const difference = MAX_GAP_VALUE - MIN_GAP_VALUE;
  // the bigger the count, the closer to MIN_GAP_VALUE we should be
  return (
    ((MAX_COUNT_OF_BARS - itemsCount) / MAX_COUNT_OF_BARS) * difference +
    MIN_GAP_VALUE
  );
}

function getRange(startTimestamp, endTimestamp, isQuarter) {
  if (isQuarter) {
    return [
      subMonths(new Date(startTimestamp), 2),
      addMonths(new Date(endTimestamp), 2),
    ];
  }

  return [startTimestamp, endTimestamp];
}

export function getXAxisOverrideConfig(startTimestamp, endTimestamp, response) {
  const isResponseDataArrayEmpty = response.histogram_data.length === 0;
  const isGranularityHour = response.histogram_granularity === "hour";
  const isGranularityDay = response.histogram_granularity === "day";
  const isGranularityQuarter = response.histogram_granularity === "quarter";

  // Calculate difference in days between start and end timestamps
  const differenceInDays = getDifferenceInDays(
    endTimestamp || defaultTimestamps.endTime, // set default endDate, if there is no endTimestamp in redux store
    startTimestamp || defaultTimestamps.startTime // set default startDate, if there is no startTimestamp in redux store
  );

  const commonProps = {
    dtick: isGranularityDay && differenceInDays > 15 ? 3 * TIME_GRANULARITY_TO_DTICK[response.histogram_granularity] : TIME_GRANULARITY_TO_DTICK[response.histogram_granularity],
    tickformat: isGranularityHour ? "%H:%M\n%d %d, %Y" : "%b %d\n%Y",
  };

  if (isResponseDataArrayEmpty) {
    return Object.assign(commonProps, {
      tick0: startTimestamp || defaultTimestamps.startTime,
      tickformat: "\n%b %d",
      autorange: false,
      range: getRange(
        startTimestamp || defaultTimestamps.startTime, // set default startDate, if there is no startTimestamp in redux store
        endTimestamp || defaultTimestamps.endTime, // set default endDate, if there is no endTimestamp in redux store
        isGranularityQuarter
      ),
    });
  } else {
    return Object.assign(commonProps, {
      tick0: response.histogram_data[0].timestamp,
      tickformat: TIME_GRANULARITY_TO_TICK_FORMAT[response.histogram_granularity],
      autorange: true,
    });
  }
}

export function getXAxisTicksCount(
  startTimestamp,
  endTimestamp,
  granularity,
  responseArrayLength
) {
  if (granularity !== "day") {
    return responseArrayLength;
  }

  return getDifferenceInDays(endTimestamp, startTimestamp);
}

/**
 * Returns an object with start and end timestamps for a given date and granularity.
 *
 * @param {Date} date - The starting date.
 * @param {("hour"|"day"|"week"|"month")} granularity - The granularity for the timestamps.
 * @returns {Object} An object with start and end timestamps.
 */
export function getStartAndEndTimestamps(date, granularity) {
  // Check if the date is of type string and convert it to Date object
  if (typeof date === "string") {
    date = new Date(date);
  }
  let start = date;
  let end = date;

  if (granularity === "hour") {
    end = endOfHour(date);
  } else if (granularity === "day") {
    start = startOfDay(start);
    end = endOfDay(date);
  } else if (granularity === "week") {
    start = startOfDay(start);
    end = endOfWeek(date, { weekStartsOn: 1 });
  } else if (granularity === "month") {
    start = startOfDay(start);
    end = endOfMonth(date);
  }

  if (granularity === "hour") {
    return { start, end };
  }

  // calling `startOfDay` will return begining of the day but in local time zone
  // `localToUTC` utility fn will return UTC values for `start` and `end` dates
  return { start: localToUTC(start), end: localToUTC(end) };
}
