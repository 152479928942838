import { Box } from "@mui/material";
import React from "react";
import { RESOURCE_TYPE_GROUP } from "../../constants/commonConstants";
import { HTTP_STATUS } from "../../shared/enums/http-status";
import { errorMessage } from "../../shared/intl/error-messages";
import { useAxios } from "../../hooks/useAxios";
import { useSelectedTenant } from "../../hooks/useSelectedTenant";
import EventGroup from "./components/EventGroup";
import InsightSummaryLoader from "../common/InsightSummaryLoader";
import ApiScreenError from "../common/error-components/ApiScreenError";
import { StyledCard } from "../common/styled-components";
import { Message } from "../common/ui-components/Message";

function Group({ groupId, fromLink, index }) {
    const selectedTenant = useSelectedTenant();

    const { data: groupData, isLoading, isError, error } = useAxios(`events/groups/${groupId}`, {
        params: {
            tenant_id: selectedTenant,
        },
    });

    if (isLoading) {
        return <InsightSummaryLoader  showTimeShip={false}/>;
      }

      if (isError) {
        if (error.response?.status === HTTP_STATUS.NOT_FOUND) {
          return <ApiScreenError error={error} resourceType="Group" />;
        } else {
          return (
            <Box pb="50px">
              <StyledCard>
                <Message>{errorMessage.CARD_ERROR}</Message>
              </StyledCard>
            </Box>
          );
        }
      }

  return <EventGroup event={groupData} fromLink={fromLink}/>;
}

export default Group;
