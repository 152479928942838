import styled from "@emotion/styled";
import { Box, Container, Stack, useMediaQuery } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { GTagArgsContext } from "../../context/GtagContext";
import { InsightContext } from "../../context/InsightContext";
import { useSpikes } from "../../hooks";
import { actualTimeSeriesLayout, config, expectedTimeSeriesRangeLayout, spikesPointLayout, timeSeriesAreaRangelayout } from "../../shared/plotly-config";
import { colors, typography } from "../../shared/theme-constants";
import { datePartsWithTZ, formatDatePerFrequency } from "../../utils/dateUtils";
import { GTAG_EVENTS, sendDataToGTM } from "../../utils/gtmHelper";
import { merge } from "../../utils/objects";
import ChartPlot, { ErrorChartPlot } from "./ChartPlot";
import InsightCardAccordion from "./InsightCardAccordion";
import TimeseriesChartDescription from "./TimeseriesChartDescription";
import ChartTable from "./chart-table/ChartTable";
import { Loading } from "./styled-components";
import { ChartTitleTypography } from "./styled-components/Container.styled";
import { theme } from "./styled-components/theme";
import "./tooltip.css";

import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { listInsightDetails } from "../../actions/insightActions";
import { SHOW_MODAL, VIEW_INDIVIDUAL_INSIGHT } from "../../constants/modalConstants";
import { prepareParams } from "../../hooks/useSpikes";
import { SET_SNACKBAR } from "../../reducers/notificationSnackbarReducer";
import HighChartsReactComponent from "./highcharts/HighChartsReactComponent";
import "./highcharts/HighChartsReactComponent.css";
import { generateTimeSeriesChartTooltip } from "../../shared/highcharts-config/timeseries-chart-tooltip";
import { nFormatter } from "../../utils/stringUtils";
import { TIMESERIES_INSIGHT_CHART } from "../../shared/highcharts-config/timeseries-spikes-config";

// Default timezone, TODO: get this from user preferences
const DEFAULT_TIMEZONE = "Etc/UTC";

const timeseriesChartTitle = { title: { text: "Timeline view" } };

const StyledTimeSeriesBox = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: theme.typography.pxToRem(900),
  padding: "0 25px",
}));

// This function adds hours or days or weeks or months to the end time based on the pipeline schedule
const addTimeToEndDate = (schedule, end) => {
  let { hours24, day, month, year } = datePartsWithTZ(end);
  switch (schedule) {
    case "hourly":
      // Add 8 hours, so that there is enough space for anomaly marker
      hours24 = Number(hours24) + 8;
      break;
    case "daily":
      // Add 4 days, so that there is enough space for anomaly marker
      day = Number(day) + 4;
      break;
    case "weekly":
      // To add 2 weeks, we need to add 14 days to the no. of days
      day = Number(day) + 14;
      break;
    case "monthly":
      // Add 3 months, so that there is enough space for anomaly marker
      month = Number(month) + 3;
      break;
    case "yearly":
      year = Number(year) + 1;
      break;
  }
  // Form the new date with the modified hours/days/weeks or months
  // And set it as initial end date
  return new Date(year, month, day, hours24);
};

/**
 * This component handles the chart events like zoom, pan etc. and calculate the dates based on the x-range
 * and use it to invoke the pattern series API to fetch the new data
 * @returns
 */
const TimeseriesChart = ({ patternType, plotBands, overlayEndDateEpoch, isForecastEnabled = true }) => {
  const matchesMobile = useMediaQuery(`(max-width:${theme.tablet})`);
  const { patternData, insightSummary } = useContext(InsightContext);
  const gtagArgsContext = useContext(GTagArgsContext);

  const initialStartTime = patternData.pattern.chart_ts_start;
  const initialEndTime = patternData.pattern.chart_ts_end;

  // We want to preserve layout.xaxis.range and layout.yaxis.range
  const [xRange, setXRange] = useState(undefined);

  // xRange0 is used for tracking the xRange array updates,
  // because useEffect cannot recognise the object updates by defualt
  // so, it's a hack for it
  const [xRange0, setXRange0] = useState(undefined);
  const [yRange, setYRange] = useState(undefined);
  const [yRangeInitial, setYRangeInitial] = useState(undefined);
  const [startTime, setStartTime] = useState(initialStartTime);
  const [endTime, setEndTime] = useState(initialEndTime);
  const [recenterChart, setRecenterChart] = useState(false);
  const [endTimeInitial, setEndTimeInitial] = useState(undefined);

  const { chartData, layout: layoutWithoutTitle, styles, error, initialYRange } = useSpikes(patternData, startTime, endTime);
  const layout = useMemo(() => merge(layoutWithoutTitle, timeseriesChartTitle), [layoutWithoutTitle]);

  // BUT, we need to clear them from state when X or Y axis changes...
  // So, we remember the axis labels in state...
  const [newXlabel, setNewXlabel] = useState(null);
  const [xLabel, setXLabel] = useState(layout.xaxis.title.text);
  const [yLabel, setYLabel] = useState(layout.yaxis.title.text);
  const [chartLayout, setChartLayout] = useState(layout);

  useEffect(() => {
    setYRangeInitial(initialYRange);
    setEndTimeInitial(addTimeToEndDate(insightSummary.schedule, initialEndTime));
  }, []);

  // When layout prop changes, check to see if X or Y axis label has changed...
  useEffect(() => {
    let newX = layout.xaxis.title.text;
    let newY = layout.yaxis.title.text;
    if (xLabel !== newX || yLabel !== newY) {
      setXRange(undefined);
      setYRange(undefined);
      setXLabel(newX);
      setYLabel(newY);
    }
  }, [layout, xLabel, yLabel]);
  // This useEffect is for handling the layout for mobile, the legends should show at bottom
  // and the chart title should be broken up
  useEffect(() => {
    if (matchesMobile) {
      const layoutForMobile = {
        legend: {
          // put the legend below the chart
          x: 0,
          y: -0.3,
          yanchor: "",
          xanchor: "",
        },
      };
      setChartLayout(merge(layout, layoutForMobile));
    } else {
      setChartLayout(layout);
    }
    // The above logic will execute, if the screen changes to mobile and vice-versa or the layout changes
  }, [matchesMobile, layout]);

  useEffect(() => {
    if (xRange) {
      if (recenterChart) {
        setStartTime(initialStartTime);
        setEndTime(initialEndTime);
        setXRange0(initialStartTime);
        setXRange([initialStartTime, endTimeInitial]);
        setYRange(yRangeInitial);
      } else {
        const start = new Date(xRange[0]);
        setStartTime(start.toISOString());

        const endISO = new Date(xRange[1]).toISOString();
        const endISOActual = datePartsWithTZ(endISO);
        const chartISO = datePartsWithTZ(patternData.pattern.chart_ts_end);
        const today = new Date();
        if (endISOActual.day < chartISO.day && endISOActual.hours24 <= chartISO.hours24) {
          setEndTime(endISO);
        } else if (endISOActual.day > today.getDay()) {
          // This else is for safer side, if plottly returns the end time to be greater than today's date, then reset to initial end time
          setEndTime(initialEndTime);
        }
      }
    }
  }, [xRange0, recenterChart]); // xRange0 as deps will ensure that this useEffect will trigger as soon as xRange is updated

  // When user pans, zooms etc. update the saved X and Y ranges
  const handleUpdate = (figure, b) => {
    setXRange0(figure.layout.xaxis.range[0]);
    setXRange(figure.layout.xaxis.range);
    setYRange(figure.layout.yaxis.range);
  };

  // Handles the double click event and set the state `recenterChart` to true
  const handleDoubleClick = () => {
    setRecenterChart(true);
  };
  // This handler gets called on every chart render, after zoom, doubleclick etc, so it's the right place
  // to set the recenter state to false again
  const onRelayout = () => {
    setRecenterChart(false);
  };

  function handleAccordionStateChange(_event, isExpanded) {
    if (isExpanded) {
      sendDataToGTM(GTAG_EVENTS.TIMESERIES_TABLE_EXPANDED, gtagArgsContext);
    }
  }

  if (!chartData && !error) return <Loading />;
  if (error) {
    return <ErrorChartPlot message="Some error occurred, please try again after sometime" />;
  }

  return (
    <Stack direction="column" spacing={10} alignItems="center">
      {patternData.pattern.pattern_type === "Point Anomaly" ? (
        <TimeSeriesChart
          initialStartTime={initialStartTime}
          initialEndTime={initialEndTime}
          setEndTime={setEndTime}
          setStartTime={setStartTime}
          setNewXlabel={setNewXlabel}
          patternData={patternData}
          expectedVal={patternData.pattern.expected_value}
          patternPipeline={patternData.pattern.pipeline_schedule}
          plotBands={plotBands}
          overlayEndDateEpoch={overlayEndDateEpoch}
          isForecastEnabled={isForecastEnabled}
        />
      ) : (
        <Container sx={{ minHeight: 420 }}>
          <ChartPlot
            data={chartData}
            layout={chartLayout}
            config={config}
            style={styles}
            xRange={xRange}
            yRange={yRange}
            onRelayout={onRelayout}
            handleUpdate={handleUpdate}
            handleDoubleClick={handleDoubleClick}
          />
        </Container>
      )}

      <Container disableGutters maxWidth={false} sx={{ maxWidth: 850, mx: "auto", textAlign: "left" }}>
        {/* Commented as part of DEV-4368 */}
        {/* <ChartTitleTypography>{patternData.pattern.chart_title}</ChartTitleTypography> */}
        {/* <TimeseriesChartDescription description={patternData.pattern.chart_description} /> */}

        <InsightCardAccordion insightIdAccordion={insightSummary.insightId} onChange={handleAccordionStateChange} summary="% Lift Calculation Table">
          <ChartTable />
        </InsightCardAccordion>
      </Container>
    </Stack>
  );
};

export default TimeseriesChart;

function TimeSeriesChart({ patternPipeline, expectedVal, patternData, initialStartTime, initialEndTime, plotBands, overlayEndDateEpoch, isForecastEnabled = true }) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();
  const modalType = useSelector((state) => state.modal?.modalName);
  const [status, setStatus] = useState("loading");
  const [chartTimeRange, setChartTimeRange] = useState({ startTime: initialStartTime, endTime: initialEndTime });
  const [options, setChartOptions] = useState(null);

  useEffect(() => {
    setStatus("loading");
    const params = prepareParams(patternData.pattern, chartTimeRange.startTime, chartTimeRange.endTime, isForecastEnabled);
    axios
      .get(BASE_URL.concat("pattern_series"), { params })
      .then((timeseries) => {
        let timeSeriesDataPoints = [...timeseries?.data];
        createChartData(timeSeriesDataPoints, patternData.pattern);
      })
      .catch((error) => {
        setStatus("error");
      });
  }, []);

  const generateTimeSeriesChartTickInterval = (frequency) => {
    switch (frequency) {
      case "h":
        return 2 * 3600 * 1000;
      case "d":
        return 2 * 24 * 3600 * 1000;
      case "w":
        return 14 * 24 * 3600 * 1000;
      case "m":
        return 2 * 30 * 24 * 3600 * 1000;
      case "q":
        return 2 * 90 * 24 * 3600 * 1000;
      default:
        return 2 * 3600 * 1000;
    }
  };
  const shouldModalOpen = (event) => {
    // check if insight object is present and has data in it
    let anomalyPointData = event.point?.anomaly;
    if (anomalyPointData?.insight?.insight_id) {
      // Check if the insight modal is already open or not
      if (modalType !== VIEW_INDIVIDUAL_INSIGHT) {
        dispatch(listInsightDetails(anomalyPointData?.insight?.insight_id))
          .then((response) => {
            // If success we show the insight modal
            dispatch({
              type: SHOW_MODAL,
              payload: {
                modalType: VIEW_INDIVIDUAL_INSIGHT,
                modalProps: { insightIds: [anomalyPointData?.insight.insight_id] },
                showCloseButton: true,
              },
            });
          })
          .catch((error) => {
            // This might be the case of muted insight hence show a toast message
            dispatch({
              type: SET_SNACKBAR,
              snackbarOpen: true,
              snackbarMessage: "No actionable insight generated",
              snackbarType: "info",
            });
          });
      }
      // else Do nothing
    } else {
      // This might be the case of outlier where point might be an anomaly but not an insight
      if (anomalyPointData) {
        // this condition is to check if the user has clicked on anomaly point or not
        dispatch({
          type: SET_SNACKBAR,
          snackbarOpen: true,
          snackbarMessage: "No actionable insight generated",
          snackbarType: "info",
        });
      }
    }
  };

  const generateChartOptions = (timeStampsArray, pattern, patternSeries, chartSeries) => {
    const chartConfig = {
      credits: {
        enabled: false, // This disables the Highcharts watermark
      },
      // This is to open the modal of the individual insight
      plotOptions: {
        series: {
          point: {
            events: {
              click: shouldModalOpen,
            },
          },
        },
      },
      chart: {
        panKey: "shift",
        panning: {
          enabled: true,
          type: "x",
        },
        resetZoomButton: {
          position: {
            x: 0,
            y: -80,
          },
        },
        borderColor: colors.gray[575],
        borderWidth: 0.5,
        borderRadius: "10px",
        spacingLeft: 20,
        spacingBottom: 30,
        spacingRight: 20,
        spacingTop: 20,
        zoomType: "xy",
        events: {
          selection: function (event) {
            if (event.resetSelection) {
              setChartTimeRange({ startTime: initialStartTime, endTime: initialEndTime });
            } else {
              let xMin = event.xAxis[0].min;
              let xMax = event.xAxis[0].max;
              // get all the available data points from initial render
              let totalXRange = event.xAxis[0].axis.categories;
              setChartTimeRange({ startTime: totalXRange[Math.floor(xMin)], endTime: totalXRange[Math.ceil(xMax) - 1] });
            }
          },
        },
      },
      legend: {
        layout: "horizontal",
        align: "right",
        verticalAlign: "top",
        itemStyle: {
          color: colors.gray[375],
          fontSize: ".75rem",
        },
      },
      margin: { left: 10 },
      title: {
        text: "Timeline View",
        align: "left",
        style: {
          color: "var(--purple-fog)",
          fontSize: "1em",
        },
      },
      accessibility: {
        enabled: false,
      },

      xAxis: {
        type: "datetime",
        categories: timeStampsArray,
        lineColor: colors.gray[350],
        tickColor: colors.gray[350],
        tickInterval: generateTimeSeriesChartTickInterval(patternPipeline),
        tickLength: 14,
        tickWidth: 0.5,
        max: overlayEndDateEpoch,
        labels: {
          style: {
            color: colors.gray[575],
            fontSize: ".75em",
          },
          formatter: function () {
            return formatDatePerFrequency(this.value, patternPipeline, DEFAULT_TIMEZONE);
          },
        },
        plotBands: plotBands && plotBands.length ? plotBands : [],
        endOnTick: true, // If set to true, the axis will end on the last
        showLastLabel: true, // To show the last label on the tick.
      },

      yAxis: {
        title: {
          text: pattern.kpi_display_name,
          style: {
            fontSize: "1.2em",
          },
          x: -5
        },
        labels: {
          style: {
            color: colors.gray[575],
            tickColor: colors.gray[350],
            fontSize: ".75em",
          },
          formatter: function () {
            if (pattern.kpi_format === "percentage") {
              return `${(this.value * 100).toFixed(1)}%`;
            }else {
              return nFormatter(this.value, 1);
            }
          }
        },
      },

      tooltip: {
        crosshairs: {
          enabled: true,
          width: 0.5,
          zIndex: 10,
          dashStyle: "longDash",
          color: "black",
        },
        shared: true,
        valueSuffix: "",
        borderColor: colors.gray[350],
        shadow: false,
        borderRadius: 3,
        borderWidth: 0.5,
        padding: 10,
        useHTML: true,
        formatter: function (tooltip) {
          return generateTimeSeriesChartTooltip(this, tooltip, patternSeries, pattern, TIMESERIES_INSIGHT_CHART, isForecastEnabled);
        },
      },
      series: chartSeries,
    };
    setChartOptions(chartConfig);
    setStatus("loaded");
  };

  const createChartData = (patternSeries, pattern) => {
    let series = [];
    // declare anomalySeries array for spike downs and spike ups
    let spikeUpSeriesArray = [];
    let spikeDownSeriesArray = [];
    // declare the actual data array
    let actualDataSeriesArray = [];
    // decalre the expected value array
    let expectedTimeSeriesArray = [];

    // declare CI range array
    let confidenceIntervalArray = [];

    // declare timestamp array
    let timeStampsArray = [];

    for (let i = 0; i < patternSeries.length; i++) {
      timeStampsArray.push(patternSeries[i].timestamp);
      confidenceIntervalArray.push([moment(patternSeries[i].timestamp).valueOf(), patternSeries[i].result.yhat_lower, patternSeries[i].result.yhat_upper]);

      // Define accent based on yhat and y
      let accent = pattern.accent;
      if (patternSeries[i].result.anomaly == "True" && patternSeries[i].result.y > patternSeries[i].result.yhat_upper) {
        accent = pattern.pattern_direction == "up" ? pattern.accent : (pattern.accent == "positive" ? "negative" : "positive");
        // spike up
        spikeUpSeriesArray.push({ x: moment(patternSeries[i].timestamp).valueOf(), y: patternSeries[i].result.y, anomaly: patternSeries[i].result });
        series.push({ ...spikesPointLayout(accent, "up"), data: spikeUpSeriesArray });

        // Check if series has object with id: "spike-up", if no then push the legend to series
        if (series.find((obj) => obj.id === "spike-up") === undefined) {
          // Legend for spike-ups
          let spikeUpLegendLayout = { ...spikesPointLayout(accent, "up") };
          delete spikeUpLegendLayout["linkedTo"];
          series.push({ ...spikeUpLegendLayout, id: "spike-up", data: [] });
        }

      } else if (patternSeries[i].result.anomaly == "True" && patternSeries[i].result.y < patternSeries[i].result.yhat_lower){
        accent = pattern.pattern_direction == "up" ? (pattern.accent == "positive" ? "negative" : "positive") : pattern.accent;
        // spike down
        spikeDownSeriesArray.push({ x: moment(patternSeries[i].timestamp).valueOf(), y: patternSeries[i].result.y, anomaly: patternSeries[i].result });
        series.push({ ...spikesPointLayout(accent, "down"), data: spikeDownSeriesArray });

        // Check if series has object with id: "spike-down", if no then push the legend to series
        if (series.find((obj) => obj.id === "spike-down") === undefined) {
          // Legend for spike-downs
          let spikeDownLegendLayout = { ...spikesPointLayout(accent, "down") };
          delete spikeDownLegendLayout["linkedTo"];
          series.push({ ...spikeDownLegendLayout, id: "spike-down", data: [] });
        }
      }
      // Populate the actual and expected value series
      expectedTimeSeriesArray.push({ x: moment(patternSeries[i].timestamp).valueOf(), y: patternSeries[i].result.yhat });
      actualDataSeriesArray.push({ x: moment(patternSeries[i].timestamp).valueOf(), y: patternSeries[i].result.y });

      // Push the expected value series
      series.push({ ...actualTimeSeriesLayout, data: actualDataSeriesArray });
    }

    series.push({ ...timeSeriesAreaRangelayout, data: confidenceIntervalArray });
    series.push({ ...expectedTimeSeriesRangeLayout, data: expectedTimeSeriesArray });

    // Below piece of code is following reasons
    // 1. We are creating different series for different sections of the spline chart
    // 2. These sections have their own legends
    // 3. But technically we just repeat between anomaly, expexted and actual values graph
    // 4. Hence We don't need multiple legends for same values
    // 5. Hence h we import chart configs. Each config will have a "linkedTo" property. This property points to "id" field which is referenced below
    // 6. This created unique legends for all the series splines
    // 7. Thus avoiding multiple legends

    // Legend for Actual series plot band
    let actualSeriesLegendLayout = { ...actualTimeSeriesLayout };
    delete actualSeriesLegendLayout["linkedTo"];
    series.push({ ...actualSeriesLegendLayout, id: "actual", data: [] });

    // Legend for expected series
    let expectedSeriesLegendLayout = { ...expectedTimeSeriesRangeLayout };
    delete expectedSeriesLegendLayout["linkedTo"];
    series.push({ ...expectedSeriesLegendLayout, id: "expected", data: [] });

    generateChartOptions(timeStampsArray, pattern, patternSeries, series);
  };
  if (status === "error") return <ErrorChartPlot message="Some error occurred, please try again after sometime" />;
  if (status === "loading")
    return (
      <Box sx={{ width: "100%", minHeight: 570, display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Loading />
      </Box>
    );
  return (
    <StyledTimeSeriesBox sx={{ mt:2 }}>
      <HighChartsReactComponent options={options} />
    </StyledTimeSeriesBox>
  );
}
