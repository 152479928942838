export const METRIC_LIST_REQUEST = "METRIC_LIST_REQUEST";
export const METRIC_LIST_SUCCESS = "METRIC_LIST_SUCCESS";
export const METRIC_LIST_FAIL = "METRIC_LIST_FAIL";

export const METRIC_DETAILS_REQUEST = "METRIC_DETAILS_REQUEST";
export const METRIC_DETAILS_SUCCESS = "METRIC_DETAILS_SUCCESS";
export const METRIC_DETAILS_FAIL = "METRIC_DETAILS_FAIL";

export const RELATED_METRIC_REQUEST = "RELATED_METRIC_REQUEST";
export const RELATED_METRIC_SUCCESS = "RELATED_METRIC_SUCCESS";
export const RELATED_METRIC_FAIL = "RELATED_METRIC_FAIL";

export const METRIC_DELETE_REQUEST = "METRIC_DELETE_REQUEST";
export const METRIC_DELETE_SUCCESS = "METRIC_DELETE_SUCCESS";
export const METRIC_DELETE_FAIL = "METRIC_DELETE_FAIL";

export const METRIC_CREATE_REQUEST = "METRIC_CREATE_REQUEST";
export const METRIC_CREATE_SUCCESS = "METRIC_CREATE_SUCCESS";
export const METRIC_CREATE_FAIL = "METRIC_CREATE_FAIL";
export const METRIC_CREATE_RESET = "METRIC_CREATE_RESET";

export const METRIC_UPDATE_REQUEST = "METRIC_UPDATE_REQUEST";
export const METRIC_UPDATE_SUCCESS = "METRIC_UPDATE_SUCCESS";
export const METRIC_UPDATE_FAIL = "METRIC_UPDATE_FAIL";
export const METRIC_UPDATE_RESET = "METRIC_UPDATE_RESET";
