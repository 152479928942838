import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import ListLoader from "../../common/ListLoader";
import HighChartsReactComponent from "../../common/highcharts/HighChartsReactComponent";

// Default timezone, TODO: get this from user preferences
const DEFAULT_TIMEZONE = "Etc/UTC";

const SparkLineChart = ({ color, patternSeriesData, kpiFormat }) => {
  const [patternOption, setPatternOption] = useState();
  const [patternData, setPatternData] = useState([]);
  const formatSparklineData = () => {
    patternSeriesData.map((data, index) => setPatternData((previousPatternData) => [...previousPatternData, data.result.y]));
  };
  function formatNumber(num) {
    if (kpiFormat === 'percentage') {
      return `${(num * 100).toFixed(1)}%`;
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    } else {
      return Math.floor(num);
    }
  }
  useEffect(() => {
    if (patternSeriesData) {
      formatSparklineData();
      setPatternOption({
        credits: {
          enabled: false,
        },
        chart: {
          backgroundColor: null,
          borderWidth: 0,
          type: "area",
          margin: [0, 2, 0, 2],
          width: 220,
          height: 40,
          style: {
            overflow: "visible",
          },
          // small optimalization, saves 1-2 ms each sparkline
          skipClone: true,
        },
        accessibility: {
          enabled: false,
        },
        title: {
          text: "",
        },
        xAxis: {
          visible: false,
        },
        yAxis: {
          visible: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          outside: true,
          shadow: false,
          borderRadius: 5,
          padding: 10,
          backgroundColor: "#E5ECFD",
          useHTML: true,
          formatter: function (tooltip) {
            let currData = patternSeriesData[Math.round(this.x, 2)];
            return `
                            <span>${moment(currData.timestamp).tz(DEFAULT_TIMEZONE).format('DD MMM YYYY, hh[:]mmA')}</span>
                            </br>
                            <span style = "font-weight : 700">${formatNumber(this.y)}</span>
                            `;
          },
        },
        series: [
          {
            name: "",
            color: "#1E46EF",
            lineWidth: "3",
            data: formatData(patternSeriesData),
            fillOpacity: 0.1,
            marker: {
              enabled: false,
              radius: 3
            },
          },
        ],
      });
    }
  }, []);
  const formatData = (patternSeriesData) => {
    let data = [];
    patternSeriesData.map((currentY, index) => {
      if (currentY.result.anomaly === "True") {
        data.push({
          y: currentY.result.y,
          marker: {
            enabled: true,
            fillColor: color === "positive" ? "#43A45C" : "#D95A5A",
          },
        });
      } else {
        data.push({
          y: currentY.result.y,
          marker: {
            enabled: false,
          },
        });
      }
    });
    return data;
  };

  if (!patternData.length) return <ListLoader count={1} height={20} paddingY={10} />;
  return <HighChartsReactComponent options={patternOption} />;
};

export default SparkLineChart;
