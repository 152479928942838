import styled from "styled-components";

import { colors } from "../../../shared/theme-constants";

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: var(--navbar-height);
  position: fixed;
  top: 0; 
  z-index: 999;
  background-color: ${colors.header_bg};
  background-image: linear-gradient(to right, #02096a, #1040c8);
`;
