import { useCallback, useMemo } from "react";

import { useAxios } from "./useAxios";
import { PATTERN_TYPE } from "../shared/constants/pattern-types";
import { colors } from "../shared/theme-constants";
import { labels } from "../shared/intl/labels";
import { datePartsWithTZ } from "../utils/dateUtils";
import { trendPredictionConfig, trendPredictionDashdotConfig } from "../shared/plotly-config";

/**
 * Prepare the arrays for table chart data, based on the Pattern Type
 * @returns Table chart data array for Spikes chart table and Trend chart table
 */
function prepareTableChartData(
  formattedActualValue,
  formattedExpectedValue,
  rowLabels,
  kpiAccent,
  accentColor,
  pType,
  patternTableData,
  formattedDifferenceValue,
  formattedYhatLower,
  formattedYhatUpper
) {
  // Trend change table has different headings and for the
  if (pType === PATTERN_TYPE.TREND_CHANGE) {
    return [
      {
        heading: labels.TABLE_CHART.TREND.OLD_RATE,
        subText: rowLabels[0],
        accent: null,
        tableData: {
          value: formattedExpectedValue,
          subText: patternTableData.trend_unit,
        },
      },
      {
        heading: labels.TABLE_CHART.TREND.NEW_RATE,
        subText: rowLabels[0],
        accent: null,
        tableData: {
          value: formattedActualValue,
          subText: patternTableData.trend_unit,
        },
      },
      {
        heading: labels.TABLE_CHART.KPI_VALUES_DIFFERENCE,
        subText: patternTableData["difference_date"],
        accent: {
          type: kpiAccent,
          value: rowLabels[1],
          color: accentColor,
        },
        tableData: {
          value: formattedDifferenceValue,
          subText: null,
        },
      },
    ];
  } else {
    return [
      {
        heading: labels.TABLE_CHART.ACTUAL_VALUE,
        subText: rowLabels[0],
        accent: null,
        tableData: {
          value: formattedActualValue,
          subText: null,
        },
      },
      {
        heading: labels.TABLE_CHART.EXPECTED_KPI_VALUE,
        subText: rowLabels[0],
        accent: null,
        tableData: {
          value: `${formattedExpectedValue} [${formattedYhatLower}, ${formattedYhatUpper}]`,
          subText: null,
        },
      },
      {
        heading: labels.TABLE_CHART.KPI_VALUES_DIFFERENCE,
        subText: null,
        accent: {
          type: kpiAccent,
          value: rowLabels[1],
          color: accentColor,
        },
        tableData: {
          value: formattedDifferenceValue,
          subText: null,
        },
      },
    ];
  }
}

function setAccentColor(accent) {
  return accent === "positive" ? colors.positive_green : colors.negative_red;
}

/**
 * Prepend `+` sign to pctChange when it's a positive number
 * @param {number} pctChange
 * @returns {string} (+/-)pctChange
 */
function formatPctChange(pctChange) {
  if (pctChange > 0) {
    return "+".concat(pctChange);
  }
  return pctChange;
}

function getPipelineSchedule(pipelineSchedule) {
  return labels.PIPELINE_SCHEDULE[pipelineSchedule];
}

export const useInsight = (insightId, config = {}) => {
  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useAxios(`insights/${insightId}`, config);

  const prepareTrendChartData = useCallback((trendLine) => {
    const oldTrend = trendLine.trend_prediction.pre_trendline;
    const newTrend = trendLine.trend_prediction.post_trendline;
    if (oldTrend && newTrend) {

      // Check if prolonged trend is present by comparing the end date of old trend and start date of new trend
      const isProlongedTrendPresent = new Date(oldTrend.coords[1][0]) > new Date(newTrend.coords[0][0]);

      // Trend Predction - Pre Trend Lines (x-start, y-start), (x-end, y-end)
      const [oldTrendLine_start_xy, oldTrendLine_end_xy] = isProlongedTrendPresent ? [oldTrend.coords[0], newTrend.coords[0]] : oldTrend.coords;

      // Trend Predction - Post Trend Lines (x-start, y-start), (x-end, y-end)
      const [newTrendLine_start_xy, newTrendLine_end_xy] = newTrend.coords;

      // Trend Predction - Prolonged Trend Lines (x-start, y-start), (x-end, y-end)
      const [prolongedTrendLine_start_xy, prolongedTrendLine_end_xy] = isProlongedTrendPresent ? [newTrend.coords[0], oldTrend.coords[1]] : [null, null];

      const oldTrendLineColor = setAccentColor(oldTrend.accent);
      const newTrendLineColor = setAccentColor(newTrend.accent);
      const prolongedTrendLineColor = setAccentColor(oldTrend.accent);

      const hoverTextOldTrendStyle = `color: ${oldTrendLineColor}; font-weight: 700;`;
      const hoverTextNewTrendStyle = `color: ${newTrendLineColor}; font-weight: 700;`;
      const hoverTextProlongedTrendStyle = `color: ${prolongedTrendLineColor}; font-weight: 700;`;
      const hoverTextOldTrend = `<span style="${hoverTextOldTrendStyle}">${labels.SPIKES_CHART.HOVER_INFO.TREND.PRE_TREND}</span>`;
      const hoverTextNewTrend = `<span style="${hoverTextNewTrendStyle}">${labels.SPIKES_CHART.HOVER_INFO.TREND.POST_TREND}</span>`;
      const hoverTextProlongedTrend = `<span style="${hoverTextProlongedTrendStyle}">${labels.SPIKES_CHART.HOVER_INFO.TREND.PROLONGED_TREND}</span>`;

      const preTrendLine = {
        ...trendPredictionConfig,
        legendrank: 2002,
        line: {
          ...trendPredictionConfig.line,
          color: oldTrendLineColor,
        },
        name: labels.SPIKES_CHART.LEGENDS.TREND.OLD,
        x: [oldTrendLine_start_xy[0], oldTrendLine_end_xy[0]],
        y: [oldTrendLine_start_xy[1], oldTrendLine_end_xy[1]],
        showlegend: true,
        hovertext: [hoverTextOldTrend, hoverTextOldTrend],
        hovertemplate: "%{hovertext}<br>%{y}<extra></extra>",
      };
      const postTrendLine = {
        ...trendPredictionConfig,
        legendrank: 2001,
        line: {
          ...trendPredictionConfig.line,
          color: newTrendLineColor,
        },
        name: labels.SPIKES_CHART.LEGENDS.TREND.NEW,
        x: [newTrendLine_start_xy[0], newTrendLine_end_xy[0]],
        y: [newTrendLine_start_xy[1], newTrendLine_end_xy[1]],
        showlegend: true,
        hovertext: [hoverTextNewTrend, hoverTextNewTrend],
        hovertemplate: "%{hovertext}<br>%{y}<extra></extra>",
      };

      if (isProlongedTrendPresent) {
        // Plot Prolonged Old Trend Line
        const prolongedTrendLine = {
          ...trendPredictionDashdotConfig,
          legendrank: 2000,
          line: {
            ...trendPredictionDashdotConfig.line,
            color: prolongedTrendLineColor,
          },
          name: labels.SPIKES_CHART.LEGENDS.TREND.PROLONGED,
          x: [prolongedTrendLine_start_xy[0], prolongedTrendLine_end_xy[0]],
          y: [prolongedTrendLine_start_xy[1], prolongedTrendLine_end_xy[1]],
          showlegend: true,
          hovertext: [hoverTextProlongedTrend, hoverTextProlongedTrend],
          hovertemplate: "%{hovertext}<br>%{y}<extra></extra>",
        };

        return [preTrendLine, prolongedTrendLine, postTrendLine];
      } else {
        return [preTrendLine, postTrendLine];
      }
    } else return [];
  }, []);

  const patternData = useMemo(() => {
    if (!response) return [];

    const pDirection = response.pattern_direction;
    const kpiAccent = response.accent;
    const pctChange = response.pattern_count;
    const kpiFormat = response.kpi_format;
    const formattedActualValue = response.formatted_current_value;
    const formattedExpectedValue = response.formatted_expected_value;
    const formattedDifferenceValue = response.pattern_type == PATTERN_TYPE.TREND_CHANGE ? response.pattern_data.formatted_delta_to_date : response.formatted_difference_value;
    const formattedYhatLower = response.pattern_data.formatted_yhat_lower;
    const formattedYhatUpper = response.pattern_data.formatted_yhat_upper;

    const pctChangeRounded =
      pctChange < 1 && pctChange > -1
        ? pctChange.toFixed(1)
        : Math.round(pctChange);

    const { day, monthName, year, hours24, minutes } = datePartsWithTZ(
      response.insight_id_ts
    );

    let time = `${monthName} ${day}, ${year}`;
    // If the pipeline is schedule for hourly run and the pattern type is Spike
    // then add the time as well along with date
    if (
      response.pipeline_schedule === "h" &&
      response.pattern_type === PATTERN_TYPE.SPIKES
    ) {
      time = `${hours24}:${minutes} on ${time}`;
    }

    const accentColor = setAccentColor(kpiAccent);

    const rowLabels = [
      labels.TABLE_CHART.TEMPLATE.asOfTemplate.replace("__time", time),
      labels.TABLE_CHART.TEMPLATE.percentageTemplate.replace(
        "__value",
        formatPctChange(pctChangeRounded)
      ),
    ];

    let trendChart = [],
      trendUnit = "";

    let patternTableData = {};

    if (response.pattern_type === PATTERN_TYPE.TREND_CHANGE) {
      trendChart = prepareTrendChartData(response.pattern_data.trend_line);
      trendUnit = response.pattern_data.trend_unit;
      patternTableData["trend_unit"] = response.pattern_data.trend_unit;

      // Populate date to display under difference label
      let difference_date_str_arr = new Date(response.pattern_data.trend_line.trend_prediction.post_trendline.coords[1][0]).toDateString().split(" ");
      patternTableData["difference_date"] = labels.TABLE_CHART.TEMPLATE.asOfTemplate.replace("__time", `${difference_date_str_arr[1]} ${difference_date_str_arr[2]}, ${difference_date_str_arr[3]}`);
    } else if (
      response.pattern_type === PATTERN_TYPE.SPIKES ||
      response.pattern_type === PATTERN_TYPE.NO_SPIKES
    ) {
      patternTableData["yhat_upper"] = response.pattern_data.yhat_upper;
      patternTableData["yhat_lower"] = response.pattern_data.yhat_lower;
    }

    const tableChart = prepareTableChartData(
      formattedActualValue,
      formattedExpectedValue,
      rowLabels,
      kpiAccent,
      accentColor,
      response.pattern_type,
      patternTableData,
      formattedDifferenceValue,
      formattedYhatLower,
      formattedYhatUpper,
      kpiFormat
    );

    const pData = {
      accent: kpiAccent,
      chartTable: tableChart,
      dimensionValue: response.dim_val,
      kpiMetric: Math.abs(pctChangeRounded),
      hasTrend: response.pattern_type === PATTERN_TYPE.TREND_CHANGE,
      pattern: response,
      patternType: response.pattern_type,
      patternDirection: pDirection,
      schedule: getPipelineSchedule(response.pipeline_schedule),
      trendChartData: trendChart,
      kpiFormat: kpiFormat,
      patternDescription: response.pattern_description,
    };
    return pData;
  }, [prepareTrendChartData, response]);

  return { pattern: patternData, isLoading, isSuccess, isError, error };
};
