import { css } from "styled-components";

export const ValignTextMiddle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PoppinsNormalBlack11px = css`
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: 11px;
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsBoldComet186px = css`
  color: var(--comet-2);
  font-family: var(--font-family-poppins);
  font-size: 18.6px;
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsBoldMariner15px = css`
  color: var(--mariner);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxs2);
  font-weight: 700;
`;

export const PoppinsNormalManatee153px = css`
  color: var(--manatee);
  font-family: var(--font-family-poppins);
  font-size: 15.3px;
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsBoldBlack20px = css`
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl2);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsLightComet24px = css`
  color: var(--comet);
  font-family: var(--font-family-poppins);
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
`;

export const PoppinsMediumMariner14px = css`
  color: var(--mariner);
  font-family: var(--font-family-poppins);
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
`;

export const PoppinsBoldQuickSilver15px = css`
  color: var(--quick-silver);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxs2);
  font-weight: 700;
`;

export const PoppinsNormalComet37px = css`
  color: var(--comet);
  font-family: var(--font-family-poppins);
  font-size: 37px;
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsNormalZircon153px = css`
  color: var(--zircon);
  font-family: var(--font-family-poppins);
  font-size: 15.3px;
  font-weight: 400;
  font-style: normal;
`;

export const Border12pxMercury = css`
  border: 1.2px solid var(--mercury);
`;

export const Border14pxMercury = css`
  border: 1.4px solid var(--mercury);
`;
