export const DoD = "DoD";
export const WoW = "WoW";
export const MoM = "MoM";
export const QoQ = "QoQ";
export const YoY = "YoY";

export const OVERLAYS_GRANULARITY_MAP = {
  // based on pipeline schedule
  hourly: {
    // for hourly pipelies, there will be exactly 24 data points for each overlay type
    // Since endDate is inclusive, we need to subtract 23 hours to get the 24 data points
    timeInterval: 23, // hours
  },
  daily: {
    // for daily pipelies, there will be exactly 7 data points for each overlay type
    // Since endDate is inclusive, we need to subtract 6 days to get 7 data points
    timeInterval: 6, // days
  },
  weekly: {
    // for daily pipelies, there will be exactly 4 data points for each overlay type
    // Since endDate is inclusive, we need to subtract 27 days (~ 4 weeks) to get 4 data points
    timeInterval: 27, // days
  },
  monthly: {
    // for monthly pipelies, there will be exactly 12 data points for each overlay type
    // Since endDate is inclusive, we need to subtract 364 days (52 weeks * 7 days or ~ 1 year) to get 12 data points
    timeInterval: 364, // days
  },
  // based on selected overlay type
  // Below mapping is used in useOverlay hook only, sor adjusting the x-range for preceding periods
  DoD: {
    daysInterval: 1,
  },
  WoW: {
    daysInterval: 7,
  },
  MoM: {
    daysInterval: 28,
  },
  QoQ: {
    daysInterval: 84,
  },
  YoY: {
    daysInterval: 364,
  },
};
