import { withStyles } from "@mui/styles";
import Snackbar from "@mui/material/Snackbar";
import { theme } from "./theme";

const styles = {
  root: {
    width: "2560px",
    height: "240px",
    top: "75px",
    alignItems: "flex-start",
    paddingTop: "25px",
    background: "linear-gradient(180deg,aliceblue 70.67%,rgba(241, 245, 254, 0) 155%)",
    "& .MuiPaper-root": {
      width: "1035px",
      height: "130px",
      fontSize: "21px",
      background: "#FFFFFF",
      border: "2.04944px solid #acb6d9",
      boxSizing: "border-box",
      borderRadius: "8px",
      lineHeight: "32px",
      color: "#98A7C6",
      fontWeight: 700,
      textAlign: "center",
      padding: "20px",

      [`@media(max-width:${theme.iPadPro})`]: {
        width: "765px",
      },

      [`@media(max-width:${theme.mobile})`]: {
        width: "610px",
      },

      [`@media(max-width:${theme.tablet})`]: {
        height: "auto",
        width: "400px",
        margin: "unset",
        fontSize: "18px"
      },
    },

    [`@media(max-width:${theme.ipadPro})`]: {
      width: "1199px",
    },

    [`@media(max-width:${theme.iPadPro})`]: {
      width: "999px",
    },

    [`@media(max-width:${theme.tablet})`]: {
      marginTop: "5px",
      height: "290px",
      fontSize: "0.8em",
    },
  },
};

export const NotificationMessage = withStyles(styles)(Snackbar);
