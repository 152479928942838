import { Box, Collapse, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RESOURCE_TYPE_GROUP } from "../../../constants/commonConstants";
import { GTagArgsContext } from "../../../context/GtagContext";
import { InsightContext } from "../../../context/InsightContext";
import { useInsight, useStateWithCallback } from "../../../hooks";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import useBoolean from "../../../hooks/useBoolean";
import { HTTP_STATUS } from "../../../shared/enums/http-status";
import { errorMessage } from "../../../shared/intl/error-messages";
import { labels } from "../../../shared/intl/labels";
import { GTAG_EVENTS, sendDataToGTM } from "../../../utils/gtmHelper";
import Card from "../../common/Card";
import InsightSummaryLoader from "../../common/InsightSummaryLoader";
import RenderCharts from "../../common/RenderCharts";
import ApiScreenError from "../../common/error-components/ApiScreenError";
import { ShareAndCollaborate } from "../../common/share-and-collaborate/ShareAndCollaborate";
import { StyledCard } from "../../common/styled-components";
import { Message } from "../../common/ui-components/Message";
import InsightCardHeader from "./InsightCardHeader";
import Feedback from "./feedback/Feedback";
import { SHOW_MODAL, VIEW_INDIVIDUAL_INSIGHT } from "../../../../src/constants/modalConstants";
import { calculateOverlayDuration } from '../../../shared/highcharts-config/timeseries-overlay-config';

function Insight({
  insight,
  handleClickActionIcon,
  fromLink,
  enableInsightAction = true,
  insightId = null,
  showExpanded = false,
  openAsModal = false,
  groupId,
  resourceId,
  resourceType,
  shouldOpenInModalOnClick = false,
}) {
  const insightID = insightId ? insightId : insight.insights[0];
  const tenantGlobalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);
  const tenantConfigs = useSelector((state) => state?.tenantConfigs?.configs);

  const { pattern, isLoading, isSuccess, isError, error } =
    useInsight(insightID, {
      params: {
        dim_name: tenantGlobalFilter?.dimension_name,
        dim_val: tenantGlobalFilter?.dimension_value,
      },
    });
  const user = useAuthenticatedUser();
  const [isOpen, { toggle }] = useBoolean(showExpanded);
  const [tagManagerArgs, setTagManagerArgs] = useStateWithCallback(null);

  const dispatch = useDispatch();

  const insightContext = {
    insightSummary: {
      insightId: insightID,
      schedule: pattern.schedule,
      accent: pattern.accent,
      kpiMetric: pattern.kpiMetric,
      title: pattern.pattern?.insight_title,
      dimensionValue: pattern.dimensionValue,
      patternDirection: pattern.patternDirection,
      insightSeverity: pattern.pattern?.insight_severity,
      insightDate: insightID
        ? pattern.pattern?.insight_id_ts
        : insight.group_ts_start,
      summary: pattern.pattern?.insight_summary,
      insightType: pattern.pattern?.dim_display_name ? `${pattern.pattern.dim_display_name}: ${pattern.pattern.dim_val}` : 'Overall Metric',
    },
    patternData: {
      pattern: pattern.pattern,
      hasTrend: pattern.hasTrend,
      trendChartData: pattern.trendChartData,
      summary: pattern.patternDescription,
    },
    tableChartData: pattern.chartTable,
  };

  const plotBands = [];
  let overlayEndDateEpoch;

  if (insightContext?.insightSummary.insightDate) {
  const insightDate = insightContext?.insightSummary.insightDate;
  const overlayDuration = calculateOverlayDuration(pattern.schedule);

  const insightDateEpoch = new Date(insightDate).getTime();
  const overlayStartDateEpoch = insightDateEpoch - overlayDuration;
  overlayEndDateEpoch = insightDateEpoch + overlayDuration;

  plotBands.push({
    color: pattern.accent === 'negative' ? 'rgba(229, 95, 95, 0.25)' : 'rgba(67, 164, 92, 0.25)',
    from: overlayStartDateEpoch,
    to: overlayEndDateEpoch,
  });
}

  useEffect(() => {
    if (fromLink) {
      toggle();
    }
  }, [fromLink, toggle]);

  useEffect(() => {
    if (pattern?.pattern) {
      setTagManagerArgs(
        {
          ...pattern.pattern,
          // tenant_id must always be the logged-in user's tenant_id
          tenant_id: user?.tenant_id,
          // this is added for outoftheblue users, it will hold the customer name selected from the dropdown
          customer_id: pattern.pattern.tenant_id,
          pipeline_schedule:
            labels.PIPELINE_SCHEDULE[pattern.pattern.pipeline_schedule],
        },
        (args) => {
          sendDataToGTM(GTAG_EVENTS.CARD_DISPLAYED, args);
        }
      );
    }
  }, [pattern, setTagManagerArgs, user?.tenant_id]);

  useEffect(() => {
    if (pattern?.pattern && isOpen) {
      sendDataToGTM(GTAG_EVENTS.CARD_EXPANDED, tagManagerArgs);
    }
  }, [isOpen, pattern?.pattern, tagManagerArgs]);

  if (isLoading) {
    return <InsightSummaryLoader isExpanded={showExpanded} />;
  }
  if (isError) {
    if (error.response?.status === HTTP_STATUS.NOT_FOUND) {
      return <ApiScreenError error={error} resourceType="Insight" />;
    } else {
      return (
        <Box pb="50px">
          <StyledCard>
            <Message>{errorMessage.CARD_ERROR}</Message>
          </StyledCard>
        </Box>
      );
    }
  }

  const shouldModalOpen = insightId => {
    // check if insight object is present and has data in it
    if (insightId) {
            dispatch({
              type: SHOW_MODAL,
              payload: {
                modalType: VIEW_INDIVIDUAL_INSIGHT,
                modalProps: { insightIds: [insightId] },
                showCloseButton: true,
              },
            });
    }
  };

  return (
    <>
      {isSuccess && (
        <Box
          sx={{ position: "relative" }}
        >
          <InsightContext.Provider value={insightContext}>
            <GTagArgsContext.Provider value={tagManagerArgs}>
              <Card
                groupId={insight?.group_id}
                groupSeverity={insight?.group_severity}
                groupAccent={insight?.group_accent}
                insightList={insight?.insights}
                insightTime={
                  insightID
                    ? pattern.pattern.insight_id_ts
                    : insight.group_ts_start
                }
                openAsModal={openAsModal}
                handleClickActionIcon={
                  enableInsightAction ? handleClickActionIcon : null
                }
                pipelineSchedule={pattern.schedule}
                isLive={
                  resourceType === RESOURCE_TYPE_GROUP &&
                  insight?.group_status === "ACTIVE"
                }
                fromLink={fromLink}
              >
                <InsightCardHeader
                  accent={pattern.pattern.accent}
                  groupSeverity={insight?.group_severity}
                  title={pattern.pattern.insight_title}
                  summary={pattern.pattern.insight_summary}
                  groupId={insight?.group_id}
                  insightId={insightID}
                  isModal={openAsModal}
                  isOpen={isOpen}
                  miniChartName={
                    pattern.hasTrend
                      ? `trend-${pattern.pattern.pattern_direction}-${pattern.pattern.accent}`
                      : `spike-${pattern.pattern.pattern_direction}-${pattern.pattern.accent}`
                  }
                  onClick={shouldOpenInModalOnClick ? () => shouldModalOpen(insightID) : toggle}
                  insightType={insightContext.insightSummary.insightType}
                  funnels={pattern?.pattern?.funnels}
                />
                <Collapse in={isOpen} mountOnEnter unmountOnExit sx={{ mt: 7.5 }}>
                  <RenderCharts
                    hasRootCause={pattern.pattern.has_rootcause}
                    insightId={insightID}
                    accent={pattern.pattern.accent}
                    kpiFormat={pattern.kpiFormat}
                    hasTrend={insightContext.patternData.hasTrend}
                    resourceType={resourceType}
                    resourceId={resourceId}
                    // get rid of below variables in refactoring tasks
                    patternType={pattern.patternType}
                    groupId={insight?.group_id}
                    openAsModal={openAsModal}
                    plotBands={plotBands}
                    overlayEndDateEpoch={overlayEndDateEpoch}
                    isForecastEnabled ={tenantConfigs?.is_forecast_enabled}
                  />
                  <Stack
                    justifyContent="space-between"
                    alignItems={{ tm: "end" }}
                    sx={{ marginTop: 6, gap: 4, marginBottom: '0.4rem' }}
                    flexDirection={{ tm: "row" }}
                  >
                    <Feedback
                      isModal={openAsModal}
                      resourceId={
                        resourceType === RESOURCE_TYPE_GROUP
                          ? insight?.group_id
                          : insightID
                      }
                      resourceType={resourceType}
                    />
                    <ShareAndCollaborate
                      resourceType={resourceType}
                      insight={insight}
                      groupId={groupId ? groupId : insight?.group_id}
                      insightId={insightID}
                      // get rid of isModal in the refactor task
                      isModal={openAsModal}
                      hasRootCause={pattern.pattern.has_rootcause}
                    />
                  </Stack>
                </Collapse>
              </Card>
            </GTagArgsContext.Provider>
          </InsightContext.Provider>
        </Box>
      )}
    </>
  );
}

export default Insight;
