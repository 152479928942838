import { styled } from "@mui/material/styles";
import { Box, FormControlLabel } from "@mui/material";
import { buttonBaseClasses } from "@mui/material/ButtonBase";
import { switchClasses } from "@mui/material/Switch";
import { typographyClasses } from "@mui/material/Typography";
import { colors } from "../../../../shared/theme-constants";

export const SwitchFormControlLabel = styled(FormControlLabel)({
  borderRadius: "10px",
  border: `1px solid ${colors.cadet_gray}`,
  paddingLeft: "12px",
  marginLeft: 0,
  marginRight: 0,
  "&:hover": {
    borderColor: colors.comet,
  },

  [`& .${typographyClasses.root}`]: {
    color: colors.comet,
    fontSize: "0.875rem",
    whiteSpace: "nowrap"
  },
  [`& .${buttonBaseClasses.root}`]: {
    color: colors.mariner,
  },

  [`& .${switchClasses.root}`]: {
    height: "36px",
  },
  [`& .${switchClasses.switchBase}`]: {
    padding: "8px",
  },
  [`& .${switchClasses.switchBase}.${switchClasses.checked}`]: {
    color: colors.mariner,
  },
});

export const IconContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  border: `1px solid ${colors.cadet_gray}`,
  borderRadius: 10,
  padding: "0 4px",
  cursor: "pointer",
  "&:hover": {
    borderColor: colors.comet,
  },
});
