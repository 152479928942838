import { startOfWeek } from "date-fns";
import { EVENTS_FEED_FILTER_RESET, EVENTS_FEED_SUMMARY_FILTERS, EVENTS_FEED_TIMESTAMPS } from "../constants/eventConstants";

const default_filters = {
  timestamps: {
    startDate: startOfWeek(new Date(), { weekStartsOn: 0 }),
    endDate: new Date(),
    pipelineSchedule: "w"
  },
  summaryFilters: {
    severity: "High",
  }
};

const getFiltersFromQueryParams = () => {
  const params = new URLSearchParams(window.location.search); // Copy the current params
  if (params.size) {
  const obj = {
    ...default_filters,
  };
  params.forEach((value, key) => {
    let parsedValue;

    // Check if the key corresponds to a filter that requires JSON parsing
    if (key === "provider_uuid") {
      try {
        parsedValue = JSON.parse(value);
      } catch (e) {
        return; // Skip dispatching this filter if parsing fails
      }
    } else {
      parsedValue = value;
    }
    obj.summaryFilters[key] = parsedValue;
  });
  return obj;
  }
  return default_filters;
};

export const eventsFeedFilterReducer = (state = { ...getFiltersFromQueryParams() }, action) => {
  switch (action.type) {
    case EVENTS_FEED_TIMESTAMPS:
      let oldState = { ...state };
      return { ...oldState, timestamps: { ...action.payload } };
    case EVENTS_FEED_SUMMARY_FILTERS:
      let currentState = { ...state };
      let oldFilterState = { ...currentState.summaryFilters };
      if (action.payload.value === null) {
        // Delete the filter key if the value is null
        delete oldFilterState[action.payload.type];
      } else {
        // Otherwise, update the filter with the new value
        oldFilterState[action.payload.type] = action.payload.value;
      }
      return {
        ...currentState,
        summaryFilters: {
          ...oldFilterState
        },
      };
    case EVENTS_FEED_FILTER_RESET:
      return { ...default_filters };
    default:
      return state;
  }
};