import { useParams } from "react-router-dom";
import FeedContainer from "../components/FeedContainer";
import { ShareCardContainer } from "../components/common/styled-components/Container.styled";
import Insight from "../components/insights/insight/Insight";
import { RESOURCE_TYPE_INSIGHT } from "../constants/commonConstants";

const SingleInsightScreen = () => {
  const { insightId } = useParams();
  return (
    <FeedContainer>
      <ShareCardContainer>
        <Insight
          // get rid of insightId prop in refactor task as it should now be referenced by resourceID
          insightId={insightId}
          fromLink
          resourceType={RESOURCE_TYPE_INSIGHT}
          resourceId={insightId}
        />
      </ShareCardContainer>
    </FeedContainer>
  );
};

export default SingleInsightScreen;
