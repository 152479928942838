import styled from "styled-components";

const Spinner = styled.div`
  width: ${({ width }) => width ? width : "40px"};
  height:  ${({ height }) => height ? height : "40px"};

  position: relative;
  margin: ${({ margin }) => margin ? margin : "100px auto"} ;
`;

const BounceOne = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor ? bgColor : "rgb(175, 200, 255)"};
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }
  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
`;

const BounceTwo = styled(BounceOne)`
  background-color: ${({ bgColor }) => bgColor ? bgColor : "rgb(95, 106, 255)"} ;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
`;

const Center = styled.div`
  position: absolute;
  top: 35%;
  left: 50%;
`;

export const Loading = (props) => {
  return (
    <Spinner {...props}>
      <BounceOne {...props} />
      <BounceTwo {...props} />
    </Spinner>
  );
};

export const LoaderInCenter = () => {
  return (
    <Center>
      <Loading />
    </Center>
  );
};
