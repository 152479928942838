import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";

// date function imports
import { getRelativeTimestringUtc } from "../../utils/dateUtils";

import { DaySeparator, Loading } from "../common/styled-components";
import { listGroupInsights } from "../../actions/groupInsightActions";
import IconCheckbox from "../common/IconCheckbox";
import { useStateWithCallback } from "../../hooks";
import {
  TEAM_INSIGHT_ACTION_INITIATED,
  TEAM_INSIGHT_ACTION_CANCEL,
} from "../../constants/teamConstants";
import Group from "./Group";
import { groupBy } from "../../utils/arrayHelper";
import { Message } from "../common/ui-components/Message";
import { useSelectedTenant } from "../../hooks/useSelectedTenant";
import { Stack } from "@mui/material";

function InsightsFeed({ handleClickActionIcon }) {
  const dispatch = useDispatch();

  // Holds the element ref for the div that contains the records and needs to be re-rendered with new records
  const container = useRef(null);
  const [hasEnded, setHasEnded] = useState(false); // to indicate whether or not we've fetched all the records
  const { data: groupInsightList, loading: groupLoading } = useSelector(
    (state) => state.groupInsightsList
  );
  const selectedTenant = useSelectedTenant();

  const timeFilter = useSelector(
    (state) => state.feedScreenTimeFilter,
    (currentTime, previousTime) => {
      if (currentTime !== previousTime) {
        setHasEnded(false);
        setPage(1);
      }
    }
  );

  const tenantGlobalFilter = useSelector(
    (state) => state.tenantGlobalFilter?.appliedFilter,
    (currentFilter, previousFilter) => {
      if (currentFilter !== previousFilter) {
        setHasEnded(false);
        setPage(1);
      }
    }
  );

  const [fetchInsights, setFetchInsights] = useState(true);

  // // DEV-4234: Older menuItem
  // const menuItem = useSelector(
  //   (state) => state.drawer.menuItem,
  //   (selectedItem, previousSelectedItem) => {
  //     if (selectedItem !== previousSelectedItem) {
  //       setHasEnded(false);
  //       setPage(1);
  //       setFetchInsights(false);
  //     }
  //   }
  // );

  const [selectedInsightIds, setSelectedInsightIds] = useStateWithCallback([]);

  const teamAction = useSelector(
    (state) => state.teamInsightList.selectedInsights
  );
  const [page, setPage] = useState(1);

  const manageInsightIds = (groupsInsightsId) => {
    const currentIndex = selectedInsightIds.indexOf(groupsInsightsId);
    const insightIdsCopy = [...selectedInsightIds];

    if (currentIndex === -1) {
      insightIdsCopy.push(groupsInsightsId);
    } else {
      insightIdsCopy.splice(currentIndex, 1);
    }

    setSelectedInsightIds(insightIdsCopy, (newIds) => {
      newIds.length > 0
        ? dispatch({
            type: TEAM_INSIGHT_ACTION_INITIATED,
            selectedInsights: newIds,
          })
        : dispatch({ type: TEAM_INSIGHT_ACTION_CANCEL, selectedInsights: [] });
    });
  };

  // useEffect(() => {
  //   if (selectedInsightIds.length) {
  //     dispatch({ type: TEAM_INSIGHT_ACTION_CANCEL, selectedInsights: [] });
  //   }
  // }, [menuItem]);

  useEffect(() => {
    if (teamAction?.length) return;
    // This is called after successful submission of the team action or if user decides to cancel
    // It will essentially remove all the selected insight ids
    setSelectedInsightIds([]);
  }, [setSelectedInsightIds, teamAction]);

  // This side effect is used to track if there are more pages to track;
  // It also cleans up the event listener each time the scroll event occurs
  useEffect(() => {
    if (!hasEnded) {
      fetchGroup();

      if (!fetchInsights) setFetchInsights(true);
    }

    return () => {
      document.removeEventListener("scroll", trackScrolling);
    };
  }, [page, timeFilter?.start_time, timeFilter?.end_time, tenantGlobalFilter]);

  // // DEV-4234: Older dpendency array version:
  // }, [menuItem, page, timeFilter?.start_time, timeFilter?.end_time]);

  // This side effect adds an event listener, each time a scroll event occurs
  useEffect(() => {
    document.addEventListener("scroll", trackScrolling);
  }, [groupInsightList]);

  // This function keeps track of scrolling; it will allow scrolling until
  // it has reached the bottom of the screen
  const trackScrolling = () => {
    if (
      container.current &&
      parseInt(container.current.getBoundingClientRect().bottom) <=
        window.innerHeight &&
      fetchInsights
    ) {
      setPage(page + 1);
      document.removeEventListener("scroll", trackScrolling);
    }
  };

  /**
   * This method fetches the data for the current page // :iap: and sets the local state objects:
   * both group of insights and insights (holds the next insights based on the page size) :iap: ???
   * loading (whether to show the loading icon)
   * hasEnded (if there is no data for the current page, then sets it true; otherwise it fetches the data)
   */
  const fetchGroup = () => {
    dispatch(
      listGroupInsights({
        tenant_id: selectedTenant,
        page_num: page,
        start_time: timeFilter?.start_time,
        end_time: timeFilter?.end_time,
        dim_name: tenantGlobalFilter?.dimension_name,
        dim_val: tenantGlobalFilter?.dimension_value,
      })
    ).then((result) => {
      if (result?.generated_groups_count < 1) {
        setHasEnded(true);
      }
    });
  };

  // This is just a wrapper function that iterates over the currently fetched insights and generates
  // 'n' number of insights section (n is the number of insights fetched for the current page)
  const renderGroupInsights = () => {
    const newGroupList = groupInsightList.map((record) => ({
      ...record,
      // Add new key `insertion_date` that will only have the date part of `insight_id_ts`
      // so that the groupBy function doesn't consider the date with different time parts as separate keys
      insertion_date: record.insight_id_ts.substr(
        0,
        record.insight_id_ts.indexOf("T")
      ),
    }));
    const grouped = groupBy(newGroupList, "insertion_date");

    return (
      grouped &&
      Object.keys(grouped).map((key, index) => (
        <div style={{marginBottom : 20}} key={index}>
          <DaySeparator data-snippyly-comment-disabled>
            {
              getRelativeTimestringUtc(key, new Date().toISOString().split('T')[0])
            }
          </DaySeparator>
          <Stack direction="column" gap={10}>
          {grouped[key].map((groupedInsight, index) => (
            <div
              key={groupedInsight.group_id}
              style={{ position: "relative", marginTop: 30 }}
            >
              {/* <IconCheckbox
                checked={
                  selectedInsightIds.indexOf(groupedInsight.group_id) !== -1
                }
                iconName="checkbox"
                value={groupedInsight.group_id}
                handleChange={(event) => manageInsightIds(event.target.value)}
              /> */}
              <Group
                groupId={groupedInsight.group_id}
                insertionTs={groupedInsight.insight_id_ts}
                handleClickActionIcon={handleClickActionIcon}
              />
            </div>
          ))}
          </Stack>
        </div>
      ))
    );
  };

  if (!groupInsightList) return <div />;

  return (
    <Box mt="24px" ref={container}>
      {renderGroupInsights()}
      {groupLoading && <Loading data-testid="loader" />}
      {!groupLoading && hasEnded && (
        <Message>{"You're all caught up here!"}</Message>
      )}
    </Box>
  );
}

export default InsightsFeed;
