export function isFunction(value) {
  return typeof value === "function";
}

export function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Checks if an object is empty.
 *
 * @param {Object} obj - The object to check
 * @returns {boolean} True if the object is empty, false otherwise
 */
export function isEmpty(obj) {
  if (obj == null) return true; // null and undefined are "empty"

  if (Array.isArray(obj) || typeof obj === "string") {
    return obj.length === 0;
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }

  return true;
}

export function isNullOrEmpty(value) {
  return value == undefined || value === "";
}
