import { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  markdownBoldRegex,
  markdownInlineLinkRegex,
} from "../utils/regexHelper";

const defaultComponents = {
  text: ({ value }) => value,
  bold: ({ value }) => <b>{value}</b>,
  link: ({ url, value }) => <Link to={url}>{value}</Link>,
};

// Combined regular expression to match both links and bold text
const COMBINED_REGEX = /\[([^\]]+)\]\(([^)]+)\)|\*\*([^*]+)\*\*/g;

export function parseMarkdown(input) {
  const ast = {
    type: "root",
    children: [],
  };

  let lastIndex = 0;
  for (const match of input.matchAll(COMBINED_REGEX)) {
    if (match.index > lastIndex) {
      const textNode = {
        type: "text",
        value: input.substring(lastIndex, match.index),
      };
      ast.children.push(textNode);
    }

    if (match[1]) {
      const linkNode = {
        type: "link",
        value: match[1],
        url: match[2],
      };
      ast.children.push(linkNode);
    } else {
      const boldNode = {
        type: "bold",
        value: match[3],
      };
      ast.children.push(boldNode);
    }

    lastIndex = match.index + match[0].length;
  }

  if (lastIndex < input.length) {
    const textNode = {
      type: "text",
      value: input.substring(lastIndex),
    };
    ast.children.push(textNode);
  }

  return ast;
}

export function deserializeToJSX(ast, componentsOverride = {}) {
  const components = Object.assign(defaultComponents, componentsOverride);

  return (ast?.children || []).map((child, index) => {
    if (components[child.type]) {
      return (
        <Fragment key={`${child.value}-${index}`}>
          {components[child.type](child)}
        </Fragment>
      );
    }

    return child.value;
  });
}

/**
 * Outputs string without markdown tokens for inline link e.g
 * input: Spike up in [DMV](/metrics/dmv)
 * output: Spike up in DMV
 */
export function stripInlineLinks(value) {
  if (!value || typeof value !== "string") return value;

  return value.replace(markdownInlineLinkRegex, "$1");
}

export function stripMarkdown(value) {
  if (!value || typeof value !== "string") return value;

  return value
    .replace(markdownInlineLinkRegex, "$1")
    .replace(markdownBoldRegex, "$1");
}
