import { NavLink } from "react-router-dom";

import Container from "@mui/material/Container";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

import { bp } from "./theme";
import { colors, typography } from "../../../shared/theme-constants";

export const activeLinkStyle = {
  fontWeight: typography.font_weight.semiBold,
  lineHeight: 1,
};

export const NavbarContainer = withStyles({
  root: {
    paddingLeft: "0px",
    background: colors.blue[925],
  },
})(Container);

export const StyledNavLink = styled(NavLink)({
  textDecoration: "none",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.05)",
    transition: "transform 0.2s ease-out",
  },
});

export const UserAccountLastMenuItemStyles = {
  "&:hover": {
    background: "#36454F",
  },
  background: "#6B6D77",
  color: "white",
  borderRadius: "0px 0px 5px 5px",
  "& .MuiMenuItem-root": {
    justifyContent: "space-between",
  },
};
export const UserAccountMenuItemStyles = {
  "&:hover": {
    background: "#36454F",
  },
  background: "#6B6D77",
  color: "white",
  "& .MuiMenuItem-root": {
    justifyContent: "space-between",
  },
};

export const NavbarItemTypography = styled("span")({
  color: "white",
  fontSize: "20px",
  lineHeight: 1.2,
  display: "none",
  "&:hover": {
    transform: "scale(1.05)",
    transition: "transform 0.2s ease-out",
  },
  [bp.min("800px")]: {
    display: "flex",
  },
});
