import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Box, CircularProgress, List, ListItem } from "@mui/material";

import {
  buttonProps,
  DownloadModalContainer,
  DownloadModalOptionButton,
  DownloadModalOptionContainer,
  DownloadModalOptionText,
  downloadModalStyle,
  listProps,
} from "./styled-components/DownloadModal.styled";
import { getInsightByIds } from "../../actions/groupInsightActions";
import { DownloadCSV } from "./DownloadCSV";

export const DownloadGroupInsight = ({ insightIds, groupId }) => {
  const dispatch = useDispatch();
  const [insightData, setInsightData] = useState([]);

  useEffect(() => {
    dispatch(getInsightByIds(insightIds)).then((response) => {
      const insights = [];
      response.forEach((insight) => {
        insights.push(insight.response.data);
      });
      setInsightData(insights);
    });
  }, [dispatch, insightIds]);

  return (
    <Box sx={downloadModalStyle}>
      <DownloadModalContainer>
        <List sx={listProps}>
          <DownloadModalOptionContainer>
            <DownloadModalOptionText>
              <ListItem>
                {`Download data for ${insightIds.length} insights`}
              </ListItem>
            </DownloadModalOptionText>
            <DownloadModalOptionButton>
              {insightData.length > 0 ? (
                <DownloadCSV
                  insightData={insightData}
                  groupId={groupId}
                  buttonStyle={buttonProps}
                />
              ) : (
                <div style={{ margin: "10px 0 0 30px" }}>
                  <CircularProgress size="1.5rem" />
                </div>
              )}
            </DownloadModalOptionButton>
          </DownloadModalOptionContainer>
        </List>
      </DownloadModalContainer>
    </Box>
  );
};
