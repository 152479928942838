import { useParams } from "react-router-dom";
import FeedContainer from "../components/FeedContainer";
import { ShareCardContainer } from "../components/common/styled-components/Container.styled";
import Group from "../components/insights/Group";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";

const GroupInsightScreen = () => {
  const { groupId } = useParams();
  const user = useAuthenticatedUser();

  return (
    <FeedContainer>
      <ShareCardContainer>
        <Group groupId={groupId} fromLink />
      </ShareCardContainer>
    </FeedContainer>
  );
};

export default GroupInsightScreen;
