import { createSvgIcon } from "@mui/material";

export const ChevronLeftIcon = createSvgIcon(
  <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12z" />,
  "ChevronLeft"
);

export const ChevronRightIcon = createSvgIcon(
  <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />,
  "ChevronRight"
);

export const ExpandMoreIcon = createSvgIcon(
  <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" />,
  "ExpandMore"
);

export const ArrowForwardIcon = createSvgIcon(
  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />,
  "ArrowForward"
);
