import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAPIKeysDetails } from "../../../actions/preferenceActions";
import { useSelectedTenant } from "../../../hooks/useSelectedTenant";
import { HTTP_STATUS } from "../../../shared/enums/http-status";
import { typography } from "../../../shared/theme-constants";
import { generateDifferenceInTimeString } from "../../../utils/dateUtils";
import { StyledActionButton } from "../../common/mui-wrapper-components/Button";
import StyledTabs from "../../common/mui-wrapper-components/StyledTabs";
import { Loading } from "../../common/styled-components";
import APIKeyGenerateDialog from "./KeyGenerateDialog";

const MY_PROFILE_TABS = [{ value: "api_keys", label: "API Keys" }];

const Profile = () => {
  const [openKeyGenerateDialog, setOpenKeyGenerateDialog] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedTenant = useSelectedTenant();

  const openKeyGenerationDialog = () => {
    setOpenKeyGenerateDialog(true);
  };

  const getTokensDetails = useCallback(() => {
    setLoading(true);
    dispatch(getAPIKeysDetails({ tenant_id: selectedTenant }))
      .then((response) => {
        if (response) {
          setTableData(response.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.status === HTTP_STATUS.UNAUTHORIZED) {
          navigate("/login");
        }
      });
  }, [dispatch, navigate, selectedTenant]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getTokensDetails();
    }
    return () => (isMounted = false);
  }, [getTokensDetails]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Stack
        direction="column"
        gap={10}
        sx={{
          height: "85vh",
          alignItems: "flex-start",
          pl: 8,
          pr: 8,
          pb: 8,
          overflowY: "scroll",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <StyledTabs
            sx={{ padding: "20px 0" }}
            value={MY_PROFILE_TABS[0].value}
            options={MY_PROFILE_TABS}
          />
          <Stack
            direction="row"
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Stack
              direction="column"
              gap={1}
              sx={{ alignItems: "flex-start", width: "70%" }}
            >
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(15),
                  fontWeight: typography.font_weight.semiBold,
                  color: "rgba(0, 0, 0, 1)",
                })}
              >
                Generate API Key
              </Typography>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: typography.font_weight.light,
                  textAlign: "left",
                })}
              >
                Please generate an API key for integration to your OTB account.
                Please note that, you can only generate this once.
              </Typography>
            </Stack>
            <StyledActionButton onClick={openKeyGenerationDialog}>
              Generate API Key
            </StyledActionButton>
          </Stack>
        </Box>
        {tableData && tableData.length ? (
          <TableContainer sx={{ backgroundColor: "transparent" }}>
            <Table
              stickyHeader
              sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
            >
              <TableHead>
                <TableRow
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.semiBold,
                    color: "rgba(0, 0, 0, 1)",
                  })}
                >
                  <TableCell>Key Name</TableCell>
                  <TableCell>Scope</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(12),
                      fontWeight: typography.font_weight.regular,
                      color: "rgba(0, 0, 0, 1)",
                    })}
                  >
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.scope == "all" ? "All" : `${row.scope.split(':')[0][0].toUpperCase() + row.scope.split(':')[0].slice(1)}: ${row.scope.split(':')[1]}`}</TableCell>
                    <TableCell
                      sx={{ fontWeight: typography.font_weight.light }}
                    >
                      {row.created_by || "-"}
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: typography.font_weight.light }}
                    >
                      {row.created_at
                        ? `${generateDifferenceInTimeString(row.created_at)} UTC`
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : <></>}

        <APIKeyGenerateDialog
          open={openKeyGenerateDialog}
          handleClose={() => {
            setOpenKeyGenerateDialog(false);
            getTokensDetails();
          }}
        />
      </Stack>
    </>
  );
};

export default Profile;
