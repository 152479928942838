export const desktopDrawerDisplay = {
  display: { sm: "none", tm: "none", lt: "none", sl: "block" },
};

export const mobileDrawerDisplay = {
  display: { sm: "block", tm: "block", lt: "block", sl: "none" },
};

export const hamburgerDisplay = {
  display: { sm: "block", tm: "block", lt: "block", sl: "none" },
};
