import { useMediaQuery } from "@mui/material";
import React from "react";
import ContentLoader from "react-content-loader";
import { theme } from "./styled-components/theme";

export const SummaryCardLoader = () => {
  const matchesMobile = useMediaQuery(`(max-width:${theme.tablet})`);
  const matchesTablet = useMediaQuery(`(max-width:${theme.mobile})`);

  return (
    <div data-snippyly-commnet-disabled style={{ position: "relative" }}>
      {matchesMobile ? (
        <ContentLoader
          viewBox="0 0 450 70"
          height="100%"
          width="100%"
          foregroundOpacity={0.4}
          backgroundOpacity={0.6}
        >
          <rect x="10" y="40" rx="5" ry="5" width="300" height="25" />
          <rect x="10" y="10" rx="5" ry="5" width="200" height="25" />
        </ContentLoader>
      ) : matchesTablet ? (
        <ContentLoader
          viewBox="0 0 500 70"
          height="100%"
          width="100%"
          foregroundOpacity={0.4}
          backgroundOpacity={0.6}
        >
          <rect x="10" y="40" rx="5" ry="5" width="300" height="25" />
          <rect x="10" y="10" rx="5" ry="5" width="200" height="25" />
        </ContentLoader>
      ) : (
        <ContentLoader
          viewBox="0 0 500 70"
          height="100%"
          width="100%"
          foregroundOpacity={0.4}
          backgroundOpacity={0.6}
        >
          <rect x="20" y="10" rx="5" ry="5" width="45" height="45" />
          <rect x="75" y="10" rx="5" ry="5" width="100" height="25" />
          <rect x="75" y="40" rx="5" ry="5" width="150" height="15" />
          <rect x="430" y="15" rx="5" ry="5" width="70" height="35" />
        </ContentLoader>
      )}
    </div>
  );
};

export const SummaryHeaderLoader = () => {
  const matchesMobile = useMediaQuery(`(max-width:${theme.tablet})`);
  const matchesTablet = useMediaQuery(`(max-width:${theme.mobile})`);

  return (
    <div style={{ position: "relative", width: "100%" }}>
      {matchesMobile ? (
        <></>
      ) : matchesTablet ? (
        <ContentLoader
          viewBox="0 0 1800 70"
          height="30px"
          width="100%"
          foregroundOpacity={0.4}
          backgroundOpacity={0.6}
        >
          <rect x="50" y="40" rx="5" ry="5" width="1400" height="20" />
        </ContentLoader>
      ) : (
        <ContentLoader
          viewBox="0 0 2500 70"
          height="30px"
          width="100%"
          foregroundOpacity={0.4}
          backgroundOpacity={0.6}
        >
          <rect x="50" y="40" rx="5" ry="5" width="1060" height="18" />
          <rect x="1850" y="40" rx="5" ry="5" width="600" height="18" />
        </ContentLoader>
      )}
    </div>
  );
};
