import { SET_DIGEST_SCREEN_TIMESTAMP } from "../constants/filterCriteriaConstants";

export const digestScreenTimeFilterReducer = (state= "", action)=>{
    switch (action.type) {
        case SET_DIGEST_SCREEN_TIMESTAMP:
            return {
                start_time: action.payload.start_time
            };
        default:
            return state;
    }
};