import React from "react";
import * as Plotly from "plotly.js";
// customizable method: use your own `Plotly` object
import createPlotlyComponent from "react-plotly.js/factory";
import { ChartContainer } from "./styled-components";
import {
  ChartSubtitleTypography,
  ChartTitleTypography,
} from "./styled-components/Container.styled";

const Plot = createPlotlyComponent(Plotly);

function HotspotChart({
  hotspot,
  barChartConfig,
  styles,
  onlyPercents,
  isDrillDownChart,
}) {
  return (
    <ChartContainer>
      <ChartTitleTypography
        sx={{
          padding: "5px 0 0 70px",
        }}
      >
        {isDrillDownChart ? hotspot.chartTitle : hotspot.chartSummary}
      </ChartTitleTypography>
      <Plot
        data={hotspot.chartData.hotspotData}
        layout={hotspot.chartData.chartLayout}
        config={barChartConfig}
        style={styles}
      />
      {isDrillDownChart && (
        <ChartTitleTypography
          sx={{
            padding: "5px 0 0 70px",
          }}
        >
          {hotspot.chartSummary}
        </ChartTitleTypography>
      )}
      <ChartSubtitleTypography
        sx={{
          padding: "5px 0 0 70px",
        }}
      >
        {!onlyPercents && hotspot.rootcause_subtitle}
      </ChartSubtitleTypography>
    </ChartContainer>
  );
}

export default HotspotChart;
