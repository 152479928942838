import React from "react";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import { bp, theme } from "../../styled-components/theme";
import { colors, typography } from "../../../../shared/theme-constants";

/**
 * Use This button when the text and background colours need to toggle on hover
 */
export const StyledButton = styled(
  React.forwardRef(function StyledButton(props, ref) {
    return (
      <MuiButton
        data-snippyly-comment-disabled
        variant="outlined"
        ref={ref}
        {...props}
      />
    );
  })
)({
  position: "relative",
  display: "flex",
  height: "36px",
  padding: "0 10px",
  border: "1px solid",
  borderColor: colors.mariner,
  color: colors.mariner,
  fontSize: "0.75rem",
  fontWeight: typography.font_weight.semiBold,
  lineHeight: 1,

  "&:hover": {
    backgroundColor: colors.mariner,
    color: colors.white,
    cursor: "pointer"
  },
  "&:disabled": {
    background: "#6b6d77",
    color: "#383838",
    borderColor: "transparent",
    cursor: "default",
  },

  [bp.min(theme.mobile)]: {
    padding: "0 20px",
    fontSize: "1rem",
    color: colors.mariner,
  },
});

/**
 * Use this Button when you have a call to action scenario
 * Mostly cases where the background of the button is coloured and has different color for disable state
 */
export const StyledActionButton = styled(
  React.forwardRef(function StyledButton(props, ref) {
    return <MuiButton  disableElevation data-snippyly-comment-disabled variant="outlined" ref={ref} {...props} />;
  })
)(({ theme }) => ({
  position: "relative",
  display: "flex",
  height: theme.typography.pxToRem(36),
  lineHeight: 1,
  color: "var(--white)",
  background: "var(--mariner)",
  border: "1px solid",
  padding: theme.spacing(0, 5),
  fontWeight: typography.font_weight.semiBold,

  "&:hover": {
    cursor: "pointer",
    color: "var(--white)",
    background: "var(--mariner)",
  },

  fontSize: theme.typography.pxToRem(16),

  ":disabled": {
    background: "rgba(0, 0, 0, 0.12)",
    color: "rgba(0, 0, 0, 0.26)",
    borderColor: "transparent",
    cursor: "default",
  },
}));
