import { createContext, useContext, useState } from "react";

export const GTagArgsContext = createContext();
export const GTagGroupContext = createContext();

/* TODO: Below is a custom hook approach for contexts that enables children component as well
    to update the context. This approach need a little more thinking as there were some issues while implementing it

export const GtagContext = createContext({
  gTagContext: undefined,
  updateGtagContext: () => {},
});

export const GtagContextProvider = (props) => {
  const [gTagContext, setGtagContext] = useState(undefined);

  const updateGtagContext = () => {
    setGtagContext(gTagContext);
  };

  return (
    <GtagContext.Provider value={{ gTagContext, updateGtagContext }}>
      {props.children}
    </GtagContext.Provider>
  );
};

const useGtagContext = () => {
  return useContext(GtagContext);
};

export default useGtagContext;
*/
