import React, { forwardRef } from "react";
import { withStyles } from "@mui/styles";
import { Typography, useMediaQuery } from "@mui/material";

import { StyledButton } from "./Button";
import { theme } from "../../styled-components/theme";
import Icon from "../../Icon";

const ButtonWithIcon = withStyles({
  root: {
    [`@media(max-width:${theme.mobile})`]: {
      borderRadius: "6px",
      border: "1.5px solid #1E46EF",
      padding: "0 8px 0 8px",
      minWidth: "36px",
      background: "white",
      color: "#1E46EF",
    },
  },
})(StyledButton);

export const StyledIconButton = forwardRef((props, ref) => {
  const matchesMobile = useMediaQuery(`(max-width:${theme.tablet})`);

  const buttonHover = (e) => {
    const element = document.getElementById(props.id);
    const svg = element.querySelector("svg");
    svg?.querySelectorAll("path")?.forEach((path) => {
      path.setAttribute("stroke", "white");
    });
  };

  const buttonHoverLeave = (e) => {
    const element = document.getElementById(props.id);
    const svg = element.querySelector("svg");
    svg?.querySelectorAll("path")?.forEach((path) => {
      path.setAttribute("stroke", "#3456ec");
    });
  };

  return (
    <ButtonWithIcon
      data-snippyly-comment-disabled
      ref={ref}
      {...props}
      onMouseEnter={(e) => buttonHover(e)}
      onMouseLeave={(e) => buttonHoverLeave(e)}
      className={props.icon}
      onClick={(e) => props.onClick(e)}
    >
      <Typography variant={matchesMobile ? "h8" : "h7"}>
        {matchesMobile ? "" : props.buttontext}
      </Typography>
      <Icon
        name={props.icon}
        id={props.id}
        showcursor="true"
        title={props.buttontext}
        size={matchesMobile ? "15px" : "20px"}
        margin="0 0 0 3px"
      />
    </ButtonWithIcon>
  );
});

StyledIconButton.displayName = "StyledIconButton";
