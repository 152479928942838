// React Imports
import * as React from "react";
import { useSelector } from "react-redux";

// MUI Imports
import Checkbox from "@mui/material/Checkbox";

// Local Imports
import Icon from "./Icon";
import { colors } from "../../shared/theme-constants";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  position: absolute;
  right: 100%;

  @media (max-width: ${({ theme }) =>
      theme.mobile || theme.large || theme.medium || theme.tablet}) {
    right: 99%;
  }
`;

export default function IconCheckbox({
  iconName,
  checked,
  handleChange,
  value,
}) {
  const loading = useSelector((state) => state.teamInsightList.loading);
  return (
    <CheckboxContainer data-snippyly-comment-disabled>
      <Checkbox
        checked={checked}
        disabled={loading}
        onChange={handleChange}
        value={value}
        icon={
          <Icon name={iconName} title="Select" size="25px" showcursor="true" />
        }
        checkedIcon={
          <Icon
            name={iconName}
            title="Deselect"
            size="25px"
            showcursor="true"
            color={colors.blue[800]}
          />
        }
      />
    </CheckboxContainer>
  );
}
