import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import { StyledIconButton } from "../mui-wrapper-components/Button";

import { GTAG_EVENTS, sendDataToGTM } from "../../../utils/gtmHelper";
import {
  DOWNLOAD_GROUP_INSIGHT,
  DOWNLOAD_INSIGHT,
  SHOW_MODAL,
} from "../../../constants/modalConstants";
import {
  GTagArgsContext,
  GTagGroupContext,
} from "../../../context/GtagContext";
import { RESOURCE_TYPE_GROUP } from "../../../constants/commonConstants";

export const Download = ({
  resourceType,
  resourceId,
  insightIds,
  hasRootCause,
  insightData,
  gTagArgs,
}) => {
  const dispatch = useDispatch();
  const gtagArgsContext = useContext(GTagArgsContext);
  const gTagGroupContext = useContext(GTagGroupContext);
  // If Download is rendered within Group card or individual insight card (or InisghtModal), then google tag manager args
  // will be available through context. So, based on the scenario, assign applicable context
  const gtagContext = gtagArgsContext ?? gTagGroupContext;

  const handleDownloadClick = (e) => {
    const modalType =
      resourceType === RESOURCE_TYPE_GROUP
        ? DOWNLOAD_GROUP_INSIGHT
        : DOWNLOAD_INSIGHT;
    const modalProps =
      resourceType === RESOURCE_TYPE_GROUP
        ? { resourceId, insightIds }
        : { insightIds, insightData, hasRootCause };

    dispatch({
      type: SHOW_MODAL,
      payload: {
        modalType: modalType,
        modalProps: modalProps,
      },
    });

    const gtagEvent =
      resourceType === RESOURCE_TYPE_GROUP
        ? GTAG_EVENTS.DOWNLOAD_GROUP_INSIGHT_CLICKED
        : GTAG_EVENTS.DOWNLOAD_INSIGHT_CLICKED;
    // If Download is rendered within Level 3 component, then google tag manager contexts won't be available
    // in which case, we should read it from props (gTagArgs)
    const gtagArgs = gtagContext ?? gTagArgs;
    sendDataToGTM(gtagEvent, gtagArgs);
  };

  return (
    <StyledIconButton
      id={`download_${resourceId}`}
      icon="download"
      onClick={(e) => handleDownloadClick(e)}
    />
  );
};
