import React from "react";
import styled from "styled-components";
import TimeseriesChartDescription from "../../common/TimeseriesChartDescription";
import { labels } from "../../../shared/intl/labels";
import { colors } from "../../../shared/theme-constants";
import { AsOfDate } from "../../common/styled-components/collaborate";
import {
  PoppinsBoldComet186px,
  PoppinsBoldBlack20px,
  ValignTextMiddle,
} from "../styledMixins";

function Highlights({
  actualValue,
  expectedValue,
  difference,
  minExpectedValue,
  maxExpectedValue,
  chartDescription,
  isTrend,
  trendUnit,
}) {
  return (
    <HiglightsContainer>
      <TableValuesContainer>
        <TableValues>
          <TableValue>
            {isTrend
              ? labels.SPIKES_CHART.LEGENDS.TREND.NEW
              : labels.TABLE_CHART.ACTUAL_VALUE}
            :{" "}
          </TableValue>
          <Value>{actualValue}</Value>
          {isTrend && <AsOfDate>{trendUnit}</AsOfDate>}
        </TableValues>
        <TableValues>
          <TableValue>
            {isTrend
              ? labels.SPIKES_CHART.LEGENDS.TREND.OLD
              : labels.TABLE_CHART.EXPECTED_KPI_VALUE}
            :{" "}
          </TableValue>
          {isTrend ? (
            <>
              <Value>{expectedValue}</Value>
              <AsOfDate>{trendUnit}</AsOfDate>
            </>
          ) : (
            <Value>
              {expectedValue} [{minExpectedValue}, {maxExpectedValue}]
            </Value>
          )}
        </TableValues>
        {!isTrend && (
          <TableValues>
            <TableValue>
              {labels.TABLE_CHART.KPI_VALUES_DIFFERENCE}:{" "}
            </TableValue>
            <Percent>{difference}</Percent>
          </TableValues>
        )}
      </TableValuesContainer>
      {!isTrend && (
        <WhyThisMatters>
          <b>{labels.COLLABORATE.WHY_THIS_MATTERS}: </b>

          {chartDescription ? (
            <TimeseriesChartDescription
              description={chartDescription}
              sx={{
                color: "secondary.main",
                fontSize: "1.1rem",
                display: "inline",
              }}
            />
          ) : (
            "NA"
          )}
        </WhyThisMatters>
      )}
    </HiglightsContainer>
  );
}

const HiglightsContainer = styled.div`
  ${PoppinsBoldBlack20px}
  display: flex;
  align-items: flex-start;
  gap: 79px;
  padding: 24px 0px;
  align-self: stretch;
`;

const TableValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const TableValues = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin-top: 3px;
`;

const TableValue = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldComet186px}
  min-width: fit-content;
  letter-spacing: 0;
  line-height: 1.1;
  white-space: nowrap;
`;

const Value = styled.div`
  font-size: 18px;
  color: cornflowerblue;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 1;
  white-space: nowrap;
`;

const Percent = styled.div`
  font-size: 18px;
  color: cornflowerblue;
  text-align: center;
  letter-spacing: 0.5px;
  white-space: nowrap;
`;

const WhyThisMatters = styled.div`
  flex: 1;
  margin-top: -1px;
  letter-spacing: 0;
  font-weight: 100;
  color: ${colors.comet};
  font-size: 1.1rem;
`;

export default Highlights;
