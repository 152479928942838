import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { LoaderInCenter } from "./styled-components";
import {
  ColoredBoldMetric,
  SemiBold,
} from "./styled-components/insight-summay/Title.styled";
import { useImpactCardStyles } from "./styled-components/ImpactCard.styled";
import { getImpactDataForGroup } from "../../actions/impactActions";
import { HTTP_STATUS } from "../../shared/enums/http-status";
import { calculateTimeInMinutes } from "../../utils/dateUtils";
import { Message } from "./ui-components/Message";

export const ImpactTable = ({ accent, groupId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();

  // fetch Impact data from the backend
  useEffect(() => {
    let isMountedRef = true;
    dispatch(getImpactDataForGroup(groupId))
      .then((result) => {
        if (isMountedRef) {
          setTableData(result);
          setLoading(false);
        }
      })
      .catch((error) => {
        setError(error);
        if (error?.status === HTTP_STATUS.UNAUTHORIZED) {
          navigate("/login");
        }
      });

    return () => (isMountedRef = false);
    // navigate excluded from deps array because it changes on every route change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, groupId]);

  return (
    <>
      {loading ? (
        <LoaderInCenter />
      ) : (
        <Paper sx={useImpactCardStyles.root}>
          <Table>
            <TableHead data-snippyly-comment-disabled>
              <TableRow sx={useImpactCardStyles.headerTableRow}>
                <TableCell sx={useImpactCardStyles.headerTableCell}>
                  Impact Category
                </TableCell>
                <TableCell
                  sx={useImpactCardStyles.headerTableCell}
                  align="center"
                >
                  Metric Name
                </TableCell>
                <TableCell
                  sx={useImpactCardStyles.headerTableCell}
                  align="center"
                >
                  Metric Value
                </TableCell>
              </TableRow>
            </TableHead>
            {error ? (
              <TableBody>
                <TableRow>
                  <TableCell colspan="3">
                    <Message>
                      There was some error while fetching the records for Impact
                    </Message>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {tableData.map((item) => (
                  <React.Fragment key={item.type}>
                    <TableRow>
                      <TableCell
                        sx={{
                          ...useImpactCardStyles.tableCell,
                          fontWeight: "600",
                        }}
                        rowSpan={item.data.length + 1}
                      >
                        {item.name}
                      </TableCell>
                    </TableRow>
                    {item.data.map((element) => (
                      <React.Fragment key={Math.random(0, 1)}>
                        <TableRow>
                          <TableCell sx={useImpactCardStyles.tableCell}>
                            {element.name}
                          </TableCell>
                          <TableCell sx={useImpactCardStyles.tableCell}>
                            {element.unit === "ms" ? (
                              <SemiBold>
                                {calculateTimeInMinutes(element.value)}
                              </SemiBold>
                            ) : (
                              <ColoredBoldMetric
                                accent={element.formatted_value ? accent : null}
                              >
                                {element.formatted_value || "-"}
                              </ColoredBoldMetric>
                            )}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>
        </Paper>
      )}
    </>
  );
};
