import { SHOW_MODAL, HIDE_MODAL } from "../constants/modalConstants";

const initialState = {
  modalName: null,
  modalProps: {},
};

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        modalName: action.payload.modalType,
        modalProps: action.payload.modalProps,
        showCloseButton: action.payload.showCloseButton,
      };
    case HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};
