import { Box, Stack, Typography, TextField, Divider } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileDetails } from "../../actions/preferenceActions";
import { getUserProfileDetails } from "../../actions/userActions";
import { typography } from "../../shared/theme-constants";
import PhoneNumberInput from "../common/PhoneNumberInput";
import { StyledActionButton } from "../common/mui-wrapper-components/Button";
import StyledTabs from "../common/mui-wrapper-components/StyledTabs";
import { Loading } from "../common/styled-components";
import { SET_SNACKBAR } from "../../../src/reducers/notificationSnackbarReducer";
import { HTTP_STATUS } from "../../shared/enums/http-status";
import { useNavigate } from "react-router-dom";

const MY_PROFILE_TABS = [{ value: "my-profile", label: "Profile" }];

const Profile = () => {
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState();
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState();
  const [currentName, setCurrentName] = useState();
  const [updatedName, setUpdatedName] = useState();
  const [userEmail, setUserEmail] = useState();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading: updatingProfileDetails, updatedProfileDetails } = useSelector(
    (state) => state.updateProfileDetails
  );

  useEffect(() => {
    let isMounted = true;
    dispatch(getUserProfileDetails())
      .then((response) => {
        if (isMounted && response) {
          setCurrentPhoneNumber(response?.data?.phone_number);
          setUpdatedPhoneNumber(response?.data?.phone_number);
          setUserEmail(response?.data?.user_email);
          setCurrentName(`${response?.data?.given_name} ${response?.data?.family_name}`);
          setUpdatedName(`${response?.data?.given_name} ${response?.data?.family_name}`);
          setLoading(false);
        }
      })
      .catch(error=>{
        if (error?.status === HTTP_STATUS.UNAUTHORIZED) {
          navigate("/login");
        }
      });
    return () => (isMounted = false);
  }, [dispatch, navigate]);

  const saveProfileDetails = () => {
    if ((updatedPhoneNumber === '' || !updatedPhoneNumber) || (updatedName === '' || !updatedName)) {
      return dispatch({
        type: SET_SNACKBAR,
        snackbarOpen: true,
        snackbarMessage: "Please enter both name and number.",
        snackbarType: "error",
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    }
    const fullName = updatedName.trim().replace(/\s+/g, ' ');
    let firstName;
    let lastName;
    const nameParts = fullName.split(' ');
    firstName = nameParts[0];
    lastName = nameParts.slice(1).join(' ');
    const postData = {
      phone_number: updatedPhoneNumber,
      given_name: firstName,
      family_name: lastName,
    };
    dispatch(updateProfileDetails(postData));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Stack
        direction="column"
        gap={10}
        sx={{
          height: "85vh",
          alignItems: "flex-start",
          pl: 8,
          pr: 8,
          overflowY: "scroll",
        }}
      >
        <Box>
          <StyledTabs
            sx={{ padding: "20px 0" }}
            value={MY_PROFILE_TABS[0].value}
            options={MY_PROFILE_TABS}
          />
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(13),
              fontWeight: typography.font_weight.regular,
            })}
          >
            Tell us about yourself - and how we can better assist you!
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            width: "100%",
          })}
        >
          <Stack direction="column" gap={5} sx={{ width: "100%" }}>
            <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Stack direction="column" gap={1} sx={{ alignItems: "flex-start" }}>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(16),
                    fontWeight: typography.font_weight.semiBold,
                  })}
                >
                  Name
                </Typography>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(13),
                    fontWeight: typography.font_weight.light,
                  })}
                >
                  Please state your full name
                </Typography>
              </Stack>
              <TextField
                type="text"
                sx={{
                  width: "20vw",
                }}
                size="small"
                value={updatedName}
                onChange={(event) => {
                  const input = event.target.value;
                  // Check if input contains any numbers
                  if (!/\d/.test(input)) {
                    setUpdatedName(input);
                  }
                }
                }
              />
            </Stack>
            <Divider orientation="horizontal" variant="middle" flexItem />
            <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Stack direction="column" gap={1} sx={{ alignItems: "flex-start" }}>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(16),
                    fontWeight: typography.font_weight.semiBold,
                  })}
                >
                  Phone Number
                </Typography>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(13),
                    fontWeight: typography.font_weight.light,
                  })}
                >
                  Used to send you SMS alerts
                </Typography>
              </Stack>
              <Box sx={{ width: "20vw" }}>
                <PhoneNumberInput
                  countryCodeEditable={false}
                  onChange={(value, data, event, formattedValue) =>
                    setUpdatedPhoneNumber(`+${value}`)
                  }
                  value={updatedPhoneNumber}
                  dropdownStyle={{ display: "flex", flexDirection: "column", alignItems: "flex-start", paddingLeft: 2 }}
                  inputStyle={{ width: '20vw' }}
                />
              </Box>
            </Stack>
            <Divider orientation="horizontal" variant="middle" flexItem />
            <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Stack direction="column" gap={1} sx={{ alignItems: "flex-start" }}>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(16),
                    fontWeight: typography.font_weight.semiBold,
                  })}
                >
                  Email
                </Typography>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(13),
                    fontWeight: typography.font_weight.light,
                  })}
                >
                  For relevant messages and authentication
                </Typography>
              </Stack>
              <TextField
                type="email"
                sx={{
                  width: "20vw",
                }}
                size="small"
                value={userEmail}
                disabled
              />
            </Stack>
            <Divider orientation="horizontal" variant="middle" flexItem />
          </Stack>
        </Box>
      </Stack>
      <Stack
        direction="row"
        gap={3}
        sx={{
          height: "15vh",
          justifyContent: "flex-start",
          alignItems: "center",
          pl: 8,
          pr: 8,
        }}
      >
        <StyledActionButton
          onClick={saveProfileDetails}
          loading={updatingProfileDetails}
          disabled={updatingProfileDetails || (_.isEqual(updatedPhoneNumber, currentPhoneNumber) && _.isEqual(updatedName, currentName))}
        >
          Save Changes
        </StyledActionButton>
        <StyledActionButton
          style={{
            background: "var(--white)",
            color: "var(--comet)",
            borderColor: "var(--comet)",
          }}
          disabled={updatingProfileDetails}
          onClick={() => {
            setUpdatedPhoneNumber(currentPhoneNumber);
            setUpdatedName(currentName);
          }}
        >
          Discard
        </StyledActionButton>
      </Stack>
    </>
  );
};

export default Profile;
