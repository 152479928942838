import React, { forwardRef } from "react";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";

export const MuiPopper = forwardRef(
  ({ placement = "top-start", children }, ref) => {
    const open = Boolean(ref);

    return (
      <Popper
        sx={{
          position: "fixed",
          boxShadow: "20px -20px 20px 0px rgba(0, 0, 0, 0.04)",
        }}
        anchorEl={ref}
        open={open}
        placement={placement}
        disablePortal={true}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={500}>
            <Paper sx={{ borderRadius: "10px" }}>{children}</Paper>
          </Fade>
        )}
      </Popper>
    );
  }
);

MuiPopper.displayName = "MuiPopper";
