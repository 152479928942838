import React from "react";

import * as Plotly from "plotly.js";
// customizable method: use your own `Plotly` object
import createPlotlyComponent from "react-plotly.js/factory";
import { colors } from "../../shared/theme-constants";
import { errorLayout } from "../../shared/plotly-config/spikes-config";
import {
  errorChartConfig,
  styles,
} from "../../shared/plotly-config/common-config";
import { merge } from "../../utils/objects";

const Plot = createPlotlyComponent(Plotly);

/**
 * Dummy chart to show error message with annotation
 */
export const ErrorChartPlot = ({ message }) => {
  const data = [{ x: [], y: [] }];
  const errorChartLayout = merge(errorLayout, {
    // Annotation text for displaying the error message
    annotations: [
      {
        text: `<b>${message}</b>`,
      },
    ],
  });
  return (
    <Plot
      data={data}
      layout={errorChartLayout}
      config={errorChartConfig}
      style={styles}
    />
  );
};

function ChartPlot({
  onRelayout,
  data,
  layout,
  config,
  style,
  handleUpdate,
  xRange,
  yRange,
  handleDoubleClick,
}) {
  // combine x and y range with layout from props
  let xaxis = { ...layout.xaxis, range: xRange };
  let yaxis = { ...layout.yaxis, range: yRange };
  const layoutWithRanges = { ...layout, xaxis: xaxis, yaxis: yaxis };

  return (
    <Plot
      data={data}
      config={config}
      layout={layoutWithRanges}
      useResizeHandler={true}
      style={style}
      onRelayout={onRelayout}
      onDoubleClick={handleDoubleClick}
      onUpdate={handleUpdate}
    />
  );
}

export default ChartPlot;
