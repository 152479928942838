import styled from "@emotion/styled";
import { Box, Stack, TableRow, Tooltip, Typography, tableRowClasses, tableCellClasses } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useAxios } from "../../../hooks/useAxios";
import { prepareParams } from "../../../hooks/useSpikes";
import { colors, typography } from "../../../shared/theme-constants";
import Icon from "../../common/Icon";
import { LightTooltip } from "../../common/mui-wrapper-components/LightTooltip";
import { GroupContext } from "./GroupedInsight";
import SparkLineChart from "./SparkLineChart";
import { useSelector } from "react-redux";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const MetricTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  color: "var(--comet)",
  [`&.${tableRowClasses.selected}`]: {
    "&.Mui-selected": {
      height: "4.25rem",
      border: "none",
      backgroundColor: "#F5F8FF",
      fontWeight: "bold",
      borderRadius: "0.625rem",
      color: "var(--new-car)",
    },
  },
  [`&.${tableRowClasses.hover}`]: {
    "&.Mui-hover": {
      backgroundColor: "#E3E3E333",
      borderRadius: "0.625rem",
    },
  },
}));

const TableCellWithToolTip = ({ cellValue, ...delegatedProps }) => {
  const cellRef = useRef();
  const [showCellTooltip, setShowcellTooltip] = useState(false);
  const toggleToolTipState = (e) => {
    setShowcellTooltip(cellRef.current.scrollWidth > cellRef.current.clientWidth);
  };
  if (!cellValue) return <></>;
  return (
    <Tooltip title={cellValue} open={showCellTooltip}>
      <Typography {...delegatedProps} onMouseEnter={toggleToolTipState} onMouseLeave={() => setShowcellTooltip(false)} ref={cellRef} noWrap>
        {cellValue}
      </Typography>
    </Tooltip>
  );
};
const MetricTableData = ({ insightId, group, insightID }) => {
  const setInsightId = useContext(GroupContext);
  const [patternSeriesData, setPatternSeriesData] = useState();
  const tenantGlobalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);
  const tenantConfigs = useSelector((state) => state.tenantConfigs.configs);

  const { data: insightResponse, isSuccess, isLoading } = useAxios(`insights/${insightId}`, {
    params: {
      dim_name: tenantGlobalFilter?.dimension_name,
      dim_val: tenantGlobalFilter?.dimension_value,
    },
  });
  const callPattern = (pattern) => {
    const params = prepareParams(pattern, null, null, tenantConfigs?.is_forecast_enabled);
    axios.get(BASE_URL.concat("pattern_series"), { params }).then((timeseries) => {
      setPatternSeriesData(timeseries.data);
    });
  };

  useEffect(() => {
    if (isSuccess) {
      callPattern(insightResponse);
    }
  }, [insightResponse, isSuccess]);

  return (
    isSuccess && (
      <MetricTableRow hover={true} selected={insightID == insightId} spacing={1} onClick={() => setInsightId(insightId)}>
        <TableCell component="th" scope="row" sx={{ pl: 0, border: "none", marginBottom: "0.5rem", maxWidth: "15rem" }}>
          <TableCellWithToolTip cellValue={insightResponse.kpi_display_name} />
        </TableCell>
        <TableCell component="th" scope="row" sx={{ pl: 0, border: "none", marginBottom: "0.5rem", maxWidth: "15rem" }}>
          <TableCellWithToolTip cellValue={group.item?.dim_name ? `${group.item?.dim_name}: ${group.item?.dim_val}` : null} />
        </TableCell>
        <TableCell component="th" scope="row" sx={{ pl: 0, border: "none", marginBottom: "0.5rem", maxWidth: "15rem" }}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "5%",
              paddingInline: "0",
            }}
          >
            {patternSeriesData && <SparkLineChart color={insightResponse.accent} patternSeriesData={patternSeriesData} kpiFormat={insightResponse?.kpi_format} />}
          </Stack>
        </TableCell>
        <TableCell component="th" scope="row" sx={{ border: "none", marginBottom: "0.5rem", maxWidth: "15rem" }}>
          <Stack
            direction="row"
            spacing={0.3}
            sx={{
              backgroundColor: insightResponse?.accent == "positive" ? colors.green[150] : colors.red[150],
              justifyContent: "center",
              width: "4.5rem",
              height: "2rem",
              paddingInline: "1rem",
              borderRadius: "0.3125rem",
              alignItems: "center",
            }}
          >
            <Box sx={{ marginInline: "0.3rem" }}>
              <Icon
                name={`arrow-${insightResponse?.accent}-${insightResponse?.pattern_direction}`}
                title={`arrow-${insightResponse?.accent}-${insightResponse?.pattern_direction}`}
                size="12px"
                showcursor="true"
              />
            </Box>
            <LightTooltip
              title={
                <div>
                  <span style={{ fontWeight: typography.font_weight.bold }}>
                    ${insightResponse?.formatted_current_value}
                  </span>
                  <span style={{ fontWeight: typography.font_weight.regular }}>
                    {`, ${insightResponse?.formatted_lift_percentage?.charAt(0) === '-' ? insightResponse?.formatted_lift_percentage?.substring(1) : insightResponse?.formatted_lift_percentage}
                    ${insightResponse?.pattern_direction == "up" ? "over" : "below"}
                    ${insightResponse?.formatted_expected_value} forecast`}
                  </span>
                </div>
              }
              placement="top"
              fontSize={11}
            >
              <Typography
                sx={{
                  color: insightResponse?.accent == "positive" ? colors.SEVERITY.GREEN.medium : colors.red[450],
                  fontWeight: typography.font_weight.mediumx,
                }}
              >
                {insightResponse?.formatted_lift_percentage?.charAt(0) === '-' ? insightResponse?.formatted_lift_percentage?.substring(1) : insightResponse?.formatted_lift_percentage}
              </Typography>
            </LightTooltip>
          </Stack>
        </TableCell>
      </MetricTableRow>
    )
  );
};

export default MetricTableData;
