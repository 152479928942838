import React from "react";
import { styled } from "@mui/material/styles";

import { colors } from "../../../shared/theme-constants";

// Placeholder for the carousel styled component, currently has the css styles for navigation icons only

export const SlideIndicator = styled("li")({
  backgroundColor: colors.blue[200],
  width: 8,
  height: 8,
  borderRadius: 4,
  display: "inline-block",
  margin: "0 8px",
  cursor: "pointer",
});

export const NavigateSlideButton = styled(
  React.forwardRef(function SlideNavigator(props, ref) {
    return (
      <button
        data-snippyly-comment-disable
        type="button"
        {...props}
        ref={ref}
      />
    );
  })
)({
  position: "absolute",
  zIndex: 1,
  top: "50%",
  transform: "translate(var(--translateX), -50%)",
  cursor: "pointer",
  boxShadow: "0px 4px 14px 0px rgba(17, 39, 133, 0.11)",
  backgroundColor: colors.white,
  color: colors.cadet_gray,
  border: 0,
  borderRadius: "50em",
  padding: 4,
  lineHeight: 0,
});
