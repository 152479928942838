import styled from "@emotion/styled";
import { Stack, ListItemButton, listItemButtonClasses } from "@mui/material";
import React, { useState } from "react";
import { typography } from "../../../../shared/theme-constants";
import Icon from "../../Icon";

const ListButton = styled(ListItemButton)(({ theme, selected }) => ({
  [`&.${listItemButtonClasses.root}`]: {
    borderRadius: "4px",
    minWidth: "100px",
    border: "1.547px solid var(--comet)",
    height: 35,
    maxHeight: 35,
    background: "var(--zircon)",
    color: "var(--comet)",
    fontSize: typography.font_size.sm,
    fontWeight: typography.font_weight.mediumx,
  },
  [`&.${listItemButtonClasses.selected}`]: {
    "&.Mui-selected": {
      border: "1.5px solid",
      color: theme.palette.primary.main,
      background: "#DDD",
    },
  },
}));

const StyledCheckBox = ({ options, onClick, children, ...delegatedprops }) => {
  const [selectedCheckBox, setSelectedCheckBox] = useState([]);
  const handleClick = (option) => {
    if (selectedCheckBox.includes(option.value)) {
      let newSelection = selectedCheckBox.filter((value) => value !== option.value);
      setSelectedCheckBox(newSelection);
    } else {
      let newSelection = [...selectedCheckBox, option.value];
      setSelectedCheckBox(newSelection);
    }

    if (typeof onClick === "function") {
      onClick(option.value);
    }
  };
  return (
    <Stack direction="row" gap={3}>
      {options.map((option) => (
        <Stack direction="column" key={option.value} gap={1}>
          <ListButton
            onClick={() => handleClick(option)}
            selected={option.isDefault || selectedCheckBox.includes(option.value)}
            sx={{ display: "flex", justifyContent: "center" }}
            key={option.value}
            component="button"
            {...delegatedprops}
          >
            <Stack gap={1} direction="row" sx={{ alignItems: "center" }}>
              <Icon name={option.value}></Icon>
              {option.label}
            </Stack>
          </ListButton>
          <div style={{ display: "flex", justifyContent: "center", fontSize: 9, color: "var(--comet)", alignSelf: "center", textAlign: "center", width: "70%" }}>
            {option.description}
          </div>
        </Stack>
      ))}
    </Stack>
  );
};

export default StyledCheckBox;
