import { Avatar, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export const metricOwnerAvatar = {
  background: "#F3F5F6",
  width: "70px",
  height: "70px",
  boxShadow: "",
};

const Owners = ({ ownerInfo, error }) => {
  return (
    <Box>
      <Stack direction="row">
        {ownerInfo?.length &&
          ownerInfo.map((info, index) => (
            <div key={index}>
              <Stack direction="column">
                <div>
                  <Avatar sx={metricOwnerAvatar} src={info.profile_img} />
                  {error && <Avatar src="public/images/fallback-avatar.png" />}
                </div>
                <div>
                  {/* TODO: Read actual keys from the response */}
                  <Typography>{info?.type}</Typography>
                  <Typography>{info?.name}</Typography>
                </div>
              </Stack>
            </div>
          ))}
      </Stack>
    </Box>
  );
};

export default Owners;
