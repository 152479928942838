import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import styled from "styled-components";

export const ConfirmationBoxStyles = {
  position: "fixed",
  width: "auto",
  flexGrow: 1,
  zIndex: 3,
  justifyContent: "flex-start",
};

export const ActionButton = withStyles({
  root: {
    height: "38px",
    width: "100px",
    background: "#FFFFFF",
    padding: "10px",
    boxShadow: "0px 6.08px 21.28px rgba(0, 0, 0, 0.15)",
    borderRadius: "6",
    color: "#767A88",
  },
  text: {
    fontSize: "0.9rem",
    justifyContent: "space-evenly",
    textTransform: "none",
    lineHeight: "12px",
    fontWeight: 700,
  },
  "@media screen and (max-width:480px)": {
    root: {
      height: "36px",
    },
  },
  "@media screen and (max-width:768px)": {
    root: {
      height: "36px",
    },
  },
})(Button);

export const StateActionLabel = styled.div`
  width: 106px;
  padding: 4px;
  margin-left: 2px;
  word-wrap: "no-wrap";
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  background: #eb4368;
  box-shadow: 0px 6.08px 21.28px rgba(0, 0, 0, 0.15);
  color: white;
  border-radius: 6px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 95px;
    font-size: 0.9rem;
  }
`;
