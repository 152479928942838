import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { Box, tableCellClasses } from "@mui/material";

import { Table } from "./mui-wrapper-components/Table";
import { InsightContext } from "../../context/InsightContext";
import { formatDateUtc } from "../../utils/dateUtils";
import { pipelineSchedule } from "./ChartOverlays";
import { LoaderInCenter } from "./styled-components";
import { nFormatter, titleCase } from "../../utils/stringUtils";
import { colors, typography } from "../../shared/theme-constants";
import { PERCENTAGE } from "../../constants/commonConstants";

const PREV_PERIOD_COLUMN_NAMES = {
  DoD: {
    current: "Today",
    period1: "Yesterday",
    period2: "2 days back",
  },
  WoW: {
    current: "This Week",
    period1: "Last week",
    period2: "2 weeks back",
  },
  MoM: {
    current: "This Month",
    period1: "Last month",
    period2: "2 months back",
  },
  YoY: {
    current: "This Year",
    period1: "Last year",
    period2: "2 years back",
  },
};
const COLOR_MAP = {
  up: "#E8F3F1",
  down: "#F5E6E9",
};

const StyledCurrentCell = styled("span")({
  color: colors.gray[500],
  fontSize: typography.font_size.base,
});

const ColoredCell = ({
  accent,
  currentY,
  previousY,
  displayVariant,
  isKpiFormatPercentage,
}) => {
  if (!previousY) return "-";
  // Compare current value with previous
  const diff = currentY - previousY;

  const absoluteValue = isKpiFormatPercentage
    ? `${(previousY * 100).toFixed(1)}%`
    : nFormatter(previousY, 1);
  // Since it is comparing current value in correlation with previous,
  // dividing it by previous value to derive the percentage change in difference
  const percent = isKpiFormatPercentage ? diff * 100 : (diff / previousY) * 100;
  const percentLiftValue = `${Math.abs(percent).toFixed(1)}%`;

  const isPositiveDirection =
    (diff > 0 && accent === "positive") || (diff < 0 && accent === "negative");
  const cellColorStyle =
    diff !== 0
      ? { backgroundColor: isPositiveDirection ? COLOR_MAP.up : COLOR_MAP.down }
      : {};

  return (
    <Box padding={4} style={cellColorStyle}>
      {displayVariant === "absolute" ? absoluteValue : percentLiftValue}
    </Box>
  );
};

function insertIf(condition, ...elements) {
  return condition ? elements : [];
}

const OverlaySeriesLiftTable = ({
  overlayType,
  tableChartData,
  displayVariant,
}) => {
  const { patternData, insightSummary } = useContext(InsightContext);
  const { schedule, accent } = insightSummary;
  const isKpiFormatPercentage = patternData.pattern.kpi_format === PERCENTAGE;

  const columns = [
    {
      field: "timestamp",
      headerName: "Date",
      valueFormatter: ({ value }) => formatDateUtc(value),
    },
    ...insertIf(schedule === pipelineSchedule.hourly, {
      field: "timestamp",
      headerName: "Time",
      valueFormatter: ({ value }) => formatDateUtc(value, "hh:mm a"),
    }),
    {
      field: "current_y",
      headerName: PREV_PERIOD_COLUMN_NAMES[overlayType].current,
      renderCell: ({ formattedValue }) => {
        const content = isKpiFormatPercentage
          ? `${(formattedValue * 100).toFixed(1)}%`
          : nFormatter(formattedValue, 1);

        return <StyledCurrentCell>{content}</StyledCurrentCell>;
      },
    },
    {
      field: "period1_y",
      headerName: titleCase(PREV_PERIOD_COLUMN_NAMES[overlayType].period1),
      cellClassName: "!p-0",
      renderCell: ({ formattedValue, row }) => (
        <ColoredCell
          accent={accent}
          currentY={row.current_y}
          previousY={formattedValue}
          displayVariant={displayVariant}
          isKpiFormatPercentage={isKpiFormatPercentage}
        />
      ),
    },
    {
      field: "period2_y",
      headerName: titleCase(PREV_PERIOD_COLUMN_NAMES[overlayType].period2),
      cellClassName: "!p-0",
      renderCell: ({ formattedValue, row }) => (
        <ColoredCell
          accent={accent}
          currentY={row.current_y}
          previousY={formattedValue}
          displayVariant={displayVariant}
          isKpiFormatPercentage={isKpiFormatPercentage}
        />
      ),
    },
  ];

  if (!tableChartData) return <LoaderInCenter />;

  return (
    <Table
      columns={columns}
      rows={tableChartData}
      square
      elevation={0}
      sx={{
        margin: "20px 0 40px",
        width: "100%",
        maxHeight: 275,

        [`.${tableCellClasses.root}:not(:last-child)`]: {
          borderRight: "3px solid transparent",
        },
      }}
    />
  );
};

export default OverlaySeriesLiftTable;
