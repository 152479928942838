import styled from "@emotion/styled";
import {
  Box,
  CardActionArea,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  Stack,
  Tooltip,
  Typography,
  listClasses,
  popoverClasses,
} from "@mui/material";
import LowPriorityRoundedIcon from "@mui/icons-material/LowPriorityRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import pluralize from "pluralize";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { colors, typography } from "../../../shared/theme-constants";
import { titleCase } from "../../../utils/stringUtils";
import Icon from "../../common/Icon";
import { bp, theme } from "../../common/styled-components/theme";
import LiveIndicator from "../../common/LiveIndicator";
import LabelChip from "../../common/LabelChip";
import { severityColorMapping } from "../../../constants/commonConstants";

const severityIconMapping = {
  High: <PriorityHighRoundedIcon style={{ color: "var(--red-3)", fontSize: "1rem" }} />,
  Low: <LowPriorityRoundedIcon style={{ color: "var(--lime-green)", fontSize: "1rem" }} />,
  Medium: <WarningAmberRoundedIcon style={{ color: "var(--orange)", fontSize: "1rem" }} />,
};

const StyledChip = styled(Chip)(({ theme }) => ({
  minWidth: theme.typography.pxToRem(136),
  height: theme.typography.pxToRem(40),
  borderRadius: theme.typography.pxToRem(5),
}));
const StyledPopover = styled(Popover)({
  pointerEvents: "none",

  [`& .${popoverClasses.paper}`]: {
    width: "100%",
    maxWidth: "15rem",
    maxHeight: "18rem",
    padding: "0.5rem 1rem",
    overflow: "hidden", // Ensure no overflow

    [`& .${listClasses.root}`]: {
      maxHeight: "14rem",
      overflowY: "auto",
    },
  },
});

const tagStyle = {
  borderRadius: "0.25rem",
  textAlign: "center",
  paddingLeft: "0.625rem",
  paddingRight: "0.625rem",
  maxWidth: "100%",
  minWidth: "unset",
  height: "1.667vw",
  fontSize: "0.833vw",
};

const ChartHeaderBase = ({
  chartName,
  eventProvider,
  categoryDisplayName,
  providerDisplayName,
  children,
}) => (
  <Stack
    direction="column"
    sx={(theme) => ({
      minWidth: "20%",
      [bp.min("st")]: { gap: "20px" },
      [theme.breakpoints.down("lt")]: { minWidth: "50px" }, // Adjust for anything below laptop small screen
    })}
  >
    <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Tooltip title={categoryDisplayName} placement="top-end">
        <img style={{ width: "70px", height: "70px", objectFit: "contain" }} src={chartName} />
      </Tooltip>
      <Tooltip title={providerDisplayName} placement="bottom-end">
        <img style={{ width: "30px", height: "30px", objectFit: "contain" }} src={eventProvider} />
      </Tooltip>
    </Box>
  </Stack>
);

const generateOutageTitle = (providerDisplayName, severity) => {
  switch (severity) {
    case "High":
      return `${providerDisplayName} Major Incident`;
    case "Medium":
      return `${providerDisplayName} Partial Disruption`;
    case "Low":
      return `${providerDisplayName} Scheduled Maintenance`;
    default:
      return `${providerDisplayName} Incident`;
  }
};

function EventGroupCardHeader({
  providerLogo,
  eventStatus,
  eventCategory,
  categoryLogo,
  type,
  numberOfEvents,
  providerDisplayName,
  description,
  impact,
  kpis,
  event_group_id,
  isDescriptionSectionEnabled = true,
  severity,
  source = "listing",
  funnels = [],
  affected_components,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // function to hanlde hover  condition for list of metrics
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // function to hanlde hover away condition for list of metrics
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <CardActionArea
        disableRipple
        sx={(theme) => ({
          font: "inherit",
          height: "120px",
          [theme.breakpoints.down("lt")]: { height: "auto", padding: "1rem" },
          cursor: source === 'view' ? "default" : "pointer",
          "& .MuiCardActionArea-focusHighlight": { display: "none" },
        })}
        onClick={() =>
          navigate(`/events/groups/${event_group_id}`, {
            state: {
              isOverlay: true,
              backgroundLocation: location,
            },
          })
        }
      >
        <Box
          style={{ display: "flex", alignItems: "center", gap: 30 }}
          sx={(theme) => ({
            overflowX: "hidden",
            [theme.breakpoints.down("lt")]: { flexDirection: "column" }, // Stack vertically on mobile and tablet.
          })}
        >
          <Stack
            direction="row"
            gap={7}
            width={type === "outage" ? "70%" : "50%"}
            sx={(theme) => ({
              [theme.breakpoints.down("lt")]: { width: "100%" },
            })}
          >
            <ChartHeaderBase
              chartName={categoryLogo}
              eventProvider={providerLogo}
              providerDisplayName={providerDisplayName}
              categoryDisplayName={eventCategory}
            />
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down("lt")]: { width: "100%" },
                width: "80%",
              })}
            >
              <Stack direction="column" gap={1}>
                <Box
                  gap={2.5}
                  sx={(theme) => ({
                    height: theme.typography.pxToRem(25),
                    display:
                      eventStatus !== "Active" && !severity && type === "error" ? "none" : "flex",
                  })}
                >
                  {eventStatus === "Active" && (
                    <LiveIndicator
                      sx={{
                        fontSize: typography.font_size.base,
                        margin: "-7px",
                        paddingRight: "10px",
                      }}
                    />
                  )}
                  {severity ? (
                    <LabelChip
                      tags={[severity]}
                      style={{
                        backgroundColor: severityColorMapping[severity],
                        justifyContent: "space-evenly",
                      }}
                      icon={severityIconMapping[severity]}
                    />
                  ) : null}
                  <LabelChip
                    tags={[titleCase(type)]}
                    style={{
                      backgroundColor: type === "outage" ? colors.blue[100] : colors.pink[100],
                      justifyContent: "space-evenly",
                    }}
                  />
                </Box>
                <Typography
                  sx={(theme) => ({
                    fontSize: typography.font_size["2xl"],
                    [theme.breakpoints.down("sl")]: {
                      fontSize: typography.font_size.base,
                    },
                    fontWeight: typography.font_weight.mediumx,
                  })}
                >
                  {type === "outage"
                    ? generateOutageTitle(providerDisplayName, severity)
                    : `${numberOfEvents} ${eventCategory} ${pluralize(
                      titleCase(type || ""),
                      numberOfEvents
                    )}`}
                </Typography>
                {source === "view" && (
                  <Stack direction="column">
                    <Stack direction="row">
                      <StyledChip
                        label={providerDisplayName}
                        sx={(theme) => ({
                          [theme.breakpoints.down("lt")]: {
                            height: `${theme.typography.pxToRem(19.12)} !important`, // Increase height on smaller screens
                            fontSize: `${theme.typography.pxToRem(8)} !important`, // Increase font size on smaller screens
                          },
                        })}
                        style={{
                          background: "rgba(0, 0, 91, 1)",
                          color: "var(--white)",
                          ...tagStyle,
                        }}
                        className="provider-tag"
                      />
                      <StyledChip
                        label="Site Monitoring"
                        sx={(theme) => ({
                          [theme.breakpoints.down("lt")]: {
                            height: `${theme.typography.pxToRem(19.12)} !important`, // Increase height on smaller screens
                            fontSize: `${theme.typography.pxToRem(8)} !important`, // Increase font size on smaller screens
                          },
                        })}
                        style={{
                          background: "rgba(232, 138, 138, 1)",
                          color: "var(--white)",
                          marginLeft: "1.5rem",
                          ...tagStyle,
                        }}
                        className="provider-tag"
                      />
                    </Stack>
                    {Array.isArray(funnels) && funnels?.length > 0 && (
                      <Stack direction="row">
                        <StyledChip
                          label={funnels[0]}
                          sx={(theme) => ({
                            [theme.breakpoints.down("lt")]: {
                              height: `${theme.typography.pxToRem(19.12)} !important`, // Increase height on smaller screens
                              fontSize: `${theme.typography.pxToRem(8)} !important`, // Increase font size on smaller screens
                            },
                          })}
                          style={{
                            background: "rgba(233, 243, 255, 1)",
                            color: "rgba(99, 104, 124, 1)",
                            marginTop: "1rem",
                            ...tagStyle,
                          }}
                          className="provider-tag"
                        />
                      </Stack>
                    )}
                  </Stack>
                )}
                {isDescriptionSectionEnabled && (
                  <>
                    <Typography
                      sx={(theme) => ({
                        fontSize: theme.typography.pxToRem(12),
                        [theme.breakpoints.down("tm")]: {
                          fontSize: theme.typography.pxToRem(10),
                        },
                        fontWeight: 300,
                      })}
                    >
                      {type === "error"
                        ? description
                        : affected_components?.length > 0
                          ? <span><span style={{ fontWeight: 450 }}>Affected Components:</span> {affected_components?.slice(0, 3).join(", ")}</span>
                          : ""}
                    </Typography>
                    {affected_components?.length > 3 && (
                      <>
                        <Typography
                          sx={(theme) => ({
                            fontSize: theme.typography.pxToRem(12),
                            [theme.breakpoints.down("tm")]: {
                              fontSize: theme.typography.pxToRem(10),
                            },
                            fontWeight: 300,
                            ":hover": { cursor: "pointer" },
                            textDecoration: "underline",
                            textDecorationThickness: "0.5px",
                          })}
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                        >
                          {type === "outage" ? affected_components?.length > 3 ? `+ ${affected_components?.length - 3} more` : "" : ""}
                        </Typography>
                        <StyledPopover
                          id="affected-components-popover"
                          sx={(theme) => ({
                            pointerEvents: "none",
                            minWidth: 300,
                            fontSize: theme.typography.pxToRem(12),
                            fontWeight: typography.font_weight.light,
                          })}
                          open={open}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          <List>
                            {affected_components?.slice(3).map((affected_component) => (
                              <ListItem key={affected_component} disableGutters disablePadding>
                                <ListItemText primary={affected_component} />
                              </ListItem>
                            ))}
                          </List>
                        </StyledPopover>
                      </>
                    )}
                  </>
                )}
              </Stack>
            </Box>
          </Stack>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={(theme) => ({
              [theme.breakpoints.down("lt")]: {
                display: "none",
              },
            })}
          />
          <Stack
            direction="row"
            width={type === "outage" ? "30%" : "50%"}
            justifyContent="space-between"
            sx={(theme) => ({
              [theme.breakpoints.down("lt")]: { width: "100%", flexDirection: "row" },
            })}
          >
            <Stack
              direction="column"
              sx={(theme) => ({
                textAlign: "center",
                [theme.breakpoints.down("sl")]: { gap: 4 },
              })}
            >
              {kpis?.length > 0 && (
                <>
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(12),
                      fontWeight: typography.font_weight.regular,
                      color: "rgba(99, 104, 124, 1)",
                    })}
                  >
                    Metrics Impacted
                  </Typography>
                  <Typography
                    sx={(theme) => ({
                      [theme.breakpoints.down("sl")]: { fontSize: theme.typography.pxToRem(20) },
                      fontSize: theme.typography.pxToRem(35),
                      fontWeight: typography.font_weight.semiBold,
                      color: "var(--mariner)",
                    })}
                  >
                    {kpis[0].kpi_display_name}
                  </Typography>
                </>
              )}
              {kpis?.length > 1 && (
                <>
                  <Typography
                    sx={{
                      ":hover": { cursor: "pointer" },
                      textDecoration: "underline",
                      textDecorationThickness: "0.5px",
                    }}
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    + {kpis.length - 1} metrics
                  </Typography>
                  <StyledPopover
                    id="affected-metrics-popover"
                    sx={{
                      pointerEvents: "none",
                      minWidth: 200,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <List>
                      {kpis.slice(1).map((kpi) => (
                        <ListItem key={kpi.kpi_id} disableGutters disablePadding>
                          <ListItemText primary={kpi.kpi_display_name} />
                        </ListItem>
                      ))}
                    </List>
                  </StyledPopover>
                </>
              )}
            </Stack>
            {impact?.monetary && (
              <Stack
                direction="column"
                gap={2}
                sx={(theme) => ({
                  textAlign: "center",
                  [theme.breakpoints.down("lt")]: { justifyContent: "space-between" },
                })}
              >
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.regular,
                    color: "rgba(99, 104, 124, 1)",
                  })}
                >
                  Dollar Impact
                </Typography>
                <StyledChip
                  sx={{
                    pl: 3,
                    background: impact.monetary.value > 0 ? colors.green["150"] : colors.red["150"],
                  }}
                  icon={
                    <Icon
                      name={impact.monetary.value > 0 ? "arrow-positive-up" : "arrow-negative-down"}
                      size="16px"
                    />
                  }
                  label={
                    <Typography
                      sx={(theme) => ({
                        [theme.breakpoints.down("lt")]: {
                          fontSize: theme.typography.pxToRem(20),
                        },
                        fontSize: theme.typography.pxToRem(29),
                        fontWeight: typography.font_weight.mediumx,
                        color:
                          impact.monetary.value > 0 ? colors.SEVERITY.GREEN.medium : "var(--red-3)",
                      })}
                    >
                      {impact.monetary.formatted_value}
                    </Typography>
                  }
                />
                {impact?.rate && (
                  <Stack direction="row" gap={1} alignItems="center">
                    <img
                      style={{ width: "15px", height: "15px" }}
                      src={`/images/${impact?.monetary?.value > 0
                        ? "stop-watch-positive.png"
                        : "stop-watch-negative.png"
                        }`}
                    />
                    <Typography
                      sx={(theme) => ({
                        [theme.breakpoints.down("lt")]: {
                          fontSize: theme.typography.pxToRem(12),
                        },
                        fontSize: theme.typography.pxToRem(15),
                        fontWeight: typography.font_weight.regular,
                        color:
                          impact?.monetary?.value > 0
                            ? colors.SEVERITY.GREEN.medium
                            : "var(--red-3)",
                      })}
                    >
                      {impact.rate.formatted_value}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </Box>
      </CardActionArea>
    </>
  );
}

export default EventGroupCardHeader;
