import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE } from "../../../constants/notificationConstants";
import { NotificationMessage } from "../styled-components/NotificationMessage.styled";
const Notification = () => {
  const dispatch = useDispatch();
  /**
   * these represent the commone properties about the notification component
   *  1. placement of the notification on the screen.
   *  2. whether the component is open or not
   */
  const [toastState, setToastState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { open, horizontal, vertical } = toastState;

  /**
   * this function will close the  notification message whenever called
   */
  const handleClose = () => {
    dispatch({ type: CLOSE });
    setToastState({ ...toastState, open: false });
  };

  const showNotification = useSelector((state) => state.notification);

  useEffect(() => {
    if (showNotification.message) {
      setToastState((prevState) => ({ ...prevState, open: true }));
    }
  }, [showNotification]);

  return open ? (
    ReactDOM.createPortal(
      <NotificationMessage
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
        message={showNotification.message}
        autoHideDuration={1200}
      />,
      document.body
    )
  ) : (
    <></>
  );
};

export default Notification;
