import styled from "@emotion/styled";
import { Box, Card, Stack, Typography } from "@mui/material";
import pluralize from "pluralize";
import React from "react";
import { titleCase } from "../../utils/stringUtils";
import EventDollarImpactChip from "../common/EventDollarImpactChip";
import { useNavigate } from "react-router-dom";

const StyledEventSummaryCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "0px 2px 15px 0px #11278529",
  minHeight: theme.typography.pxToRem(140),
  ":hover": {
    cursor: "pointer",
    transform: "scale3d(1.05,1.05,1.05)",
    transition: "transform 0.2s ease-out",
  },
}));
const EventSummaryCard = ({ type, name, display_name, logo_url, count, impact }) => {
  const navigate = useNavigate();
  return (
    <StyledEventSummaryCard onClick={() => navigate('/events')}>
      <Stack direction="row" gap={10} alignItems="center">
        <Box>
          <img style={{ width: "60px", height: "60px", objectFit: "contain" }} src={logo_url} />
        </Box>
        <Stack direction="column" gap={3}>
          <Typography>
            {`${count} ${display_name} ${pluralize(titleCase(type || ""), count)}`}
          </Typography>
          <Box
            sx={(theme) => ({
              height: "4vh",
              maxWidth: "8vw",
            })}
          >
            <EventDollarImpactChip impactData={impact}/>
          </Box>
        </Stack>
      </Stack>
    </StyledEventSummaryCard>
  );
};

export default EventSummaryCard;
