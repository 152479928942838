import { styled } from "@mui/material/styles";
import { Paper, TableRow, TableCell as MUITableCell } from "@mui/material";
import { colors, typography } from "../../../shared/theme-constants";

const textTruncate = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Container = styled(Paper)(
  ({ theme, elevation = 1, square = false }) => ({
    width: "98%",
    height: "100%",
    maxHeight: 400,
    overflow: "auto",
    backgroundColor: colors.white,
    boxShadow: elevation !== 0 && "0px 4px 19px rgba(0, 0, 0, 0.14)",
    borderRadius: !square && 13,
    margin: theme.spacing(10, "auto"),
  })
);

export const HeaderTableRow = styled(TableRow)`
  background-color: #fff;
`;

const BaseTableCell = styled(MUITableCell)`
  ${textTruncate};
  max-width: 175px;
  text-align: center;
  border-bottom: none;
  color: ${colors.title};
`;

export const HeaderTableCell = styled(BaseTableCell)`
  font-size: ${typography.font_size.base}px;
  font-weight: ${typography.font_weight.semiBold};
  box-shadow: 0 1px 0 ${colors.gray[100]};
`;

export const TableCell = styled(BaseTableCell)`
  font-size: ${typography.font_size.sm}px;
  min-width: 110px;
`;

const pinnedCell = `
  position: sticky;
  background-color: #fff;
`;

const PinnedCell = styled(TableCell)`
  ${pinnedCell};
  z-index: 2;
`;

const PinnedHeaderCell = styled(HeaderTableCell)`
  ${pinnedCell};
  z-index: 3;
`;

const firstPinnedCell = `
  left: 0;
  max-width: 220px;
  text-overflow: initial;
  border-right: 1px solid ${colors.gray[100]};
`;

export const FirstPinnedHeaderCell = styled(PinnedHeaderCell)`
  ${firstPinnedCell}
`;

export const FirstPinnedCell = styled(PinnedCell)`
  ${firstPinnedCell};
  text-align: left;
`;

const lastPinnedCell = `
  right: 0;
  min-width: 100px;
  border-left: 1px solid ${colors.gray[100]};
`;

export const LastPinnedHeaderCell = styled(PinnedHeaderCell)`
  ${lastPinnedCell}
`;

export const LastPinnedCell = styled(PinnedCell)`
  ${lastPinnedCell}
`;
