import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";

export const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    position: absolute;
    top: -10px;
    left: 30px;
  }
`;

export const StyledTooltipHeader = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    position: absolute;
    top: -20px;
    left: -35px;
    width: max-content;
  }
`;

export const StyledTooltipExpand = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    position: absolute;
    top: -25px;
    left: -10px;
  }
`;

export const StyledTooltipChart = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    position: absolute;
    top: 10px;
    right: -80px;
    width: max-content;
    max-width: 110px;
  }
`;

export const StyledTooltipLink = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    position: absolute;
    width: 140px;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 153px;
      font-size: 11px;
    }
  }
`;
