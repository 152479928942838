import React from "react";
import { Container, Grid, Stack } from "@mui/material";

import {
  MetricAttributeHeader,
  MetricAttributeValue,
} from "../../common/styled-components/metric/MetricDetails.styled";
import { bp } from "../../common/styled-components/theme";
import { get } from "../../../utils/objects";
import { isFunction, isObject } from "../../../utils/is";

function getLabelForValue(options, value) {
  const selectedOption = options.find((option) => {
    // when options have both value and label, it's an array of objects
    // otherwise, it's an array of primitive values e.g. [1, 2, 3]
    const valueToCompare = isObject(option) ? option.value : option;

    return String(valueToCompare) === String(value);
  });

  if (selectedOption) {
    return isObject(selectedOption) ? selectedOption.label : selectedOption;
  }

  return value;
}

const MetricAttributes = ({ attributes, metricDetails }) => {
  const getDisplayValueMarkup = (tabAttribute, metricDetails) => {
    const value = get(metricDetails, tabAttribute.name) || "";

    let formattedValue = value;
    if (tabAttribute.options) {
      formattedValue = getLabelForValue(tabAttribute.options, value);
    }

    if (isFunction(tabAttribute.formatValue)) {
      formattedValue = tabAttribute.formatValue(value, metricDetails);
    }

    if (isFunction(tabAttribute.renderValue)) {
      formattedValue = tabAttribute.renderValue({ value, formattedValue });
    }

    return (
      <>
        {tabAttribute.prefix ? tabAttribute.prefix : null}
        {formattedValue}
        {tabAttribute.suffix ? tabAttribute.suffix : null}
      </>
    );
  };

  return (
    <Container
      maxWidth="xll"
      sx={{
        overflow: "hidden",
        [bp.min("1200px")]: {
          padding: 0,
        },
      }}
    >
      <Grid container spacing={5}>
        {attributes.map((baseAttribute, index) => (
          <Grid key={index} item sm={4}>
            <Stack alignItems="flex-start" direction="column">
              <MetricAttributeHeader>
                {baseAttribute.title}
              </MetricAttributeHeader>
              <MetricAttributeValue>
                {getDisplayValueMarkup(baseAttribute, metricDetails)}
              </MetricAttributeValue>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default MetricAttributes;
