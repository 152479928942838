export const rcaHeaders = [
  {
    key: "dim_name",
    name: "Dimension Name",
  },
  {
    key: "dim_val",
    name: "Dimension Value",
  },
  {
    key: "kpi_delta",
    name: "Dimension Explanation Value",
  },
  {
    key: "kpi_value_movement",
    name: "Explainability",
  },
];
