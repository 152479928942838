import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { colors, typography } from "../../shared/theme-constants";
import { Box, Button, Chip, LinearProgress, MenuItem, Select, Stack, TextField, ToggleButton, Tooltip } from "@mui/material";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import MultiSelect from "../common/mui-wrapper-components/Select/MultiSelect";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { createMetricsAndDimensions, getMetricColumnMetaData } from "../../actions/onboardingActions";
import NorthTwoToneIcon from "@mui/icons-material/NorthTwoTone";
import SouthTwoToneIcon from "@mui/icons-material/SouthTwoTone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Icon from "../common/Icon";
import { ToggleButtonGroupWithIcon, ToggleGroup } from "../common/mui-wrapper-components/StyledToggleGroup";
import { escapeString } from "../../utils/stringUtils";
import { Loading } from "../common/styled-components";
import { useSelectedTenant } from "../../hooks/useSelectedTenant";
import { isNullOrEmpty } from "../../utils/is";

const Title = styled("div")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(35),
  fontWeight: typography.font_weight.mediumx,
  lineHeight: theme.typography.pxToRem(53),
  letterSpacing: "0em",
  textAlign: "center",
  color: "#5C6178",
  margin: "3% 0 3% 0",
}));
const StyledInput = styled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    color: "var(--purple-fog)",
    padding: "8.5px 10.5px",
    fontSize: theme.typography.pxToRem(14),
  },
}));
const StyledSelect = styled(Select)(({ theme, isformula = undefined }) => ({
  width: isformula ? "30%" : "100%",
  "& .MuiInputBase-input": {
    color: "var(--purple-fog)",
    padding: "8.5px 10.5px",
    fontSize: theme.typography.pxToRem(14),
  },
}));

const ActionButton = styled(Button)(({ theme, textcolor, bgcolor }) => ({
  width: "100px",
  height: "41px",
  padding: theme.spacing(0, 7),
  borderRadius: "9px",
  border: "1.5px",
  background: bgcolor,
  color: textcolor ? textcolor : "white",
  ":hover": {
    cursor: "pointer",
  },
  "&:disabled": {
    background: textcolor ? null : "linear-gradient(0deg, #3063E7, #3063E7),linear-gradient(0deg, #C4C4C4, #C4C4C4)",
    opacity: 0.4,
    color: textcolor ? textcolor : "var(--zircon)",
  },
  alignSelf: "center",
}));

const FIELD_TYPE_OPTIONS = [
  { value: "metric", label: "Metric" },
  { value: "dimension", label: "Dimension" },
];

const AGGREGATION_OPTIONS = [
  { value: "sum", label: "Sum", type: "operation" },
  { value: "avg", label: "Average", type: "operation" },
  { value: "count", label: "Count", type: "operation" },
  { value: "min", label: "Minimum", type: "operation" },
  { value: "max", label: "Maximum", type: "operation" },
  { value: "stddev_samp", label: "Std. Deviation", type: "operation" },
  { value: "var_samp", label: "Variance", type: "operation" },
  { value: "formula", label: "Formula", type: "formula" },
];

const baseColumnOptions = {
  sortable: false,
  pinnable: false,
  hideable: false,
  filterable: false,
  headerAlign: "center",
  align: "center",
};

function getRowId(row) {
  return `${row.field_name}_${Math.random(0, 1)}`;
}

const ConfigureMetrics = () => {
  const selectedTenant = useSelectedTenant();
  const location = useLocation();
  const navigate = useNavigate();
  const createMetricsStatus = useSelector((state) => state.onBoardingCreateMetrics.loading);
  const [error, setError] = useState(false);
  const [activeStep, setActiveStep] = useOutletContext();
  const [fieldNamesArray, setFieldNamesArray] = useState(null);
  const [selectedFieldNamesArray, setSelectedFieldNamesArray] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [datasourceId, setDatasourceId] = useState(null);
  const dispatch = useDispatch();

  const extraction_job_id = location.state?.extraction_job_id;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    dispatch(getMetricColumnMetaData(extraction_job_id))
      .then((response) => {
        setDatasourceId(response?.datasource_id);
        const data = response?.columns?.map((item) => {
          return Object.assign(item, {
            id: `${item.field_name}_${Math.random(0, 1)}`,
            field_type: "metric",
            aggregation: { value: "sum", label: "Sum", type: "operation" },
            objective: "positive",
            formula: "",
          });
        });
        if (data && data.length <= 10) {
          setSelectedFieldNamesArray(data);
          setFieldNamesArray(data);
          setRowData(data);
        } else if (data && data.length > 10) {
          let initialRows = data.slice(0, 10);
          setSelectedFieldNamesArray(initialRows);
          setFieldNamesArray(data);
          setRowData(initialRows);
        }
        setLoading(false);
      })
      .catch((error) => {});

    return () => (isMounted = false);
  }, [dispatch, extraction_job_id]);

  useEffect(() => (!extraction_job_id ? navigate("/onboarding/select-datasources") : setActiveStep(2)));

  const handleObjectiveChange = (_event, value, row) => {
    let newRowDataArray = rowData.map((obj) => {
      if (obj.id === row.id) {
        return { ...obj, objective: value };
      } else {
        return obj;
      }
    });
    setRowData(newRowDataArray);
  };

  const handleFieldTypeChange = (_event, value, row) => {
    let newRowDataArray = rowData.map((obj) => {
      if (obj.id === row.id) {
        return { ...obj, field_type: value };
      } else {
        return obj;
      }
    });
    setRowData(newRowDataArray);
  };

  const handleFieldNameChange = (row, _event, value, reason) => {
    // Set the rest of the columns when we select the item from the field name
    let newRowDataArray = rowData.map((obj) => {
      if (obj.id === row.id) {
        return { ...obj, field_name: value?.field_name, display_name: value?.display_name, source_table_name: value?.source_table_name };
      } else {
        return obj;
      }
    });
    setSelectedFieldNamesArray(newRowDataArray.filter((element) => element?.field_name !== row?.field_name));

    setRowData(newRowDataArray);
  };

  const handleFormulaFieldChange = (_event, value, row) => {
    // Don't update the rowData state As it will re render the the entire Grid
    // Re rendering will create new text input element and we will loose the last input values of formula field
    // instead directly update the formula field in rowData
    _event.preventDefault();
    let item = rowData.find((x) => x.id == row.id);
    if (item) {
      item.formula = _event.target.value;
    }
  };

  const handleAggregationChange = (_event, value, row) => {
    let newRowDataArray = rowData.map((obj) => {
      if (obj.id === row.id) {
        return { ...obj, aggregation: _event.target.value };
      } else {
        return obj;
      }
    });
    setRowData(newRowDataArray);
  };

  const handleDisplayNameChange = (_event, value, row) => {
    // Don't update the rowData state As it will re render the the entire Grid
    // Re rendering will create new text input element and we will loose the last input values of formula field
    // instead directly update the formula field in rowData
    _event.preventDefault();
    let item = rowData.find((x) => x.id == row.id);
    if (item) {
      item.display_name = _event.target.value;
    }
  };

  const handleRowDelete = (value, row) => {
    let newRowDataArray = rowData.filter((obj) => obj.id !== row.id);
    setSelectedFieldNamesArray(newRowDataArray);
    setRowData(newRowDataArray);
  };

  const handleAddRowData = () => {
    let Obj = {
      id: Math.random(0, 1),
      field_type: "metric",
      aggregation: { value: "sum", label: "Sum", type: "operation" },
      objective: "positive",
      formula: "",
    };
    let newRowDataArray = [...rowData, { ...Obj }];
    setRowData(newRowDataArray);

    // scroll to the last of grid on add row
    let rowElementDiv = document.querySelector(".MuiDataGrid-virtualScroller");
    rowElementDiv.scrollTop = rowElementDiv.scrollHeight;
  };

  const handleNext = () => {
    setLoading(true);
    // Collect metrics
    let metricsArray = rowData
      ?.filter((element) => element.field_type === "metric")
      ?.map((element) => {
        return Object.assign(
          {},
          {
            source_table_name: element.source_table_name,
            destination_table_name: element.destination_table_name,
            field_name: element.field_name,
            display_name: element.display_name,
            accent: element.objective,
            aggregation: {
              type: element.aggregation.type,
              [element.aggregation.type]: element.aggregation.type === "formula" ? escapeString(element.formula) : element.aggregation.value,
            },
          }
        );
      });
    // Collect dimensions
    let dimensionsArray = rowData
      .filter((element) => element.field_type === "dimension")
      ?.map((element) => {
        return Object.assign(
          {},
          {
            source_table_name: element.source_table_name,
            destination_table_name: element.destination_table_name,
            field_name: element.field_name,
            display_name: element.display_name,
            accent: element.objective,
            aggregation: {
              type: "formula",
              formula: escapeString(element.formula),
            },
          }
        );
      });

    // Logic to prepare the payload and  make backed POST call for creating metrics and dims
    let metricsAndDimsPayload = Object.assign(
      {},
      {
        metrics: [...metricsArray],
        dimensions: [...dimensionsArray],
        datasource_id: datasourceId,
        tenant_id: selectedTenant,
      }
    );
    // create metrics API call
    dispatch(createMetricsAndDimensions(metricsAndDimsPayload)).then((response) => {
      // On success navigate to set preferences screen
      navigate("/onboarding/set-preferences", { state: response.data });
    });
  };

  const checkConfiguredMetrics = () => {
    // When We add the new row the deafult values which are preopopluated are as follows
    // 1. Accent is Positive
    // 2.  Aggregation is Sum
    // Hence we will check for 3 fields
    // 1. field_name , field_display_name and if there are any dimensions set or not for enabling the next button
    let selectedMetrics = rowData?.filter((element) => element.field_type === "metric");
    let rowsWithNoOrInCompleteData = rowData?.filter((element) => {
      if (isNullOrEmpty(element.field_name)) return true;
      else if (isNullOrEmpty(element.display_name)) return true;
      return false;
    });
    if (selectedMetrics?.length < rowData?.length && rowsWithNoOrInCompleteData?.length === 0) return false;
    return true;
  };

  // Method to filter out the options which are already selected
  // So that user do not select them again by mistake
  const getFieldNameOptions = (row) => {
    let filteredArr = fieldNamesArray.filter((element) => {
      let selected = selectedFieldNamesArray?.find((element1) => element1.field_name === element.field_name);
      return selected ? false : true;
    });
    let newFiltereArr = [...filteredArr, row];
    return newFiltereArr;
  };

  const columns = [
    {
      ...baseColumnOptions,
      field: "field_name",
      headerName: "Field Name",
      flex: 0.8,
      renderCell: ({ value, row }) => {
        return (
          <Tooltip title="Select fields from only one table" placement="right">
            <MultiSelect
              sx={{ maxWidth: "210px", color: "#5C6178" }}
              disablePortal={false}
              componentsProps={{
                paper: {
                  sx: {
                    width: "fit-content",
                  },
                },
              }}
              defaultValue=""
              multiple={false}
              optionsCheckbox={false}
              placeholder="Metric Name"
              value={fieldNamesArray.find((item) => item.field_name === row.field_name) || null}
              onChange={(_event, newValue, reason) => handleFieldNameChange(row, _event, newValue, reason)}
              options={getFieldNameOptions(row) || fieldNamesArray || []}
              getOptionLabel={(option) => option?.field_name || ""}
              isOptionEqualToValue={(option, value) => option.field_name === value.field_name}
              groupBy={(option) => option.destination_table_name}
            />
          </Tooltip>
        );
      },
    },
    {
      ...baseColumnOptions,
      field: "display_name",
      headerName: "Display Name",
      flex: 0.8,
      renderCell: ({ value, row }) => {
        return (
          <StyledInput
            onKeyDown={(event) => event.stopPropagation()}
            defaultValue={row.display_name}
            id="outlined-basic"
            onChange={(_event, value) => handleDisplayNameChange(_event, value, row)}
            variant="outlined"
          />
        );
      },
    },
    {
      ...baseColumnOptions,
      field: "field_type",
      headerName: "Field Type",
      flex: 0.6,
      valueGetter: ({ row }) => {
        return row.field_type;
      },
      renderCell: ({ value, row }) => {
        return (
          <ToggleGroup
            onChange={(event, value) => handleFieldTypeChange(event, value, row)}
            value={value}
            sx={{ width: "100%", height: "32px" }}
            options={FIELD_TYPE_OPTIONS}
          ></ToggleGroup>
        );
      },
    },
    {
      ...baseColumnOptions,
      field: "aggregation",
      headerName: "Aggregation",
      flex: 0.8,
      renderCell: ({ value, row }) => {
        if (row.field_type === "dimension") {
          return (
            <StyledInput
              onKeyDown={(event) => event.stopPropagation()}
              placeholder="Formula"
              defaultValue={row.formula}
              onChange={(_event, value) => handleFormulaFieldChange(_event, value, row)}
              variant="outlined"
            />
          );
        } else {
          return (
            <Box sx={{ width: "100%", display: "flex", gap: 1, flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
              <StyledSelect
                IconComponent={ExpandMoreIcon}
                defaultValue=""
                isformula={row?.aggregation?.value === "formula" ? "true" : undefined}
                onChange={(_event, value) => handleAggregationChange(_event, value, row)}
                value={AGGREGATION_OPTIONS.filter((item) => item.value === value.value)[0]}
                renderValue={(selectedOption) => {
                  return (
                    <Box sx={{ display: "flex", gap: 1, flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                      <Icon name={selectedOption.value} size="20px" />
                      {!(row?.aggregation?.value === "formula") && selectedOption.label}
                    </Box>
                  );
                }}
              >
                {AGGREGATION_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", gap: 1 }}>
                      <Icon name={option.value} size="20px" />
                      {option.label}
                    </Box>
                  </MenuItem>
                ))}
              </StyledSelect>
              {row?.aggregation?.value === "formula" && (
                <Tooltip title="We recommend using the legacy SQL syntax" placement="bottom">
                  <StyledInput
                    onKeyDown={(event) => event.stopPropagation()}
                    placeholder="Formula"
                    defaultValue={row.formula}
                    onChange={(_event, value) => handleFormulaFieldChange(_event, value, row)}
                    variant="outlined"
                  />
                </Tooltip>
              )}
            </Box>
          );
        }
      },
    },
    {
      ...baseColumnOptions,
      field: "objective",
      headerName: "Objective",
      flex: 0.4,
      valueGetter: ({ row }) => {
        return row.objective;
      },
      renderCell: ({ value, row }) => {
        return (
          <ToggleButtonGroupWithIcon
            disabled={row.field_type === "dimension"}
            value={value}
            onChange={(event, value) => handleObjectiveChange(event, value, row)}
            sx={{ width: "100%", height: "32px" }}
          >
            <ToggleButton value="positive" key="positive">
              <NorthTwoToneIcon />
            </ToggleButton>
            <ToggleButton value="negative" key="negative">
              <SouthTwoToneIcon />
            </ToggleButton>
          </ToggleButtonGroupWithIcon>
        );
      },
    },
    {
      ...baseColumnOptions,
      field: "source_table",
      headerName: "Source Table",
      flex: 0.8,
      renderCell: ({ value, row }) => {
        return (
          <Tooltip title={`${row.source_table_name}`} placement="bottom-end">
            <Chip
              label={row.source_table_name}
              sx={{
                color: "var(--purple-fog)",
                maxWidth: 210,
                fontSize: "1rem",
                height: "33px",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                display: "flex",
                background: "#F0F0F0",
                borderRadius: "8px",
              }}
            ></Chip>
          </Tooltip>
        );
      },
    },
    {
      ...baseColumnOptions,
      field: "delete",
      headerName: "",
      flex: 0.2,
      renderCell: ({ value, row }) => {
        return (
          <Button
            onClick={() => handleRowDelete(value, row)}
            disabled={rowData?.length <= 2}
            sx={{
              ":disabled": {
                opacity: 0.3,
              },
              ":hover": {
                cursor: "pointer",
                transform: "scale3d(1.5, 1.5, 1.5)",
              },
            }}
          >
            <Icon name="delete" size="20px" />
          </Button>
        );
      },
    },
  ];

  if (createMetricsStatus) {
    return (
      <Stack sx={{ padding: "0 5%" }}>
        <div style={{ maxWidth: "1274px", width: "100%", alignSelf: "center" }}>
          <Title> Configure Metrics to monitor</Title>
          <div
            style={{
              height: "53vh",
              background: "var(--resolution-blue)",
              borderRadius: "10px",
              boxShadow: "0px 4px 22px 0px #11278540",
              marginBottom: "2%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Loading width="100px" height="100px" margin="50px auto" bgColor="white"></Loading>
            <span style={{ color: "white", fontSize: "20px", fontWeight: typography.font_weight.mediumx }}>Creating Metrics and Dimensions</span>
          </div>
        </div>
      </Stack>
    );
  }
  return (
    <Box>
      <Stack sx={{ padding: "0 5%" }}>
        <div style={{ maxWidth: "1274px", width: "100%", alignSelf: "center" }}>
          <Title> Configure Metrics to monitor</Title>
          <div style={{ height: "53vh", background: "#FFFFFF", borderRadius: "10px", boxShadow: "0px 4px 22px 0px #11278540", marginBottom: "2%" }}>
            <DataGrid
              disableColumnMenu
              disableSelectionOnClick
              loading={loading}
              sx={{
                width: "100%",
                height: "47vh",
                border: "none",
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "inherit",
                },
                "& .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
                "& .MuiDataGrid-columnSeparator--sideRight": {
                  display: "none",
                },
                "&>.MuiDataGrid-main": {
                  borderRadius: "10px",
                  borderBottom: "none",
                  "&>.MuiDataGrid-columnHeaders": {
                    borderBottom: "none",
                  },

                  "& div div div div >.MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  ["& .MuiDataGrid-columnHeaders"]: {
                    backgroundColor: "none",
                    color: "var(--purple-fog)",
                  },
                  backgroundColor: colors.white,
                },
              }}
              components={{
                LoadingOverlay: LinearProgress,
              }}
              getRowHeight={() => 80}
              columns={columns}
              getRowId={getRowId}
              rows={rowData || []}
              sortingMode="server"
              hideFooter={true}
            />
            <Box
              sx={{
                padding: "15px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                ":hover": { cursor: "pointer" },
              }}
            >
              <Icon onClick={handleAddRowData} name="add-row" size="30px" />
            </Box>
          </div>
          <Stack direction="row" sx={{ justifyContent: "space-between" }}>
            <ActionButton disabled textcolor="var(--mariner)" bgcolor="var(--zircon)" onClick={() => navigate(-1)}>
              Back
            </ActionButton>
            <ActionButton disabled={checkConfiguredMetrics()} bgcolor="linear-gradient(0deg, #245AE6, #245AE6)" onClick={handleNext}>
              Next
            </ActionButton>
          </Stack>
        </div>
      </Stack>
    </Box>
  );
};

export default ConfigureMetrics;
