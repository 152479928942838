import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import ContentLoader from "react-content-loader";

import { ChipContainer, StyledCard, TimeChip } from "./styled-components";
import { theme } from "./styled-components/theme";

const InsightSummaryLoader = ({ isExpanded, showTimeShip = true }) => {
  const matchesMobile = useMediaQuery(`(max-width:${theme.tablet})`);
  const matchesTablet = useMediaQuery(`(max-width:${theme.mobile})`);

  return (
    <Box
      data-snippyly-comment-disabled
      sx={{
        position: "relative",
      }}
    >
      {showTimeShip && (
        <ChipContainer sx={{ width: 428, height: 30 }}>
          <TimeChip />
        </ChipContainer>
      )}

      <StyledCard height={isExpanded && 700} sx={{ pb: 0 }}>
        {matchesMobile ? (
          <ContentLoader viewBox="0 0 300 200" height="100%" width="100%">
            <rect x="3" y="0" rx="5" ry="5" width="90" height="98" />

            <rect x="100" y="0" rx="5" ry="5" width="200" height="15" />
            <rect x="100" y="20" rx="5" ry="5" width="180" height="15" />
            <rect x="100" y="40" rx="5" ry="5" width="150" height="15" />
            <rect x="100" y="60" rx="5" ry="5" width="130" height="15" />

            <rect x="6" y="110" rx="5" ry="5" width="80" height="15" />
            <rect x="90" y="109" rx="5" ry="5" width="190" height="13" />
            <rect x="6" y="128" rx="5" ry="5" width="270" height="13" />
            <rect x="6" y="148" rx="5" ry="5" width="260" height="13" />
            <rect x="6" y="168" rx="5" ry="5" width="250" height="13" />
            <rect x="6" y="188" rx="5" ry="5" width="220" height="13" />
          </ContentLoader>
        ) : matchesTablet ? (
          <ContentLoader viewBox="0 0 500 65" height="100%" width="100%">
            <rect x="3" y="0" rx="5" ry="5" width="53" height="60" />

            <rect x="65" y="0" rx="5" ry="5" width="420" height="18" />
            <rect x="65" y="23" rx="5" ry="5" width="200" height="15" />
            <rect x="65" y="45" rx="5" ry="5" width="50" height="8" />
            <rect x="118" y="45" rx="5" ry="5" width="360" height="8" />
            <rect x="65" y="57" rx="5" ry="5" width="250" height="8" />
          </ContentLoader>
        ) : (
          // Desktop view
          <ContentLoader viewBox="0 0 650 75" height="100%" width="100%">
            <rect x="3" y="0" rx="5" ry="5" width="53" height="60" />

            <rect x="65" y="0" rx="5" ry="5" width="510" height="18" />
            <rect x="65" y="23" rx="5" ry="5" width="200" height="15" />
            <rect x="65" y="45" rx="5" ry="5" width="50" height="8" />
            <rect x="118" y="45" rx="5" ry="5" width="455" height="8" />
            <rect x="65" y="57" rx="5" ry="5" width="250" height="8" />
          </ContentLoader>
        )}
      </StyledCard>
    </Box>
  );
};

export default InsightSummaryLoader;
