import Container from "@mui/material/Container";
import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { STATUS_NEW } from "../constants/commonConstants";
import { errorMessage } from "../shared/intl/error-messages";
import ScreenError from "./common/error-components/ScreenError";
import HomeLayout from "./HomeLayout";
import OnboardingLayout from "./OnboardingLayout";
import { LoaderInCenter } from "./common/styled-components";

const isDevelopment = process.env.NODE_ENV === "development";

const FallbackComponent = ({ error }) => {
  return (
    <ScreenError title="" message={errorMessage.UNEXPECTED_ERROR} showButtons>
      {isDevelopment ? (
        <Container maxWidth="md" mx="auto">
          <pre style={{ whiteSpace: "pre-wrap" }}>{error.message}</pre>
        </Container>
      ) : null}
    </ScreenError>
  );
};

// This is the master component just beneath App.jsx
// The main purpose of this layout is understand if onboarding of user is complete
// If so trigger onboarding flow else trigger
const AppLayout = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const userObject = useSelector((state) => state.userLogin);
  const [onboardingFlow, setOnboardingFlow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // We Will Add the Flow which navigates either to home page or Onboarding
    // In side Onboarding we will then handle the drop Off points and then navigate accordingly

    if (userObject?.user.show_onboarding) {
      setLoading(false);
      setOnboardingFlow(userObject?.user?.show_onboarding); // Placeholder for navigate
      if (userObject?.tenant_status === STATUS_NEW) {
        if (userObject?.user_status === STATUS_NEW) {
          navigate(`/onboard/create_tenant`);
        } else {
          // shopify app status == installed and billing_status == incomplete
        }
      } else {
        if (userObject?.user_status === STATUS_NEW) {
          navigate(`/onboard/join_workspace`);
        } else {
          if (userObject?.shopify_app_status === "not_installed") {
            navigate(`/onboard/app_installation`);
          } else if (userObject?.billing_status === "incomplete") {
            navigate(`/onboard/start_trial`);
          } else {
            navigate(`/onboard/user_persona`);
          }
        }
      }
    } else if (location.pathname.includes("onboard")) {
      setLoading(false);
      navigate("/");
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <LoaderInCenter />;
  }
  if (onboardingFlow) {
    return (
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <OnboardingLayout />
      </ErrorBoundary>
    );
  }
  return <HomeLayout />;
};

export default AppLayout;
