import moment from "moment-timezone";
import { colors, typography } from "../theme-constants";
import { TIMESERIES_INSIGHT_CHART } from "./timeseries-spikes-config";
const DEFAULT_TIMEZONE = "Etc/UTC";

function formatNumber(num, patternFormat) {
  if (patternFormat === 'percentage') {
    return `${(num * 100).toFixed(1)}%`;
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "m";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "k";
  } else {
    return Math.floor(num);
  }
}
function searchYValue(arr, targetY, targetX) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].result.y === targetY && moment(arr[i].timestamp).valueOf() === targetX) {
      return arr[i];
    }
  }
  return { timestamp: "", result: { y: 0, yhat: 0, yhat_lower: 0, yhat_upper: 0 } };
}
function calculateLastPercentage(arr, targetY, targetX) {
  let value = 0;
  let calculatedPercentage = 0;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].result.y === targetY && moment(arr[i].timestamp).valueOf() === targetX) {
      value = i;
    }
  }
  if (value === 0) {
    return calculatedPercentage;
  } else {
    calculatedPercentage = ((arr[value].result.y - arr[value - 1].result.y) / arr[value - 1].result.y) * 100;
  }
  return `${calculatedPercentage.toFixed(1)}%`;
}
function calculatePercentage(value1, value2) {
  // Calculate the percentage difference
  const difference = value1 - value2;
  const percentageDifference = (difference / Math.abs(value2)) * 100;

  // Determine if it's positive, negative, or zero
  let result;
  if (percentageDifference > 0) {
    result = `${percentageDifference.toFixed(1)}%`;
  } else if (percentageDifference < 0) {
    result = `-${Math.abs(percentageDifference).toFixed(1)}%`;
  } else {
    result = "No difference";
  }

  return result;
}

function showPreviousPeriodData(isPreviousComparisonVisible, thisInstance, pattern, yValue) {
  let tooltipHtml = '';
  // Add previous period data if available
  if (isPreviousComparisonVisible) {
    thisInstance.points.forEach(point => {
      const calculatedPercentage = ((thisInstance.y - point.y) / point.y) * 100;
      if (point.series?.userOptions?.id === "previous_period_series") {
        tooltipHtml += `<div style="display: flex; gap: 0.2rem; margin: 0; padding: 0; align-items: center; flex-direction: row;">
          <div class = 'kpi-line-tooltip' style="background-color: ${point.color}"><div class="kpi-circle-tooltip" style="border: 2px solid ${point.color}; background-color: ${point.color}; border-radius: 50%; margin: 0; padding: 0;"></div></div>vs Last ${point.point.frequency || ''}: <b>${formatNumber(point.y, pattern.kpi_format)}</b><br/>
          ${yValue !== 0 ? (
            `<span class = "tooltip-text" style='${calculatedPercentage < 0 ? `color: ${colors.red[450]};` : `color: ${colors.green[450]};`}'>(${calculatedPercentage.toFixed(1)}%)</span>`
          ) : ''}
        </div>`;
      }
    });
  }
  return tooltipHtml;
}
export const generateTimeSeriesChartTooltip = (thisInstance, tooltip, patternSeries, pattern, chartType = TIMESERIES_INSIGHT_CHART, isForecastEnabled = false, showPreviousPeriodComparison = false) => {
  let { result, timestamp } = searchYValue(patternSeries, thisInstance.y, thisInstance.x);
  let expVal = calculatePercentage(result.y, result.yhat);
  let lastVal = calculateLastPercentage(patternSeries, thisInstance.y, thisInstance.x);
  let isSpikeUp = tooltip?.chart?.hoverPoint?.series?.name.includes("Spike") && (result.y > result.yhat_upper);
  // Define accent based on yhat and y
  let accent = pattern.accent;
  if (chartType == TIMESERIES_INSIGHT_CHART) {
    if (result.y > result.yhat_upper) {
      accent = pattern.pattern_direction == "up" ? pattern.accent : (pattern.accent == "positive" ? "negative" : "positive");
    } else if (result.y < result.yhat_lower) {
      accent = pattern.pattern_direction == "up" ? (pattern.accent == "positive" ? "negative" : "positive") : pattern.accent;
    }
  } else {
    if (result.y > result.yhat_upper) {
      accent = pattern.accent == "positive" ? "positive" : "negative";
    } else if (result.y < result.yhat_lower) {
      accent = pattern.accent == "positive" ? "negative" : "positive";
    }
  }

  const interval = pattern.pipeline_schedule === "d" ? "Day" : pattern.pipeline_schedule === "h" ? "Hour" : pattern.pipeline_schedule === "y" ? "Year" : "";
  let tooltipHtml =  `
    <div class="custom-tooltip">
      <div class="tooltip-header" style = "${tooltip?.chart?.hoverPoint?.series?.name.includes("Spike") ? "justify-content: space-between;" : "justify-content: end;"}">
        <div style = "${tooltip?.chart?.hoverPoint?.series?.name.includes("Spike") ? "display : block;" : "display : none"}">
          <div class="${accent === "positive" ? "positive-direction" : "negative-direction"}">
            <span class="${accent === "positive" ? "positive-direction-text" : "negative-direction-text"}">
              ${isSpikeUp ? "Spike up" : "Spike down"}
            </span>
          </div>
        </div>
        <div style ="${tooltip?.chart?.hoverPoint?.series?.name.includes("Spike") ? "display : block;" : "display : flex;"}" >
          <span class="tooltip-text" style="color : ${colors.chart_tooltip.gray}; width : 20%; font-size : 0.7rem">${moment(thisInstance.x)
      .tz(DEFAULT_TIMEZONE)
      .format("DD MMM YYYY, hh[:]mm A")}</span>
        </div>
      </div>
      <div class = 'tooltip-parent-body'>
        <div style="display : flex; flex-direction : row; gap : 0.2rem; align-items : center;">
          <div class = 'kpi-line-tooltip' style="background-color: ${thisInstance.color}">
            <div class = 'kpi-circle-tooltip' style="border: solid ${thisInstance.color} 1px; background-color: ${thisInstance.color}"></div>
          </div>
          <span class = "tooltip-text" style = "font-weight : 500; margin-left : 0.2rem;">
            ${pattern.kpi_display_name}
          </span>
        </div>
        <div class = "align-expected-renge">
          <div class="values-circle-tooltip" style="${tooltip?.chart?.hoverPoint?.series?.name.includes("Spike") ? `background-color: ${colors.chart_tooltip.blue};` : `background-color: ${colors.white}; border : 1px solid ${colors.gray[550]};`
    }"></div>
          <span class = "tooltip-text" style = "font-weight : 700">${result.anomaly === "False" ? formatNumber(result.y, pattern.kpi_format) : formatNumber(result.yhat, pattern.kpi_format)}</span>
          ${isForecastEnabled ? `<span class = "tooltip-text" style = "font-weight : 400">[${formatNumber(result.yhat_lower, pattern.kpi_format)}, ${formatNumber(result.yhat_upper, pattern.kpi_format)}]</span>`: "" }
        </div>
        <div style = "${tooltip?.chart?.hoverPoint?.series?.name.includes("Spike") ? `display : block; font-weight : ${typography.font_weight.bold}` : "display : none"}">
          <div class = "align-expected-renge">
            <div class="values-circle-tooltip" style="background-color: ${accent == "positive" ? "#43A45C" : colors.red[450]};"></div>
            <span class = "tooltip-text">${formatNumber(thisInstance.y, pattern.kpi_format)}</span>
          </div>
        </div>
        <div>
          <div style="display : flex; flex-direction : row; gap : 1rem">
            <div style ="${tooltip?.chart?.hoverPoint?.series?.name.includes("Spike") ? "display : block;" : "display : none;"}">
              <span class = "tooltip-text" style = "font-weight : ${typography.font_weight.mediumx}">vs Expected:</span>
              <span class = "tooltip-text" style='${expVal[0] == "-" ? `color: ${colors.red[450]};` : `color: ${colors.green[450]};`}'>${expVal}</span>
            </div>
            <!--Need to be revisited when we bring in pane. Right now done as part of DEV-4285-->
            ${thisInstance && thisInstance.point && thisInstance.point.index !== 0 ? (
            `<div>
              <span class = "tooltip-text" style = "font-weight : ${typography.font_weight.mediumx}">vs Last ${interval}:</span>
              <span class = "tooltip-text" style='${lastVal[0] == "-" ? `color: ${colors.red[450]};` : `color: ${colors.green[450]};`}'>${lastVal}</span>
            </div>`
            ): ''}
          </div>
        </div>
        <!--Add previous period data if available-->
        ${showPreviousPeriodData(showPreviousPeriodComparison, thisInstance, pattern, result.y)}
        <div class = "tooltip-text" style = "${tooltip?.chart?.hoverPoint?.series?.name.includes("Spike") ? "display : block;" : "display : none"}">
          <span class="tooltip-text" style="color : ${colors.chart_tooltip.gray}; width : 50%; font-size : 0.7rem; font-weight : ${typography.font_weight.mediumx
    };">Click on observation to view Insight</span>
        </div>
      </div>
    </div>`;
  return tooltipHtml;
};