import { colors } from "../../../shared/theme-constants";

export const useImpactCardStyles = {
  root: {
    width: "96%",
    overflowX: "auto",
    position: "absolute",
    height: "max-content",
    left: "2%",
    top: "50px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.14)",
    borderRadius: "13px",
  },
  headerTableRow: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.06)",
  },

  headerTableCell: {
    borderBottom: "none",
    color: colors.comet,
    fontSize: "1.1rem",
    fontWeight: "700",
    textAlign: "center",
  },
  tableCell: {
    fontSize: "1rem",
    color: colors.comet,
    borderBottom: "none",
    textAlign: "center",
  },
};
