import styled from "@emotion/styled";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Drawer,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { typography } from "../../shared/theme-constants";
import { isEmpty } from "../../utils/is";
import { StyledActionButton } from "./mui-wrapper-components/Button";
import { Loading } from "./styled-components";

const StyledAccordion = styled(Accordion)({
  // Had to keep this psuedo selector, because for some wierd reason,
  // it was showing an extra <hr /> above Overlays Chart Table
  boxShadow: "none",
  border: 0,
  margin: "10px 0",
  "&:before": {
    content: "none",
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  minHeight: 40,
  height: 48
});

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: 10,
  padding: 0,
  marginTop: theme.spacing(4),
}));

const SEVERITY_MAP = {
  High: {
    label: "",
    subtitle: "These events have direct business impact and will need immediate intervention to address errors with the providers and categories mentioned below.",
  },
  Medium: {
    label: "",
    subtitle:
      "These events can cause potential disruptions with limited business impact and may need intervention depending on the kind of errors with the providers and categories mentioned below.",
  },
  Low: {
    label: "",
    subtitle: "These errors do not have any business impact and do not warrant any action.",
  },
};

const EventProviderDrawer = ({
  subscriptions = [],
  severity,
  isEventProviderDrawerOpen = false,
  onEventProviderDrawerClose,
}) => {
  const DRAWER_CLOSE_REASONS = ["escapeKeyDown", "backdropClick"];
  const [providerPrefernces, setProviderPreferences] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (Array.isArray(subscriptions)) {
        // Create a new object in order not to manipulate the object in th parent
        let componentSubscriptionsObj = subscriptions?.find(
          (element) => element.severity === severity
        );
        let eventPreferences = [];

        if (!isEmpty(componentSubscriptionsObj)) {
          // destructure the providers array
          eventPreferences = [...componentSubscriptionsObj.providers];
        } else {
          eventPreferences = [];
        }

        setProviderPreferences(eventPreferences);
        setLoading(false);
      } else {
        setProviderPreferences([]);
        setLoading(false);
      }
    }
    return () => (isMounted = false);
  }, [severity, subscriptions]);

  return (
    <Drawer
      anchor="right"
      open={isEventProviderDrawerOpen}
      onClose={onEventProviderDrawerClose}
      sx={{
        zIndex: 100000,
      }}
      PaperProps={{
        sx: { justifyContent: "center" },
      }}
    >
      <>
        {loading ? (
          <Loading />
        ) : (
          <Stack sx={{ width: 550, padding: 4, height: "90vh" }} gap={4}>
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(24),
                fontWeight: typography.font_weight.mediumx,
              })}
            >
              {`What is ${severity} Impact event?`}
            </Typography>
            <Stack gap={4} sx={{ height: "90%" }}>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(14),
                })}
              >
                {SEVERITY_MAP[severity]?.subtitle}
              </Typography>
              <Stack direction="column" gap={0} sx={{ overflowY: "scroll" }}>
                {providerPrefernces?.map((provider) => (
                  <React.Fragment key={provider.id}>
                    <StyledAccordion expanded={true}>
                      <StyledAccordionSummary expandIcon={<></>}>
                        <Stack direction="row" alignItems="center" gap={3}>
                          <img src={`${provider.logo_url}`} width={20} height={20} />
                          <Typography
                            sx={(theme) => ({
                              fontSize: theme.typography.pxToRem(16),
                            })}
                          >
                            {provider.display_name}
                          </Typography>
                        </Stack>
                      </StyledAccordionSummary>
                      <StyledAccordionDetails>
                        {provider?.categories?.map((category) => (
                          <Chip
                            key={category.id}
                            label={category.display_name}
                            sx={(theme) => ({
                              border: "none",
                              borderRadius: 1,
                              background: "var(--lavender)",
                              color: "var(--resolution-blue)",
                            })}
                            variant={`${category.is_active ? "filled" : "outlined"}`}
                          />
                        ))}
                      </StyledAccordionDetails>
                    </StyledAccordion>
                    <Divider />
                  </React.Fragment>
                ))}
              </Stack>
            </Stack>
          </Stack>
        )}

        <Stack
          direction="row"
          gap={3}
          sx={{
            height: "10vh",
            justifyContent: "center",
            alignItems: "center",
            padding: 4,
          }}
        >
          <StyledActionButton onClick={onEventProviderDrawerClose}>Got it</StyledActionButton>
        </Stack>
      </>
    </Drawer>
  );
};

export default EventProviderDrawer;
