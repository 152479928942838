import styled from "@emotion/styled";
import { Box, Card, Divider, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import moment from "moment-timezone";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EventGroupCardHeader from "../components/Events/components/EventGroupCardHeader";
import EventPayloadTable from "../components/Events/components/EventPayloadTable";
import ApiScreenError from "../components/common/error-components/ApiScreenError";
import { StyledButton } from "../components/common/mui-wrapper-components/Button";
import { CopyLink } from "../components/common/share-and-collaborate/CopyLink";
import { LoaderInCenter } from "../components/common/styled-components";
import { Message } from "../components/common/ui-components/Message";
import { useAxios } from "../hooks/useAxios";
import { useSelectedTenant } from "../hooks/useSelectedTenant";
import { HTTP_STATUS } from "../shared/enums/http-status";
import { errorMessage } from "../shared/intl/error-messages";
import { colors, typography } from "../shared/theme-constants";
import { NavigateSlideButton } from "../components/common/styled-components/Carousel.styled";
import { ChevronLeftIcon } from "../components/common/Icons";
import { useSelector } from "react-redux";
import { L3ContainerMain } from "../components/common/styled-components/collaborate";
import { HomeRounded, SensorsOutlined } from "@mui/icons-material";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4, 8),
  [theme.breakpoints.down("lt")]: { padding: `${theme.spacing(5)}` },
  borderRadius: 12,
  overflow: "unset",
  backgroundColor: colors.white,
  position: "relative",
}));

const StyledTitle = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(22),
  fontWeight: typography.font_weight.black,
  lineHeight: theme.typography.pxToRem(60),
  color: colors.blue["925"],
  [theme.breakpoints.down("tm")]: { fontSize: theme.typography.pxToRem(12) }
}));

const DEFAULT_TIMEZONE = "Etc/UTC";

const EventGroupCollaborateScreen = () => {
  const selectedTenant = useSelectedTenant();
  // Get the tenant configs
  const tenantConfigs = useSelector((state) => state.tenantConfigs.configs);
  // Get the timezone from the tenant configs
  let timezone = tenantConfigs?.timezone?.name ? tenantConfigs.timezone.name : DEFAULT_TIMEZONE;
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('lt'));
  const { eventGroupId } = useParams();
  const {
    data: eventGroupData,
    isLoading,
    isError,
    error,
  } = useAxios(`events/groups/${eventGroupId}`, {
    params: {
      tenant_id: selectedTenant,
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const calculateDuration = () => {
    let current_time_stamp = null;
    let event_group_start_time = moment(eventGroupData?.event_group_ts_start).tz(DEFAULT_TIMEZONE);

    let event_group_duration = 0;

    if (eventGroupData?.status === "Active") {
      // Get browser's Time
      current_time_stamp = moment().tz(DEFAULT_TIMEZONE);
      event_group_duration = moment.duration(current_time_stamp.diff(event_group_start_time));
    } else if (eventGroupData?.status === "Closed") {
      current_time_stamp = moment(eventGroupData?.event_group_ts_end).tz(DEFAULT_TIMEZONE);
      event_group_duration = moment.duration(current_time_stamp.diff(event_group_start_time));
    }

    const days = event_group_duration.days();
    const hours = event_group_duration.hours().toString().padStart(2, "0");
    const minutes = event_group_duration.minutes().toString().padStart(2, "0");
    let formattedDuration = `${days > 0 ? days + "d" : ""} ${hours > 0 ? hours + "h" : ""} ${minutes > 0 ? minutes + "mins" : ""
      }`;
    return formattedDuration;
  };

  const renderTimeAndDuration = () => {
    return (
      <Stack
        direction="row"
        sx={(theme) => ({
          mt: 13, justifyContent: "space-between",
          [theme.breakpoints.down("lt")]: { mt: 0 },
        })}
      >
        <Stack direction="column" gap={2}>
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(16),
              [theme.breakpoints.down("lt")]: { fontSize: theme.typography.pxToRem(10) },
              fontWeight: typography.font_weight.light,
            })}
          >
            Start of Event
          </Typography>
          <Typography
            sx={(theme) => ({
              fontWeight: typography.font_weight.semiBold,
              [theme.breakpoints.down("lt")]: { fontSize: theme.typography.pxToRem(10) },
            })}
          >
            {moment.tz(eventGroupData.event_group_ts_start, DEFAULT_TIMEZONE)
              .tz(timezone)
              .format("ddd, Do MMM, YYYY, hh:mm A z")}
          </Typography>
        </Stack>
        {eventGroupData.status === "Closed" && (
          <Stack direction="column" gap={2}>
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(16),
                [theme.breakpoints.down("lt")]: { fontSize: theme.typography.pxToRem(10) },
                fontWeight: typography.font_weight.light,
              })}
            >
              End of Event
            </Typography>
            <Typography
              sx={(theme) => ({
                fontWeight: typography.font_weight.semiBold,
                [theme.breakpoints.down("lt")]: { fontSize: theme.typography.pxToRem(10) },
              })}
            >
              {moment.tz(eventGroupData.event_group_ts_end, DEFAULT_TIMEZONE)
                .tz(timezone)
                .format("ddd, Do MMM, YYYY, hh:mm A z")}
            </Typography>
          </Stack>)}
        <Stack direction="column" gap={2}>
          <Stack direction="row" sx={(theme) => ({ alignItems: "center" })}>
            <img
              width="11px"
              height="15px"
              src={`/icons/${eventGroupData?.status === "Active"
                ? "duration_red"
                : "duration"
                }.svg`}
              style={{ marginRight: "4px" }}
            />
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(16),
                [theme.breakpoints.down("lt")]: { fontSize: theme.typography.pxToRem(10) },
                fontWeight: typography.font_weight.light,
                color:
                  eventGroupData?.status === "Active"
                    ? "var(--red-2)"
                    : "var(--sonic-silver)",
              })}
            >
              Duration
            </Typography>
          </Stack>
          <Typography
            sx={(theme) => ({
              color:
                eventGroupData?.status === "Active"
                  ? "var(--red-2)"
                  : "var(--sonic-silver)",
              fontSize: theme.typography.pxToRem(16),
              [theme.breakpoints.down("lt")]: { fontSize: theme.typography.pxToRem(10) },
              fontWeight: typography.font_weight.semiBold,
            })}
          >
            {calculateDuration()}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  if (isLoading) {
    return (
      <L3ContainerMain id={eventGroupId} style={{ backgroundColor: "#e5ecfd" }}>
        <LoaderInCenter />
      </L3ContainerMain>
    );
  }

  if (isError) {
    if (error.response?.status === HTTP_STATUS.NOT_FOUND) {
      return <ApiScreenError error={error} resourceType="Group" />;
    } else {
      return (
        <Box pb="50px">
          <Message>{errorMessage.CARD_ERROR}</Message>
        </Box>
      );
    }
  }
  return (
    <L3ContainerMain id={eventGroupId} style={{ backgroundColor: "#e5ecfd", alignItems: "center", ...(isSmallScreen && { position: "fixed", height: 'auto', overflowY: "auto" }) }}>
      <Box
        sx={(theme) => ({
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "50px",
          marginBottom: "50px",
          paddingBottom: "50px",
          width: "100%",
          [theme.breakpoints.down("lt")]: { overflow: `scroll`, marginTop: '0px' },
        })}
      >
        <Stack
          gap={15}
          direction="column"
          sx={(theme) => ({
            height: "100%",
            maxWidth: theme.typography.pxToRem(1200),
            width: "100%",
            padding: "0 20px",
          })}
        >
          <Stack
            direction="row"
            gap={3}
            sx={{ alignItems: "center", mt: 8 }}
            justifyContent="space-between"
          >
            <Stack direction="row" gap={3} sx={{ alignItems: "center" }}>
              <NavigateSlideButton
                onClick={() => navigate(-1)}
                style={{
                  background: "rgba(36, 90, 230, 1)",
                  color: "var(--white)",
                  padding: "0px",
                  position: "unset",
                }}
              >
                <ChevronLeftIcon fontSize="large" />
              </NavigateSlideButton>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(30),
                  fontWeight: typography.font_weight.semiBold,
                })}
              >
                {eventGroupData.formatted_event_group_sequence_id}
              </Typography>
            </Stack>
            <CopyLink link={`events/groups/${eventGroupData.event_group_id}`} />
          </Stack>
          <StyledCard
            sx={(theme) => ({
              padding: `${theme.spacing(8)} !important`,
              [theme.breakpoints.down("lt")]: { padding: `${theme.spacing(2)} !important` },
            })}
          >
            <EventGroupCardHeader
              categoryLogo={eventGroupData?.category_logo_url}
              providerLogo={eventGroupData?.provider_logo_url}
              eventStatus={eventGroupData?.status}
              eventSeqId={eventGroupData?.formatted_event_group_sequence_id}
              eventStartTime={eventGroupData?.event_group_ts_start}
              eventEndTime={eventGroupData?.event_group_ts_end}
              numberOfEvents={eventGroupData?.number_of_events}
              type={eventGroupData?.type}
              eventCategory={eventGroupData?.category_display_name}
              providerDisplayName={eventGroupData?.provider_display_name}
              isDescriptionSectionEnabled={false}
              funnels={eventGroupData?.funnels}
              source="view"
              {...eventGroupData}
            />
            <Box sx={(theme) => ({ [theme.breakpoints.down("lt")]: { display: "none" } })}>
              {renderTimeAndDuration()}
            </Box>
          </StyledCard>
          <Box sx={(theme) => ({ [theme.breakpoints.up("lt")]: { display: "none" } })}>
            <StyledCard>{renderTimeAndDuration()}</StyledCard>
          </Box>
          <StyledCard>
            <StyledTitle>{eventGroupData?.type === 'error' ? `ERROR LOGS` : `OUTAGE HISTORY`}</StyledTitle>
            <Divider orientation="horizontal" />
            <Box mt={7} mb={5} height="auto" sx={{
              marginTop: 0
            }}>
              <EventPayloadTable events={eventGroupData?.events} type={eventGroupData?.type} />
            </Box>
          </StyledCard>
          {eventGroupData?.type === 'error' && <StyledCard>
            <StyledTitle>WHAT DOES THIS MEAN?</StyledTitle>
            <Divider orientation="horizontal" />
            <Box mt={7} mb={5} height="auto">
              <Typography
                sx={(theme) => ({
                  [theme.breakpoints.down("tm")]: { fontSize: theme.typography.pxToRem(10) },
                })}
              >
                {eventGroupData?.description}
              </Typography>
            </Box>
          </StyledCard>}
          <StyledCard>
            <StyledTitle>WHAT CAN YOU DO?</StyledTitle>
            <Divider orientation="horizontal" />
            {eventGroupData?.type === "error" && <Box mt={7} mb={5} height="auto">
              <Typography
                component="pre"
                sx={(theme) => ({
                  whiteSpace: "pre-wrap",
                  [theme.breakpoints.down("tm")]: { fontSize: theme.typography.pxToRem(10) },
                })}
              >
                {eventGroupData?.action}
              </Typography>
            </Box>}
            {eventGroupData?.type === "outage" && <Box mt={7} mb={5} height="auto" sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              [theme.breakpoints.down("tm")]: { flexDirection: "column" },
            })}>
              <Box sx={(theme) => ({
                className: "status-page",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
                [theme.breakpoints.down("tm")]: { flexDirection: "column" },
                padding: "40px 20px",
                boxShadow: "2px 2px 4px 2px rgba(0, 0, 0, 0.25)",
                cursor: "pointer",
                margin: "0 10px",
                borderRadius: "12px",
                "&:hover .status-page-icon": {
                      transform: "scale(1.05)",
                      transition: "transform 0.2s",
                    }
              })}
              onClick={() => window.open(eventGroupData.provider_status_page_url, "_blank")}>
                <Typography
                  sx={(theme) => ({
                    fontWeight: typography.font_weight.semiBold,
                    [theme.breakpoints.down("tm")]: { fontSize: theme.typography.pxToRem(14) },
                  })}
                >
                  {`Check ${eventGroupData.provider_display_name}'s Status Page`}
                </Typography>
                <IconButton
                  className="status-page-icon"
                  sx={(theme) => ({
                    color: colors.white
                  })}
                  size="large"
                >
                  <SensorsOutlined
                    sx={(theme) => ({
                      color: theme.palette.primary.main,
                      fontSize: theme.typography.pxToRem(90),
                    })}
                  >
                  </SensorsOutlined>
                </IconButton>
              </Box>
              <Box sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
                [theme.breakpoints.down("tm")]: { flexDirection: "column" },
                padding: "40px 20px",
                boxShadow: "2px 2px 4px 2px rgba(0, 0, 0, 0.25)",
                cursor: "pointer",
                margin: "0 10px",
                borderRadius: "12px",
                "&:hover .home-page-icon": {
                      transform: "scale(1.05)",
                      transition: "transform 0.2s",
                    }
              })}
              onClick={() => window.open(eventGroupData.provider_home_page_url, "_blank")}>
                <Typography
                  sx={(theme) => ({
                    fontWeight: typography.font_weight.semiBold,
                    [theme.breakpoints.down("tm")]: { fontSize: theme.typography.pxToRem(10) },
                  })}
                >
                  {`Visit ${eventGroupData.provider_display_name} Home Page`}
                </Typography>
                <IconButton
                  className="home-page-icon"
                  sx={(theme) => ({
                    color: colors.white,
                  })}
                  size="large"
                >
                  <HomeRounded
                    sx={(theme) => ({
                      color: theme.palette.primary.main,
                      fontSize: theme.typography.pxToRem(90),
                    })}
                  >
                  </HomeRounded>
                </IconButton>
              </Box>
            </Box>}
          </StyledCard>
        </Stack>
      </Box>
    </L3ContainerMain>
  );
};

export default EventGroupCollaborateScreen;
