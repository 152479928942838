import React from "react";
import { labels } from "../../../shared/intl/labels";
import {
  KpiRowStack,
  KpiRowTypography,
  SubTitleTypography,
  TitleTypography,
} from "../styled-components/LiftPercentage.styled";

const LiftBasicCard = ({
  derivedNonPercentage,
  normalizedView,
  formattedNormalizedValue,
  kpiName,
  children,
}) => {
  return (
    <>
      <TitleTypography>{labels.LIFT_CALCULATION.TITLE}</TitleTypography>
      {normalizedView && formattedNormalizedValue && (
        <SubTitleTypography>{formattedNormalizedValue}</SubTitleTypography>
      )}
      <KpiRowStack direction="row">
        <KpiRowTypography>
          {derivedNonPercentage && "Average "}
          {kpiName}
        </KpiRowTypography>
      </KpiRowStack>
      {children}
    </>
  );
};

export default LiftBasicCard;
