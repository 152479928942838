export const PERCENTAGE = "percentage";
export const RESOURCE_TYPE_INSIGHT = "insight";
export const RESOURCE_TYPE_GROUP = "group";
export const DIM_NAME_OTHER = "Other";

export const HOUR_IN_MS = 1000 * 60 * 60; // 3_600_00
export const DAY_IN_MS = HOUR_IN_MS * 24; // 86_400_000
export const WEEK_IN_MS = DAY_IN_MS * 7; // 604_800_000
export const TIME_GRANULARITY_TO_DTICK = {
  hour: HOUR_IN_MS,
  day: DAY_IN_MS,
  week: WEEK_IN_MS,
  month: "M1",
  quarter: "M3",
};
export const TIME_GRANULARITY_TO_TICK_FORMAT = {
  hour: "%H:%M\n%b %d, %Y",
  day: "%b %d\n%Y",
  week: "%b %d\n%Y",
  month: "%b\n%Y",
  quarter: "%b\n%Y",
};

export const PIPELINE_SCHEDULE_TRANSLATION = {
  d: "day",
  m: "month",
  q: "quarter",
  h: "hour",
  w: "week"
};

export const CSV_FILE_FORMAT = "text/csv";

export const severityColorMapping = {
  High: 'var(--pale-rose)',
  Medium: 'var(--ivory-cream)',
  Low: 'var(--mist-green)',
};

export const severityTextColorMapping = {
  High: 'var(--terracota)',
  Medium: 'var(--golden-amber)',
  Low: 'var(--olive-green)',
};

export const eventsBorderColorMapping = {
  High: 'var(--coral-pink)',
  Medium: 'var(--honey-gold)',
  Low: 'var(--sage-green)',
};

// Below constant will handle color coding for all filter chips.
export const eventSummaryFilterColorMapping = {
  High: '#F9EAE6',
  Medium: '#FCF0CF',
  Low: '#E2F3E6',
  provider: '#EBEDEE',
  Live: '#FDE2E1',
  Closed: '#DFF5D8',
};

export const SERVER_ERROR_MESSAGE =
  "There was a technical issue while saving, please contact system administrator.";

export const STATUS_NEW = 'new';
export const STATUS_PRESENT = 'present';