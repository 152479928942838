import React, { Children, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { StyledTooltipChart } from "../styled-components/Tooltip.styled";
import { ChevronLeftIcon, ChevronRightIcon } from "../Icons";
import {
  SlideIndicator,
  NavigateSlideButton,
} from "../styled-components/Carousel.styled";
import { colors } from "../../../shared/theme-constants";
import { GTAG_EVENTS, sendDataToGTM } from "../../../utils/gtmHelper";
import { DoD, MoM, WoW } from "../../../constants/chartOverlayConstants";
import { labels } from "../../../shared/intl/labels";
import { GTagArgsContext } from "../../../context/GtagContext";
import { InsightContext } from "../../../context/InsightContext";
import { RESOURCE_TYPE_INSIGHT } from "../../../constants/commonConstants";
// We need to control the position of the slider Icons in caousel. hence added custom css
import "./ChartCarousel.css";

function ChartCarousel(props) {
  const dispatch = useDispatch();
  const gtagArgsContext = useContext(GTagArgsContext);
  const insightContext = useContext(InsightContext);
  const { schedule, insightId } = insightContext.insightSummary;
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  let carouselChildren = [...props.children];
  // prune the last element of the carousel if the resourece type is insight
  if (props.resourceType === RESOURCE_TYPE_INSIGHT) {
    carouselChildren.pop();
  }

  // Slide index is zero based, and children count returns exact number of children, so we must subtract 1 from it
  const totalChildren = Children.count(carouselChildren) - 1;

  const setOverlayType = () => {
    if (schedule) {
      switch (schedule) {
        case labels.PIPELINE_SCHEDULE["h"]:
          return DoD;
        case labels.PIPELINE_SCHEDULE["d"]:
          return WoW;
        case labels.PIPELINE_SCHEDULE["w"]:
          return MoM;
      }
    }
  };

  const handleChange = (selectedIndex) => {
    if (currentSlideIndex !== selectedIndex) {
      setCurrentSlideIndex(selectedIndex);
    }
    if (selectedIndex === 1) {
      sendDataToGTM(GTAG_EVENTS.OVERLAY_VIEWED, {
        ...gtagArgsContext,
        overlay_type: setOverlayType(),
      });
    } else if (selectedIndex > 1 && props.hasRootCause) {
      sendDataToGTM(GTAG_EVENTS.ROOTCAUSE_VIEWED, {
        ...gtagArgsContext,
        total_rootcause: props.totalRootcause,
      });
    }
  };

  const next = () => {
    if (currentSlideIndex < totalChildren) {
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  };

  const previous = () => {
    if (currentSlideIndex > 0) {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  useEffect(() => {
    dispatch({
      type: "COLLAPSE_TABLE",
      payload: {
        carouselIndex: currentSlideIndex,
        shouldCollapseTable: true,
        insightId: insightId,
      },
    });
  }, [dispatch, currentSlideIndex, insightId]);

  return (
    <>
      {currentSlideIndex > 0 && (
        <StyledTooltipChart title="Back to previous view" placement="right-end">
          <NavigateSlideButton
            onClick={previous}
            style={{ left: 0, "--translateX": "-50%", background: 'rgba(36, 90, 230, 1)', color: 'var(--white)' }}
          >
            <ChevronLeftIcon fontSize="large" />
          </NavigateSlideButton>
        </StyledTooltipChart>
      )}

      {totalChildren !== currentSlideIndex && (
        <StyledTooltipChart title="View next card" placement="right-end">
          <NavigateSlideButton
            onClick={next}
            style={{ right: 0, "--translateX": "50%", background: 'rgba(36, 90, 230, 1)', color: 'var(--white)' }}
          >
            <ChevronRightIcon fontSize="large" />
          </NavigateSlideButton>
        </StyledTooltipChart>
      )}

      <Carousel
        showThumbs={false}
        showArrows={false}
        selectedItem={currentSlideIndex}
        useKeyboardArrows={true}
        onChange={handleChange}
        statusFormatter={() => ""}
        renderIndicator={(onClickHandler, isSelected, index) => {
          if (isSelected) {
            return (
              <SlideIndicator style={{ backgroundColor: colors.mariner }} />
            );
          }

          return (
            <SlideIndicator
              key={index}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              role="button"
              tabIndex={0}
              value={index}
            />
          );
        }}
      >
        {carouselChildren}
      </Carousel>
    </>
  );
}

export default ChartCarousel;
