import styled from "@emotion/styled";
import { Breadcrumbs, Chip, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { typography } from "../../../shared/theme-constants";

const StyledChip = styled(Chip)(({ theme }) => ({
  border: "none",
  color: "var(--cool-grey)",
  fontWeight: typography.font_weight.regular,
  fontSize: theme.typography.pxToRem(14),
  backgroundColor: "transparent",
  borderRadius: 6,
  maxWidth: 210,
  ":hover": {
    border: "none",
    backgroundColor: "rgba(36, 90, 230, 0.04)",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: typography.font_weight.mediumx,
  background: "var(--solitude)",
  color: "var(--cool-grey)",
  padding: "3px 9px",
  borderRadius: theme.typography.pxToRem(6),
  display: "block",
  alignContent: "center",
  height: theme.typography.pxToRem(32),
  maxWidth: "210px",
}));

const BreadCrumbs = ({ value, onChange, breadCrumbs, ...delegatedProps }) => {
  const [selectedValue, setSelectedValue] = useState();

  function handleChange(clickedBreadCrumb) {
    if (selectedValue !== null) {
      // Find the index of the item which was clicked
      // This is done in order to remove all the itmes in the list which are after that clicked item
      let indexOfClickedBreadCrumb = selectedValue.findIndex((element, i) => {
        return element.value === clickedBreadCrumb.value;
      });

      let arrayToSplit = [...selectedValue];

      // Set the value of breadCrumb from start till the point which was clicked and remoce the latter half of the array
      setSelectedValue(arrayToSplit.slice(0, indexOfClickedBreadCrumb + 1));

      if (typeof onChange === "function") {
        onChange(arrayToSplit.slice(0, indexOfClickedBreadCrumb + 1));
      }
    }
  }

  useEffect(() => {
    setSelectedValue(breadCrumbs);
  }, [breadCrumbs]);

  return (
    <Breadcrumbs maxItems={3} separator=">">
      {breadCrumbs.map((breadCrumb, index, rows) =>
        index == rows.length - 1 ? (
          <Tooltip title={breadCrumb?.text} key={`${breadCrumb.value}_${index}`} placement="bottom-end">
            <StyledTypography key={breadCrumb.value} noWrap>
              {breadCrumb?.text}
            </StyledTypography>
          </Tooltip>
        ) : (
          <Tooltip key={`${breadCrumb.value}_${index}`}  title={breadCrumb?.text} placement="bottom-end">
            <StyledChip
              key={breadCrumb?.value}
              label={breadCrumb?.text}
              onClick={() => handleChange(breadCrumb)}
            />
          </Tooltip>
        )
      )}
    </Breadcrumbs>
  );
};

export default BreadCrumbs;
