import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

import { colors } from "../../../../shared/theme-constants";

export const MetricLink = styled(Link)({
  textDecoration: "none",
  cursor: "pointer",
  color: colors.mariner,
  "&:hover": {
    textDecoration: "underline",
  },
});
export const SummaryMetricLink = styled(MetricLink)({
  textDecoration: "underline",
  color: colors.white,
  "&:hover": {
    color: colors.blue_bell,
    textDecoration: "none",
  },
});
