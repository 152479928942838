// borrowed (and tweaked) from https://github.com/kentcdodds/react-hooks/blob/00c2ea1db76303d52003516fb80c9ddd9013b045/src/utils.js
import { useCallback, useEffect, useState } from "react";

/**
 *
 * @param {String} key The key to set in localStorage for this value
 * @param {Object} defaultValue The value to use if it is not already in localStorage
 * @param {{serialize: Function, deserialize: Function}} options The serialize and deserialize functions to use (defaults to JSON.stringify and JSON.parse respectively)
 * @returns {[state: Object, setState: Function, clearItemFromLS: Function]}
 */
export function useLocalStorageState(
  key,
  defaultValue = null,
  { serialize = JSON.stringify, deserialize = JSON.parse } = {}
) {
  const [state, setState] = useState(() => {
    const valueInLocalStorage = window.localStorage.getItem(key);
    if (valueInLocalStorage) {
      // the try/catch is here in case the localStorage value was set before
      // we had the serialization in place
      try {
        return deserialize(valueInLocalStorage);
      } catch (error) {
        window.localStorage.removeItem(key);
      }
    }

    return typeof defaultValue === "function" ? defaultValue() : defaultValue;
  });

  const clearItemFromLS = useCallback(() => {
    window.localStorage.removeItem(key);
  }, [key]);

  useEffect(() => {
    if (state !== null) {
      window.localStorage.setItem(key, serialize(state));
    }
  }, [key, state, serialize]);

  return [state, setState, clearItemFromLS];
}
