import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  LinearProgress,
  Typography,
  Container,
  Box,
  IconButton,
  Stack,
  Popover,
  popoverClasses,
  alpha,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  gridClasses,
  GridColumnHeaderTitle,
  GridFilterListIcon,
} from "@mui/x-data-grid";
import { listMetrics } from "../actions/metricActions";
import { colors, typography } from "../shared/theme-constants";
import Icon from "../components/common/Icon";
import MetricsColumnMenu from "../components/metrics/MetricsColumnMenu";
import { MetricLink } from "../components/common/styled-components/metric/Metric.styled";
import { useSelectedTenant } from "../hooks/useSelectedTenant";
import { isEmpty } from "../utils/is";
import { labels } from "../shared/intl/labels";
import { formatDateTime, generateDifferenceInTimeString } from "../utils/dateUtils";
import { max, parseISO } from "date-fns";

const ROWS_PER_PAGE = [10, 25, 50];
const SORTING_ORDER = ["desc", "asc"];
const TIER_TO_COLOR_MAP = {
  1: "#29409F",
  2: "#8193E0",
  3: "#A9BAFF",
};

const PIPELINE_STATUS_COLORS = {
  success: colors.positive_green,
  failure: colors.negative_red,
  disabled: colors.disabled,
};

const Tier = styled("span")(({ children: tier }) => ({
  padding: "4px 16px",
  color: colors.white,
  borderRadius: 4,
  backgroundColor: TIER_TO_COLOR_MAP[tier],
}));

const StyledAnchor = styled("a")({
  textDecoration: "none",
  cursor: "pointer",
  color: colors.mariner,
  "&:hover": {
    textDecoration: "underline",
  },
});

const ColorDot = styled("span", {
  shouldForwardProp: (prop) => prop !== "color",
})((props) => ({
  display: "inline-block",
  width: 10,
  height: 10,
  borderRadius: "50%",
  marginRight: 4,
  backgroundColor: props.color,
}));

const PipelineStatusTextStyled = styled("span")({
  fontWeight: typography.font_weight.regular,
  textTransform: "capitalize",
});

const PopoverTitleStyled = styled("span")({
  textAlign: "center",
  fontSize: 14,
  cursor: "pointer",
  color: "#A6A6A6DE",
  textDecoration: "underline",
});

const PipelineScheduleStyled = styled("span")({
  fontWeight: typography.font_weight.bold,
  textTransform: "capitalize",
});

const PipelineStatusStyled = styled("div")({
  width: 20,
  height: 20,
  textAlign: "center",
  border: "1.5px solid var(--status-color)",
  backgroundColor: "var(--status-bg-color)",
  borderRadius: "50em",
  fontWeight: 700,
  fontSize: 12,
  cursor: "pointer",
  textTransform: "uppercase",
});

const StyledPopover = styled(Popover)({
  pointerEvents: "none",

  [`& .${popoverClasses.paper}`]: {
    borderRadius: "1px solid gray",
    maxWidth: "15rem",
    maxHeight: "10rem",
    padding: "1.2rem",
  },
});

const PipelineHoverPopover = ({ pipeline, metric }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "pipeline-status-popover" : undefined;
  /* Check if metric is active, if not, set status to disabled, else set status to online/offline */
  const status = pipeline.is_active ? pipeline.status : "disabled";
  const statusColor = PIPELINE_STATUS_COLORS[status];
  return (
    <div>
      <PipelineStatusStyled
        id={id}
        style={{
          "--status-color": statusColor,
          "--status-bg-color": alpha(statusColor, 0.25),
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {pipeline?.frequency?.charAt(0)}
      </PipelineStatusStyled>
      <StyledPopover
        id={id}
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        elevation={1}
      >
        <Stack spacing={2} alignItems="flex-start">
          <PopoverTitleStyled>{metric}</PopoverTitleStyled>
          <Stack direction="row" spacing={1}>
            <PipelineScheduleStyled>
              {labels.PIPELINE_SCHEDULE[pipeline.frequency]}
            </PipelineScheduleStyled>
            <PipelineStatusTextStyled>
              <ColorDot color={PIPELINE_STATUS_COLORS[status]} />
              {status}
            </PipelineStatusTextStyled>
          </Stack>
        </Stack>
      </StyledPopover>
    </div>
  );
};

const ColumnHeaderTitle = styled(Box)({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  fontWeight: typography.font_weight.medium,
  display: "inline-flex",

  "& .MuiIconButton-root": {
    cursor: "default",
  },
});

const HeaderWithFilterIcon = ({ field, colDef, selectedFilters }) => {
  return (
    <ColumnHeaderTitle className={gridClasses.columnHeaderTitle}>
      <GridColumnHeaderTitle
        label={colDef.headerName}
        description={colDef.description}
        columnWidth={colDef.width}
      />

      {!isEmpty(selectedFilters[field]) ? (
        <IconButton color="primary" disableRipple size="small">
          <GridFilterListIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </ColumnHeaderTitle>
  );
};

const baseColumnOptions = {
  sortable: false,
  pinnable: false,
  hideable: false,
  filterable: false,
};

/**
 * @returns {import("@mui/x-data-grid").GridColumns}
 */
function createColumns({ selectedFilters }) {
  return [
    {
      ...baseColumnOptions,
      field: "kpi_display_name",
      headerName: "Metric",
      flex: 0.7,
      filterable: true,
      renderHeader: (params) => (
        <HeaderWithFilterIcon {...params} selectedFilters={selectedFilters} />
      ),
      renderCell: ({ formattedValue, row }) => {
        if (!formattedValue) return null;

        return <MetricLink to={row.kpi_id}>{formattedValue}</MetricLink>;
      },
    },
    {
      ...baseColumnOptions,
      field: "category_name",
      headerName: "Category",
      flex: 0.6,
      filterable: true,
      renderHeader: (params) => (
        <HeaderWithFilterIcon {...params} selectedFilters={selectedFilters} />
      ),
    },
    {
      ...baseColumnOptions,
      field: "funnel_name",
      headerName: "Funnel",
      flex: 0.4,
      filterable: true,
      renderHeader: (params) => (
        <HeaderWithFilterIcon {...params} selectedFilters={selectedFilters} />
      ),
    },
    {
      ...baseColumnOptions,
      field: "tier",
      headerName: "Tier",
      flex: 1,
      headerAlign: "center",
      align: "center",
      maxWidth: 100,
      filterable: true,
      renderHeader: (params) => (
        <HeaderWithFilterIcon {...params} selectedFilters={selectedFilters} />
      ),
      renderCell: ({ value }) => {
        if (!value) return null;

        return <Tier>{value}</Tier>;
      },
    },
    {
      ...baseColumnOptions,
      field: "impact_to_topline",
      headerName: "Impact to Topline",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      valueFormatter: ({ value }) => value && Math.floor(value * 100),
      renderCell: ({ value, formattedValue }) => {
        if (!value) return null;

        return (
          <Typography
            component="span"
            color="primary.main"
            fontWeight={typography.font_weight.bold}
          >
            {formattedValue}%
          </Typography>
        );
      },
    },
    {
      ...baseColumnOptions,
      field: "accent",
      headerName: "Optimization Objective",
      headerAlign: "center",
      align: "center",
      filterable: true,
      flex: 0.8,
      renderHeader: (params) => (
        <HeaderWithFilterIcon {...params} selectedFilters={selectedFilters} />
      ),
      renderCell: ({ value }) => {
        if (!value) return null;
        const iconName =
          value === "positive" ? "arrow-upward" : "arrow-downward";
        const label = value === "positive" ? "Up" : "Down";

        return (
          <>
            <Icon name={iconName} size="18px" /> {label}
          </>
        );
      },
    },
    {
      ...baseColumnOptions,
      field: "status",
      headerAlign: "center",
      align: "center",
      headerName: "Status",
      // This custom config key is added for hiding the Sort controls on the menu filter
      hideSortOptions: true,
      // This custom config key is added for showing the custom Pipeline Frequency Filter
      showCustomFilter: true,
      filterable: true,
      renderHeader: (params) => (
        <HeaderWithFilterIcon {...params} selectedFilters={selectedFilters} />
      ),
      renderCell: ({ value, row }) => {
        return (
          <Stack direction="row" gap={1}>
            {row.status.pipeline_runs.map((pipeline, index) => (
              <PipelineHoverPopover
                key={index}
                pipeline={pipeline}
                metric={row.kpi_display_name}
              />
            ))}
          </Stack>
        );
      },
    },
    ,
    {
      ...baseColumnOptions,
      field: "last_run",
      headerName: "Last Analysed",
      headerAlign: "center",
      align: "center",
      flex: 0.7,
      valueGetter: ({ params, row }) => {
        const dates = row.status.pipeline_runs
          .filter((pipeline) => pipeline.last_run)
          .map((date) => date.last_run);

        if (dates && dates.length > 0) {
          const maxDate = max(dates.map((date) => parseISO(date)));
          return maxDate;
        } else {
          return null;
        }
      },
      valueFormatter: ({ value }) =>
        value && generateDifferenceInTimeString(value),
      renderCell: ({ value, formattedValue }) => {
        if (!value) return (
          <Stack maxWidth={120} whiteSpace="break-spaces">
            Never
          </Stack>
        );
        return (
          <Stack maxWidth={120} whiteSpace="break-spaces">
            {formattedValue}
          </Stack>
        );
      },
    },
    {
      ...baseColumnOptions,
      field: "last_insight_ts",
      headerName: "Latest Insights",
      headerAlign: "center",
      align: "center",
      flex: 0.7,
      valueGetter: ({ row }) => {
        return row.status.last_insight_ts;
      },
      valueFormatter: ({ value }) =>
        value && generateDifferenceInTimeString(value),
      renderCell: ({ formattedValue, row }) => {
        if (!formattedValue) return (
          <Stack maxWidth={120} whiteSpace="break-spaces">
            No Insight
          </Stack>
        );
        return (
          <StyledAnchor
            href={`/insights/${row.status.last_insight_id}`}
            target="_blank"
          >
            <Stack maxWidth={130} whiteSpace="break-spaces">
              {formattedValue}
            </Stack>
          </StyledAnchor>
        );
      },
    },
  ];
}

function getRowId(row) {
  return row.kpi_id;
}

const MetricListScreen = () => {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [sortModel, setSortModel] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    is_active: [true],
  });
  const selectedTenant = useSelectedTenant();
  const tenantGlobalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);
  const { loading, metrics } = useSelector((state) => state.metricList);
  const columns = useMemo(
    () => createColumns({ selectedFilters }),
    [selectedFilters]
  );

  function handlePageSizeChange(newPageSize) {
    setPageSize(newPageSize);
    setPageNumber(0);
  }

  useEffect(() => {
    const queryParams = {
      tenant_id: selectedTenant,
      count: pageSize,
      page_num: pageNumber + 1,
      dim_name: tenantGlobalFilter?.dimension_name,
      dim_val: tenantGlobalFilter?.dimension_value,
    };
    // Add sort by and sorting order, only when user select
    // By default it will not be sent
    if (!isEmpty(sortModel)) {
      Object.assign(queryParams, {
        sort_by: sortModel[0].field,
        order: sortModel[0].sort,
      });
    }

    if (!isEmpty(selectedFilters)) {
      Object.assign(queryParams, selectedFilters);
    }

    dispatch(listMetrics(queryParams));
  }, [
    dispatch,
    pageNumber,
    pageSize,
    selectedFilters,
    selectedTenant,
    sortModel,
    tenantGlobalFilter,
  ]);

  return (
    <Container
      maxWidth="100%"
      sx={(theme) => ({
        py: { sm: theme.spacing(5), sl: theme.spacing(10) },
        px: { sm: "5%", sl: "5%" },
      })}
    >
      <Box sx={{ width: "100%", height: 630}}>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
            ColumnMenu: MetricsColumnMenu,
          }}
          componentsProps={{
            columnMenu: {
              selectedTenant,
              sortModel,
              setSortModel,
              selectedFilters,
              setSelectedFilters,
            },
            pagination: {
              showFirstButton: true,
              showLastButton: true,
            },
          }}
          columns={columns}
          getRowId={getRowId}
          loading={loading}
          onPageChange={(newPageNumber) => setPageNumber(newPageNumber)}
          onPageSizeChange={handlePageSizeChange}
          page={pageNumber}
          pageSize={pageSize}
          pagination
          paginationMode="server"
          rowCount={metrics?.count || 0}
          rows={metrics?.rows || []}
          rowsPerPageOptions={ROWS_PER_PAGE}
          sortingMode="server"
          sortingOrder={SORTING_ORDER}
          sortModel={sortModel}
          sx={{
            ["& .MuiDataGrid-columnHeaders"]: {
              backgroundColor: colors.gray[100],
            },
            backgroundColor: colors.white
          }}
        />
      </Box>
    </Container>
  );
};

export default MetricListScreen;
