// React Imports
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// MUI Imports
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useMediaQuery } from "@mui/material";

// Local Imports
import { ActionToggleButton } from "../styled-components/ActionToggleButton.styled";
import CalendarModal from "../CalendarModal";
import { GTAG_EVENTS, sendDataToGTM } from "../../../utils/gtmHelper";
import { ERROR, SUCCESS } from "../../../constants/notificationConstants";
import { theme } from "../../common/styled-components/theme";
import {
  OPEN,
  HOME,
  IMPORTANT,
  SNOOZED,
  ARCHIVED,
  FLAGGED,
  RESOLVED,
} from "../../../constants/userConstants";
import Icon from "../Icon";
import { colors } from "../../../shared/theme-constants";
import { StyledTooltip } from "../styled-components/Tooltip.styled";
import {
  updateGroupArchive,
  updateGroupImportant,
  updateGroupOpen,
} from "../../../actions/groupInsightActions";
import { InsightContext } from "../../../context/InsightContext";

export default function ActionList({
  insightList,
  groupId,
  handleClickActionIcon,
  isGroup,
}) {
  const dispatch = useDispatch();
  const insightContext = useContext(InsightContext);
  const selectedFolder = useSelector((state) => state.drawer.menuItem);
  const isTeamAction = useSelector(
    (state) => state.teamInsightList.selectedInsights
  );
  const matchesSmallMobile = useMediaQuery(`(max-width:${theme.smallMobile})`);
  const [insightAction, setInsightAction] = useState(selectedFolder);
  const [showCalendar, setShowCalendar] = useState(false);

  const handleAction = (_event, selectedAction) => {
    // open sidebar just to show visually to user where his action is ending
    // set animation time in ms
    handleClickActionIcon(selectedAction, 1200);
    setInsightAction(selectedAction);

    /**
     * This variable is added to solve the selection issue, wherein if a user would mark an insight as Important, Snoozed or Archived,
     * the insight below it would be marked with the same state after the selected insight is removed from the current folder
     * case 1: selectedFolder = Home, user marks an insight as Important, then the applicable folder should remain as open
     * case 2: selectedFolder = Important, user marks an insight as Archived, then the applicable folder should remain as Important
     */
    switch (selectedAction) {
      case IMPORTANT:
        return dispatch(
          updateGroupImportant(insightList, groupId, {
            old_state: selectedFolder,
            current_state: selectedAction,
          })
        ).then((isSuccess) => {
          handleSuccess(IMPORTANT, selectedFolder, isSuccess);
        });
      case SNOOZED:
        setInsightAction(selectedFolder);
        setShowCalendar(true);
        return null;
      case ARCHIVED:
        return dispatch(
          updateGroupArchive(insightList, groupId, {
            old_state: selectedFolder,
            current_state: selectedAction,
          })
        ).then((isSuccess) => {
          handleSuccess(ARCHIVED, selectedFolder, isSuccess);
        });
      default:
        return dispatch(
          updateGroupOpen(insightList, groupId, {
            old_state: selectedFolder,
            current_state: OPEN,
          })
        ).then((isSuccess) => {
          handleSuccess(OPEN, selectedFolder, isSuccess);
        });
    }
  };

  /**
   * The success callback for the dispatch functions
   * @param {*} folderName
   * @param {*} applicableState
   */
  const handleSuccess = (folderName, applicableState, isSuccess) => {
    let message = "";

    if (isSuccess) {
      const displayFolderName = folderName === OPEN ? HOME : folderName;

      message = `This item was moved to your ${displayFolderName} feed. Click the ${displayFolderName} icon to the left to revisit it`;
    } else {
      message = `There was an error while marking this insight as ${folderName}`;
    }

    dispatch({ type: isSuccess ? SUCCESS : ERROR, payload: message });
    setInsightAction(applicableState);
    invokeGTM(isSuccess, folderName);
  };

  const invokeGTM = (isSuccess, folderName) => {
    const gTagArgs = isGroup
      ? {
          event: GTAG_EVENTS.GROUP_INSIGHT_STATE_ACTIONS,
          data: {
            old_state: selectedFolder,
            current_state: folderName,
            group_id: groupId,
          },
        }
      : {
          event: GTAG_EVENTS.INSIGHT_STATE_ACTIONS,
          data: {
            old_state: selectedFolder,
            current_state: folderName,
            group_id: groupId,
            pattern_type: insightContext.patternData?.pattern?.pattern_type,
            kpi_accent: insightContext.patternData?.pattern?.accent,
            dim_name: insightContext.patternData?.pattern?.dim_name,
            dim_value: insightContext.patternData?.pattern?.dim_val,
            kpi_name: insightContext.patternData?.pattern?.kpi_display_name,
            insight_severity: insightContext.insightSummary?.insightSeverity,
          },
        };

    if (isSuccess) {
      sendDataToGTM(gTagArgs.event, {
        insight_state_actions: gTagArgs.data,
      });
    }
  };

  const fillColor = (selectedAction, border = false) => {
    if (!insightAction) return;
    return insightAction === selectedAction || selectedFolder === selectedAction
      ? border
        ? colors.blue[300]
        : colors.blue[500]
      : "";
  };

  const showTeamActions = [FLAGGED, RESOLVED].includes(selectedFolder);
  const showUserActions = !showTeamActions;

  return (
    <>
      {showCalendar && (
        <CalendarModal
          close={() => setShowCalendar(false)}
          selectedInsightState={selectedFolder}
          groupId={groupId}
          insightList={insightList}
          showSuccessNotification={(result) =>
            handleSuccess(SNOOZED, selectedFolder, result)
          }
        />
      )}

      {showUserActions && (
        <ToggleButtonGroup
          aria-label="individual state"
          disabled={isTeamAction.length > 0}
          accessKey={groupId}
          value={insightAction}
          exclusive
          onChange={handleAction}
          sx={{ pr: 1 }}
        >
          <StyledTooltip title={IMPORTANT} placement="left-end" key={IMPORTANT}>
            <ActionToggleButton value={IMPORTANT} aria-label={IMPORTANT}>
              <Icon
                name={IMPORTANT}
                size={matchesSmallMobile ? "11px" : "15px"}
                strokecolor={fillColor(IMPORTANT)}
                color={fillColor(IMPORTANT, true)}
                showcursor="true"
              />
            </ActionToggleButton>
          </StyledTooltip>
          <StyledTooltip title={"Snooze"} placement="left-end" key={SNOOZED}>
            <ActionToggleButton value={SNOOZED} aria-label={SNOOZED}>
              <Icon
                name={SNOOZED}
                size={matchesSmallMobile ? "11px" : "15px"}
                showcursor="true"
                strokecolor={fillColor(SNOOZED)}
                color={fillColor(SNOOZED, true)}
              />
            </ActionToggleButton>
          </StyledTooltip>
          <StyledTooltip title={"Archive"} placement="left-end" key={ARCHIVED}>
            <ActionToggleButton value={ARCHIVED} aria-label={ARCHIVED}>
              <Icon
                name={ARCHIVED}
                size={matchesSmallMobile ? "11px" : "15px"}
                showcursor="true"
                strokecolor={fillColor(ARCHIVED)}
                color={fillColor(ARCHIVED, true)}
              />
            </ActionToggleButton>
          </StyledTooltip>
        </ToggleButtonGroup>
      )}

      {showTeamActions && (
        <ActionToggleButton disabled value={selectedFolder}>
          <Icon
            name={selectedFolder}
            size="15px"
            showcursor="true"
            strokecolor={colors.gray[500]}
          />
        </ActionToggleButton>
      )}
    </>
  );
}
