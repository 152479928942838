import { colors } from "../theme-constants";

export const overlaySeriesPlot = {
  type: "scatter",
  marker: {
    size: 7,
    symbol: "circle",
  },
  line: {
    color: colors.blue[900],
    dash: "solid",
    width: 2,
  },
  mode: "lines+markers",
};

export const overlayForecastPlot = {
  mode: "lines",
  line: {
    color: colors.blue[300],
    width: 0,
  },
};

export const overlayChartLayout = {
  title: {
    font: {
      color: colors.gray[500],
      family: "Poppins",
      size: 18,
    },
  },
  ticks: "outside",
  showlegend: true,
  margin: {
    t: 30,
    pad: 15,
  },
  // For responsive chart
  autosize: true,
  hovermode: "x unified",
  hoverlabel: {
    bgcolor: "#fff",
    bordercolor: colors.gray[200],
    font: {
      family: "Inter",
      color: "#000",
      size: "12px",
    },
    namelength: -1,
  },
  legend: {
    x: 1.055,
    y: 1,
    font: {
      color: colors.gray[500],
      size: 12,
    },
    traceorder: "reversed",
    itemsizing: "constant",
    orientation: "h",
    yanchor: "bottom",
    xanchor: "right",
    valign: "top",
  },
  xaxis: {
    type: "date",
    showline: false,
    showgrid: false,
    fixedrange: true,
    title: {
      text: "",
      font: {
        color: colors.gray[300],
      },
    },
    tickfont: {
      color: colors.gray[500],
    },
  },
  yaxis: {
    showline: false,
    zeroline: true,
    fixedrange: true,
    zerolinecolor: colors.gray[100],
    title: {
      text: "",
      font: {
        color: colors.gray[500],
      },
    },
    tickfont: {
      color: colors.gray[500],
    },
  },
};
