import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

import { Box } from "@mui/material";
import FeedContainer from "../components/FeedContainer";
import TimeFilter from "../components/feed/TimeFilter";
import InsightsFeed from "../components/insights/InsightsFeed";
import SummaryCard from "../components/summary/SummaryCard/SummaryCard";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";

const FeedScreen = () => {
  const { isDrawerOpen } = useOutletContext();
  const [highlightedValue, setHighlightedValue] = useState();
  const [animationTime, setAnimationTime] = useState(0);
  const timeFilter = useSelector((state) => state.feedScreenTimeFilter);

  const isTimeFilterApplied = timeFilter.type !== "initial";

  /**
   * open sidebar just to show visually to user where his action is ending
   * set value of sidebar draw to highlight
   * set animation time
   */
  const handleClickActionIcon = useCallback((value, time) => {
    setHighlightedValue(value);
    setAnimationTime(time);
  }, []);

  return (
    <FeedContainer handleClickActionIcon={handleClickActionIcon}>
      {/* <SideBar
        drawerOpen={isDrawerOpen}
        highlightedDraw={highlightedValue}
        animationTime={animationTime}
      /> */}
      <SummaryCard />

      {isTimeFilterApplied && (
        <Box mt="16px">
          <TimeFilter timeFilter={timeFilter} />
        </Box>
      )}

      <InsightsFeed handleClickActionIcon={handleClickActionIcon} />
    </FeedContainer>
  );
};

export default FeedScreen;
