import React from "react";
import { Stack } from "@mui/material";
import { labels } from "../../../shared/intl/labels";
import {
  LiftCardContainer,
  RowSeparator,
  RowStack,
  RowTypography,
  RowTypographyBold,
} from "../styled-components/LiftPercentage.styled";
import LiftBasicCard from "./LiftBasicCard";
import LiftFooter from "./LiftFooter";

const LiftTrendCard = ({ data, derivedNonPercentage, normalizedView }) => {
  const kpiName = data.kpi_display_name;

  /**
   * Trend unit contains `KPI Name/{unit}`, but we need to display `per {unit}` instead.
   * Ex: `Shop Views/Day` or `Orders/Day`
   * This function will extract the {unit} from trend unit
   * @returns trend unit without kpi name
   */
  const showTrendUnit = () => {
    // trend_unit is titlecase, and we need to show in lowercase
    const trendUnit = data.pattern_data?.trend_unit?.toLowerCase() ?? "";
    if (!trendUnit) return "";

    const indexOfSlash = trendUnit.indexOf("/");

    return ` per ${trendUnit.substr(
      // we don't want to show `/` either, hence omit it
      indexOfSlash + 1,
      trendUnit.length
    )}`;
  };

  return (
    <LiftBasicCard
      derivedNonPercentage={derivedNonPercentage}
      normalizedView={normalizedView}
      formattedNormalizedValue={data.formatted_normalization_string}
      kpiName={kpiName}
    >
      <LiftCardContainer>
        <RowStack direction="row">
          <Stack direction="column">
            <RowTypography>
              {labels.LIFT_CALCULATION.TREND.NEW_RATE}
            </RowTypography>
          </Stack>
          <Stack direction="column">
            <RowTypography>{data.formatted_current_value}</RowTypography>
          </Stack>
        </RowStack>
        <RowSeparator></RowSeparator>
        <RowStack direction="row">
          <Stack direction="column">
            <RowTypography>
              {labels.LIFT_CALCULATION.TREND.OLD_RATE}
            </RowTypography>
          </Stack>
          <Stack direction="column">
            <RowTypography>{data.formatted_expected_value}</RowTypography>
          </Stack>
        </RowStack>
        <RowSeparator></RowSeparator>
        <RowStack direction="row">
          <Stack direction="column" mb="10px">
            <RowTypographyBold>
              {labels.LIFT_CALCULATION.DIFFERENCE}
            </RowTypographyBold>
          </Stack>
          <Stack direction="column" mb="10px">
            <RowTypographyBold>
              {data.formatted_difference_value}
            </RowTypographyBold>
          </Stack>
        </RowStack>
        {/* Value at change point should only be displayed for derived-non-percentage metric */}
        {derivedNonPercentage && (
          <RowStack direction="row">
            <Stack direction="column" mt="10px">
              <RowTypography>
                {labels.LIFT_CALCULATION.TREND.VALUE_AT_CHANGE_POINT}
              </RowTypography>
            </Stack>
            <Stack direction="column" mt="10px">
              <RowTypography>
                {data.pattern_data.formatted_value_at_change_point}
              </RowTypography>
            </Stack>
          </RowStack>
        )}
        {/* Post period, Total delta to date, and Trailing sum should only be displayed for non-derived-non-percentage metric */}
        {normalizedView && (
          <>
            <RowStack direction="row">
              <Stack direction="column" mt="10px">
                <RowTypography>
                  {labels.LIFT_CALCULATION.TREND.POST_PERIOD}
                </RowTypography>
              </Stack>
              <Stack direction="column" mt="10px">
                <RowTypography>{data.pattern_data.post_period}</RowTypography>
              </Stack>
            </RowStack>
            <RowStack direction="row">
              <Stack direction="column">
                <RowTypography>
                  {labels.LIFT_CALCULATION.TREND.TOTAL_DELTA_TO_DATE}
                </RowTypography>
              </Stack>
              <Stack direction="column">
                <RowTypography>
                  {data.pattern_data.formatted_delta_to_date}
                </RowTypography>
              </Stack>
            </RowStack>
            {data.formatted_trailing_sum && (
              <RowStack direction="row">
                <Stack direction="column">
                  <RowTypography>
                    {labels.LIFT_CALCULATION.TRAILING_SUM}
                  </RowTypography>
                </Stack>
                <Stack direction="column">
                  <RowTypography>{data.formatted_trailing_sum}</RowTypography>
                </Stack>
              </RowStack>
            )}
          </>
        )}
      </LiftCardContainer>
      <LiftFooter>
        {data.formatted_lift_percentage}
        {/* Trend unit should only be displayed for derived-non-percentage metric */}
        {derivedNonPercentage && showTrendUnit()}
      </LiftFooter>
    </LiftBasicCard>
  );
};

export default LiftTrendCard;
