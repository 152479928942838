import { forwardRef } from "react";
import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import { colors } from "../../../../shared/theme-constants";

export const LoginContainer = styled.div`
  background-image: url("/images/login_bg.png");
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  max-height: 100%;
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: space-between;
`;

export const AppLogo = styled.div`
  z-index: 999;
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.ipadPro}) {
    justify-content: left;
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    justify-content: left;
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    justify-content: left;
  }
`;

export const LoginBody = styled.div`
  min-height: calc(100vh - 68px);
  padding: 61px 68px 0;

  @media (max-width: ${({ theme }) => theme.ipadPro}) {
    padding: 45px 61px 0;
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    min-height: calc(100vh - 36px);
    padding: 45px 61px 0;
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    min-height: calc(100vh - 36px);
    padding: 45px 30px 0;
    text-align: center;
  }
`;

export const LoginContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  padding-top: 26vh;

  @media (max-width: ${({ theme }) => theme.web}) {
    padding-top: 20vh;
  }

  @media (max-width: ${({ theme }) => theme.ipadPro}) {
    flex-direction: column;
    max-width: 440px;
    margin: 0 auto;
    padding-top: 18vh;
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
    padding-top: 25vh;
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    flex-direction: column;
    padding-top: 25vh;
  }
`;

export const LoginText = styled.div`
  width: 442px;
  display: inline-block;
  margin-right: 193px;

  @media (max-width: ${({ theme }) => theme.ipadPro}) {
    width: 100%;
    margin-right: 0;
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    margin-right: 0;
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    width: 96%;
    margin-right: 0;
  }
`;
export const LoginButton = styled.div`
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.ipadPro}) {
    justify-content: center;
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    justify-content: center;
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    justify-content: center;
    width: 100%;
  }
`;

export const AnchorStyle = styled.a`
  text-decoration: none;
`;

export const SignInButton = styled.button`
  margin-top: 34px;
  height: 55.3875732421875px;
  margin-right: 7px;
  width: 198px;
  left: 1033px;
  top: 609.778076171875px;
  border-radius: 15px 15px 15px 15px;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  color: #112785;
  line-height: 37px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    height: 60px;
    width: 174px;
    font-size: 0.8rem;
    padding: unset;
  }
  @media (max-width: ${({ theme }) => theme.ipadPro}) {
    margin-top: 55px;
    margin-right: 11px;
    height: 60px;
    width: 174px;
    font-size: 1.3rem;
    padding: unset;
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-right: 7px;
    margin-top: 27px;
    height: 35px;
    width: 104px;
    border-radius: 10px;
    font-size: 0.8rem;
    padding: unset;
  }
`;

export const SignUpButton = styled.button`
  margin-top: 34px;
  height: 55.3875732421875px;
  margin-left: 7px;
  width: 198px;
  left: 1033px;
  top: 609.778076171875px;
  border-radius: 15px 15px 15px 15px;
  border: none;
  background: #68d1bf;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  color: #112785;
  line-height: 37px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    height: 60px;
    width: 174px;
    font-size: 0.8rem;
    padding: unset;
  }
  @media (max-width: ${({ theme }) => theme.ipadPro}) {
    margin-top: 55px;
    height: 60px;
    width: 174px;
    font-size: 1.3rem;
    padding: unset;
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 27px;
    height: 35px;
    width: 104px;
    border-radius: 10px;
    font-size: 0.8rem;
    padding: unset;
  }
`;

export const SignInContainer = styled.form`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  flex: 1 0 auto;
`;

export const SignInButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 210px;
  }
`;
export const EmailInputContainer = styled.input.attrs((props) => ({
  type: props.type,
  placeholder: props.placeholder,
}))`
  /* Auto Layout */
  outline: 0;
  border: none;
  background: none;
  background-size: 24px 14px;
  height: 50px;
  width: 90%;
  left: 1038.74267578125px;
  color: white !important;
  font-size: 23px;
  top: 574.2108154296875px;
  border-radius: 0px;
  align-self: center;
  &::placeholder {
    color: #ffffff;
    font-size: 23px;
    width: 88px;
    height: 36px;
    left: 1079px;
    top: 532px;
    padding-bottom: 6.21px;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
    background: url("/icons/email.svg") no-repeat left;
    -webkit-text-fill-color: white !important;
  }

  @media (max-width: ${({ theme }) => theme.tablet}) {
    width: auto;
  }
  @media (max-width: ${({ theme }) => theme.ipadPro}) {
    width: 360px;
    margin-right: 0;
    color: white;
    font-size: 18px;
    &::placeholder {
      font-size: 18px;
      width: 88px;
      height: 36px;
      padding-bottom: 5.3px;
    }
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 210px;
    margin-right: 0;
    color: white;
    font-size: 11px;
    padding-left: 16px;
    &::placeholder {
      font-size: 11px;
      width: 88px;
      height: 36px;
      padding-bottom: 4.4px;
    }
  }
`;

export const EmailIconInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid white;
  border-width: 0 0 1.54px;
  border-color: #ffffff;
  width: 97%;
  margin-left: 7px;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    margin-left: 30px;
    width: 86%;
  }
  @media (max-width: ${({ theme }) => theme.ipadPro}) {
    margin: 0 auto;
    width: 85%;
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 50%;
  }
  @media (max-width: 550px) {
    width: 60%;
  }
  @media (max-width: 385px) {
    width: 80%;
  }
  @media (max-width: 320px) {
    width: 100%;
  }
`;

export const EmailIcon = styled.div`
  background-image: url("/icons/email.svg");
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  width: 6%;
  margin-right: 7px;
  height: 14px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 15%;
    margin: 0;
  }
`;

export const LogoWithName = muiStyled(
  forwardRef(function LogoWithName(props, ref) {
    return (
      <img
        src="/images/all-white-logo.svg"
        alt="Out Of The Blue logo"
        {...props}
        ref={ref}
      />
    );
  })
)({
  height: 80,
  color: colors.white,
});
