import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Icon from "./Icon";

const businessHealthColorMap = {
  Good: "green",
  Soft: "red",
  Neutral: "var(--comet)",
};
const BusinessHealthIndicator = ({ health, ...props }) => {
  return (
    <Stack direction="row" alignItems="center" marginLeft="20px" gap={4}>
      <Typography sx={{ color: businessHealthColorMap[health], fontSize: 30 }}>{health}</Typography>
      <Box>
        <Icon name ={`${health}-business-health`} ></Icon>
      </Box>
    </Stack>
  );
};

export default BusinessHealthIndicator;
