import styled from "styled-components";
import { colors } from "../../../../shared/theme-constants";

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  margin-bottom: 5px;
  white-space: nowrap;
  min-width: 108px;
  width: fit-content;

  font-weight: 400;
  font-size: 12px;
  color: ${colors.white_secondary};
  background: ${({ bgColor }) => bgColor};
  border-radius: 4px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 5px;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    height: 20px;
  }
`;
