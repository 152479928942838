import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import {
  collaborateFilterReducer,
  collapseTableNotifierReducer,
  columnMetaDataReducer,
  createMetricsReducer,
  digestScreenTimeFilterReducer,
  drawerReducer,
  eventHistoryReducer,
  feedScreenTimeFilterReducer,
  groupInsightsListReducer,
  insightListReducer,
  modalReducer,
  notificationReducer,
  notificationSnackbarReducer,
  selectedTenantReducer,
  teamReducer,
  tenantConfigReducer,
  tenantGlobalFilterReducer,
  uploadStatusReducer,
  userLoginReducer,
  eventsFeedFilterReducer
} from "./reducers";

import {
  metricCreateReducer,
  metricDeleteReducer,
  metricDetailsReducer,
  metricListReducer,
  metricUpdateReducer,
  relatedMetricsReducer,
} from "./reducers/metricReducer";

import { generateAPIKeyReducer, updateProfileDetailsReducer, updateUserPreferenceReducer } from './reducers/preferenceReducer';

import { groupEventsListReducer } from './reducers/eventsReducer';

const reducer = combineReducers({
  insightList: insightListReducer,
  userLogin: userLoginReducer,
  drawer: drawerReducer,
  eventHistory: eventHistoryReducer,
  teamInsightList: teamReducer,
  // Remove the below reducer once we completely move towards notification snackbar reducer
  notification: notificationReducer,
  notificationSnackbar: notificationSnackbarReducer,
  feedScreenTimeFilter: feedScreenTimeFilterReducer,
  groupInsightsList: groupInsightsListReducer,
  modal: modalReducer,
  selectedTenant: selectedTenantReducer,
  collaborateFilters: collaborateFilterReducer,
  digestScreenTimeFilter: digestScreenTimeFilterReducer,
  // This reducer is added as a hacky solution to solve the dynamic height issue of `react-responsive-carousel` library
  collapseTable: collapseTableNotifierReducer,

  metricList: metricListReducer,
  metricDetails: metricDetailsReducer,
  metricDelete: metricDeleteReducer,
  metricCreate: metricCreateReducer,
  metricUpdate: metricUpdateReducer,
  relatedMetric: relatedMetricsReducer,
  // Onboarding data below
  onBoardingColumnDetails: columnMetaDataReducer,
  onBoardingCreateMetrics: createMetricsReducer,
  onBoardingUploadStatus: uploadStatusReducer,
  updateUserPreference: updateUserPreferenceReducer,
  updateProfileDetails: updateProfileDetailsReducer,
  apiKeyReducer: generateAPIKeyReducer,
  groupEventsList: groupEventsListReducer,
  tenantConfigs: tenantConfigReducer,
  tenantGlobalFilter : tenantGlobalFilterReducer,
  eventsFeedFilters: eventsFeedFilterReducer
});

const initialState = { userLogin: { loading: true, user: null, tenants: [] } };

const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
