import styled from "styled-components";

export const EventHistoryModalContainer = styled.div`
  overflow: auto;
  height: 490px;
  margin-top: 15px;
`;

export const eventHistoryModalStyle = {
  minWidth: "350px",
  height: "563px",
  top: "15%",
  background: "#FFFFFF",
  borderRadius: "16px",
};

export const eventHistoryListStyle = {
  width: "100%",
  maxWidth: 360,
  padding: 0,
  bgcolor: "background.paper",
};

export const eventHistoryListItemStyle = {
  alignItems: "center",
  padding: "unset",
  paddingLeft: "16px",
};

export const errorMessageFontStyle = {
  display: "inline",
  margin: "5px",
};

export const eventHistoryListIcon = {
  background: "#F3F5F6",
  width: "70px",
  height: "70px",
};

export const titleFontStyle = {
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "23px",
  display: "flex",
  alignItems: "center",
  padding: "20px 0 0 20px",
  color: "#000000",
};

export const secondaryFontStyle = {
  display: "inline",
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "15px",
  color: "#545C7E",
};

export const primaryFontStyle = {
  display: "inline",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "23px",
  letterSpacing: "-0.02em",
};
