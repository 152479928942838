import styled from "@emotion/styled";
import { Box, Button, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getIsUserLoggedIn } from "../../actions/userActions";
import { typography } from "../../shared/theme-constants";
import Icon from "../common/Icon";

const Title = styled("div")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(35),
  fontWeight: typography.font_weight.mediumx,
  lineHeight: theme.typography.pxToRem(53),
  letterSpacing: "0em",
  textAlign: "center",
  color: "#5C6178",
  margin: "3% 0 3% 0",
}));
const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  width: "100%",
  height: "53vh",
  background: "var(--zircon)",
  boxShadow: "0px 4px 22px rgba(17, 39, 133, 0.25)",
  borderRadius: theme.typography.pxToRem(10),
  marginBottom: theme.typography.pxToRem(20),
}));

const ActionButton = styled(Button)(({ theme, textcolor, bgcolor }) => ({
  width: "100px",
  height: "41px",
  padding: theme.spacing(0, 7),
  borderRadius: "9px",
  border: "1.5px",
  background: "linear-gradient(0deg, #245AE6, #245AE6)",
  color: "var(--zircon)",
  ":hover": {
    cursor: "pointer",
  },
  alignSelf: "center",
}));
const FinishOnboarding = () => {
  const [activeStep, setActiveStep] = useOutletContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => setActiveStep(4));

  const handleNext = () => {
    dispatch(getIsUserLoggedIn()).then((response) => {
      navigate("/metrics");
    });
  };
  return (
    <Stack sx={{ pl: 20, pr: 20 }}>
      <Box sx={{ maxWidth: "1035px", width: "100%", alignSelf: "center" }}>
        <Title>You are all Set!!</Title>
        <StyledBox>
          <Stack gap={10} sx={{ pl: 10, pr: 10, width: "100%", maxWidth: "900px", justifyContent: "center", alignItems: "center" }}>
            <Icon name="upload-success"></Icon>
            <span style={{ fontSize: typography.font_size["2xl"], textAlign: "center", color: "var(--comet)" }}>
              Excellent job! <br></br>We are currently downloading and processing your data. We will send you an email once your insights are ready and your feed is live.
            </span>
          </Stack>
        </StyledBox>
        <Stack direction="row" sx={{ justifyContent: "end" }}>
          <ActionButton onClick={handleNext}>Next</ActionButton>
        </Stack>
      </Box>
    </Stack>
  );
};

export default FinishOnboarding;
