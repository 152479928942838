import styled from "styled-components";

export const FeedTitle = styled.div`
  max-width: 100%;
  min-width: 300px;
  margin-left: -70px;
  height: 80px;
  position: fixed;
  top: 70px;
  z-index: 2;
  padding: 10px 0 0 30px;
  width: 100%;
  color: #112785;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  background: linear-gradient(
    180deg,
    #e5ecfd 75.67%,
    rgba(241, 245, 254, 0) 100%
  );
  @media (max-width: ${({ theme }) => theme.tablet}) {
    margin-left: -60px;
  }
`;

export const DaySeparator = styled.div`
  max-width: 100%;
  min-width: 300px;
  margin-left: -70px;
  height: 60px;
  position: relative;
  z-index: 1;
  padding: 10px 0 0 70px;
  width: 100%;
  color: #112785;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    margin-left: -60px;
  }
`;
