import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import {
  ColoredBoldMetric,
  SemiBold,
} from "../common/styled-components/insight-summay/Title.styled";
import { ArrowForwardIcon } from "../common/Icons";
import { deserializeToJSX, parseMarkdown } from "../../services/markdown";
import { colors, typography } from "../../shared/theme-constants";
import { labels } from "../../shared/intl/labels";
import { format } from "date-fns";

const StyledGroupTitle = styled("div")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(30),
  color: theme.palette.secondary.main,
  padding: "16px 0",
}));

const StyledCellStack = styled(Stack)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textAlign: "center",

  "& .value": {
    fontSize: theme.typography.pxToRem(19),
    textTransform: "capitalize",
  },

  "& .label": {
    fontSize: theme.typography.pxToRem(10),
    textDecoration: "underline",
  },
}));

const StyledLinkContainer = styled(Paper)({
  boxShadow: "0px 3px 24px rgba(0, 0, 0, 0.15)",
  textAlign: "center",
  borderRadius: 10,
  width: 60,
  marginLeft: "auto",

  "&:hover": {
    boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.15)",
  },
});

const StyledAnchor = styled("a")({
  display: "inline-flex",
  padding: "10px 6px",
  textDecoration: "none",
  color: colors.gray[400],
});

function createSerializer(accent) {
  return {
    bold: ({ value }) => (
      <ColoredBoldMetric accent={accent}>{value}</ColoredBoldMetric>
    ),
    link: ({ value }) => value,
  };
}

const StoriesTable = ({ rows }) => {
  return (
    <TableContainer>
      <Table aria-label="table with top stories to focus on">
        <TableBody>
          {rows?.map((row) => (
            <TableRow
              key={row.group_title}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{ padding: 0 }}>
                <StyledGroupTitle>
                  {deserializeToJSX(
                    parseMarkdown(row.group_title),
                    createSerializer(row.group_accent)
                  )}
                </StyledGroupTitle>
              </TableCell>

              <TableCell sx={{ width: 140, padding: 0}}>
                <StyledCellStack>
                  <ColoredBoldMetric
                    accent={row.group_accent}
                    className="value"
                  >
                    {row.impact.formatted_value}
                  </ColoredBoldMetric>
                  <span className="label">
                    {row.impact.unit === "$" ? "Monetary" : row.impact.unit}{" "}
                    Impact
                  </span>
                </StyledCellStack>
              </TableCell>

              <TableCell sx={{ width: 140, padding: 0}}>
                <StyledCellStack>
                  <SemiBold className="value">
                    {labels.PIPELINE_SCHEDULE[row.pipeline_schedule]}
                  </SemiBold>
                  <span className="label">Granularity</span>
                </StyledCellStack>
              </TableCell>

              <TableCell sx={{ width: 180, padding: 0}}>
                <StyledCellStack>
                  <SemiBold className="value">
                    {format(new Date(
                      new Date(row.group_ts_end).getTime() + new Date().getTimezoneOffset() * 60 * 1000
                    ), "eee, dd MMM, yyyy")}
                  </SemiBold>
                  <span className="label">
                    {row.pipeline_schedule == 'h' ? format(new Date(
                      new Date(row.group_ts_end).getTime() + new Date().getTimezoneOffset() * 60 * 1000
                    ), "hh:mm a") : ''}
                  </span>
                </StyledCellStack>
              </TableCell>

              <TableCell sx={{ width: 110 }}>
                <StyledLinkContainer>
                  <StyledAnchor
                    href={`/groups/${row.group_id}`}
                    target="_blank"
                  >
                    <ArrowForwardIcon />
                    <div className="sr-only">Go to insight page</div>
                  </StyledAnchor>
                </StyledLinkContainer>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StoriesTable;
