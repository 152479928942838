import { Stack } from "@mui/material";
import React from "react";
import { labels } from "../../../shared/intl/labels";
import {
  FooterStack,
  FooterTypography,
} from "../styled-components/LiftPercentage.styled";

const LiftFooter = ({ children }) => {
  return (
    <FooterStack direction="row">
      <Stack direction="column">
        <FooterTypography>{labels.LIFT_CALCULATION.LIFT}</FooterTypography>
      </Stack>
      <Stack direction="column">
        <FooterTypography>{children}</FooterTypography>
      </Stack>
    </FooterStack>
  );
};

export default LiftFooter;
