import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Icon from "../common/Icon";
import {
  MetricInfoTypography,
  MetricValue,
} from "../common/styled-components/metric/MetricDetails.styled";

const Details = ({ type, value }) => {
  return (
    <Box sx={{ padding: "10px 0" }}>
      <Stack direction="row">
        <div>
          <Icon name="db-container" size="25px" />
        </div>
        <Stack direction="row">
          <MetricInfoTypography>
            {type}
            {": "}
            <MetricValue>{value ?? "NA"}</MetricValue>
          </MetricInfoTypography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Details;
