import { Box } from "@mui/material";
import React from "react";
import { LoaderInCenter } from "../components/common/styled-components";
import { useAxios } from "../hooks/useAxios";
import { useSelectedTenant } from "../hooks/useSelectedTenant";
import { HTTP_STATUS } from "../shared/enums/http-status";
import { colors, typography } from "../shared/theme-constants";
import { useSelector } from "react-redux";

const DataExplorerScreen = () => {
  const selectedTenant = useSelectedTenant();
  const globalFilter = useSelector((state)=> state.tenantGlobalFilter?.appliedFilter);

  const {
    data: dataExplorerData,
    error: error,
    isError: dataExplorerError,
    status: liveGroupsStatus,
    isLoading: loading,
  } = useAxios("data-explorers/generate-url", {
    params: {
      tenant_id: selectedTenant,
      dim_name: globalFilter?.dimension_name,
      dim_val: globalFilter?.dimension_value
    },
  });

  if (loading) return <LoaderInCenter />;
  if (dataExplorerError && error?.response.status === HTTP_STATUS.NOT_FOUND)
    return (
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", margin: "40vh", color: colors.blue[900], fontSize: typography.font_size.lg }}>
        No Data Cubes configured
      </Box>
    );

  return (
    <Box>
      <object style={{ position: "absolute", height: "100%", width: "100%", border: "none" }} data={dataExplorerData?.data_explorer_url}>
        <embed style={{ position: "absolute", height: "100%", width: "100%", border: "none" }} src={dataExplorerData?.data_explorer_url}></embed>
      </object>
    </Box>
  );
};

export default DataExplorerScreen;
