/**
 * Generic template for all overlays with placeholders
 */
export const overlayHeaders = [
  {
    key: "timestamp",
    name: "Timestamp",
  },
  {
    key: "current_y",
    name: "__Metric_Name__ Actual Value",
  },
  {
    key: "yhat_upper",
    name: "Expected Maximum Value",
  },
  {
    key: "yhat_lower",
    name: "Expected Minimum Value",
  },
  {
    key: "period1_y",
    name: "Actual Value __period1_placeholder__",
  },
  {
    key: "period2_y",
    name: "Actual Value __period2_placeholder__",
  },
];
/**
 * Applicable values for the overlay placeholders
 */
export const overlay_placeholders = [
  {
    DoD: {
      period1: "same time Yesterday",
      period2: "same time day before Yesterday",
    },
    WoW: {
      period1: "7 days back",
      period2: "14 days back",
    },
    MoM: {
      period1: "4 weeks back",
      period2: "8 weeks back",
    },
    YoY: {
      period1: "12 months back",
      period2: "24 months back",
    },
  },
];
