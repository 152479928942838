import { subMonths, subWeeks, subDays, subHours } from "date-fns";

export const calculateOverlayDuration = (schedulePattern) => {
    let overlayDuration;

    switch (schedulePattern) {
        case 'hourly':
            overlayDuration = (75 / 100) * 60 * 60 * 1000; // 45 minutes in milliseconds
            break;
        case 'daily':
            overlayDuration = (75 / 100) * 24 * 60 * 60 * 1000; // 18 hours in milliseconds
            break;
        case 'weekly':
            overlayDuration = (75 / 100) * 7 * 24 * 60 * 60 * 1000; // 75% part of a week in milliseconds
            break;
        case 'monthly':
            overlayDuration = (75 / 100) * 30 * 24 * 60 * 60 * 1000; // 75% part of a month in milliseconds
            break;
        default:
            overlayDuration = 0;
            break;
    }

    return overlayDuration;
};

export const getChartStartDuration = (pipelineSchedule, startTime) => {
  let newStartTime;

  // Convert startTime to a Date object
  const startTimeDate = new Date(startTime);

  switch (pipelineSchedule) {
    case "hourly":
      newStartTime = subHours(startTimeDate, 24); // Subtract 24 hours
      break;
    case "daily":
      newStartTime = subDays(startTimeDate, 7); // Subtract 7 days
      break;
    case "weekly":
      newStartTime = subWeeks(startTimeDate, 4); // Subtract 4 weeks
      break;
    case "monthly":
      newStartTime = subMonths(startTimeDate, 4); // Subtract 4 months
      break;
    default:
      // Default to the initial start time if schedule is not recognized
      newStartTime = startTimeDate;
  }

  // Formatting newStartTime into desired format
  const formattedStartTime = newStartTime.toISOString();

  return formattedStartTime;
};
