import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography } from "@mui/material";

const StyledTabs = ({ value, onChange, options, ...delegatedProps }) => {
  const [tabsValue, setTabsValue] = useState(value);

  const handleChange = (event, newValue) => {
    if (tabsValue !== null) {
      setTabsValue(newValue);

      if (typeof onChange === "function") {
        onChange(event, newValue);
      }
    }
  };
  return (
    <Tabs
      value={tabsValue}
      onChange={handleChange}
      TabIndicatorProps={{ style: { backgroundColor: "var(--new-car)" } }}
      {...delegatedProps}
    >
      {options?.map((option) => (
        <Tab
          key={option.value}
          value={option.value}
          label={
            <Typography sx={{ fontSize: 20, color: "var(--new-car)" }}>{option.label}</Typography>
          }
        />
      ))}
    </Tabs>
  );
};

export default StyledTabs;
