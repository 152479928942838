import React from "react";
import { ReactSVG } from "react-svg";
import styled, { css } from "styled-components";

const StyledSVGIcon = styled(ReactSVG)`
  /* change to flex to avoid unnecessary spacing that's present when non flex display is set */
  & > div {
    display: flex;
    ${({ justifycontent })=>
    justifycontent &&
    css`
      justify-content : ${justifycontent}
      `
    }
  }

  svg {
    ${({ size }) =>
      size &&
      css`
        width: ${size};
        height: ${size};
      `}
    ${({ margin }) =>
      margin &&
      css`
        margin: ${margin};
      `}
    ${({ showcursor }) =>
      showcursor === "true" &&
      css`
        cursor: pointer;
      `}
    ${({ transform }) =>
      transform &&
      css`
        transform: ${transform};
      `}
    path {
      ${({ color }) =>
        color &&
        css`
          fill: ${color};
        `}
      ${({ strokecolor }) =>
        strokecolor &&
        css`
          stroke: ${strokecolor};
        `}
    }
    @media (max-width: ${({ theme }) => theme.ipadPro}) {
      ${({ ipsize }) =>
        ipsize &&
        css`
          width: ${ipsize};
          height: ${ipsize};
        `}
    }
    @media (max-width: ${({ theme }) => theme.mobile}) {
      ${({ rsize }) =>
        rsize &&
        css`
          width: ${rsize};
          height: ${rsize};
        `}
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
      ${({ rsize }) =>
        rsize &&
        css`
          width: ${rsize};
          height: ${rsize};
        `}
    }
  }
`;

const Icon = (props) => {
  return (
    <StyledSVGIcon
      data-snippyly-comment-disabled
      src={`/icons/${props.name}.svg`}
      color={props.color}
      strokecolor={props.strokecolor}
      showcursor={props.showcursor || "false"}
      margin={props.margin || "0px"}
      size={props.size}
      rsize={props.rsize}
      ipsize={props.ipsize}
      padding={props.padding || 0}
      transform={props.transform}
      onClick={props.onClick}
      id={props.id}
      justifycontent={props.justifyContent}
    />
  );
};

export default Icon;
