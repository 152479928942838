import styled from "@emotion/styled";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Box, Button, Stack, Typography } from "@mui/material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { createConnector } from "../../actions/onboardingActions";
import { CSV_FILE_FORMAT } from "../../constants/commonConstants";
import { useSelectedTenant } from "../../hooks/useSelectedTenant";
import { typography } from "../../shared/theme-constants";
import Icon from "./Icon";
import { Loading } from "./styled-components";

const Title = styled("div")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(35),
  fontWeight: typography.font_weight.mediumx,
  lineHeight: theme.typography.pxToRem(53),
  letterSpacing: "0em",
  textAlign: "center",
  color: "#5C6178",
  margin: "3% 0 3% 0",
}));
const Subtitle = styled("div")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(23),
  fontWeight: typography.font_weight.regular,
  lineHeight: "35px",
  textAlign: "center",
  color: "#5C6178",
  marginBottom: "2%",
}));

const CSVUploadContainer = styled(Box)(({ theme, dragEnter, bgcolor }) => ({
  boxShadow: "0px 4px 22px 0px #11278540",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  minHeight: theme.typography.pxToRem(430),
  borderRadius: "10px",
  opacity: dragEnter ? 0.6 : null,
  bgcolor: bgcolor,
  marginBottom: "2%",
}));

const SelectedFilePreview = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "relative",
  borderRadius: "10px",
  marginBottom: "10px",
  padding: theme.spacing(3.5, 3.5),
  boxShadow: "0px 4px 22px 0px #11278529",
}));
const ErrorMessageContainer = styled(Box)({
  padding: "4px 16px",
  backgroundColor: "#FFE4E6",
  borderRadius: 4,
  border: "1px solid #E11D48",
});
const ActionButton = styled(Button)(({ theme, textcolor, bgcolor }) => ({
  width: "100px",
  height: "41px",
  padding: theme.spacing(0, 7),
  borderRadius: "9px",
  border: "1.5px",
  background: bgcolor,
  color: textcolor ? textcolor : "white",
  ":hover": {
    cursor: "pointer",
  },
  "&:disabled": {
    background: textcolor ? null : "linear-gradient(0deg, #3063E7, #3063E7),linear-gradient(0deg, #C4C4C4, #C4C4C4)",
    opacity: 0.4,
    color: textcolor ? textcolor : "var(--white)",
  },
  alignSelf: "center",
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#3858E5",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#FFFFFF",
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35, marginLeft: 3}}>
        <Typography variant="body2" color="white" fontSize="18px" fontWeight={typography.font_weight.mediumx}>{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const CsvFileUpload = () => {
  const [activeStep, setActiveStep, selectedConnector, setSelectedConnector, availableConnectors] = useOutletContext();
  const selectedTenant = useSelectedTenant();
  const [uploading, setuploading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fileUploadError, setFileUploadError] = useState(false);
  const [fileAttachementError, setFileAttachementError] = useState(false);
  const [data, setData] = useState(false);
  const [file, setFile] = useState(null);
  const [dragEnter, setDragEnter] = useState(false);
  const onDragEnter = () => setDragEnter(true);
  const onDragLeave = () => setDragEnter(false);
  const uploadStatusPct = useSelector((state) => state.onBoardingUploadStatus.pct);

  useEffect(() => {
    setActiveStep(1);
    // set the connector to CSV when user directly lands on the upload csv page
    setSelectedConnector(availableConnectors.find((connector) => connector?.connector_name === "csv"));
  }, []);
  const onFileSelect = (event) => {
    setDragEnter(false);
    let newFile = event.target.files[0];
    event.target.value = null;
    if (newFile && newFile.type === CSV_FILE_FORMAT) {
      setFileUploadError(false);
      setFileAttachementError(false);
      setFile(newFile);
    } else {
      setFile(null);
      setFileAttachementError(true);
    }
  };

  // prevent default event actions when user stops dragging the file
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  // Fcuntion triggers when user drops the file
  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragEnter(false);
    let newFile = event.dataTransfer.files[0];
    if (newFile && newFile.type === CSV_FILE_FORMAT) {
      setFileUploadError(false);
      setFileAttachementError(false);
      setFile(newFile);
    } else {
      setFile(null);
      setFileAttachementError(true);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    setuploading(true);
    dispatch(createConnector({ connector_uuid: selectedConnector?.connector_uuid, tenant_id: selectedTenant }, true, file))
      .then((response) => {
        if (response.data.success) {
          setData(response.data);
          setuploading(false);
        }
      })
      .catch((error) => {
        setFile(null);
        setuploading(false);
        setFileUploadError(true);
      });
  };
  const handleNext = (event) => {
    if (data && data.redirect.self) {
      navigate(data.redirect.path);
    }
  };
  return (
    <Stack sx={{ padding: "0 5%" }}>
      <Title>
        {fileUploadError
          ? "Oops! There seems to be an error while uploading"
          : uploading
          ? "Please wait while your files upload"
          : data
          ? "Thanks! You're all done"
          : "Select File to upload"}
      </Title>
      <div style={{ maxWidth: "920px", width: "100%", alignSelf: "center", marginBottom: "1%" }}>
        {uploading ? (
          <CSVUploadContainer bgcolor="#112785">
            <Loading width="100px" height="100px" margin="50px auto" bgColor="white"></Loading>
            <span style={{ color: "white", fontSize: "20px", fontWeight: typography.font_weight.mediumx, marginBottom: "inherit"}}> Uploading</span>
            <Box sx={{ width: '70%' }}>
              <LinearProgressWithLabel value={uploadStatusPct} />
            </Box>
          </CSVUploadContainer>
        ) : (
          <CSVUploadContainer
            bgcolor="var(--white)"
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            dragEnter={dragEnter}
            sx={{ border: `${fileUploadError ? "2px solid #D95A5A" : data ? "2px solid #68C07E" : null}` }}
          >
            <Icon name="FileUpload" size="125px" margin="20px" />
            {fileAttachementError && (
              <ErrorMessageContainer>
                <Typography component="span" color="error">
                  Only files with .csv extension are allowed
                </Typography>
              </ErrorMessageContainer>
            )}

            {data ? (
              <>
                <Icon name="upload-success"></Icon>
                <Subtitle>Upload Complete</Subtitle>
              </>
            ) : (
              <>
                {!(fileUploadError || file) && <Subtitle>Drag and drop the file</Subtitle>}
                {file && (
                  <>
                    <SelectedFilePreview>
                      <InsertDriveFileIcon sx={{ mr: 2.5, color: "var(--quick-silver)" }} />
                      <span>{file.name}</span>
                    </SelectedFilePreview>
                  </>
                )}
                {fileUploadError && (
                  <>
                    <Icon name="upload-error"></Icon>
                    <Subtitle>Please try again</Subtitle>
                  </>
                )}
                <Stack direction="row">
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<FileUploadOutlinedIcon sx={{ color: "var(--comet)" }} />}
                    sx={{
                      color: "#5C6178",
                      boxShadow: "0px 4px 22px 0px #11278529",
                      border: "1px solid #C4C4C4",
                      margin: "1rem",
                      fontWeight: typography.font_weight.regular,
                    }}
                  >
                    Choose File
                    <input type="file" accept=".csv" hidden onChange={(event) => onFileSelect(event)} />
                  </Button>
                  <Button
                    sx={{
                      color: "var(--white)",
                      margin: "1rem",
                      fontWeight: typography.font_weight.mediumx,
                      background: "linear-gradient(0deg, #245AE6, #245AE6)",
                      ":disabled": { background: "linear-gradient(0deg, #3063E7, #3063E7),linear-gradient(0deg, #C4C4C4, #C4C4C4)", opacity: 0.4, color: "white" },
                    }}
                    onClick={handleClick}
                    disabled={file ? false : true}
                  >
                    Upload
                  </Button>
                </Stack>
              </>
            )}
          </CSVUploadContainer>
        )}
        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          <ActionButton disabled={data ? true : false} textcolor="var(--mariner)" bgcolor="var(--white)" onClick={() => navigate(-1)}>
            Back
          </ActionButton>
          <ActionButton bgcolor="linear-gradient(0deg, #245AE6, #245AE6)" onClick={handleNext} disabled={data ? false : true}>
            Next
          </ActionButton>
        </Stack>
      </div>
    </Stack>
  );
};

export default CsvFileUpload;
