import {
  add,
  compareAsc,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  sub
} from "date-fns";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EVENTS_FEED_TIMESTAMPS } from "../../../constants/eventConstants";
import { localToUTC } from "../../../utils/dateUtils";
import ToggleButtonWithSideButtonsGroup, {
  SideButton,
} from "../../common/ToggleButtonWithSideButtonsGroup";

const PERIOD_TO_DURATION = {
  week: { weeks: 1 },
  month: { months: 1 },
  quarter: { months: 3 },
};

const PIPELINE_OPTIONS = [
  { value: "week", label: "Weekly", frequency: "w" },
  { value: "month", label: "Monthly", frequency: "m" },
  { value: "quarter", label: "Quarterly", frequency: "q" },
];

const EventFeedTimeFilter = () => {
  const defaultDates = useSelector((state) => state.eventsFeedFilters.timestamps);
  const dispatch = useDispatch();
  const [selectedPipelineFrequency, setSelectedPipelineFrequency] = useState("week");
  const [startDate, setStartDate] = useState(defaultDates?.startDate);
  const [endDate, setEndDate] = useState(defaultDates.endDate);

  function setStartAndEndDates(frequency, date) {
    // Check if type of date is string, if yes, parse it to Date object
    if (typeof date === "string") {
      date = localToUTC(parseISO(date));
    } else if (typeof date === "object" && !(date instanceof Date)) {
      // Check if date is key-value object and not a date object, if yes, use the object to set the date based on frequency
      date = localToUTC(parseISO(date[frequency]));
    }
    let timesStampsObj = {};
    if (frequency === "week") {
      timesStampsObj.startDate = startOfWeek(date, { weekStartsOn: 0 });
      if (compareAsc(new Date(), endOfWeek(date, { weekStartsOn: 0 })) === 1) {
        timesStampsObj.endDate = endOfWeek(date, { weekStartsOn: 0 });
      } else {
        timesStampsObj.endDate = new Date();
      }
      timesStampsObj.pipelineSchedule = "w";
      setStartDate(timesStampsObj.startDate);
      setEndDate(timesStampsObj.endDate);
    }
    if (frequency === "month") {
      timesStampsObj.startDate = startOfMonth(date);
      if (compareAsc(new Date(), endOfMonth(date)) === 1) {
        timesStampsObj.endDate = endOfMonth(date);
      } else {
        timesStampsObj.endDate = new Date();
      }
      timesStampsObj.pipelineSchedule = "m";
      setStartDate(timesStampsObj.startDate);
      setEndDate(timesStampsObj.endDate);
    }
    if (frequency === "quarter") {
      timesStampsObj.startDate = startOfQuarter(date);
      if (compareAsc(new Date(), endOfQuarter(date)) === 1) {
        timesStampsObj.endDate = endOfQuarter(date);
      } else {
        timesStampsObj.endDate = new Date();
      }
      timesStampsObj.pipelineSchedule = "q";
      setStartDate(timesStampsObj.startDate);
      setEndDate(timesStampsObj.endDate);
    }

    dispatch({ type: EVENTS_FEED_TIMESTAMPS, payload: timesStampsObj });
  }

  function handleChange(_event, selectedOption) {
    setSelectedPipelineFrequency(selectedOption);
    // Below the date will alwasy be browser date as we need to calculate the start date based on the todays date and the pipeline_scehdule
    setStartAndEndDates(selectedOption, new Date());
  }

  function handlePrevClick(selectedOption) {
    const newEndDate = sub(endDate, PERIOD_TO_DURATION[selectedOption]);
    setStartAndEndDates(selectedOption, newEndDate);
  }

  function handleNextClick(selectedOption) {
    const newEndDate = add(endDate, PERIOD_TO_DURATION[selectedOption]);
    if (compareAsc(new Date(), newEndDate) === 1) {
      setStartAndEndDates(selectedOption, newEndDate);
    } else {
      setStartAndEndDates(selectedOption, new Date());
    }
  }

  return (
    <ToggleButtonWithSideButtonsGroup
      value={selectedPipelineFrequency}
      options={PIPELINE_OPTIONS}
      onChange={handleChange}
      prevButton={<SideButton onClick={handlePrevClick} />}
      nextButton={
        <SideButton
          // Disable the SideButton if the last_digest_ts for the selected frequency is exactly between the start and end date, both included
          disabled={startOfDay(endDate).valueOf() >= startOfDay(localToUTC(new Date())).valueOf()}
          onClick={handleNextClick}
        />
      }
    />
  );
};

export default EventFeedTimeFilter;
