import React from "react";

import ChartCarousel from "./hoc/ChartCarousel";
import TimeseriesChart from "./TimeseriesChart";
import { ChartOverlays } from "./ChartOverlays";
import { ImpactTable } from "./ImpactTable";
import { RESOURCE_TYPE_GROUP } from "../../constants/commonConstants";

/**
 * This component renders the spikes chart that has no rootcuase, and the chart overlays
 * @returns
 */
function SpikesWithOverlays({ accent, resourceType, resourceId, plotBands, overlayEndDateEpoch, isForecastEnabled = true }) {
  return (
    <ChartCarousel resourceType={resourceType}>
      <TimeseriesChart plotBands={plotBands} overlayEndDateEpoch={overlayEndDateEpoch} isForecastEnabled={isForecastEnabled} />

      <div>
        <ChartOverlays />
      </div>
      {/* Commented below the impact table as part of DEV-4390 */}
      {/* {resourceType === RESOURCE_TYPE_GROUP && (
        <div>
          <ImpactTable accent={accent} groupId={resourceId} />
        </div>
      )} */}
    </ChartCarousel>
  );
}

export default SpikesWithOverlays;
