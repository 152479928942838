import { merge } from "../../utils/objects";
import { colors } from "../theme-constants";

/**
 * @type {import("plotly.js").Data}
 */
export const summaryHistogramPlot = {
  type: "bar",
  mode: "markers",
  marker: {
    color: colors.white,
  },
  textposition: "none",
  hovertemplate: "<extra></extra>(%{x|%b %d, %Y}, <b>%{text}</b>)",
};

export const collaborateSummaryHistogramPlot = merge(summaryHistogramPlot, {
  marker: {
    color: colors.gray[400],
  },
});

/**
 * @type {import("plotly.js").Layout}
 */
export const summaryHistogramLayout = {
  height: 90,
  bargap: 0.9,
  paper_bgcolor: "transparent",
  plot_bgcolor: "transparent",
  margin: {
    t: 0,
    b: 0,
    l: 0,
    r: 0,
  },
  xaxis: {
    type: "date",
    tickfont: {
      family: "Poppins",
      size: 10,
      color: colors.white,
    },
    tickangle: 0,
    ticklabelposition: "inside",
    tickmode: "linear",
    fixedrange: true,
  },
  yaxis: {
    autotick: false,
    showgrid: false,
    showticklabels: false,
    zeroline: true,
    zerolinewidth: 0,
    zerolinecolor: colors.white,
    fixedrange: true,
  },
};

export const collaborateSummaryHistogramLayout = merge(summaryHistogramLayout, {
  height: 70,
  xaxis: {
    tickfont: {
      color: colors.gray[400],
    },
  },
  yaxis: {
    zerolinewidth: 3,
    zerolinecolor: colors.gray[400],
  },
});

export const summaryHistogramConfig = {
  useResizeHandler: true,
  responsive: true,
  displayModeBar: false,
};
