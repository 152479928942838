import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Box, Container, useMediaQuery } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { CardContent, Loading } from "./styled-components";

import { useOverlays, getLegendName, getHoverTemplateDate, getHoverTemplateY } from "../../hooks/useOverlays";
import { HTTP_STATUS } from "../../shared/enums/http-status";
import { InsightContext } from "../../context/InsightContext";
import OverlaySeriesLiftTable from "./OverlaySeriesLiftTable";
import InsightCardAccordion from "./InsightCardAccordion";
import ToggleButtonGroup from "./mui-wrapper-components/ToggleButtonGroup";
import { ErrorChartPlot } from "./ChartPlot";
import { GTAG_EVENTS, sendDataToGTM } from "../../utils/gtmHelper";
import { GTagArgsContext } from "../../context/GtagContext";
import { ChartTitleTypography } from "./styled-components/Container.styled";
import { colors } from "../../shared/theme-constants";
import { labels } from "../../shared/intl/labels";
import { isNullOrEmpty } from "../../utils/is";
import { overlayHighChartLayout } from "../../shared/plotly-config/overlay-chart-config";
import { formatDatePerFrequency } from "../../utils/dateUtils";
import moment from "moment-timezone";
import { nFormatter } from "../../utils/stringUtils";

const CHART_TITLE = {
  DoD: "Trailing 1 Day & 2 Days",
  WoW: "Trailing 7 Days & 14 Days",
  MoM: "Trailing 4 Weeks & 8 Weeks",
  YoY: "Trailing 12 Months & 24 Months",
};

const DISPLAY_VARIANT_OPTIONS = [
  {
    value: "percentage",
    label: "% Lift",
    "aria-label": "Percentage lift value",
  },
  {
    value: "absolute",
    label: "Absolute value",
    "aria-label": "Absolute value",
  },
];

function OverlaySeriesChart({ startDate, overlayType }) {
  const { patternData, insightSummary } = useContext(InsightContext);
  const gtagArgsContext = useContext(GTagArgsContext);
  const endDate = patternData.pattern.chart_ts_end;

  const { overlaysData, tableChartData, error } = useOverlays(
    patternData,
    startDate,
    endDate,
    overlayType
  );

  const [displayVariant, setDisplayVariant] = useState("percentage");
  const [xAxis, setXAxis] = useState([]);
  const [highChartData, setHighChartData] = useState([]);

  useEffect(() => {
    if (overlaysData) {
      const series = [];
      const x = [];
      const schedule = patternData.pattern.pipeline_schedule;
      const range = [];
      if (overlaysData && overlaysData?.main?.length) {
        const y = [];
        const tooltipContent = [];
        const anomalies = [];
        overlaysData?.main.forEach((mainSeries, index) => {
          x.push(mainSeries.timestamp);
          y.push(mainSeries.result.y);
          anomalies.push(mainSeries.result.anomaly);
          tooltipContent.push(
            `${getHoverTemplateDate(mainSeries.timestamp, schedule)} - ${getHoverTemplateY(
              mainSeries.result.y,
              patternData?.pattern?.kpi_format
            )}`
          );
          /**
           * Forecast may or may not be present for a smaller granularity;
           * For example, if the pipeline scdedule is Hourly, then there will be no forecast for DoD, WoW, MoM, QoQ
           * Similarily,  if the pipeline scdedule is Daily, then there will be no forecast for WoW, MoM, QoQ
           */
          if (
            !isNullOrEmpty(mainSeries.result.yhat_lower) &&
            !isNullOrEmpty(mainSeries.result.yhat_upper)
          ) {
            const overlayRange = [mainSeries.result.yhat_lower, mainSeries.result.yhat_upper];
            range.push(overlayRange);
          }
        });
        const markerColors = anomalies.map((anomaly) =>
          anomaly === "True" ? colors.pink[500] : colors.blue[900]
        );
        const traceMetric = {
          name: `${getLegendName(overlayType, 0)}`,
          data: y.map((yValue, dataIndex) => ({
            y: yValue,
            color: markerColors[dataIndex], // Use marker color corresponding to data index
            tooltipData: tooltipContent[dataIndex],
          })),
          type: "line",
          yAxis: 0,
          zIndex: 1,
          lineWidth: 2,
          color: colors.blue[900],
          marker: {
            enabled: true,
            lineWidth: 2.5,
            radius: 7,
            lineColor: "white",
            symbol: "circle",
            fillColor: null,
          },
        };
        series.push(traceMetric);
      }
      /**
       * The data for preceding periods, immediate period and prior to that are captured in `period1`, `period2` and so on, based on the provided request
       * So, there is no gurantee that there will always be data present for preceding periods, hence, UI need to ensure that it only iterates over
       * the data when it's only available.
       */
      if (overlaysData?.period1?.length) {
        const traceName = `${getLegendName(overlayType, 1)}`;
        const traceColor = colors.blue[500];
        const period1TraceMetric = getPeriodDataTrace(overlaysData.period1, overlaysData.main.length, traceName, traceColor);
        series.push(period1TraceMetric);
      }
      if (overlaysData?.period2?.length) {
        const traceName = `${getLegendName(overlayType, 2)}`;
        const traceColor = colors.blue[300];
        const period2TraceMetric = getPeriodDataTrace(overlaysData.period2, overlaysData.main.length, traceName, traceColor);
        series.push(period2TraceMetric);
      }
      if (range?.length > 0) {
        const rangeForecast = {
          name: labels.SPIKES_CHART.LEGENDS.EXPECTED_RANGE,
          data: range,
          yAxis: 0,
          type: "arearange",
          lineWidth: 0,
          color: colors.blue[300],
          fillOpacity: 0.3,
          zIndex: 0,
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        };
        series.push(rangeForecast);
      }
      setHighChartData(series);
      setXAxis(x);
    }
  }, [overlaysData, overlayType]);

  const getPeriodDataTrace = (periodData, mainDataLength, traceName, traceColor) => {
    const schedule = patternData.pattern.pipeline_schedule;
    const y = Array(mainDataLength - periodData.length).fill(null);
    const tooltipContent = [];
    periodData.forEach(period => {
      y.push(period.result.y);
      tooltipContent.push(
        `${getHoverTemplateDate(
          period.timestamp,
          schedule
        )} - ${getHoverTemplateY(
          period.result.y,
          patternData?.pattern?.kpi_format
        )}`
      );
    });
    const periodTraceMetric = {
      name: traceName,
      data: y.map((yValue, dataIndex) => ({
        y: yValue,
        color: traceColor, // Use marker color corresponding to data index
        tooltipData: tooltipContent[dataIndex],
      })),
      type: "line",
      yAxis: 0,
      zIndex: 1,
      lineWidth: 2,
      color: traceColor,
      marker: {
        enabled: true,
        lineWidth: 2.5,
        radius: 7,
        lineColor: "white",
        symbol: "circle",
        fillColor: null,
      },
    };
    return periodTraceMetric;
  };

  function handleAccordionStateChange(_event, isExpanded) {
    if (isExpanded) {
      sendDataToGTM(GTAG_EVENTS.OVERLAYS_TABLE_EXPANDED, gtagArgsContext);
    }
  }

  if (!overlaysData) return <Loading />;

  if (error) {
    if (error.response?.status === HTTP_STATUS.UNAUTHORIZED) {
      return <Navigate to="/login" />;
    } else {
      return <ErrorChartPlot message="Overlays data not found." />;
    }
  }

  const generateHighchartsOptions = () => {
    const options = {
      ...overlayHighChartLayout,
      xAxis: {
        ...overlayHighChartLayout.xAxis,
        categories: xAxis,
        labels: {
          formatter: function () {
            return formatDatePerFrequency(this.value, null, "Etc/Utc");
          },
          style: {
            color: "rgba(36, 90, 230, 1)", // Set color for percentage x-axis labels
          },
        },
      },
      yAxis: [
        {
          ...overlayHighChartLayout.yAxis,
          type: "linear",
          labels: {
            style: {
              color: "rgba(36, 90, 230, 1)", // Set color for percentage y-axis labels
              fontFamily: "Poppins", // Set font family for percentage y-axis labels
              fontSize: "14px", // Set font size for percentage y-axis labels
            },
            formatter: function () {
              if (patternData?.pattern?.kpi_format === "percentage") {
                return `${(this.value * 100).toFixed(1)}%`;
              }else {
                return nFormatter(this.value, 1);
              }
            },
          },
        },
      ],
      legend: {
        layout: "horizontal",
        align: "right",
        verticalAlign: "top",
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        formatter: function () {
          const points = this.points;
          let tooltip = `<b>${moment(this.x).tz("Etc/Utc").format("DD MMM, YYYY")}</b><br/>`; // Format date
          points.forEach((point, index) => {
            const seriesName = point.series.name;
            const kpiFormat = patternData?.pattern?.kpi_format;
            let formattedValue;
            if (seriesName === labels.SPIKES_CHART.LEGENDS.EXPECTED_RANGE) {
              // Format range values
              const low = point.point.low;
              const high = point.point.high;
              formattedValue = `${getHoverTemplateY(low, kpiFormat)} - ${getHoverTemplateY(
                high,
                kpiFormat
              )}`;
            } else {
              // Format metric values
              formattedValue = point.point.tooltipData;
            }
            tooltip += `<span style="color:${point.color}">\u25CF</span> ${seriesName}: <b>${formattedValue}</b><br/>`;
          });
          return tooltip;
        },
      },
      series: highChartData,
    };
    return options;
};

  return (
    <Container
      maxWidth={false}
      sx={{ maxWidth: 920, ml: "50px", textAlign: "left" }}
    >
      <CardContent>
        <Box sx={{ width: "100%" }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={generateHighchartsOptions()}
            style={{ width: "100%" }}
          />
        </Box>
      </CardContent>

      <Box sx={{ mx: "auto", width: 820 }}>
        <ChartTitleTypography marginBottom="10px">
          {CHART_TITLE[overlayType]}
        </ChartTitleTypography>

        <InsightCardAccordion
          insightIdAccordion={insightSummary.insightId}
          onChange={handleAccordionStateChange}
          summary="Overlays Chart Table"
        >
          <Box textAlign="right">
            <ToggleButtonGroup
              value={displayVariant}
              onChange={(_event, value) => setDisplayVariant(value)}
              options={DISPLAY_VARIANT_OPTIONS}
              size="small"
            />
          </Box>

          <OverlaySeriesLiftTable
            overlayType={overlayType}
            tableChartData={tableChartData}
            displayVariant={displayVariant}
          />
        </InsightCardAccordion>
      </Box>
    </Container>
  );
}

export default OverlaySeriesChart;
