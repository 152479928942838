import React from "react";
import { useNavigate } from "react-router-dom";

import { Stack } from "@mui/material";

import { StyledButton } from "../../common/mui-wrapper-components/Button";
import { ViewHistory } from "../../common/share-and-collaborate/ViewHistory";
import { CopyLink } from "../../common/share-and-collaborate/CopyLink";
import { Download } from "../../common/share-and-collaborate/Download";
import { useDispatch } from "react-redux";
import {
  SHOW_MODAL,
  VIEW_INDIVIDUAL_INSIGHT,
} from "../../../constants/modalConstants";
import {
  Actions,
  ComponentContainer,
  InsightActions,
} from "../../common/styled-components/collaborate";
import { RESET_COLLABORATE_FILTERS } from "../../../constants/multiSelectFilterConstants";
import { RESOURCE_TYPE_GROUP } from "../../../constants/commonConstants";

const CollaborateComponent = ({
  isModal,
  resourceType,
  resourceId,
  data,
  tenantId,
  // this props is used for Download
  link,
  gTagArgs,
  hasRootCause,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
    dispatch({ type: RESET_COLLABORATE_FILTERS });

    if (isModal) {
      dispatch({
        type: SHOW_MODAL,
        payload: {
          modalType: VIEW_INDIVIDUAL_INSIGHT,
          modalProps: {
            insightIds: [resourceId],
            insight: data,
            tenantId,
          },
          showCloseButton: true,
        },
      });
    }
  };

  return (
    <ComponentContainer>
      <InsightActions />
      <Actions>
        <Stack gap={2} flexDirection="row">
          <StyledButton id={`back_${resourceId}_1`} onClick={handleBackClick}>
            Back
          </StyledButton>

          {resourceType === RESOURCE_TYPE_GROUP && (
            <ViewHistory groupId={resourceId} gTagArgs={gTagArgs} />
          )}

          <CopyLink
            resourceType={resourceType}
            resourceId={resourceId}
            link={link}
          />

          <Download
            resourceType={resourceType}
            resourceId={resourceId}
            insightIds={
              resourceType === RESOURCE_TYPE_GROUP
                ? data.insights
                : [resourceId]
            }
            hasRootCause={hasRootCause}
            insightData={data}
            gTagArgs={gTagArgs}
          />
        </Stack>
      </Actions>
    </ComponentContainer>
  );
};

export default CollaborateComponent;
