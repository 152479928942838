import { GROUP_EVENT_LIST_REQUEST, GROUP_EVENT_LIST_SUCCESS, GROUP_EVENT_LIST_FAIL, GROUP_EVENT_LIST_RESET } from '../constants/eventConstants';

let collated_event_groups = [];
export const groupEventsListReducer = (state = {}, action) => {
    switch (action.type) {
      case GROUP_EVENT_LIST_REQUEST:
        return { loading: true, data: [...collated_event_groups] };
      case GROUP_EVENT_LIST_SUCCESS:
        collated_event_groups = [...collated_event_groups, ...action.payload];
        return { loading: false, data: [...collated_event_groups] };
      case GROUP_EVENT_LIST_FAIL:
        return { loading: false, error: action.payload };
      case GROUP_EVENT_LIST_RESET:
        collated_event_groups= [];
        return { loading: false, error: [] };
      default:
        return state;
    }
  };