import { unparse } from "papaparse";
import JsZip from "jszip";
import FileSaver from "file-saver";

/**
 * This function converts the JSON to CSV blob (binary large object), with the headers for columns
 * @param {*} headers the column headings
 * @param {*} items the data
 * @returns equivalent CSV bolb for the provided JSON
 */
export const ConvertJsonToCsv = (headers, items) => {
  let csvHeader = unparse({
    fields: [...headers.map((item) => item.name)],
    data: [],
  });

  const csvVal = unparse(items, {
    header: false,
    columns: [...headers.map((item) => item.key)],
  });

  const csv = csvHeader.concat(csvVal);

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  return blob;
};

/**
 * This function takes in an array of blob objects, and generate separate CSV files, and then zip them in a folder
 * @param {*} blobs array of bolb objects with filename
 * @param {*} zippedFileName the name of the zipped file to be downloaded
 */
export const exportAsZip = (blobs, zippedFileName) => {
  const zip = JsZip();
  blobs.forEach((blob, i) => {
    zip.file(`${blob.filename}.csv`, blob.blob);
  });
  zip.generateAsync({ type: "blob" }).then((zipFile) => {
    const currentTimestamp = new Date().getTime();
    const fileName = `${zippedFileName}_${currentTimestamp}.zip`;
    return FileSaver.saveAs(zipFile, fileName);
  });
};
