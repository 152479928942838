import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { colors, typography } from "../../../../shared/theme-constants";

export const MuiSelect = ({
  options,
  placeholder,
  selectedValue,
  handleChange,
  size = "medium",
}) => {
  return (
    <FormControl
      variant="filled"
      sx={{
        minWidth: 120,
        background: 'transparent',
        border : "1px solid var(--mist-gray)",
        borderRadius: "5px",
      }}
      size={size}
    >
      <InputLabel
        sx={{
          color: colors.mariner,
          fontSize: typography.font_size.sm,
          fontWeight: typography.font_weight.bold,
        }}
        id="select-filled-label"
      >
        {placeholder}
      </InputLabel>

      <Select
        sx={{
          color: colors.mariner,
          "&:before": { display: "none" },
          ".MuiSelect-icon": { color: colors.mariner },
          background: 'transparent'
        }}
        labelId="select-filled-label"
        id="select-filled"
        value={selectedValue}
        onChange={handleChange}
      >
        {options &&
          options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
