import {
  SUCCESS,
  ERROR,
  WARNING,
  CLOSE,
} from "../constants/notificationConstants";
export const notificationReducer = (state = { message: "" }, action) => {
  switch (action.type) {
    case SUCCESS:
      return { message: action.payload };
    case ERROR:
      return { message: action.payload };
    case WARNING:
      return { message: action.payload };
    case CLOSE:
      return { message: "" };
    default:
      return state;
  }
};
