import axios from "axios";

import { GROUP_EVENT_LIST_FAIL, GROUP_EVENT_LIST_REQUEST, GROUP_EVENT_LIST_SUCCESS } from "../constants/eventConstants";

const url = process.env.REACT_APP_BASE_URL.concat("events");

export const listGroupEvents = (params) => async (dispatch) => {

  try {

    dispatch({
      type: GROUP_EVENT_LIST_REQUEST,
      payload: [],
    });

    const { data } = await axios.get(`${url}/groups`, {
      params: new URLSearchParams(params),
    });
    dispatch({
      type: GROUP_EVENT_LIST_SUCCESS,
      payload: data.event_groups,
    });
    return data;
  } catch (error) {
    dispatch({
      type: GROUP_EVENT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};