export const FLAGGED = "Flagged";
export const RESOLVED = "Resolved";

// below constants are being used for dynamically setting the texts
// of the Button fields while changing the team state of an insight
export const FLAG = "Flag";
export const RESOLVE = "Resolve";

// These constants are used while updating the team state transition data in the redux store
export const TEAM_INSIGHT_ACTION_INITIATED = "initiated";
export const TEAM_INSIGHT_ACTION_CANCEL = "cancel";

export const TEAM_INSIGHT_ACTION_REQUESTED = "requested";

export const TEAM_INSIGHT_ACTION_SUCCESS = "success";

export const TEAM_INSIGHT_ACTION_FAILED = "failed";
