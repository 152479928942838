import styled from "styled-components";
export const ActionControlContainer = styled.div`
  top: ${({ top }) => top};
  max-width: 100%;
  min-width: 300px;
  margin-left: -18px;
  height: 130px;
  position: fixed;
  z-index: 2;
  width: 100%;
  background: linear-gradient(
    180deg,
    aliceblue 75.67%,
    rgba(241, 245, 254, 0) 100%
  );
`;
