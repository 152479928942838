import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Divider,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { typography } from "../../../shared/theme-constants";
import { StyledActionButton } from "../mui-wrapper-components/Button";
import MultiSelect from "../mui-wrapper-components/Select/MultiSelect";
import { useSelectedTenant } from "../../../hooks/useSelectedTenant";
import { isEmpty } from "../../../utils/is";
import { SET_SNACKBAR } from "../../../reducers/notificationSnackbarReducer";
import { useDispatch } from "react-redux";
import { useAxios } from "../../../hooks/useAxios";
import { Loading } from "../styled-components";
import { HTTP_STATUS } from "../../../shared/enums/http-status";
import { useNavigate } from "react-router-dom";
import { useLocalStorageState } from "../../../hooks";
import { TENANT_UPDATE_GLOBAL_FILTER } from "../../../constants/tenantConstants";

const CreateFilterDrawer = ({ isOpen = false, closeCreateFilterDrawer }) => {
  const url = process.env.REACT_APP_BASE_URL;
  const selectedTenant = useSelectedTenant();
  const queryParams = { tenant_id: selectedTenant };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [, updateAppliedGlobalFilters] = useLocalStorageState(
    "currentAppliedGlobalFilters"
  );
  const [loading, setLoading] = useState(true);
  const [dimensionValueOptions, setDimensionValueOptions] = useState([]);
  const [selectedDimension, setSelectedDimension] = useState(null);
  const [selectedDimensionValue, setSelectedDimensionValue] = useState(null);
  const [filterLabel, setFilterLabel] = useState("");
  const [creatingGlobalFilter, setCreatingGlobalFilter] = useState(false);

  const { data: dimensionOptions, isLoading } = useAxios(
    `metrics_hub/dimensions`,
    {
      params: {
        tenant_id: selectedTenant,
      },
    }
  );

  const handleCloseDrawer = () => {
    closeCreateFilterDrawer();
  };

  const handleCreateGlobalFilter = async () => {
    setCreatingGlobalFilter(true);
    const postData = {
      tenant_id: selectedTenant,
      dimension_id: selectedDimension.id,
      dimension_name: selectedDimension.name,
      dimension_value: selectedDimensionValue,
      display_name: filterLabel,
    };
    const params = new URLSearchParams({});
    try {
      const response = await axios.post(`${url}feed/filters`, postData, {
        params,
      });
      if (response?.data?.success) {
          // Update the local storage with the applied filters
        dispatch({
          type: TENANT_UPDATE_GLOBAL_FILTER,
          payload: response?.data?.data,
        });
        updateAppliedGlobalFilters(response?.data?.data);
        dispatch({
          type: SET_SNACKBAR,
          snackbarOpen: true,
          snackbarMessage: "Global filter created",
          snackbarType: "success",
        });
        handleCloseDrawer();
      }
    } catch (error) {
      dispatch({
        type: SET_SNACKBAR,
        snackbarOpen: true,
        snackbarMessage: "Something went wrong.Please try again",
        snackbarType: "error",
      });
    }
    setCreatingGlobalFilter(false);
  };

  const handleDimensionValueChange = (_event, newValue, reason) => {
    setSelectedDimensionValue(newValue);
  };

  const handleDimensionChange = (_event, newValue, reason) => {
    setSelectedDimension(newValue);
    setSelectedDimensionValue(null);
    if (!isEmpty(newValue)) {
      fetchDimensionValues(newValue.id);
    } else {
      setDimensionValueOptions([]);
    }
  };

  const fetchDimensionValues = async (dimensionId) => {
    const params = new URLSearchParams(queryParams);
    try {
      const response = await axios.get(
        `${url}metrics_hub/dimensions/${dimensionId}/values`,
        {
          params,
        }
      );
      const filteredValues = (response.data || []).filter(
        (item) => !isEmpty(item)
      );
      setDimensionValueOptions(filteredValues);
    } catch (error) {
      if (error?.status === HTTP_STATUS) {
        navigate("/login");
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      sx={{
        zIndex: 100000,
      }}
      PaperProps={{
        sx: { justifyContent: "center" },
      }}
    >
      <Stack sx={{ width: 550, height: "90vh" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ padding: 4 }}
        >
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(24),
              fontWeight: typography.font_weight.mediumx,
            })}
          >
            Create New Global Filter
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseDrawer}
            sx={(theme) => ({
              color: "rgba(84, 92, 126, 1)",
            })}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        {isLoading ? (
          <Loading />
        ) : (
          <Stack
            direction="column"
            sx={{ padding: 4, height: "90%", overflowY: "scroll" }}
            gap={4}
          >
            <Alert
              icon={false}
              severity="success"
              style={{ width: "100%" }}
              sx={(theme) => ({
                backgroundColor: "rgba(241, 245, 254, 1)",
                color: "rgba(102, 102, 102, 1)",
                fontSize: theme.typography.pxToRem(12),
                mt: 1,
              })}
            >
              <Stack direction="column" gap={4}>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(16),
                    fontWeight: typography.font_weight.mediumx,
                    color: "rgba(36, 90, 230, 1)",
                  })}
                >
                  What is Global View ?
                </Typography>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(14),
                    fontWeight: typography.font_weight.mediumx,
                    color: "rgba(61, 61, 61, 1)",
                  })}
                >
                  Global Filters can be used to segment the data based on
                  specific attributes in the data. This segmentation enables
                  users to auto-detect and zoom into relevant insights for
                  deeper analysis.
                </Typography>
              </Stack>
            </Alert>
            <Stack direction="column" sx={{ mt: 6 }} gap={6}>
              <Stack direction="column" gap={3}>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.regular,
                  })}
                >
                  <span style={{ color: "red" }}>*</span> Select Dimension
                </Typography>
                <MultiSelect
                  multiple={false}
                  optionsCheckbox={false}
                  disableCloseOnSelect={false}
                  placeholder="Search for Dimension"
                  onChange={handleDimensionChange}
                  clearIcon={selectedDimension?.length === 1 ? null : undefined}
                  value={selectedDimension}
                  options={dimensionOptions?.data || []}
                  getOptionLabel={(option) => option.display_name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  sx={{
                    maxWidth: "unset",
                  }}
                />
              </Stack>
              <Stack direction="column" gap={3}>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.regular,
                  })}
                >
                  <span style={{ color: "red" }}>*</span> Select Dimension Value
                </Typography>
                <MultiSelect
                  sx={{
                    maxWidth: "unset",
                  }}
                  multiple={false}
                  placeholder="Add Dimension value"
                  optionsCheckbox={false}
                  disableCloseOnSelect={false}
                  disabled={isEmpty(selectedDimension)}
                  onChange={handleDimensionValueChange}
                  disableClearable
                  value={selectedDimensionValue}
                  options={dimensionValueOptions}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                />
              </Stack>
            </Stack>
            <Divider sx={{ mt: 6, mb: 6 }} />
            <Stack direction="column" gap={3} sx={{ alignItems: "flex-start" }}>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: typography.font_weight.regular,
                })}
              >
                <span style={{ color: "red" }}>*</span> Please Name Your Global
                Filter
              </Typography>
              <TextField
                type="text"
                sx={{
                  width: "100%",
                }}
                size="small"
                value={filterLabel}
                onChange={(event) => setFilterLabel(event.target.value)}
                placeholder="For eg, Country -  US"
              />
            </Stack>
          </Stack>
        )}
      </Stack>
      <Stack
        direction="row"
        gap={3}
        sx={{
          height: "10vh",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: 4,
        }}
      >
        <StyledActionButton
          onClick={handleCreateGlobalFilter}
          disabled={
            isEmpty(selectedDimension) ||
            isEmpty(selectedDimensionValue) ||
            filterLabel === "" ||
            creatingGlobalFilter
          }
        >
          Apply Filter
        </StyledActionButton>
        <StyledActionButton
          style={{
            background: "var(--white)",
            color: "var(--comet)",
            borderColor: "var(--comet)",
          }}
          disabled={creatingGlobalFilter}
          onClick={handleCloseDrawer}
        >
          Cancel
        </StyledActionButton>
      </Stack>
    </Drawer>
  );
};

export default CreateFilterDrawer;
