import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    color: hsl(192, 100%, 9%);
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    margin: 0;
    transition: all .3s ease-in-out;
    background-color : #e5ecfd
  }

  /* using \\ to escape ! character */
  .\\!p-0 {
    padding: 0 !important;
  }

  .\\!d-none {
    display: none !important;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .text-right {
    text-align: right;
  }

  .capitalize {
    text-transform: capitalize;
  }
`;

export default GlobalStyles;
