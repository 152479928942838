export const HOME = "Home";
export const OPEN = "Open";
export const IMPORTANT = "Important";
export const SNOOZED = "Snoozed";
export const FLAGGED = "Flagged";
export const ARCHIVED = "Archived";
export const RESOLVED = "Resolved";
export const MENU_ITEMS = [
  HOME,
  FLAGGED,
  RESOLVED,
  IMPORTANT,
  SNOOZED,
  ARCHIVED,
];

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
