import {
  GROUP_INSIGHT_IMPORTANT_REQUEST,
  GROUP_INSIGHT_IMPORTANT_SUCCESS,
  GROUP_INSIGHT_IMPORTANT_FAIL,
  GROUP_INSIGHT_SNOOZE_REQUEST,
  GROUP_INSIGHT_SNOOZE_SUCCESS,
  GROUP_INSIGHT_SNOOZE_FAIL,
  GROUP_INSIGHT_ARCHIVE_REQUEST,
  GROUP_INSIGHT_ARCHIVE_SUCCESS,
  GROUP_INSIGHT_ARCHIVE_FAIL,
  GROUP_INSIGHT_LIST_REQUEST,
  GROUP_INSIGHT_LIST_SUCCESS,
  GROUP_INSIGHT_LIST_FAIL,
} from "../constants/groupInsightConstans";

export const groupInsightsListReducer = (state = {}, action) => {
  switch (action.type) {
    case GROUP_INSIGHT_LIST_REQUEST:
      return { loading: true, data: action.payload };
    case GROUP_INSIGHT_LIST_SUCCESS:
      return { loading: false, data: action.payload };
    case GROUP_INSIGHT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const groupInsightImpReducer = (state = {}, action) => {
  switch (action.type) {
    case GROUP_INSIGHT_IMPORTANT_REQUEST:
      return { loading: true };
    case GROUP_INSIGHT_IMPORTANT_SUCCESS:
      return { loading: false, current_user: action.payload };
    case GROUP_INSIGHT_IMPORTANT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const groupInsightSnoozeReducer = (state = {}, action) => {
  switch (action.type) {
    case GROUP_INSIGHT_SNOOZE_REQUEST:
      return { loading: true };
    case GROUP_INSIGHT_SNOOZE_SUCCESS:
      return { loading: false, current_user: action.payload };
    case GROUP_INSIGHT_SNOOZE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const groupInsightArchiveReducer = (state = {}, action) => {
  switch (action.type) {
    case GROUP_INSIGHT_ARCHIVE_REQUEST:
      return { loading: true };
    case GROUP_INSIGHT_ARCHIVE_SUCCESS:
      return { loading: false, current_user: action.payload };
    case GROUP_INSIGHT_ARCHIVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};