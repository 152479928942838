import React, { useContext, useState } from "react";
import { useMediaQuery } from "@mui/material";

import { StyledIconButton } from "../mui-wrapper-components/Button";

import { StyledTooltipLink } from "../styled-components/Tooltip.styled";

import { theme } from "../styled-components/theme";
import { GTAG_EVENTS, sendDataToGTM } from "../../../utils/gtmHelper";
import {
  GTagArgsContext,
  GTagGroupContext,
} from "../../../context/GtagContext";
import { RESOURCE_TYPE_GROUP } from "../../../constants/commonConstants";

export const CopyLink = ({ resourceType, resourceId, link }) => {
  const matchesMobile = useMediaQuery(`(max-width:${theme.tablet})`);
  const groupContext = useContext(GTagGroupContext);
  const gtagArgsContext = useContext(GTagArgsContext);

  const [positionX, setPositionX] = useState(undefined);
  const [positionY, setPositionY] = useState(undefined);
  const [copied, setCopied] = useState(false);

  // Form complete share link
  let url = process.env.REACT_APP_BASE_URL;
  const newUrl = url.slice(0, url.lastIndexOf("/"));
  const BASE_URL = newUrl.slice(0, newUrl.lastIndexOf("/"));
  const copyLink = `${BASE_URL}/${link}`;

  const handleCopyLink = (e) => {
    // get position of the mouse pointer
    // for tooltip positioning
    setPositionX(e.clientX);
    setPositionY(e.clientY);
    setCopied(true);
    navigator.clipboard.writeText(copyLink);
    const event =
      resourceType === RESOURCE_TYPE_GROUP
        ? GTAG_EVENTS.GROUP_SHARE_LINK_CLICKED
        : GTAG_EVENTS.SHARE_LINK_CLICKED;

    const gtagData =
      resourceType === RESOURCE_TYPE_GROUP ? groupContext : gtagArgsContext;
    sendDataToGTM(event, gtagData);
    setTimeout(() => {
      setCopied(false);
    }, 1200);
  };

  return (
    <StyledTooltipLink
      title={"Link Copied to Clipboard"}
      placement="right-end"
      open={copied}
      PopperProps={{
        anchorEl: {
          getBoundingClientRect: () => ({
            top: positionY + 15,
            left: matchesMobile ? positionX - 105 : positionX,
            width: 0,
            height: 0,
          }),
        },
      }}
    >
      <StyledIconButton
        id={`link_${resourceId}`}
        height = '1.5rem'
        icon="link"
        onClick={(e) => handleCopyLink(e)}
      />
    </StyledTooltipLink>
  );
};
