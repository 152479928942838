import { useEffect, useState } from "react";

import {
  DoD,
  MoM,
  YoY,
  WoW,
  QoQ,
} from "../constants/chartOverlayConstants";
import { PERCENTAGE } from "../constants/commonConstants";
import { nFormatter } from "../utils/stringUtils";
import { datePartsWithTZ, localToUTC } from "../utils/dateUtils";
import { useDispatch } from "react-redux";
import { getOverlays } from "../actions/chartOverlayActions";

export const getHoverTemplateDate = (date, schedule) => {
  const { year, monthName, day, hours24, minutes } = datePartsWithTZ(date);
  if (schedule === "h")
    return `${monthName} ${day}, ${year} ${hours24}:${minutes}`;

  return `${monthName} ${day}, ${year}`;
};

export const getHoverTemplateY = (yValue, kpiFormat) => {
  return kpiFormat === PERCENTAGE
    ? `${Math.round(yValue * 100)}%`
    : `${nFormatter(yValue, 1)}`;
};

export const getLegendName = (overlayType, period) => {
  switch (overlayType) {
    case DoD:
      if (period === 0) return "Today";
      return `${period} Day${period > 1 ? "s" : ""}`;
    case WoW:
      if (period === 0) return "This Week";
      return `${period} Week${period > 1 ? "s" : ""}`;
    case MoM:
      if (period === 0) return "This Week";
      return `${period * 4} Week${period > 0 ? "s" : ""}`;
    case QoQ:
      if (period === 0) return "This Quarter";
      return `${period} Quarter${period > 1 ? "s" : ""}`;
    case YoY:
      if (period === 0) return "This Year";
      return `${period} Year${period > 1 ? "s" : ""}`;
  }
};

const prepareParams = (pattern, overlayType, startTime, endTime) => {
  const paramsDetail = new URLSearchParams({
    tenant_id: pattern.tenant_id,
    kpi_id: pattern.kpi_id,
    pipeline_schedule: pattern.pipeline_schedule,
    type: overlayType,
  });

  if (!pattern.pipeline_tag && pattern.pipeline_id) {
    paramsDetail.append("pipeline_id", pattern.pipeline_id);
  }
  if (pattern.chart_ts_start) {
    paramsDetail.append("timestamp_start", startTime || pattern.chart_ts_start);
  }
  if (pattern.chart_ts_end) {
    paramsDetail.append("timestamp_end", endTime || pattern.chart_ts_end);
  }
  if (pattern.dim_name) {
    paramsDetail.append("dim_name", pattern.dim_name);
  }
  if (pattern.dim_val) {
    paramsDetail.append("dim_val", pattern.dim_val);
  }
  if (pattern.pipeline_guid) {
    paramsDetail.append("pipeline_guid", pattern.pipeline_guid);
  }
  return paramsDetail;
};

export const useOverlays = (patternData, startTime, endTime, overlayType, isDigest = false) => {
  const [overlaysData, setOverlaysData] = useState(null);
  const [tableChartData, setTableChartData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const pattern = patternData.pattern;
    const params = prepareParams(pattern, overlayType, startTime, endTime);
    dispatch(getOverlays(params))
      .then((timeseries) => {
        const overlayData = [];
        const overlayMain = [],
          overlayPeriod1 = [],
          overlayPeriod2 = [];
        if (timeseries) {
          Object.entries(timeseries).forEach((entry) => {
            overlayData.push(entry[1]);
          });
          setOverlaysData(timeseries);
          if (timeseries?.main?.length) {
            timeseries.main.forEach((mainSeries) => {
              overlayMain.push({
                timestamp: mainSeries.timestamp,
                current_y: mainSeries.result.y,
              });
            });
          }

          if (timeseries?.period1?.length && !isDigest) {
            /**
             * There is also no gurantee that there will always be equal number of data points available in the past periods for the given range,
             * For example, it is possible that `timeseries.data.main` has 12 records while `period1` or `period2` might have lesser or no records for the given range.
             * This behaviour might result in the wrong visualisation of the data, if the chart data is prepared with a presumption that the number of records will
             * always be equal for all preceding preiods.
             *
             * To overcome the above issue, below code is pushing the adjusted x-value range to the x-axis array for `period1` and period2 below
             */
            timeseries.period1.forEach((p1Series) => {
              overlayPeriod1.push({ period1_y: p1Series.result.y });
            });
          }

          if (timeseries?.period2?.length && !isDigest) {
            timeseries.period2.forEach((p2Series) => {
              overlayPeriod2.push({ period2_y: p2Series.result.y });
            });
          }
        }

        setTableChartData(() =>
          overlayMain
            .map(({ timestamp, current_y }, i) => ({
              timestamp,
              current_y,
              id: timestamp + i,
              ...overlayPeriod1[i],
              ...overlayPeriod2[i],
            }))
            .reverse()
        );
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
      });
      return () => setLoading(true);

  }, [dispatch, endTime, overlayType, patternData.pattern, startTime]);

  return { overlaysData, tableChartData, error, isLoading : loading };
};
