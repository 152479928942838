import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// MUI Imports
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

// Local Imports
import Icon from "./Icon";
import { getEventHistory } from "../../actions/eventHistoryActions";
import { titleCase } from "../../utils/stringUtils";
import { formatDateTimeUtc } from "../../utils/dateUtils";
import { Box } from "@mui/material";
import {
  errorMessageFontStyle,
  EventHistoryModalContainer,
  eventHistoryModalStyle,
  eventHistoryListStyle,
  eventHistoryListItemStyle,
  eventHistoryListIcon,
  titleFontStyle,
  primaryFontStyle,
  secondaryFontStyle,
} from "./styled-components/EventHistory.styled";
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser";

/**
 * Recieves a string or array of strings and process it based on its type, then returns a formatted message
 * @param {String | Array} annotationTypes string or array of strings
 * @returns Formatted annotation types
 */
const formUpdateMessage = (annotationTypes) => {
  if (!annotationTypes.length) return "";

  if (annotationTypes.length === 1) return titleCase(annotationTypes[0]);

  if (annotationTypes.length > 1)
    return `${titleCase(annotationTypes[0])} & ${titleCase(
      annotationTypes[1]
    )}`;
};

export const EventHistory = ({ groupId, title }) => {
  const dispatch = useDispatch();
  const eventHistory = useSelector((state) => state.eventHistory.data);
  const loggedInUser = useAuthenticatedUser();

  const [error, setError] = useState(undefined);

  useEffect(() => {
    dispatch(getEventHistory(groupId))
      .then((result) => {})
      .catch((err) => setError(err));
  }, [dispatch, groupId]);

  return (
    <Box sx={eventHistoryModalStyle}>
      <Typography sx={titleFontStyle}>{title}</Typography>
      <EventHistoryModalContainer>
        {error ? (
          <Box sx={{ padding: "0 20px 0 20px" }}>
            {"There was an error while fetching the history"}
          </Box>
        ) : (
          !eventHistory && (
            <Box sx={{ padding: "25% 0 0 45%" }}>
              <CircularProgress size="2rem" />
            </Box>
          )
        )}
        <List sx={eventHistoryListStyle}>
          <ListItem sx={{ alignItems: "center", paddingLeft: "16px" }}>
            {eventHistory?.length === 0 && (
              <ListItemText>
                <Typography
                  sx={errorMessageFontStyle}
                  component="span"
                  variant="subtitle1"
                >
                  {"No history available"}
                </Typography>
              </ListItemText>
            )}
          </ListItem>
          {eventHistory?.map((history, index) => (
            <div key={index}>
              <ListItem sx={eventHistoryListItemStyle}>
                <Avatar sx={eventHistoryListIcon}>
                  {history.change_type === "state_change" && (
                    <Icon
                      name={
                        history.change_data.current_state === "open"
                          ? "Home"
                          : titleCase(history.change_data.current_state)
                      }
                      size="25px"
                    />
                  )}
                  {history.change_type === "annotation_change" && (
                    <Icon
                      name="edit-summary"
                      size="25px"
                      strokecolor="#71717a"
                    />
                  )}
                </Avatar>
                <ListItemText
                  sx={{ paddingLeft: "20px" }}
                  primary={
                    <Typography sx={primaryFontStyle}>
                      {history.change_type === "state_change" &&
                        `Moved to ${titleCase(
                          history.change_data.current_state
                        )}`}
                      {history.change_type === "annotation_change" &&
                        `${formUpdateMessage(
                          history.change_data.annotation_type
                        )} Updated`}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography
                        sx={secondaryFontStyle}
                        component="span"
                        variant="subtitle2"
                        color="text.primary"
                      >
                        {`by ${
                          loggedInUser?.login_id === history.login_id
                            ? "you"
                            : history.login_id
                        }`}
                        <br />
                        {formatDateTimeUtc(
                          history.created_at,
                          "dd MMM, yyyy 'at' h:mm a"
                        )}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            </div>
          ))}
        </List>
      </EventHistoryModalContainer>
    </Box>
  );
};
