import React from "react";
import { RedocStandalone } from "redoc";

const url = process.env.REACT_APP_BASE_URL;

const RedocScreen = () => {
  return (
    <RedocStandalone
      specUrl={`${process.env.REACT_APP_SECURED_PROTOCOL}${process.env.REACT_APP_DOMAIN}/docs/api/openapi.latest.json`}
    />
  );
};

export default RedocScreen;
