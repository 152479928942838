import React from "react";
import { Box } from "@mui/material";

import Insight from "./Insight";
import { insightModalStyle } from "../../common/styled-components/InsightModal.styled";
import { RESOURCE_TYPE_INSIGHT } from "../../../constants/commonConstants";

export const InsightModal = ({ insightIds, groupId, insight, tenantId }) => {
  return (
    <Box sx={insightModalStyle}>
      <Insight
        insight={insight}
        insightId={insightIds[0]}
        enableInsightAction={false}
        showExpanded={true}
        openAsModal={true}
        groupId={groupId}
        resourceType={RESOURCE_TYPE_INSIGHT}
      />
    </Box>
  );
};
