import styled from "styled-components";

export const HamburgerMenuContainer = styled.div`
  position: absolute;
  display: flex;
  /* width: 50%; */
`;
export const HamburgerMenu = styled.div`
  position: relative;
  left: 37px;

  @media (max-width: ${({ theme }) => theme.ipadPro}) {
    left: 30px;
  }
`;
