import React, { useMemo, useState } from "react";
import {
  LinearProgress,
  Typography,
  Container,
  Box,
  IconButton,
  Stack,
  Button,
  alpha,
  Chip,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  gridClasses,
  GridColumnHeaderTitle,
  GridFilterListIcon,
} from "@mui/x-data-grid";
import { colors, typography } from "../shared/theme-constants";
import { useSelectedTenant } from "../hooks/useSelectedTenant";
import { isEmpty } from "../utils/is";
import { formatDateTime, generateDifferenceInTimeString } from "../utils/dateUtils";
import { format, max, parseISO } from "date-fns";
import DatasourcesColumnMenu from "../components/datasources/DatasourcesColumnMenu";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ShareOutlined from '@mui/icons-material/ShareOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import '../components/datasources/DatasourcesStatusScreen.css';
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { HTTP_STATUS } from "../shared/enums/http-status";

const ROWS_PER_PAGE = [10, 25, 50];
const SORTING_ORDER = ["desc", "asc"];

const ColumnHeaderTitle = styled(Box)({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  fontWeight: typography.font_weight.medium,
  display: "inline-flex",

  "& .MuiIconButton-root": {
    cursor: "default",
  },
});

const HeaderWithFilterIcon = ({ field, colDef, selectedFilters }) => {
  return (
    <ColumnHeaderTitle className={gridClasses.columnHeaderTitle}>
      <GridColumnHeaderTitle
        label={colDef.headerName}
        description={colDef.description}
        columnWidth={colDef.width}
      />

      {!isEmpty(selectedFilters[field]) ? (
        <IconButton color="primary" disableRipple size="small">
          <GridFilterListIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </ColumnHeaderTitle>
  );
};

const ActionButton = styled(Button)(({ theme }) => ({
  height: "41px",
  padding: theme.spacing(0, 7),
  borderRadius: "9px",
  border: "1.5px",
  background: "linear-gradient(0deg, #245AE6, #245AE6)",
  color: "white",
  ":hover": {
    cursor: "pointer",
  },
  alignSelf: "center",
}));

const DataConnectionAnimation = (message) => {
  return (
    <div className="animation-container">
      <span>{message}</span>
      <div className="dot-container">
        <span className="dot">.</span>
        <span className="dot">.</span>
        <span className="dot">.</span>
      </div>
    </div>
  );
};

const baseColumnOptions = {
  sortable: false,
  pinnable: false,
  hideable: false,
  filterable: false
};

function getDatsourceId(row) {
  return row.datasource_id;
}

// Overlay
const nowRowsOverlayComponent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: colors.gray[500],
          fontWeight: typography.font_weight.medium,
        }}
      >
        {`No data sources connected, Click on '+ Add Data Source' to connect a data source`}
      </Typography>
    </Box>
  );
};

const DatasourcesStatusScreen = () => {
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [sortModel, setSortModel] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const selectedTenant = useSelectedTenant();
  const windowFeatures = " width=500,height=600 top=500 left=500";
  const windowName = "_self";

  const navigate = useNavigate();

  const columns = [
    {
      ...baseColumnOptions,
      field: "datasource_id",
      headerName: "ID",
      flex: 0.4,
      filterable: false,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) => {
        // Return #<CAPITALISED ID>
        return `#${value.toUpperCase()}`;
      },
      renderHeader: (params) => (
        <HeaderWithFilterIcon {...params} selectedFilters={selectedFilters} />
      ),
      renderCell: ({ formattedValue, row }) => {
        if (!formattedValue) return null;

        return <Typography component="span" fontSize={typography.font_size.sm}>{formattedValue}</Typography>;
      },
    },
    {
      ...baseColumnOptions,
      field: "connector_name",
      headerName: "Type",
      flex: 0.3,
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        <HeaderWithFilterIcon {...params} selectedFilters={selectedFilters} />
      ),
      renderCell: ({ formattedValue, row }) => {
        if (!formattedValue) return null;

        return (<Tooltip
          title={`${row.connector_type}: ${row.connector_display_name}`}
          placement="bottom-start"
          arrow
        >
          <img src={row.connector_logo_url} />
        </Tooltip>);
      }
    },
    {
      ...baseColumnOptions,
      field: "connection_name",
      headerName: "Name",
      flex: 0.8,
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        <HeaderWithFilterIcon {...params} selectedFilters={selectedFilters} />
      ),
      renderCell: ({ formattedValue, row }) => {
        if (!formattedValue) return null;

        let connection_subtext = "";
        if (row.source_tables.length > 0) {
          // If first file name is too long, truncate it by keeping first 10 characters and last 10 characters
          let first_file_name = row.source_tables[0];
          if (first_file_name.length > 20) {
            first_file_name = `${first_file_name.substring(0, 15)}...${first_file_name.substring(first_file_name.length - 15, first_file_name.length)}`;
          }
          if (row.connector_type === "file") {
            // Create subtext string in format of file1_name and x more files from array row.source_tables
            connection_subtext = row.source_tables.length == 1 ? first_file_name : `${first_file_name} and ${row.source_tables.length - 1} more file${row.source_tables.length == 1 ? '' : 's'}`;
          } else if (row.source_tables.length > 0) {
            let first_file_name = row.source_tables[0];
            // Create subtext string in format of table1_name and x more tables from array row.source_tables
            connection_subtext = row.source_tables.length == 1 ? first_file_name : `${first_file_name} and ${row.source_tables.length - 1} more table${row.source_tables.length == 1 ? '' : 's'}`;
          }
        }

        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography component="span" sx={{
              fontSize: typography.font_size.sm,
              fontWeight: typography.font_weight.mediumx
            }}
            >{formattedValue}
            </Typography>
            <Tooltip
              title={row.source_tables.length > 0 ? row.source_tables.join(', ') : "No files or tables selected"}
              placement="bottom"
              arrow
            >
              <Typography component="span" sx={{
                fontSize: typography.font_size.xs,
                fontWeight: typography.font_weight.regular,
                opacity: 0.7,
                color: colors.comet,
                paddingTop: 1.5
              }}
              >{connection_subtext}</Typography>
            </Tooltip>
          </div>
        );
      }
    },
    {
      ...baseColumnOptions,
      field: "status",
      headerName: "Status",
      flex: 1,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      valueGetter: ({ value }) => {
        // Check if value is empty object
        if (isEmpty(value)) return null;

        // Else, return the status in priority order of insight_generaton_status, metric_configuration_status and data_connection_status
        if (value.insight_generation_status && value.insight_generation_status.status !== 'NOT_STARTED') {
          return {
            state: "insight_generation",
            status: value.insight_generation_status.status,
            status_fomatted: value.insight_generation_status.status.replace('_', ' '),
            message: value.insight_generation_status.message
          };
        } else if (value.metric_configuration_status && value.metric_configuration_status.status !== 'NOT_STARTED') {
          // If status is IN_PROGRESS, show "Click to resume" and make it clickable
          if (value.metric_configuration_status.status === 'IN_PROGRESS') {
            return {
              state: "metric_configuration",
              status: value.metric_configuration_status.status,
              status_fomatted: "CLICK TO RESUME",
              message: "User Action Needed"
            };
          }
          return {
            state: "metric_configuration",
            status: value.metric_configuration_status.status,
            status_fomatted: value.metric_configuration_status.status.replace('_', ' '),
            message: value.metric_configuration_status.message
          };
        } else if (value.metric_configuration_status && value.metric_configuration_status.status == 'NOT_STARTED') {
          // If status is NOT_STARTED, show "Click to resume" and make it clickable
          return {
              state: "metric_configuration",
              status: value.metric_configuration_status.status,
              status_fomatted: "CLICK TO RESUME",
              message: "User Action Needed"
            };
        } else if (value.data_connection_status && value.data_connection_status.status !== 'NOT_STARTED') {
          // If status is IN_PROGRESS, show "Click to resume" and make it clickable
          if (value.data_connection_status.status === 'IN_PROGRESS') {
            return {
              state: "data_connection",
              status: value.data_connection_status.status,
              status_fomatted: "CLICK TO RESUME",
              message: value.data_connection_status.message
            };
          }
          return {
            state: "data_connection",
            status: value.data_connection_status.status,
            status_fomatted: value.data_connection_status.status.replace('_', ' '),
            message: value.data_connection_status.message
          };
        } else {
          return {
            state: "data_connection",
            status: 'NOT_STARTED',
            status_fomatted: 'NOT STARTED',
            message: 'Not Started'
          };
        }
      },
      renderHeader: (params) => (
        <HeaderWithFilterIcon {...params} selectedFilters={selectedFilters} />
      ),
      renderCell: ({ value, row }) => {
        if (!value) return null;

        return (<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: typography.font_size.sm }}>
          <Chip
            label={value.status_fomatted}
            variant="outlined"
            clickable={(value.status === 'IN_PROGRESS' && value.state === 'data_connection') || (value.status === 'NOT_STARTED' && value.state === 'metric_configuration') ||  (value.status === 'IN_PROGRESS' && value.state === 'metric_configuration')}
            onClick={() => {
              if ((value.status === 'IN_PROGRESS' && value.state === 'data_connection') || (value.status === 'NOT_STARTED' && value.state === 'metric_configuration') ||  (value.status === 'IN_PROGRESS' && value.state === 'metric_configuration')) {
                // If data-source type == CSV -> take to upload csv page else trigger Connector resume from here
                if (row.connector_name === "csv" && value.status === 'IN_PROGRESS' && value.state === 'data_connection') {
                  navigate(`/onboarding/${row.connector_name}`);
                } else {
                  // Resume data connection
                  fetch(`${process.env.REACT_APP_BASE_URL}connections/${row.datasource_id}/resume`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  }).then((response) => {
                    // Response JSON
                    response.json().then((data) => {
                      if (data?.success) {
                        if (data?.redirect.self) {
                          // navigate internally to another route
                          navigate(data?.redirect.path);
                        } else {
                          // redirect to the connect card URI which was formed by saas-backend
                          window.open(
                            `${data?.redirect.uri}&redirect_uri=${process.env.REACT_APP_SECURED_PROTOCOL}${process.env.REACT_APP_DOMAIN}${data?.redirect.path}`,
                            windowName,
                            windowFeatures
                          );
                        }
                      }
                    });
                  });
                }
              }
            }}
            sx={{
              fontFamily: 'Poppins',
              fontSize: 12,
              fontWeight: 500,
              letterSpacing: 0.15,
              textAlign: 'center',
              textTransform: 'uppercase',
              backgroundColor: alpha(colors.status[value.status], 0.1),
              color: colors.status[value.status],
              borderColor: colors.status[value.status],
              height: 25,
              borderRadius: 1,
              borderWidth: 1,
              alignContent: 'center',
            }}
          />
          <Typography component="span"
            sx={{
              fontFamily: 'Poppins',
              fontSize: typography.font_size.xs,
              fontWeight: typography.font_weight.regular,
              letterSpacing: 0.15,
              textAlign: 'center',
              opacity: 0.7,
              color: colors.comet,
              paddingTop: 1.5
            }}
          >
            {/* If status is IN_PROGRESS, create animation for message with ellipsis */}
            {value.status === 'IN_PROGRESS' ?
              DataConnectionAnimation(value.message) : value.message}
          </Typography>
        </Box>);
      }
    },
    {
      ...baseColumnOptions,
      field: "sync_frequency",
      headerName: "Sync Frequency",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      renderHeader: (params) => (
        <HeaderWithFilterIcon {...params} selectedFilters={selectedFilters} />
      ),
      renderCell: ({ value }) => {
        if (!value) return <Typography component="span" fontSize={typography.font_size.sm}>Daily, 12:00 AM UTC</Typography>;

        return <Typography component="span" fontSize={typography.font_size.sm}>{value}</Typography>;
      },
    },
    {
      ...baseColumnOptions,
      field: "created_at",
      headerName: "Created",
      headerAlign: "center",
      align: "center",
      flex: 0.7,
      valueFormatter: ({ value }) =>
        value && generateDifferenceInTimeString(parseISO(value)),
      renderCell: ({ value, formattedValue }) => {
        if (!value) return (
          <Typography component="span" fontSize={typography.font_size.sm} fontWeight={typography.font_weight.mediumx} lineHeight={24}>NA</Typography>
        );
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography component="span" sx={{
              fontSize: typography.font_size.sm,
              fontWeight: typography.font_weight.mediumx
            }}
            >{formattedValue}
            </Typography>
            <Typography component="span" sx={{
              fontSize: typography.font_size.xs,
              fontWeight: typography.font_weight.regular,
              textAlign: 'center',
              opacity: 0.7,
              color: colors.comet,
              paddingTop: 1.5
            }}
            >{format(parseISO(value), 'EEE, dd MMM yyyy')}</Typography>
          </div>
        );
      },
    },
    {
      ...baseColumnOptions,
      disableColumnMenu: true,
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      renderCell: ({ formattedValue, row }) => {
        // Action Buttons
        return (
          <Stack direction="row" gap={0.5}>
            <Tooltip title="Edit">
              <span>
                <IconButton
                  disabled={true}
                >
                  <DriveFileRenameOutlineOutlinedIcon
                    sx={{
                      color: colors.gray[500],
                      "&:hover": {
                        color: "#1556EF",
                      },
                      "&:disabled": {
                        opacity: 0.6
                      }
                    }}
                    fontSize="small"
                  /> </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Copy">
              <span>
                <IconButton
                  disabled={true}
                >
                  <ContentCopyOutlinedIcon
                    sx={{
                      color: colors.gray[500],
                      "&:hover": {
                        color: "#1556EF",
                      },
                      "&:disabled": {
                        opacity: 0.6
                      }
                    }}
                    fontSize="small"
                  /> </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Share">
              <span>
                <IconButton
                  disabled={true}
                >
                  <ShareOutlined
                    sx={{
                      color: colors.gray[500],
                      "&:hover": {
                        color: "#1556EF",
                      },
                      "&:disabled": {
                        opacity: 0.6
                      }
                    }}
                    fontSize="small"
                  /> </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <span>
                <IconButton
                  disabled={row.status.metric_configuration_status.status === 'SUCCESSFUL' || row.status.metric_configuration_status.status === 'FAILED'}
                  onClick={() => {
                    // Delete data connection
                    fetch(`${process.env.REACT_APP_BASE_URL}connections/${row.datasource_id}`, {
                      method: 'DELETE',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    }).then((response) => {
                      // Response JSON
                      response.json().then((data) => {
                        if (data?.success) {
                          // Update the table
                          listDatasources();
                        }
                      }).catch((error) => {
                        if (error.status === HTTP_STATUS.UNAUTHORIZED) {
                          // Redirect to login page
                          navigate("/login");
                        }
                      });
                    }).catch((error) => {
                        if (error.status === HTTP_STATUS.UNAUTHORIZED) {
                          // Redirect to login page
                          navigate("/login");
                        }
                      });
                  }}
                >
                  <DeleteOutlineOutlinedIcon
                    sx={{
                      color: colors.gray[500],
                      "&:hover": {
                        color: "#E55F5F",
                      },
                      "&:disabled": {
                        opacity: 0.6
                      }
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  function handlePageSizeChange(newPageSize) {
    setPageSize(newPageSize);
    setPageNumber(0);
  }

  const listDatasources = (tenant_id = selectedTenant, page_number = pageNumber + 1, page_size = pageSize, sort_model = []) => fetch(`${process.env.REACT_APP_BASE_URL}connections?tenant_id=${tenant_id}&page_num=${page_number}&count=${page_size}&${sort_model.length == 0 ? '' : new URLSearchParams({
    sort_by: sort_model[0].field,
    order: sort_model[0].sort,
  })}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json());

  const {
    isLoading,
    data,
  } = useQuery({
    queryKey: ['datasources', { selectedTenant, pageSize, pageNumber, sortModel, selectedFilters }],
    queryFn: () => listDatasources(selectedTenant, pageNumber + 1, pageSize, sortModel),
    refetchOnWindowFocus: "always",
    refetchInterval: 5000,
    retry: true,
    enabled: true,
  });

  return (
    <Container
      maxWidth="100%"
      sx={(theme) => ({
        py: { sm: theme.spacing(5), sl: theme.spacing(10) },
        px: { sm: "5%", sl: "5%" },
      })}
    >
      <Box
        sx={{
          width: "100%",
          height: "75vh",
        }}
      >
        <DataGrid
          disableSelectionOnClick
          components={{
            LoadingOverlay: LinearProgress,
            ColumnMenu: DatasourcesColumnMenu,
          }}
          componentsProps={{
            columnMenu: {
              selectedTenant,
              sortModel,
              setSortModel,
              selectedFilters,
              setSelectedFilters,
            },
            pagination: {
              showFirstButton: true,
              showLastButton: true,
            },
          }}
          rowHeight={75}
          columns={columns}
          getRowId={getDatsourceId}
          loading={isLoading}
          onPageChange={(newPageNumber) => setPageNumber(newPageNumber)}
          onPageSizeChange={handlePageSizeChange}
          page={pageNumber}
          pageSize={pageSize}
          pagination
          paginationMode="server"
          rowCount={data?.count || 0}
          rows={data?.rows || []}
          rowsPerPageOptions={ROWS_PER_PAGE}
          sortingMode="server"
          sortingOrder={SORTING_ORDER}
          sortModel={sortModel}
          sx={{
            ["& .MuiDataGrid-columnHeaders"]: {
              backgroundColor: colors.gray[100],
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            backgroundColor: colors.white,
            borderRadius: 1,
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
          }}
          slots={{
            noRowsOverlay: nowRowsOverlayComponent,
          }}
        />
      </Box>
      <Stack direction="row" gap={5} sx={{ justifyContent: "flex-end", alignItems: "center", paddingTop: 7.5 }}>
        <ActionButton
          href="/onboarding/select-datasources"
          sx={{
            background: colors.white,
            color: "var(--mariner)",
            border: 1.5,
            borderColor: "var(--mist-grey)",
            ":hover": {
              background: "var(--mariner)",
              borderColor: "#245AE6",
              color: colors.white,
            },
          }}
        >
          + Add Data Source
        </ActionButton>
        <ActionButton
          href={`mailto:success@outoftheblue.ai?subject=Help%20Missing%20Connector%20%3A%20${selectedTenant}&body=Hi%2C%0D%0D%0AI%20am%20not%20able%20to%20find%20previously%20configured%20datasource.%0D%0A%0D%0AThanks`}
        >
          Talk to us
        </ActionButton>
      </Stack>
    </Container>
  );
};

export default DatasourcesStatusScreen;