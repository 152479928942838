import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  List,
  listClasses,
  ListItem,
  ListItemText,
  Popover,
  popoverClasses,
  Typography,
} from "@mui/material";
import { deserializeToJSX, parseMarkdown } from "../../services/markdown";
import { ChartSubtitleTypography } from "./styled-components/Container.styled";
import { isEmpty } from "../../utils/is";
import { typography } from "../../shared/theme-constants";

const DIMENSION_TYPE_TO_LABEL = {
  total: "analyzed",
  occurrence: "evidence supporting",
  non_occurrence: "evidence non-supporting",
};

const PopoverTitle = styled((props) => (
  <Typography component="div" variant="h6" color="secondary.main" {...props} />
))(({ theme }) => ({
  borderBottom: "1px solid",
  borderColor: theme.palette.secondary.light,
  textTransform: "capitalize",
  fontWeight: typography.font_weight.regular,
}));

const DimensionPopoverTrigger = styled("span")({
  position: "relative",
  textDecoration: "underline",
  cursor: "pointer",
  fontWeight: typography.font_weight.bold,
});

const StyledPopover = styled(Popover)({
  pointerEvents: "none",

  [`& .${popoverClasses.paper}`]: {
    width: "100%",
    maxWidth: "22rem",
    maxHeight: "18rem",
    padding: "1rem 2rem",

    [`& .${listClasses.root}`]: {
      maxHeight: "14rem",
      overflowY: "auto",
    },
  },
});

function createSerializer(id, onDimensionEnter, onDimensionLeave) {
  return {
    link: ({ url, value }) => (
      <DimensionPopoverTrigger
        aria-owns={id}
        aria-haspopup="true"
        onMouseEnter={(event) => onDimensionEnter(event, url)}
        onMouseLeave={onDimensionLeave}
      >
        {value}
      </DimensionPopoverTrigger>
    ),
  };
}

const TimeseriesChartDescription = ({ description, sx }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dimensionType, setDimensionType] = useState("");

  function handlePopoverOpen(event, type) {
    setAnchorEl(event.currentTarget);
    setDimensionType(type);
  }

  function handlePopoverClose() {
    setAnchorEl(null);
  }

  const dimensions = description[dimensionType] || [];
  const open = Boolean(anchorEl) && !isEmpty(dimensions);
  const id = open ? "dimensions-popover" : undefined;

  return (
    <ChartSubtitleTypography sx={sx}>
      {deserializeToJSX(
        parseMarkdown(description.cause),
        createSerializer(id, handlePopoverOpen, handlePopoverClose)
      )}

      <StyledPopover
        disableScrollLock
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        disableRestoreFocus
      >
        <PopoverTitle>
          Top {dimensions.length} {DIMENSION_TYPE_TO_LABEL[dimensionType]}{" "}
          dimensions
        </PopoverTitle>

        <List>
          {dimensions.map((dimension) => (
            <ListItem key={dimension} disableGutters disablePadding>
              <ListItemText primary={dimension} />
            </ListItem>
          ))}
        </List>
      </StyledPopover>
    </ChartSubtitleTypography>
  );
};

export default TimeseriesChartDescription;
