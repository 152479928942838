import axios from "axios";
import { ERROR } from "../constants/notificationConstants";
import { HTTP_STATUS } from "../shared/enums/http-status";

const url = process.env.REACT_APP_BASE_URL.concat("insights");

export const editTitleSummary = (id, payload, isInsight) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${url}${isInsight ? "" : "/groups"}/${id}/annotate`, {
      ...payload,
    });
    return data;
  } catch (error) {
    let errorMessage = "Error occurred, try again after sometime";
    if (error?.response?.status === HTTP_STATUS.SERVER_ERROR) {
      errorMessage = "Some error occurred while updating the record";
    }
    dispatch({ type: ERROR, payload: errorMessage });
    throw error;
  }
};
