import { useCallback, useState } from "react";
import DOMPurify from "dompurify";

const useForm = (initialValues, validate, action) => {
  const [inputs, setInputs] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const resetForm = useCallback(() => {
    setErrors({});
  }, []);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const validationErrors = validate(inputs, initialValues);
      const errorExists = Object.keys(validationErrors).length > 0;
      setErrors(validationErrors);

      if (errorExists) {
        // if input validation fails then reset the form values to initial values
        setInputs(initialValues);
      } else {
        action(inputs, { resetForm, setInputs });
      }
    },
    [action, initialValues, inputs, resetForm, validate]
  );

  const handleInputChange = useCallback((event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: DOMPurify.sanitize(event.target.value),
    }));
  }, []);

  return {
    handleSubmit,
    setInputs,
    handleInputChange,
    resetForm,
    inputs,
    errors,
  };
};

export default useForm;
