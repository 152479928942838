import { colors } from "../theme-constants";
import { config } from "./common-config";

export const groupChartConfig = {
  ...config,
  scrollZoom: false,
  useResizeHandler: true,
  responsive: true,
  displaylogo: false,
  displayModeBar: false,
};

/**
 * @type {import("plotly.js").Data}
 */
export const groupChartPlot = {
  type: "scatter",
  marker: {
    size: 17,
    symbol: "circle",
    line: {
      width: 2,
      color: "white",
    },
  },
  line: {
    dash: "solid",
    width: 4,
  },
  mode: "lines+markers",
};

/**
 * @type {import("plotly.js").Layout}
 */
export const groupChartLayout = {
  title: {
    font: {
      color: colors.gray[500],
      family: "Poppins",
      size: 18,
    },
  },
  ticks: "outside",
  showlegend: true,
  margin: {
    t: 40,
    pad: 10,
    b: 60,
  },
  legend: {
    x: 1.1,
    y: 1,
    font: {
      color: colors.gray[500],
      size: 12,
    },
    itemsizing: "constant",
    orientation: "h",
    yanchor: "bottom",
    xanchor: "right",
    valign: "top",
  },
  // For responsive chart
  autosize: true,
  hovermode: "closest",
  xaxis: {
    type: "date",
    showline: false,
    showgrid: true,
    fixedrange: true,
    title: {
      text: "",
      font: {
        color: colors.gray[300],
      },
    },
    tickfont: {
      color: colors.gray[500],
    },
  },
  yaxis: {
    showline: false,
    zeroline: true,
    fixedrange: true,
    zerolinecolor: colors.gray[100],
    title: {
      text: "",
      font: {
        color: colors.gray[500],
      },
    },
    tickformat: ",.2%",
    tickfont: {
      color: colors.gray[500],
    },
  },
};
