import scStyled from "styled-components";
import { styled } from "@mui/material/styles";
import { colors, typography } from "../../../../shared/theme-constants";

export const StyledTitle = scStyled.div`
  font-style: normal;
  font-weight: 500;
  font-size: ${({ $allowEdit }) => ($allowEdit ? "1.3rem" : "2.3rem")};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -7px;
  padding-bottom: 5px;
  color: ${colors.title};
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    flex-direction: row;
    font-size: 14px;
    line-height: 21px;
    width: 80%;
    align-self: flex-start;
    text-align: left;
    height: 100px;
    font-weight: 500;
  }
`;

export const SummaryText = scStyled.div`
  display: ${({ $isEditing }) => $isEditing && "flex"};
  gap: 4px;
  align-self: stretch;
  text-align: left;
  font-style: normal;
  white-space: pre-wrap;
  font-weight: 400;
  font-size: ${({ $allowEdit }) => ($allowEdit ? "0.9rem" : "1.2rem")};
  line-height: 1.5;
  color: ${colors.title};
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${({ theme }) => theme.tablet}) {
    flex-direction: row;
    align-self: flex-end;
    width: auto;
    height: auto;
    margin-left: -79px;
    font-size: 13px;
  }
`;

export const ButtonLabel = styled("span")`
  font-size: 13px;
  line-height: 2;
  color: ${colors.title};
  font-weight: ${typography.font_weight.bold};
  text-transform: none;
`;

export const SemiBold = styled("b")({
  fontWeight: typography.font_weight.semiBold,
});

export const Bold = styled("b")({
  fontWeight: typography.font_weight.bold,
});

export const ColoredBoldMetric = ({ accent, className, ...props }) => {
  const style = accent
    ? {
        color:
          accent === "positive" ? colors.positive_green : colors.negative_red,
      }
    : {};

  return <SemiBold className={className} style={style} {...props} />;
};
