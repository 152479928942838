import styled from "@emotion/styled";
import { Chip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { colors } from "../../shared/theme-constants";
import Icon from "./Icon";

const StyledChip = styled(Chip)(({ theme, fontSize }) => ({
  borderRadius: theme.typography.pxToRem(5),
  "& .MuiChip-label": { fontSize: theme.typography.pxToRem(fontSize), paddingRight: 0 },
}));

const EventDollarImpactChip = ({ impactData }) => {
  const chipRef = useRef(null);
  const [labelFontSize, setLabelFontSize] = useState(0);
  const [iconSize, setIconSize] = useState(0);

  const fetchImpactIcon = (impactData) => {
    if (impactData?.monetary?.value > 0) {
      return "arrow-positive-up";
    } else {
      return "arrow-negative-down";
    }
  };
  const fetchImpactStyles = (impactData) => {
    if (impactData?.monetary?.value > 0) {
      return Object.assign({},  { color: colors.SEVERITY.GREEN.medium, backgroundColor: colors.green[150] }) ;
    } else {
      return Object.assign({}, { color: colors.red[450], backgroundColor: colors.red[150] });
    }
  };

  useEffect(() => {
    // This dynamically adjusts the font size and the size of the icon of the chip according to the size of the chip
    if (chipRef.current) {
      const chipSize = chipRef.current.getBoundingClientRect();
      const fontSize = Math.min(chipSize.width, chipSize.height) * 0.5; // Adjust the multiplier as needed
      setLabelFontSize(fontSize);
      const iconSize = Math.min(chipSize.width, chipSize.height) * 0.04 +'em';
      setIconSize(iconSize);
    }
  }, []);
  return (
    <StyledChip
      icon={<Icon name={fetchImpactIcon(impactData)} size={iconSize} showcursor="true" />}
      sx={(theme) => fetchImpactStyles(impactData)}
      style={{ height: "100%", width: "100%" }}
      label={impactData?.monetary?.formatted_value}
      ref={chipRef}
      fontSize={labelFontSize}
    ></StyledChip>
  );
};

export default EventDollarImpactChip;
