import React from "react";
import { createPortal } from "react-dom";
import { useDispatch } from "react-redux";

// MUI imports
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

// Local imports
import { EventHistory } from "../EventHistory";
import { DownloadInsight } from "../DownloadInsight";
import { isNullOrEmpty } from "../../../utils/is";
import { HIDE_MODAL } from "../../../constants/modalConstants";
import { InsightModal } from "../../insights/insight/InsightModal";
import Icon from "../Icon";
import { colors } from "../../../shared/theme-constants";
import { ToggleContainer } from "../styled-components";
import { DownloadGroupInsight } from "../DownloadGroupInsight";

// List of current modals, if you wish to add support for new modal,
// just add it to this object with the corresponding component
const MODAL_COMPONENTS = {
  EVENT_HISTORY: EventHistory,
  DOWNLOAD_INSIGHT: DownloadInsight,
  DOWNLOAD_GROUP_INSIGHT: DownloadGroupInsight,
  VIEW_INDIVIDUAL_INSIGHT: InsightModal,
};

// This is a generic modal component, governs via redux store,
// it mounts the Modal on top of the DOM body, using creatPortal
export const ModalComponent = ({
  modalName,
  modalProps,
  showCloseButton = false,
}) => {
  const dispatch = useDispatch();

  if (!modalName) return null;

  const handleClose = () => {
    dispatch({
      type: HIDE_MODAL,
    });
  };

  // Here we are assigning the applicable modal based on the modal name
  const SpecificModal = MODAL_COMPONENTS[modalName];

  return createPortal(
    <Modal
      componentsProps={{
        backdrop: {
          style: { backgroundColor: "rgba(17, 39, 133, 0.82)" },
        },
      }}
      open={!isNullOrEmpty(modalName)}
      onClose={handleClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box sx={{ alignSelf: "center", mx: "auto", outline: "none" }}>
        <div style={{ position: "relative" }}>
          {showCloseButton && (
            <ToggleContainer>
              <IconButton onClick={handleClose}>
                <Icon
                  name="cross"
                  size="24px"
                  showcursor="true"
                  strokecolor={colors.gray[400]}
                />
              </IconButton>
            </ToggleContainer>
          )}
        </div>
        <SpecificModal {...modalProps} />
      </Box>
    </Modal>,
    document.body
  );
};
