import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { typography } from "../../../shared/theme-constants";
import LabelChip from "../../common/LabelChip";
import { useDispatch, useSelector } from "react-redux";
import { EVENTS_FEED_SUMMARY_FILTERS } from "../../../constants/eventConstants";
import { eventSummaryFilterColorMapping } from "../../../constants/commonConstants";
import { useNavigate } from "react-router-dom";

const SelectedFiltersBox = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const eventsSummaryFilter = useSelector((state) => state.eventsFeedFilters?.summaryFilters);

  // Below function will remove the selected filter from store.
  const handleClearFilter = (filterName) => {
    dispatch({ type: EVENTS_FEED_SUMMARY_FILTERS, payload: { value: null, type: filterName } });
    // Update the URL query parameters
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(filterName); // Remove the filter from query parameters

    navigate({ search: searchParams.toString() }); // Update the URL
  };

  const generateColorsForFilterChips = (filterName, filterValue) => {
    if (filterName === 'provider_uuid') {
      return eventSummaryFilterColorMapping.provider;
    }
    return eventSummaryFilterColorMapping[filterValue];
  };

  return (
    <Stack
      sx={(theme) => ({
        flexDirection: "row",
        minHeight: 50,
        border: "1px solid #D6D6D6",
        padding: "0px 16px",
        background: "#FAFAFA",
        borderRadius: "6px",
        [theme.breakpoints.down("lm")]: {
          alignItems: "center",
          padding: 2,
          minHeight: 30,
        },
        alignItems: "center",
      })}
    >
      <Typography
        sx={(theme) => ({
          fontSize: theme.typography.pxToRem(12),
          color: "var(--comet)",
          fontWeight: typography.font_weight.mediumx,
          mr: 2,
        })}
      >
        Selected Filters:
      </Typography>
      <Box gap={1} sx={{ display: 'flex' }}>
        {Object.entries(eventsSummaryFilter).map(([filterName, filterValue]) => (
          <LabelChip
            key={filterName}
            tags={filterName === "provider_uuid" ? [filterValue.name] : [filterValue]}
            style={{
              borderRadius: "16px",
              backgroundColor: generateColorsForFilterChips(filterName, filterValue),
            }}
            onDelete={() => handleClearFilter(filterName)}
          />
        ))}
      </Box>
    </Stack>
  );
};

export default SelectedFiltersBox;
