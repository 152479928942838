import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Grid, InputAdornment, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { createConnector } from "../../actions/onboardingActions";
import { useSelectedTenant } from "../../hooks/useSelectedTenant";
import { HTTP_STATUS } from "../../shared/enums/http-status";
import { typography } from "../../shared/theme-constants";
import { Loading } from "../common/styled-components";
import { SET_SNACKBAR } from "../../reducers/notificationSnackbarReducer";

const Title = styled("div")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(35),
  fontWeight: typography.font_weight.mediumx,
  lineHeight: theme.typography.pxToRem(53),
  letterSpacing: "0em",
  textAlign: "center",
  color: "#5C6178",
  margin: "3% 0 3% 0",
}));

const TextFieldComponent = styled(TextField)(({ theme }) => ({
  alignSelf: "center",
  marginBottom: "5%",
  width: "100%",
  height: theme.typography.pxToRem(35),
  boxShadow: "0px 1px 2px 0px #1F293714",
  border: "1px solid #E5E7EB",
  borderRadius: theme.typography.pxToRem(4),
  background: "linear-gradient(0deg, #E5E7EB, #E5E7EB),linear-gradient(0deg, #EAEAEA, #EAEAEA)",
}));

const ActionButton = styled(Button)(({ theme }) => ({
  height: "41px",
  padding: theme.spacing(0, 7),
  borderRadius: "9px",
  border: "1.5px",
  background: "linear-gradient(0deg, #245AE6, #245AE6)",
  color: "white",
  ":hover": {
    cursor: "pointer",
  },
  alignSelf: "center",
}));

const ConnectorIconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: theme.typography.pxToRem(90),
  height: theme.typography.pxToRem(90),
  borderRadius: "10px",
  background: "white",
  boxShadow: "0px 4px 22px 0px #11278529",
  marginBottom: theme.typography.pxToRem(15),
}));

const SelectConnectorsBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "20px",
  width: "100%",
  height: theme.typography.pxToRem(430),
  borderRadius: "10px",
  background: "#FFFFFF",
  boxShadow: "0px 4px 22px 0px #11278540",
}));

const SelectDataSources = () => {
  const windowFeatures = " width=500,height=600 top=500 left=500";
  const windowName = "_self";
  const [activeStep, setActiveStep, selectedConnector, setSelectedConnector, availableConnectors] = useOutletContext();
  const selectedTenant = useSelectedTenant();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [connectorsList, setConnectorsList] = useState(availableConnectors);

  useEffect(() => setActiveStep(0));
  // filter the list when user starts typing in
  const handleChange = (event) => {
    event.preventDefault();
    let filteredList = availableConnectors.filter((item) => {
      return item?.connector_display_name.toLowerCase().includes(event.target.value);
    });
    setConnectorsList(filteredList);
  };

  // Navigate to the page of selected data-source
  const handleNextPage = (event, nextPage) => {
    event.preventDefault();
    setSelectedConnector(nextPage);
    setLoading(true);
    // If data-source type == CSV -> take to upload csv page else trigger Coonector creation from here
    if (nextPage.connector_name === "csv") {
      navigate(`/onboarding/${nextPage.connector_name}`);
    } else {
      dispatch(createConnector({ connector_uuid: nextPage?.connector_uuid, tenant_id: selectedTenant }, false))
        .then((response) => {
          if (response.data.success) {
            if (response?.data.redirect.self) {
              // navigate internally to another route
              navigate(response.data.redirect.path);
            } else {
              // redirect to the connect card URI which was formed by saas-backend
              window.open(
                `${response?.data?.redirect.uri}&redirect_uri=${process.env.REACT_APP_SECURED_PROTOCOL}${process.env.REACT_APP_DOMAIN}${response.data.redirect.path}`,
                windowName,
                windowFeatures
              );
            }
          } else {
            setLoading(false);
            // Display error message to user
            dispatch({
              type: SET_SNACKBAR,
              snackbarOpen: true,
              snackbarMessage: "Click on 'Talk to us' to get help",
              snackbarType: "error",
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.status === HTTP_STATUS.UNAUTHORIZED) {
            // Redirect to login page
            navigate("/login");
          } else {
            // Display error message to user
            dispatch({
              type: SET_SNACKBAR,
              snackbarOpen: true,
              snackbarMessage: "Click on 'Talk to us' to get help",
              snackbarType: "error",
            });
          }
        });
    }
  };

  return (
    <Box sx={{ justifyContent: "center", display: "flex", padding: "0 5%" }}>
      <Stack direction="column" sx={{ maxWidth: "920px", width: "100%" }}>
        <Title>Select a connector to get started</Title>

        <SelectConnectorsBox>
          <div style={{ maxWidth: "769px", width: "100%" }}>
            <TextFieldComponent
              onChange={handleChange}
              type="search"
              variant="standard"
              placeholder="Search"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment sx={{ pl: 2.5 }} position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ overflowY: "auto", width: "100%" }}>
            {loading ? (
              <Loading />
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                {connectorsList?.map((listItem) => (
                  <React.Fragment key={listItem.connector_name}>
                    <Grid item sm={4}>
                      <Button
                        disabled={!listItem.is_active}
                        onClick={(event) => handleNextPage(event, listItem)}
                        sx={{
                          ":hover": {
                            cursor: "pointer",
                            transform: "scale3d(1.1,1.1,1.1)",
                            transition: "transform 0.2s ease-out",
                          },
                          margin: "auto",
                          display: "flex",
                          flexDirection: "column",
                          "&:disabled": {
                            opacity: 0.4,
                            color: "var(--zircon)",
                          },
                        }}
                        disableRipple
                      >
                        <ConnectorIconContainer>
                          <img src={listItem.connector_logo_url} alt={listItem.connector_display_name} />
                        </ConnectorIconContainer>
                        <span style={{ fontSize: typography.font_size.base, fontWeight: typography.font_weight.regular, color: "#000000"}}>
                          {listItem.connector_display_name}
                        </span>
                      </Button>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            )}
          </div>
        </SelectConnectorsBox>
        <Stack direction="row" gap={4} sx={{ justifyContent: "flex-end", alignItems: "center" }}>
          <span style={{ fontSize: typography.font_size.lg, fontWeight: typography.font_weight.mediumx, color: "var(--comet)" }}>Data source not available?</span>
          <ActionButton
            href={`mailto:success@outoftheblue.ai?subject=Help%20Missing%20Connector%20%3A%20${selectedTenant}&body=Hi%2C%0D%0D%0AWe%20are%20unable%20to%20on-board%20our%20dataset%20as%20the%20required%20connector%20is%20not%20listed%20in%20the%20%22connector%22%20page%0D%0A%0D%0AThanks`}
          >
            Talk to us
          </ActionButton>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SelectDataSources;
