import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import isEqual from 'lodash/isEqual';

import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { fetchPatternSeries } from "../../actions/insightActions";
import { HTTP_STATUS } from "../../shared/enums/http-status";
import { generateTimeSeriesChartTooltip } from "../../shared/highcharts-config/timeseries-chart-tooltip";
import { TIMESERIES_METRIC_CHART } from "../../shared/highcharts-config/timeseries-spikes-config";
import {
  actualTimeSeriesLayout as currentTimeSeriesLayout,
  spikesPointLayout,
  timeSeriesAreaRangelayout,
  previousTimeSeriesLayout,
} from "../../shared/plotly-config";
import { colors } from "../../shared/theme-constants";
import { formatDatePerFrequency } from "../../utils/dateUtils";
import { ErrorChartPlot } from "../common/ChartPlot";
import ListLoader from "../common/ListLoader";
import HighChartsReactComponent from "../common/highcharts/HighChartsReactComponent";
import { nFormatter } from "../../utils/stringUtils";
import { NORTH_START_METRIC_CONTEXT } from "../../constants/featureContexts";
import { isBefore } from "date-fns";

const DEFAULT_TIMEZONE = "Etc/UTC";

const prepareMetricTimeSeriesChartParams = (
  pattern,
  startDate,
  endDate,
  tenantGlobalFilter,
  isForecastEnabled = false
) => {
  let paramsDetail = new URLSearchParams({
    tenant_id: pattern.tenant_id,
    kpi_id: pattern.kpi_id,
    pipeline_schedule: pattern.pipeline_schedule,
    timestamp_start: startDate,
    timestamp_end: endDate,
    dim_name: tenantGlobalFilter?.dimension_name,
    dim_val: tenantGlobalFilter?.dimension_value,
    is_forecast_enabled: isForecastEnabled,
  });

  return paramsDetail;
};
function MetricTimeSeriesChart({
  patternData,
  startDate,
  endDate,
  context = NORTH_START_METRIC_CONTEXT,
  metricError: MetricError,
  isForecastEnabled,
  tenantGlobalFilter,
  showPreviousPeriodComparison = false, // Check if we need to show previous period's comparison or not.
  previousPeriodstartDate, // if we have to show previous period's comparison then it's start date.
  selectedPipelineFrequency // Holds the selected frequency if it's week, month or quarter.
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [chartData, setChartData] = useState();
  const [chartOptions, setChartOptions] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    let isMounted = true;
    // periodStartDate checks if we have to show previous period's data with current period then it takes preivous period start date else current period start date.
    const periodStartDate = showPreviousPeriodComparison ? previousPeriodstartDate : startDate;
    let metricTimeSeriesParams = prepareMetricTimeSeriesChartParams(
      patternData.pattern,
      periodStartDate,
      endDate,
      tenantGlobalFilter,
      isForecastEnabled
    );

    dispatch(fetchPatternSeries(metricTimeSeriesParams))
      .then((timeseries) => {
        if (isMounted) {
          let timeSeriesDataPoints = [...timeseries];
          setChartData(timeSeriesDataPoints);
          createMetricTimeSeriesChartData(timeSeriesDataPoints, patternData.pattern);
        }
      })
      .catch((error) => {
        if (error?.response?.status === HTTP_STATUS.UNAUTHORIZED) {
          navigate("/login");
        } else {
          setError(error);
        }
      });

    return () => (isMounted = false);
  }, [startDate, endDate, patternData, dispatch, navigate, tenantGlobalFilter]);

  const generateMetricTimeSeriesChartOptions = (
    timeStampsArray,
    pattern,
    patternSeries,
    chartSeries
  ) => {
    const chartConfig = {
      credits: {
        enabled: false, // This disables the Highcharts watermark
      },
      legend: {
        enabled: false,
      },
      title: {
        text: "",
      },
      accessibility: {
        enabled: false,
      },

      xAxis: {
        type: "datetime",
        lineWidth: 0.6,
        tickPositions: [
          moment(timeStampsArray[0]).valueOf(),
          moment(timeStampsArray[timeStampsArray?.length - 1]).valueOf(),
        ],
        categories: timeStampsArray,
        labels: {
          style: {
            color: colors.gray[575],
            fontSize: ".75em",
          },
          formatter: function () {
            return formatDatePerFrequency(this.value, "w", DEFAULT_TIMEZONE);
          },
        },
      },

      yAxis: {
        gridLineWidth: 0,
        title: {
          text: "",
        },
        labels: {
          enabled: context == NORTH_START_METRIC_CONTEXT ? true : false,
          formatter: function () {
            if (pattern.kpi_format === "percentage") {
              return `${(this.value * 100).toFixed(1)}%`;
            }else {
              return nFormatter(this.value, 1);
            }
          }
        },
      },
      tooltip: {
        crosshairs: {
          enabled: true,
          width: 0.5,
          zIndex: 10,
          dashStyle: "longDash",
          color: "black",
        },
        shared: true,
        valueSuffix: "",
        borderColor: colors.gray[350],
        shadow: false,
        borderRadius: 3,
        borderWidth: 0.5,
        padding: 10,
        useHTML: true,
        formatter: function (tooltip) {
          return generateTimeSeriesChartTooltip(
            this,
            tooltip,
            patternSeries,
            pattern,
            TIMESERIES_METRIC_CHART,
            isForecastEnabled,
            showPreviousPeriodComparison
          );
        },
      },
      series: chartSeries,
    };
    setChartOptions(chartConfig);
    setIsLoading(false);
  };

  const createMetricTimeSeriesChartData = (patternSeries, pattern) => {
    let series = [];
    // declare anomalySeries array for spike downs and spike ups
    let spikeUpSeriesArray = [];
    let spikeDownSeriesArray = [];
    // declare the current period's data array
    let currentPeriodSeriesArray = [];
    // Array for the data of previous period
    let previousPeriodSeriesArray = [];
    // declare CI range array
    let confidenceIntervalArray = [];

    // declare timestamp array
    let timeStampsArray = [];

    for (let i = 0; i < patternSeries.length; i++) {
      // Parsing pattern data's timestamp to check if it's before the start date or not. Hence below line of code.
      const parsedTimeStampDate = moment.tz(patternSeries[i].timestamp, DEFAULT_TIMEZONE);
      const startMoment = moment.tz(startDate, DEFAULT_TIMEZONE);
      const previousPeriodStartMoment = moment.tz(previousPeriodstartDate, DEFAULT_TIMEZONE);
      // Below if condition check's if data's timestamp is before the start date of current period to handle the previous period data.
      if (
        showPreviousPeriodComparison &&
        isBefore(parsedTimeStampDate.toDate(), startMoment.toDate())
      ) {
        // Previous period data has different timestamp so to adjust it with current period's timestamp we find the offset time and put it in as x value.
        const offsetTimestamp = parsedTimeStampDate.add(
          startMoment.diff(previousPeriodStartMoment),
          "milliseconds"
        );
        // Data for previous period
        previousPeriodSeriesArray.push({
          x: offsetTimestamp.valueOf(),
          y: patternSeries[i].result.y,
          tooltipData: patternSeries[i].timestamp,
          frequency: selectedPipelineFrequency,
        });
      } else {
        // Handling data of current period as before
        timeStampsArray.push(patternSeries[i].timestamp);
        confidenceIntervalArray.push([
          moment(patternSeries[i].timestamp).valueOf(),
          patternSeries[i].result.yhat_lower,
          patternSeries[i].result.yhat_upper,
        ]);

        // Define accent based on yhat and y
        let accent = pattern.accent;
        if (
          patternSeries[i].result.anomaly == "True" &&
          patternSeries[i].result.y > patternSeries[i].result.yhat_upper
        ) {
          accent = pattern.accent == "positive" ? "positive" : "negative";
          // spike up
          spikeUpSeriesArray.push({
            x: moment(patternSeries[i].timestamp).valueOf(),
            y: patternSeries[i].result.y,
            anomaly: patternSeries[i].result,
          });
          series.push({ ...spikesPointLayout(accent, "up"), data: spikeUpSeriesArray });
        } else if (
          patternSeries[i].result.anomaly == "True" &&
          patternSeries[i].result.y < patternSeries[i].result.yhat_lower
        ) {
          accent = pattern.accent == "positive" ? "negative" : "positive";
          // spike down
          spikeDownSeriesArray.push({
            x: moment(patternSeries[i].timestamp).valueOf(),
            y: patternSeries[i].result.y,
            anomaly: patternSeries[i].result,
          });
          series.push({ ...spikesPointLayout(accent, "down"), data: spikeDownSeriesArray });
        }
        // currentPeriodSeriesArray holds the data for the current week, month or quarter.
        currentPeriodSeriesArray.push({
          x: moment(patternSeries[i].timestamp).valueOf(),
          y: patternSeries[i].result.y,
        });
      }
    }
    let lastTimeStampDate = moment.tz(
      timeStampsArray[timeStampsArray.length - 1],
      DEFAULT_TIMEZONE
    );
    let endMoment = moment.tz(endDate, DEFAULT_TIMEZONE);

    if (isBefore(lastTimeStampDate.toDate(), endMoment.toDate())) {
      timeStampsArray.push(endMoment.toISOString());
      currentPeriodSeriesArray.push({
        x: endMoment.valueOf(),
        y: null,
      });
      confidenceIntervalArray.push([endMoment.valueOf(), null, null]);
    }
    // Trace for current period data
    series.push({
      ...currentTimeSeriesLayout,
      name: "Current",
      data: currentPeriodSeriesArray,
      marker: {
        ...currentTimeSeriesLayout.marker,
        fillColor: colors.gray[375],
        lineColor: colors.gray[375],
        enabled: false,  // Disables the markers
        states: {
          hover: {
            enabled: true, // Ensures markers don’t appear on hover
          },
        },
      },
    });
    // Trace for previous period data
    series.push({
      ...previousTimeSeriesLayout,
      data: previousPeriodSeriesArray,
      marker: {
        enabled: false,  // Disables the markers
        states: {
          hover: {
            enabled: true, // Ensures markers don’t appear on hover
          },
        },
      },
    });
    series.push({ ...timeSeriesAreaRangelayout, data: confidenceIntervalArray });
    generateMetricTimeSeriesChartOptions(timeStampsArray, pattern, patternSeries, series);
  };

  if (isLoading) return <ListLoader count={1} height={200} paddingY={10} width="100%" />;

  if (error) {
    if (error.response?.status === HTTP_STATUS.UNAUTHORIZED) {
      return <Navigate to="/login" />;
    } else {
      return <ErrorChartPlot message="Overlays data not found." />;
    }
  }
  if (chartData?.length === 0) {
    return (
      <MetricError
        errorMessage={`Unable to get data for ${patternData.pattern.kpi_display_name}`}
      />
    );
  }

  return <HighChartsReactComponent containerProps={{ style: { height: "100%", width:"100%" } }} options={chartOptions} />;
}

function areEqual(preVProps, nextProps) {
  return (
    preVProps.overlayType === nextProps.overlayType &&
    preVProps.startDate === nextProps.startDate &&
    preVProps.endDate === nextProps.endDate &&
    isEqual(preVProps?.tenantGlobalFilter, nextProps?.tenantGlobalFilter)
  );
}

export default React.memo(MetricTimeSeriesChart, areEqual);
