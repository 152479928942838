import { useEffect, useState } from "react";
import axios from "axios";

import { isAfter, parseISO } from "date-fns";

import {
  forecastPlot,
  scatterLayout,
  errorLayout,
  timeseriesPlot,
  styles,
} from "../shared/plotly-config";
import { colors } from "../shared/theme-constants";
import { labels } from "../shared/intl/labels";
import { processAnomalySeriesData } from "../utils/transformData";
import { datePartsWithTZ } from "../utils/dateUtils";
import { PERCENTAGE } from "../constants/commonConstants";
import { isEmpty } from "../utils/is";
import { nFormatter } from "../utils/stringUtils";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const prepareParams = (pattern, startTime, endTime, isForecastEnabled) => {
  const paramsDetail = new URLSearchParams({
    tenant_id: pattern.tenant_id,
    kpi_id: pattern.kpi_id,
    pipeline_schedule: pattern.pipeline_schedule,
  });

  if (!pattern.pipeline_tag) {
    paramsDetail.append("pipeline_id", pattern.pipeline_id);
  }
  if (pattern.chart_ts_start) {
    paramsDetail.append("timestamp_start", startTime || pattern.chart_ts_start);
  }
  if (pattern.chart_ts_end) {
    paramsDetail.append("timestamp_end", endTime || pattern.chart_ts_end);
  }
  if (pattern.dim_name) {
    paramsDetail.append("dim_name", pattern.dim_name);
  }
  if (pattern.dim_val) {
    paramsDetail.append("dim_val", pattern.dim_val);
  }
  if (pattern.pipeline_guid) {
    paramsDetail.append("pipeline_guid", pattern.pipeline_guid);
  }
  if(isForecastEnabled){
    paramsDetail.append("is_forecast_enabled", isForecastEnabled);
  }
  return paramsDetail;
};

const getHoverTemplateY = (yValue, kpiFormat) => {
  return kpiFormat === PERCENTAGE
    ? `${Math.round(yValue * 100)}%`
    : `${nFormatter(yValue, 1)}`;
};

export const useSpikes = (patternData, startTime, endTime) => {
  const [chartData, setChartData] = useState(undefined);
  const [layout, setLayout] = useState(scatterLayout);
  const [error, setError] = useState(undefined);
  const [initialYRange, setInitialYRange] = useState(undefined);

  useEffect(() => {
    const startDate = parseISO(startTime);
    const endDate = parseISO(endTime);
    const today = new Date();
    // Start date should not be after today or End date
    if (isAfter(startDate, today) || isAfter(startDate, endDate)) {
      setChartData([{ x: 0, y: 0 }]);
      const mergedLayout = {
        ...errorLayout,
        annotations: [
          {
            x: 2,
            y: 2,
            align: "center",
            text: labels.SPIKES_CHART.ERROR_MESSAGE,
            opacity: 0.75,
            showarrow: false,
            font: {
              size: 16,
              color: colors.gray[600],
            },
          },
        ],
      };
      setLayout(mergedLayout);
    } else {
      const pattern = patternData.pattern;
      const params = prepareParams(pattern, startTime, endTime);
      axios
        .get(BASE_URL.concat("pattern_series"), { params })
        .then((timeseries) => {
          const xaxis = [];
          const yaxis = [];
          const anomaly = [];
          const fcast_lower = [];
          const fcast_upper = [];
          const spikeUpsDowns = [];
          // it will hold the Plotly's hover template for expected range
          const fCastHoverTemplate = [];
          const actualValueTextTemplate = [];
          if (timeseries?.data && !isEmpty(timeseries.data)) {
            timeseries.data.forEach((series) => {
              xaxis.push(series.timestamp);
              anomaly.push(series.result.anomaly);
              yaxis.push(series.result.y);
              fcast_lower.push(series.result.yhat_lower);
              fcast_upper.push(series.result.yhat_upper);
              spikeUpsDowns.push(
                series.result.y < series.result.yhat_lower
                  ? labels.SPIKES_CHART.HOVER_INFO.SPIKE_DOWN
                  : series.result.y > series.result.yhat_upper
                  ? labels.SPIKES_CHART.HOVER_INFO.SPIKE_UP
                  : null
              );
              actualValueTextTemplate.push(`${getHoverTemplateY(series.result.y, pattern.kpi_format)}`);
              fCastHoverTemplate.push(
                `${getHoverTemplateY(
                  series.result.yhat_lower,
                  pattern.kpi_format
                )} - ${getHoverTemplateY(
                  series.result.yhat_upper,
                  pattern.kpi_format
                )}`
              );
            });
          }
          const series = new Map();
          series.set("__time", xaxis);
          series.set("anomaly", anomaly);
          series.set("y", yaxis);
          series.set("yhat_lower", fcast_lower);
          series.set("yhat_upper", fcast_upper);
          series.set("actual_value_text", actualValueTextTemplate);
          series.set("spike_up_down", spikeUpsDowns);

          // Process timeseries data
          const { dataActual, plotActual, textActual, plotAnomaly, dataAnomaly, textAnomaly, anomalyLabels, isCurrentAnomaly } =
            processAnomalySeriesData(
              series.get("__time"),
              series.get("y"),
              series.get("anomaly"),
              series.get("actual_value_text"),
              series.get("spike_up_down")
            );

          // Prepare trace for Actual
          const timeseriesDataActual = {
            ...timeseriesPlot,
            x: plotActual,
            y: dataActual,
            name: labels.SPIKES_CHART.LEGENDS.ACTUAL,
            text: textActual,
            hovertemplate: `<b>${labels.SPIKES_CHART.LEGENDS.ACTUAL}</b>: %{text}<extra></extra>`,
            legendrank: 2000,
          };

          // Prepare trace for Spikes
          const timeseriesDataAnomaly = {
            ...timeseriesPlot,
            x: plotAnomaly,
            y: dataAnomaly,
            name: labels.SPIKES_CHART.LEGENDS.SPIKES,
            text: anomalyLabels,
            customdata: textAnomaly,
            hovertemplate: `<b>%{text}</b>: %{customdata}<extra></extra>`,
            legendrank: 2001,
            marker: {
              color: colors.pink[500],
              size: 3,
            },
          };

          // Prepare trace for current anomaly
          let currentAnomaly = {};
          if (isCurrentAnomaly && !patternData.hasTrend) {
            currentAnomaly = {
              ...timeseriesPlot,
              x: [pattern.insight_id_ts],
              y: [pattern.current_value],
              showlegend: false,
              text: [
                `${
                  pattern.pattern_direction === "up"
                    ? labels.SPIKES_CHART.HOVER_INFO.SPIKE_UP
                    : labels.SPIKES_CHART.HOVER_INFO.SPIKE_DOWN
                }`,
              ],
              customdata: [getHoverTemplateY(pattern.current_value, pattern.kpi_format)],
              hovertemplate: `<b>%{text}</b>: %{customdata}<extra></extra>`,
              marker: {
                symbol: "circle",
                color: colors.pink[500],
                size: 13,
                line: {
                  color: colors.pink[300],
                  width: 4,
                },
              },
            };
          }

          // DEV-1491: Prepare trace for Expected Value
          let expectedValue = {};
          if (!patternData.hasTrend) {
            expectedValue = {
              ...timeseriesPlot,
              x: [pattern.insight_id_ts],
              y: [pattern.expected_value],
              name: labels.SPIKES_CHART.LEGENDS.EXPECTED_VALUE,
              showlegend: pattern.expected_value > 0,
              text: [getHoverTemplateY(pattern.expected_value, pattern.kpi_format)],
              hovertemplate: `<b>${labels.SPIKES_CHART.HOVER_INFO.EXPECTED_VALUE}</b>: %{text}<extra></extra>`,
              marker: {
                symbol: "circle",
                color: colors.white_secondary,
                size: 12,
                line: {
                  color: colors.blue[925],
                  width: 2,
                },
              },
            };
          }

          // Prepare trace for forecast lower
          const fCast_lower = {
            ...forecastPlot,
            x: series.get("__time"),
            y: series.get("yhat_lower"),
            showlegend: false,
            hoverinfo: "skip",
            name: labels.SPIKES_CHART.HOVER_INFO.EXPECTED_LOW,
          };
          // Prepare trace for forecast upper
          const fCast_upper = {
            ...forecastPlot,
            x: series.get("__time"),
            y: series.get("yhat_upper"),
            name: labels.SPIKES_CHART.LEGENDS.EXPECTED_RANGE,
            text: fCastHoverTemplate,
            hovertemplate: `<b>${labels.SPIKES_CHART.LEGENDS.EXPECTED_RANGE}</b>: %{text} <extra></extra>`,
            fill: "tonexty",
          };

          if (patternData.hasTrend) {
            const ts_Data_Spikes = {
              ...timeseriesDataAnomaly,
              showlegend: false,
            };
            setChartData([
              fCast_lower,
              fCast_upper,
              timeseriesDataActual,
              ts_Data_Spikes,
              ...patternData.trendChartData,
            ]);
          } else if (isCurrentAnomaly) {
            setChartData([
              fCast_lower,
              fCast_upper,
              currentAnomaly,
              expectedValue,
              timeseriesDataActual,
              timeseriesDataAnomaly,
            ]);
          } else {
            setChartData([
              fCast_lower,
              fCast_upper,
              expectedValue,
              timeseriesDataActual,
              timeseriesDataAnomaly,
            ]);
          }

          // Layout
          let layout = {
            ...scatterLayout,
            yaxis: {
              ...scatterLayout.yaxis,
              // setting tickformat property to show percentages in plotly chart
              tickformat: pattern.kpi_format === PERCENTAGE ? ",.0%" : "",
              title: {
                ...scatterLayout.yaxis.title,
                text: pattern.kpi_display_name,
              },
            },
          };
          // Derive ymins and ymax for the trend change line
          const ymin = Math.min(...yaxis, ...fcast_lower);
          const ymax = Math.max(...yaxis, ...fcast_upper);
          setInitialYRange([ymin, ymax]);

          if (patternData.hasTrend) {
            const trendCP =
              patternData.pattern.pattern_data.trend_line.trend_change
                .lastcp_ts;
            const { year, month, day } = datePartsWithTZ(trendCP);
            const mon = `0${month + 1}`.slice(-2);
            const annotationText = `${year}-${mon}-${day}`;

            layout = {
              ...layout,
              // the trend change dotted line
              shapes: [
                {
                  type: "line",
                  xref: "x",
                  yref: "y",
                  width: 1.5,
                  x0: trendCP,
                  y0: ymin,
                  x1: trendCP,
                  y1: ymax,
                  line: {
                    color: colors.blue[500],
                    width: 1.5,
                    dash: "dot",
                  },
                },
              ],
              // Annotation text for the trend change line
              annotations: [
                {
                  x: trendCP,
                  y: ymax,
                  text: `New trend on<br><b>${annotationText}</b>`,
                  align: "left",
                  bgcolor: "white",
                  opacity: 0.85,
                  xshift: 53,
                  showarrow: false,
                  font: {
                    size: 15,
                    color: colors.gray[600],
                  },
                },
              ],
            };
          }
          setLayout(layout);
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [
    startTime,
    endTime,
    patternData.pattern,
    patternData.hasTrend,
    patternData.trendChartData,
  ]);

  return {
    chartData,
    layout,
    styles,
    error,
    initialYRange,
  };
};
