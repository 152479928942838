import React from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import Icon from "../common/Icon";
import { isEmpty } from "../../utils/is";

const DatasourcesColumnMenuSortSection = ({ columnId, sortModel, setSortModel }) => {
  function handleSort(direction) {
    return () => {
      setSortModel([
        {
          field: columnId,
          sort: direction,
        },
      ]);
    };
  }

  function isOptionSelected(direction) {
    if (isEmpty(sortModel[0])) return false;

    return sortModel[0].field === columnId && sortModel[0].sort === direction;
  }

  const sortingOptions = [
    {
      label: "Sort A → Z",
      isSelected: isOptionSelected("asc"),
      onClick: handleSort("asc"),
    },
    {
      label: "Sort Z → A",
      isSelected: isOptionSelected("desc"),
      onClick: handleSort("desc"),
    },
  ];

  return (
    <>
      {sortingOptions.map(({ label, isSelected, onClick }) => (
        <MenuItem key={label} onClick={onClick}>
          <ListItemIcon>
            {isSelected ? <Icon name="check" size="20px" /> : null}
          </ListItemIcon>
          <ListItemText>{label}</ListItemText>
        </MenuItem>
      ))}
    </>
  );
};

export default DatasourcesColumnMenuSortSection;
