import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  ".MuiFormControlLabel-label": {
    fontSize: theme.typography.pxToRem(14),
  },
}));

function SmallRadioButton(props) {
  return <Radio size="small" {...props} />;
}

const FrequencyFilter = ({ preSelectOption, onFrequencyChange }) => {
  const [selectedFrequency, setSelectedFrequency] = useState();
  function handleChange(event) {
    setSelectedFrequency(event.target.value);
    onFrequencyChange(event.target.value);
  }
  return (
    <FormControl sx={{ padding: 5 }}>
      <span>Choose Frequency</span>
      <RadioGroup
        value={
          selectedFrequency ? selectedFrequency : preSelectOption || "Hourly"
        }
        aria-labelledby="customized-frequency-filter"
        name="customized-frequency-filter"
        onChange={handleChange}
      >
        <Box
          sx={{
            flexWrap: "wrap",
            p: "5px 0",
            m: "5px 0",
            maxWidth: 300,
          }}
        >
          <Stack direction="row" spacing={10}>
            <Stack direction="column">
              <StyledFormControlLabel
                value="Hourly"
                selected={selectedFrequency === "Hourly"}
                control={<SmallRadioButton />}
                label="Hourly"
              />
              <StyledFormControlLabel
                value="Daily"
                selected={selectedFrequency === "Daily"}
                control={<SmallRadioButton />}
                label="Daily"
              />
            </Stack>
            <Stack direction="column">
              <StyledFormControlLabel
                value="Weekly"
                selected={selectedFrequency === "Weekly"}
                control={<SmallRadioButton />}
                label="Weekly"
              />
              <StyledFormControlLabel
                value="Monthly"
                selected={selectedFrequency === "Monthly"}
                control={<SmallRadioButton />}
                label="Monthly"
              />
            </Stack>
          </Stack>
        </Box>
      </RadioGroup>
    </FormControl>
  );
};

export default FrequencyFilter;
