import React, { useState } from "react";

import { Box, Tabs } from "@mui/material";

import { MetricDetailTab } from "../../common/styled-components/metric/MetricDetails.styled";
import TabPanel from "../../common/ui-components/TabPanel";
import MetricAttributes from "./MetricAttributes";
import { attributesBySections } from "../../../shared/metric-attributes/constants";

const MetricDetailsTab = ({ metricDetails, currencySymbol }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (_event, currentTabIndex = "$") => {
    setTabIndex(currentTabIndex);
  };

  return (
    <Box width="100%">
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "secondary.main",
        }}
      >
        <Tabs
          sx={{
            border: "1px solid",
            borderColor: "secondary.main",
            borderBottom: 0,
            borderRadius: "10px 10px 0 0",
            width: "max-content",
          }}
          TabIndicatorProps={{
            style: {
              background: "transparent",
            },
          }}
          value={tabIndex}
          onChange={handleTabChange}
        >
          {attributesBySections(currencySymbol).map((section, index) => (
            <MetricDetailTab
              key={section.title}
              label={section.title}
              id={`tab-${index}`}
              aria-controls={`tabpanel-${index}`}
            />
          ))}
        </Tabs>
      </Box>

      {attributesBySections(currencySymbol).map((section, index) => (
        <TabPanel currentTabIndex={tabIndex} key={section.title} index={index}>
          <MetricAttributes
            attributes={section.items}
            metricDetails={metricDetails}
          />
        </TabPanel>
      ))}
    </Box>
  );
};

export default MetricDetailsTab;
