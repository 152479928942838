import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ThemeProvider } from "styled-components";
import StyledText from "../components/common/label";
import { LoaderInCenter } from "../components/common/styled-components";
import {
  LoginBody,
  LoginContainer,
  LoginContent,
  LoginText,
  LogoWithName,
} from "../components/common/styled-components/login/Login.styled";
import {
  LetsGoButton,
  TenantContainer,
  TenantSelectionContainer,
  customStyles,
} from "../components/common/styled-components/tenant-selection/tenant.selection.styled";
import { theme } from "../components/common/styled-components/theme";
import { MASTER_TENANT, TENANT_DELETE_GLOBAL_FILTER } from "../constants/tenantConstants";
import { useLocalStorageState } from "../hooks";
import { colors } from "../shared/theme-constants";

function prepareOptions(tenants = []) {
  return tenants
    .map((tenant) => {
      const label = tenant === MASTER_TENANT ? "All" : tenant;

      return { value: tenant, label };
    })
    .sort((x, y) =>
      x.value === MASTER_TENANT ? -1 : y.value === MASTER_TENANT ? 1 : 0
    );
}

const CustomersScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isBgImageLoaded, setIsBgImageLoaded] = useState(false);
  const [selectedTenant, updateSelectedTenantInLocalStorage] =
    useLocalStorageState("selectedTenant");
  const [, , clearAppliedGlobalFilter] = useLocalStorageState("currentAppliedGlobalFilters");
  const tenants = useSelector((state) => state.userLogin.tenants);
  const userGivenName = useSelector((state) => state.userLogin.user.given_name);
  const options = useMemo(() => prepareOptions(tenants), [tenants]);

  const from = location.state?.from?.pathname || "/";

  function handleCustomerChange(selectedOption) {
    clearAppliedGlobalFilter();
    dispatch({ type: TENANT_DELETE_GLOBAL_FILTER });
    updateSelectedTenantInLocalStorage(selectedOption.value);
  }

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      // when it finishes loading, update the component state
      setIsBgImageLoaded(true);
    };
    // when img src is set, the browser starts downloading the specified image
    img.src = "/images/login_bg.png";
  }, []);

  return (
    <>
      {!isBgImageLoaded ? (
        <LoaderInCenter />
      ) : (
        <ThemeProvider theme={theme}>
          <LoginContainer>
            <LoginBody>
              <LogoWithName />

              <LoginContent>
                <LoginText>
                  <StyledText
                    fsize="60px"
                    color={colors.white_primary}
                    lheight="65%"
                    fweight="600"
                    mtop="0"
                    text={`Hello ${userGivenName ? userGivenName : "Teammate"} :)`}
                    jcontent="center"
                    aitem="center"
                    mbottom="35px"
                    rfsize="35px"
                    rtalign="center"
                    rlheight="60%"
                    tfsize="30px"
                    tlheight="60%"
                    tmbottom="15px"
                    ipfsize="50px"
                    iptalign="center"
                    iplheight="60%"
                    whiteSpace="nowrap"
                  />
                  <StyledText
                    fsize="35px"
                    color={colors.white_primary}
                    lheight="50px"
                    fweight="500"
                    mtop="0"
                    text="Which customer feed "
                    jcontent="center"
                    aitem="center"
                    rfsize="20px"
                    rtalign="center"
                    rlheight="28px"
                    tfsize="20px"
                    tlheight="29px"
                    ipfsize="30px"
                    iptalign="center"
                    iplheight="39px"
                  />
                  <StyledText
                    fsize="35px"
                    color={colors.white_primary}
                    lheight="46px"
                    fweight="500"
                    text="would you like to check"
                    rfsize="20px"
                    rlheight="28px"
                    rtalign="center"
                    rmbottom="20%"
                    ipfsize="30px"
                    iplheight="39px"
                    iptalign="center"
                    ipmbottom="18%"
                  />
                </LoginText>
                <TenantSelectionContainer>
                  <TenantContainer>
                    <Select
                      options={options}
                      styles={customStyles}
                      onChange={handleCustomerChange}
                      placeholder="Customer"
                      defaultValue={options.find(
                        (option) => option.value === selectedTenant
                      )}
                    />

                    <LetsGoButton
                      onClick={() => navigate(from)}
                      disabled={!selectedTenant}
                    >
                      {"Let's go"}
                    </LetsGoButton>
                  </TenantContainer>
                </TenantSelectionContainer>
              </LoginContent>
            </LoginBody>
            <StyledText
              fsize="16px"
              color={colors.white}
              lheight="31px"
              padding="18.5px 68px"
              talign="right"
              text="©2022 Out Of The Blue™"
              rfsize="11px"
              rlheight="11px"
              rtalign="center"
              ipfsize="17px"
              iplheight="31px"
              iptalign="center"
            />
          </LoginContainer>
        </ThemeProvider>
      )}
    </>
  );
};

export default CustomersScreen;
