import styled from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { colors, typography } from "../../../../shared/theme-constants";
import {
  PoppinsNormalComet37px,
  PoppinsNormalBlack11px,
  PoppinsMediumMariner14px,
  ValignTextMiddle,
} from "../../../collaborate/styledMixins";

export const L3ContainerMain = styled.div`
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 1201;
  position: relative;
  overflow-y: scroll;
`;

export const L3ComponentsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

export const L3Components = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  align-items: flex-start;
  gap: 20px;
  padding: 40px;
`;

export const ContentTitle = styled("div")`
  font-weight: ${typography.font_weight.medium};
  font-size: 2.3rem;
  color: ${colors.title};
`;

export const SelectedFilter = styled.p`
  ${PoppinsNormalBlack11px}
  align-self: stretch;
  letter-spacing: 0;
`;

export const HistogramContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 24px 0px;
  align-self: stretch;
`;

export const Title = styled.h1`
  ${PoppinsNormalComet37px}
  min-width: 260px;
  letter-spacing: 0;
  line-height: 41.6px;
  white-space: nowrap;
`;

export const ChartTitleTypography = MuiStyled(Typography)({
  color: colors.comet,
  fontSize: "1.1rem",
  fontWeight: 400,
});

export const ComponentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InsightActions = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const FollowThisStory = styled.div`
  ${ValignTextMiddle}
  ${PoppinsMediumMariner14px}
  width: 154px;
  height: 33px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
`;

export const Actions = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  position: relative;
`;
