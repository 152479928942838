import { bp, theme } from "./theme";

export const insightModalStyle = {
  width: "90%",
  margin: "auto",

  [bp.min(theme.iPadPro)]: {
    width: "1000px",
  },
};
