import React from "react";
import styled, { css } from "styled-components";
// import colors from "../../shared/theme-constants";

const StyledLabel = styled.div`
  ${({ fsize }) =>
    fsize &&
    css`
      font-size: ${fsize};
    `}
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  ${({ lheight }) =>
    lheight &&
    css`
      line-height: ${lheight};
    `}
  ${({ mtop }) =>
    mtop &&
    css`
      margin-top: ${mtop};
    `}
  ${({ mbottom }) =>
    mbottom &&
    css`
      margin-bottom: ${mbottom};
    `}
  ${({ mleft }) =>
    mleft &&
    css`
      margin-left: ${mleft};
    `}
  ${({ mright }) =>
    mright &&
    css`
      margin-right: ${mright};
    `}
  ${({ fweight }) =>
    fweight &&
    css`
      font-weight: ${fweight};
    `}
  ${({ aitem }) =>
    aitem &&
    css`
      align-items: ${aitem};
    `}
  ${({ jcontent }) =>
    jcontent &&
    css`
      justify-content: ${jcontent};
    `}
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
  ${({ talign }) =>
    talign &&
    css`
      text-align: ${talign};
    `}
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}
    ${({ whiteSpace }) =>
    whiteSpace &&
    css`
      white-space: ${whiteSpace};
    `}
    
@media (max-width: ${({ theme }) => theme.ipadPro}) {
    ${({ ipfsize }) =>
      ipfsize &&
      css`
        font-size: ${ipfsize};
      `}
    ${({ iplheight }) =>
      iplheight &&
      css`
        line-height: ${iplheight};
      `}
    ${({ iptalign }) =>
      iptalign &&
      css`
        text-align: ${iptalign};
      `}
    ${({ ipmbottom }) =>
      ipmbottom &&
      css`
        margin-bottom: ${ipmbottom};
      `}
    ${({ ipmtop }) =>
      ipmtop &&
      css`
        margin-top: ${ipmtop};
      `}
    ${({ ipheight }) =>
      ipheight &&
      css`
        height: ${ipheight};
      `}
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    ${({ rfsize }) =>
      rfsize &&
      css`
        font-size: ${rfsize};
      `}
    ${({ rlheight }) =>
      rlheight &&
      css`
        line-height: ${rlheight};
      `}
    ${({ rtalign }) =>
      rtalign &&
      css`
        text-align: ${rtalign};
      `}
    ${({ rmbottom }) =>
      rmbottom &&
      css`
        margin-bottom: ${rmbottom};
      `}
    ${({ rmtop }) =>
      rmtop &&
      css`
        margin-top: ${rmtop};
      `}
    ${({ rheight }) =>
      rheight &&
      css`
        height: ${rheight};
      `}
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    ${({ tfsize }) =>
      tfsize &&
      css`
        font-size: ${tfsize};
      `}
    ${({ tlheight }) =>
      tlheight &&
      css`
        line-height: ${tlheight};
      `}
    ${({ rtalign }) =>
      rtalign &&
      css`
        text-align: ${rtalign};
      `}
    ${({ tmbottom }) =>
      tmbottom &&
      css`
        margin-bottom: ${tmbottom};
      `}
    ${({ rmtop }) =>
      rmtop &&
      css`
        margin-top: ${rmtop};
      `}
    ${({ rheight }) =>
      rheight &&
      css`
        height: ${rheight};
      `}
  }
`;

const StyledText = (props) => {
  return (
    <StyledLabel
      fsize={props.fsize}
      color={props.color}
      lheight={props.lheight}
      mtop={props.mtop}
      fweight={props.fweight}
      mleft={props.mleft}
      jcontent={props.jcontent}
      aitem={props.aitem}
      mbottom={props.mbottom}
      padding={props.padding}
      talign={props.talign}
      height={props.height}
      rfsize={props.rfsize}
      rlheight={props.rlheight}
      rtalign={props.rtalign}
      rmbottom={props.rmbottom}
      rmtop={props.rmtop}
      rheight={props.rheight}
      tfsize={props.tfsize}
      tlheight={props.tlheight}
      tmbottom={props.tmbottom}
      ipfsize={props.ipfsize}
      iplheight={props.iplheight}
      iptalign={props.iptalign}
      ipmbottom={props.ipmbottom}
      ipmtop={props.ipmtop}
      ipheight={props.ipheight}
      whiteSpace = {props.whiteSpace}
    >
      {props.text}
    </StyledLabel>
  );
};

export default StyledText;
