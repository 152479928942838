import React from "react";
import * as Plotly from "plotly.js";
// customizable method: use your own `Plotly` object
import createPlotlyComponent from "react-plotly.js/factory";

const Plot = createPlotlyComponent(Plotly);

export const Histogram = ({ data, layout, config, style, onBarClick }) => {
  function handleClick(event) {
    const { x: xTickTimeValue, text } = event.points[0];
    // xTickTimeValue have 2 variants, "date" and "date time" e.g. "2022-12-28" and "2022-12-28 12:00"
    // though on chromium "2022-12-28Z" is valid date string and returns valid Date object
    // on Firefox, it returns `Invalid date`, so we need to convert it to ISO string
    const [date, time = "00:00"] = xTickTimeValue.split(" ");
    // as x tick time values on histogram are in UTC, append Z to the string
    const dateStringUTC = [date, "T", time, "Z"].join("");
    onBarClick(dateStringUTC);
  }

  return (
    <Plot
      data={data}
      layout={layout}
      config={config}
      style={{ height: "100%", ...style }}
      onClick={handleClick}
    />
  );
};
