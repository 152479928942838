import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { typography } from "../../../shared/theme-constants";
import { ChipContainer, StyledCard, TimeChip } from "../../common/styled-components";
import EventGroupCardHeader from "./EventGroupCardHeader";
import { formatDateForTimeChip } from "../../../utils/dateUtils";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

const DEFAULT_TIMEZONE = "Etc/UTC";

function EventGroup({ event }) {

  // Get the tenant configs
  const tenantConfigs = useSelector((state) => state.tenantConfigs.configs);

  // Get the timezone from the tenant configs
  let timezone = tenantConfigs?.timezone?.name ? tenantConfigs.timezone.name : DEFAULT_TIMEZONE;

  const calculateDuration = () => {
    let current_time_stamp = null;
    let event_group_start_time = moment(event?.event_group_ts_start).tz(DEFAULT_TIMEZONE);

    let event_group_duration = 0;

    if (event?.status === "Active") {
      // Get browser's Time
      current_time_stamp = moment().tz(DEFAULT_TIMEZONE);
      event_group_duration = moment.duration(current_time_stamp.diff(event_group_start_time));
    } else if (event?.status === "Closed") {
      current_time_stamp = moment(event?.event_group_ts_end).tz(DEFAULT_TIMEZONE);
      event_group_duration = moment.duration(current_time_stamp.diff(event_group_start_time));
    }

    const days = event_group_duration.days();
    const hours = event_group_duration.hours();
    const minutes = event_group_duration.minutes();
    let formattedDuration = "";

    if (days > 0) {
      formattedDuration += `${days}d`;
    }

    if (hours > 0) {
      formattedDuration += (hours < 10 ? " 0" : " ") + `${hours}h`;
    }

    if (minutes > 0) {
      formattedDuration += (minutes < 10 ? " 0" : " ") + `${minutes}min`;
    }
    // We are keeping 30 min as defualt for now in case when there is no duration. It will change in future.
    if (days <= 0 && hours <= 0 && minutes <= 0) {
      formattedDuration += "30min";
    }
    return formattedDuration;
  };
  return (
    <>
      <ChipContainer data-snippyly-comment-disabled direction="row">
        <TimeChip
          sx={{
            minWidth: "250px",
          }}
        >
          <Stack direction="row" gap={7}>
            <Box>
              <Typography
                component="span"
                sx={(theme) => ({
                  fontWeight: typography.font_weight.bold,
                  textTransform: "capitalize",
                  color: "var(--black)",
                  fontSize: theme.typography.pxToRem(13),
                })}
              >
                {event?.formatted_event_group_sequence_id} |{" "}
              </Typography>
              <span style={{ color: "var(--black)" }}>
                {formatDateForTimeChip(event?.event_group_ts_start, null, timezone, "ddd, DD MMM, hh:mm A z")}
              </span>
            </Box>
            <Box
              sx={(theme) => ({
                color: event?.status === "Active" ? "var(--red-2)" : "var(--sonic-silver)",
                fontSize: theme.typography.pxToRem(13),
                fontWeight: typography.font_weight.semiBold,
              })}
            >
              <img
                width="11px"
                height="15px"
                src={`/icons/${event?.status === "Active" ? "duration_red" : "duration"}.svg`}
                style={{ marginRight: "4px" }}
              />
              {calculateDuration()}
            </Box>
          </Stack>
        </TimeChip>
      </ChipContainer>

      <StyledCard pageType="events" eventstatus={event?.status}>
        <EventGroupCardHeader
          categoryLogo={event?.category_logo_url}
          providerLogo={event?.provider_logo_url}
          eventStatus={event?.status}
          eventSeqId={event?.formatted_event_group_sequence_id}
          eventStartTime={event?.event_group_ts_start}
          eventEndTime={event?.event_group_ts_end}
          numberOfEvents={event?.number_of_events}
          type={event?.type}
          eventCategory={event?.category_display_name}
          providerDisplayName={event?.provider_display_name}
          severity={event?.severity}
          {...event}
        />
      </StyledCard>
    </>
  );
}

export default EventGroup;
