// local imports
import { theme } from "./theme";

import styled from "styled-components";

export const TimeIconContainer = styled.div`
  position: ${({ position }) => position || "absolute"};
  right: ${({ right }) => right || "101%"};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    right: ${({ right }) => right || "102%"};
  }
`;

export const TimePickerModalStyles = {
  background: "#FFFFFF",
  width: "763px",
  height: "270px",
  border: "2px solid white",
  position: "absolute",
  borderRadius: "8px",

  [`@media(min-width:${theme.tablet}) and (max-width:${theme.mobile})`]: {
    width: "300px",
    height: "560px",
  },
  [`@media(max-width:${theme.tablet})`]: {
    width: "300px",
    height: "560px",
  },
};
