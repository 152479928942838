import {
  METRIC_LIST_REQUEST,
  METRIC_LIST_SUCCESS,
  METRIC_LIST_FAIL,
  METRIC_DETAILS_REQUEST,
  METRIC_DETAILS_SUCCESS,
  METRIC_DETAILS_FAIL,
  METRIC_DELETE_REQUEST,
  METRIC_DELETE_SUCCESS,
  METRIC_DELETE_FAIL,
  METRIC_CREATE_REQUEST,
  METRIC_CREATE_SUCCESS,
  METRIC_CREATE_FAIL,
  METRIC_CREATE_RESET,
  METRIC_UPDATE_REQUEST,
  METRIC_UPDATE_SUCCESS,
  METRIC_UPDATE_FAIL,
  METRIC_UPDATE_RESET,
  RELATED_METRIC_REQUEST,
  RELATED_METRIC_FAIL,
  RELATED_METRIC_SUCCESS,
} from "../constants/metricConstants";

export const metricListReducer = (state = { metrics: {} }, action) => {
  switch (action.type) {
    case METRIC_LIST_REQUEST:
      return { ...state, loading: true };
    case METRIC_LIST_SUCCESS:
      return {
        loading: false,
        metrics: action.payload,
      };
    case METRIC_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const metricDetailsReducer = (
  state = { metric: {}, loading: false, error: {} },
  action
) => {
  switch (action.type) {
    case METRIC_DETAILS_REQUEST:
      return { ...state, loading: true };
    case METRIC_DETAILS_SUCCESS:
      return { loading: false, metric: action.payload };
    case METRIC_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const relatedMetricsReducer = (
  state = { metric: {}, loading: false, error: {} },
  action
) => {
  switch (action.type) {
    case RELATED_METRIC_REQUEST:
      return { ...state, loading: true };
    case RELATED_METRIC_SUCCESS:
      return { loading: false, metric: action.payload };
    case RELATED_METRIC_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const metricDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case METRIC_DELETE_REQUEST:
      return { loading: true };
    case METRIC_DELETE_SUCCESS:
      return { loading: false, success: true };
    case METRIC_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const metricCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case METRIC_CREATE_REQUEST:
      return { loading: true };
    case METRIC_CREATE_SUCCESS:
      return { loading: false, success: true, metric: action.payload };
    case METRIC_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case METRIC_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const metricUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case METRIC_UPDATE_REQUEST:
      return { loading: true };
    case METRIC_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case METRIC_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case METRIC_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
