import { styled } from "@mui/system";
import { useEffect } from "react";

const SCROLL_LOCKING_ATTRIBUTE = "data-lock-scrolling";

const ModalContainer = styled("div")`
  position: absolute;
  left: 0;
  right: 0;
  top: var(--navbar-height);
  height: calc(100% - var(--navbar-height));
  overflow-y: auto;
`;

export const ModalRouteContainer = ({ children }) => {
  useEffect(() => {
    document.body.setAttribute(SCROLL_LOCKING_ATTRIBUTE, "");

    return () => {
      document.body.removeAttribute(SCROLL_LOCKING_ATTRIBUTE);
    };
  }, []);

  return <ModalContainer>{children}</ModalContainer>;
};
