/**
 * Checks if the the email entered is a valid email format or not
 * @param {*} email
 * @returns true if the email format is correct is false
 */
export const emailValidator = (email) => {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(email)) {
    // this is a valid email address
    return true;
  } else {
    // invalid email, maybe show an error to the user.
    return false;
  }
};

export const markdownInlineLinkRegex = new RegExp(/\[([^\]]*?)\][[(](.*?)[\])]/, "g");

export const markdownBoldRegex = new RegExp(/\*\*([^*]+)\*\*/, "g");
