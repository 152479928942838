import MuiCard from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import { colors } from "../../../shared/theme-constants";

const transientProps = ["height", "isGroup", "openAsModal", "pageType"];

export const StyledCard = styled(MuiCard, {
  shouldForwardProp: (prop) => !transientProps.includes(prop),
})`
  background-color: ${colors.white};
  overflow: hidden;
  border-radius: 8px;
  border-left: ${({ pageType, eventstatus }) =>
  pageType
    && `7px solid ${eventstatus === 'Active' ? "var(--red-3)" : eventstatus === 'Closed' ? "var(--sonic-silver)" : null }`}; 
  padding: 20px;
  padding-bottom: 12px;
  height: ${({ height }) => (height ? height + "px" : "")};
  width: 100%;
  box-shadow: ${({ isGroup }) =>
    isGroup
      ? `6px 7px 0 rgb(240 240 240 / 96%), 
      7px 8px 0 rgb(0 0 0  / 4%),
      12px 14px 0 rgb(230 230 230 / 96%), 
      18px 22px 12px rgb(0 0 0  / 10%),
      19px 23px 28px rgb(0 0 0  / 10%)`
      : `0px 2px 20px rgb(0, 0, 0, 0.15)`};

  ${({ openAsModal }) => openAsModal && `
    overflow-y: auto;
    max-height: 90vh;
  `};

  @media (max-width: ${({ theme }) => theme.iPadPro}) {
    margin-top: ${({ openAsModal }) => (openAsModal ? "-50px" : "")};
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin: 20px 0 60px 0;
    margin-top: ${({ openAsModal }) => (openAsModal ? "100px" : "")};
    flex-direction: column;
    max-height: ${({ $height }) => $height * 2 + "px"};
    padding: 15px;
    height: 100%;
  }

  @media (max-width: ${({ theme }) => theme.tablet}) {
    margin-top: ${({ openAsModal }) => (openAsModal ? "204px" : "")};
    max-height: ${({ openAsModal }) => (openAsModal ? "925px" : "")};
  }
`;
