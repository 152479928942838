import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { subDays, subHours, subQuarters, subWeeks } from "date-fns";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import moment from "moment-timezone";

const PRESET_TIME_FILTERS = {
  "preset/last_hour": {
    label: "Last hour",
    getStartTime: (endTime) => subHours(endTime, 1),
  },
  "preset/last_day": {
    label: "Last day",
    getStartTime: (endTime) => subDays(endTime, 1),
  },
  "preset/last_week": {
    label: "Last week",
    getStartTime: (endTime) => subWeeks(endTime, 1),
  },
  "preset/last_month": {
    label: "Last month",
    getStartTime: (endTime) => subDays(endTime, 30),
  },
  "preset/last_quarter": {
    label: "Last quarter",
    getStartTime: (endTime) => subQuarters(endTime, 1),
  },
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  width: "100%",
  gap: theme.spacing(2),
  flexWrap: "wrap",
  flexDirection: "column",

  [theme.breakpoints.up(769)]: {
    flexDirection: "row",
  },

  // class present on <button> element within group
  "& .MuiToggleButtonGroup-grouped": {
    flex: 1,
    whiteSpace: "nowrap",
    lineHeight: 1.6,
    borderColor: "rgba(0, 0, 0, 0.23)",
    padding: theme.spacing(2),

    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
      borderLeftColor: "rgba(0, 0, 0, 0.23)",
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const PresetTimePickerGroup = ({ onSelect, onClose }) => {
  const timeFilterType = useSelector((state) => state.feedScreenTimeFilter.type);

  function handlePresetSelect(_event, selectedPresetTime) {
    const endTime = new Date();

    onSelect({
      start_time: moment(PRESET_TIME_FILTERS[selectedPresetTime].getStartTime(endTime)).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      end_time: moment(endTime).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      type: selectedPresetTime,
    });

    onClose();
  }

  return (
    <StyledToggleButtonGroup
      color="primary"
      aria-label="Preset time filter"
      exclusive
      onChange={handlePresetSelect}
      value={timeFilterType}
    >
      {Object.keys(PRESET_TIME_FILTERS).map((id) => (
        <ToggleButton
          key={id}
          value={id}
          aria-label={PRESET_TIME_FILTERS[id].label}
        >
          {PRESET_TIME_FILTERS[id].label}
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

export default PresetTimePickerGroup;
