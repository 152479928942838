import React, { useEffect } from "react";

// MUI Imports
import { Box, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import Toolbar from "@mui/material/Toolbar";

// Local imports
import { useSelector } from "react-redux";
import { SAAS_FILTERING_FEATURE_TOGGLE } from "../../../constants/featureFlagConstants";
import { MASTER_TENANT } from "../../../constants/tenantConstants";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import useBoolean from "../../../hooks/useBoolean";
import { useSelectedTenant } from "../../../hooks/useSelectedTenant";
import { colors } from "../../../shared/theme-constants";
import { isEnabled } from "../../../utils/featureFlags";
import CreateFilterDrawer from "../globalFilters/CreateFilterDrawer";
import Icon from "../Icon";
import Logo from "../Logo";
import { NavbarContainer } from "../styled-components";
import {
  activeLinkStyle,
  NavbarItemTypography,
  StyledNavLink,
} from "../styled-components/NavLink.styled";
import NavBarViewDropDown from "./NavBarViewDropDown";
import UserProfile from "./UserProfile";

const windowName = "_blank";
const DOCUMENTATION_URL = process.env.REACT_APP_BASE_URL + "docs";
const SUPPORT_URL = "http://support.devrev.ai/outoftheblue";

const CommentSelector = styled(ToggleButton)({
  height: "var(--navbar-height)",
  border: 0,
  "&.Mui-selected": {
    backgroundColor: colors.azure,
  },
});

const StyledAppBar = styled(AppBar)({
  height: "var(--navbar-height)",
  zIndex: "1202",
  overflowY: "hidden",
});

const NavbarItems = () => {
  const user = useAuthenticatedUser();
  const tenantConfigs = useSelector((state) => state.tenantConfigs.configs);

  const navbarItems = [
    { name: "Home", icon: "", linkTo: "/", is_enabled: true },
    {
      name: "Insights",
      icon: "menu-feed",
      linkTo: "/feed",
      is_enabled: tenantConfigs?.is_forecast_enabled,
    },
    { name: "Events", icon: "calendar", linkTo: "/events", is_enabled: true },
    { name: "Metrics", icon: "menu-metrics", linkTo: "/metrics", is_enabled: true },
    { name: "Explorer", icon: "data-explorer", linkTo: "/explorer", is_enabled: true },
  ];

  return (
    <Stack direction="row" width="70%">
      <Grid container rowSpacing={10} sx={{ flexWrap: "nowrap" }}>
        {navbarItems.map((menuItem, index) => (
          <React.Fragment key={index}>
            {menuItem.name == "Onboarding" &&
              user?.tenant_id === MASTER_TENANT &&
              menuItem.is_enabled && (
                <Grid key={index} item>
                  <StyledNavLink
                    to={menuItem.linkTo}
                    style={({ isActive }) => (isActive ? activeLinkStyle : null)}
                  >
                    <Stack direction="row" p="20px 0 20px 20px">
                      <Box p="0 10px">
                        <Icon name={menuItem.icon} size="24px" />
                      </Box>
                      <NavbarItemTypography>{menuItem.name}</NavbarItemTypography>
                    </Stack>
                  </StyledNavLink>
                </Grid>
              )}
            {menuItem.name != "Onboarding" && menuItem.is_enabled && (
              <Grid key={index} item>
                <StyledNavLink
                  to={menuItem.linkTo}
                  style={({ isActive }) => (isActive ? activeLinkStyle : null)}
                >
                  <Stack direction="row" p="20px 0 20px 20px">
                    <Box p="0 10px">
                      <Icon name={menuItem.icon} size="24px" />
                    </Box>
                    <NavbarItemTypography>{menuItem.name}</NavbarItemTypography>
                  </Stack>
                </StyledNavLink>
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Stack>
  );
};

const Navbar = ({ drawerOpen }) => {
  const user = useAuthenticatedUser();
  const [isDrawerOpen, { setTrue: openDrawer, setFalse: closeDrawer }] = useBoolean(false);
  const selectedTenant = useSelectedTenant();
  let isGlobalFilterEnabled = isEnabled(SAAS_FILTERING_FEATURE_TOGGLE);

  const initializeDevRev = (sessionToken) => {
    // Below piece of code integrates devRev plug widget into our system.
    window.plugSDK.init({
        app_id: process.env.REACT_APP_DEV_REV_APP_ID,
        session_token: sessionToken // Include the session token in the initialization
    });
};

const generateSessionToken = async (user) => {
  try {
      const response = await fetch('https://api.devrev.ai/auth-tokens.create', {
          method: 'POST',
          headers: {
              'Accept': 'application/json, text/plain, */*',
              'Authorization': process.env.REACT_APP_DEV_REV_PUBLIC_AUTH_TOKEN,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            rev_info: {
              "user_ref": user?.email,
              "user_traits": {
                  "email": user?.email,
                  "display_name": `${user?.given_name} ${user?.family_name}`
              }
          }
          })
      });
      if (!response.ok) {
          throw new Error('Failed to generate session token');
      }

      const data = await response.json();
      return data.access_token; // Return the session token for use later
  } catch (error) {
    // Nothing to be done here.
  }
};

  useEffect(() => {
    if (user?.login_id && process.env.REACT_APP_DEV_REV_APP_ID && process.env.REACT_APP_DEV_REV_PUBLIC_AUTH_TOKEN) {
      const initialize = async () => {
            try {
                const sessionToken = await generateSessionToken(user);
                if (sessionToken) {
                    initializeDevRev(sessionToken);
                }
            } catch (error) {}
    };

    initialize();
    }
  }, [user]);

  /**
   * This function will be triggered when user clicks on documentation icon
   * user will then be redirected to user docs
   */
  const gotoDocumentation = () => {
    window.open(SUPPORT_URL, windowName);
  };

  return (
    <>
      <StyledAppBar position="fixed">
        <NavbarContainer
          data-snippyly-comment-disabled
          maxWidth="false"
          sx={{ paddingLeft: "0px" }}
        >
          <Toolbar
            disableGutters
            sx={{
              height: "var(--navbar-height)",
              paddingLeft: "5px",
              justifyContent: "space-between",
            }}
          >
            <Logo open={drawerOpen} />
            {user?.tenant_id == MASTER_TENANT ? (
              <NavbarItems />
            ) : !user?.show_onboarding ? (
              <NavbarItems />
            ) : null}
            <Stack direction="row" width="25%" alignItems="center" justifyContent="flex-end">
              {isGlobalFilterEnabled && <NavBarViewDropDown openCreateFilterDrawer={openDrawer} />}
              <Tooltip title="Documentation" placement="bottom-start">
                <IconButton
                  onClick={gotoDocumentation}
                  sx={{
                    ml: 4,
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                >
                  <Icon name="Documentation" showcursor="true" />
                </IconButton>
              </Tooltip>
              <UserProfile />
            </Stack>
          </Toolbar>
        </NavbarContainer>
      </StyledAppBar>
      {isDrawerOpen && (
        <CreateFilterDrawer isOpen={isDrawerOpen} closeCreateFilterDrawer={closeDrawer} />
      )}
    </>
  );
};

export default Navbar;
