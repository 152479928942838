import axios from "axios";
import { COLUMN_META_DATA_SUCESS, METRICS_CREATE_SUCCESS, METRICS_CREATE_INITIATE, METRICS_CREATE_FAILED, UPLOAD_STATUS_PCT } from "../reducers/onBoardingReducer";

const url = process.env.REACT_APP_BASE_URL;

export const createConnector = (params, isCsv = false, file) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: function(progressEvent) {
                let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                dispatch({
                    type: UPLOAD_STATUS_PCT, payload: percentCompleted
                });
            }
        };
        let formData = new FormData();
        formData.append('connector_uuid', params.connector_uuid);
        formData.append('tenant_id', params.tenant_id);
        let data = null;
        if (isCsv) {
            formData.append('csvFile', file);
            data = await axios.post(`${url}connections`, formData, config);
        }
        else {
            data = await axios.post(`${url}connections`, formData, config);
        }
        return { ...data };

    } catch (error) {
        throw error.response;
    };
};

export const getMetricColumnMetaData = (extractionJobId) => async (dispatch) => {
    try {

        let { data } = await axios.get(`${url}extractions/${extractionJobId}/results`);
        dispatch({
            type: COLUMN_META_DATA_SUCESS, payload: data
        });
        return data;
    } catch (error) {
        throw error.response;
    }
};

export const createMetricsAndDimensions = (metricsDimensionsPayload) => async (dispatch) => {
    try {

        dispatch({ type: METRICS_CREATE_INITIATE });
        let { data } = await axios.post(`${url}metrics_hub/onboard`, { ...metricsDimensionsPayload });
        dispatch({ type: METRICS_CREATE_SUCCESS, payload: data });
        return data;
    } catch (error) {
        dispatch({ type: METRICS_CREATE_FAILED, payload: error.response });
        throw error.response;
    }
};

export const updatePipelinesWithPreferenceValues = (preferenceValue) => async (dispatch) => {
    try {

        let { data } = await axios.patch(`${url}preferences/pipelines`, { ...preferenceValue });
        return data;
    } catch (error) {
        throw error.response;
    }
};