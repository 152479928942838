import axios from "axios";
import {
  INSIGHT_OPEN_REQUEST,
  INSIGHT_OPEN_SUCCESS,
  INSIGHT_OPEN_FAIL,
  INSIGHT_IMPORTANT_REQUEST,
  INSIGHT_IMPORTANT_SUCCESS,
  INSIGHT_IMPORTANT_FAIL,
  INSIGHT_SNOOZE_REQUEST,
  INSIGHT_SNOOZE_SUCCESS,
  INSIGHT_SNOOZE_FAIL,
  INSIGHT_FLAG_FAIL,
  INSIGHT_ARCHIVE_REQUEST,
  INSIGHT_ARCHIVE_SUCCESS,
  INSIGHT_ARCHIVE_FAIL,
  INSIGHT_RESOLVE_REQUEST,
  INSIGHT_RESOLVE_SUCCESS,
  INSIGHT_RESOLVE_FAIL,
  INSIGHT_LIST_REQUEST,
  INSIGHT_LIST_SUCCESS,
  INSIGHT_LIST_FAIL,
  INSIGHT_DETAILS_REQUEST,
  INSIGHT_DETAILS_SUCCESS,
  INSIGHT_DETAILS_FAIL,
  INSIGHT_COMMENTS_REQUEST,
  INSIGHT_COMMENTS_SUCCESS,
  INSIGHT_COMMENTS_FAIL,
} from "../constants/insightConstants";
import { OPEN, FLAGGED, RESOLVED } from "../constants/userConstants";

import { TEAM_INSIGHT_ACTION_REQUESTED } from "../constants/teamConstants";

const url = process.env.REACT_APP_BASE_URL.concat("insights");
let collated_insights = [];
let prevMenuItem = null;
let prev_start_time = null;
let pre_end_time = null;
let nonUserStates = [OPEN, FLAGGED, RESOLVED];

export const resetInsights = () => async (dispatch) => {
  collated_insights = [];
  dispatch({ type: INSIGHT_LIST_REQUEST, payload: collated_insights });
};

export const listInsights =
  (tenant_id, menu_item, page_num, start_time, end_time) =>
    async (dispatch) => {
      try {
        if (prevMenuItem !== menu_item) {
          prevMenuItem = menu_item;
          collated_insights = [];
        }
        if (pre_end_time !== end_time && prev_start_time !== start_time) {
          pre_end_time = end_time;
          prev_start_time = start_time;
          collated_insights = [];
        }
        dispatch({ type: INSIGHT_LIST_REQUEST, payload: collated_insights });

        const { data } = await axios.get(
          `${url}?tenant_id=${tenant_id}&page_num=${page_num}&state=${menu_item}&start_time=${start_time}&end_time=${end_time}`
        );
        collated_insights = [...collated_insights, ...data.insights];
        dispatch({
          type: INSIGHT_LIST_SUCCESS,
          payload: collated_insights,
        });
        return data;
      } catch (error) {
        dispatch({
          type: INSIGHT_LIST_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

export const listInsightDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: INSIGHT_DETAILS_REQUEST });
    const { data } = await axios.get(`/v1/insights/${id}`);
    dispatch({
      type: INSIGHT_DETAILS_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: INSIGHT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw error;
  }
};

// Get Insights list for the level 3 view
export const getInsightsForL3View =
  (queryParams, page) => async (dispatch) => {
    try {
      const params = new URLSearchParams(queryParams);

      const { data } = await axios.get(
        `${url}?page_num=${page}&count=4`,
        {
          params,
        }
      );

      return data;
    } catch (error) {
      throw error;
    }
  };

export const listInsightComments = (id) => async (dispatch) => {
  try {
    dispatch({ type: INSIGHT_COMMENTS_REQUEST });
    const { data } = await axios.get(`/v1/insights/${id}/comments`);
    dispatch({
      type: INSIGHT_COMMENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INSIGHT_COMMENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateInsightOpen = (id, state) => async (dispatch) => {
  try {
    dispatch({ type: INSIGHT_OPEN_REQUEST });
    const { data } = await axios.post(`${url}/${id}/user_state`, {
      ...state,
    });
    dispatch({
      type: INSIGHT_OPEN_SUCCESS,
      payload: data,
    });

    collated_insights = collated_insights.filter(
      (id) => id.insight_id !== data.insight_id
    );
    dispatch({
      type: INSIGHT_LIST_SUCCESS,
      payload: collated_insights,
    });
    return data.success;
  } catch (error) {
    dispatch({
      type: INSIGHT_OPEN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    return error.response.data.success;
  }
};

export const updateInsightImportant = (id, state) => async (dispatch) => {
  try {
    dispatch({ type: INSIGHT_IMPORTANT_REQUEST });
    const { data } = await axios.post(`${url}/${id}/user_state`, {
      ...state,
    });
    setTimeout(() => {
      dispatch({
        type: INSIGHT_IMPORTANT_SUCCESS,
        payload: data,
      });
    }, 500);

    collated_insights = collated_insights.filter(
      (id) => id.insight_id !== data.insight_id
    );
    dispatch({
      type: INSIGHT_LIST_SUCCESS,
      payload: collated_insights,
    });
    return data.success;
  } catch (error) {
    dispatch({
      type: INSIGHT_IMPORTANT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    return error.response.data.success;
  }
};

export const updateInsightArchive = (id, state) => async (dispatch) => {
  try {
    dispatch({ type: INSIGHT_ARCHIVE_REQUEST });
    const { data } = await axios.post(`${url}/${id}/user_state`, {
      ...state,
    });
    setTimeout(() => {
      dispatch({
        type: INSIGHT_ARCHIVE_SUCCESS,
        payload: data,
      });
    }, 500);
    collated_insights = collated_insights.filter(
      (id) => id.insight_id !== data.insight_id
    );
    dispatch({
      type: INSIGHT_LIST_SUCCESS,
      payload: collated_insights,
    });
    return data.success;
  } catch (error) {
    dispatch({
      type: INSIGHT_ARCHIVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    return error.response.data.success;
  }
};

export const updateInsightSnooze = (id, state, time) => async (dispatch) => {
  try {
    dispatch({ type: INSIGHT_SNOOZE_REQUEST });
    const { data } = await axios.post(`${url}/${id}/user_state`, {
      ...state,
      state_data: {
        snooze_time: time,
      },
    });
    setTimeout(() => {
      dispatch({
        type: INSIGHT_SNOOZE_SUCCESS,
        payload: data,
      });
    }, 500);
    collated_insights = collated_insights.filter(
      (id) => id.insight_id !== data.insight_id
    );
    dispatch({
      type: INSIGHT_LIST_SUCCESS,
      payload: collated_insights,
    });
    return data.success;
  } catch (error) {
    dispatch({
      type: INSIGHT_SNOOZE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    return error.response.data.success;
  }
};

export const updateInsightStateForTeam =
  (state, insightList) => async (dispatch) => {
    try {
      dispatch({
        type: TEAM_INSIGHT_ACTION_REQUESTED,
        selectedInsights: insightList,
      });
      const { data } = await axios.post(`${url}/team_states`, {
        ...state,
        insights: [...insightList],
      });

      collated_insights = collated_insights.filter((id) => {
        return !(
          nonUserStates.includes(state?.old_state) &&
          insightList.includes(id.insight_id)
        );
      });
      dispatch({
        type: INSIGHT_LIST_SUCCESS,
        payload: collated_insights,
      });
      return data.success;
    } catch (error) {
      dispatch({
        type: INSIGHT_FLAG_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      return error.response.data.success;
    }
  };

export const fetchPatternSeries = (insightPatternSeriesParams) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}pattern_series`, { params: insightPatternSeriesParams });
    return data;
  } catch (error) {
    throw error.reponse;
  }
};
