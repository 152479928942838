import { Box, Divider, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { formatISO } from "date-fns";
import { useSelector } from "react-redux";
import { useAxios } from "../../hooks/useAxios";
import { deserializeToJSX, parseMarkdown } from "../../services/markdown";
import { colors, muiTheme, typography } from "../../shared/theme-constants";
import BusinessHealthIndicator from "../common/BusinessHealthIndicator";
import Icon from "../common/Icon";
import ListLoader from "../common/ListLoader";
import { Bold } from "../common/styled-components/insight-summay/Title.styled";

const StyledHealthDesc = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(30),
  color: theme.palette.secondary.main,
  lineHeight: theme.typography.pxToRem(48.6),
  paddingTop: theme.typography.pxToRem(25),
  paddingBottom: theme.typography.pxToRem(25),
}));

const ErrorCardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  marginTop: "18px",
}));
const CardMessage = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(22),
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: theme.typography.pxToRem(48.6),
  color: colors.title,
}));

const StyledTitle = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(32),
  fontWeight: typography.font_weight.black,
  lineHeight: "normal",
  fontsize: "normal",
  color: colors.blue["925"],
}));
const BusinessHealthError = ({ errorMessage }) => (
  <ErrorCardContainer>
    <Icon justifyContent="center" name="BusinessHealth" />
    <CardMessage
      sx={{
        marginTop: "16px",
      }}
    >
      {errorMessage ? errorMessage : "Unable to get data for Business Health"}
    </CardMessage>
  </ErrorCardContainer>
);

function createSerializer() {
  return {
    bold: ({ value }) => <Bold>{value}</Bold>,
  };
}

const BusinessHealthTable = ({ tenant_id, startDate, endDate, pipelineSchedule }) => {
  const tenantGlobalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);

  const { data: businessHealthData, isLoading: isBusinessHealthLoading } = useAxios(
    "digest/metrics/north_star/health",
    {
      params: {
        tenant_id: tenant_id,
        start_time: formatISO(startDate, { representation: "date" }),
        end_time: formatISO(endDate, { representation: "date" }),
        granularity: pipelineSchedule,
        dim_name: tenantGlobalFilter?.dimension_name,
        dim_val: tenantGlobalFilter?.dimension_value,
      },
    }
  );

  if (isBusinessHealthLoading) {
    return (
      <Stack
        sx={{ minHeight: 350, justifyContent: "center", alignItems: "center" }}
        direction="column"
      >
        <ListLoader count={1} height={200} paddingY={10} />
      </Stack>
    );
  }

  if (businessHealthData?.success == false) {
    return (
      <Stack direction="column" gap={15}>
        <Stack direction="column">
          <StyledTitle>Business Health</StyledTitle>
          <Divider sx={{ marginTop: "20px", marginBottom: "10px" }} />
        </Stack>
        <BusinessHealthError errorMessage={businessHealthData?.message} />
      </Stack>
    );
  }

  return (
    <Stack direction="column">
      <Stack direction="row" sx={{ alignItems: "center" }}>
        <StyledTitle>Business Health</StyledTitle>

        <Stack direction="row" sx={{ ml: "auto" }}>
          <BusinessHealthIndicator health={businessHealthData?.health} />
        </Stack>
      </Stack>
      <Divider sx={{ marginTop: "20px", marginBottom: "10px" }} />
      <Box>
        <StyledHealthDesc>
          {deserializeToJSX(
            parseMarkdown(businessHealthData.formatted_subtitle),
            createSerializer()
          )}
        </StyledHealthDesc>
        <Typography sx={{ fontSize: 20, color: muiTheme.palette.secondary.main }}>
          For further context, please refer to the insights we gathered.
        </Typography>
      </Box>
    </Stack>
  );
};

export default BusinessHealthTable;
