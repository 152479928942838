import axios from "axios";

import {
  EVENT_HISTORY_REQUEST,
  EVENT_HISTORY_SUCCESS,
  EVENT_HISTORY_FAIL,
} from "../constants/eventHistoryConstants";

const url = process.env.REACT_APP_BASE_URL.concat("insights");

export const getEventHistory = (id) => async (dispatch) => {
  try {
    dispatch({ type: EVENT_HISTORY_REQUEST });
    const { data } = await axios.get(`${url}/groups/${id}/history`);
    dispatch({
      type: EVENT_HISTORY_SUCCESS,
      payload: data,
    });

    return data.success;
  } catch (error) {
    dispatch({
      type: EVENT_HISTORY_FAIL,
      payload: error?.response?.data?.message
        ? error.response.data.message
        : error.message,
    });
    throw error.response;
  }
};
