import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import { useDispatch } from "react-redux";
import { SwitchFormControlLabel } from "../../common/styled-components/collaborate/FilterStack.styled";
import { IS_LOG_SCALE_ENABLED } from "../../../constants/multiSelectFilterConstants";

const LogarithmicScaleSwitch = () => {
  const dispatch = useDispatch();
  const [enableLogScale, setEnableLogScale] = useState(false);

  // set unset the value of switch and also dispatch the value to the filter reduxe store
  const handleChange = (event, checked) => {
    setEnableLogScale(checked);
    dispatch({ type: IS_LOG_SCALE_ENABLED, payload: checked });
  };

  return (
    <SwitchFormControlLabel
      onChange={handleChange}
      control={<Switch checked={enableLogScale} />}
      label="Log Scale"
      labelPlacement="start"
    />
  );
};

export default LogarithmicScaleSwitch;
