import React from "react";
// MUI imports
import Box from "@mui/material/Box";
// local imports
import {
  AppLogo,
  HamburgerMenu,
  HamburgerMenuContainer,
} from "../common/styled-components";
import Icon from "./Icon";
import { colors } from "../../shared/theme-constants";
import { hamburgerDisplay } from "./displayBreakpoints";

export default function Logo(props) {
  return (
    <AppLogo>
      <Icon name="Logo" size="95px" rsize="80px" ipsize="80px" />
      {/* <HamburgerMenuContainer>
        <HamburgerMenu>
          <Box sx={hamburgerDisplay}>
            <Icon
              title="Menu"
              showcursor="true"
              name="Hamburger"
              size="20px"
              color={colors.header_bg}
              onClick={props.open}
            />
          </Box>
        </HamburgerMenu>
      </HamburgerMenuContainer> */}
    </AppLogo>
  );
}
