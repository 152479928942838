import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary, {
  accordionSummaryClasses as summaryClasses,
} from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { ExpandMoreIcon } from "./Icons";
import { colors } from "../../shared/theme-constants";

const StyledAccordion = styled(Accordion)({
  // Had to keep this psuedo selector, because for some wierd reason,
  // it was showing an extra <hr /> above Overlays Chart Table
  "&:before": {
    content: "none",
  },
  "&.Mui-disabled": {
    backgroundColor: "unset",
    opacity: "unset",
  },
});

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  color: colors.gray[325],

  [`&.${summaryClasses.root}, &.${summaryClasses.root}.${summaryClasses.expanded}`]:
    {
      minHeight: "auto",
    },

  [`.${summaryClasses.content}, .${summaryClasses.content}.${summaryClasses.expanded}`]:
    {
      margin: 0,
    },

  "& hr": {
    flex: 1,
    margin: theme.spacing(0, 4),
    alignSelf: "center",
    border: 0,
    borderBottom: "1px solid",
    borderBottomColor: colors.gray[325],
  },

  [`& .${summaryClasses.expandIconWrapper}`]: {
    color: "currentColor",
    borderRadius: "50em",
    padding: theme.spacing(1),
    color: colors.gray[325],
    transition: theme.transitions.create(["transform", "background-color"], {
      duration: theme.transitions.duration.shortest,
    }),
    backgroundColor: "transparent",

    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: 0,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));

const InsightCardAccordion = ({
  /* This prop is added as a dirty hack for carousel dynamic height issue */
  insightIdAccordion,
  summary,
  children,
  onChange,
  ...delegatedProps
}) => {
  const { carouselIndex, shouldCollapseTable, insightId } = useSelector(
    (state) => state.collapseTable
  );
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (shouldCollapseTable) {
      // This conidtion ensures that if multiple cards are open, then specific table is collapsed
      if (insightIdAccordion === insightId) {
        setExpanded(false);
      }
    }
    // carouselIndex ensures that this block executes for all carousel index change event
  }, [carouselIndex, shouldCollapseTable, insightId, insightIdAccordion]);

  function handleChange(_event, isExpanded) {
    if (onChange) {
      onChange(_event, isExpanded);
    }
    setExpanded(isExpanded);
  }
  return (
    <StyledAccordion
      expanded={expanded}
      elevation={0}
      disableGutters
      onChange={handleChange}
      {...delegatedProps}
    >
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="inherit" color={colors.gray[325]}>
          {summary}
        </Typography>
        <hr />
      </StyledAccordionSummary>

      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default InsightCardAccordion;
