import { SET_COLLABORATE_TIMESTAMP } from "../constants/filterCriteriaConstants";
import {
  CHART_TYPE,
  IS_FORECAST_ENABLED,
  IS_LOG_SCALE_ENABLED,
  KPIS,
  METRIC_COMPARE,
  PATTERN_TYPE,
  PIPELINE_SCHEDULE,
  RESET_COLLABORATE_FILTERS,
  SEVERITY,
  DIMENSIONS_NAMES,
  DIMENSION_VALUES
} from "../constants/multiSelectFilterConstants";
import { localToUTC } from "../utils/dateUtils";

export const collaborateFilterReducer = (
  state = {
    isForecastEnabled: true,
    isLogScaleEnabled: false,
    isFetched: false,
    pipelineSchedule : "h",
    chartType : METRIC_COMPARE,
    selectedDimensionValues : [],
    selectedDimensionNames : []
  },
  action
) => {
  switch (action.type) {
    case KPIS:
      if (state.hasOwnProperty(KPIS)) {
        // check if Payload length has reduced to zero
        if (action.payload.length) {
          // Retain other keys and update the Metrics array
          return { ...state, kpis: [...action.payload] };
        } else {
          // If yes the delete the 'Metrics Key from the State and retain other Keys
          let currentState = { ...state };
          delete currentState[KPIS];
          return currentState;
        }
      } else {
        // When Adding Metrics to the filter for the First time and retain other Keys
        return { ...state, kpis: action.payload, isFetched: true };
      }
    case SEVERITY:
      if (state.hasOwnProperty(SEVERITY)) {
        if (action.payload.length) {
          return { ...state, Severity: [...action.payload] };
        } else {
          // If yes the delete the 'SEVERITY Key from the State and retain other Keys
          let currentState = { ...state };
          delete currentState[SEVERITY];
          return currentState;
        }
      } else {
        // When Adding Severity to the filter for the First time and retain other Keys
        return { ...state, Severity: action.payload };
      }

    case PATTERN_TYPE:
      if (state.hasOwnProperty(PATTERN_TYPE)) {
        if (action.payload.length) {
          return { ...state, pattern_type: [...action.payload] };
        } else {
          // If yes the delete the 'PATTERN_TYPE Key from the State and retain other Keys
          let currentState = { ...state };
          delete currentState[PATTERN_TYPE];
          return currentState;
        }
      } else {
        // When Adding Pattern to the filter for the First time and retain other Keys
        return { ...state, pattern_type: action.payload };
      }
    case IS_FORECAST_ENABLED:
      // set/reset the value of whether forecast  band should be enabled or not
      return { ...state, isForecastEnabled: action.payload };
    case IS_LOG_SCALE_ENABLED:
      // set/reset the value of whether forecast  band should be enabled or not
      return { ...state, isLogScaleEnabled: action.payload };
    case SET_COLLABORATE_TIMESTAMP:
      return {
        ...state,
        timeStamps: {
          startTime: typeof action.payload.start_time == 'string' ? action.payload.start_time : localToUTC(action.payload.start_time).toISOString(),
          endTime: typeof action.payload.end_time == 'string' ? action.payload.end_time : localToUTC(action.payload.end_time).toISOString(),
        },
      };
    case PIPELINE_SCHEDULE:
      // Set the pipeline Schedule for visusalisation
      return { ...state, pipelineSchedule: action.payload };
    case DIMENSIONS_NAMES:
      // Set the dimensions names array for visusalisation
      return {
        ...state,
        selectedDimensionNames: action.payload || [],
      };
    case DIMENSION_VALUES:
      // Set Dimension Values Array for visualisation
      return {
        ...state,
        selectedDimensionValues : action.payload || [],
      };
    case CHART_TYPE:
      // set the type of the chart which you want to visualise
      return { ...state, chartType: action.payload };
    case RESET_COLLABORATE_FILTERS:
      // reset other filters, and keep this UI side filter with default value
      return {
        isForecastEnabled: true,
        isLogScaleEnabled: false,
        isFetched: false,
        pipelineSchedule: "h",
        chartType: METRIC_COMPARE
      };
    default:
      return state;
  }
};
