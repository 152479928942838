import React from "react";
import ContentLoader from "react-content-loader";

export const HistogramLoader = ({ isTransparent = true }) => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <ContentLoader
        viewBox="0 0 800 70"
        height="100%"
        width="100%"
        foregroundOpacity={isTransparent ? 0.4 : 1}
        backgroundOpacity={isTransparent ? 0.6 : 1}
      >
        <rect x="15" y="15" rx="0" ry="0" width="100%" height="2" />

        <rect x="45" y="10" rx="0" ry="0" width="5" height="30" />
        <rect x="75" y="15" rx="0" ry="0" width="5" height="30" />
        <rect x="105" y="5" rx="0" ry="0" width="5" height="30" />

        <rect x="135" y="15" rx="0" ry="0" width="5" height="30" />
        <rect x="175" y="10" rx="0" ry="0" width="5" height="30" />
        <rect x="205" y="15" rx="0" ry="0" width="5" height="30" />

        <rect x="235" y="5" rx="0" ry="0" width="5" height="30" />
        <rect x="275" y="15" rx="0" ry="0" width="5" height="30" />
        <rect x="305" y="5" rx="0" ry="0" width="5" height="30" />

        <rect x="335" y="15" rx="0" ry="0" width="5" height="30" />
        <rect x="375" y="10" rx="0" ry="0" width="5" height="30" />
        <rect x="405" y="15" rx="0" ry="0" width="5" height="30" />

        <rect x="435" y="5" rx="0" ry="0" width="5" height="30" />
        <rect x="475" y="15" rx="0" ry="0" width="5" height="30" />
        <rect x="505" y="5" rx="0" ry="0" width="5" height="30" />

        <rect x="535" y="15" rx="0" ry="0" width="5" height="30" />
        <rect x="575" y="10" rx="0" ry="0" width="5" height="30" />
        <rect x="605" y="15" rx="0" ry="0" width="5" height="30" />

        <rect x="635" y="5" rx="0" ry="0" width="5" height="30" />
        <rect x="675" y="15" rx="0" ry="0" width="5" height="30" />
        <rect x="705" y="5" rx="0" ry="0" width="5" height="30" />

        <rect x="735" y="15" rx="0" ry="0" width="5" height="30" />
        <rect x="775" y="10" rx="0" ry="0" width="5" height="30" />
        <rect x="805" y="15" rx="0" ry="0" width="5" height="30" />

        <rect x="835" y="5" rx="0" ry="0" width="5" height="30" />
        <rect x="875" y="15" rx="0" ry="0" width="5" height="30" />
      </ContentLoader>
    </div>
  );
};
