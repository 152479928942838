import React from "react";
import { Stack } from "@mui/material";
import { labels } from "../../../shared/intl/labels";
import {
  LiftCardContainer,
  RowSeparator,
  RowStack,
  RowTypography,
  RowTypographyBold,
} from "../styled-components/LiftPercentage.styled";
import LiftBasicCard from "./LiftBasicCard";
import LiftFooter from "./LiftFooter";

const LiftSpikeCard = ({ data, normalizedView }) => {
  const kpiName = data.kpi_display_name;

  return (
    <LiftBasicCard
      normalizedView={normalizedView}
      formattedNormalizedValue={data.formatted_normalization_string}
      kpiName={kpiName}
    >
      <LiftCardContainer>
        <RowStack direction="row">
          <Stack direction="column">
            <RowTypography>{labels.LIFT_CALCULATION.ACTUAL}</RowTypography>
          </Stack>
          <Stack direction="column">
            <RowTypography>{data.formatted_current_value}</RowTypography>
          </Stack>
        </RowStack>
        <RowSeparator></RowSeparator>
        <RowStack direction="row">
          <Stack direction="column">
            <RowTypography>{labels.LIFT_CALCULATION.EXPECTED}</RowTypography>
          </Stack>
          <Stack direction="column">
            <RowTypography>{data.formatted_expected_value}</RowTypography>
          </Stack>
        </RowStack>
        <RowSeparator></RowSeparator>
        <RowStack direction="row">
          <Stack direction="column" mb="10px">
            <RowTypographyBold>
              {labels.LIFT_CALCULATION.DIFFERENCE}
            </RowTypographyBold>
          </Stack>
          <Stack direction="column" mb="10px">
            <RowTypographyBold>
              {data.formatted_difference_value}
            </RowTypographyBold>
          </Stack>
        </RowStack>
        {/* Trailing Sum should only be displayed for non-derived-non-percentage metric */}
        {normalizedView && data.formatted_trailing_sum && (
          <RowStack direction="row">
            <Stack direction="column" mt="10px">
              <RowTypography>
                {labels.LIFT_CALCULATION.TRAILING_SUM}
              </RowTypography>
            </Stack>
            <Stack direction="column" mt="10px">
              <RowTypography>{data.formatted_trailing_sum}</RowTypography>
            </Stack>
          </RowStack>
        )}
      </LiftCardContainer>
      <LiftFooter>{data.formatted_lift_percentage}</LiftFooter>
    </LiftBasicCard>
  );
};

export default LiftSpikeCard;
