export const point_anomaly_headers = [
  {
    key: "timestamp",
    name: "Timestamp",
  },
  {
    key: "current_y",
    name: "Actual Value",
  },
  {
    key: "yhat_upper",
    name: "Expected Maximum Value",
  },
  {
    key: "yhat_lower",
    name: "Expected Minimum Value",
  },
  {
    key: "anomaly",
    name: "Anamoly (Yes/No)",
  },
];

export const no_anomaly_headers = [
  {
    key: "timestamp",
    name: "Timestamp",
  },
  {
    key: "current_y",
    name: "Actual value",
  },
  {
    key: "yhat_upper",
    name: "Expected Maximum Value",
  },
  {
    key: "yhat_lower",
    name: "Expected Minimum Value",
  },
  {
    key: "anomaly",
    value: "No News Is Good News (Yes/No)",
  },
];
