import React from "react";

import {
  PERCENTAGE
} from "../../constants/commonConstants";
import { useHotspots } from "../../hooks";
import { HTTP_STATUS } from "../../shared/enums/http-status";
import { errorMessage } from "../../shared/intl/error-messages";
import { isEmpty } from "../../utils/is";
import { ChartOverlays } from "./ChartOverlays";
import DrillDownHotspotChart from "./DrillDownHotspotChart";
import HotspotChart from "./HotspotChart";
import TimeseriesChart from "./TimeseriesChart";
import ChartCarousel from "./hoc/ChartCarousel";
import RootcauseChart from "./rootcause/RootcauseChart";
import { Message } from "./ui-components/Message";
import { isEnabled } from "../../utils/featureFlags";
import { SAAS_RCA_FEATURE_TOGGLE } from "../../constants/featureFlagConstants";

/**
 * This component renders the Spikes Chart with Rootcause, and the chart overlays
 * @param {*} props
 * @returns
 */
function SpikesWithRootCause({
  hasRootCause,
  insightId,
  accent,
  kpiFormat,
  resourceType,
  resourceId,
  plotBands,
  overlayEndDateEpoch,
}) {

  let isRCAFeatureEnabled = isEnabled(SAAS_RCA_FEATURE_TOGGLE);

  return (
    <ChartCarousel
      totalRootcause={1}
      hasRootCause={hasRootCause}
      resourceType={resourceType}
    >
      <div style={{ minHeight: "450px", textAlign: "left" }}>
        <TimeseriesChart plotBands={plotBands} overlayEndDateEpoch={overlayEndDateEpoch} />
      </div>
      {/* Disabling Older RCA highcharts */}
      {/* {rcaHighChartsMarkup} */}
      {isRCAFeatureEnabled && (
          <div>
            <RootcauseChart insightId={insightId} />
          </div>
        )}
      <div>
        <ChartOverlays />
      </div>
      {/* Commented below the impact table as part of DEV-4390 */}
      {/* {resourceType === RESOURCE_TYPE_GROUP && (
        <div>
          <ImpactTable accent={accent} groupId={resourceId} />
        </div>
      )} */}
    </ChartCarousel>
  );
}

export default SpikesWithRootCause;
