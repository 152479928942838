import styled from "@emotion/styled";
import { Box, Checkbox, Container, FormControlLabel, FormGroup, Grid, List, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, listItemButtonClasses, listItemTextClasses } from "@mui/material";
import axios from "axios";
import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocalStorageState } from "../../src/hooks";
import { listMetrics } from "../actions/metricActions";
import { updateUserPreference } from '../actions/preferenceActions';
import Icon from '../components/common/Icon';
import { StyledActionButton } from "../components/common/mui-wrapper-components/Button";
import StyledTabs from "../components/common/mui-wrapper-components/StyledTabs";
import { Loading } from "../components/common/styled-components";
import NotificationPreferences from "../components/preferences/NotificationPreferences";
import Profile from "../components/preferences/Profile";
import ApiKey from "../components/preferences/apiKeyTab/ApiKey";
import StorePreferences from "../components/preferences/StorePreferences";
import { useSelectedTenant } from "../hooks/useSelectedTenant";
import { SET_SNACKBAR } from "../reducers/notificationSnackbarReducer";
import { typography } from "../shared/theme-constants";

const Item = styled(Paper)(({ theme, sidebar }) => ({
  backgroundColor: "var(--white)",
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: "0px 0px 30px 0px #AAAAAA29",
  height: '100%',
  width: '100%',
  overflowY: 'scroll',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: sidebar ? 'space-between' : 'unset'
}));

const FormGroupStyled = styled(FormGroup)(({ theme }) => ({
  overflowY: 'scroll',
  flexWrap: 'noWrap',
  height: '81%',
  padding: '0px 20px 0px 20px'
}));

const ListButton = styled(ListItemButton)(({ theme, selected }) => ({
  height: theme.typography.pxToRem(47),
  background: "var(--white)",
  color: "var(--comet)",
  ...(selected && {
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      right: -11,
      width: 0,
      height: 0,
      transform: "rotate(-45deg)",
      zIndex: 0,
    },
  }),
  [`&.${listItemButtonClasses.selected}`]: {
    "&.Mui-selected": {
      border: "4px solid var(--mairner)",
      color: theme.palette.primary.main,
    },
  },
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  [`& .${listItemTextClasses.primary}`]: { fontWeight: typography.font_weight.semiBold },
}));

const LOGOUT_URL = process.env.REACT_APP_BASE_URL + "logout";
const MY_METRICS_TAB = [
  { value: "my-metrics", label: "My Metrics"},
];

const PreferenceScreen = () => {
  const dispatch = useDispatch();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [section, setSection] = useState(searchParams.get("tab"));
  const [selectedKpis, setSelectedKpis] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const selectedTenant = useSelectedTenant();
  const [clearSelectedTenantFromLS] = useLocalStorageState("selectedTenant");
  const navigate = useNavigate();
  const { loading, metrics } = useSelector((state) => state.metricList);
  const { loading: updatingPreference, updatedUserPreference } = useSelector((state) => state.updateUserPreference);
  const tenantGlobalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);
  const userObject = useSelector((state) => state.userLogin);

  const menuOptions = [
    {
      label: 'Profile',
      value: 'profile',
      name: 'profile',
      is_enabled: true,
    },
    {
      label: 'My Metrics',
      value: 'my_metrics',
      name: 'my_metrics',
      is_enabled: userObject?.is_design_partner,
    },
    {
      label: 'Notifications',
      value: 'notifications',
      name: 'notifications',
      is_enabled: true,
    },
    {
      label: 'API Keys',
      value: 'api_keys',
      name: 'api_keys',
      is_enabled: true,
    },
    {
      label: 'Stores',
      value: 'stores',
      name: 'stores',
      is_enabled: true,
    },
  ];

  useEffect(() => {
    const queryParams = {
      tenant_id: selectedTenant,
      is_active: [true],
      sort_by: "kpi_display_name",
      dim_name: tenantGlobalFilter?.dimension_name,
      dim_val: tenantGlobalFilter?.dimension_value
    };

    dispatch(listMetrics(queryParams));
  }, [
    dispatch,
    selectedTenant,
    tenantGlobalFilter?.dimension_name,
    tenantGlobalFilter?.dimension_value
  ]);

  const { isLoading: fetchingUserPreference, data: userPreference } = useQuery({
    queryKey: ["userPreference"],
    queryFn: () => {
      const queryParams = {
        tenant_id: selectedTenant,
        name: "MY_METRICS_PREFERENCE",
        scope: 'user',
      };

      const params = new URLSearchParams(queryParams);
      return axios.get(`${process.env.REACT_APP_BASE_URL}preferences`, { params }).then(res => res.data);
    },
      enabled: loading !== undefined && !loading && Array.isArray(metrics),
      refetchOnWindowFocus: false,
  }
  );

  useEffect(() => {
    if (userPreference?.preference_value?.kpis) {
      setSelectedKpis(userPreference.preference_value.kpis);
    }
  }, [userPreference]);

  const handleSectionClicked = (section) => {
    setSearchParams(`tab=${section}`);
    setSection(section);
  };

  const saveUserPreferences = () => {
    if (selectedKpis && (!selectedKpis.length || selectedKpis.length < 3 || selectedKpis.length > 4)) {
      return dispatch({
        type: SET_SNACKBAR,
        snackbarOpen: true,
        snackbarMessage: selectedKpis.length < 3 ? "Select atleast 3 metrics" : "You can select minimum 3 and maximum 4 metrics.",
        snackbarType: "info",
      });
    }
    const queryParams = {
      tenant_id: selectedTenant,
      scope: 'user',
    };
    const postData = {
      preference_name: "MY_METRICS_PREFERENCE",
      preference_value: {
        kpis: selectedKpis
      },
      is_active: true,
      scope: 'user'
    };
    dispatch(updateUserPreference(postData, queryParams));
  };

  const handleCheckbox = (event, kpiId) => {
    if (event.target.checked && !selectedKpis.includes(kpiId) && selectedKpis.length >= 4) {
      return dispatch({
        type: SET_SNACKBAR,
        snackbarOpen: true,
        snackbarMessage: "More than 4 metrics cannot be selected.",
        snackbarType: "info",
      });
    }
    setSelectedKpis((prevKpis) => {
      const updatedKpis = [...prevKpis];

      if (event.target.checked && !updatedKpis.includes(kpiId)) {
        if (updatedKpis.length < 4) {
          updatedKpis.push(kpiId);
        }
      } else if (!event.target.checked && updatedKpis.includes(kpiId)) {
        updatedKpis.splice(updatedKpis.indexOf(kpiId), 1);
      }

      // Check if the selected metrics are exactly the same as saved metrics
      if (userPreference?.preference_value?.kpis && userPreference?.preference_value?.kpis.length) {
        setHasChanges(!isEqual(updatedKpis, userPreference.preference_value.kpis));
      } else {
        setHasChanges(true);
      }

      return updatedKpis;
    });
  };

  const handleDiscard = () => {
    if (userPreference?.preference_value?.kpis && userPreference?.preference_value?.kpis.length) {
      setSelectedKpis(userPreference.preference_value.kpis);
      setHasChanges(false);
    } else {
      setSelectedKpis([]);
    }
  };

  const logout = async () => {
    await axios
      .get(LOGOUT_URL)
      .then((data) => {
        clearSelectedTenantFromLS();
        // reset the Heap user Identity on logout
        window?.heap?.resetIdentity();
        navigate("/login");
      })
      .catch((err) => {
        // handle logout error navigation
      });
  };

  function tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const renderUserMetrics = () => {
    if (loading === undefined || loading || !Array.isArray(metrics)) {
      return <Loading />;
    }
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div style={{ flex: 1, overflowY: "auto", display: "flex", flexDirection: "column" }}>
          <StyledTabs
            sx={{ padding: 5 }}
            value={MY_METRICS_TAB[0].value}
            options={MY_METRICS_TAB}
          />
          <FormGroupStyled
            role="tabpanel"
            id={`simple-tabpanel-0`}
            aria-labelledby={`simple-tab-0`}
            value="myMetrics"
            index={0}
          >
            {(metrics || []).map((item, index) => {
              return (
                <FormControlLabel
                  key={item.kpi_id}
                  control={
                    <Checkbox
                      onChange={(e) => handleCheckbox(e, item.kpi_id)}
                      checked={selectedKpis.includes(item.kpi_id)}
                    />
                  }
                  label={item?.kpi_display_name}
                />
              );
            })}
          </FormGroupStyled>
        </div>
       <Stack direction="row" gap={3}
        sx={{
          justifyContent: "flex-start",
          alignItems: "center",
          padding: 4,
        }}>
          <StyledActionButton
            onClick={saveUserPreferences}
            loading={updatingPreference}
            disabled={updatingPreference || !hasChanges}
          >
            Save changes
          </StyledActionButton>
          <StyledActionButton
           style={{
            background: "var(--white)",
            color: "var(--comet)",
            borderColor: "var(--comet)",
          }}
            disabled={updatingPreference || !hasChanges}
            onClick={() => handleDiscard()}
          >
            Discard
          </StyledActionButton>
        </Stack>
      </div>
    );
  };

  const sectionsToRender = {
    "my_metrics": renderUserMetrics(),
    "notifications": <NotificationPreferences/>,
    "profile": <Profile />,
    "api_keys": <ApiKey />,
    "stores": <StorePreferences />,
  };

  return (
    <Container
      maxWidth="100%"
      sx={(theme) => ({
        py: { sm: theme.spacing(5), sl: theme.spacing(10) },
        px: { sm: "5%", sl: "5%" },
        height: "calc(100vh - 75px)",
      })}
    >
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%", height: "100%" }}>
        <Grid container spacing={2} style={{ display: "flex", justifyContent: "center" }}>
          <Grid item sm={2} sx={{ height: "50%" }}>
            <Item sidebar={1}>
              <List disablePadding component="nav" sx={{ width: "100%" }}>
                {menuOptions.filter(tab => tab.is_enabled).map(({ index, label, value, name, ...rest }) => (
                  <ListButton
                    onClick={() => handleSectionClicked(value)}
                    key={value}
                    selected={section === value}
                  >
                    <ListItemIcon style={{ paddingRight: "10px", minWidth: "unset" }}>
                      <img src={`/images/${name}.png`} width={20} height={20}/>
                    </ListItemIcon>
                    <StyledListItemText primary={label}></StyledListItemText>
                  </ListButton>
                ))}
              </List>
              <List>
                <ListButton onClick={() => logout()} key="logOut" selected={section === "logOut"}>
                  <ListItemIcon style={{ paddingRight: "10px", minWidth: "unset" }}>
                    <Icon name="logout-black" size="20px"></Icon>
                  </ListItemIcon>
                  <StyledListItemText primary="Logout"></StyledListItemText>
                </ListButton>
              </List>
            </Item>
          </Grid>
          <Grid item sm={8} sx={{ height: "100%", justifyContent: "center" }}>
            <Item>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                {sectionsToRender[section]}
              </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PreferenceScreen;
