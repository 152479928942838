import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { ChevronLeftIcon, ChevronRightIcon } from "./Icons";

const ButtonGroupContainer = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  flexDirection: "row",
  flexGrow: 0,
  borderRadius: "50em",
  backgroundColor: theme.palette.common.white,
}));

const StyledButtonGroup = styled(ButtonGroup)({
  "& .MuiButtonGroup-grouped": {
    minWidth: "auto",
    transform: "scale(1.03)",

    "&:not(.sideButton)": {
      transitionProperty: "none",
    },

    "&:only-child": {
      borderRadius: "50em",
      // using outlined but changing border to transparent
      // because variant text have smaller paddings for some reason
      borderColor: "transparent",
    },
  },
});

export const SideButton = styled(
  React.forwardRef(function SideButton({ className, ...props }, ref) {
    return (
      <Button
        {...props}
        className={[className, "sideButton"].join(" ")}
        ref={ref}
      />
    );
  })
)(({ theme }) => ({
  padding: 0,

  "&:first-of-type": {
    borderTopLeftRadius: "50em",
    borderBottomLeftRadius: "50em",
  },

  "&:last-of-type": {
    borderTopRightRadius: "50em",
    borderBottomRightRadius: "50em",
  },

  "&:disabled": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const ToggleButtonWithSideButtonsGroup = ({
  onChange,
  prevButton,
  nextButton,
  options,
  value,
  ...rest
}) => {
  const [selectedValue, setSelectedValue] = useState(value);

  function handleChange(event, selectedValue) {
    if (selectedValue !== null) {
      setSelectedValue(selectedValue);

      if (typeof onChange === "function") {
        onChange(event, selectedValue);
      }
    }
  }

  return (
    <ButtonGroupContainer role="group" {...rest}>
      {options.map((option) => {
        const isSelected = selectedValue === option.value;

        return (
          <StyledButtonGroup
            aria-label={option.value}
            aria-pressed={isSelected}
            disabled={option.disabled}
            disableElevation
            key={option.value}
            variant={isSelected ? "contained" : "outlined"}
            size="xlarge"
          >
            {isSelected && (
              <prevButton.type
                aria-label="previous side button"
                {...prevButton.props}
                onClick={() => prevButton.props.onClick(selectedValue)}
              >
                <ChevronLeftIcon />
              </prevButton.type>
            )}

            <Button onClick={(event) => handleChange(event, option.value)}>
              {option.label}
            </Button>

            {isSelected && (
              <nextButton.type
                aria-label="next side button"
                {...nextButton.props}
                onClick={() => nextButton.props.onClick(selectedValue)}
              >
                <ChevronRightIcon />
              </nextButton.type>
            )}
          </StyledButtonGroup>
        );
      })}
    </ButtonGroupContainer>
  );
};

export default ToggleButtonWithSideButtonsGroup;
