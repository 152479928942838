// React Imports
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";

// Local Imports
import {
  ActionButton,
  StateActionLabel,
  ConfirmationBoxStyles,
} from "../styled-components/confirmation-box/ConfirmationBox.styled";
import { NotificationMessage } from "../styled-components/NotificationMessage.styled";

import * as TeamInsightStates from "../../../constants/teamConstants";
import Icon from "../Icon";
import { GTAG_EVENTS, sendDataToGTM } from "../../../utils/gtmHelper";

import { Fade } from "@mui/material";
import { updateGroupStateForTeam } from "../../../actions/groupInsightActions";

const TeamActionsControl = ({ handleClickTeamActionIcon }) => {
  const matches = useMediaQuery("(max-width:600px)");

  const [ifFlagged, setIfFlagged] = useState(false);
  const currentFolder = useSelector((state) => state.drawer.menuItem);
  const loading = useSelector((state) => state.teamInsightList.loading);
  const selectedInsights = useSelector(
    (state) => state.teamInsightList.selectedInsights
  );

  const dispatch = useDispatch();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [toastState, setToastState] = useState({
    open: true,
    vertical: "top",
    horizontal: "center",
  });
  const { open, horizontal, vertical } = toastState;

  /**
   * Depending upon which next Team action is allowed on an insight
   * this function will automatically generate the payload for the requesst
   */
  const getNextStateTransition = () => {
    switch (currentFolder) {
      case TeamInsightStates.FLAGGED:
        return TeamInsightStates.RESOLVED;
      case TeamInsightStates.RESOLVED:
        return TeamInsightStates.FLAGGED;
      default:
        return TeamInsightStates.FLAGGED;
    }
  };
  const Confirm = (state) => {
    // open sidebar just to show visually to user where his action is ending
    // set animation time in ms
    handleClickTeamActionIcon(getNextStateTransition(), 1200);
    dispatch(
      updateGroupStateForTeam(
        {
          old_state: currentFolder,
          current_state: getNextStateTransition(),
        },
        selectedInsights
      )
    ).then((result) => {
      if (result) {
        setShowSuccessMessage(true);
        selectedInsights.forEach((insight, index) => {
          sendDataToGTM(GTAG_EVENTS.INSIGHT_STATE_ACTIONS, {
            insight_state_actions: {
              old_state: currentFolder,
              current_state: getNextStateTransition(),
              insight_id: insight,
              pattern_type: undefined,
              kpi_accent: undefined,
              dim_name: undefined,
              dim_value: undefined,
              kpi_name: undefined,
              insight_severity: undefined,
            },
          });
        });
      }
    });
  };
  const onCancel = () => {
    dispatch({
      type: TeamInsightStates.TEAM_INSIGHT_ACTION_CANCEL,
      selectedInsights: [],
    });
  };

  /**
   * This Function will automatically generate the text of the button of the next transition.
   *
   */
  const getNextTransitionButtonText = (
    nextState = getNextStateTransition()
  ) => {
    switch (nextState) {
      case TeamInsightStates.RESOLVED:
        return TeamInsightStates.RESOLVE;
      case TeamInsightStates.FLAGGED:
        return TeamInsightStates.FLAG;
    }
  };
  const handleClose = () => {
    setToastState({ ...toastState, open: false });
    dispatch({ type: TeamInsightStates.TEAM_INSIGHT_ACTION_SUCCESS });
  };

  return showSuccessMessage ? (
    <NotificationMessage
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      key={vertical + horizontal}
      message={`This item was moved to your ${getNextStateTransition()} feed. Click the ${getNextStateTransition()} icon to the left to revisit it`}
      autoHideDuration={1500}
    />
  ) : (
    <div style={{ position: "relative" }}>
      <Box
        sx={{
          ...ConfirmationBoxStyles,
          top: currentFolder !== "Open" ? "150px" : "124px",
        }}
      >
        <Fade in={!showSuccessMessage} timeout={500}>
          {!ifFlagged ? (
            <Grid item sm={4}>
              <ActionButton onClick={() => setIfFlagged(!ifFlagged)}>
                {getNextTransitionButtonText()}
                <Icon
                  name={getNextStateTransition()}
                  strokecolor="#545C7E"
                  size="15px"
                  margin="1px 0px 0px 3px"
                />
              </ActionButton>
            </Grid>
          ) : (
            <Fade in={ifFlagged}>
              <Grid container spacing={matches ? 2 : 8} columns={12}>
                <Grid item sm={4}>
                  <StateActionLabel>
                    <div style={{ margin: "3px" }}>
                      {getNextStateTransition()}
                    </div>
                    <Icon
                      name={getNextStateTransition()}
                      strokecolor="white"
                      color={
                        getNextStateTransition() === TeamInsightStates.FLAGGED
                          ? "white"
                          : ""
                      }
                      size="15px"
                      margin="5px 0px 0px 1px"
                    />
                  </StateActionLabel>
                </Grid>
                <Grid item sm={matches ? 4 : 3.7}>
                  <ActionButton
                    style={{ color: "#B9BED5" }}
                    disabled={loading}
                    onClick={onCancel}
                  >
                    Cancel
                  </ActionButton>
                </Grid>
                <Grid item sm={4}>
                  <ActionButton
                    style={{ color: "#767A88" }}
                    disabled={loading}
                    onClick={Confirm}
                  >
                    Confirm {loading && <CircularProgress size="0.8rem" />}
                  </ActionButton>
                </Grid>
              </Grid>
            </Fade>
          )}
        </Fade>
      </Box>
    </div>
  );
};

export default TeamActionsControl;
