import React from "react";
import {
  Table as MUITable,
  TableBody,
  TableRow,
  TableHead,
} from "@mui/material";
import {
  Container,
  HeaderTableRow,
  TableCell,
  FirstPinnedCell,
  LastPinnedCell,
  HeaderTableCell,
  FirstPinnedHeaderCell,
  LastPinnedHeaderCell,
} from "../styled-components/KPIGroupTable.styled";
import { colors } from "../../../shared/theme-constants";

const tableHeaderRowHeight = 56;

function renderCell(column, row) {
  let value = row[column.field]; // either object or primitive value
  let formattedValue = value;
  if (column.valueFormatter) {
    formattedValue = column.valueFormatter({ value });
  }
  if (column.renderCell) {
    formattedValue = column.renderCell({
      value,
      formattedValue,
      row,
    });
  }

  return formattedValue;
}

function getHeaderCellComponent(index, columns, isColumnPinned) {
  if (index === 0 && isColumnPinned) {
    return FirstPinnedHeaderCell;
  } else if (index === columns.length - 1 && isColumnPinned) {
    return LastPinnedHeaderCell;
  }

  return HeaderTableCell;
}

function getBodyCellComponent(index, columns, isColumnPinned) {
  if (index === 0 && isColumnPinned) {
    return FirstPinnedCell;
  } else if (index === columns.length - 1 && isColumnPinned) {
    return LastPinnedCell;
  }

  return TableCell;
}

export const Table = ({ columns, rows, headerGroups = null, ...muiProps }) => {
  return (
    <Container
      ref={(element) => {
        if (!element) return;

        element.scrollLeft += element.offsetWidth;
      }}
      {...muiProps}
    >
      <MUITable
        stickyHeader
        sx={{
          "& .style-kpi a:hover": {
            cursor: "pointer",
            fontSize: "0.9rem",
            transition: "font-size 0.2s ease-out",
            color: colors.blue[700],
          },
        }}
      >
        <TableHead>
          {headerGroups}
          <HeaderTableRow>
            {columns.map((column, index) => {
              const CellComponent = getHeaderCellComponent(
                index,
                columns,
                column.pinned
              );

              return (
                <CellComponent
                  key={index}
                  // For grouped columns, adding +1 to the table header row height, so that the bottom border is visible
                  style={headerGroups ? { top: tableHeaderRowHeight + 1 } : {}}
                >
                  {column.headerName}
                </CellComponent>
              );
            })}
          </HeaderTableRow>
        </TableHead>

        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              {columns.map((column, index) => {
                const CellComponent = getBodyCellComponent(
                  index,
                  columns,
                  column.pinned
                );

                return (
                  <CellComponent key={index} className={column.cellClassName}>
                    {renderCell(column, row)}
                  </CellComponent>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
    </Container>
  );
};
