import { useMemo } from "react";

import {
  hotspotLayout,
  hotspotPlot,
  styles,
  hotspotConfig,
} from "../shared/plotly-config";
import { nFormatter } from "../utils/stringUtils";
import { color_continuous_scale, colors } from "../shared/theme-constants";
import { PERCENTAGE } from "../constants/commonConstants";
import { useAxios } from "./useAxios";

const lastCommaInTheGivenString = /,(?!.*,)/gim;
// This is an arbitrary constant, derived by trial and runs, which will ensure that xRange is derived properly
const X_RANGE_BUFFER = 2;

// Calculates the bargap based on the number of items in the array
function getBarGap(itemsCount) {
  const MIN_GAP_VALUE = 0.3;
  const MAX_GAP_VALUE = 0.99;
  const MAX_COUNT_OF_BARS = 5;

  if (itemsCount === 0) return 0;
  if (itemsCount > MAX_COUNT_OF_BARS) return MIN_GAP_VALUE;

  const difference = MAX_GAP_VALUE - MIN_GAP_VALUE;
  // the bigger the count, the closer to MIN_GAP_VALUE we should be
  return (
    ((MAX_COUNT_OF_BARS - itemsCount) / MAX_COUNT_OF_BARS) * difference +
    MIN_GAP_VALUE
  );
}

/**
 * This function forms the hover template and text template for each rootcause object
 * @param {*} rootcauseObj
 * @returns { {hoverTemplate: string, textTemplate: string} }
 */
const getTemplates = ({
  dimVal,
  kpiDelta,
  kpiSumDelta,
  kpiValueMovement,
  kpiFormat,
  dimName,
  deltaSgPct,
  isMDH,
  parentDimName,
  parentDimVal,
  drillDownGroup,
  rcType,
  accent,
  rootcauseChartIndex,
  kpiValueMovementDecimal,
  patternContribution,
  kpiPopulationChangePct
}) => {
  // The top ranked chart is considered the one with rootcause_type: `udh+mdh`
  const isTopRanked = rcType === "udh+mdh";
  const hotspotType = isMDH ? "Multidimensional" : "Unidimensional";

  const positiveIsGood = accent === "positive" && kpiDelta > 0;
  const negativeIsGood = accent === "negative" && kpiDelta < 0;
  const formattedKpiDelta = kpiFormat === 'percentage' ? `${(kpiDelta * 100).toFixed(1)}%` : nFormatter(kpiDelta, 1);
  const formattedKpiSumDelta = kpiFormat === 'percentage' ? `${(kpiSumDelta * 100).toFixed(1)}%` : nFormatter(kpiSumDelta, 1);

  const fontWeight = `font-weight: 700;`;
  // Title color and font style of the hover template
  const hoverTemplateTitleStyle = `color: ${isMDH ? colors.turquoise : colors.azure
    }; ${fontWeight}`;

  // Font color of the contribution % value
  const hoverTemplateContributionValueStyle = `color: ${positiveIsGood || negativeIsGood
    ? colors.positive_green
    : colors.negative_red
    }; ${fontWeight}`;

  let impactStyles = 'font-weight: 600; font-size: 13px; font-family: Poppins;';

  if (kpiValueMovementDecimal > 0 ) {
    if (accent === "positive") {
      impactStyles +=`color: ${colors.positive_green}`;
    } else {
      impactStyles +=  `color: ${colors.negative_red}`;
    }
  }else{
    if (accent === "positive") {
      impactStyles += `color: ${colors.negative_red}`;
    } else {
      impactStyles += `color: ${colors.positive_green}`;
    }
  }

  // Style for the Hover template and Text template values
  const hoverAndTextVaueStyle = `color: ${colors.gray[500]}; ${fontWeight}`;
  // Color for the sub group size values
  const subGroupSizeColor = `${deltaSgPct === "N/A"
    ? colors.gray[500]
    : deltaSgPct[0] === "-"
      ? colors.negative_red
      : colors.positive_green
    }`;
  // Style for the sub group size values
  const subGroupSizeStyle = `color: ${subGroupSizeColor}; ${fontWeight}`;
  // Template for the `Change in subgroup size` percentage
  const subGroupSizeTemplate = `Change in subgroup size: <span style="${subGroupSizeStyle}">${deltaSgPct}</span>`;

  // Template for the hover template title `Unidimensional` or `Multidimensional`
  const hoverTemplateTitle = `<span style="${hoverTemplateTitleStyle}">${hotspotType}</span>`;

  const contributionValue = `<b>${formattedKpiDelta}</b>`;

  const totalKpiSumDelta = `<b>${formattedKpiSumDelta}</b>`;

  let hoverTemplate = "",
    textTemplate = "";

  const pDimName = parentDimName ? parentDimName + ": " : "";

  if (isTopRanked) {
    // Final hover template for `Multidimensional` rootcause
    hoverTemplate = `${hoverTemplateTitle}<br>Contribution: <span style="${hoverTemplateContributionValueStyle}">${contributionValue}</span><br>${subGroupSizeTemplate}`;
    if (isMDH) {
      const dimNames = formatDimensions(dimName, "; ");
      // Final text template for `Multidimensional` rootcause with multiple diminesion names
      textTemplate = `${dimNames}:<br>${dimVal}<br><span style="${hoverAndTextVaueStyle}">${kpiValueMovement}</span>`;
    } else {
      // Final text template for `Multidimensional` rootcause with single dimension name
      textTemplate = `${dimName}: ${dimVal}<br><span style="${hoverAndTextVaueStyle}">${kpiValueMovement}</span>`;
    }
  } else {
    // Final hover template for `Unidimensional` rootcause
    hoverTemplate = `Contribution: <span style="${hoverTemplateContributionValueStyle}">${kpiValueMovement}</span><br>${subGroupSizeTemplate}${drillDownGroup > 0 ? `<br><span style="color:${colors.gray[500]};">Click to Drill-In</span>` : ``}`;
    // Final text template for `Unidimensional` rootcause
    textTemplate =
      // The parent Dimension Value should only be displayed for primary drivers, i.e. index=0
      drillDownGroup && rootcauseChartIndex === 0
        ? `<span style="font-size :13px; font-family: Poppins">${pDimName}${dimName} : ${dimVal}</span><br><span class="impact-with-hover" style="${impactStyles}">${patternContribution ? patternContribution : kpiValueMovement} Impact</span> <span class="hover-text">(${kpiPopulationChangePct? kpiPopulationChangePct: deltaSgPct} Vol. Change)</span>`
        : `<span style="font-size :13px">${dimVal}</span><br><span class="impact-with-hover" style="${impactStyles}">${patternContribution ? patternContribution : kpiValueMovement} Impact</span><span class="hover-text">(${kpiPopulationChangePct? kpiPopulationChangePct: deltaSgPct} Vol. Change)</span>`;
  }
  return { textTemplate, hoverTemplate };
};

/**
 * For udh+mdh, the dimension names and values should be in below format:
 * e.g. App Version, Payment Method and xyz: 1.0.2, Online and xyzValue
 * @param {string} details dimension name or value
 * @param {string} splitBy split by `;` or `:`
 * @returns formatted string
 */
const formatDimensions = (details, splitBy) => {
  if (!details) return details;

  // Generate arrays of dimension split by `;` or ':' and then join them using `, ` to form a single string
  return (
    details
      .split(splitBy)
      .join(", ")
      // Then, replace the last comma with ` and`
      .replace(lastCommaInTheGivenString, " and")
  );
};

const calculateColorScale = (showSingleColorPallete, accent) => {
  let colorScale = [];
  if (accent === "positive") {
    colorScale = showSingleColorPallete
      ? color_continuous_scale.Greens
      : color_continuous_scale.RdYlGn_r;
  } else {
    colorScale = showSingleColorPallete
      ? color_continuous_scale.Reds
      : color_continuous_scale.RdYlGn;
  }

  return colorScale;
};

const generateChartTitle = (index, isDrillDownEnabled, isMDH) => {
  if (index === 0 && isDrillDownEnabled) return "Primary Drivers";
  if (index === 1 && isMDH && isDrillDownEnabled)
    return "Multi-Dimensional Primary Drivers";
  if (isDrillDownEnabled) return "Drill Down chart for primary drivers";
  return null;
};

export const useHotspots = (insightId, accent, kpiFormat) => {
  const { data, status, error } = useAxios(`insights/${insightId}/rootcause`);

  const hotspotData = useMemo(() => {
    if (!data) return [];

    let rootcausePlot = {};
    const rootcauseData = [];

    data.rootcause_data.forEach((rootcause, index) => {
      const xValues = [],
        yValues = [],
        hoverTempl = [],
        textTempl = [],
        kpiDeltas = [],
        customData = [],
        nullValueData = [];

      const isDrillDownEnabled = rootcause.rootcause_details.some(
        (rc) => rc.drill_down_group
      );
      const isAtLeastOneMdhPresent = rootcause.rootcause_details.some(
        (rc) => rc.is_mdh
      );

      rootcause.rootcause_details.forEach((each) => {
        if (each.dim_val === null) {
          // In case of the dim values with null are present  then maintain them separately and show them at bottom corner at right
          nullValueData.push({
            isClickable: each.is_drill_down,
            group: each.group,
            drillDownGroup: each.drill_down_group,
            dimName: each.dim_name,
            dimValue: each.dim_val,
            isMDH: each.is_mdh,
            kpiValueMovement: each.kpi_value_movement,
            kpiValueMovementDecimal: each.kpi_value_movement_decimal,
            deltaSgPct: each.delta_subgroup_sizes_pct,
            parentDimName: each.parent_dim_name,
            parentDimValue: each.parent_dim_value,
            patternContribution: each.formatted_pattern_contribution,
            kpiPopulationChangePct: each.formatted_kpi_population_change_pct
          });
        } else {
          const { textTemplate, hoverTemplate } = getTemplates({
            dimVal: each.dim_val,
            kpiDelta: each.kpi_delta,
            kpiSumDelta: each.kpi_sum_delta,
            kpiValueMovement: each.kpi_value_movement,
            kpiFormat: each.kpi_format,
            dimName: each.dim_name,
            deltaSgPct: each.delta_subgroup_sizes_pct,
            isMDH: each.is_mdh,
            parentDimName: each.parent_dim_name,
            parentDimVal: each.parent_dim_value,
            drillDownGroup: each.drill_down_group,
            rcType: rootcause.rootcause_type,
            accent,
            rootcauseChartIndex: index,
            kpiValueMovementDecimal: each.kpi_value_movement_decimal,
            patternContribution: each.formatted_pattern_contribution,
            kpiPopulationChangePct: each.formatted_kpi_population_change_pct
          });
          xValues.push(each.kpi_value_movement_decimal);
          yValues.push(each.hotspot_id || "");
          kpiDeltas.push(each.kpi_delta);
          hoverTempl.push(hoverTemplate);
          textTempl.push(textTemplate);
          customData.push({
            isClickable: each.is_drill_down,
            group: each.group,
            drillDownGroup: each.drill_down_group,
            dimName: each.dim_name,
            dimValue: each.dim_val,
            isMDH: each.is_mdh,
            kpiValueMovement: each.kpi_value_movement,
            kpiValueMovementDecimal: each.kpi_value_movement_decimal,
            parentDimName: each.parent_dim_name,
            parentDimValue: each.parent_dim_value,
          });
        }
      });
      const kpiDeltasLength = kpiDeltas.length;
      const areAllDeltasPositive =
        kpiDeltas.filter((value) => value > 0).length === kpiDeltasLength;

      const areAllDeltasNegative =
        kpiDeltas.filter((value) => value < 0).length === kpiDeltasLength;

      const showSingleColorPallete =
        areAllDeltasPositive || areAllDeltasNegative;

      const xMax = Math.max(...xValues);
      const xMin = Math.min(...xValues);
      let range_margin = xMax - xMin;
      // When there is only one record, range_margin will be zero, hence the xRange will not be correct
      // So, set it to 1 for such cases
      if (range_margin === 0) {
        range_margin = 1;
      }

      // Below config ensures that the x-range is extended to fit in the texttemplate, shown next to each bar [start, end]
      let xRange = [];
      if (xMin > 0) {
        // If xMin is greater than 0 then we need to set xrange min valu to 0
        xRange = [0, xMax * X_RANGE_BUFFER];
      } else if (Math.abs(xMin) > Math.abs(xMax)) {
        // we check absolute value of xMin and xMax
        // if ABS(xMin) > ABS(xMax) then chart should be centered or else it will be skewed to right
        xRange = [xMin * X_RANGE_BUFFER, Math.abs(xMin) * X_RANGE_BUFFER];
      } else {
        // Similary in or avoid skeing chart on left we need below condition
        xRange = [-xMax * X_RANGE_BUFFER, xMax * X_RANGE_BUFFER];
      }

      rootcausePlot = {
        hotspotData: [
          {
            ...hotspotPlot,
            marker: {
              ...hotspotPlot.marker,
              colorscale: calculateColorScale(showSingleColorPallete, accent),
            },
            customdata: customData,
            nullValueData: nullValueData,
            x: xValues,
            y: yValues,
            text: textTempl,
            hoverinfo: "text",
            hovertext: hoverTempl,
            hovertemplate: "%{hovertext}<extra></extra>",
            texttemplate: "%{text}",
            textposition: "outside",
            textfont: {
              family: "Poppins",
              size: 14,
            },
          },
        ],
        chartLayout: {
          ...hotspotLayout,
          bargap: getBarGap(rootcause.rootcause_details.length),
          hoverlabel: {
            bgcolor: "#fff",
            bordercolor: colors.gray[200],
            font: {
              family: "Inter",
              color: "#000",
              size: "14px",
            },
          },
          xaxis: {
            ...hotspotLayout.xaxis,
            range: xRange,
            title: {
              text: kpiFormat === PERCENTAGE ? "Contribution %" : "",
              font: {
                family: "Poppins",
                size: 12,
                color: colors.gray[500],
              },
            },
          },
          yaxis: { ...hotspotLayout.yaxis },
        },
      };

      rootcauseData.push({
        isDrillDownEnabled,
        isMdh: isAtLeastOneMdhPresent,
        chartSummary: rootcause.rootcause_title,
        annotation: rootcause.rootcause_subtitle,
        chartData: rootcausePlot,
        chartTitle: generateChartTitle(
          index,
          isDrillDownEnabled,
          isAtLeastOneMdhPresent
        ),
      });
    });

    return rootcauseData;
  }, [accent, data, kpiFormat]);

  return {
    hotspotData,
    error,
    status,
    barChartConfig: hotspotConfig,
    styles,
  };
};
