import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Stack } from "@mui/material";
import { Loading } from "../../common/styled-components";
import { Message } from "../../common/ui-components/Message";
import Insight from "../../insights/insight/Insight";
import { getInsightsForL3View } from "../../../actions/insightActions";
import { RESOURCE_TYPE_INSIGHT } from "../../../constants/commonConstants";
import { typography } from "../../../shared/theme-constants";
import { generateDimensionListQueryParam, getQueryParamsFromFilterObject } from "../../../utils/processFilterParams";

const FilteredInsightsGrid = ({ tenantId, pipelineSchedule }) => {
  const [insightList, setInsightList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const globalFilter = useSelector((state)=> state.tenantGlobalFilter?.appliedFilter, (previousValue, currentValue)=>{
 // Check if filters have changed
      // If yes then reset the page count to 1 and set the current insight list to empty
      if (previousValue !== currentValue) {
        setPage(1);
        setInsightList([]);
      }
  });
  const appliedFilters = useSelector(
    (state) => state.collaborateFilters,
    (previousValue, currentValue) => {
      // Check if filters have changed
      // If yes then reset the page count to 1 and set the current insight list to empty
      if (previousValue !== currentValue) {
        setPage(1);
        setInsightList([]);
      }
    }
  );

  useEffect(() => {
    // variable to avoid setting component variables once component is unmounted
    let isMounted = true;

    if (appliedFilters.isFetched) {
      // call function that prepares query param string for the api
      let dimensionsArrayString = generateDimensionListQueryParam(appliedFilters?.selectedDimensionNames, appliedFilters?.selectedDimensionValues);
      let encodedQueryParams = getQueryParamsFromFilterObject(
        appliedFilters,
        tenantId,
        pipelineSchedule,
        null,
        globalFilter?.dimension_name,
        globalFilter?.dimension_value,
        dimensionsArrayString
      );

      setLoading(true);

      dispatch(getInsightsForL3View(encodedQueryParams, page)).then(
        (response) => {
          if (isMounted) {
            setHasNextPage(response.generated_insights_count > 0);
            setInsightList((previousInsightsList) => [
              ...previousInsightsList,
              ...response.insights,
            ]);
            setLoading(false);
          }
        }
      );
    }

    return () => (isMounted = false);
  }, [appliedFilters, dispatch, page, pipelineSchedule, tenantId, globalFilter]);

  return (
    <Stack direction="column" sx={{ width: "inherit" }} mt="30px">
      <Grid container columnSpacing={4}>
        {insightList?.length > 0 &&
          insightList.map((insight) => (
            <Grid key={insight.insight_id} mb={15} item sm={6}>
              <Insight
                key={insight.insightId}
                // get rid of insight id var in the reafctor task
                insightId={insight.insight_id}
                enableInsightAction={false}
                resourceType={RESOURCE_TYPE_INSIGHT}
                resourceId={insight.insight_id}
                shouldOpenInModalOnClick
              />
            </Grid>
          ))}
      </Grid>

      {loading && <Loading />}

      {hasNextPage && (
        <Stack ml="auto" direction="row">
          <Button
            sx={{
              color: "#1E46EF",
              fontWeight: typography.font_weight.bold,
              borderColor: "#AFBDDB",
              textTransform: "none",
            }}
            variant="outlined"
            onClick={() => setPage(page + 1)}
          >
            See More
          </Button>
        </Stack>
      )}

      {!loading && !hasNextPage && (
        <Message>{"You're all caught up here!"}</Message>
      )}
    </Stack>
  );
};

export default FilteredInsightsGrid;
