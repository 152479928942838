export const labels = {
  COLLABORATE: {
    TIME_TO_DETECT: "Time to Detect",
    TIME_TO_RESOLVE: "Time to Resolve",
    WHY_THIS_MATTERS: "Why this matters",
    AS_OF: "As of __time",
  },
  TABLE_CHART: {
    ACTUAL_VALUE: "Actual value",
    EXPECTED_KPI_VALUE: "Expected value [Min, Max]",
    KPI_VALUES_DIFFERENCE: "Difference",
    TREND: {
      OLD_RATE: "Old rate",
      NEW_RATE: "New rate",
    },
    TEMPLATE: {
      asOfTemplate: "as of __time",
      percentageTemplate: "__value%",
    },
  },
  LIFT_CALCULATION: {
    TITLE: "How we calculated Lift",
    ACTUAL: "Actual",
    EXPECTED: "Expected",
    DIFFERENCE: "Difference",
    TRAILING_SUM: "Trailing sum",
    LIFT: "Lift",
    TREND: {
      OLD_RATE: "Old Rate",
      NEW_RATE: "New Rate",
      POST_PERIOD: "Post period",
      TOTAL_DELTA_TO_DATE: "Total delta to date",
      VALUE_AT_CHANGE_POINT: "Value at change-point",
    },
  },
  SPIKES_CHART: {
    LEGENDS: {
      ACTUAL: "Actual",
      SPIKES: "Spikes",
      EXPECTED_VALUE: "Expected Value",
      EXPECTED_RANGE: "Expected Range",
      TREND: {
        CHANGE: "Trend Change",
        NEW: "New Trend",
        OLD: "Old Trend",
        PROLONGED: "Prolonged Old Trend",
      },
    },
    HOVER_INFO: {
      EXPECTED_LOW: "Expected Low",
      EXPECTED_HIGH: "Expected High",
      EXPECTED_VALUE: "Expected Value",
      SPIKE: "Spike",
      SPIKE_UP: "Spike Up",
      SPIKE_DOWN: "Spike Down",
      TREND: {
        PRE_TREND: "Old Trend",
        POST_TREND: "New Trend",
        PROLONGED_TREND: "Prolonged Old Trend",
      },
    },
    ERROR_MESSAGE: "<b>Oops, it appears we've encountered an issue.<br><br>Please click on the reset button on the control bar to get back to the chart.</b>"
  },
  PIPELINE_SCHEDULE: {
    h: "hourly",
    d: "daily",
    w: "weekly",
    m: "monthly",
    y: "yearly",
  },
  SEVERITY: {
    low: "Low",
    medium: "Medium",
    high: "High",
  },
  BUSINESS_HEALTH: {
    GOOD: "Good",
    SLOW: "Slow",
    NEUTRAL: "Neutral"
  },
};
