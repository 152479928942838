import {
  INSIGHT_IMPORTANT_REQUEST,
  INSIGHT_IMPORTANT_SUCCESS,
  INSIGHT_IMPORTANT_FAIL,
  INSIGHT_SNOOZE_REQUEST,
  INSIGHT_SNOOZE_SUCCESS,
  INSIGHT_SNOOZE_FAIL,
  INSIGHT_FLAG_REQUEST,
  INSIGHT_FLAG_SUCCESS,
  INSIGHT_FLAG_FAIL,
  INSIGHT_ARCHIVE_REQUEST,
  INSIGHT_ARCHIVE_SUCCESS,
  INSIGHT_ARCHIVE_FAIL,
  INSIGHT_RESOLVE_REQUEST,
  INSIGHT_RESOLVE_SUCCESS,
  INSIGHT_RESOLVE_FAIL,
  INSIGHT_LIST_REQUEST,
  INSIGHT_LIST_SUCCESS,
  INSIGHT_LIST_FAIL,
  INSIGHT_DETAILS_REQUEST,
  INSIGHT_DETAILS_SUCCESS,
  INSIGHT_DETAILS_FAIL,
} from "../constants/insightConstants";

export const insightListReducer = (state = {}, action) => {
  switch (action.type) {
    case INSIGHT_LIST_REQUEST:
      return { loading: true, data: action.payload };
    case INSIGHT_LIST_SUCCESS:
      return { loading: false, data: action.payload };
    case INSIGHT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const insightDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case INSIGHT_DETAILS_REQUEST:
      return { loading: true };
    case INSIGHT_DETAILS_SUCCESS:
      return { loading: false, data: action.payload };
    case INSIGHT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const insightImpReducer = (state = {}, action) => {
  switch (action.type) {
    case INSIGHT_IMPORTANT_REQUEST:
      return { loading: true };
    case INSIGHT_IMPORTANT_SUCCESS:
      return { loading: false, current_user: action.payload };
    case INSIGHT_IMPORTANT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const insightSnoozeReducer = (state = {}, action) => {
  switch (action.type) {
    case INSIGHT_SNOOZE_REQUEST:
      return { loading: true };
    case INSIGHT_SNOOZE_SUCCESS:
      return { loading: false, current_user: action.payload };
    case INSIGHT_SNOOZE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const insightFlagReducer = (state = {}, action) => {
  switch (action.type) {
    case INSIGHT_FLAG_REQUEST:
      return { loading: true };
    case INSIGHT_FLAG_SUCCESS:
      return { loading: false, current_user: action.payload };
    case INSIGHT_FLAG_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const insightArchiveReducer = (state = {}, action) => {
  switch (action.type) {
    case INSIGHT_ARCHIVE_REQUEST:
      return { loading: true };
    case INSIGHT_ARCHIVE_SUCCESS:
      return { loading: false, current_user: action.payload };
    case INSIGHT_ARCHIVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const insightResolveReducer = (state = {}, action) => {
  switch (action.type) {
    case INSIGHT_RESOLVE_REQUEST:
      return { loading: true };
    case INSIGHT_RESOLVE_SUCCESS:
      return { loading: false, current_user: action.payload };
    case INSIGHT_RESOLVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
