import ToggleButton from "@mui/material/ToggleButton";
import { withStyles } from "@mui/styles";

const styles = {
  root: {
    textTransform: "none",
    border: "none",
    backgroundColor: "transparent",
    padding: "8px 10px",
    "&$selected": {
      backgroundColor: "transparent",
      "-moz-opacity": 1 /* Netscape */,
      "-khtml-opacity": 1 /* Safari 1.x */,
      opacity: 1,
      color: "white",
      size: "large",
    },
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },
  selected: {},
};

const overlayStyles = {
  root: {
    height: "25px",
    textTransform: "none",
    padding: 10,
    "&.selected": {
      size: "large",
      width: "33%",
    },
  },
};
export const OverlayToggleButton = withStyles(overlayStyles)(ToggleButton);
export const ActionToggleButton = withStyles(styles)(ToggleButton);
