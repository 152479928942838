import styled from "@emotion/styled";
import { Box, MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";

const SelectWithoutIcon = styled(Select)(({ theme }) => ({
  width: "100%",
  maxWidth: 200,
  "& .MuiInputBase-input": {
    color: "var(--purple-fog)",
    padding: "8.5px 10.5px",
    fontSize: theme.typography.pxToRem(14),
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  width: "100%",
  background: "var(--zircon)",
}));

const StyledSelect = ({ options, defaultValue = undefined, onChange, ...delegatedProps }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || "");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (typeof onChange === "function") {
      onChange();
    }
  };
  return (
    <SelectWithoutIcon
      IconComponent={ExpandMoreIcon}
      value={selectedValue}
      onChange={handleChange}
      renderValue={(selectedOption) => {
        return <Box sx={{ display: "flex", gap: 1, flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>{selectedOption.label}</Box>;
      }}
      {...delegatedProps}
    >
      {options.map((option, key) => (
        <StyledMenuItem key={key} value={option}>
          {option.label}
        </StyledMenuItem>
      ))}
    </SelectWithoutIcon>
  );
};

export default StyledSelect;
