import React from "react";
import { Typography } from "@mui/material";

import ActionList from "./action-list/ActionList";
import { ChipContainer, StyledCard, TimeChip } from "./styled-components";
import { typography } from "../../shared/theme-constants";
import { formatDateForTimeChip } from "../../utils/dateUtils";
import LiveIndicator from "./LiveIndicator";

const DEFAULT_TIMEZONE = "Etc/UTC";

export default function Card({
  insightTime,
  fromLink,
  handleClickActionIcon,
  pipelineSchedule,
  groupId,
  insightList,
  isGroup,
  openAsModal,
  isLive,
  chipNotRequired,
  pageType,
  eventStatus,
  children,
}) {
  return (
    <>

      <ChipContainer data-snippyly-comment-disabled direction="row">
        {isLive ? (
          <LiveIndicator sx={{ fontSize: typography.font_size.xs }} />
        ) : null}
        <TimeChip sx={{
          minWidth: isLive ? "180px" : "230px",
        }}>
          <Typography
            component="span"
            variant="inherit"
            sx={{
              fontWeight: typography.font_weight.semiBold,
              textTransform: "capitalize",
            }}
          >
            {pipelineSchedule}
          </Typography>{" "}
          {formatDateForTimeChip(insightTime, pipelineSchedule, DEFAULT_TIMEZONE)}
        </TimeChip>

        {/* {!fromLink && handleClickActionIcon && (
          <ActionList
            handleClickActionIcon={handleClickActionIcon}
            groupId={groupId}
            insightList={insightList}
            isGroup={isGroup}
          />
        )} */}
      </ChipContainer>
      <StyledCard isGroup={isGroup} openAsModal={openAsModal} pageType={pageType} eventstatus={eventStatus}>
        {children}
      </StyledCard>
    </>
  );
}
