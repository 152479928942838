import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { init } from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./components/App";
import store from "./store";
import { merge } from "./utils/objects";

function tweakLocationStateOnPageLoad() {
  const usrLocationState = window.history.state?.usr;

  if (usrLocationState?.backgroundLocation) {
    window.history.replaceState(
      merge(window.history.state, { usr: { isOverlay: false } }),
      document.title
    );
  }
}

const hasWindow = typeof window !== "undefined";

if (hasWindow) {
  tweakLocationStateOnPageLoad();
}

if (process.env.REACT_APP_SENTRY_DSN) {
  // Initiate Sentry
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
