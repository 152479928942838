import { Grid, Stack } from "@mui/material";
import React from "react";
import Details from "./Details";
import Owners from "./Owners";

const MetricInfo = ({ metricDetails }) => {
  return (
    <Grid container spacing={4}>
      <Grid item sm={8}>
        <Stack direction="row">
          <Details type="Tier" value={metricDetails.tier} />
          <Details
            type="Impact"
            value={
              metricDetails.impact_to_topline
                ? `${(metricDetails.impact_to_topline * 100).toFixed(1)}%`
                : null
            }
          />
        </Stack>
      </Grid>
      {/* <Grid item sm={4}>
        <Owners
          ownerInfo={[
            { profile_img: "", type: "Metric Owner", name: "Test" },
            { profile_img: "", type: "Contact Owner", name: "Test" },
          ]}
        />
      </Grid> */}
    </Grid>
  );
};

export default MetricInfo;
