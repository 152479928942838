import {
  Box,
  Chip,
  CircularProgress,
  Container,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { typography } from "../../../src/shared/theme-constants";
import { updatePersonaDetails } from "../../actions/userOnboardingActions";
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser";
import { StyledActionButton } from "../common/mui-wrapper-components/Button";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const founderUsesOption = [
  {
    label: "Core revenue metrics",
    value: "Core revenue metrics",
    styles: {
      backgroundColor: "#F2FDF5",
      borderColor: "#D1EAD5",
      color: "#2E663B",
      deleteIconColor: "#79997E",
    },
  },
  {
    label: "High severity events",
    value: "High severity events",
    styles: {
      backgroundColor: "#FCF2F2",
      borderColor: "#F7DFDE",
      color: "#AA2E26",
      deleteIconColor: "#E39691",
    },
  },
];

const marketerUsesOption = [
  {
    label: "Key marketing metrics",
    value: "Key marketing metrics",
    styles: {
      backgroundColor: "#F9F5FE",
      borderColor: "#ECE0F8",
      color: "#7429C6",
      deleteIconColor: "#B699F1",
    },
  },
  {
    label: "Medium severity events",
    value: "Medium severity events",
    styles: {
      backgroundColor: "#FEFCEA",
      borderColor: "#F2E6C5",
      color: "#7D501F",
      deleteIconColor: "#CBB086",
    },
  },
  {
    label: "Low severity events",
    value: "High severity events",
    styles: {
      backgroundColor: "#F9FAFB",
      borderColor: "#EBEDEE",
      color: "#4D5562",
      deleteIconColor: "#A3A7AE",
    },
  },
];

const PersonalSelectionScreen = () => {
  const user = useAuthenticatedUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState(""); // State for selected role
  const [selectedUsageOptions, setSelectedUsageOptions] = useState([]);
  const [updatingTenantDetails, setUpdatingTenantDetails] = useState(false);
  const [otherUsage, setOtherUsage] = useState("");

  // Function to handle role selection
  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
    setSelectedUsageOptions([]);
    setOtherUsage("");
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedUsageOptions(typeof value === "string" ? value.split(",") : value);
  };

  const handleDeleteChip = (optionToDelete) => {
    setSelectedUsageOptions((prevOptions) =>
      prevOptions.filter((option) => option !== optionToDelete)
    );
  };

  const updatePersonaSelection = () => {
    setUpdatingTenantDetails(true);
    const postData = {
      role: selectedRole === 'Other' ? `${selectedRole}: ${otherUsage}` : selectedRole,
      objectives: selectedUsageOptions.map((item) => ({ name: item })),
    };
    dispatch(updatePersonaDetails(postData, user?.user_uuid)).then((response) => {
      setUpdatingTenantDetails(false);
      navigate(`/onboard/app_installation`);
    });;
  };

  const renderOTBUsages = () => {
    const options = selectedRole === "Founder" ? founderUsesOption : marketerUsesOption;
    return (
      <Box
        sx={{
          backgroundColor: "var(--grey-100)",
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          padding: "12px",
          marginLeft: "28px",
        }}
        gap={2}
      >
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(11),
            fontWeight: typography.font_weight.mediumx,
            color: "var(--grey-900)",
          })}
        >
          I'd like to use Out Of The Blue for
        </Typography>
        {selectedRole === "Other" ? (
          <TextField
            type="text"
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px", // Set border radius for the input field
                "& fieldset": {
                  border: "1px solid #E0E0E0", // Set border for the input field
                },
              },
            }}
            size="small"
            value={otherUsage}
            placeholder="Enter your usage"
            onChange={(event) => setOtherUsage(event.target.value)}
          />
        ) : (
          <Select
            multiple
            value={selectedUsageOptions}
            placeholder="Please select one option"
            onChange={handleSelectChange}
            input={<OutlinedInput />}
            displayEmpty
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <Typography sx={{ color: "#999999", fontSize: "13px", lineHeight: "inherit" }}>
                    Please select one option
                  </Typography>
                );
              }
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                  }}
                >
                  {selected.map((value) => {
                    const selectedValueData = options.find((item) => item.value === value);
                    const chipStyles = selectedValueData ? selectedValueData.styles : {};
                    return (
                      <Chip
                        key={value}
                        label={value}
                        clickable
                        onMouseDown={(event) => event.stopPropagation()}
                        onDelete={() => handleDeleteChip(value)}
                        chipStyles={{ ...selectedValueData.styles }}
                        sx={{
                          height: "24px",
                          padding: "6px",
                          borderRadius: "6px",
                          border: `1px solid ${chipStyles?.borderColor}`, // Default border color
                          backgroundColor: chipStyles?.backgroundColor,
                          color: chipStyles?.color,
                          fontSize: "11px",
                          "& .MuiChip-deleteIcon": {
                            fontSize: "13px", // Set border radius for the input field
                            color: chipStyles?.deleteIconColor,
                          },
                        }}
                      />
                    );
                  })}
                </Box>
              );
            }}
            sx={{
              height: selectedUsageOptions.length > 1 ? "auto" : "36px",
              overflow: "auto",
              borderRadius: "8px",
              backgroundColor: "var(--white)",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #E0E0E0",
              },
              "& .MuiSelect-icon": {
                display: "none",
              },
            }}
          >
            {options.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        )}
        <Stack style={{ display: "flex", flexDirection: "row" }} gap={2}>
          <img
            style={{ width: "14px", height: "18px", objectFit: "contain" }}
            src="/images/star-icon.png"
          />
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(11),
              fontWeight: typography.font_weight.regular,
              color: "var(--grey-700)",
            })}
          >
            Founders use Out Of The Blue to quickly spot and address forecast deviations across
            channels.
          </Typography>
        </Stack>
      </Box>
    );
  };

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "var(--grey-50)",
      }}
    >
      {/* User info box */}
      <Box
        sx={{
          position: "absolute",
          top: "16px",
          right: "16px",
          padding: "8px",
          borderRadius: "4px",
          backgroundColor: "transparent",
        }}
      >
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(12),
            fontWeight: typography.font_weight.regular,
            color: "var(--grey-600)",
          })}
        >
          Logged in as:
        </Typography>
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(13),
            fontWeight: typography.font_weight.mediumx,
            color: "var(--grey-800)",
          })}
        >
          {user?.email || "Not logged in"}
        </Typography>
      </Box>
      <Box
        elevation={3}
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "auto",
          width: "100vw",
          maxWidth: theme.typography.pxToRem(460),
          boxShadow: "none",
          backgroundColor: "transparent",
          [theme.breakpoints.up("tm")]: {
            width: `${theme.typography.pxToRem(460)} !important`, // Increase height on smaller screens
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
          }}
          gap={6}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
              width: "100%",
            }}
            gap={6}
          >
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(22),
                fontWeight: typography.font_weight.mediumx,
                color: "var(--grey-900)",
                textAlign: "center",
              })}
            >
              How are you planning to use
              <br />
              Out Of The Blue?
            </Typography>
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(12),
                fontWeight: typography.font_weight.mediumx,
                color: "var(--grey-700)",
                textAlign: "center",
              })}
            >
              Let's start with some basic info to help you get started.
            </Typography>
          </Box>
          <Paper
            elevation={3}
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "auto",
              width: "100vw",
              maxWidth: theme.typography.pxToRem(460),
              boxShadow: "0 5px 20px 0 rgba(0, 0, 0, 0.15)",
              backgroundColor: "var(--white)",
              padding: "24px",
              borderRadius: "8px",
              gap: "24px",
              [theme.breakpoints.up("tm")]: {
                width: `${theme.typography.pxToRem(460)} !important`, // Increase height on smaller screens
              },
            })}
          >
            <RadioGroup value={selectedRole} onChange={handleRoleChange}>
              <FormControlLabel
                value="Founder"
                control={<Radio />}
                label={
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(15),
                      fontWeight: typography.font_weight.regular,
                      color: "var(--grey-700)",
                    })}
                  >
                    I'm a Founder
                  </Typography>
                }
              />
              {selectedRole === "Founder" ? renderOTBUsages() : null}
              <FormControlLabel
                value="Marketer"
                control={<Radio />}
                label={
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(15),
                      fontWeight: typography.font_weight.regular,
                      color: "var(--grey-700)",
                    })}
                  >
                    I'm a Marketer
                  </Typography>
                }
              />
              {selectedRole === "Marketer" ? renderOTBUsages() : null}
              <FormControlLabel
                value="Other"
                control={<Radio />}
                label={
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(15),
                      fontWeight: typography.font_weight.regular,
                      color: "var(--grey-700)",
                    })}
                  >
                    Other
                  </Typography>
                }
              />
              {selectedRole === "Other" ? renderOTBUsages() : null}
            </RadioGroup>
          </Paper>
          <Box>
            <StyledActionButton
              sx={{
                backgroundColor: "var(--brand-blue)",
                height: "2.875rem",
                width: "7.75rem",
                minWidth: "unset",
                fontSize: "13px",
                "&.Mui-disabled": {
                  backgroundColor: "var(--grey-300)", // Customize disabled background color
                  color: "var(--grey-50)",
                  cursor: "not-allowed",
                },
                "& .MuiButton-endIcon": {
                  marginLeft: "4px",
                },
              }}
              disabled={selectedRole === "" || (!selectedUsageOptions.length && otherUsage === "")}
              onClick={updatePersonaSelection}
              endIcon={
                <ArrowForwardIcon
                  sx={{
                    fontSize: "13px !important",
                  }}
                />
              }
            >
              {/* u2192 gives you -> arrow in html */}
              {updatingTenantDetails ? (
                <CircularProgress size={13} color="inherit" />
              ) : (
                `Continue`
              )}
            </StyledActionButton>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
export default PersonalSelectionScreen;
