import { Chip } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

// Styled version of the Chip component
const StyledChip = styled(Chip)(({ theme }) => ({
  minWidth: theme.typography.pxToRem(90),
  height: theme.typography.pxToRem(23),
  borderRadius: theme.typography.pxToRem(5),
  // Override MuiChip-label class styles
  "&& .MuiChip-label": {
    paddingLeft: "0rem !important",
    paddingRight: "0rem !important",
    fontFamily: "Poppins !important",
    fontSize: "12px",
    fontWeight: 500,
  },
  "&& .MuiChip-deleteIcon": {
    margin: "0 0px 0 0.375rem",
    fontSize: '1rem',
  },
  "&& .MuiChip-icon": {
    margin: 0,
  }
}));

// Common styles for the chips
const tagStyle = {
  borderRadius: "0.25rem",
  textAlign: "center",
  paddingLeft: "0.625rem",
  paddingRight: "0.625rem",
  color: "rgba(99, 104, 124, 1)",
};

// Define the color mappings
const colorMappings = [
  {
    background: "rgba(255, 251, 229, 1)",
  },
  {
    background: "rgba(233, 243, 255, 1)",
  },
  {
    background: "rgba(249, 240, 255, 1)",
  },
  {
    background: "rgba(255, 246, 238, 1)",
  },
  {
    background: "#A9A9A9",
  },
];

const LabelChip = ({ tags, ...rest }) => {
  return tags.map((tag, index) => {
    // Calculate the color index in a cyclic manner
    const colorIndex = index % colorMappings.length;

    // Get the color style from colorMappings
    const tagColor = colorMappings[colorIndex];

    const chipStyle = {
      ...tagColor,
      ...tagStyle,
      ...(index > 0 && { marginLeft: "8px" }), // Apply margin only if not the first chip
    };

    // Render the chip with the assigned color and styles
    return (
      <StyledChip
        key={index}
        label={tag}
        sx={chipStyle}
        className="chip-tag"
        {...rest}
      />
    );
  });
};

export default LabelChip;
