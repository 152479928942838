import axios from "axios";

const url = process.env.REACT_APP_BASE_URL.concat("insights/groups");

export const getImpactDataForGroup = (id) => async () => {
  try {
    const { data } = await axios.get(`${url}/${id}/impact`);
    return data;
  } catch (error) {
    throw error;
  }
};
