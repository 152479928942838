import { createContext, useContext, useState } from "react";

export const InsightContext = createContext();

/* TODO: Below is a custom hook approach for contexts that enables children component as well
    to update the context. This approach need a little more thinking as there were some issues while implementing it

export const InsightContextToDo = createContext({
  insightContext: undefined,
  updateInsightContext: () => {},
});

export const InsightContextProvider = (props) => {
  const [insightContext, setInsightContext] = useState(undefined);

  const updateInsightContext = () => {
    setInsightContext(insightContext);
  };

  return (
    <InsightContextToDo.Provider
      value={{ insightContext, updateInsightContext }}
    >
      {props.children}
    </InsightContextToDo.Provider>
  );
};

const useInsightContext = () => {
  return useContext(InsightContextToDo);
};

export default useInsightContext;
*/
