import axios from "axios";

const url = process.env.REACT_APP_BASE_URL;

// Funtion to fetch metrics that are related to the provided `metricId`
export const getRelatedMetrics = (metricId, queryParam) => async (dispatch) => {
  try {
    const params = new URLSearchParams(queryParam);
    const response = await axios.get(
      `${url}metrics_hub/metrics/${metricId}/related_metrics`,
      { params }
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

// Funtion to compare the selected metrics
export const compareMetrics = (resourceId, queryParams) => async (dispatch) => {
  try {
    let response = null;
    const params = new URLSearchParams(queryParams);

    response = await axios.get(
      `${url}metrics_hub/metrics/${resourceId}/compare`,
      { params }
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const getDimensionValues = (dimensionId, queryParams) => async (dispatch) => {
  try {
    let response = null;
    const params = new URLSearchParams(queryParams);

    response = await axios.get(
      `${url}metrics_hub/dimensions/${dimensionId}/values`,
      { params }
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
};
