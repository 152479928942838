import React from "react";
import { Box } from "@mui/material";

const TabPanel = ({ children, currentTabIndex, index, ...otherProps }) => {
  return (
    <div
      role="tabpanel"
      hidden={currentTabIndex !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...otherProps}
    >
      {currentTabIndex === index && (
        <Box sx={{ minHeight: "500px", padding: "30px 0 100px 0" }}>
          {children}
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
