import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import styled from "styled-components";
import TextField from "@mui/material/TextField";

export const TitleField = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 1.5em;
  color: #6b6d77;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    font-size: 1.5em;
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 1.1em;
  }
`;
export const calendarBoxStyle = {
  background: "#FFFFFF",
  width: "763px",
  height: "282px",
  border: "2px solid white",
  position: "absolute",
  borderRadius: "8px 8px 8px 8px",

  "@media(min-width:820px) and (max-width:1199px)": {
    width: "770px",
    height: "300px",
  },
  "@media(min-width:769px) and (max-width:820px)": {
    width: "770px",
    height: "300px",
  },

  "@media(min-width:480px) and (max-width:768px)": {
    width: "300px",
    height: "560px",
  },
  "@media(max-width:480px)": {
    width: "300px",
    height: "560px",
  },
};

export const stackButtonStyles = {
  display: "flex",
  flexDirection: "row",
  marginBottom: "15px",
  "@media(max-width:768px)": {
    width: "300px",
    flexDirection: "column",
    marginBottom: "0px",
  },
  "@media(max-width:480px)": {
    width: "350px",
    flexDirection: "column",
    marginBottom: "0px",
  },
};

export const stackDatePickerStyles = {
  display: "flex",
  flexDirection: "row",
  marginBottom: "15px",
  marginLeft: "25px",
  width: "750px",
  "@media(max-width:480px)": {
    width: "350px",
    flexDirection: "column",
    marginBottom: "0px",
    marginLeft: "25px",
  },
  "@media(max-width:768px)": {
    width: "200px",
    flexDirection: "column",
    marginBottom: "0px",
    marginLeft: "25px",
  },
};

export const OneClickSnoozeButton = withStyles({
  root: {
    height: "50px",
    width: "350px",
    background: "#FFFFFF",
    boxShadow: "0px 6.08px 21.28px rgba(0, 0, 0, 0.15)",
    borderRadius: "6px",
    color: "#767A88",
    marginLeft: "25px",
    "@media(max-width:768px)": {
      width: "250px",
      marginBottom: "15px",
    },
    "@media(max-width:480px)": {
      width: "250px",
      marginBottom: "15px",
    },
  },
  text: {
    fontSize: "15px",
    textTransform: "none",
    "@media(max-width:768px)": {
      fontSize: "12px",
    },
    "@media(max-width:480px)": {
      fontSize: "12px",
    },
  },
})(Button);

export const SnoozeButtonText = styled.div`
  display: flex;
  flex-direction: row;
  width: 350px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: #6b6d77;

  gap: ${({ gap }) => (gap ? gap : "200px")};

  @media (max-width: ${({ theme }) => theme.tablet}) {
    width: 250px;
    gap: ${({ gap }) => (gap ? gap : "60px")};
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 250px;
    gap: ${({ gap }) => (gap ? gap : "60px")};
  }
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CalendarTitle = styled.div`
  display: flex;
  padding: 15px 20px;
  flex-direction: row;
  gap: 12px;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 80%;
  }
`;

export const SaveButton = withStyles({
  root: {
    height: "38px",
    width: "100px",
    background: "#1E46EF",
    boxShadow: "0px 6.08px 21.28px rgba(0, 0, 0, 0.15)",
    borderRadius: "6px",
    color: "#767A88",
    marginLeft: "270px",
    marginTop: "15px",

    "&:disabled": {
      background: "#6b6d77",
      color: "#383838",
    },
    "@media(min-width:769px) and (max-width:1199px)": {
      marginLeft: "270px",
    },
    "@media(min-width:480px) and (max-width:768px)": {
      marginLeft: "75px",
    },
    "@media(max-width:480px)": {
      marginLeft: "75px",
    },
  },
  text: {
    fontSize: "15px",
    textTransform: "none",
    color: "white",
  },
})(Button);

export const DateTimeTextField = withStyles({
  root: {
    width: "350px",
    "@media(max-width:768px)": {
      width: "250px",
    },
    "@media(max-width:480px)": {
      width: "250px",
    },
  },
})(TextField);
