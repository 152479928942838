import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getL3DataByResourceType } from "../actions/groupInsightActions";
import { listMetricDetails } from "../actions/metricActions";
import CollaborateComponent from "../components/collaborate/CollaborateComponent";
import CompareMetrics from "../components/collaborate/CompareMetrics";
import DimensionsBreakup from "../components/collaborate/CompareMetrics/DimensionsBreakup";
import FilterStack from "../components/collaborate/Filter/FilterStack";
import FilteredInsightsGrid from "../components/collaborate/FilteredInsightsComponent/FilteredInsightsGrid";
import Highlights from "../components/collaborate/Highlights";
import InsightHistogram from "../components/collaborate/InsightHistogram";
import InsightTitleSummaryView from "../components/common/InsightTitleSummaryView";
import TimePickerModal from "../components/common/TimePickerModal";
import ApiScreenError from "../components/common/error-components/ApiScreenError";
import { Loading } from "../components/common/styled-components";
import {
  L3Components,
  L3ComponentsContainer,
  L3ContainerMain,
} from "../components/common/styled-components/collaborate";
import {
  ChartTitleTypography,
  ContentTitle,
} from "../components/common/styled-components/collaborate/Collaborate.styled";
import Feedback from "../components/insights/insight/feedback/Feedback";
import { RESOURCE_TYPE_INSIGHT } from "../constants/commonConstants";
import { SET_COLLABORATE_TIMESTAMP } from "../constants/filterCriteriaConstants";
import { METRIC_COMPARE, PIPELINE_SCHEDULE } from "../constants/multiSelectFilterConstants";
import useBoolean from "../hooks/useBoolean";
import { PATTERN_TYPE } from "../shared/constants/pattern-types";

const resourceType = RESOURCE_TYPE_INSIGHT;

const InsightCollaborateScreen = () => {
  const dispatch = useDispatch();

  const { state } = useLocation();
  const { id } = useParams();

  // If insightId is not provided, then it's a group, hence derive the insightId from the top metric timeframes
  const [insightData, setInsightData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const globalFilter = useSelector((state)=> state.tenantGlobalFilter?.appliedFilter);
  const filterSelector = useSelector((state) => state.collaborateFilters);
  const [
    isTimeFilterOpen,
    { setTrue: openTimeFilterModal, setFalse: closeTimeFilterModal },
  ] = useBoolean();

  const  { pipelineSchedule, chartType } = useSelector((state)=> state.collaborateFilters);

  const { metric } = useSelector((state) => state.metricDetails);

  function handleTimeSelected(selectedStartEndTimestamp) {
    dispatch({
      type: SET_COLLABORATE_TIMESTAMP,
      payload: selectedStartEndTimestamp,
    });
  }

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    dispatch(getL3DataByResourceType(resourceType, id))
      .then((response) => {
        if (isMounted) {
            // Make metric :id call to get the information about the dimensions attached to the metric
            dispatch(listMetricDetails(response?.kpi_id)).then((data) => {
              // In this case, we don't to fetch the last active pipeline schedule as the data is already present in insight regarding its pipeline schedule
              // Hence set this value to the store instead
              dispatch({
                type: PIPELINE_SCHEDULE,
                payload: response.pipeline_schedule,
              });
              setInsightData(() => {
                dispatch({
                  type: SET_COLLABORATE_TIMESTAMP,
                  payload: {
                    start_time: response.chart_ts_start,
                    end_time: response.chart_ts_end,
                  },
                });
                return response;
              });
              setLoading(false);
            });
        }
      })
      .catch((error) => {
        setError(error);
      });

    window.scrollTo(0, 0);

    return () => (isMounted = false);
  }, [dispatch, id, globalFilter]);

  if (error) {
    return <ApiScreenError error={error} resourceType={resourceType} />;
  }
  if (loading) {
    return (
      <L3ContainerMain id={id}>
        <L3ComponentsContainer>
          <Loading />
        </L3ComponentsContainer>
      </L3ContainerMain>
    );
  }

  return (
    <div className="container-center-horizontal">
      {isTimeFilterOpen && (
        <TimePickerModal
          onClose={closeTimeFilterModal}
          onSelect={handleTimeSelected}
        />
      )}

      <L3ContainerMain id={id}>
        <L3ComponentsContainer>
          {insightData && (
            <L3Components>
              <CollaborateComponent
                isModal={state?.isModal}
                hasRootCause={insightData.has_rootcause}
                resourceId={id}
                data={insightData}
                resourceType={RESOURCE_TYPE_INSIGHT}
                tenantId={insightData.tenant_id}
                link={`insights/${insightData.insight_id}/collaborate?dim_name=${globalFilter?.dimension_name}&dim_val=${globalFilter?.dimension_value}`}
              />
              <InsightTitleSummaryView
                accent={insightData.accent}
                title={insightData.insight_title}
                summary={insightData.insight_summary}
                showLegend={false}
                allowEdit={false}
                insightType= {insightData?.dim_display_name ? `${insightData.dim_display_name}: ${insightData.dim_val}` : 'Overall Metric'}
                funnels= {insightData?.funnels}
              />
              {insightData.chart_description.effect && (
                <Typography color="secondary">
                  {insightData.chart_description.effect}
                </Typography>
              )}
              <FilterStack
                onTimeFilterClick={openTimeFilterModal}
                tenantId={insightData.tenant_id}
                kpiId={insightData.kpi_id}
                pipelineSchedule={pipelineSchedule}
                chartType={chartType}
                metric={metric}
                globalFilter={globalFilter}
              />
              <Box
                sx={{
                  border: "1.39px solid #C7D1EB",
                  padding: "5px 40px",
                  borderRadius: 1,
                  width: "100%"
                }}
              >
                {chartType === METRIC_COMPARE ? (
                  <CompareMetrics
                    tenantId={insightData.tenant_id}
                    kpiId={insightData.kpi_id}
                    pipelineSchedule={pipelineSchedule}
                  />
                ) : (
                  <DimensionsBreakup
                    metric={metric}
                    chartType={chartType}
                    kpiId={insightData.kpi_id}
                    pipelineSchedule={pipelineSchedule}
                  />
                )}
              </Box>
              <ChartTitleTypography>{insightData.chart_title}</ChartTitleTypography>
              <Highlights
                actualValue={insightData.formatted_current_value}
                expectedValue={insightData.formatted_expected_value}
                difference={insightData.formatted_difference_value}
                minExpectedValue={insightData.pattern_data.formatted_yhat_lower}
                maxExpectedValue={insightData.pattern_data.formatted_yhat_upper}
                chartDescription={insightData.chart_description}
                isTrend={insightData.pattern_type === PATTERN_TYPE.TREND_CHANGE}
                trendUnit={insightData.pattern_data.trend_unit}
              />
              <ContentTitle>Insight History</ContentTitle>
              <InsightHistogram
                tenantId={insightData.tenant_id}
                pipelineSchedule={pipelineSchedule}
                dimensionNames= {filterSelector?.selectedDimensionNames}
                dimensionValues={filterSelector?.selectedDimensionValues}
              />
              <FilteredInsightsGrid
                tenantId={insightData.tenant_id}
                pipelineSchedule={pipelineSchedule}
              />
              <Feedback resourceId={id} resourceType={resourceType} />
            </L3Components>
          ) }
        </L3ComponentsContainer>
      </L3ContainerMain>
    </div>
  );
};

export default InsightCollaborateScreen;
