import {
  differenceInCalendarDays,
  intervalToDuration,
  subMinutes,
  format,
  parseISO,
  formatRelative,
  isWithinInterval,
  sub,
} from "date-fns";
import { utcToZonedTime, format as formatTZ } from "date-fns-tz";
import { enUS } from "date-fns/locale";
import moment from "moment-timezone";
import { getNumberWithOrdinal } from "./numberUtils";

// Constants
const formatRelativeLocale = {
  lastWeek: "eeee, LLL do",
  yesterday: "'Yesterday'",
  today: "'Today'",
  other: "eeee, LLL do",
};
const DEFAULT_TIMEZONE = "Etc/UTC";
const locale = {
  ...enUS,
  formatRelative: (token) => formatRelativeLocale[token],
};

/**
 * Converts the date and time parts with string parse.
 * Doesn't consider the local time zone, rather keep the provided time zone as is
 * @param {*} dateString date in the string format "yyyy-mm-ddT00:00:000"
 * @returns all date and time parts; { year, month, monthName, day, hours, minutes, ampm, hours24 }
 */
export const datePartsWithTZ = (dateString) => {
  if (!dateString) return;
  // TODO: throw new InvalidInput("datePartsWithTZ: Input string is null or empty!");
  const datePatternISO =
    /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
  if (!datePatternISO.test(dateString)) return;
  // TODO: throw new InvalidInput("datePartsWithTZ: Date string is not a valid ISO string");

  const datetime = dateString.split("T");
  const dateParts = datetime[0].split("-");
  const timeParts = datetime[1].split(":");

  const year = dateParts[0];

  const day = dateParts[2];
  const hh = timeParts[0];
  let hours = hh;
  const minutes = timeParts[1];
  let ampm = "AM";

  if (hh > 12) {
    hours = hh - 12;
    hours = `0${hours}`.slice(-2); // Add a leading zero
    ampm = "PM";
  } else if (hh == 12) {
    hours = 12;
    ampm = "PM";
  } else if (hh === "00") {
    hours = 12;
  }
  const mon = `0${dateParts[1]}`.slice(-2);
  // Subtracting 1 from the month below, because months are zero based in the `Date.toLocaleString()` function
  // If the `dateString` is `2022-01-27T00:00:00.000Z`, then it will wrongly translates to February
  // in the `Date.toLocaleString()` function, hence we need to subtract 1 from it.
  const month = Number(mon) - 1;
  const date = new Date(year, month, day, hh, minutes);
  const monthName = date.toLocaleString("en-US", { month: "short" });

  return {
    year,
    mon,
    month,
    monthName,
    day,
    hours,
    minutes,
    ampm,
    hours24: hh,
  };
};

// Calculate Difference in Milliseconds
const differenceInMilliseconds = (date1, date2) => {
  const diffInMs = Math.abs(date1 - date2);
  return diffInMs;
};

// Calculate time in Minutes. If the value is null the return -
export const calculateTimeInMinutes = (timeInMs) => {
  if (timeInMs) {
    const duration = intervalToDuration({ start: 0, end: timeInMs });
    if (duration.months) {
      return duration.months + " months";
    }
    if (duration.days) {
      return duration.days + " days";
    }
    if (duration.hours) {
      return duration.hours + " hours";
    }
    if (duration.minutes) {
      return duration.minutes + " mins";
    }
  } else {
    return "-";
  }
};

// Formats the Date as per pipeline schedule to be shown on Time chip card above
export const formatDateForTimeChip = (time_stamp, pipeline_schedule, timezone, pattern = "ddd, DD MMM, YYYY") => {
  if (!time_stamp) return;

  if (pipeline_schedule === 'hourly') {
    pattern = "ddd, DD MMM, yyyy hh:mm A z";
  }

  let timeChipTime = moment.tz(time_stamp, DEFAULT_TIMEZONE).tz(timezone).format(pattern);

  // Return the formatted date
  return timeChipTime;
};

/**
 * @param {string|Date} endTimestamp
 * @param {string|Date} startTimestamp
 *
 * @returns {number} Number of days between start and end date
 */
export function getDifferenceInDays(endTimestamp, startTimestamp) {
  const endDate =
    typeof endTimestamp === "string" ? new Date(endTimestamp) : endTimestamp;
  const startDate =
    typeof startTimestamp === "string"
      ? new Date(startTimestamp)
      : startTimestamp;

  return differenceInCalendarDays(endDate, startDate);
}

function subOffsetMinutes(date) {
  return subMinutes(date, date.getTimezoneOffset());
}

/**
 * Returns the UTC equivalent of a given date.
 *
 * @param {Date} date - The date to convert to UTC.
 * @returns {Date} The UTC equivalent of the given date.
 */
export function localToUTC(date) {
  if (typeof date === "string") {
    date = parseISO(date);
  }
  return utcToZonedTime(date, "UTC");
}

export function formatDate(date, pattern = "dd MMM, yyyy") {
  if (!date) return date;

  return format(new Date(date), pattern);
}

export function formatDateTime(date, pattern = "dd MMM, yyyy HH:mm") {
  if (!date) return date;

  return format(new Date(date), pattern);
}

export function formatDateUtc(date, pattern = "dd MMM, yyyy") {
  if (!date) return date;

  const zonedTime = utcToZonedTime(date, "UTC");
  return formatTZ(zonedTime, pattern, { timeZone: "UTC" });
}

export function formatDateTimeUtc(date, pattern = "dd MMM, yyyy HH:mm") {
  if (!date) return date;

  const zonedTime = utcToZonedTime(date, "UTC");
  return formatTZ(zonedTime, pattern, { timeZone: "UTC" });
}

/**
 * Returns the relative time string for a given date.
 * @param (string) date1 - The date to compare with.
 * @param (string) date2 - The date to compare to.
 * @returns {string} The relative time string.
 */
export function getRelativeTimestringUtc(date1, date2) {
  return formatRelative(
    parseISO(date1),
    parseISO(date2),
    {
      timeZone: "UTC",
      locale: locale,
    }
  );
}

export function generateDifferenceInTimeString(date) {
  // Generate the difference in time string as below
  // 1. If the difference is less than 1 minute, then show "Just now"
  // 2. If the difference is less than 1 hour, then show "X minutes ago"
  // 3. If the difference is less than 1 day, then show "X hours ago"
  // 4. If the difference is less than 1 week, then show "X days ago"
  // 5. If the difference is less than 1 month, then show "X weeks ago"
  // 6. If the difference is less than 1 year, then show "X months ago"
  // 7. If the difference is more than 1 year, then show "dd MMM, yyy HH:MM"

  const differenceInTime = differenceInMilliseconds(new Date(), date);
  const differenceInMinutes = differenceInTime / 1000 / 60;
  const differenceInHours = differenceInMinutes / 60;
  const differenceInDays = differenceInHours / 24;
  const differenceInWeeks = differenceInDays / 7;
  const differenceInMonths = differenceInDays / 30;
  const differenceInYears = differenceInDays / 365;

  if (differenceInMinutes < 1) {
    return "Just now";
  }

  if (differenceInHours < 1) {
    return `${Math.round(differenceInMinutes)} ${Math.round(differenceInMinutes) == 1 ? 'minute' : 'minutes'} ago`;
  }

  if (differenceInDays < 1) {
    const hours = Math.floor(differenceInHours);
    return `${hours} ${hours == 1 ? 'hour' : 'hours'} ago`;
  }

  if (differenceInWeeks < 1) {
    return `${Math.round(differenceInDays)} ${Math.round(differenceInDays) == 1 ? 'day' : 'days'} ago`;
  }

  if (differenceInMonths < 1) {
    return `${Math.round(differenceInWeeks)} ${Math.round(differenceInWeeks) == 1 ? 'week' : 'weeks'} ago`;
  }

  if (differenceInYears < 1) {
    return `${Math.round(differenceInMonths)} ${Math.round(differenceInMonths) == 1 ? 'month' : 'months'} ago`;
  }

  return formatDateTimeUtc(date);
}

export function formatDatePerFrequency(date, frequency, timezone) {
  switch (frequency) {
    case 'h':
      return moment(date).tz(timezone).format('hh A');
    case 'd':
      return moment(date).tz(timezone).format('DD MMM');
    case 'w':
      return moment(date).tz(timezone).format('DD MMM');
    case 'm':
      return moment(date).tz(timezone).format('MMM YYYY');
    case 'q':
      return moment(date).tz(timezone).format('MMM YYYY');
    default:
      return moment(date).tz(timezone).format('DD MMM');
  }
}

// This function will generate a formatted date as follows
// In case of current Week/month/Quarter It will generate formatted date string for Week to Date/ Month to Date / Quarter to Date
// E.g current Month = July , current date 9th July, the the Monthly formmatted date witll be (1 Jul - 9 Jul)
// In other cases it will be "previous" month/week/quarter start and end dates
export function getFormattedTimePeriod(startDate, endDate, frequency) {
  if (frequency === "week") {
    if (isWithinInterval(localToUTC(new Date()), { start: startDate, end: endDate })) {
      return `Current Week (${startDate.getDate()} ${format(startDate, "LLL")} - ${(new Date()).getDate()} ${format(startDate, "LLL")})`;
    } else {
      return `Previous Week (${startDate.getDate()} ${format(startDate, "LLL")} - ${endDate.getDate()} ${format(endDate, "LLL")})`;
    }
  } else if (frequency === "month") {
    if (isWithinInterval(localToUTC(new Date()), { start: startDate, end: endDate })) {
      return`Current Month (${startDate.getDate()} ${format(startDate, "LLL")} - ${(new Date()).getDate()} ${format(startDate, "LLL")})`;
    } else {
      return `Previous Month (${startDate.getDate()} ${format(startDate, "LLL")} - ${endDate.getDate()} ${format(endDate, "LLL")})`;
    }
  } else if (frequency === "quarter") {
    if (isWithinInterval(localToUTC(new Date()), { start: startDate, end: endDate })) {
      return `Current Quarter (${startDate.getDate()} ${format(startDate, "LLL")} - ${(new Date()).getDate()} ${format(startDate, "LLL")})`;
    } else {
      return `Previous Quarter (${startDate.getDate()} ${format(startDate, "LLL")} - ${endDate.getDate()} ${format(endDate, "LLL")})`;
    }
  }
}