import TagManager from "react-gtm-module";

export const GTAG_EVENTS = {
  CARD_EXPANDED: "card_expanded",
  CARD_DISPLAYED: "card_displayed",
  DOWNLOAD_CSV_CLICKED: "download_csv_clicked",
  DOWNLOAD_GROUP_CSV_CLICKED: "download_group_csv_clicked",
  DOWNLOAD_IMAGE_CLICKED: "download_image_clicked",
  DOWNLOAD_PDF_CLICKED: "download_pdf_clicked",
  DOWNLOAD_GROUP_INSIGHT_CLICKED: "download_group_insight_clicked",
  DOWNLOAD_INSIGHT_CLICKED: "download_insight_clicked",
  EMOJI_CLICKED: "emoji_clicked",
  EVENT_HISTORY_VIEWED: "event_history_viewed",
  FEEDBACK_DROPDOWN_SELECTED: "feedback_dropdown_selected",
  FOLLOW_SHARE_LINK: "follow_share_link",
  GROUP_CARD_DISPLAYED: "group_card_displayed",
  GROUP_CARD_EXPANDED: "group_card_expanded",
  GROUP_EVENT_HISTORY_VIEWED: "group_event_history_viewed",
  GROUP_IMPACT_TABLE_VIEWED: "group_impact_table_viewed",
  /** State action for grouped insight clicked */
  GROUP_INSIGHT_STATE_ACTIONS: "group_insight_state_actions",
  /** Grouped caraousel clicked to view the table */
  GROUP_TABLE_VIEWED: "group_table_viewed",
  /** Metric hyperlink in the table clicked */
  GROUP_TABLE_METRIC_CLICKED: "group_table_metric_clicked",
  GROUP_SHARE_LINK_CLICKED: "group_share_link_clicked",
  INSIGHT_STATE_ACTIONS: "insight_state_actions",
  LEVEL3_VIEWED: "level3_viewed",
  METRIC_COMPARED: "metric_compared",
  MENU_ITEM_SELECTED: "menu_item_selected",
  /** Metric table expanded */
  METRIC_TABLE_EXPANDED: "metric_table_expanded",
  OVERLAYS_TABLE_EXPANDED: "overlays_table_expanded",
  OVERLAY_VIEWED: "overlay_viewed",
  ROOTCAUSE_VIEWED: "rootcause_viewed",
  SHARE_LINK_CLICKED: "share_link_clicked",
  SUMMARY_EDITED: "summary_edited",
  TIMESERIES_TABLE_EXPANDED: "timeseries_table_expanded",
  TITLE_EDITED: "title_edited",
  ZOOM: "zoom",
  /** Login Screen */
  USER_LOGIN_FAILED: "user_login_failed",
  USER_LOGIN_SUCCESS: "user_login_success",
  GET_IN_TOUCH: "get_in_touch",
};

export const initializeGTM = (gtmId) => {
  const gtmArgs = {
    gtmId: gtmId,
  };
  TagManager.initialize(gtmArgs);
};
/**
 * Sends the provided data to the configured Google Tag Manager
 * @param {*} eventName name of the custom event that will appear in the GTM
 * @param {*} data data to be sent to Google Analytics via GTM
 */
export const sendDataToGTM = (eventName, data) => {
  const gtmArgs = {
    dataLayer: {
      event: eventName,
      event_datetime: new Date().toISOString(),
      ...data,
    },
  };
  TagManager.dataLayer(gtmArgs);
};
