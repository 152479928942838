import styled from "@emotion/styled";
import DoneIcon from "@mui/icons-material/Done";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LowPriorityRoundedIcon from "@mui/icons-material/LowPriorityRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import {
  Box,
  Divider,
  Drawer,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  outlinedInputClasses,
} from "@mui/material";
import { inputBaseClasses } from "@mui/material/InputBase";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getNotificationPreferences,
  updateProfileDetails,
  updateUserSubscriptions,
} from "../../actions/preferenceActions";
import { getUserProfileDetails } from "../../actions/userActions";
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser";
import { useAxios } from "../../hooks/useAxios";
import useBoolean from "../../hooks/useBoolean";
import { useSelectedTenant } from "../../hooks/useSelectedTenant";
import { HTTP_STATUS } from "../../shared/enums/http-status";
import { typography } from "../../shared/theme-constants";
import { isEmpty } from "../../utils/is";
import EventProviderDrawer from "../common/EventProviderDrawer";
import PhoneNumberInput from "../common/PhoneNumberInput";
import { StyledActionButton } from "../common/mui-wrapper-components/Button";
import StyledTabs from "../common/mui-wrapper-components/StyledTabs";
import { ToggleGroup } from "../common/mui-wrapper-components/StyledToggleGroup";
import { Loading } from "../common/styled-components";

const NOTIFICATION_PREF_TABS = [{ value: "notifications", label: "Notifications" }];

const EMAIL_CHANNEL = "email";
const SMS_CHANNEL = "sms";
const SLACK_CHANNEL = "slack";

const HIGH_SEVERITY = "High";
const LOW_SEVERITY = "Low";
const MEDIUM_SEVERITY = "Medium";

const DRAWER_CLOSE_REASONS = ["escapeKeyDown", "backdropClick"];

const CHANNEL_OPTIONS = [
  { value: true, label: "ON" },
  { value: false, label: "OFF" },
];

const StyledTextField = styled(TextField)(({ theme }) => ({
  background: "var(--lavender)",
  borderRadius: 6,
  width: "25vw",
  [`& .${inputBaseClasses.input}`]: {
    color: "var(--resolution-blue)",
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: "none",
    borderRadius: 6,
  },
  // This removes the Up/down Arrow key from the text number field
  "& input[type=number]": {
    "-moz-appearance": "textfield",
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

const NotificationPreferences = () => {
  const selectedTenant = useSelectedTenant();
  const [highSeverity, setHighSeverity] = useState(false);
  const [mediumSeverity, setMediumSeverity] = useState(false);
  const [lowSeverity, setLowSeverity] = useState(false);
  const [emailOption, setEmailOption] = useState(false);
  const [smsOption, setSmsOption] = useState(false);
  const [slackOption, setSlackOption] = useState(false);
  const [monetaryImpactValue, setMonetaryImpactValue] = useState();
  const [newMonetaryImpactValue, setNewMonetaryImpactValue] = useState();
  const [eventSeverityPreferences, setEventSeverityPreferences] = useState();
  const [notificationChannelPreferences, setNotificationChannelPreferences] = useState();
  const [loading, setLoading] = useState(true);
  const [originalSubscriptions, setOriginalSubscriptions] = useState();
  const [severityInfoIconClick, setSeverityInfoIconClick] = useState();
  const [
    isMonetaryImpactSaveEnabled,
    { setTrue: enableSaveMonetaryImpact, setFalse: disableSaveMonetaryImpact },
  ] = useBoolean(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [isDrawerOpen, { setTrue: openDrawer, setFalse: closeDrawer }] = useBoolean(false);
  const tenantConfigs = useSelector((state) => state?.tenantConfigs?.configs);
  const [
    isEventProviderDrawerOpen,
    { setTrue: openEventProviderDrawer, setFalse: closeEventProviderDrawer },
  ] = useBoolean(false);
  const currentUser = useAuthenticatedUser();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { data: eventMappings, status: eventMappingStatus } = useAxios(
    `/preferences/events/mapping`,
    {
      params: {
        tenant_id: selectedTenant,
      },
    }
  );

  useEffect(() => {
    let isMounted = true;
    dispatch(getNotificationPreferences(currentUser))
      .then((response) => {
        if (isMounted) {
          // Set the email and sms options values
          // We need to create 2 new different objects to avoid situation where we don't update each other references further
          setOriginalSubscriptions(response);
          // get Channels
          let channels = [...response.channels];
          if (!isEmpty(channels)) {
            channels.forEach((channel) => {
              switch (channel.name) {
                case EMAIL_CHANNEL:
                  channel.is_active
                    ? setEmailOption(channel.is_active)
                    : setEmailOption(channel.is_active);
                  return;
                case SMS_CHANNEL:
                  channel.is_active
                    ? setSmsOption(CHANNEL_OPTIONS[0].value)
                    : setSmsOption(CHANNEL_OPTIONS[1].value);
                  return;
                case SLACK_CHANNEL:
                  channel.is_active
                    ? setSlackOption(CHANNEL_OPTIONS[0].value)
                    : setSlackOption(CHANNEL_OPTIONS[1].value);
                  return;
              }
            });
            setNotificationChannelPreferences([...channels]);
          }

          // get event type preferences
          let eventPreference = response?.preferences.find(
            (preference) => preference.notification_type === "event"
          );
          let preferences = [];
          // sanity check
          preferences = Array.isArray(eventPreference.filters?.severity)
            ? [...eventPreference.filters?.severity]
            : [];
          if (!isEmpty(preferences)) {
            preferences.forEach((preference) => {
              switch (preference.type) {
                case HIGH_SEVERITY:
                  setHighSeverity(preference.is_active);
                  return;
                case MEDIUM_SEVERITY:
                  setMediumSeverity(preference.is_active);
                  return;
                case LOW_SEVERITY:
                  setLowSeverity(preference.is_active);
                  return;
              }
            });
          }
          setEventSeverityPreferences(Object.assign({}, eventPreference));

          // get the Dollary impact value
          let impactValue = eventPreference?.filters.monetary_impact_threshold;
          setMonetaryImpactValue(
            impactValue === null || impactValue === undefined ? 0 : impactValue
          );
          setNewMonetaryImpactValue(
            impactValue === null || impactValue === undefined ? 0 : impactValue
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error?.status === HTTP_STATUS.UNAUTHORIZED) {
          navigate("/login");
        }
      });
    return () => (isMounted = false);
  }, [dispatch, currentUser, navigate]);

  const handleChannelOptionChange = (event, value, clickedChannel) => {
    // declare Patch Payload to savechannel preferences
    let channelsPayload = { channels: [] };
    let findMatchingChannel = notificationChannelPreferences?.find(
      (pref) => pref.name === clickedChannel
    );

    // In order to update the field, We need to create a new object and update the field of new one.
    // Else it will update the reference of the Old one
    let channelToUpdate = findMatchingChannel ? Object.assign({}, findMatchingChannel) : {};
    // mark is_active equal to the value
    channelToUpdate.is_active = value;

    channelsPayload.channels.push(channelToUpdate);
    // Set the value of the toggle button
    switch (clickedChannel) {
      case SMS_CHANNEL:
        // While switching on
        // We first need to check if User already has phone number registered with us
        // for that we first need to get progile details of the user
        // If no phone number is set then we open up the side bar and let user fill in the details
        if (value === true) {
          dispatch(getUserProfileDetails()).then((response) => {
            if (response?.data?.phone_number === null) {
              openDrawer();
            } else {
              dispatch(updateUserSubscriptions(currentUser, channelsPayload))
                .then((suubscriptionsResponse) => {
                  setSmsOption(value);
                  setNotificationChannelPreferences([...suubscriptionsResponse?.data?.channels]);
                })
                .catch(() => {
                  setSmsOption((prev) => prev);
                });
            }
          });
        } else {
          dispatch(updateUserSubscriptions(currentUser, channelsPayload))
            .then((response) => {
              setSmsOption(value);
              setNotificationChannelPreferences([...response?.data?.channels]);
            })
            .catch(() => {
              setSmsOption((prev) => prev);
            });
        }
        return;
    }

    // In other cases we simply need to make an api call to the back with the payload generated
    dispatch(updateUserSubscriptions(currentUser, channelsPayload))
      .then((response) => {
        switch (clickedChannel) {
          case EMAIL_CHANNEL:
            setEmailOption(value);
            break;
          case SLACK_CHANNEL:
            setSlackOption(value);
            break;
        }
        setNotificationChannelPreferences([...response?.data?.channels]);
      })
      .catch(() => {
        switch (clickedChannel) {
          case EMAIL_CHANNEL:
            setEmailOption((prev) => prev);
            return;
          case SLACK_CHANNEL:
            setSlackOption((prev) => prev);
            return;
        }
      });
  };

  const handleMonetaryImpactValueChange = (event) => {
    // check if the previously saved threshold value is same as new one
    // if so then do not enable the save thresold value button because the values will be the same
    // else enable
    let newMonetaryImpactValue = event.target.value ? parseInt(event.target.value) : 0;
    if (newMonetaryImpactValue === monetaryImpactValue) {
      disableSaveMonetaryImpact();
      setNewMonetaryImpactValue(monetaryImpactValue);
    } else if (Number.isFinite(newMonetaryImpactValue)) {
      setNewMonetaryImpactValue(newMonetaryImpactValue);
      enableSaveMonetaryImpact();
    }
  };

  const handleClosePhoneInputDrawer = (event, reason) => {
    // Don't close the drawer if it is backdrop click
    if (!DRAWER_CLOSE_REASONS.includes(reason)) {
      closeDrawer();
      setSmsOption((prev) => prev);
    }
  };
  const handlePhoneInputSave = (event) => {
    const postData = {
      phone_number: phoneNumber,
    };
    dispatch(updateProfileDetails(postData)).then((response) => {
      if (response.success) {
        closeDrawer();
        handleChannelOptionChange(null, true, SMS_CHANNEL);
      }
    });
  };

  // In this case when user inputs the phone number and discards it without saving then we need to switch off the sms notification
  const handleDiscardPhoneNumberInput = () => {
    // Discard the phone Input which was not saved
    setPhoneNumber();
    // Switch off the SMS channel
    handleChannelOptionChange(null, false, SMS_CHANNEL);
    // Close drawer
    closeDrawer();
  };

  const handlePreferenceOptionChange = (_event, newValue, clickedPreference) => {
    let updatedPreference = {};
    updatedPreference.preferences = [];
    updatedPreference.preferences.push(Object.assign({}, eventSeverityPreferences));
    // in order to create the filters array from scratch
    updatedPreference.preferences[0].filters = {};
    updatedPreference.preferences[0].filters.severity = [];
    switch (clickedPreference) {
      case HIGH_SEVERITY:
        updatedPreference.preferences[0].filters.severity.push({
          type: HIGH_SEVERITY,
          is_active: newValue,
        });
        break;
      case MEDIUM_SEVERITY:
        updatedPreference.preferences[0].filters.severity.push({
          type: MEDIUM_SEVERITY,
          is_active: newValue,
        });
        break;
      case LOW_SEVERITY:
        updatedPreference.preferences[0].filters.severity.push({
          type: LOW_SEVERITY,
          is_active: newValue,
        });
        break;
    }
    // Dispatch
    dispatch(updateUserSubscriptions(currentUser, updatedPreference))
      .then((response) => {
        // get event type preferences
        // below step is required for the initial save when Id of the preference is created for the first later
        // Hence need to reset the eventPreference Obj to the new one with Newly Created Id
        let eventPreference = response?.data?.preferences.find(
          (preference) => preference.notification_type === "event"
        );
        setEventSeverityPreferences(Object.assign({}, eventPreference));
        switch (clickedPreference) {
          case HIGH_SEVERITY:
            setHighSeverity(newValue);
            return;
          case MEDIUM_SEVERITY:
            setMediumSeverity(newValue);
            return;
          case LOW_SEVERITY:
            setLowSeverity(newValue);
            return;
        }
      })
      .catch((error) => {
        switch (clickedPreference) {
          case HIGH_SEVERITY:
            setHighSeverity((prev) => prev);
            return;
          case MEDIUM_SEVERITY:
            setMediumSeverity((prev) => prev);
            return;
          case LOW_SEVERITY:
            setLowSeverity((prev) => prev);
            return;
        }
      });
  };

  // Save the monetary Impact and hide the save button post successful submit
  const saveMonetaryImpact = () => {
    let updatedPreference = {};
    updatedPreference.preferences = [];
    updatedPreference.preferences.push(Object.assign({}, eventSeverityPreferences));
    // in order to create the filters array from scratch
    updatedPreference.preferences[0].filters = Object.assign({}, {});
    updatedPreference.preferences[0].filters.monetary_impact_threshold = newMonetaryImpactValue;

    dispatch(updateUserSubscriptions(currentUser, updatedPreference))
      .then((response) => {
        // get event type preferences
        // below step is required for the initial save when Id of the preference is created for the first later
        // Hence need to reset the eventPreference Obj to the new one with Newly Created Id
        let eventPreference = response?.data?.preferences.find(
          (preference) => preference.notification_type === "event"
        );
        setEventSeverityPreferences(Object.assign({}, eventPreference));
        disableSaveMonetaryImpact();
        setMonetaryImpactValue(newMonetaryImpactValue);
      })
      .catch(() => {
        enableSaveMonetaryImpact();
      });
  };

  const encodeSlackAuthCallbackURI = () => {
    // Form the required redirect url
    let redirectURL = `${process.env.REACT_APP_BASE_URL}auth/slack/callback`;

    // Encode url
    let encodedURL = encodeURIComponent(redirectURL);

    // Formulate slack OAuth url
    let slackOAuthUrl = `${process.env.REACT_APP_BASE_URL}auth/slack?redirect_uri=${encodedURL}`;

    return slackOAuthUrl;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Stack
        direction="column"
        gap={5}
        sx={{
          height: "85vh",
          alignItems: "flex-start",
          pl: 8,
          pr: 8,
          overflowY: "scroll",
        }}
      >
        <Box>
          <StyledTabs
            sx={{ padding: "10px 0" }}
            value={NOTIFICATION_PREF_TABS[0].value}
            options={NOTIFICATION_PREF_TABS}
            // onChange={handleOptionsChange}
          />
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(14),
            })}
          >
            Configure which Event alerts you receive and over what channels
          </Typography>
        </Box>

        <Stack direction="column" gap={3} sx={{ alignItems: "flex-start", width: "100%" }}>
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(18),
              fontWeight: typography.font_weight.mediumx,
            })}
          >
            Receive notification of the events that are
          </Typography>
          <Stack direction="column" width="100%" gap={3}>
            <Stack direction="row" justifyContent="space-between">
              <Stack gap={2} direction="column" sx={{ justifyContent: "space-between" }}>
                <Stack direction="row" gap={3} alignItems="center">
                  <PriorityHighRoundedIcon style={{ color: "var(--red-3)" }} />
                  <Typography
                    sx={(theme) => ({
                      fontWeight: typography.font_weight.mediumx,
                    })}
                  >
                    High Impact
                  </Typography>
                </Stack>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                  })}
                >
                  Direct business impact; requires intervention.
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <ToggleGroup
                  value={highSeverity}
                  sx={{ height: 30 }}
                  options={CHANNEL_OPTIONS}
                  onChange={(event, value) =>
                    handlePreferenceOptionChange(event, value, HIGH_SEVERITY)
                  }
                />
                <InfoOutlinedIcon
                  sx={{
                    ml: 1.5,
                    ":hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setSeverityInfoIconClick("High");
                    openEventProviderDrawer();
                  }}
                  fontSize="medium"
                  color="var(--resolution-blue)"
                />
              </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="middle" flexItem />
          </Stack>
          <Stack direction="column" width="100%" gap={3}>
            <Stack direction="row" justifyContent="space-between">
              <Stack gap={2} direction="column" sx={{ justifyContent: "space-between" }}>
                <Stack direction="row" gap={3} alignItems="center">
                  <WarningAmberRoundedIcon style={{ color: "CC6700" }} />
                  <Typography
                    sx={(theme) => ({
                      fontWeight: typography.font_weight.mediumx,
                    })}
                  >
                    Medium Impact
                  </Typography>
                </Stack>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                  })}
                >
                  Potential disruptions; may need intervention.
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <ToggleGroup
                  value={mediumSeverity}
                  sx={{ height: 30 }}
                  options={CHANNEL_OPTIONS}
                  onChange={(event, value) =>
                    handlePreferenceOptionChange(event, value, MEDIUM_SEVERITY)
                  }
                />
                <InfoOutlinedIcon
                  sx={{
                    ml: 1.5,
                    ":hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setSeverityInfoIconClick("Medium");
                    openEventProviderDrawer();
                  }}
                  fontSize="medium"
                  color="var(--resolution-blue)"
                />
              </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="middle" flexItem />
          </Stack>
          <Stack direction="column" width="100%" gap={3}>
            <Stack direction="row" justifyContent="space-between">
              <Stack gap={2} direction="column" sx={{ justifyContent: "space-between" }}>
                <Stack direction="row" gap={3} alignItems="center">
                  <LowPriorityRoundedIcon style={{ color: "var(--lime-green)" }} />
                  <Typography
                    sx={(theme) => ({
                      fontWeight: typography.font_weight.mediumx,
                    })}
                  >
                    Low Impact
                  </Typography>
                </Stack>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                  })}
                >
                  No business impact; often no action needed.
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <ToggleGroup
                  value={lowSeverity}
                  sx={{ height: 30 }}
                  options={CHANNEL_OPTIONS}
                  onChange={(event, value) =>
                    handlePreferenceOptionChange(event, value, LOW_SEVERITY)
                  }
                />
                <InfoOutlinedIcon
                  sx={{
                    ml: 1.5,
                    ":hover": {
                      cursor: "pointer",
                    },
                  }}
                  fontSize="medium"
                  onClick={() => {
                    setSeverityInfoIconClick("Low");
                    openEventProviderDrawer();
                  }}
                  color="var(--resolution-blue)"
                />
              </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="middle" flexItem />
          </Stack>
        </Stack>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
          gap={3}
        >
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(18),
              fontWeight: typography.font_weight.mediumx,
            })}
          >
            Minimum dollar impact to capture
          </Typography>
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(12),
            })}
          >
            Please enter the minimum worth of the event in dollar value that you’d like to get
            notifications on.
          </Typography>
          <Stack direction="row" gap={2}>
            <StyledTextField
              value={newMonetaryImpactValue || ""}
              type="number"
              size="small"
              inputProps={{ min: 0 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography sx={{ color: "var(--resolution-blue)" }}>
                      {tenantConfigs?.currency_symbol}
                    </Typography>
                  </InputAdornment>
                ),
              }}
              onChange={handleMonetaryImpactValueChange}
            />
            {isMonetaryImpactSaveEnabled && (
              <StyledActionButton
                onClick={saveMonetaryImpact}
                sx={(theme) => ({
                  height: "100%",
                  minWidth: 10,
                  width: theme.typography.pxToRem(35),
                })}
              >
                <DoneIcon fontSize="medium" color="var(--white)" />
              </StyledActionButton>
            )}
          </Stack>
        </Box>
        <Box
          gap={3}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(18),
              fontWeight: typography.font_weight.mediumx,
            })}
          >
            Alert Channels
          </Typography>
          <Stack direction="column" gap={3} sx={{ width: "100%" }}>
            {originalSubscriptions?.channels?.some(
              (channel) => channel.name == EMAIL_CHANNEL && channel.is_verified
            ) ? (
              <React.Fragment>
                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                  <Stack direction="row" gap={3} alignItems="center">
                    <img src={`images/email.png`} width={20} height={20} />
                    Email
                  </Stack>
                  <ToggleGroup
                    value={emailOption}
                    sx={{ height: 30 }}
                    options={CHANNEL_OPTIONS}
                    onChange={(event, value) =>
                      handleChannelOptionChange(event, value, EMAIL_CHANNEL)
                    }
                  />
                </Stack>
                <Divider orientation="horizontal" variant="middle" flexItem />
              </React.Fragment>
            ) : null}
            {originalSubscriptions?.channels?.some(
              (channel) => channel.name == SMS_CHANNEL && channel.is_verified
            ) ? (
              <React.Fragment>
                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                  <Stack direction="row" gap={3} alignItems="center">
                    <img src={`images/sms.png`} width={20} height={20} />
                    Sms
                  </Stack>
                  <ToggleGroup
                    value={smsOption}
                    sx={{ height: 30 }}
                    options={CHANNEL_OPTIONS}
                    onChange={(event, value) =>
                      handleChannelOptionChange(event, value, SMS_CHANNEL)
                    }
                  />
                </Stack>
                <Divider orientation="horizontal" variant="middle" flexItem />
              </React.Fragment>
            ) : null}
            {originalSubscriptions?.channels?.some(
              (channel) => channel.name == SLACK_CHANNEL && channel.is_verified
            ) ? (
              <React.Fragment>
                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                  <Stack direction="row" gap={3} alignItems="center">
                    <img src={`images/slack.png`} width={20} height={20} />
                    Slack
                  </Stack>
                  <ToggleGroup
                    value={slackOption}
                    sx={{ height: 30 }}
                    options={CHANNEL_OPTIONS}
                    onChange={(event, value) =>
                      handleChannelOptionChange(event, value, SLACK_CHANNEL)
                    }
                  />
                </Stack>
              </React.Fragment>
            ) : (
              <>
                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                  <Stack direction="column" gap={1}>
                    <Stack direction="row" gap={3} alignItems="center">
                      <img src={`images/slack.png`} width={20} height={20} />
                      Slack
                    </Stack>
                    <Typography
                      sx={(theme) => ({
                        fontSize: theme.typography.pxToRem(14),
                        fontWeight: typography.font_weight.regular,
                      })}
                    >
                      You need to grant access to your Slack
                    </Typography>
                  </Stack>
                  <a href={`${encodeSlackAuthCallbackURI()}`}>
                    <img
                      alt="Add to Slack"
                      height="40"
                      width="139"
                      src="https://platform.slack-edge.com/img/add_to_slack.png"
                      srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                    />
                  </a>
                </Stack>
              </>
            )}
          </Stack>
        </Box>
      </Stack>
      {/* Code below can be extracted out in differetn component when required */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleClosePhoneInputDrawer}
        sx={{
          zIndex: 100000,
        }}
      >
        <Stack sx={{ width: 400, padding: 4, height: "90vh" }} gap={4}>
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(20),
              fontWeight: typography.font_weight.mediumx,
            })}
          >
            Update your phone number
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }} gap={3}>
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(14),
                fontWeight: typography.font_weight.regular,
              })}
            >
              Please enter your phone to receive SMS alerts
            </Typography>
            <PhoneNumberInput
              countryCodeEditable={false}
              onChange={(value, data, event, formattedValue) => setPhoneNumber(`+${value}`)}
              value={phoneNumber}
            ></PhoneNumberInput>
          </Box>
        </Stack>
        <Stack
          direction="row"
          gap={3}
          sx={{
            height: "10vh",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: 4,
          }}
        >
          <StyledActionButton
            onClick={handlePhoneInputSave}
            disabled={phoneNumber?.length > 0 ? false : true}
          >
            Save Changes
          </StyledActionButton>
          <StyledActionButton
            style={{
              background: "var(--white)",
              color: "var(--comet)",
              borderColor: "var(--comet)",
            }}
            onClick={handleDiscardPhoneNumberInput}
          >
            Discard
          </StyledActionButton>
        </Stack>
      </Drawer>
      {isEventProviderDrawerOpen && (
        <EventProviderDrawer
          subscriptions={eventMappings}
          severity={severityInfoIconClick}
          isEventProviderDrawerOpen={isEventProviderDrawerOpen}
          onEventProviderDrawerClose={closeEventProviderDrawer}
        />
      )}
    </>
  );
};

export default NotificationPreferences;
