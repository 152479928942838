import { UnleashClient } from 'unleash-proxy-client';

const unleash = new UnleashClient({
    url: process.env.REACT_APP_FEATURE_FLAGS_BASE_URL, // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
    clientKey: process.env.REACT_APP_FEATURE_FLAGS_CLIENT_KEY, // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
    refreshInterval: 100, // How often (in seconds) the client should poll the proxy for updates
    appName: 'insights-dashboard', // The name of your application. It's only used for identifying your application
});

// Start the background polling
unleash.start();

export const isEnabled =(toggleName)=>{
    let isFeatureEnabled = unleash.isEnabled(toggleName);
   return isFeatureEnabled;
};

// Context as of now only supports Enabling disabling feature per tenant
// once we set the context here, it will fetch whether the feature is enabled or disabled for a particular tenant
// as per constraints set on feature flag
// These constraints are set with key = "_client_id"
export const setFeatureContext = (tenantId) => {
    unleash.setContextField("_client_id", tenantId);
};