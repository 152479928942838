import React from "react";
import { Box } from "@mui/material";

import SpikesWithRootCause from "./SpikesWithRootCause";
import SpikesWithOverlays from "./SpikesWithOverlays";
import TimeSeriesTrendChart from "./TimeSeriesTrendChart";

export const RenderCharts = ({
  hasRootCause,
  insightId,
  accent,
  kpiFormat,
  hasTrend,
  resourceType,
  resourceId,
  patternType,
  plotBands,
  overlayEndDateEpoch,
  isForecastEnabled = true
}) => {
  return (
    <Box minHeight={550}>
      {hasRootCause ? (
        <SpikesWithRootCause
          hasRootCause={hasRootCause}
          insightId={insightId}
          accent={accent}
          kpiFormat={kpiFormat}
          resourceType={resourceType}
          resourceId={resourceId}
          plotBands={plotBands}
          overlayEndDateEpoch={overlayEndDateEpoch}
          isForecastEnabled={isForecastEnabled}
        />
      ) : !hasTrend ? (
        <SpikesWithOverlays
          accent={accent}
          resourceId={resourceId}
          resourceType={resourceType}
          plotBands={plotBands}
          overlayEndDateEpoch={overlayEndDateEpoch}
          isForecastEnabled={isForecastEnabled}
        />
      ) : (
        // This essentially renders the Trend chart
        <TimeSeriesTrendChart
          patternType={patternType}
          accent={accent}
          resourceId={resourceId}
          resourceType={resourceType}
        />
      )}
    </Box>
  );
};

export default RenderCharts;
