/**
 * This reducer is added a hacky solution for the `react-responsive-carousel`
 */
export const collapseTableNotifierReducer = (
  state = { carouselIndex: 0, shouldCollapseTable: false, insightId: "" },
  action
) => {
  switch (action.type) {
    case "COLLAPSE_TABLE":
      return {
        carouselIndex: action.payload.carouselIndex,
        shouldCollapseTable: action.payload.shouldCollapseTable,
        insightId: action.payload.insightId,
      };
    default:
      return state;
  }
};
