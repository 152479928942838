import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsMore from "highcharts/highcharts-more";
HighchartsMore(Highcharts);
NoDataToDisplay(Highcharts);

// This is normal chart constructor type highcharts initialisation
// In order to use this components to use stock charts we will have to make somee updates to this component in order to make it reusable
const HighChartsReactComponent = ({ options, ...props }) => {
  const [chartOptionsState, setChartOptionsState] = useState(options);

  useEffect(() => {
    Highcharts.setOptions({
      lang: {
        resetZoom: "Reset",
        noData: "Some error occurred, please try again after sometime"
      },
    });
  }, []);
  return <HighchartsReact highcharts={Highcharts} options={chartOptionsState} {...props} />;
};

export default HighChartsReactComponent;
