import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box, ToggleButtonGroup, useMediaQuery } from "@mui/material";
import { subDays, subHours } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

import {
  DoD,
  MoM,
  OVERLAYS_GRANULARITY_MAP,
  YoY,
  WoW,
} from "../../constants/chartOverlayConstants";
import { labels } from "../../shared/intl/labels";
import OverlaySeriesChart from "./OverlaySeriesChart";
import { OverlayToggleButton } from "./styled-components/ActionToggleButton.styled";
import { GTAG_EVENTS, sendDataToGTM } from "../../utils/gtmHelper";
import { InsightContext } from "../../context/InsightContext";
import { GTagArgsContext } from "../../context/GtagContext";
import { theme } from "./styled-components/theme";
import { ChartTitleTypography } from "./styled-components/Container.styled";

const overlayOptions = [
  { value: DoD, label: DoD },
  { value: WoW, label: WoW },
  { value: MoM, label: MoM },
  { value: YoY, label: YoY },
];

export const ChartOverlays = () => {
  const matchesMobile = useMediaQuery(`(max-width:${theme.tablet})`);
  const { insightSummary, patternData } = useContext(InsightContext);
  const gtagArgsContext = useContext(GTagArgsContext);
  const { schedule } = insightSummary;

  const endDate = useMemo(() => {
    return utcToZonedTime(patternData.pattern.chart_ts_end, "UTC");
  }, [patternData.pattern.chart_ts_end]);

  const [overlayType, setOverlayType] = useState("");
  const [startDate, setStartDate] = useState(null);

  const initializeStates = useCallback(() => {
    if (schedule) {
      switch (schedule) {
        case pipelineSchedule.daily:
          convertAndSetDate(
            subDays(
              endDate,
              OVERLAYS_GRANULARITY_MAP[pipelineSchedule.daily].timeInterval
            )
          );
          setOverlayType(WoW);
          break;
        case pipelineSchedule.weekly:
          convertAndSetDate(
            subDays(
              endDate,
              OVERLAYS_GRANULARITY_MAP[pipelineSchedule.weekly].timeInterval
            )
          );
          setOverlayType(MoM);
          break;
        case pipelineSchedule.monthly:
          convertAndSetDate(
            subDays(
              endDate,
              OVERLAYS_GRANULARITY_MAP[pipelineSchedule.monthly].timeInterval
            )
          );
          setOverlayType(YoY);
          break;
        default:
          convertAndSetDate(
            subHours(
              endDate,
              OVERLAYS_GRANULARITY_MAP[pipelineSchedule.hourly].timeInterval
            )
          );
          setOverlayType(DoD);
          break;
      }
    }
  }, [schedule, endDate]);

  const handleChange = (event, selectedOption) => {
    if (selectedOption) {
      switch (schedule) {
        case pipelineSchedule.hourly:
          convertAndSetDate(
            subHours(
              endDate,
              OVERLAYS_GRANULARITY_MAP[pipelineSchedule.hourly].timeInterval
            )
          );
          break;
        case pipelineSchedule.daily:
          convertAndSetDate(
            subDays(
              endDate,
              OVERLAYS_GRANULARITY_MAP[pipelineSchedule.daily].timeInterval
            )
          );
          break;
        case pipelineSchedule.weekly:
          convertAndSetDate(
            subDays(
              endDate,
              OVERLAYS_GRANULARITY_MAP[pipelineSchedule.weekly].timeInterval
            )
          );
          break;
      }

      sendDataToGTM(GTAG_EVENTS.OVERLAY_VIEWED, {
        ...gtagArgsContext,
        overlay_type: selectedOption,
      });
      setOverlayType(selectedOption);
    }
  };

  // Converts the provided date to UTC timezone, and then sets the startDate with ISO format
  const convertAndSetDate = (date) => {
    setStartDate(zonedTimeToUtc(date, "UTC").toISOString());
  };

  useEffect(() => {
    initializeStates();
  }, [initializeStates]);

  return (
    <>
      <Box
        sx={{
          width: "96%",
          display: "flex",
          flexDirection: matchesMobile ? "column" : "row",
          alignItems: matchesMobile ? "center" : "flex-end",
          justifyContent: "space-between",
          alignItems: "center",
          "& > *": {
            m: 1,
          },
        }}
      >
        <ChartTitleTypography
          sx={{ marginTop: "20px", marginLeft: "95px", fontWeight: 400 }}
        >
          Overlays Chart
        </ChartTitleTypography>
        <ToggleButtonGroup
          data-snippyly-comment-disabled
          color="primary"
          value={overlayType}
          exclusive
          onChange={handleChange}
        >
          {overlayOptions
            .filter((overlayOption) =>
              shouldShowOverlayOption(overlayOption.value, schedule)
            )
            .map((overlayOption, index) => (
              <OverlayToggleButton key={index} value={overlayOption.value}>
                {overlayOption.label}
              </OverlayToggleButton>
            ))}
        </ToggleButtonGroup>
      </Box>
      {startDate !== null && (
        <OverlaySeriesChart startDate={startDate} overlayType={overlayType} />
      )}
    </>
  );
};

/**
 * Governs showing/hiding of the overlay options, based on the pipeline schedule.
 * The option with lower or equal granularity than the pipeline, should be hidden.
 *
 * For example, if pipeline schedule is monthly, then WoW, DoD, and MoM should be hidden as they have lower/equal
 * granularity than/to month
 *
 * @param {*} overlay
 * @returns true if the provided option should be shown; otherwise false.
 */
export const shouldShowOverlayOption = (overlay, schedule) => {
  switch (overlay) {
    case DoD:
      return schedule === pipelineSchedule.hourly;
    case WoW:
      return (
        schedule === pipelineSchedule.hourly ||
        schedule === pipelineSchedule.daily
      );
    case MoM:
      return (
        schedule === pipelineSchedule.hourly ||
        schedule === pipelineSchedule.daily ||
        schedule === pipelineSchedule.weekly
      );
    default:
      return (
        schedule === pipelineSchedule.hourly ||
        schedule === pipelineSchedule.daily ||
        schedule === pipelineSchedule.weekly ||
        schedule === pipelineSchedule.monthly
      );
  }
};

export const pipelineSchedule = {
  hourly: labels.PIPELINE_SCHEDULE["h"],
  daily: labels.PIPELINE_SCHEDULE["d"],
  weekly: labels.PIPELINE_SCHEDULE["w"],
  monthly: labels.PIPELINE_SCHEDULE["m"],
};
