import axios from "axios";

import { GENERATE_API_REQUEST, GENERATE_API_REQUEST_FAIL, GENERATE_API_REQUEST_SUCCESS, UPDATE_PREFERENCE, UPDATE_PREFERENCE_FAIL, UPDATE_PREFERENCE_SUCCESS, UPDATE_PROFILE_DETAILS, UPDATE_PROFILE_DETAILS_FAIL, UPDATE_PROFILE_DETAILS_SUCCESS } from "../constants/preferenceConstants";
import { SET_SNACKBAR } from "../reducers/notificationSnackbarReducer";

const url = process.env.REACT_APP_BASE_URL;

export const updateUserPreference = (postData, queryParam) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_PREFERENCE,
    });
    const params = new URLSearchParams(queryParam);
    const { data } = await axios.put(`${url}preferences`, postData, { params });

    dispatch({
      type: UPDATE_PREFERENCE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: SET_SNACKBAR,
      snackbarOpen: true,
      snackbarMessage: "Metrics preference updated.",
      snackbarType: "success",
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PREFERENCE_FAIL,
      payload: error.response,
    });
  }
};

export const updateProfileDetails = (postData, queryParam = {}) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_DETAILS });

    const params = new URLSearchParams(queryParam);
    const { data } = await axios.put(`${url}users/profile`, postData, { params });

    dispatch({
      type: UPDATE_PROFILE_DETAILS_SUCCESS,
      payload: data,
    });
    dispatch({
      type: SET_SNACKBAR,
      snackbarOpen: true,
      snackbarMessage: "Changes have been saved.",
      snackbarType: "success",
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
     return data;
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_DETAILS_FAIL,
      payload: error.response,
    });
  }
};

export const getNotificationPreferences = (currentUser) => async (dispatch) => {
  try {

    const { data } = await axios.get(`${url}users/${currentUser.user_uuid}/subscriptions`);

    return data;
  } catch (error) {

  }
};

export const updateUserSubscriptions = (currentUser, updatedSubscriptions) => async(dispatch)=>{

  try{

    const { data } = await axios.patch(`${url}users/${currentUser.user_uuid}/subscriptions`, updatedSubscriptions);

    dispatch({
      type: SET_SNACKBAR,
      snackbarOpen: true,
      snackbarMessage: "Your Subscriptions are updated successfully",
      snackbarType: "success",
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
    return data;
  }catch(error){
    dispatch({
      type: SET_SNACKBAR,
      snackbarOpen: true,
      snackbarMessage: "An Error occurred while updating the subscriptions",
      snackbarType: "error",
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
    throw error.response;
  }
};

export const generateAPIKey = (postData, queryParam = {}) => async (dispatch) => {
  try {
    dispatch({ type: GENERATE_API_REQUEST });

    const params = new URLSearchParams(queryParam);
    const { data } = await axios.post(`${url}apps/tokens`, postData, { params });
    dispatch({
      type: GENERATE_API_REQUEST_SUCCESS,
      payload: data?.data,
    });
     return data?.data;
  } catch (error) {
    dispatch({
      type: GENERATE_API_REQUEST_FAIL,
      payload: error.response,
    });
  }
};

export const getAPIKeysDetails = (payload = {}) => async (dispatch) => {
  try {

    const { data } = await axios.get(`${url}apps/tokens`, { params: new URLSearchParams(payload)});

    return data;
  } catch (error) {
    return error;
  }
};

export const getStorePreferenceDetails = (orgId, payload = {}) => async (dispatch) => {
  try {

    const { data } = await axios.get(`${url}orgs/${orgId}/preferences`, { params: new URLSearchParams(payload)});

    return data;
  } catch (error) {
    return error;
  }
};

export const updateStorePreferenceDetails = (orgId, postData, storeDisplayName, queryParam = {}) => async (dispatch) => {
  try {
    const params = new URLSearchParams(queryParam);
    const { data } = await axios.put(`${url}orgs/${orgId}/preferences`, postData, { params });
    dispatch({
      type: SET_SNACKBAR,
      snackbarOpen: true,
      snackbarMessage: `Your ${storeDisplayName} Preferences are updated successfully`,
      snackbarType: "success",
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
     return data?.data;
  } catch (error) {
    const { response } = error;
    const errorMessage = response;
    dispatch({
      type: SET_SNACKBAR,
      snackbarOpen: true,
      snackbarMessage: `An Error occurred while updating the ${storeDisplayName} preferences`,
      snackbarType: "error",
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
  }
};