import styled from "@emotion/styled";
import { Chip, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useAxios } from "../../hooks/useAxios";
import { useSelectedTenant } from "../../hooks/useSelectedTenant";
import { colors, typography } from "../../shared/theme-constants";
import Icon from "./Icon";
import ListLoader from "./ListLoader";

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: 3,
  padding: 10,
  "& .MuiChip-label": { fontSize: theme.typography.pxToRem(20), paddingRight: 0 },
}));

const StyledTextChip = styled(Chip)(({ theme }) => ({
  borderRadius: 3,
  padding: 10,
  height: "100%",
  "& .MuiChip-label": { fontSize: theme.typography.pxToRem(20), padding: 0 },
}));

const MetricImpact = ({ kpi_id, timestamp_start, timestamp_end, pipeline_schedule }) => {
  const selectedTenant = useSelectedTenant();
  const tenantGlobalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);

  const { data: metricImpactData, status: metricImpactStatus } = useAxios(
    `metrics_hub/metrics/${kpi_id}/impact`,
    {
      params: {
        timestamp_start: timestamp_start,
        timestamp_end: timestamp_end,
        tenant_id: selectedTenant,
        pipeline_schedule: pipeline_schedule,
        dim_name: tenantGlobalFilter?.dimension_name,
        dim_val: tenantGlobalFilter?.dimension_value
      },
    }
  );

  const calculateLabelColor = (accent, kpi_value_movement) => {
    if (accent === "positive") {
      if (kpi_value_movement > 0) {
        return colors.SEVERITY.GREEN.medium;
      } else {
        return colors.red[450];
      }
    } else {
      if (kpi_value_movement < 0) {
        return colors.SEVERITY.GREEN.medium;
      } else {
        return colors.red[450];
      }
    }
  };

  const calculateLabelTextColor = (accent, kpi_value_movement) => {
    if (accent === "positive") {
      if (kpi_value_movement > 0) {
        return colors.green[150];
      } else {
        return colors.red[150];
      }
    } else {
      if (kpi_value_movement < 0) {
        return colors.green[150];
      } else {
        return colors.red[150];
      }
    }
  };

  const calculateFormattedValue = (impact_type, kpi_format, formatted_value) => {
    if (impact_type === "dollar") {
      return "$" + formatted_value;
    } else {
      return formatted_value;
    }
  };

  const fetchIcon = (metricImpactData) => {
    // This is specifically required for 2 reasons
    // 1. Pattern direction == down and accent == positive equals red color in case of insigt because, THe value might still be above the CI band
    // Here we compare the metrics value against the previous period and hence had to do this tweak in order to resuse the icons
    if (metricImpactData?.accent === "positive" && metricImpactData?.kpi_value_movement > 0) {
      return `arrow-positive-up`;
    } else if (metricImpactData?.accent === "positive" && metricImpactData?.kpi_value_movement < 0) {
      return `arrow-negative-down`;
    } else if (metricImpactData?.accent === "negative" && metricImpactData?.kpi_value_movement > 0) {
      return `arrow-negative-up`;
    } else {
      return `arrow-positive-down`;
    }
  };

  if (!metricImpactData) {
    return <></>;
  } else if (metricImpactStatus == "loading") {
  return <ListLoader height={10} paddingY={10} />;
  } else {
    return (
      <Stack direction="row" gap={4} height={40}>
        {metricImpactData?.formatted_kpi_value_movement === "N/A" ? (
          <StyledTextChip label={metricImpactData?.formatted_kpi_value_movement} />
        ) : (
          <StyledChip
            icon={<Icon name={fetchIcon(metricImpactData)} size="12px" showcursor="true" />}
            sx={{
              height: "100%",
              color: calculateLabelColor(
                metricImpactData?.accent,
                metricImpactData?.kpi_value_movement
              ),
              backgroundColor: calculateLabelTextColor(
                metricImpactData?.accent,
                metricImpactData?.kpi_value_movement
              ),
            }}
            label={
              metricImpactData?.formatted_kpi_value_movement?.charAt(0) === "-"
                ? metricImpactData?.formatted_kpi_value_movement?.substring(1)
                : metricImpactData?.formatted_kpi_value_movement
            }
          />
        )}
        <Stack direction="column">
          <Typography
            fontSize={typography.font_size.base}
            fontWeight={typography.font_weight.semiBold}
          >
            {metricImpactData?.formatted_current_value}
          </Typography>
          <Typography fontSize={10}>
            from{" "}
            {metricImpactData?.formatted_previous_value}
          </Typography>
        </Stack>
      </Stack>
    );
  }
};

export default MetricImpact;
