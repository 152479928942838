import React from "react";
import { styled } from "@mui/material/styles";
import { MenuList, Paper } from "@mui/material";
import DatasourcesColumnMenuSortSection from "./DatasourcesColumnMenuSortSection";

const StyledMenuList = styled(MenuList)({
  "& .MuiListItemIcon-root, & .ListItemIcon-root": {
    minWidth: 28,
  },
  "& .MuiListItemText-inset": {
    paddingLeft: 28,
  },
});

/**
 * @param {import("@mui/x-data-grid").GridColumnMenuProps} props
 */
const DatasourcesColumnMenu = ({
  selectedTenant,
  sortModel,
  setSortModel,
  selectedFilters,
  setSelectedFilters,
  // MUI props
  currentColumn,
  hideMenu,
}) => {
  const columnId = currentColumn.field;

  return (
    <Paper sx={{ width: 320, maxWidth: "100%" }}>
      <StyledMenuList>
        {!currentColumn.hideSortOptions ? (
          <DatasourcesColumnMenuSortSection
            columnId={columnId}
            sortModel={sortModel}
            setSortModel={setSortModel}
          />
        ) : null}
      </StyledMenuList>
    </Paper>
  );
};

export default DatasourcesColumnMenu;
